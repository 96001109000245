import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, SelectMultipleUI } from '../../../common';

const ModalCategoriasSecundarias = (props) => {
  let {
    common,
    handleChangeMultipleSelect,
    options,
    handleSubmit,
    modalHandler,
    modal_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="categorias_secundarias"
      title="Categorías secundarias"
      subtitle="Tu producto también aparecerá en las categorias secundarias"
      modalHandler={modalHandler}
      aditional_param={false}
      handleSubmit={handleSubmit}
      button_label="Guardar"
      allow_overflow
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <SelectMultipleUI
            data={common.categorias_secundarias}
            options={options}
            value_atribute="idCategorias"
            label_atribute="c_nombre"
            label_sub_atribute="c_prefix"
            handleChangeMultipleSelect={handleChangeMultipleSelect}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalCategoriasSecundarias };
