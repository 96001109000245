import React from 'react';
import { Config } from './../../../../other';

export const LogoPaymentSuscription = (props) => {
  return (
    <img
      {...props}
      src={`${Config.CLOUDFRONT_CDN_PROD}/panel/suscripciones/cards.png`}
    />
  );
};
