import { Grid } from '@mui/material';
import React from 'react';
import { InfoText } from '../InfoText';
import {
  ImageUI,
  InputUI,
  SelectUI,
  ToggleUI,
} from '../../../../components/common';
import { Config } from '../../../../other';
import { yesOrNoOptions } from '../../jsonSelects';

export const BannersCarousel = ({
  handlerShowAlertUI,
  handleChangeFileBlock,
  handleChangeSelectBlock,
  handleChangeBlock,
  block,
}) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText
          title="Banners"
          subtitle="Dimensiones recomendadas: 1920x677 o 1920x800 píxeles"
        />
        <ImageUI
          handlerShowAlertUI={handlerShowAlertUI}
          enable_url
          data={block.bl_data.items}
          max_files={6}
          max_size={5}
          allowed_files={['jpg', 'png', 'jpeg', 'gif']}
          input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
          handleChange={handleChangeFileBlock}
          cdn_prefix={Config.CLOUDFRONT_BANNERS_CDN}
          upload_path="banner"
          row_height="90px"
          reducer_max_width={1920}
          reducer_max_height={1920}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Adicionales" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data?.width}
              options={[
                {
                  value: 0,
                  label: 'Toda la pantalla',
                },
                {
                  value: 1,
                  label: 'Centrado',
                },
              ]}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.autoplay}
              options={yesOrNoOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <ToggleUI show={block.bl_data.autoplay.value ? true : false}>
            <Grid item xs={12}>
              <InputUI
                input={block.bl_data.seconds}
                handleChange={handleChangeBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
    </Grid>
  );
};
