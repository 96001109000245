import React, { useState } from 'react';
import { ButtonUI, CodeUI, ModalLiteUI } from '../../../../components/common';
import { Grid } from '@mui/material';
import { InfoText } from '../InfoText';

export const HTMLCode = ({ block, handleChangeBlock }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let bloque_10_modal_subtitle = (
    <span>
      Si requerís agregar código CSS para éste bloque HTML, podés hacerlo desde
      la sección de Ajustes. Adicionalmente, podés utilizar los componentes del
      framework{' '}
      <u>
        <a
          href="https://getuikit.com"
          style={{ color: 'inherit' }}
          target="_blank"
          rel="noreferrer"
        >
          UIkit
        </a>
      </u>{' '}
      para tu código que ya viene incluído en las plantillas.
    </span>
  );

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <ModalLiteUI
          open={isModalOpen}
          title="Código HTML"
          subtitle={bloque_10_modal_subtitle}
          modalHandler={() => setIsModalOpen(!isModalOpen)}
          full_size
          id="codigo_html"
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <CodeUI
                input={block.bl_data.code}
                lang="html"
                width="100%"
                height="400px"
                handleChange={handleChangeBlock}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonUI
                type="callf"
                onClickFunc={() => setIsModalOpen(!isModalOpen)}
                label="Cerrar editor HTML"
              />
            </Grid>
          </Grid>
        </ModalLiteUI>
        <InfoText title="Código HTML" subtitle={bloque_10_modal_subtitle} />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <ButtonUI
              type="callf"
              onClickFunc={() => setIsModalOpen(!isModalOpen)}
              variant="outlined"
              color="primary"
              size="small"
              label="Abrir editor HTML"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
