import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Config, Validate } from '../../other';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { AlertUI, ModuleTitleUI } from '../../components/common';
import { dominioObtenerDominioPreferencial } from '../../store/actions';

const MetaAdsPage = ({ getPreferencialDomain, classes, dominio }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: 'success',
    message: 'El link de tu catálogo ha sido copiado con éxito',
  });

  useEffect(() => {
    getPreferencialDomain((err, _resp) => {
      if (err) {
        setError(true);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleCloseAlert = () => {
    setAlert((prevState) => {
      return { ...prevState, open: false };
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      document.querySelector('#link_catalogo').value
    );
    if (!Validate.is_mobile()) {
      setAlert((prevState) => {
        return { ...prevState, open: true };
      });
    }
  };

  const link_catalogo =
    'https://' + dominio.d_nombre + '/facebook/catalogo-facebook.xml';

  return (
    <WrapperLayout loading={loading} error={error}>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Meta Ads"
            inline_image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/meta.png`}
            subtitles={[
              'Meta Ads te permite configurar anuncios pagos en Instagram y Facebook desde el administrador de anuncios en Facebook.',
              'Con la creación de un catálogo de productos en Meta Ads, podrás configurar anuncios dinámicos que aumenten las ventas y conversiones de tu negocio.',
              'Podrás crear fácilmente tu catálogo de productos en Facebook y vincularlo con los productos de tu Empretienda utilizando el link proporcionado.',
            ]}
          />
          <Card className={classes.card}>
            <CardContent className={classes.card_content}>
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    label="Link para tu catálogo de productos"
                    value={link_catalogo}
                    id="link_catalogo"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Copiar link" placement="left">
                          <InputAdornment position="end">
                            <IconButton onClick={handleCopyLink} size="large">
                              <Icon>filter_none</Icon>
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
  step_image: {
    width: '80%',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
});

const mapStateToProps = (state) => {
  return {
    dominio: state.dominio,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPreferencialDomain: (callback) =>
      dispatch(dominioObtenerDominioPreferencial(callback)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MetaAdsPage)
);
