import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { ModalUI } from '../../common';
import { connect } from 'react-redux';
import { tiendaBajaSuscripcion } from '../../../store/actions';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../../amplitude';

const ModalCancelSuscription_ = ({
  open,
  classes,
  idCountry,
  handleSubmit,
  modalHandler,
  cancelarSuscripcion,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitCancelSuscription = (e) => {
    e.preventDefault();
    setLoading(true);

    cancelarSuscripcion((err, resp) => {
      if (err) {
        createAmplitudeEventWithDevice(
          suscription_amplitude_events.mi_plan_confirmar_cancelar_suscripcion
            .event_name,
          { success: false }
        );
      } else {
        createAmplitudeEventWithDevice(
          suscription_amplitude_events.mi_plan_confirmar_cancelar_suscripcion
            .event_name,
          { success: true }
        );
      }

      handleSubmit({
        err,
        alert: {
          open: true,
          message: err ? resp.message : 'Cancelaste tu suscripción.',
          type: err ? 'error' : 'success',
        },
      });

      modalHandler(false);
    });
  };

  const translate = {
    ARG: {
      subtitle: 'Podés volver a suscribirte cuando quieras.',
    },
    COL: {
      subtitle: 'Puedes volver a activar tu suscripción cuando quieras.',
    },
  };

  return (
    <ModalUI
      open={open}
      button_label="Confirmar"
      cancel_button_label="Cancelar"
      button_loading={loading}
      id="cancelar_suscripcion"
      modalHandler={modalHandler}
      aditional_param="cancelar_suscripcion"
      handleSubmit={handleSubmitCancelSuscription}
      title="Estás por dar de baja al débito automático"
      classes={{ card_actions: classes.card_actions }}
      subtitle={translate[idCountry].subtitle}
    />
  );
};

const styles = () => ({
  card_actions: {
    marginTop: '24px',
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    cancelarSuscripcion: (callback) =>
      dispatch(tiendaBajaSuscripcion(callback)),
  };
};

export const ModalCancelSuscription = withStyles(styles)(
  connect(null, mapDispatchToProps)(ModalCancelSuscription_)
);
