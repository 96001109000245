import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../../../components/common';

const ModalDevolverStock = (props) => {
  let { handleSubmit, modalHandler, modal_state, form_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="devolver_stock"
      title="Devolver productos al stock"
      modalHandler={modalHandler}
      aditional_param="devolver_stock"
      handleSubmit={handleSubmit}
      button_label="Devolver stock"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Typography>
          Todos los productos de la orden van a ser devueltos al stock para
          poder volver a ser comprados.
        </Typography>
      </Grid>
    </ModalUI>
  );
};

export { ModalDevolverStock };
