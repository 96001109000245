import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Validate } from '../../../other';

const ArticulosList_ = (props) => {
  let { blog, classes, modalHandler, dominio } = props;
  const router = useHistory();

  const renderizarArticulos = (articulos, d_nombre, modalHandler) => {
    if (articulos.length > 0) {
      return articulos.map((articulo, index) => {
        return (
          <ListItem
            divider={index < articulos.length - 1}
            key={articulo.idBlog}
          >
            <ListItemText
              classes={{ root: classes.list_text }}
              primary={articulo.pb_titulo}
              secondary={
                <span className={classes.span}>
                  <span className={classes.span}>
                    Publicado {Validate.utc2relative_local(articulo.pb_fecha)}
                  </span>
                </span>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Ver"
                href={'https://' + d_nombre + '/blog/' + articulo.pb_link}
                size="large"
                target="_blank"
                rel="noreferrer"
              >
                <Icon>open_in_new</Icon>
              </IconButton>
              <IconButton
                aria-label="Editar"
                onClick={() => router.push(`/blog/${articulo.idBlog}`)}
                size="large"
              >
                <Icon>edit</Icon>
              </IconButton>
              <IconButton
                aria-label="Borrar"
                onClick={(e) => modalHandler(true, 'borrar', articulo)}
                size="large"
              >
                <Icon>delete</Icon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      });
    } else {
      return (
        <ListItem>
          <ListItemText
            primary="No se han encontrado artículos activos"
            secondary="Puedes agregar uno haciendo click en Agregar"
          />
        </ListItem>
      );
    }
  };

  return (
    <List classes={{ root: classes.list }}>
      {renderizarArticulos(blog, dominio.d_nombre, modalHandler)}
    </List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  list_text: {
    paddingRight: '150px',
  },
});

const mapStateToProps = (state) => {
  return {
    blog: state.blog,
    auth: state.auth,
    dominio: state.dominio,
  };
};

const mapDispatchToProps = null;

export const ArticulosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ArticulosList_));
