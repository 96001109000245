import StoreIcon from '@mui/icons-material/Store';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import BrushIcon from '@mui/icons-material/Brush';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const initialStateOnboardingSteps = [
  {
    step_number: 1,
    completed: true,
    key: 'shopCreated',
    title: { default: 'Creaste tu tienda', success: 'Creaste tu tienda' },
    image: StoreIcon,
  },
  {
    step_number: 2,
    completed: false,
    key: 'addProductsStepDone',
    title: {
      default: 'Agregá tus productos',
      success: 'Agregaste productos',
    },
    subtitle: 'Cargá lo que quieras vender, con fotos y descripción.',
    image: ShoppingBasketIcon,
    link_to: '/productos/agregar',
    amplitude_event: 'empretienda_onboarding_selecciona_productos',
  },
  {
    step_number: 3,
    completed: false,
    key: 'designStepDone',
    title: {
      default: 'Personalizá tu tienda',
      success: 'Personalizaste tu tienda',
    },
    subtitle: 'Cargá el logo de tu tienda y dale estilo a tu marca.',
    image: BrushIcon,
    link_to: '/builder',
    amplitude_event: 'empretienda_onboarding_selecciona_diseño',
  },
  {
    step_number: 4,
    completed: false,
    key: 'paymentMethodStepDone',
    title: {
      default: 'Elegí los métodos de pago',
      success: 'Elegiste los métodos de pago',
    },
    subtitle: 'Seleccioná opciones para que te paguen tus clientes.',
    image: PaymentsIcon,
    link_to: '/metodos-pago',
    amplitude_event: 'empretienda_onboarding_selecciona_metodos_de_pago',
  },
  {
    step_number: 5,
    completed: false,
    key: 'shipmentMethodStepDone',
    title: {
      default: 'Configurá los métodos de envío',
      success: 'Configuraste los métodos de envío',
    },
    subtitle: 'Seleccioná cómo querés enviar tus productos.',
    image: LocalShippingIcon,
    link_to: '/metodos-envio',
    amplitude_event: 'empretienda_onboarding_selecciona_metodos_de_envio',
  },
];
