/**
 * Summary. Export metadata over expected worksheet format
 *
 * Description. The data includes required columns, order and validations.
 *       <br>also indicates the mapping between columns and json data for backend.
 *
 */
import { isNaN as _isNaN } from 'underscore';

import { Regex, Validate } from '../../../other';

import { IMPORT_ERROR_CODE_MESSAGE } from './ImportErrorCodes';

/**
 * @typedef {Object} Cell
 * @property {number} index - index in the row of the column related to the cell
 * @property {string} title - title of the column related to the cell
 * @property {string | number | null } value - value of the cell
 */

/**
 * Checks if a column name exist in a list of column names
 * @param {string} column_name - a column name
 * @param {string[]} column_name_list - a list of column names
 * @returns {boolean} the column exists in the columns list
 */
export const hasColumn = (column_name, column_name_list) => {
  const found = column_name_list.find(
    (a_column_name) => column_name === a_column_name
  );
  return found ? true : false;
};

/**
 * @summary: Given the cells of a row and a column name returns the value of the corresponding cell
 * @param {Cell[]} row_cells
 * @param {string} column_name
 * @returns {string | number | null} value of cell
 */
export const cell_value = (row_cells, column_name) => {
  const cell = row_cells.find((cell) => cell.title === column_name);
  return cell ? cell.value : null;
};

export const categoryPathFormat = (categoryPath) => {
  const categories = Validate.trim(String(categoryPath)).split(' > ');
  const categoryWhitErrors = categories.find(
    (category) => !Regex.CATEGORIA_NOMBRE.test(category)
  );
  if (categoryWhitErrors) return false;
  return true;
};

export const isEmptyString = (value) => {
  if (value === undefined || value === null || value === '') {
    return true;
  } else {
    return false;
  }
};

export const isEmptyNumber = (value) => {
  if (_isNaN(value)) return true;
  const numericValue = Number(value);
  if (
    numericValue === 0 ||
    numericValue === undefined ||
    numericValue === null
  ) {
    return true;
  } else {
    return false;
  }
};

export const isEmptyPriceAndDimension = (value) => {
  //Estamos recibiendo precios y dimensiones como strings
  if (value === '' || value === null || value === undefined) {
    return true;
  } else {
    return false;
  }
};

//armar funcion para peso y otra para profundidad, alto, y ancho.
export const ConvertStringToNumberOrCero = (value, idCountry = 'ARG') => {
  const valueStr = {
    ARG: value,
    COL: Regex.PRECIO_POSITIVO.ARG.test(value)
      ? parseFloat(value).toFixed(0)
      : value,
  }[idCountry];

  //Si el string cumple con alguna RegExp se pasa a Number, sino se asigna 0
  const isANumber =
    Regex.PESO.test(value) ||
    Regex.PRECIO_POSITIVO[idCountry].test(valueStr) ||
    Regex.NUMERO_ENTERO_POSITIVO.test(value);

  if (idCountry === 'COL') {
    return isANumber ? valueStr : 0;
  }

  return isANumber ? parseFloat(value) : 0;
};

export const column_names = {
  nombre: 'Nombre',
  stock: 'Stock',
  SKU: 'SKU',
  nombreAtributo1: 'Nombre atributo 1',
  valorAtributo1: 'Valor atributo 1',
  nombreAtributo2: 'Nombre atributo 2',
  valorAtributo2: 'Valor atributo 2',
  nombreAtributo3: 'Nombre atributo 3',
  valorAtributo3: 'Valor atributo 3',
  categorias: 'Categorías',
  precio: 'Precio',
  precioOferta: 'Precio oferta',
  precioMayorista: 'Precio mayorista',
  peso: 'Peso',
  alto: 'Alto',
  ancho: 'Ancho',
  profundidad: 'Profundidad',
  mostrarEnTienda: 'Mostrar en tienda',
  descripcion: 'Descripción',
  IDProduct: 'IDProduct',
  IDStock: 'IDStock',
};

export const reserved_column_names = [
  column_names.nombre,
  column_names.stock,
  column_names.SKU,
  column_names.nombreAtributo1,
  column_names.valorAtributo1,
  column_names.nombreAtributo2,
  column_names.valorAtributo2,
  column_names.nombreAtributo3,
  column_names.valorAtributo3,
  column_names.categorias,
  column_names.precio,
  column_names.precioOferta,
  column_names.precioMayorista,
  column_names.peso,
  column_names.alto,
  column_names.ancho,
  column_names.profundidad,
  column_names.mostrarEnTienda,
  column_names.descripcion,
  column_names.IDProduct,
  column_names.IDStock,
];

export const titleWithDot = [
  column_names.precio,
  column_names.precioOferta,
  column_names.precioMayorista,
  column_names.peso,
  column_names.alto,
  column_names.ancho,
  column_names.profundidad,
];

export const worksheet_format = [
  {
    position: 0,
    column_name: column_names.nombre,
    json_name: ['p_nombre'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR009,
        /**
         * @param { Cell } cell
         * @param {Cell[]} row_cells
         * @returns {boolean} value of cell is valid
         */
        validation: (cell, row_cells) => {
          let value = Validate.trim(cell.value);
          if (isEmptyString(value)) return false;
          return Regex.NOMBRE_PRODUCTO.test(value);
        },
      },
    ],
  },
  {
    position: 1,
    column_name: column_names.stock,
    json_name: ['s_cantidad', 's_ilimitado'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR029,
        validation: (cell, row_cells) => {
          if (isEmptyNumber(cell.value)) return true;
          return Regex.NUMERO_ENTERO.test(cell.value);
        },
      },
    ],
  },
  {
    position: 2,
    column_name: column_names.SKU,
    json_name: ['s_sku'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR033,
        validation: (cell, row_cells) => {
          let value = Validate.trim(cell.value);
          if (isEmptyString(value)) return true;
          return Regex.SKU.test(value);
        },
      },
    ],
  },
  {
    position: 3,
    column_name: column_names.precio,
    json_name: ['s_precio'],
    product_level_validation:
      'todos los stocks sin precio o todos los stocks con algún precio',
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR035,
        validation: (cell, row_cells) => {
          let value = cell.value;
          if (isEmptyPriceAndDimension(value)) return true;
          return Regex.PRECIO_POSITIVO.ARG.test(value);
        },
      },
    ],
  },
  {
    position: 4,
    column_name: column_names.precioOferta,
    json_name: ['s_precio_oferta'],
    product_level_validation: 'puede haber stocks con precio y sin precio',
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR039,
        validation: (cell, row_cells) => {
          let value = cell.value;
          if (isEmptyPriceAndDimension(value)) return true;
          return Regex.PRECIO_POSITIVO.ARG.test(value);
        },
      },
    ],
  },
  {
    position: 5,
    column_name: column_names.precioMayorista,
    json_name: ['s_precio_mayorista'],
    product_level_validation:
      'todos los stocks sin precio o todos los stocks con algún precio',
    column_required: (column_name_list) => false,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR041,
        validation: (cell, row_cells) => {
          let value = cell.value;
          if (isEmptyPriceAndDimension(value)) return true;
          return Regex.PRECIO_POSITIVO.ARG.test(value);
        },
      },
    ],
  },
  {
    position: 6,
    column_name: column_names.nombreAtributo1,
    json_name: ['atributos', 'valoratributo'],
    product_level_validation:
      'todos los stocks con la misma cantidad y los mismos nombres de atributos',
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR019,
        validation: (cell, row_cells) => {
          if (isEmptyString(cell.value)) return true;
          return Regex.ATRIBUTO.test(cell.value);
        },
      },
    ],
  },
  {
    position: 7,
    column_name: column_names.valorAtributo1,
    json_name: ['valoratributo'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR025,
        validation: (cell, row_cells) => {
          let value = cell.value;
          if (isEmptyString(value)) return true;
          return Regex.VALOR_ATRIBUTO.test(value);
        },
      },
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR022,
        validation: (cell, row_cells) =>
          !isEmptyString(cell.value) &&
          isEmptyString(cell_value(row_cells, column_names.nombreAtributo1))
            ? false
            : true,
      },
    ],
  },
  {
    position: 8,
    column_name: column_names.nombreAtributo2,
    json_name: ['atributos', 'valoratributo'],
    product_level_validation:
      'todos los stocks con la misma cantidad y los mismos nombres de atributos',
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR019,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          let value = cell.value;
          if (isEmptyString(value)) return true;
          return Regex.ATRIBUTO.test(value);
        },
      },
    ],
  },
  {
    position: 9,
    column_name: column_names.valorAtributo2,
    json_name: ['valoratributo'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR025,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          let value = cell.value;
          if (isEmptyString(value)) return true;
          return Regex.VALOR_ATRIBUTO.test(value);
        },
      },
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR022,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          !isEmptyString(cell.value) &&
          isEmptyString(cell_value(row_cells, column_names.nombreAtributo2))
            ? false
            : true,
      },
    ],
  },
  {
    position: 10,
    column_name: column_names.nombreAtributo3,
    json_name: ['atributos', 'valoratributo'],
    product_level_validation:
      'todos los stocks con la misma cantidad y los mismos nombres de atributos',
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR019,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          let value = cell.value;
          if (isEmptyString(value)) return true;
          return Regex.ATRIBUTO.test(value);
        },
      },
    ],
  },
  {
    position: 11,
    column_name: column_names.valorAtributo3,
    json_name: ['valoratributo'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR025,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          let value = cell.value;
          if (isEmptyString(value)) return true;
          return Regex.VALOR_ATRIBUTO.test(value);
        },
      },
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR022,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          !isEmptyString(cell.value) &&
          isEmptyString(cell_value(row_cells, column_names.nombreAtributo3))
            ? false
            : true,
      },
    ],
  },
  {
    position: 12,
    column_name: column_names.categorias,
    json_name: ['categorias'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR015,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          categoryPathFormat(cell.value),
      },
    ],
  },
  {
    position: 13,
    column_name: column_names.peso,
    json_name: ['s_peso'],
    format_type: 'number',
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR043,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          if (isEmptyPriceAndDimension(cell.value) && !dimensiones_requeridas)
            return true;
          if (isEmptyPriceAndDimension(cell.value) && dimensiones_requeridas)
            return false;
          return Regex.PESO.test(cell.value);
        },
      },
    ],
  },
  {
    position: 14,
    column_name: column_names.alto,
    json_name: ['s_alto'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR045,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          if (isEmptyPriceAndDimension(cell.value) && !dimensiones_requeridas)
            return true;
          if (isEmptyPriceAndDimension(cell.value) && dimensiones_requeridas)
            return false;
          return Regex.NUMERO_ENTERO_POSITIVO.test(cell.value);
        },
      },
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR087,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          //Esta validación solo se ocupa del caso de dimensiones no requeridas
          //si las dimensiones no son requeridas, hay dos opciones
          //1) entrada de stock sin ninguna dimensión
          //2) entrada de stock con todas las dimensiones
          // Para evaluar cuando no tiene dimensiones requeridas estamos usando el
          // valor númerico para saber si todas las dimensiones tiene un valor y
          // para saber que ninguna tiene dimensión tiene un valor estamos comparando por la cadena de carácteres vacío.

          if (dimensiones_requeridas) return true; //más abajo estoy analizando cuando las dimensiones son opcionales

          const alto_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.alto)
          );
          const ancho_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.ancho)
          );
          const peso_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.peso)
          );
          const profundidad_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.profundidad)
          );
          const all_dimensions =
            alto_cell > 0 &&
            ancho_cell > 0 &&
            peso_cell > 0 &&
            profundidad_cell > 0;
          const none_dimensions =
            isEmptyPriceAndDimension(
              cell_value(row_cells, column_names.alto)
            ) &&
            isEmptyPriceAndDimension(
              cell_value(row_cells, column_names.ancho)
            ) &&
            isEmptyPriceAndDimension(
              cell_value(row_cells, column_names.peso)
            ) &&
            isEmptyPriceAndDimension(
              cell_value(row_cells, column_names.profundidad)
            );

          if (!dimensiones_requeridas && (all_dimensions || none_dimensions))
            return true;
          return false; //serían los casos de mezcla entre vacios y con datos
        },
      },
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR404,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          if (!dimensiones_requeridas) return true; //Más abajo estoy analizando cuando las dimensiones son requeridas

          const alto_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.alto)
          );
          const ancho_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.ancho)
          );
          const peso_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.peso)
          );
          const profundidad_cell = ConvertStringToNumberOrCero(
            cell_value(row_cells, column_names.profundidad)
          );
          const all_dimensions =
            alto_cell > 0 &&
            ancho_cell > 0 &&
            peso_cell > 0 &&
            profundidad_cell > 0;
          if (dimensiones_requeridas && all_dimensions) return true;
          return false; //serían los casos de mezcla entre vacios y con datos
        },
      },
    ],
  },
  {
    position: 15,
    column_name: column_names.ancho,
    json_name: ['s_ancho'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR047,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          if (isEmptyPriceAndDimension(cell.value) && !dimensiones_requeridas)
            return true;
          if (isEmptyPriceAndDimension(cell.value) && dimensiones_requeridas)
            return false;
          return Regex.NUMERO_ENTERO_POSITIVO.test(cell.value);
        },
      },
    ],
  },
  {
    position: 16,
    column_name: column_names.profundidad,
    json_name: ['s_profundidad'],
    column_required: (column_name_list) => true,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR049,
        validation: (cell, row_cells, dimensiones_requeridas) => {
          if (isEmptyPriceAndDimension(cell.value) && !dimensiones_requeridas)
            return true;
          if (isEmptyPriceAndDimension(cell.value) && dimensiones_requeridas)
            return false;
          return Regex.NUMERO_ENTERO_POSITIVO.test(cell.value);
        },
      },
    ],
  },
  {
    position: 17,
    column_name: column_names.mostrarEnTienda,
    json_name: ['p_desactivado'],
    column_required: (column_name_list) => false,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR053,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          /^(?:Si|No)$/.test(cell.value),
      },
    ],
  },
  {
    position: 18,
    column_name: column_names.descripcion,
    json_name: ['p_descripcion'],
    column_required: (column_name_list) => false,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR051,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          /^[\s\S]{0,20000}$/.test(cell.value),
      },
    ],
  },
  {
    position: 19,
    column_name: column_names.IDProduct,
    json_name: ['idProductos'],
    column_required: (column_name) => false,
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR007,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          cell.value >= 0 ? true : false,
      },
    ],
  },
  {
    position: 20,
    column_name: column_names.IDStock,
    json_name: ['idStock'],
    column_required: (column_name_list) =>
      hasColumn(column_names.IDProduct, column_name_list),
    validations: [
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR027,
        validation: (cell, row_cells, dimensiones_requeridas) =>
          cell.value >= 0 ? true : false,
      },
      {
        message: IMPORT_ERROR_CODE_MESSAGE.ERR065,
        /**
         * @param { Cell } cell
         * @param {Cell[]} row_cells
         * @returns {boolean} value of cell is valid
         */
        validation: (cell, row_cells, dimensiones_requeridas) => {
          if (isEmptyNumber(cell.value)) return true;
          const IDProduct_cell = cell_value(row_cells, column_names.IDProduct);
          if (!IDProduct_cell) return false;
          if (IDProduct_cell.value === 0) return false;
          return true;
        },
      },
    ],
  },
];
