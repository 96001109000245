import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import { Config } from '../../../other';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@emotion/react';
import ModalVideo from './ModalVideo';
import { Image } from './Image';

const urlCloudFront = Config.CLOUDFRONT_CDN_PROD;
const IDs_VIDEOS = {
  initial: {
    video: 'G1PylsdFld8',
    images: {
      desktop: `${urlCloudFront}/panel/home/video_1.png`,
      mobile: `${urlCloudFront}/panel/home/video_1_mobile.png`,
    },
  },
  onboardingComplete: {
    video: 'E_3-SSHMIuQ',
    images: {
      desktop: `${urlCloudFront}/panel/home/video_2.png`,
      mobile: `${urlCloudFront}/panel/home/video_2_mobile.png`,
    },
  },
  postFirstSale: {
    video: 'pqoGhUJ6fho',
    images: {
      desktop: `${urlCloudFront}/panel/home/video_3.png`,
      mobile: `${urlCloudFront}/panel/home/video_3_mobile.png`,
    },
  },
};

export const VideoTutorial = ({
  salesAmount,
  allRequestsLoaded,
  onboardingStepsCompleted,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [videoStep, setVideoStep] = useState(null);

  useEffect(() => {
    if (salesAmount === null || allRequestsLoaded === false) {
      return;
    }
    if (salesAmount < 1) {
      if (onboardingStepsCompleted) {
        setVideoStep('onboardingComplete');
      } else {
        setVideoStep('initial');
      }
    } else {
      setVideoStep('postFirstSale');
    }
  }, [salesAmount, allRequestsLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const device = isMobile ? 'mobile' : 'desktop';

  const amplitudeVideoEvent = {
    initial: 'pasos_guia',
    onboardingComplete: 'primera_venta',
    postFirstSale: 'potenciar_tienda',
  };

  const imageTitle = {
    initial: 'Creá tu tienda',
    onboardingComplete: 'Tips para tu primera venta',
    postFirstSale: 'Llevá tu tienda a otro nivel',
  };

  const redirectToYouTube = () => {
    const youtubeVideoId = IDs_VIDEOS[videoStep]?.video;
    const youtubeUrl = `https://www.youtube.com/watch?v=${youtubeVideoId}`;
    window.open(youtubeUrl, '_blank');
  };

  const handleOpenModal = () => {
    if (device === 'desktop') {
      if (!isOpen) {
        createAmplitudeEventWithDevice(
          amplitude_events.visualiza_video_home.event_name,
          {
            video: amplitudeVideoEvent[videoStep],
          }
        );
      }
      setIsOpen(!isOpen);
    } else {
      createAmplitudeEventWithDevice(
        amplitude_events.visualiza_video_home.event_name,
        {
          video: amplitudeVideoEvent[videoStep],
        }
      );
      redirectToYouTube();
    }
  };

  const handleOnKeyPress = ({ code }) => {
    code === 'Enter' && handleOpenModal();
  };

  return (
    <Box
      sx={{
        gridColumn: { xs: '1 / 5', md: '9 / 13' },
        gridRow: 'span 2',
      }}
    >
      <Box
        tabIndex={0}
        role="button"
        onClick={handleOpenModal}
        onKeyDown={handleOnKeyPress}
      >
        <Box
          height="100%"
          position="relative"
          sx={{
            cursor: 'pointer',
            display: { xs: 'none', md: 'block' },
            '&:hover': {
              '& div': {
                height: '100%',
              },
            },
          }}
        >
          <Image
            sx={{ minHeight: '205px' }}
            src={IDs_VIDEOS[videoStep]?.images.desktop}
          />
          <Box
            sx={{
              background:
                'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
            }}
            width={'100%'}
            left={0}
            bottom={0}
            right={0}
            height={'68px'}
            position="absolute"
            borderRadius={'4px'}
          >
            <Typography
              left="14px"
              bottom="14px"
              color="#FFFFFF"
              component="span"
              fontWeight="400"
              fontSize="1.125rem"
              position="absolute"
            >
              {imageTitle[videoStep]}
            </Typography>
          </Box>
        </Box>

        <Box
          height="100%"
          position="relative"
          sx={{ cursor: 'pointer', display: { xs: 'block', md: 'none' } }}
          boxShadow="0 0.07em 0.125em 0 rgba(0,0,0,.15)"
          borderRadius={'4px'}
        >
          <Image
            alt="Thumbnail"
            onLoad={handleImageLoad}
            src={IDs_VIDEOS[videoStep]?.images.mobile}
            sx={{ display: { xs: 'block', md: 'none' } }}
          />
          {imageLoaded && (
            <>
              <Typography
                left="20px"
                fontSize="1rem"
                component="span"
                fontWeight="500"
                position="absolute"
                bottom={{ sm: '50px' }}
                top={{ xs: '16px', sm: 'unset' }}
              >
                {imageTitle[videoStep]}
              </Typography>
              <Box
                left="20px"
                bottom="20px"
                position="absolute"
                display="flex"
                alignItems="center"
                sx={{
                  '& svg': {
                    color: '#53B755',
                    fontSize: '20px',
                  },
                }}
              >
                <Typography
                  fontSize={{ xs: '14px', sm: '16px' }}
                  color="#53B755"
                  fontWeight="500"
                  component="span"
                >
                  Ver video
                </Typography>
                <KeyboardArrowRightIcon />
              </Box>
            </>
          )}
        </Box>
      </Box>
      {isOpen && (
        <ModalVideo
          onClick={handleOpenModal}
          onKeyDown={handleOnKeyPress}
          video={IDs_VIDEOS[videoStep]?.video}
        />
      )}
    </Box>
  );
};
