import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SnackbarContent, IconButton } from '@mui/material';
import { CheckCircle, Close, Warning, Error, Info } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import { ToggleUI } from './';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const MessageUI_ = (props) => {
  let {
    classes,
    type,
    message,
    show = true,
    close_button = true,
    show_icon = true,
    id = 'message-ui',
  } = props;

  const [open, setCloseAlert] = useState(true);

  const closeAlert = () => setCloseAlert(!open);

  let Icon = variantIcon[type];

  let action = close_button
    ? [
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => closeAlert()}
          size="large"
        >
          <Close className={classes.icon} />
        </IconButton>,
      ]
    : [];

  return (
    <ToggleUI show={show && open}>
      <SnackbarContent
        classes={{ root: `${classes[type]} ${classes.snackbar}` }}
        aria-describedby={id}
        message={
          <span id={id} className={classes.message}>
            <ToggleUI show={show_icon}>
              <Icon className={`${classes.icon} ${classes.iconVariant}`} />
            </ToggleUI>
            {message}
          </span>
        }
        action={action}
      />
    </ToggleUI>
  );
};

const styles = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: '#FADDAA',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbar: {
    maxWidth: '100%',
    boxShadow: 'none',
    marginBottom: '20px',
  },
});

MessageUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //Type of the alert, it makes
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,

  //The message of the alert
  message: PropTypes.string.isRequired,

  //Whether the alert must be shown or not (default true)
  show: PropTypes.bool,

  //Wheter the alert can be dismissed or not (default true)
  close_button: PropTypes.bool,

  //Wheter the message icon must be shown or not (default true)
  show_icon: PropTypes.bool,

  //The id of the alert
  id: PropTypes.string,
};

export const MessageUI = withStyles(styles)(MessageUI_);
