import React from 'react';
import { CardModuleUI } from '../../../../components/common';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

export const ShareDomainLinkContainer = ({
  setAlert,
  idCountry,
  domainStore,
  handleSkipTip,
}) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(document.querySelector('#link_tienda').value);
    setAlert({
      open: true,
      type: 'success',
      message: 'El link de tu tienda ha sido copiado',
    });
  };

  const domainResources = 'https://recursos.empretienda.com';
  const linkButtonTips = `${domainResources}/5-tips-para-la-tienda${
    idCountry === 'COL' ? '-col' : ''
  }.pdf`;

  return (
    <CardModuleUI>
      <Grid container>
        <Grid mb={1} item xs={12}>
          <Typography
            as="h3"
            fontWeight="600"
            fontSize="1.125rem"
            color="#3A3A3A"
            lineHeight="1.2"
          >
            Estás a un paso de lograr tu primera venta
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography fontSize="0.875rem">
              Compart{idCountry === 'COL' ? 'e' : 'í'} este link de tu tienda en
              tus redes sociales para empezar a vender.
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              id="link_tienda"
              variant="outlined"
              value={domainStore}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Copiar link" placement="left">
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopyLink} size="large">
                        <FilterNoneIcon />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            mt={1.5}
            justifyContent="end"
            direction={{ xs: 'column', sm: 'row-reverse' }}
            gap={1}
          >
            <Button
              variant="contained"
              sx={{ width: { sm: '200px', xs: '100%' } }}
              href={linkButtonTips}
              target="_blank"
            >
              Tips para vender más
            </Button>
            <Button
              variant="text"
              onClick={() => handleSkipTip()}
              sx={{ width: { sm: '200px', xs: '100%' } }}
            >
              Omitir
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};
