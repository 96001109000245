import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paginasAgregar } from '../../store/actions';
import { Card, CardContent, Grid } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  ToggleUI,
  NextActionsUI,
} from '../common';
import { withStyles } from '@mui/styles';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import NewQuillUI from '../common/NewQuillUI';

class PaginaAgregar_ extends Component {
  state = {
    agregar: {
      pa_titulo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Título',
        id: 'pa_titulo',
        name: 'pa_titulo',
        change_param: 'agregar',
        regex: Regex.STRING_GENERICO_2_90,
        value: '',
        default_value: '',
        placeholder: 'Cómo comprar',
        messages: {
          error: 'Por favor, ingrese un título válido',
          error_extra: RegexExtra.STRING_GENERICO_2_90,
        },
      },
      pa_link: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link',
        id: 'pa_link',
        name: 'pa_link',
        change_param: 'agregar',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: 'https://www.empretienda.com/',
        messages: {
          error: 'Por favor, ingrese un link válido',
          error_extra: RegexExtra.LINK,
        },
      },
      pa_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de página',
        id: 'pa_tipo',
        name: 'pa_tipo',
        change_param: 'agregar',
        value: 0,
        messages: {
          error: 'Por favor, seleccione un tipo',
        },
      },
      pa_contenido: {
        validate: false,
        value: '',
      },
    },
    forms: {
      agregar: false,
    },
    after: false,
    selects: {
      tipos: [
        {
          value: 0,
          label: 'Contenido',
        },
        {
          value: 1,
          label: 'Link externo',
        },
      ],
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
        pa_link: {
          ...prevState.agregar.pa_link,
          validate: value ? true : false,
        },
      },
    }));
  };

  handleChangeQuill = (value) => {
    this.setState((prevState) => ({
      agregar: {
        ...prevState.agregar,
        pa_contenido: {
          ...prevState.agregar.pa_contenido,
          value: value,
        },
      },
    }));
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        agregar: true,
      },
    }));

    let form = this.state.agregar;

    if (Validate.validar_formulario(form)) {
      this.props.paginasAgregar(
        {
          pa_titulo: form.pa_titulo.value,
          pa_link: form.pa_link.value,
          pa_tipo: form.pa_tipo.value,
          pa_contenido: form.pa_contenido.value,
        },
        (err, resp) => {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              agregar: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            after: err ? false : true,
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            agregar: false,
          },
          agregar: new_form,
        }));
      });
    }
  };

  render() {
    let classes = this.props.classes;

    return (
      <WrapperLayout loading={false} error={false}>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <ToggleUI show={!this.state.after}>
            <Grid item xs={12}>
              <ModuleTitleUI title="Crear nueva página" />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.agregar.pa_titulo}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectUI
                        data={this.state.agregar.pa_tipo}
                        options={this.state.selects.tipos}
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <ToggleUI show={!this.state.agregar.pa_tipo.value}>
                      <Grid item xs={12}>
                        <NewQuillUI
                          defaultValue={this.state.agregar.pa_contenido.value}
                          handleChangeQuill={this.handleChangeQuill}
                          placeholder={'Escriba aquí...'}
                        />
                      </Grid>
                    </ToggleUI>
                    <ToggleUI show={this.state.agregar.pa_tipo.value}>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.agregar.pa_link}
                        />
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="callf"
                  label="Agregar"
                  minHeight={true}
                  onClickFunc={(e) => this.handleSubmit(e)}
                  isLoading={this.state.forms.agregar}
                />
              </ModuleButtonBoxUI>
            </Grid>
          </ToggleUI>
        </Grid>
        <ToggleUI show={this.state.after}>
          <NextActionsUI
            title="¡La página ha sido creada con éxito!"
            buttons={[
              <ButtonUI type="link" label="Ver mis páginas" link="/paginas" />,
              <ButtonUI
                type="link"
                label="Crear otra página"
                link="/paginas/agregar"
                external_link
              />,
            ]}
          />
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    paginasAgregar: (data, callback) =>
      dispatch(paginasAgregar(data, callback)),
  };
};

export const PaginaAgregar = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PaginaAgregar_)
);
