import React from 'react';
import { Typography, Grid, Icon } from '@mui/material';
import { ButtonUI, ToggleUI } from './';
import { withStyles } from '@mui/styles';

const ModuleTitleUI_ = (props) => {
  const {
    classes,
    title = '',
    subtitles = [],
    image = null,
    inline_image = null,
    back_button = false,
    back_button_link = '',
    image_size = '75px',
    image_width = 'auto',
    button = '',
    subtitles_icon = true,
    beta = false,
    titleMarginRight = 0,
  } = props;

  let subtitles_html = subtitles.map((subtitle, index) => {
    return (
      <Typography className={classes.module_subtitle} key={index}>
        <ToggleUI show={subtitles_icon}>
          <Icon className={classes.module_subtitle_icon}>chevron_right</Icon>
        </ToggleUI>
        {subtitle}
      </Typography>
    );
  });

  let title_html = image ? (
    <div className={classes.module_title_image_box}>
      <img
        src={image}
        style={{ height: image_size, width: image_width }}
        alt="Imagen de modulo"
      />
      <ToggleUI show={beta}>
        <span className={classes.beta_tag}>BETA</span>
      </ToggleUI>
    </div>
  ) : (
    <Typography
      sx={{ marginRight: titleMarginRight }}
      className={classes.module_title}
    >
      {inline_image ? (
        <span className={classes.module_title_image_box}>
          <img
            src={inline_image}
            className={classes.module_inline_image}
            alt="Imagen de modulo"
          />
          {title}
        </span>
      ) : (
        title
      )}
    </Typography>
  );

  if (back_button) {
    title_html = (
      <Grid container spacing={1} alignItems="center">
        <Grid item mr={1}>
          <ButtonUI
            label={<Icon>keyboard_arrow_left</Icon>}
            type="link"
            link={back_button_link}
          />
        </Grid>
        <Grid item>{title_html}</Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.module_box}>
      <div className={classes.module_title_box}>
        {title_html}
        {button}
      </div>
      {subtitles_html}
    </div>
  );
};

const styles = (theme) => ({
  module_box: {
    marginBottom: '15px',
  },
  module_title_box: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  module_title: {
    color: theme.texts.module_title,
    fontSize: '1.4rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem',
    },
  },
  module_subtitle: {
    padding: 0,
    margin: '5px 0px 0px 0px',
    color: theme.texts.module_subtitle,
    fontSize: '0.8rem',
  },
  module_subtitle_icon: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  module_inline_image: {
    height: '2rem',
    marginRight: '5px',
  },
  module_title_image_box: {
    display: 'flex',
    alignItems: 'center',
  },
  beta_tag: {
    backgroundColor: 'red',
    color: '#fff',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginLeft: '5px',
    fontSize: '10px',
    padding: '5px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
      fontSize: '12px',
      padding: '5px',
    },
  },
});

export const ModuleTitleUI = withStyles(styles)(ModuleTitleUI_);
