import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../../../components/common';

const ModalColocarAbonada = (props) => {
  let { handleSubmit, modalHandler, modal_state, form_state, venta } = props;

  let message;

  switch (venta.v_metodo_pago) {
    case 1:
      message = (
        <span>
          Esta acción permite que coloques la orden como abonada.
          <br />
          <br />
          Antes de cambiar el estado del pago{' '}
          <strong>
            corroborá que tengas el dinero acreditado en tu cuenta de Mercado
            Pago o que hayas concretado el pago mediante otra forma.
          </strong>
          <br />
          <br />
          <strong>
            Te recomendamos utilizar esta acción solamente cuando tu cliente no
            finaliza el pago en la tienda y, por ejemplo, le mandas un link de
            pago externo o abona por otro medio.
          </strong>
          <br />
          <br />
          En caso contrario, podés utilizar la acción "Actualización manual de
          estado de pago" para actualizar el estado de pago con el número de
          operación de Mercado Pago.
        </span>
      );
      break;
    case 4:
      message = (
        <span>
          Esta acción permite que coloques la orden como abonada.
          <br />
          Antes de cambiar el estado del pago{' '}
          <strong>
            corroborá que tengas el dinero acreditado en tu cuenta de Todo Pago
            o que hayas concretado el pago mediante otra forma.
          </strong>
          <br />
          <br />
          <strong>
            Te recomendamos utilizar esta acción solamente cuando tu cliente no
            finaliza el pago en la tienda y, por ejemplo, le mandas un link de
            pago externo o abona por otro medio.
          </strong>
          <br />
          <br />
          En caso contrario, te recomendamos esperar a que la orden sea abonada
          por el cliente, ya que probablemente dicho cliente se encuentre
          realizando el proceso de pago.
        </span>
      );
      break;
    case 6:
      message = (
        <span>
          Esta acción permite que coloques la orden como abonada.
          <br />
          Antes de cambiar el estado del pago{' '}
          <strong>
            corroborá que tengas el dinero acreditado en tu cuenta de Mobbex o
            que hayas concretado el pago mediante otra forma.
          </strong>
          <br />
          <br />
          <strong>
            Te recomendamos utilizar esta acción solamente cuando tu cliente no
            finaliza el pago en la tienda y, por ejemplo, le mandas un link de
            pago externo o abona por otro medio.
          </strong>
          <br />
          <br />
          En caso contrario, te recomendamos esperar a que la orden sea abonada
          por el cliente, ya que probablemente dicho cliente se encuentre
          realizando el proceso de pago.
        </span>
      );
      break;
    default:
      message = (
        <span>Esta acción permite que coloques la orden como abonada.</span>
      );
      break;
  }

  return (
    <ModalUI
      open={modal_state}
      id="colocar_abonada"
      title="Colocar como abonada"
      modalHandler={modalHandler}
      aditional_param="colocar_abonada"
      handleSubmit={handleSubmit}
      button_label="Abonada"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Typography>{message}</Typography>
      </Grid>
    </ModalUI>
  );
};

export { ModalColocarAbonada };
