import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const BillingDirection_ = ({ classes, venta, idCountry }) => {
  if (idCountry === 'COL') {
    return (
      <Typography className={classes.info}>
        <span className={classes.info_title}>Dirección:</span>{' '}
        {venta.v_facturacion_calle} {' - '} {venta.v_facturacion_numero}
        {venta.v_facturacion_dpto ? ' ' + venta.v_facturacion_dpto : ''},{' '}
        {venta.v_facturacion_ciudad}, {venta.v_facturacion_provincia}
      </Typography>
    );
  }

  return (
    <>
      <Typography className={classes.info}>
        <span className={classes.info_title}>Dirección:</span>{' '}
        {venta.v_facturacion_calle} {venta.v_facturacion_numero}
        {venta.v_facturacion_piso ? ' ' + venta.v_facturacion_piso : ''}
        {venta.v_facturacion_dpto ? ' ' + venta.v_facturacion_dpto : ''},{' '}
        {venta.v_facturacion_ciudad}, {venta.v_facturacion_provincia}
      </Typography>
      <Typography className={classes.info}>
        <span className={classes.info_title}>Código postal:</span>{' '}
        {venta.v_facturacion_postal}
      </Typography>
    </>
  );
};

const styles = () => ({
  info: {
    fontWeight: '300',
  },
  info_title: {
    fontWeight: '500',
  },
});

export const BillingDirection = withStyles(styles)(BillingDirection_);
