export const colorOptions = [
  {
    id: 1,
    gama: 'Negro',
    primary: {
      background: '#000000',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#1c1c1c',
      text: '#1c1c1c',
      contrastText: '#ffffff',
    },
  },
  {
    id: 2,
    gama: 'Gris',
    primary: {
      background: '#f0f4f1',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f6f6f6',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 3,
    gama: 'Rojo',
    primary: {
      background: '#ea3a3a',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#ed5758',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 4,
    gama: 'Rosa pastel',
    primary: {
      background: '#ffcae6',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 5,
    gama: 'Coral pastel',
    primary: {
      background: '#ffc0cb',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 6,
    gama: 'Celeste pastel',
    primary: {
      background: '#e0ffff',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 7,
    gama: 'Violeta pastel',
    primary: {
      background: '#e8d7ff',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 8,
    gama: 'Verde pastel',
    primary: {
      background: '#ddffe3',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 9,
    gama: 'Amarillo pastel',
    primary: {
      background: '#fcffcc',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 10,
    gama: 'Naranja pastel',
    primary: {
      background: '#ffdcba',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 11,
    gama: 'Azul pastel',
    primary: {
      background: '#cfdbff',
      text: '#000000',
      contrastText: '#333333',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
  {
    id: 12,
    gama: 'Magenta',
    primary: {
      background: '#74113f',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#74113f',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 13,
    gama: 'Verde',
    primary: {
      background: '#00ae66',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#17aa70',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 14,
    gama: 'Celeste',
    primary: {
      background: '#6594ec',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#89cdfa',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 15,
    gama: 'Naranja',
    primary: {
      background: '#ff7f00',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#fc9733',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 16,
    gama: 'Salmón',
    primary: {
      background: '#fa8071',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#dd999a',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 17,
    gama: 'Violeta',
    primary: {
      background: '#622582',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#6e748c',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 18,
    gama: 'Amarillo',
    primary: {
      background: '#ffe164',
      text: '#000000',
      contrastText: '#000000',
    },
    secondary: {
      background: '#1c1c1c',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 19,
    gama: 'Marrón',
    primary: {
      background: '#663200',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#c09a6b',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 20,
    gama: 'Azul',
    primary: {
      background: '#446CB3',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#6f96db',
      text: '#000000',
      contrastText: '#ffffff',
    },
  },
  {
    id: 21,
    gama: 'Verde agua',
    primary: {
      background: '#47d6c4',
      text: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      background: '#f3f3f3',
      text: '#000000',
      contrastText: '#333333',
    },
  },
];

export const tabOptions = [
  {
    value: -1,
    label: 'Personalización',
  },
  {
    value: -3,
    label: 'Plantillas y secciones',
  },
  {
    value: -5,
    label: 'Productos',
  },
  {
    value: -6,
    label: 'Checkout',
  },
];

export const fontOptions = [
  {
    label: 'Alegreya',
    value: 1,
    font_family: "'Alegreya', serif",
    font_setting: 'Alegreya:wght@400;500;700',
  },
  {
    label: 'Asap Condensed',
    value: 2,
    font_family: "'Asap Condensed', sans-serif",
    font_setting: 'Asap+Condensed:wght@400;500;700',
  },
  {
    label: 'Baloo 2',
    value: 3,
    font_family: "'Baloo 2', cursive",
    font_setting: 'Baloo+2:wght@400;500;700',
  },
  {
    label: 'Balsamiq Sans',
    value: 4,
    font_family: "'Balsamiq Sans', cursive",
    font_setting: 'Balsamiq+Sans:wght@400;700',
  },
  {
    label: 'Bitter',
    value: 5,
    font_family: "'Bitter', serif",
    font_setting: 'Bitter:wght@400;700',
  },
  {
    label: 'Cairo',
    value: 6,
    font_family: "'Cairo', sans-serif",
    font_setting: 'Cairo:wght@300;400;700',
  },
  {
    label: 'Heebo',
    value: 7,
    font_family: "'Heebo', sans-serif",
    font_setting: 'Heebo:wght@300;400;500;700',
  },
  {
    label: 'Inconsolata',
    value: 8,
    font_family: "'Inconsolata', monospace",
    font_setting: 'Inconsolata:wght@300;400;500;700',
  },
  {
    label: 'Josefin Sans',
    value: 9,
    font_family: "'Josefin Sans', sans-serif",
    font_setting: 'Josefin+Sans:wght@300;400;500;700',
  },
  {
    label: 'Jost',
    value: 10,
    font_family: "'Jost', sans-serif",
    font_setting: 'Jost:wght@300;400;500;700',
  },
  {
    label: 'Kanit',
    value: 11,
    font_family: "'Kanit', sans-serif",
    font_setting: 'Kanit:wght@300;400;500;700',
  },
  {
    label: 'Lato',
    value: 12,
    font_family: "'Lato', sans-serif",
    font_setting: 'Lato:wght@300;400;700',
  },
  {
    label: 'Libre Baskerville',
    value: 13,
    font_family: "'Libre Baskerville', serif",
    font_setting: 'Libre+Baskerville:wght@400;700',
  },
  {
    label: 'Libre Caslon Text',
    value: 14,
    font_family: "'Libre Caslon Text', serif",
    font_setting: 'Libre+Caslon+Text:wght@400;700',
  },
  {
    label: 'Literata',
    value: 15,
    font_family: "'Literata', serif",
    font_setting: 'Literata:wght@400;500;700',
  },
  {
    label: 'Lora',
    value: 16,
    font_family: "'Lora', serif",
    font_setting: 'Lora:wght@400;500;700',
  },
  {
    label: 'Merriweather',
    value: 17,
    font_family: "'Merriweather', serif",
    font_setting: 'Merriweather:wght@300;400;700',
  },
  {
    label: 'Montserrat',
    value: 18,
    font_family: "'Montserrat', sans-serif",
    font_setting: 'Montserrat:wght@300;400;500;700',
  },
  {
    label: 'Nunito',
    value: 19,
    font_family: "'Nunito', sans-serif",
    font_setting: 'Nunito:wght@300;400;700',
  },
  {
    label: 'Open Sans',
    value: 20,
    font_family: "'Open Sans', sans-serif",
    font_setting: 'Open+Sans:wght@300;400;700',
  },
  {
    label: 'Oswald',
    value: 21,
    font_family: "'Oswald', sans-serif",
    font_setting: 'Oswald:wght@300;400;500;700',
  },
  {
    label: 'Playfair Display',
    value: 22,
    font_family: "'Playfair Display', serif",
    font_setting: 'Playfair+Display:wght@400;500;700',
  },
  {
    label: 'Poppins',
    value: 23,
    font_family: "'Poppins', sans-serif",
    font_setting: 'Poppins:wght@300;400;500;700',
  },
  {
    label: 'Raleway',
    value: 24,
    font_family: "'Raleway', sans-serif",
    font_setting: 'Raleway:wght@300;400;500;700',
  },
  {
    label: 'Roboto',
    value: 25,
    font_family: "'Roboto', sans-serif",
    font_setting: 'Roboto:wght@300;400;500;700',
  },
  {
    label: 'Rubik',
    value: 26,
    font_family: "'Rubik', sans-serif",
    font_setting: 'Rubik:wght@300;400;500;700',
  },
  {
    label: 'Teko',
    value: 27,
    font_family: "'Teko', sans-serif",
    font_setting: 'Teko:wght@300;400;500;700',
  },
  {
    label: 'Titillium Web',
    value: 28,
    font_family: "'Titillium Web', sans-serif",
    font_setting: 'Titillium+Web:wght@300;400;700',
  },
  {
    label: 'Trirong',
    value: 29,
    font_family: "'Trirong', serif",
    font_setting: 'Trirong:wght@300;400;500;700',
  },
  {
    label: 'Work Sans',
    value: 30,
    font_family: "'Work Sans', sans-serif",
    font_setting: 'Work+Sans:wght@300;400;500;700',
  },
];

export const productsFeedOrder = [
  {
    value: 0,
    label: 'Más nuevo a más viejo',
  },
  {
    value: 1,
    label: 'Más viejo a más nuevo',
  },
  {
    value: 2,
    label: 'Precio menor a mayor',
  },
  {
    value: 3,
    label: 'Precio mayor a menor',
  },
  {
    value: 4,
    label: 'Orden establecido',
  },
];

export const installmentsOptions = [
  {
    value: 3,
    label: '3 cuotas sin interés',
  },
  {
    value: 6,
    label: '6 cuotas sin interés',
  },
  {
    value: 9,
    label: '9 cuotas sin interés',
  },
  {
    value: 12,
    label: '12 cuotas sin interés',
  },
  {
    value: 18,
    label: '18 cuotas sin interés',
  },
];

export const borderRadiusOptions = [
  {
    value: 0,
    label: 'Recto',
  },
  {
    value: 3,
    label: 'Redondeado pequeño',
  },
  {
    value: 6,
    label: 'Redondeado normal',
  },
  {
    value: 10,
    label: 'Redondeado grande',
  },
  {
    value: 20,
    label: 'Redondeado muy grande',
  },
];

export const addBlockOptions = [
  {
    value: 0,
    label: 'Seleccionar una sección',
  },
  {
    value: 1,
    label: 'Carrusel de banners',
  },
  {
    value: 2,
    label: 'Feed principal de productos',
  },
  {
    value: 3,
    label: 'Conjunto de productos',
  },
  {
    value: 4,
    label: 'Lista de categorías',
  },
  {
    value: 5,
    label: 'Galería',
  },
  {
    value: 6,
    label: 'Imágenes con link',
  },
  {
    value: 11,
    label: 'Grillas de imágenes con link',
  },
  {
    value: 7,
    label: 'Texto',
  },
  {
    value: 8,
    label: 'Información de compra',
  },
  {
    value: 9,
    label: 'Imagen con texto y botón',
  },
  {
    value: 10,
    label: 'Código HTML (avanzado)',
  },
];

export const purchaseInformationIconOptions = [
  {
    value: 0,
    label: 'Envío',
  },
  {
    value: 1,
    label: 'Pago online',
  },
  {
    value: 2,
    label: 'Pago seguro',
  },
  {
    value: 3,
    label: 'Whatsapp',
  },
  {
    value: 4,
    label: 'Pago efectivo',
  },
  {
    value: 5,
    label: 'Compra en casa',
  },
  {
    value: 6,
    label: 'Descuento / Oferta',
  },
  {
    value: 7,
    label: 'Devolución',
  },
  {
    value: 8,
    label: 'Dirección / Punto de entrega',
  },
  {
    value: 9,
    label: 'Email',
  },
  {
    value: 10,
    label: 'Teléfono',
  },
  {
    value: 11,
    label: 'Pago transferencia',
  },
];

export const blockAlignmentOptions = [
  {
    value: 0,
    label: 'Centrado',
  },
  {
    value: 1,
    label: 'Izquierda',
  },
  {
    value: 2,
    label: 'Derecha',
  },
  {
    value: 3,
    label: 'Justificado',
  },
];

export const blockSpacingOptions = [
  {
    value: 0,
    label: 'Muy grande',
  },
  {
    value: 1,
    label: 'Grande',
  },
  {
    value: 2,
    label: 'Normal',
  },
  {
    value: 3,
    label: 'Pequeño',
  },
  {
    value: 4,
    label: 'Muy pequeño',
  },
  {
    value: 5,
    label: 'Sin espaciado',
  },
];

export const mobileRowElementsQuantity = [
  {
    value: 1,
    label: '1 por fila',
  },
  {
    value: 2,
    label: '2 por fila',
  },
];

export const rowElementsQuantity = [
  {
    value: 2,
    label: '2 por fila',
  },
  {
    value: 3,
    label: '3 por fila',
  },
  {
    value: 4,
    label: '4 por fila',
  },
];

export const imagesGapOptions = [
  {
    value: 0,
    label: 'Grande',
  },
  {
    value: 1,
    label: 'Normal',
  },
  {
    value: 2,
    label: 'Pequeño',
  },
  {
    value: 3,
    label: 'Sin espaciado',
  },
];

export const yesOrNoOptions = [
  {
    value: 1,
    label: 'Si',
  },
  {
    value: 0,
    label: 'No',
  },
];
