import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const enviopersonalizadoObtenerEnviosPersonalizados = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/envio-personalizado')
      .then((resp) => {
        //guardo en redux
        const enviospe = resp.data.data;
        dispatch({
          type: Reducers.ENVIOPERSONALIZADO_GUARDAR,
          data: enviospe,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const enviopersonalizadoObtenerEnvioPersonalizado = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(
        `${Config.BACKEND_ENDPOINT}/envio-personalizado/${data.idEnviosPersonalizados}`
      )
      .then((resp) => {
        //guardo en redux
        const enviope = resp.data.data;
        dispatch({
          type: Reducers.ENVIOPERSONALIZADO_GUARDAR,
          data: enviope,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const enviopersonalizadoAgregar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/envio-personalizado', data)
      .then((resp) => {
        //guardo en redux
        const nuevo_enviope = resp.data.data;
        dispatch({
          type: Reducers.ENVIOPERSONALIZADO_GUARDAR,
          data: nuevo_enviope,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const enviopersonalizadoEditar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/envio-personalizado/${data.idEnviosPersonalizados}`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const enviope = resp.data.data;
        dispatch({
          type: Reducers.ENVIOPERSONALIZADO_GUARDAR,
          data: enviope,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const enviopersonalizadoBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(
        `${Config.BACKEND_ENDPOINT}/envio-personalizado/${data.idEnviosPersonalizados}`
      )
      .then((resp) => {
        //guardo en redux
        const enviope = resp.data.data;
        dispatch({
          type: Reducers.ENVIOPERSONALIZADO_BORRAR,
          data: enviope,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  enviopersonalizadoObtenerEnviosPersonalizados,
  enviopersonalizadoObtenerEnvioPersonalizado,
  enviopersonalizadoAgregar,
  enviopersonalizadoEditar,
  enviopersonalizadoBorrar,
};
