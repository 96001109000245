import React from 'react';
import { Config } from './../../../other';

export const LogoUalaBis = ({ width, style }) => (
  <img
    style={style}
    width={width}
    src={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/uala-card-img.png`}
  />
);
