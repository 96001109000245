import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, ModuleCardTitleUI, CardModuleUI } from '../../common';

const DominioMetodoPago = (props) => {
  let tienda = props.tienda;

  return (
    <CardModuleUI below>
      <Grid container spacing={1.5}>
        <ModuleCardTitleUI
          title={
            'Para poder cargar un dominio propio que hayas comprado/registrado en algun proveedor externo, por ejemplo: tumarca.com.ar, debes seleccionar un medio de pago.' +
            (tienda.t_prueba ? ' No vas a perder tus días de prueba.' : '')
          }
        />
        <Grid item xs={12}>
          <ButtonUI
            type="link"
            label="Ver plan"
            fullWidth={false}
            link="/plan"
          />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};

export { DominioMetodoPago };
