import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, DateTimeUI } from '../../../components/common';

export const ModalPeriodo = (props) => {
  let {
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
    handleChangeDate,
    periodo_desde,
    periodo_hasta,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="seleccionar_periodo"
      title="Seleccionar período"
      modalHandler={modalHandler}
      aditional_param="seleccionar_periodo"
      handleSubmit={handleSubmit}
      button_label="Aplicar"
      button_loading={form_state}
    >
      <Grid container>
        <Grid item xs={12} py={1.5}>
          <DateTimeUI
            handleChangeDate={handleChangeDate}
            inputdate={periodo_desde}
          />
        </Grid>
        <Grid item xs={12} py={1.5}>
          <DateTimeUI
            handleChangeDate={handleChangeDate}
            inputdate={periodo_hasta}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};
