/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, CustomCardUI } from '../../../components/common';
import { Config } from '../../../other';

const OCA = (props) => {
  const { metodos_envio } = props;

  return (
    <Grid item lg={4} sm={6} xs={12}>
      <CustomCardUI
        title="OCA Epak"
        subtitle={metodos_envio.me_oca ? 'Método activo' : 'Método no activo'}
        info={
          <span>
            Es un servicio integral para vender los productos a través de tu
            tienda online hacia todo el país. Primero deberás solicitar una
            cuenta en OCA Epak, podés hacerlo haciendo click en "Configuración"{' '}
            {'>'} "Solicitar cuenta"
          </span>
        }
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_envio/oca-card-img.png`}
      >
        <ButtonUI
          type="link"
          link="/metodos-envio/oca"
          label="Configuración"
          size="large"
        />
      </CustomCardUI>
    </Grid>
  );
};

export { OCA };
