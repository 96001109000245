import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  tiendaObtenerMiPlan,
  tiendaActualizarDatosFacturacion,
  tiendaAdherirPagoManual,
  tiendaPagarFactura,
  tiendaObtenerMiPlanWithCode,
  tiendaInformarPago,
  tiendaDesactivarMediosPagoSuscripcion,
} from '../../store/actions';
import { withStyles } from '@mui/styles';
import { AlertUI, ModuleTitleUI } from '../common';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  ModalDatosFacturacion,
  ModalCambiarMetodo,
  ModalInformarPago,
  ModalPagarFactura,
} from './subcomponents';
import {
  suscription_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import {
  connectSplit,
  selectTreatmentValue,
} from '@splitsoftware/splitio-redux';
import { MiPlan } from './MiPlan';
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom.min';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class Plan_ extends Component {
  state = {
    splitio: {
      treatment: '',
      timeout_flag: false,
    },
    loading_splitio: true,
    loading_plan: true,
    loading_error: false,
    alerta_status_debito: 0,
    alerta_status_pago_manual: 0,
    facturacion: 0,
    factura: {
      id: 0,
      monto: 0,
      fecha_vencimiento: null,
      dias_vencimiento: 0,
      fecha_corte: null,
      dias_corte: 0,
      paga: 0,
      cerrada: 0,
      fecha_pago: null,
      periodo_prueba: 0,
    },
    facturas: [],
    datosfacturacion: {
      df_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre completo / Razón social',
        id: 'df_nombre',
        name: 'df_nombre',
        change_param: 'datosfacturacion',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese una razón social válida',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      df_condicion: {
        validate: false,
        value: '',
      },
      df_documento: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'CUIT/DNI',
        id: 'df_documento',
        name: 'df_documento',
        change_param: 'datosfacturacion',
        regex: Regex.CUIT,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          help: 'Lo usaremos para enviarte la factura de la suscripción cada vez que ingrese un pago',
          error: 'Por favor, ingrese un DNI o CUIT válido',
          error_extra: RegexExtra.CUIT,
        },
      },
    },
    informar_pago: {
      idFacturas: {
        validate: false,
        value: 0,
      },
      numero_operacion: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Número de operación',
        id: 'numero_operacion',
        name: 'numero_operacion',
        change_param: 'informar_pago',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: 'XXXXXXXXXXX',
        messages: {
          error:
            'Por favor, ingrese un número de operación de Mercado Pago válido',
          help: 'Es el número de operación de Mercado Pago de 11 dígitos que figura en Mercado Pago (no es la referencia externa).',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
    },
    forms: {
      datosfacturacion: false,
      pago_manual: false,
      desactivar_metodo: false,
      pagar_factura: false,
      informar_pago: false,
    },
    pagar_factura_id: 0,
    pagar_factura_metodo: 1,
    modals: {
      datosfacturacion: false,
      datosfacturacion_close: false,
      desactivar_metodo: false,
      informar_pago: false,
      pagar_factura: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    let { collection_id, pago } = queryString.parse(this.props.location.search);
    if (collection_id && pago) {
      // Pago recibido desde Mercado pago
      this.props.tiendaObtenerMiPlanWithCode(
        {
          id: collection_id,
        },
        (err, resp) => {
          let datosfacturacion = Validate.cargar_formulario_sin_nulos_sync(
            resp.data.datosfacturacion,
            this.state.datosfacturacion
          );
          let factura = resp.data.factura;
          let facturas = resp.data.facturas;
          let facturacion = resp.data.facturacion;

          if (pago === 'exito') {
            this.setState(
              (prevState) => ({
                loading_plan: false,
                loading_error: err,
                datosfacturacion: datosfacturacion,
                facturacion: facturacion,
                factura: factura,
                facturas: facturas,
                alert: {
                  ...prevState.alert,
                  open: true,
                  message:
                    '¡Tu pago ha sido procesado con éxito, muchas gracias!',
                  type: 'success',
                },
              }),
              () => this.registerManualPaymentEvents(facturas)
            );
          } else {
            this.setState({
              loading_plan: false,
              loading_error: err,
              datosfacturacion: datosfacturacion,
              facturacion: facturacion,
              factura: factura,
              facturas: facturas,
            });
          }
        }
      );
    } else {
      let alert = this.state.alert;
      let timeout = 10;
      if (pago) {
        if (pago === 'exito_bis') {
          // Pago recibido de bis exitosamente
          timeout = 9000;
          alert = {
            ...this.state.alert,
            open: true,
            message: '¡Tu pago ha sido procesado con éxito, muchas gracias!',
            type: 'success',
          };
        } else if (pago === 'error_bis') {
          // Pago recibido de bis con error
          alert = {
            ...this.state.alert,
            open: true,
            message: 'Ha ocurrido un error con el pago, intente nuevamente',
            type: 'error',
          };
        }
      }

      setTimeout(() => {
        this.setState(
          {
            alert: alert,
          },
          () => {
            this.props.tiendaObtenerMiPlan((err, resp) => {
              let datosfacturacion = Validate.cargar_formulario_sin_nulos_sync(
                resp.data.datosfacturacion,
                this.state.datosfacturacion
              );
              let factura = resp.data.factura;
              let facturas = resp.data.facturas;
              let facturacion = resp.data.facturacion;

              if (pago === 'exito_bis') {
                // Pago recibido de bis exitosamente (registra evento)
                this.registerManualPaymentEvents(facturas);
              }

              this.setState({
                loading_plan: false,
                loading_error: err,
                datosfacturacion: datosfacturacion,
                facturacion: facturacion,
                factura: factura,
                facturas: facturas,
              });
            });
          }
        );
      }, timeout);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.tienda &&
      this.props.tienda.idTienda &&
      this.state.loading_splitio &&
      this.props.splitio &&
      this.props.splitio.isReady &&
      (prevProps.splitio.lastUpdate > 0 ||
        prevProps.splitio.lastUpdate < this.props.splitio.lastUpdate)
    ) {
      const trment = selectTreatmentValue(
        this.props.splitio,
        'feature_flag_empretienda_panel_mi_plan_debito_automatico',
        this.props.tienda.idTienda
      );

      if (trment && trment !== 'control') {
        this.setState({
          loading_splitio: false,
          splitio: { treatment: trment },
        });
      } else {
        if (!this.state.splitio.timeout_flag) {
          setTimeout(() => {
            const treatment = selectTreatmentValue(
              this.props.splitio,
              'feature_flag_empretienda_panel_mi_plan_debito_automatico',
              this.props.tienda.idTienda
            );

            if (treatment === 'control') {
              this.setState({
                loading_splitio: false,
                splitio: { treatment: 'off' },
              });
            }
          }, 5000);

          this.setState({
            splitio: { timeout_flag: true },
          });
        }
      }
    }
  }

  registerManualPaymentEvents = (facturas) => {
    if (typeof window !== 'undefined' && facturas.length > 0) {
      let monto_factura_abonada = facturas[facturas.length - 1].monto;

      const idCountry = this.props.tienda.Country_idCountry;

      const currency = idCountry === 'COL' ? 'COP' : 'ARS';
      if (window.fbq) {
        if (facturas.length === 1) {
          window.fbq('track', 'Subscribe', {
            currency: currency,
            value: monto_factura_abonada,
          });
        }

        window.fbq('track', 'Purchase', {
          currency: currency,
          value: monto_factura_abonada,
        });
      }
      if (window.gtag) {
        if (facturas.length === 1) {
          window.gtag('event', 'conversion', {
            event_category: 'Suscripción',
            event_label: 'Primer pago manual',
            value: monto_factura_abonada,
            currency: currency,
            transaction_id: '',
          });
        }
        window.gtag('event', 'conversion', {
          event_category: 'Suscripción',
          event_label: 'Pago manual',
          value: monto_factura_abonada,
          currency: currency,
          transaction_id: '',
        });
      }
    }
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        modals: {
          ...prevState.modals,
          datosfacturacion_close: false,
        },
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSeleccionarMetodoPagoManual = (pagar_factura_metodo) => {
    this.setState({ pagar_factura_metodo: pagar_factura_metodo });
  };

  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'informar_pago':
        if (open) {
          let factura = aditional;
          this.setState((prevState) => ({
            informar_pago: {
              ...prevState.informar_pago,
              idFacturas: {
                ...prevState.informar_pago.idFacturas,
                value: factura.id,
              },
            },
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        } else {
          this.setState((prevState) => ({
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        }
        break;

      case 'pagar_factura':
        this.setState((prevState) => ({
          modals: {
            ...prevState.modals,
            [id]: open,
          },
          pagar_factura_id: open ? aditional.id : 0,
          pagar_factura_metodo: null,
        }));
        break;

      default:
        this.setState((prevState) => ({
          modals: {
            ...prevState.modals,
            datosfacturacion_close: open
              ? prevState.datosfacturacion_close
              : false,
            [id]: open,
          },
        }));
        break;
    }
  };

  handleSubmitActualizarDatosFacturacion = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        datosfacturacion: true,
      },
    }));

    let form = this.state.datosfacturacion;

    if (Validate.validar_formulario(form)) {
      let doc_len = form.df_documento.value.length;
      if (doc_len === 11 || doc_len === 8 || doc_len === 7) {
        this.props.tiendaActualizarDatosFacturacion(
          {
            df_documento: form.df_documento.value,
            df_nombre: form.df_nombre.value,
          },
          (err, resp) => {
            if (err) {
              this.setState((prevState) => ({
                forms: {
                  ...prevState.forms,
                  datosfacturacion: false,
                },
                alert: {
                  ...prevState.alert,
                  open: true,
                  message: resp.message,
                  type: 'error',
                },
              }));
            } else {
              this.setState(
                (prevState) => ({
                  datosfacturacion: Validate.cargar_formulario_sin_nulos_sync(
                    resp.data,
                    prevState.datosfacturacion
                  ),
                  facturacion: 1,
                  forms: {
                    ...prevState.forms,
                    datosfacturacion: false,
                  },
                  modals: {
                    ...prevState.modals,
                    datosfacturacion_close: true,
                  },
                  alert: {
                    ...prevState.alert,
                    open: true,
                    message: resp.message,
                    type: 'success',
                  },
                }),
                () => {
                  if (!err) {
                    createAmplitudeEventWithDevice(
                      suscription_amplitude_events
                        .plan_datos_facturacion_guardar.event_name,
                      { success: true }
                    );
                  } else {
                    createAmplitudeEventWithDevice(
                      suscription_amplitude_events
                        .plan_datos_facturacion_guardar.event_name,
                      { success: false }
                    );
                  }
                }
              );
            }
          }
        );
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            datosfacturacion: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message: 'Por favor, ingrese un documento válido',
            type: 'error',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            datosfacturacion: false,
          },
          datosfacturacion: new_form,
        }));
      });
    }
  };

  handleSubmitAdherirPagoManual = () => {
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        pago_manual: true,
      },
    }));
    this.props.tiendaAdherirPagoManual((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            pago_manual: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message: resp.message,
            type: 'error',
          },
        }));
      } else {
        let message_last = resp.message;
        //todo salio ok, traigo todo para actualizar
        //revisar esto
        this.props.tiendaObtenerMiPlan((err, resp) => {
          let datosfacturacion = Validate.cargar_formulario_sin_nulos_sync(
            resp.data.datosfacturacion,
            this.state.datosfacturacion
          );
          let factura = resp.data.factura;
          let facturas = resp.data.facturas;
          let facturacion = resp.data.facturacion;

          this.setState(
            (prevState) => ({
              loading_plan: false,
              loading_error: err,
              datosfacturacion: datosfacturacion,
              facturacion: facturacion,
              factura: factura,
              facturas: facturas,
              forms: {
                ...prevState.forms,
                pago_manual: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: message_last,
                type: 'success',
              },
            }),
            () => {
              createAmplitudeEventWithDevice(
                suscription_amplitude_events
                  .plan_configuracion_metodo_pago_seleccionar.event_name
              );
            }
          );
        });
      }
    });
  };

  handleSubmitPagarFactura = (e) => {
    e.preventDefault();
    let id = this.state.pagar_factura_id;
    let metodo = this.state.pagar_factura_metodo;
    if (metodo !== null) {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          pagar_factura: true,
        },
      }));

      this.props.tiendaPagarFactura(
        {
          id: id,
          metodo: metodo,
        },
        (err, resp) => {
          if (err) {
            this.setState((prevState) => ({
              forms: {
                ...prevState.forms,
                pagar_factura: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            }));
          } else {
            this.setState(
              (prevState) => ({
                forms: {
                  ...prevState.forms,
                  pagar_factura: false,
                },
                modals: {
                  ...prevState.modals,
                  pagar_factura: false,
                },
              }),
              () => {
                window.location.replace(resp.data.url);
              }
            );
          }
        }
      );
    } else {
      this.setState((prevState) => ({
        alert: {
          ...prevState.alert,
          open: true,
          message: 'Debes seleccionar un método de pago',
          type: 'error',
        },
      }));
    }
  };

  handleSubmitInformarPago = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        informar_pago: true,
      },
    }));

    let form = this.state.informar_pago;

    if (Validate.validar_formulario(form)) {
      this.props.tiendaInformarPago(
        {
          idFacturas: form.idFacturas.value,
          numero_operacion: form.numero_operacion.value,
        },
        (err, resp) => {
          let facturas = err
            ? this.state.facturas
            : this.state.facturas.map((factura) => {
                if (factura.id === form.idFacturas.value) {
                  factura.paga = 1;
                }

                return factura;
              });

          this.setState(
            (prevState) => ({
              facturas: facturas,
              forms: {
                ...prevState.forms,
                informar_pago: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              modals: {
                ...prevState.modals,
                informar_pago: err ? true : false,
              },
            }),
            () => {
              if (!err) {
                createAmplitudeEventWithDevice(
                  suscription_amplitude_events.plan_selecciona_informar_pago
                    .event_name
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            informar_pago: false,
          },
          informar_pago: new_form,
        }));
      });
    }
  };

  handleDesactivarMetodoPago = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        desactivar_metodo: true,
      },
    }));
    this.props.tiendaDesactivarMediosPagoSuscripcion((err, resp) => {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          desactivar_metodo: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
        modals: {
          ...prevState.modals,
          desactivar_metodo: err ? true : false,
        },
      }));
    });
  };

  render() {
    return (
      <WrapperLayout
        loading={this.state.loading_plan || this.state.loading_splitio}
        error={this.state.loading_error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
          hideSeconds={5000}
        />
        <ModalDatosFacturacion
          datosfacturacion={this.state.datosfacturacion}
          handleSubmit={this.handleSubmitActualizarDatosFacturacion}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.datosfacturacion}
          form_state={this.state.forms.datosfacturacion}
          datosfacturacion_close={this.state.modals.datosfacturacion_close}
        />
        <ModalCambiarMetodo
          handleSubmit={this.handleDesactivarMetodoPago}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.desactivar_metodo}
          form_state={this.state.forms.desactivar_metodo}
        />
        <ModalInformarPago
          informar_pago={this.state.informar_pago}
          handleSubmit={this.handleSubmitInformarPago}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.informar_pago}
          form_state={this.state.forms.informar_pago}
        />
        <ModalPagarFactura
          pagar_factura_metodo={this.state.pagar_factura_metodo}
          handleSubmit={this.handleSubmitPagarFactura}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.pagar_factura}
          form_state={this.state.forms.pagar_factura}
          handleSeleccionarMetodoPagoManual={
            this.handleSeleccionarMetodoPagoManual
          }
        />

        {this.state.facturacion === 0 && this.props.tienda.t_deuda === 1 ? (
          <Route
            render={(props) => (
              <Redirect
                to={{
                  pathname: `/plan/reactivacion`,
                  state: { from: props.location },
                }}
              />
            )}
          />
        ) : this.state.facturacion === 0 ? (
          <Route
            render={(props) => (
              <Redirect
                to={{
                  pathname: `/plan/datos-facturacion`,
                  state: { from: props.location },
                }}
              />
            )}
          />
        ) : (
          <>
            <ModuleTitleUI title="Mi plan" />
            <MiPlan />
          </>
        )}
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  hello_title: {
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'center',
    fontSize: '1.4rem',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7rem',
    },
  },
  hello_title_red: {
    color: '#f50057',
    textAlign: 'center',
    fontSize: '1.4rem',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  },
  hello_subtitle: {
    padding: 0,
    margin: '5px 0px 0px 0px',
    color: 'rgba(0,0,0,0.7)',
    textAlign: 'center',
    fontSize: '0.9rem',
  },
  payment_method_title: {
    color: 'rgba(0,0,0,0.7) !important',
    textAlign: 'center',
    fontSize: '1.2rem',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    },
  },
  payment_method_image: {
    display: 'block',
    width: '150px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
    },
  },
  payment_method_subtitle: {
    margin: '0px 0px 20px 0px',
    color: 'rgba(0,0,0,0.7)',
    textAlign: 'center',
    fontSize: '1rem',
  },
  payment_method_tip: {
    margin: '0px 0px 30px 0px',
    color: '#9e9e9e',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    splitio: state.splitio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtenerMiPlan: (callback) => dispatch(tiendaObtenerMiPlan(callback)),
    tiendaObtenerMiPlanWithCode: (data, callback) =>
      dispatch(tiendaObtenerMiPlanWithCode(data, callback)),
    tiendaActualizarDatosFacturacion: (data, callback) =>
      dispatch(tiendaActualizarDatosFacturacion(data, callback)),
    tiendaAdherirPagoManual: (callback) =>
      dispatch(tiendaAdherirPagoManual(callback)),
    tiendaDesactivarMediosPagoSuscripcion: (callback) =>
      dispatch(tiendaDesactivarMediosPagoSuscripcion(callback)),
    tiendaPagarFactura: (data, callback) =>
      dispatch(tiendaPagarFactura(data, callback)),
    tiendaInformarPago: (data, callback) =>
      dispatch(tiendaInformarPago(data, callback)),
  };
};

export const Plan = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(connectSplit()(Plan_))
);
