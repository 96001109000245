import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import { ToggleUI } from '../../../components/common';

const UserMenuLayout_ = ({
  history,
  anchorEl,
  profileMenuOpen,
  handleProfileMenu,
  idCountry,
  logout,
  setAvoidDrawerLogic,
  setForceRenderDrawer,
}) => {
  return (
    <Menu
      style={{ top: '40px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={profileMenuOpen}
      onClose={(e) => handleProfileMenu(e, 'close')}
    >
      <MenuItem
        onClick={(e) => {
          handleProfileMenu(e, 'close');
          setAvoidDrawerLogic(true);
          setForceRenderDrawer((prev) => !prev);
          history.push(`/plan`);
        }}
      >
        Mi plan
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleProfileMenu(e, 'close');
          setAvoidDrawerLogic(true);
          setForceRenderDrawer((prev) => !prev);
          history.push(`/dominios`);
        }}
      >
        Configurar dominio
      </MenuItem>
      <ToggleUI show={idCountry === 'ARG'}>
        <MenuItem
          onClick={(e) => {
            handleProfileMenu(e, 'close');
            setAvoidDrawerLogic(true);
            setForceRenderDrawer((prev) => !prev);
            history.push(`/data-fiscal`);
          }}
        >
          Data fiscal
        </MenuItem>
      </ToggleUI>
      <MenuItem
        onClick={(e) => {
          createAmplitudeEventWithDevice(
            amplitude_events.selecciona_salir.event_name
          );
          handleProfileMenu(e, 'close');
          localStorage.setItem('country', idCountry);
          logout();
        }}
      >
        Cerrar Sesión
      </MenuItem>
    </Menu>
  );
};

export const UserMenuLayout = UserMenuLayout_;
