import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ModalUI, ToggleUI } from '../../common';
import { Config } from '../../../other';

const Selector = ({ selected }) => {
  return (
    <div>
      <ToggleUI show={selected}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="#53B755" />
          <circle cx="8" cy="8" r="4" fill="white" />
        </svg>
      </ToggleUI>
      <ToggleUI show={!selected}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="white" />
          <circle cx="8" cy="8" r="7.5" stroke="black" strokeOpacity="0.12" />
        </svg>
      </ToggleUI>
    </div>
  );
};

const ModalPagarFactura_ = (props) => {
  let {
    classes,
    pagar_factura_metodo,
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
    handleSeleccionarMetodoPagoManual,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="pagar_factura"
      title="Seleccionar método de pago"
      modalHandler={modalHandler}
      aditional_param="pagar_factura"
      handleSubmit={handleSubmit}
      button_label="Pagar"
      button_loading={form_state}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          className={classes.wrapper}
          onClick={() => handleSeleccionarMetodoPagoManual(1)}
        >
          <div
            className={
              pagar_factura_metodo === 1 ? classes.card_selected : classes.card
            }
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Selector selected={pagar_factura_metodo === 1} />
              </Grid>
              <Grid item>
                <img
                  src={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/uala-card-img.png`}
                  className={classes.payment_method_image}
                  alt="Metodo imagen"
                />
                <div className={classes.payment_method_image_mini_wrapper}>
                  <Grid container spacing={0.5}>
                    <Grid item>
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/iconos-pago/mastercard.png`}
                        className={classes.payment_method_image_mini}
                        alt="Metodo imagen"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/iconos-pago/visa.png`}
                        className={classes.payment_method_image_mini}
                        alt="Metodo imagen"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/iconos-pago/american-express.png`}
                        className={classes.payment_method_image_mini}
                        alt="Metodo imagen"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/iconos-pago/naranja.png`}
                        className={classes.payment_method_image_mini}
                        alt="Metodo imagen"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/iconos-pago/cabal.png`}
                        className={classes.payment_method_image_mini}
                        alt="Metodo imagen"
                      />
                    </Grid>
                    <Grid item>
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/iconos-pago/maestro.png`}
                        className={classes.payment_method_image_mini}
                        alt="Metodo imagen"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.wrapper}
          onClick={() => handleSeleccionarMetodoPagoManual(0)}
        >
          <div
            className={
              pagar_factura_metodo === 0 ? classes.card_selected : classes.card
            }
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Selector selected={pagar_factura_metodo === 0} />
              </Grid>
              <Grid item>
                <img
                  src={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/mercadopago-card-img.png`}
                  className={classes.payment_method_image}
                  alt="Metodo imagen"
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

const styles = (theme) => ({
  card: {
    backgroundColor: '#fff',
    padding: '16px 16px',
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  card_selected: {
    backgroundColor: '#fff',
    padding: '16px 16px',
    boxSizing: 'border-box',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
  wrapper: {
    cursor: 'pointer',
  },
  payment_method_image: {
    height: '60px',
  },
  payment_method_image_mini_wrapper: {
    marginLeft: '8px',
  },
  payment_method_image_mini: {
    height: '15px',
  },
});

export const ModalPagarFactura = withStyles(styles, { withTheme: true })(
  ModalPagarFactura_
);
