import React from 'react';
import { Switch } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { withStyles } from '@mui/styles';

const SwitchUI_ = ({ classes, handleChange, isLoading = false, ...props }) => (
  <>
    {isLoading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 48,
          width: 68,
          marginRight: '6px',
        }}
      >
        <Skeleton height={20} width={48} />
      </div>
    ) : (
      <Switch
        {...props}
        onChange={handleChange}
        classes={{
          switchBase: classes.colorSwitchBase,
          checked: classes.colorChecked,
          bar: classes.colorBar,
        }}
      />
    )}
  </>
);

const styles = () => ({
  colorSwitchBase: {
    color: '#BDBDBD',
    '& + $colorBar': {
      backgroundColor: '#E3E3E3',
    },
    '&$colorChecked': {
      color: '#53B755',
      '& + $colorBar': {
        backgroundColor: '#BDE3BD',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

export const SwitchUI = withStyles(styles)(SwitchUI_);
