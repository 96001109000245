/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    DialogUI is a react function component based on Material UI 
    which is used to handle modals with ease.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@mui/material';

const DialogUI = (props) => {
  let { children, title, dialog_state, dialogHandler, id, aditional_param } =
    props;

  return (
    <Dialog
      open={dialog_state}
      onClose={() => dialogHandler(false, id, aditional_param)}
      transitionDuration={{ enter: 300, exit: 300 }}
    >
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
};

DialogUI.propTypes = {
  //true to open the dialog
  dialog_state: PropTypes.bool.isRequired,

  //The title of the dialog
  title: PropTypes.string.isRequired,

  //The function which opens and closes the dialog
  //Called: dialogHandler(open,id,aditional_param)
  dialogHandler: PropTypes.func.isRequired,

  //The id of the dialog
  id: PropTypes.string.isRequired,

  //The aditional param which
  //is passed in dialogHandler
  //Default: null
  aditional_param: PropTypes.any,

  //The content of the modal
  children: PropTypes.node.isRequired,
};

export { DialogUI };
