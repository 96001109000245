import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../common';

const ModalDesactivarActivar = (props) => {
  let { desactivar, handleSubmit, modalHandler, modal_state, form_state } =
    props;

  return (
    <ModalUI
      open={modal_state}
      id="desactivar"
      title={
        desactivar.p_desactivado ? 'Activar producto' : 'Desactivar producto'
      }
      modalHandler={modalHandler}
      aditional_param="desactivar"
      handleSubmit={handleSubmit}
      button_label={desactivar.p_desactivado ? 'Activar' : 'Desactivar'}
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            {desactivar.p_desactivado
              ? 'Al activar el producto "' +
                desactivar.p_nombre +
                '", volverá a ser visible en la tienda.'
              : 'Al desactivar el producto "' +
                desactivar.p_nombre +
                '" dejará de ser visible en la tienda hasta volver a activarlo. No se eliminará ni se borrarán las configuraciones realizadas.'}
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalDesactivarActivar };
