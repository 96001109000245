import React from 'react';
import { Icon, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import { ModalUI } from '../../../common';
import { Config } from '../../../../other';

const ModalVarianteImagen_ = (props) => {
  let {
    handlePreChangeVarianteImagen,
    imagen_variante,
    handleSubmit,
    modalHandler,
    modal_state,
    classes,
    fotos,
  } = props;

  let { link, variante } = imagen_variante;

  let html = fotos.files.map((foto, index) => {
    if (foto.link === link) {
      return (
        <Grid item sm={2} xs={4} key={index}>
          <div className={classes.image_container}>
            <span className={classes.check}>
              <Icon>check</Icon>
            </span>
            <img
              src={Config.CLOUDFRONT_PRODUCTS_CDN + foto.link}
              className={classes.image}
              alt="Imagen de atributo"
            />
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item sm={2} xs={4} key={index}>
          <div
            className={classes.image_container}
            onClick={() => handlePreChangeVarianteImagen(foto.link)}
          >
            <img
              src={Config.CLOUDFRONT_PRODUCTS_CDN + foto.link}
              className={classes.image_with_overlay}
              alt="Imagen de atributo"
            />
          </div>
        </Grid>
      );
    }
  });

  return (
    <ModalUI
      open={modal_state}
      id="imagen_variante"
      title="Cambiar imagen"
      subtitle={'Variante ' + variante}
      modalHandler={modalHandler}
      aditional_param="imagen_variante"
      handleSubmit={handleSubmit}
      button_label="Guardar"
    >
      <Grid container spacing={1.5}>
        {html}
      </Grid>
    </ModalUI>
  );
};

const styles = (theme) => ({
  image_container: {
    width: '100%',
    height: '150px',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '87.5px',
    },
  },
  image: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  image_with_overlay: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover',
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: '#000',
    opacity: '0.5',
    cursor: 'pointer',
    transition: 'opacity 200ms',
    '&:hover': {
      opacity: '1',
    },
  },
  check: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    width: '35px',
    height: '35px',
    borderRadius: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: green[600],
    color: '#fff',
  },
});

export const ModalVarianteImagen = withStyles(styles, { withTheme: true })(
  ModalVarianteImagen_
);
