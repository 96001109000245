import React, { useEffect, useState } from 'react';
import {
  ButtonUI,
  InputUI,
  ModuleTitleUI,
  SelectUI,
  ToggleUI,
} from '../../../common';
import { Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  initMercadoPago,
  CardNumber,
  ExpirationDate,
  SecurityCode,
  createCardToken,
} from '@mercadopago/sdk-react';
import { Config, Regex, RegexExtra, Validate } from '../../../../other';
import { CreditCardInput } from './CreditCardInput';
import { LogoPaymentSuscription } from './LogoPaymentSuscription';
import { LogoMercadoPago } from '../LogoMercadoPago';
import { LogoProtectPayment } from './LogoProtectPayment';
import {
  tiendaAltaSuscripcion,
  tiendaModificarSuscripcion,
  tiendaObtenerMiPlan,
} from '../../../../store/actions';
import { connect } from 'react-redux';
import inputDefaultProps from './inputDefaultProps.json';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../../../amplitude';
import { Redirect, Route } from 'react-router-dom';
import { WrapperLayout } from '../../../../layouts/WrapperLayout';

const Suscripcion_ = ({
  history,
  classes,
  idCountry,
  isSuscribed,
  obtenerMiPlan,
  altaSuscripcion,
  modificarSuscripcion,
}) => {
  const [layoutLoading, setLayoutLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const INPUT_ERRORS = {
    ARG: {
      errorCardNumber: 'Ingresá un número de tarjeta de crédito o débito.',
      errorExpirationDate: 'Ingresá el mes y el año de vencimiento.',
      errorSecurityCode: 'Ingresá el código de seguridad válido.',
      invalid_length: 'Ingresá un número de tarjeta de crédito o débito.',
    },
    COL: {
      errorCardNumber: 'Ingresa un número de tarjeta de crédito o débito.',
      errorExpirationDate: 'Ingresa el mes y el año de expiración.',
      errorSecurityCode: 'Ingresa el código de seguridad válido.',
      invalid_length: 'Ingresa un número de tarjeta de crédito o débito.',
    },
  };

  const moduleTitle = {
    ARG: {
      title: 'Ingresá los datos de tu tarjeta',
    },
    COL: {
      title: 'Información de tu tarjeta',
    },
  };

  const DEFAULT_INPUT_VALUES = {
    ARG: {
      cardName: {
        placeholder: '',
        messages: {
          error:
            'Ingresá el nombre tal cual figura en la tarjeta seleccionada.',
        },
      },
      cardIdentification: {
        label: 'DNI del titular de la tarjeta',
        messages: {
          error: 'Ingresá el DNI del titular de la tarjeta.',
        },
      },
    },
    COL: {
      cardName: {
        placeholder: 'Nombre y Apellido',
        messages: {
          error:
            'Ingresa el nombre tal cual figura en la tarjeta seleccionada.',
        },
      },
      cardIdentification: {
        label: 'Número de identidad',
        placeholder: 'Ingresar números',
        regex: Regex.DOCUMENTO_COLOMBIA,
        messages: {
          error: RegexExtra.DOCUMENTO_COLOMBIA,
          help: 'Ingresa entre 5 y 10 dígitos.',
        },
      },
    },
  };

  const DOC_TYPES = [
    {
      label: 'Seleccionar',
      value: 'Seleccionar',
    },
    {
      label: 'CC',
      value: 'CC',
    },
    {
      label: 'CE',
      value: 'CE',
    },
    {
      label: 'PPT',
      value: 'PPT',
    },
  ];

  const [cardName, setCardName] = useState({
    ...inputDefaultProps,
    label: 'Nombre del titular de la tarjeta',
    id: 'cardName',
    name: 'cardName',
    change_param: 'debitoAutomatico',
    regex: Regex.STRING_GENERICO_2_45,
    placeholder: DEFAULT_INPUT_VALUES[idCountry]?.cardName.placeholder,
    messages: {
      error: DEFAULT_INPUT_VALUES[idCountry]?.cardName.messages.error,
      error_extra: '',
    },
  });
  const [cardIdentification, setCardIdentification] = useState({
    ...inputDefaultProps,
    label: DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification.label,
    id: 'cardIdentification',
    name: 'cardIdentification',
    change_param: 'debitoAutomatico',
    placeholder:
      DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification?.placeholder || '',
    regex: Regex.DNI,
    messages: {
      help: DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification?.help || '',
      error: 'Ingresá el DNI del titular de la tarjeta.',
      error_extra: '',
    },
  });
  const [cardEmail, setCardEmail] = useState({
    ...inputDefaultProps,
    label: 'Email',
    id: 'cardEmail',
    name: 'cardEmail',
    type: 'email',
    change_param: 'debitoAutomatico',
    regex: Regex.EMAIL,
    placeholder: 'Ingresar email',
    messages: {
      error:
        'El email ingresado no está asociado a tu tarjeta. Coloca el correo utilizado en compras anteriores en Mercado Libre o Mercado Pago.',
      error_extra: RegexExtra.EMAIL,
    },
  });
  const [selectedDocType, setSelectedDocType] = useState({
    label: 'Tipo de documento',
    auth: true,
    validate: false,
    required: true,
    error: false,
    id: 'df_tipo_documento',
    name: 'df_tipo_documento',
    change_param: 'datosFacturacion',
    value: 'Seleccionar',
    default_value: null,
    messages: {
      error: 'Selecciona una opción.',
    },
  });
  const [errorCardNumber, setErrorCardNumber] = useState('');
  const [errorSecurityCode, setErrorSecurityCode] = useState('');
  const [errorExpirationDate, setErrorExpirationDate] = useState('');
  const [redirectTo, setRedirectTo] = useState('');
  const [error, setError] = useState(false);

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (idCountry) {
      initMercadoPago(Config.MP_PUBLIC_KEY[idCountry]);

      setTimeout(() => setLayoutLoading(false), 1000);
    }
  }, [idCountry]);

  useEffect(() => {
    obtenerMiPlan((err, resp) => {
      setAmount(resp.data.factura.monto);
      if (err) {
        setError(true);
      } else if (resp.data) {
        if (resp.data.facturacion === 0) {
          setRedirectTo(`/plan/datos-facturacion`);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (idCountry === 'COL') {
      setCardName({
        ...cardName,
        placeholder: DEFAULT_INPUT_VALUES[idCountry]?.cardName.placeholder,
        messages: {
          ...cardName.messages,
          error: DEFAULT_INPUT_VALUES[idCountry]?.cardName.messages.error,
        },
      });

      setCardIdentification({
        ...cardIdentification,
        label: DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification.label,
        placeholder:
          DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification.placeholder,
        messages: {
          ...cardIdentification.messages,
          error:
            DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification.messages.error,
          help: DEFAULT_INPUT_VALUES[idCountry]?.cardIdentification.messages
            .help,
        },
      });
    }
  }, [idCountry]);

  const refreshPage = (err, resp) => {
    if (
      resp.message ===
      'El email ingresado no está asociado a tu tarjeta. Coloca el correo utilizado en compras anteriores en Mercado Libre o Mercado Pago.'
    ) {
      setCardEmail({
        ...cardEmail,
        error: true,
        messages: {
          error: resp.message,
          error_extra: '',
        },
      });

      createAmplitudeEventWithDevice(
        suscription_amplitude_events.suscripcion_confirma_datos_tarjeta
          .event_name,
        { success: false, tipo_error: 'email_incorrecto' }
      );

      setButtonLoading(false);

      return;
    }

    const translate = {
      ARG: {
        isSuscribed: '¡Listo! Actualizaste los datos de tu tarjeta.',
        isNotSuscribed: '¡Listo! Te suscribiste con éxito.',
      },
      COL: {
        isSuscribed: '¡Hecho! Actualizaste los datos de tu tarjeta.',
        isNotSuscribed: '¡Hecho! Activaste tu suscripción con éxito.',
      },
    };

    const messageSuccess = isSuscribed
      ? translate[idCountry].isSuscribed
      : translate[idCountry].isNotSuscribed;

    localStorage.setItem(
      'plan-alert',
      JSON.stringify({
        open: true,
        message: err ? resp.message : messageSuccess,
        type: err ? 'error' : 'success',
      })
    );

    if (err) {
      createAmplitudeEventWithDevice(
        suscription_amplitude_events.suscripcion_confirma_datos_tarjeta
          .event_name,
        { success: false, tipo_error: 'falla_suscripcion' }
      );
    } else {
      createAmplitudeEventWithDevice(
        suscription_amplitude_events.suscripcion_confirma_datos_tarjeta
          .event_name,
        { success: true, tipo_error: 'ninguno' }
      );
      if (typeof window !== 'undefined') {
        if (window.fbq) {
          window.fbq('track', 'Subscribe', {
            currency: idCountry === 'COL' ? 'COP' : 'ARS',
            value: amount,
          });
        }
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            event_category: 'Suscripción',
            event_label: 'Click en adherirse al débito automático',
          });
        }
      }
    }

    history.push('/plan');
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setErrorCardNumber('');
    setErrorSecurityCode('');
    setErrorExpirationDate('');
    setButtonLoading(true);

    const form = {
      ARG: {
        cardName: cardName,
        cardEmail: cardEmail,
        cardIdentification: cardIdentification,
      },
      COL: {
        cardName: cardName,
        cardEmail: cardEmail,
        cardIdentification: cardIdentification,
        selectedDocType: selectedDocType,
      },
    };

    const cardTokenData = {
      ARG: {
        identificationType: 'DNI',
        cardholderName: cardName.value,
        identificationNumber: cardIdentification.value,
      },
      COL: {
        identificationType: selectedDocType.value,
        cardholderName: cardName.value,
        identificationNumber: cardIdentification.value,
      },
    };

    const selectIsOK =
      idCountry === 'ARG' ||
      (selectedDocType.value !== 'Seleccionar' && idCountry === 'COL');

    let cardToken;
    try {
      cardToken = await createCardToken(cardTokenData[idCountry]);
    } catch (errors) {
      if (errors.length > 0) {
        createAmplitudeEventWithDevice(
          suscription_amplitude_events.suscripcion_confirma_datos_tarjeta
            .event_name,
          { success: false, tipo_error: 'usuario' }
        );

        errors.map((err) => {
          if (err.cause === 'invalid_value') {
            if (err.field === 'cardNumber') {
              setErrorCardNumber(INPUT_ERRORS[idCountry].errorCardNumber);
            } else if (
              err.field === 'expirationMonth' ||
              err.field === 'expirationYear'
            ) {
              setErrorExpirationDate(
                INPUT_ERRORS[idCountry].errorExpirationDate
              );
            } else if (err.field === 'securityCode') {
              setErrorSecurityCode(INPUT_ERRORS[idCountry].errorSecurityCode);
            }
          } else if (err.cause === 'invalid_length') {
            if (err.field === 'cardNumber') {
              setErrorCardNumber(INPUT_ERRORS[idCountry].invalid_length);
            }
          }
          return err;
        });
      }

      Validate.validar_formulario_actualizar_obj(
        form[idCountry],
        (new_form) => {
          setCardName(new_form.cardName);
          setCardEmail(new_form.cardEmail);
          setCardIdentification(new_form.cardIdentification);
          setSelectedDocType({
            ...selectedDocType,
            error: selectedDocType.value === 'Seleccionar',
          });
        }
      );

      setButtonLoading(false);
      return;
    }

    if (Validate.validar_formulario(form[idCountry]) && selectIsOK) {
      const dataAction = {
        cardToken: cardToken.id,
        userMail: cardEmail.value,
      };

      // post validaciones
      if (isSuscribed) {
        modificarSuscripcion(dataAction, refreshPage);
      } else {
        altaSuscripcion(dataAction, refreshPage);
      }
    } else {
      createAmplitudeEventWithDevice(
        suscription_amplitude_events.suscripcion_confirma_datos_tarjeta
          .event_name,
        { success: false, tipo_error: 'usuario' }
      );
      Validate.validar_formulario_actualizar_obj(
        form[idCountry],
        (new_form) => {
          setCardName(new_form.cardName);
          setCardEmail(new_form.cardEmail);
          setCardIdentification(new_form.cardIdentification);
          setSelectedDocType({
            ...selectedDocType,
            error: selectedDocType.value === 'Seleccionar',
          });

          setButtonLoading(false);
        }
      );
    }
  };

  const handleChange = (e, blur) => {
    const value = e.target.value;
    const name = e.target.name;

    const getFormatState = (docType) => {
      return {
        regex:
          docType === 'PPT'
            ? Regex.DOCUMENTO_COLOMBIA_PPT
            : Regex.DOCUMENTO_COLOMBIA,
        message:
          docType === 'PPT'
            ? RegexExtra.DOCUMENTO_COLOMBIA_PPT
            : RegexExtra.DOCUMENTO_COLOMBIA,
      };
    };

    const statesTextInputs = {
      cardName: { state: cardName, set: setCardName },
      cardIdentification: {
        state: cardIdentification,
        set: setCardIdentification,
      },
      cardEmail: { state: cardEmail, set: setCardEmail },
      df_tipo_documento: {
        state: { ...selectedDocType, error: false },
        set: setSelectedDocType,
      },
    };

    if (Object.keys(statesTextInputs).includes(name)) {
      let newInputText = {
        ...statesTextInputs[name].state,
        value: value,
      };

      if (idCountry === 'COL' && name === 'df_documento') {
        const { regex, message } = getFormatState(selectedDocType.value);

        newInputText = {
          ...newInputText,
          regex: regex,
          messages: {
            help: message,
            error: message,
            error_extra: '',
          },
        };
      }

      if (name === 'df_tipo_documento') {
        const { regex, message } = getFormatState(value);

        setCardIdentification({
          ...cardIdentification,
          regex: regex,
          messages: {
            help: message,
            error: message,
            error_extra: '',
          },
        });
      }

      Validate.validate_input(newInputText, blur, (input) => {
        statesTextInputs[name].set(input);
      });
    }
  };

  const wordingText = {
    ARG: {
      expirationDate: 'Fecha de vencimiento',
      securityCode: 'Código de seguridad',
      footerText: `Para validar tu tarjeta, realizaremos un cobro de $5 que será reembolsado de inmediato. Este paso es necesario para activar tu suscripción.`,
    },
    COL: {
      expirationDate: 'Fecha de expiración',
      securityCode: 'Código CVC',
      footerText: `Para verificar tu tarjeta, realizaremos un cargo de $ 1600 COP que será reembolsado de inmediato. Este paso es esencial para activar tu suscripción.`,
    },
  };

  return (
    <WrapperLayout errors={error} loading={layoutLoading}>
      <ModuleTitleUI title={moduleTitle[idCountry]?.title} />
      {redirectTo && (
        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: props.location },
              }}
            />
          )}
        />
      )}
      <form className={classes.paymentForm} onSubmit={handleOnSubmit}>
        <Grid container className={classes.containerForm} style={{ gap: 12 }}>
          <Grid item xs={12}>
            <Grid
              className={`${classes.cardInput} ${
                errorCardNumber && classes.cardInputError
              }`}
              item
              xs={12}
            >
              <CreditCardInput
                inputProps={{
                  startAdornment: (
                    <Icon className={classes.icon}>credit_card</Icon>
                  ),
                  label: 'Número de la tarjeta',
                }}
                error={errorCardNumber}
              >
                <CardNumber placeholder="XXXX XXXX XXXX XXXX" />
              </CreditCardInput>
            </Grid>
            {errorCardNumber && (
              <span className={classes.cardInputHelperText}>
                {errorCardNumber}
              </span>
            )}
          </Grid>
          <Grid className={classes.logoPayment} item xs={12}>
            <LogoPaymentSuscription
              style={{
                clipPath:
                  idCountry === 'COL' ? 'inset(0 67% 0 0)' : 'inset(0 0% 0 0)',
              }}
              className={classes.logoPaymentGroup}
            />
          </Grid>
          <Grid item md xs={12}>
            <Grid
              className={`${classes.cardInput} ${
                errorExpirationDate && classes.cardInputError
              }`}
              md
              item
              xs={12}
            >
              <CreditCardInput
                inputProps={{
                  label: wordingText[idCountry || 'ARG'].expirationDate,
                }}
                error={errorExpirationDate}
              >
                <ExpirationDate placeholder="MM/AA" mode="short" />
              </CreditCardInput>
            </Grid>
            {errorExpirationDate && (
              <Grid item xs={12} md={6}>
                <span className={classes.cardInputHelperText}>
                  {errorExpirationDate}
                </span>
              </Grid>
            )}
          </Grid>
          <Grid item md xs={12}>
            <Grid
              className={`${classes.cardInput} ${
                errorSecurityCode && classes.cardInputError
              }`}
              md
              item
              xs={12}
            >
              <CreditCardInput
                inputProps={{
                  label: wordingText[idCountry || 'ARG'].securityCode,
                }}
                error={errorSecurityCode}
              >
                <SecurityCode placeholder="123" />
              </CreditCardInput>
            </Grid>
            {errorSecurityCode && (
              <Grid item xs={12} md={6}>
                <span className={classes.cardInputHelperText}>
                  {errorSecurityCode}
                </span>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputUI handleChange={handleChange} input={cardName} />
          </Grid>

          <ToggleUI show={idCountry === 'COL'}>
            <Grid item xs={12} className={classes.containerRow}>
              <Grid item xs={12} md={6}>
                <SelectUI
                  data={selectedDocType}
                  options={DOC_TYPES}
                  handleChangeSelect={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputUI
                  handleChange={handleChange}
                  input={cardIdentification}
                />
              </Grid>
            </Grid>
          </ToggleUI>

          <ToggleUI show={idCountry !== 'COL'}>
            <Grid item xs={12}>
              <InputUI handleChange={handleChange} input={cardIdentification} />
            </Grid>
          </ToggleUI>

          <Grid item xs={12}>
            <InputUI handleChange={handleChange} input={cardEmail} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.containerMessage}>
              <div className={classes.containerMessageItem}>
                <span className={classes.messageProcess}>Procesado por</span>{' '}
                <LogoMercadoPago width="79px" />
              </div>
              <div className={classes.containerMessageItem}>
                <LogoProtectPayment />
                <span className={classes.messageProtected}>100% Protegido</span>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.containerMessageInfo}>
          <Icon style={{ marginRight: '10px' }}>error_outline</Icon>
          <p>{wordingText[idCountry || 'ARG'].footerText}</p>
        </div>
        <Grid container wrap="nowrap">
          <Grid item xs={12} className={classes.containerButtons}>
            <ButtonUI
              fullWidth={false}
              type="link"
              variant="outlined"
              label="Volver"
              link={isSuscribed ? '/plan' : '/plan/metodo-pago'}
              classes={{ root: classes.button }}
            />
            <ButtonUI
              fullWidth={false}
              type="submit"
              label="Confirmar"
              onClickFunc={handleOnSubmit}
              classes={{ root: classes.buttonOk }}
              isLoading={buttonLoading}
              minHeight={true}
            />
          </Grid>
        </Grid>
      </form>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  buttonOk: {
    minWidth: '181px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '164px',
    },
  },
  button: {
    marginRight: '8px',
    minWidth: '181px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '164px',
    },
  },
  cardInput: {
    display: 'flex',
    height: '52px',
    borderRadius: '4px',
    position: 'relative',
    marginBottom: '24px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '16px 12px 12px 12px',
    '& #cardNumberSecureField_container': {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '8px',
    },
  },
  cardInputError: {
    border: '1px solid #f44336',
    marginBottom: '6px',
  },
  cardInputHelperText: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  containerButtons: {
    wrap: 'nowrap',
    display: 'flex',
    marginTop: '24px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  icon: { marginRight: '10px' },
  paymentForm: { width: '100%' },
  containerForm: {
    backgroundColor: '#fff',
    padding: '24px',
  },
  logoPayment: {
    marginBottom: '12px',
    '& svg': {
      width: '100%',
      maxWidth: '319px',
    },
  },
  containerMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  containerMessageItem: {
    display: 'flex',
    alignItems: 'center',
  },
  messageProcess: {
    color: '#565656',
    fontSize: '0.75rem',
    fontWeight: '400',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.62rem',
    },
  },
  messageProtected: {
    color: '#EAA902',
    fontSize: '0.75rem',
    fontWeight: 500,
    marginLeft: '6px',
  },
  containerMessageInfo: {
    display: 'flex',
    alignItems: 'center',
    color: '#565656',
    fontSize: '1rem',
    fontWeight: 400,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
    },
  },
  inputStyle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  inputUIStyle: {
    height: '52px',
  },
  logoPaymentGroup: {
    width: '100%',
    maxWidth: '318px',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  containerRow: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      gap: '12px',
    },
  },
});

const mapStateToProps = (state) => {
  return {
    idCountry: state.tienda.Country_idCountry || 'ARG',
    isSuscribed: state.tienda.t_debito_automatico,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    altaSuscripcion: (data, callback) =>
      dispatch(tiendaAltaSuscripcion(data, callback)),
    modificarSuscripcion: (data, callback) =>
      dispatch(tiendaModificarSuscripcion(data, callback)),
    obtenerMiPlan: (callback) => dispatch(tiendaObtenerMiPlan(callback)),
  };
};

export const Suscripcion = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Suscripcion_)
);
