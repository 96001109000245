import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  blogObtenerArticulos,
  blogBorrar,
  tiendaObtener,
  tiendaBlogActivar,
  tiendaBlogDesactivar,
} from '../../store/actions';
import { withStyles } from '@mui/styles';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { ButtonUI, AlertUI, ModuleTitleUI, ToggleUI } from '../common';
import { ModalBorrar, ArticulosList } from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const Blog_ = ({
  classes,
  tienda,
  blogObtenerArticulos,
  tiendaObtener,
  blogBorrar,
  tiendaBlogActivar,
  tiendaBlogDesactivar,
}) => {
  const [state, setState] = useState({
    borrar: {
      idBlog: null,
      pb_titulo: '',
    },
    loading: {
      blog: true,
      tienda: true,
      error: false,
    },
    modals: {
      borrar: false,
    },
    forms: {
      activar_blog: false,
      desactivar_blog: false,
      borrar: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });

  useEffect(() => {
    tiendaObtener((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          tienda: false,
          error: prevState.loading.error || err ? true : false,
        },
      }));
    });
    blogObtenerArticulos((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          blog: false,
          error: prevState.loading.error || err ? true : false,
        },
      }));
    });
  }, []);

  const handleActivarBlogSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        activar_blog: true,
      },
    }));

    tiendaBlogActivar((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        forms: {
          ...prevState.forms,
          activar_blog: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      }));
    });
  };

  const handleDesactivarBlogSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        desactivar_blog: true,
      },
    }));

    tiendaBlogDesactivar((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        forms: {
          ...prevState.forms,
          desactivar_blog: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      }));
    });
  };

  const handleSubmitBorrar = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        borrar: true,
      },
    }));

    let form = state.borrar;

    blogBorrar({ idBlog: form.idBlog }, (err, resp) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            borrar: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'error',
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            borrar: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'success',
          },
          modals: {
            ...prevState.modals,
            borrar: false,
          },
        }));
      }
    });
  };

  const modalHandler = (open, id, aditional) => {
    if (open) {
      let articulo = aditional;
      setState((prevState) => ({
        ...prevState,
        borrar: {
          ...prevState.borrar,
          idBlog: articulo.idBlog,
          pb_titulo: articulo.pb_titulo,
        },
        modals: {
          ...prevState.modals,
          [id]: open,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        modals: {
          ...prevState.modals,
          [id]: open,
        },
      }));
    }
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const activar_desactivar_btn = tienda.t_blog ? (
    <ButtonUI
      type="callf"
      label="Desactivar"
      minHeight={true}
      fullWidth={false}
      onClickFunc={handleDesactivarBlogSubmit}
      isLoading={state.forms.desactivar_blog}
    />
  ) : (
    <ButtonUI
      type="callf"
      label="Activar"
      minHeight={true}
      fullWidth={false}
      onClickFunc={handleActivarBlogSubmit}
      isLoading={state.forms.activar_blog}
    />
  );

  return (
    <WrapperLayout
      loading={state.loading.tienda || state.loading.blog}
      error={state.loading.error}
    >
      <ModalBorrar
        borrar={state.borrar}
        handleSubmit={handleSubmitBorrar}
        modalHandler={modalHandler}
        modal_state={state.modals.borrar}
        form_state={state.forms.borrar}
      />
      <AlertUI
        open={state.alert.open}
        type={state.alert.type}
        message={state.alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <ModuleTitleUI
        title="Blog"
        subtitles={[
          'En esta sección podrás crear tu propio blog que se visualizará dentro de tu tienda online. Se mostrará en el menú principal.',
          'Aquí podrás activar la función, agregar los artículos, editarlos y borrarlos.',
        ]}
        button={activar_desactivar_btn}
      />
      <ToggleUI show={tienda.t_blog ? true : false}>
        <Card className={classes.card_below}>
          <CardHeader
            title="Mis artículos"
            className={classes.card_header}
            classes={{ action: classes.card_action_button }}
            action={
              <ButtonUI type="link" label="Agregar" link="/blog/agregar" />
            }
          />
          <Divider />
          <CardContent className={classes.card_content}>
            <ArticulosList modalHandler={modalHandler} />
          </CardContent>
        </Card>
      </ToggleUI>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    blogObtenerArticulos: (callback) =>
      dispatch(blogObtenerArticulos(callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    blogBorrar: (data, callback) => dispatch(blogBorrar(data, callback)),
    tiendaBlogActivar: (callback) => dispatch(tiendaBlogActivar(callback)),
    tiendaBlogDesactivar: (callback) =>
      dispatch(tiendaBlogDesactivar(callback)),
  };
};

export const Blog = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Blog_)
);
