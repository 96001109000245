/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../../components/common';

const UalaModal = (props) => {
  const {
    modals,
    inputs,
    forms,
    modalHandler,
    handleSubmit,
    handleChange,
    idCountry = 'ARG',
  } = props;

  const text_style = {
    fontSize: '0.87rem',
    fontWeight: '400',
    lineHeight: '16px',
    color: '#ABABAB',
  };

  const countryValues = {
    ARG: {
      href: '',
      linkPartialText: 'seguí',
      msgPartialText: 'obtené',
      subtitlePartialText: 'Ingresá',
    },
    COL: {
      href: '-colombia',
      linkPartialText: 'sigue',
      msgPartialText: 'obtén',
      subtitlePartialText: 'Ingresa',
    },
  }[idCountry];

  const hrefActivarUalaBis = `https://recursos.empretienda.com/activar-ualabis${countryValues.href}.pdf`;

  return (
    <ModalUI
      title="Credenciales de Ualá Bis"
      subtitle={
        <span style={text_style}>
          Para activar este método de pago, es necesario tener cuenta en Ualá.{' '}
          {countryValues.subtitlePartialText} a la app de Ualá,{' '}
          <u>
            <a
              href={hrefActivarUalaBis}
              target="_blank"
              rel="noopener noreferrer"
            >
              {countryValues.linkPartialText} este instructivo
            </a>
          </u>{' '}
          y {countryValues.msgPartialText} tus credenciales.
        </span>
      }
      open={modals.activar_editar_uala}
      id="activar_editar_uala"
      button_label="Guardar"
      button_loading={forms.activar_editar_uala}
      modalHandler={modalHandler}
      handleSubmit={handleSubmit}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            input={inputs.mp_uala_username}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            input={inputs.mp_uala_client_id}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            input={inputs.mp_uala_client_secret_id}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { UalaModal };
