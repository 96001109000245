const metodosenvioObtener = () => {
  return {
    status: 200,
    data: {
      idMediosEnvio: 217,
      me_retiro_local: 0,
      me_acordar: 1,
      me_oca: 0,
      me_personalizados: 0,
      me_correo_argentino: 0,
      me_mercadoenvios: 0,
      me_pickit: 0,
      me_pudo: 0,
      me_epick: 0,
      me_emprepack: 0,
      me_mercadoenvios_tipo: 0,
      me_mercadoenvios_minimo: 0,
      me_oca_usuario: null,
      me_oca_clave: null,
      me_oca_nrocuenta: null,
      me_oca_solicitante: null,
      me_oca_cuit: null,
      me_oca_operativa_pp: null,
      me_oca_operativa_ps: null,
      me_oca_operativa_sp: null,
      me_oca_operativa_ss: null,
      me_oca_calle: null,
      me_oca_nro: null,
      me_oca_piso: null,
      me_oca_dpto: null,
      me_oca_cp: null,
      me_oca_localidad: null,
      me_oca_provincia: null,
      me_oca_provincia_id: 2,
      me_oca_tipo: 0,
      me_oca_gratis_sucursal: 0,
      me_oca_gratis_domicilio: 0,
      me_oca_gratis_minimo: 0,
      me_oca_recargo_monto: 0,
      me_oca_recargo_porcentaje: 0,
      me_pickit_api_key: null,
      me_pickit_token: null,
      me_pudo_api_key: null,
      me_pudo_secret_key: null,
      me_correo_argentino_postal: null,
      me_correo_argentino_prioritario: 1,
      me_correo_argentino_clasico: 1,
      me_correo_argentino_tipo_cuenta: 0,
      me_correo_argentino_tipo: 0,
      me_correo_argentino_gratis_sucursal: 0,
      me_correo_argentino_gratis_domicilio: 0,
      me_correo_argentino_gratis_minimo: 0,
      me_correo_argentino_recargo_monto: 0,
      me_correo_argentino_recargo_porcentaje: 0,
      me_correo_argentino_email: null,
      me_correo_argentino_password: null,
      me_epick_solicitante: null,
      me_epick_telefono: null,
      me_epick_calle: null,
      me_epick_nro: null,
      me_epick_piso: null,
      me_epick_dpto: null,
      me_epick_cp: null,
      me_epick_localidad: null,
      me_epick_provincia: null,
      me_epick_tipo: 0,
      me_epick_gratis_minimo: 0,
      me_epick_recargo_monto: 0,
      me_epick_recargo_porcentaje: 0,
      me_emprepack_office_id: 0,
      me_emprepack_tipo: 0,
      me_emprepack_gratis_minimo: 0,
      me_emprepack_recargo_monto: 0,
      me_emprepack_recargo_porcentaje: 0,
      me_emprepack_gratis_sucursal: 0,
      me_emprepack_gratis_domicilio: 0,
    },
    message: 'Ok',
  };
};

const emprepackSucursales = {
  status: 200,
  data: [
    {
      office_id: 10058,
      office_name: 'RIT',
      office_street: 'Av Gral Savio',
      office_street_number: '2097',
      office_floor: null,
      office_apartment: null,
      office_city: 'Rio Tercero',
      office_state: 'Cordoba',
      office_zipcode: '5850',
      office_country: 'Argentina',
      office_phone: '0810-122-1111',
      office_worktime:
        'Lunes a Viernes de 08:00 a 18:00 - Sábados de 08:00 a 13:00',
      office_latitude: '-32.172920',
      office_longitude: '-64.113250',
      office_enabled_zipcodes: [
        '5121',
        '5191',
        '5192',
        '5194',
        '5196',
        '5197',
        '5199',
        '5850',
        '5851',
        '5853',
        '5854',
        '5856',
        '5857',
        '5858',
        '5859',
        '5862',
        '5864',
        '5929',
        '5931',
        '5933',
        '5935',
        '5968',
        '5987',
      ],
    },
    {
      office_id: 10107,
      office_name: 'VDM',
      office_street: 'Belgrano',
      office_street_number: '1345',
      office_floor: null,
      office_apartment: null,
      office_city: 'Viedma',
      office_state: 'Rio Negro',
      office_zipcode: '8500',
      office_country: 'Argentina',
      office_phone: '0810-122-1111',
      office_worktime:
        'Lunes a Viernes de 08:00 a 18:00 - Sábados de 08:00 a 13:00',
      office_latitude: '-40.813476',
      office_longitude: '-63.004313',
      office_enabled_zipcodes: ['8500', '8501', '8504', '8505', '8506', '8514'],
    },
  ],
  message: 'Ok',
};

export default {
  emprepackSucursales,
  metodosenvioObtener,
};
