export default class Reducers {
  /********/
  /* Auth */
  /********/
  static AUTH_SET = 'AUTH_SET';
  static AUTH_UNSET = 'AUTH_UNSET';
  static AUTH_ACTUALIZAR_NOMBRE = 'AUTH_ACTUALIZAR_NOMBRE';
  static AUTH_ACTUALIZAR_DOMINIO = 'AUTH_ACTUALIZAR_DOMINIO';
  static AUTH_BRAZE_USER = 'AUTH_BRAZE_USER';

  /***********/
  /* Tienda  */
  /***********/
  static TIENDA_GUARDAR = 'TIENDA_GUARDAR';

  /***********/
  /* Alerta  */
  /***********/
  static SHOW_ALERT = 'SHOW_ALERT';

  /*********/
  /* Beta  */
  /*********/
  static BETA_GUARDAR = 'BETA_GUARDAR';

  /*****************/
  /* Metodos Pago  */
  /*****************/
  static METODOSPAGO_GUARDAR = 'METODOSPAGO_GUARDAR';

  /******************/
  /* Metodos Envio  */
  /******************/
  static METODOSENVIO_GUARDAR = 'METODOSENVIO_GUARDAR';

  /*********/
  /* Local */
  /*********/
  static LOCAL_GUARDAR = 'LOCAL_GUARDAR';
  static LOCAL_AGREGAR = 'LOCAL_AGREGAR';
  static LOCAL_EDITAR = 'LOCAL_EDITAR';
  static LOCAL_BORRAR = 'LOCAL_BORRAR';

  /***********************/
  /* Envio personalizado */
  /***********************/
  static ENVIOPERSONALIZADO_GUARDAR = 'ENVIOPERSONALIZADO_GUARDAR';
  static ENVIOPERSONALIZADO_AGREGAR = 'ENVIOPERSONALIZADO_AGREGAR';
  static ENVIOPERSONALIZADO_EDITAR = 'ENVIOPERSONALIZADO_EDITAR';
  static ENVIOPERSONALIZADO_BORRAR = 'ENVIOPERSONALIZADO_BORRAR';

  /***************************/
  /* Configuracion mayorista */
  /***************************/
  static CONFIGURACIONMAYORISTA_GUARDAR = 'CONFIGURACIONMAYORISTA_GUARDAR';

  /***********/
  /* Cliente */
  /***********/
  static CLIENTE_GUARDAR = 'CLIENTE_GUARDAR';
  static CLIENTE_GUARDAR_NO_CLEAN = 'CLIENTE_GUARDAR_NO_CLEAN';
  static CLIENTE_AGREGAR = 'CLIENTE_AGREGAR';
  static CLIENTE_EDITAR = 'CLIENTE_EDITAR';
  static CLIENTE_REVOCAR_MAYORISTA = 'CLIENTE_REVOCAR_MAYORISTA';
  static CLIENTE_BORRAR = 'CLIENTE_BORRAR';
  static CLIENTE_ACTUALIZAR_EXPORTADOS = 'CLIENTE_ACTUALIZAR_EXPORTADOS';

  /*************/
  /* Categoria */
  /*************/
  static CATEGORIA_GUARDAR = 'CATEGORIA_GUARDAR';
  static CATEGORIA_UPDATE_GOOGLE_ID = 'CATEGORIA_UPDATE_GOOGLE_ID';

  /***********/
  /* Dominio */
  /***********/
  static DOMINIO_GUARDAR = 'DOMINIO_GUARDAR';
  static DOMINIOS_GUARDAR = 'DOMINIOS_GUARDAR';
  static DOMINIO_AGREGAR = 'DOMINIO_AGREGAR';
  static DOMINIO_EDITAR = 'DOMINIO_EDITAR';
  static DOMINIO_MIGRAR = 'DOMINIO_MIGRAR';
  static DOMINIO_EDITAR_REGISTROS = 'DOMINIO_EDITAR_REGISTROS';

  /*********/
  /* Venta */
  /*********/
  static VENTA_GUARDAR = 'VENTA_GUARDAR';
  static VENTA_GUARDAR_NO_CLEAN = 'VENTA_GUARDAR_NO_CLEAN';
  static VENTA_ARCHIVAR = 'VENTA_ARCHIVAR';
  static VENTA_ACTUALIZAR_SINGLE_VENTA = 'VENTA_ACTUALIZAR_SINGLE_VENTA';

  /********/
  /* Blog */
  /********/
  static BLOG_GUARDAR = 'BLOG_GUARDAR';
  static BLOG_BORRAR = 'BLOG_BORRAR';

  /***********/
  /* Paginas */
  /***********/
  static PAGINA_GUARDAR = 'PAGINA_GUARDAR';
  static PAGINA_BORRAR = 'PAGINA_BORRAR';
  static PAGINA_ORDENAR = 'PAGINA_ORDENAR';

  /*************/
  /* Productos */
  /*************/
  static PRODUCTO_AGREGAR = 'PRODUCTO_AGREGAR';
  static PRODUCTO_GUARDAR = 'PRODUCTO_GUARDAR';
  static PRODUCTO_GUARDAR_NO_CLEAN = 'PRODUCTO_GUARDAR_NO_CLEAN';
  static PRODUCTO_BORRAR = 'PRODUCTO_BORRAR';
  static PRODUCTO_DESACTIVAR_ACTIVAR = 'PRODUCTO_DESACTIVAR_ACTIVAR';
  static PRODUCTO_ACTUALIZAR_STOCK = 'PRODUCTO_ACTUALIZAR_STOCK';

  /************************/
  /* Cupones de descuento */
  /************************/
  static CUPON_GUARDAR = 'CUPON_GUARDAR';
  static CUPON_BORRAR = 'CUPON_BORRAR';
  static CUPON_DESACTIVAR = 'CUPON_DESACTIVAR';

  /***********/
  /* Bloques */
  /***********/
  static BLOQUE_GUARDAR = 'BLOQUE_GUARDAR';

  /***************/
  /* Track  */
  /***************/
  static TRACK_GUARDAR = 'TRACK_GUARDAR';
  static TRACK_GUARDAR_NO_CLEAN = 'TRACK_GUARDAR_NO_CLEAN';
}
