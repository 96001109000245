import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../common';

const ModalInformarPago = (props) => {
  let {
    informar_pago,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;
  return (
    <ModalUI
      open={modal_state}
      id="informar_pago"
      title="Informar pago"
      subtitle="Solo debes informar un pago si abonaste y el pago no se ve reflejado"
      modalHandler={modalHandler}
      aditional_param="informar_pago"
      handleSubmit={handleSubmit}
      button_label="Informar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={informar_pago.numero_operacion}
            adornment="#"
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalInformarPago };
