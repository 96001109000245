import { Config } from '../../../other';

export const templateObjects = [
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/aura.png`,
    templateName: 'Aura',
    id: 1,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/mimas.png`,
    templateName: 'Mimas',
    id: 8,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/elara.png`,
    templateName: 'Elara',
    id: 10,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/eris.png`,
    templateName: 'Eris',
    id: 9,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/favourite.png`,
    templateName: 'Favourite',
    id: 2,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/simple.png`,
    templateName: 'Simple',
    id: 6,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/elegant.png`,
    templateName: 'Elegant',
    id: 5,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/integral.png`,
    templateName: 'Integral',
    id: 7,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/classic.png`,
    templateName: 'Classic',
    id: 3,
  },
  {
    src: `${Config.CLOUDFRONT_CDN_PROD}/panel/templates/ceres.png`,
    templateName: 'Ceres',
    id: 4,
  },
];
