import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';
import async from 'async';
import productoDummy from '../../__mocks__/productoDummy';

const productoAgregar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/producto/new', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoEditar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/producto/${data.idProductos}`, data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoObtenerProductos = (data, callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = productoDummy.productoObtenerProductos();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  let page = data.page;
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/producto', {
        params: data,
      })
      .then((resp) => {
        //guardo en redux
        const productos = resp.data.data;
        if (page === 0) {
          dispatch({
            type: Reducers.PRODUCTO_GUARDAR,
            data: productos,
          });
        } else {
          dispatch({
            type: Reducers.PRODUCTO_GUARDAR_NO_CLEAN,
            data: productos,
          });
        }

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoObtenerProductosExportar = (data, callback) => {
  return (dispatch, _getState) => {
    const max_records = data.max_records;
    const limit = data.limit;

    let registros = {
      alcanzo_max_records: false,
      productos: [], //un registro es un producto con uno o más elementos de stock
      stock_count: 0,
    };

    let filtros_paginado = data.filtros;
    let obtener_mas_productos = true;
    let page = 0;
    let p_producto_digital = 0; //solo productos físicos

    async.whilst(
      function test(cb) {
        cb(null, obtener_mas_productos && !registros.alcanzo_max_records);
      },
      function iter(iterCallback) {
        filtros_paginado = {
          ...filtros_paginado,
          page,
          limit,
          p_producto_digital,
        };
        axios
          .get(Config.BACKEND_ENDPOINT + '/producto', {
            params: filtros_paginado,
          })
          .then((resp) => {
            const resp_filtrada = data.filtroANivelDeStock(
              resp.data.data,
              filtros_paginado
            );
            if (resp.data.data.length < limit) obtener_mas_productos = false;

            //performance. uso de push en lugar de spread operator
            registros.productos.push(...resp_filtrada);
            registros.stock_count = resp_filtrada.reduce(
              (previous_value, current_value) =>
                previous_value + current_value.stock.length,
              registros.stock_count
            );

            if (registros.stock_count > max_records)
              registros.alcanzo_max_records = true;

            page++;
            iterCallback(null, registros);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              dispatch(adminLogout());
            }
            iterCallback(Validate.procesar_error_axios(err.response), null);
          });
      },
      (err, resp) => {
        err && err.status && err.status === 401
          ? (() => {})() //noop, esperar al logout
          : callback(err, resp);
      }
    );
  };
};

const productoObtenerProducto = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(`${Config.BACKEND_ENDPOINT}/producto/${data.idProductos}`)
      .then((resp) => {
        //guardo en redux
        const producto = resp.data.data;
        dispatch({ type: Reducers.PRODUCTO_GUARDAR, data: producto });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoObtenerProductosOrdenados = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/producto/ordenado')
      .then((resp) => {
        //guardo en redux
        const productos = resp.data.data;
        dispatch({ type: Reducers.PRODUCTO_GUARDAR, data: productos });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(`${Config.BACKEND_ENDPOINT}/producto/${data.idProductos}`)
      .then((resp) => {
        //guardo en redux
        const producto = resp.data.data;
        dispatch({ type: Reducers.PRODUCTO_BORRAR, data: producto });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoDesactivarActivar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/producto/${data.idProductos}/activar-desactivar`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const producto = resp.data.data;
        dispatch({
          type: Reducers.PRODUCTO_DESACTIVAR_ACTIVAR,
          data: producto,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoAumentoMasivo = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/producto/aumento-masivo`, data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoCrearOfertaMasiva = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/producto/oferta`, data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoBorrarOfertaMasiva = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(`${Config.BACKEND_ENDPOINT}/producto/oferta`, {
        data: data,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoEditarDetalleStock = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/producto/${data.idProductos}/stock`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const stock = resp.data.data;

        dispatch({
          type: Reducers.PRODUCTO_ACTUALIZAR_STOCK,
          data: {
            stock: stock,
            idProductos: data.idProductos,
          },
        });

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoImportacionCrearChunks = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/producto/importacion/chunk', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const productoImportacionProcesarChunks = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/producto/importacion/procesar', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  productoAgregar,
  productoEditar,
  productoBorrar,
  productoObtenerProductos,
  productoObtenerProductosExportar,
  productoObtenerProducto,
  productoObtenerProductosOrdenados,
  productoAumentoMasivo,
  productoCrearOfertaMasiva,
  productoBorrarOfertaMasiva,
  productoDesactivarActivar,
  productoEditarDetalleStock,
  productoImportacionCrearChunks,
  productoImportacionProcesarChunks,
};
