const tiendaObtener = () => {
  return {
    status: 200,
    data: {
      idTienda: 110154,
      t_nombre: 'mail',
      t_email: 'mail@mail.com',
      t_deuda: 0,
      t_prueba: 0,
      t_direccion: null,
      t_telefono: null,
      t_facebook: null,
      t_twitter: null,
      t_instagram: null,
      t_pinterest: null,
      t_youtube: null,
      t_tiktok: null,
      t_logo: null,
      t_datafiscal: 0,
      t_datafiscal_codigo: null,
      t_notificacion_stock: 0,
      t_notificacion_stock_cantidad: 1,
      t_chat: 0,
      t_chat_facebook: null,
      t_chat_whatsapp: null,
      t_google_analytics: 0,
      t_google_analytics_codigo: 'UA-00000000-0',
      t_cupones_descuento: 1,
      t_blog: 0,
      t_paginas: 0,
      t_mayorista: 1,
      t_banners: 0,
      t_fecha_creacion: '2023-01-02 17:45:45',
      t_offmode: 1,
      t_offmode_mensaje: '',
      t_activa: 1,
      t_seo_descripcion:
        'Esta es la tienda online de mdq123456! Ingres\u00e1 y conoc\u00e9 nuestra amplia variedad de productos.',
      t_email_marketing: 0,
      t_facebook_pixel: 0,
      t_facebook_pixel_codigo: null,
      t_facebook_pixel_tracking: 0,
      t_formulario_contacto: 1,
      t_email_mp: '',
      t_debito_automatico: 0,
      t_pago_manual: 0,
      t_destacar_productos: 0,
      t_destacar_productos_tipo: 2,
      t_productos_digitales: 1,
      t_ordenar_productos: 0,
      t_template_data: {
        id: 8,
        css: '',
        body: {
          background: '#ffffff',
        },
        fonts: {
          text: 23,
          title: 19,
        },
        other: {
          menu_type: 0,
          border_radius: 10,
          menu_label_products: 'Productos',
          menu_label_wholesaler: 'Mayoristas',
        },
        popup: {
          html: '',
          link: '',
          text: '',
          type: 0,
          image: '',
          title: '',
          active: 0,
          button: '',
          visibility: 0,
          visibility_place: 0,
        },
        message: {
          link: '',
          text: '',
        },
        primary: {
          text: '#000000',
          background: '#ddffe3',
          contrastText: '#333333',
        },
        checkout: {
          comments: 1,
          step_review: 1,
          short_billing_form: 1,
        },
        secondary: {
          text: '#000000',
          background: '#f3f3f3',
          contrastText: '#333333',
        },
        products_feed: {
          order: 0,
          filter: 0,
          masonry: 0,
          product_subtext: 'HASTA 12 CUOTAS',
          product_subtext_type: 1,
          product_subtext_cuotas: 3,
        },
        products_detail: {
          photo_zoom: 0,
          photo_display: 1,
        },
      },
      t_pinterest_tag: null,
      t_market: 0,
      t_market_categoria_id: 1,
      t_market_categoria_id_extra: 0,
      t_market_ciudad_id: 0,
      t_alerta_envio: 0,
      t_alerta_envio_mensaje:
        'Debido a la situaci\u00f3n de cuarentena total en el pa\u00eds, los tiempos previstos de entrega pueden sufrir demoras.',
      t_script_head: null,
      t_script_body: null,
      t_favicon: 'favicon.png',
      t_producto_subtexto: 'HASTA 12 CUOTAS',
      t_carritos_abandonados: 0,
      Templates_idTemplates: 1,
      Country_idCountry: 'ARG',
      Impuesto_idImpuesto: null,
    },
    message: 'Ok',
  };
};

const tiendaObtenerOverview = () => {
  return {
    status: 200,
    data: {
      tienda: {
        idTienda: 110154,
        t_nombre: 'mail',
        t_email: 'mail@gmail.com',
        t_deuda: 0,
        t_prueba: 0,
        t_direccion: null,
        t_telefono: null,
        t_facebook: null,
        t_twitter: null,
        t_instagram: null,
        t_pinterest: null,
        t_youtube: null,
        t_tiktok: null,
        t_logo: null,
        t_datafiscal: 0,
        t_datafiscal_codigo: null,
        t_notificacion_stock: 0,
        t_notificacion_stock_cantidad: 1,
        t_chat: 0,
        t_chat_facebook: null,
        t_chat_whatsapp: null,
        t_google_analytics: 0,
        t_google_analytics_codigo: 'UA-00000000-0',
        t_cupones_descuento: 1,
        t_blog: 0,
        t_paginas: 0,
        t_mayorista: 1,
        t_banners: 0,
        t_fecha_creacion: '2023-01-02 17:45:45',
        t_offmode: 1,
        t_offmode_mensaje: '',
        t_activa: 1,
        t_seo_descripcion:
          'Esta es la tienda online de tienda! Ingres\u00e1 y conoc\u00e9 nuestra amplia variedad de productos.',
        t_email_marketing: 0,
        t_facebook_pixel: 0,
        t_facebook_pixel_codigo: null,
        t_facebook_pixel_tracking: 0,
        t_formulario_contacto: 1,
        t_email_mp: '',
        t_debito_automatico: 0,
        t_pago_manual: 0,
        t_destacar_productos: 0,
        t_destacar_productos_tipo: 2,
        t_productos_digitales: 1,
        t_ordenar_productos: 0,
        t_template_data: {
          id: 8,
          css: '',
          body: {
            background: '#ffffff',
          },
          fonts: {
            text: 23,
            title: 19,
          },
          other: {
            menu_type: 0,
            border_radius: 10,
            menu_label_products: 'Productos',
            menu_label_wholesaler: 'Mayoristas',
          },
          popup: {
            html: '',
            link: '',
            text: '',
            type: 0,
            image: '',
            title: '',
            active: 0,
            button: '',
            visibility: 0,
            visibility_place: 0,
          },
          message: {
            link: '',
            text: '',
          },
          primary: {
            text: '#000000',
            background: '#ddffe3',
            contrastText: '#333333',
          },
          checkout: {
            comments: 1,
            step_review: 1,
            short_billing_form: 1,
          },
          secondary: {
            text: '#000000',
            background: '#f3f3f3',
            contrastText: '#333333',
          },
          products_feed: {
            order: 0,
            filter: 0,
            masonry: 0,
            product_subtext: 'HASTA 12 CUOTAS',
            product_subtext_type: 1,
            product_subtext_cuotas: 3,
          },
          products_detail: {
            photo_zoom: 0,
            photo_display: 1,
          },
        },
        t_pinterest_tag: null,
        t_market: 0,
        t_market_categoria_id: 1,
        t_market_categoria_id_extra: 0,
        t_market_ciudad_id: 0,
        t_alerta_envio: 0,
        t_alerta_envio_mensaje:
          'Debido a la situaci\u00f3n de cuarentena total en el pa\u00eds, los tiempos previstos de entrega pueden sufrir demoras.',
        t_script_head: null,
        t_script_body: null,
        t_favicon: 'favicon.png',
        t_producto_subtexto: 'HASTA 12 CUOTAS',
        t_carritos_abandonados: 0,
        Templates_idTemplates: 1,
        Country_idCountry: 'ARG',
        Impuesto_idImpuesto: null,
      },
      cupon_descuento: false,
      overview: {
        ventas_activas: 2,
        envios_pendientes: 0,
        cobros_pendientes: 2,
        cantidad_clientes: 2,
      },
    },
    message: 'Ok',
  };
};

const tiendaObtenerUltimoPago = () => {
  return {
    status: 200,
    data: {
      id: 499771,
      monto: 1190,
      fecha_vencimiento: '23/02/2024',
      dias_vencimiento: 14,
      fecha_corte: '04/03/2024',
      dias_corte: 24,
      paga: 0,
      cerrada: 0,
      fecha_pago: null,
      periodo_prueba: 0,
    },
    message: 'Ok',
  };
};

export default {
  tiendaObtener,
  tiendaObtenerOverview,
  tiendaObtenerUltimoPago,
};
