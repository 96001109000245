/* eslint-disable no-unreachable */

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

const IconButtonUI_ = ({ isActive = false, classes, ...rest }) => {
  return (
    <IconButton
      {...rest}
      className={`${classes.button} ${isActive ? classes.active : ''}`}
    />
  );
};

const styles = (theme) => ({
  button: {
    backgroundColor: 'transparent',
    height: '40px',
    width: '40px',

    marginLeft: '8px',
    '& span': {
      color: '#9E9E9E',
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,

    '& span': {
      color: '#fff',
    },
  },
});

IconButtonUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //The button shows a success color
  //Default: false
  isActive: PropTypes.bool,
};

export const IconButtonUI = withStyles(styles)(IconButtonUI_);
