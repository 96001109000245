import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { ButtonUI, ToggleUI } from '../../common';
import { alpha } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../../amplitude';
import getMessage from './getMessage';

const SuccessSuscriptionMessage_ = ({
  classes,
  show,
  isDebtor,
  isTrialUser,
  isSuscribedAndNotAvailableCancel,
  message,
}) => {
  const { title, subtitle, buttonVisible, variant } =
    message ||
    getMessage({
      isDebtor,
      isTrialUser,
      isSuscribedAndNotAvailableCancel,
    });
  const router = useHistory();

  const handleOnClick = () => {
    createAmplitudeEventWithDevice(
      suscription_amplitude_events.plan_reactiva_tienda.event_name
    );

    router.push('/plan/metodo-pago');
  };

  return (
    <ToggleUI show={show}>
      <Grid
        item
        xs={12}
        className={`${classes.container} ${classes[`container${variant}`]}`}
      >
        <>
          <p className={`${classes.title} ${classes[`title${variant}`]}`}>
            {title}
          </p>
          <p className={classes.paragraph}>
            {subtitle}
            {!!subtitle && ' '}
          </p>
          {buttonVisible && (
            <ButtonUI
              type="callf"
              onClickFunc={handleOnClick}
              textTransformNone
              fullWidth={false}
              label="Reactivar tienda"
              classes={{ root: classes.buttonDanger }}
            />
          )}
        </>
      </Grid>
    </ToggleUI>
  );
};

const styles = (theme) => ({
  container: {
    padding: '24px 24px 20px',
    borderRadius: '4px',
    marginBottom: '30px',
    backgroundColor: '#fff',
  },
  containerSuccess: {
    padding: '24px',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  containerDanger: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: '500',
    margin: '0 0 8px',
  },
  titleSuccess: {
    color: theme.palette.primary.main,
  },
  titleDanger: {
    color: theme.texts.secondary,
  },
  paragraph: {
    margin: 0,
    fontSize: '1rem',
    fontWeight: 100,
  },
  buttonDanger: {
    marginTop: '12px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.8),
    },
  },
});

export const SuccessSuscriptionMessage = withStyles(styles)(
  SuccessSuscriptionMessage_
);
