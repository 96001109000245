import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export const ShopInformationStatus = ({ nameStore }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        gridColumn: { xs: '1 / 5', md: '1 / 9' },
        gridRow: 'span 1',
      }}
    >
      <Box
        p="24px 12px"
        pb={{ xs: '0px', md: '24px' }}
        sx={{ alignContent: 'center', backgroundColor: 'transparent' }}
      >
        <Typography
          variant="h1"
          fontSize="1.5rem"
          fontWeight="600"
          color={theme.palette.primary.main}
        >
          Hola, {nameStore}
        </Typography>
        <Typography
          mt={{ xs: 1, md: 2 }}
          variant="h3"
          color="#565656"
          fontSize="1rem"
        >
          Este es tu espacio para crear y gestionar tu tienda online.
        </Typography>
        <Typography
          variant="span"
          m={0}
          color="#565656"
          fontSize="1rem"
          fontWeight="500"
        >
          ¡Te damos la bienvenida a tu panel de control!
        </Typography>
      </Box>
    </Box>
  );
};
