import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI, SelectUI } from '../../../../../components/common';

const ModalEditarDatosFacturacion = (props) => {
  let {
    editar,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
    handleChangeSelect,
    selects,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="editar_datos_facturacion"
      title="Editar datos de facturación"
      modalHandler={modalHandler}
      aditional_param="editar_datos_facturacion"
      handleSubmit={handleSubmit}
      button_label="Guardar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_nombre}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_apellido}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectUI
            data={editar.v_facturacion_tipo_identificacion}
            options={selects.v_facturacion_tipo_identificacion}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item xs={6}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_numero_identificacion}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_calle}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_numero}
          />
        </Grid>
        <Grid item xs={6}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_piso}
          />
        </Grid>
        <Grid item xs={6}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_dpto}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_ciudad}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectUI
            data={editar.v_facturacion_provincia}
            options={selects.v_provincia}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_facturacion_postal}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalEditarDatosFacturacion };
