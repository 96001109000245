import { column_names } from './ExportFormat';
import { IMPORT_ERROR_MAP_NUMBER_TO_MESSAGE } from './ImportErrorCodes';

/**Mapeo de nombre de columna a índice de columna acordado */
let EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX = new Map();
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(0, column_names.nombre);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(1, column_names.stock);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(2, column_names.SKU);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(3, column_names.precio);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(4, column_names.precioOferta);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(5, column_names.precioMayorista);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(6, column_names.nombreAtributo1);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(7, column_names.valorAtributo1);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(8, column_names.nombreAtributo2);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(9, column_names.valorAtributo2);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(10, column_names.nombreAtributo3);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(11, column_names.valorAtributo3);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(12, column_names.categorias);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(13, column_names.peso);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(14, column_names.alto);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(15, column_names.ancho);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(16, column_names.profundidad);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(17, column_names.mostrarEnTienda);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(18, column_names.descripcion);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(19, column_names.IDProduct);
EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.set(20, column_names.IDStock);

/**
 * @summary: Conver a backend ideal index to the real letter in the actual worksheet
 * @param {number} ideal_index
 * @returns {ImportError[]} the column exists in the columns list
 */
export const convertIdealIndexToColumnName = (ideal_index) => {
  const column_name = EQUIV_COLUMN_NAME_WITH_BACKEND_INDEX.get(ideal_index);
  return column_name ? column_name : 'N/A';
};

/**
 * @typedef {Object} BackendImportError
 * @property {number} item
 * @property {number[]} row
 * @property {string } product_name
 * @property {number} error
 * @property {number} [column] ideal column number
 */

/**
 * Conver array of backend errors to front-end import errors
 * @param {BackendImportError[]} chunk_errors - errors of a chunk
 * @returns {ImportError[]} the column exists in the columns list
 */
export const convertListOfBackendErrors = (chunk_errors) => {
  let import_errors = [];
  for (const backend_error of chunk_errors) {
    for (const row of backend_error.row) {
      import_errors.push(
        new ImportError(
          backend_error.product_name,
          row,
          convertIdealIndexToColumnName(backend_error.column),
          IMPORT_ERROR_MAP_NUMBER_TO_MESSAGE.get(backend_error.error)
        )
      );
    }
  }
  return import_errors;
};

/**
 * Class representing an import error
 *
 */
export class ImportError {
  /**
   * Create an import error
   * @param {string} product_name
   * @param {number} row_number
   * @param {string} column_name
   * @param {string} error_type
   */
  constructor(product_name, row_number, column_name, error_type) {
    this.product_name = product_name;
    this.row_number = row_number;
    this.column_name = column_name; //puede venir 'N/A'
    this.error_type = error_type;
  }
}
