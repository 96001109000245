import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  InputUI,
  ToggleUI,
  SelectUI,
  ModuleCardTitleUI,
} from '../../../common';

const DimensionesSection = (props) => {
  let {
    dimensiones_requeridas,
    handleChange,
    handleChangeSelect,
    selects,
    atributos_off,
    common,
  } = props;

  return (
    <Grid container spacing={common.p_atributos.value === 0 ? 2.5 : 1.5}>
      <ToggleUI show={common.p_atributos.value === 0}>
        <Grid item xs={12}>
          <Typography
            as="h3"
            color="#333333"
            sx={{ fontSize: '1.1rem' }}
            mb={0.5}
            data-testid={'dimensions-title-id'}
          >
            Dimensiones y peso
          </Typography>
          <Typography
            fontSize="14px"
            color="#666666"
            as="p"
            data-testid={'first-dimension-subtitle-id'}
          >
            Las dimensiones y el peso son importantes para calcular el costo del
            envío.
          </Typography>
          <Typography
            fontSize="14px"
            color="#666666"
            as="p"
            data-testid={'second-dimension-subtitle-id'}
          >
            Tenés que ingresar las medidas del paquete o caja del producto de
            manera individual.
          </Typography>
        </Grid>
      </ToggleUI>
      <ToggleUI show={!dimensiones_requeridas}>
        <Grid item xs={12}>
          <SelectUI
            data={atributos_off.ui_dimensiones}
            options={selects.ui_dimensiones}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
      </ToggleUI>
      <ToggleUI show={atributos_off.ui_dimensiones.value ? true : false}>
        <Grid item md={3} sm={6} xs={6}>
          <InputUI
            handleChange={handleChange}
            input={atributos_off.p_alto}
            end_adornment="cm"
            step="1"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <InputUI
            handleChange={handleChange}
            input={atributos_off.p_ancho}
            end_adornment="cm"
            step="1"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <InputUI
            handleChange={handleChange}
            input={atributos_off.p_profundidad}
            end_adornment="cm"
            step="1"
          />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <InputUI
            handleChange={handleChange}
            input={atributos_off.p_peso}
            end_adornment="kg"
            step="0.01"
          />
        </Grid>
        <ToggleUI show={common.p_atributos.value === 1}>
          <ModuleCardTitleUI
            subtitles={[
              'Las dimensiones y el peso son importantes para que se pueda calcular el costo del envío.',
              'Debes ingresar las dimensiones del paquete o caja del producto (individualmente).',
            ]}
          />
        </ToggleUI>
      </ToggleUI>
    </Grid>
  );
};
export { DimensionesSection };
