import React, { useEffect } from 'react';
import { ModuleTitleUI } from '../../common';
import { WrapperLayout } from '../../../layouts/WrapperLayout';
import { Grid } from '@mui/material';
import { SuccessSuscriptionMessage } from '../subcomponents/SuccessSuscriptionMessage';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../../amplitude';

export default function Reactivation() {
  useEffect(() => {
    createAmplitudeEventWithDevice(
      suscription_amplitude_events.inicio_con_deuda.event_name
    );
  }, []);

  return (
    <WrapperLayout>
      <ModuleTitleUI title="Mi plan" />
      <Grid container>
        <SuccessSuscriptionMessage
          show={true}
          message={{
            variant: 'Danger',
            title: 'Tu tienda está desactivada por falta de pago',
            subtitle:
              'Te invitamos a reactivarla completando el pago de manera manual o por suscripción al débito automático.',
            buttonVisible: true,
          }}
        />
      </Grid>
    </WrapperLayout>
  );
}
