import React from 'react';
import { Box, Grid } from '@mui/material';
import { ButtonUI, SelectUI } from '../../../../components/common';
import { addBlockOptions } from '../../jsonSelects';

export const BlockAdd = ({ agregar, handleChangeSelect, handleAddBlock }) => {
  return (
    <Box component="form" onSubmit={handleAddBlock}>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <SelectUI
            data={agregar.bl_tipo}
            options={addBlockOptions}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonUI
            type="submit"
            label="Agregar"
            variant="outlined"
            color="primary"
            size="small"
            disabled={agregar.bl_tipo.value === 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
