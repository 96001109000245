import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../components/common';

export const ModalArchivar = ({
  orderNumberToArchive,
  handleSubmit,
  modalHandler,
  isArchiveOrderModalOpen,
  isArchiveOrderLoading,
}) => {
  return (
    <ModalUI
      open={isArchiveOrderModalOpen}
      id="archivar_orden"
      title="Archivar orden"
      modalHandler={modalHandler}
      aditional_param="archivar_orden"
      handleSubmit={handleSubmit}
      button_label="Archivar"
      button_loading={isArchiveOrderLoading}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            ¿Confirmás que deseas archivar la orden {orderNumberToArchive}? La
            misma desaparecera por defecto de la lista de ordenes y podrá volver
            a ser visualizada utilizando el fitro "Etiqueta de orden".
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};
