import React from 'react';
import { Box, Icon, Tooltip, Typography } from '@mui/material';
import { ToggleUI } from '../../../components/common';
import { withStyles } from '@mui/styles';

const InfoText_ = ({
  title,
  classes,
  text = '',
  first = false,
  subtitle = '',
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      mb="15px"
      mt={first ? '0px' : '20px'}
    >
      <Box mr="5px">
        <Typography
          sx={{
            fontWeight: 500,
            userSelect: 'none',
          }}
        >
          {title}
        </Typography>
        <ToggleUI show={subtitle ? true : false}>
          <Typography
            sx={{
              padding: 0,
              margin: 0,
              color: '#9e9e9e',
              fontSize: '0.8rem',
              userSelect: 'none',
            }}
          >
            {subtitle}
          </Typography>
        </ToggleUI>
      </Box>
      <ToggleUI show={text ? true : false}>
        <Tooltip
          placement="right"
          classes={{ tooltip: classes.tooltip }}
          PopperProps={{
            sx: {
              opacity: 1,
              maxWidth: '232px',
            },
          }}
          title={text}
        >
          <Icon
            sx={{
              fontSize: '20px !important',
            }}
          >
            help_outline
          </Icon>
        </Tooltip>
      </ToggleUI>
    </Box>
  );
};

const styles = (theme) => ({
  tooltip: {
    fontWeight: 400,
    fontSize: '.875rem',
    backgroundColor: theme.texts.secondary,
  },
});

const InfoText = withStyles(styles)(InfoText_);

export { InfoText };
