/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  metodosenvioObtener,
  metodoenvioConfigurarCorreoArgentino,
} from '../../../store/actions';
import { Config, Regex, RegexExtra, Validate } from '../../../other';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  CheckboxUI,
  SelectUI,
  InputUI,
  ToggleUI,
  ModuleCardTitleUI,
} from '../../../components/common';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

class CorreoArgentino_ extends Component {
  state = {
    correo_argentino: {
      me_correo_argentino: {
        validate: false,
        name: 'me_correo_argentino',
        label: 'Activar Correo Argentino',
        value: 1,
        change_param: 'correo_argentino',
      },
      me_correo_argentino_agree: {
        validate: false,
        name: 'me_correo_argentino_agree',
        label:
          'Acepto que esta integración con el tarifario de Correo Argentino no es oficial y las tarifas pueden no ser exactas a las de Mi Correo. Entiendo que sólo es una integración de parte de Empretienda para automatizar el cálculo de costos de envío y la selección de sucursales para los usuarios. Tengo las dimensiones de los productos cargadas correctamente. Vi el tutorial en video y acepto utilizar la integración con esta modalidad.',
        value: 0,
        change_param: 'correo_argentino',
      },
      me_correo_argentino_clasico: {
        validate: false,
        name: 'me_correo_argentino_clasico',
        label:
          'Habilitar la modalidad encomienda clásica (El tiempo de entrega es de 3 a 6 días)',
        value: 1,
        change_param: 'correo_argentino',
      },
      me_correo_argentino_prioritario: {
        validate: false,
        name: 'me_correo_argentino_prioritario',
        label:
          'Habilitar la modalidad encomienda prioritaria (El tiempo de entrega es de 1 a 3 días)',
        value: 1,
        change_param: 'correo_argentino',
      },
      me_correo_argentino_email: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Usuario de correo argentino',
        id: 'me_correo_argentino_email',
        name: 'me_correo_argentino_email',
        change_param: 'correo_argentino',
        regex: Regex.EMAIL,
        value: '',
        default_value: '',
        placeholder: 'usuario@mail.com',
        messages: {
          error:
            'El mail no tiene el formato correcto o no coincide con una cuenta de Correo Argentino.',
          help: (
            <>
              Ingresá el mail de tu cuenta. Podés crear una cuenta desde{' '}
              <a
                href="https://www.correoargentino.com.ar/MiCorreo/public"
                target="_blank"
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                Correo Argentino
              </a>
              .
            </>
          ),
          error_extra: RegexExtra.EMAIL,
        },
      },
      me_correo_argentino_password: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'password',
        label: 'Clave de correo argentino',
        id: 'me_correo_argentino_password',
        name: 'me_correo_argentino_password',
        change_param: 'correo_argentino',
        regex: /./g,
        value: '',
        default_value: '',
        placeholder: '*********',
        messages: {
          error: 'La clave es incorrecta.',
          help: 'La clave utilizada para iniciar sesión en Correo Argentino',
          error_extra: '',
        },
      },
      me_correo_argentino_tipo_cuenta: {
        validate: false,
        required: true,
        disabled: false,
        error: false,
        label: 'Tipo de cuenta',
        id: 'me_correo_argentino_tipo_cuenta',
        name: 'me_correo_argentino_tipo_cuenta',
        change_param: 'correo_argentino',
        value: 0,
        messages: {
          error: 'Por favor, elija el tipo de cuenta de Mi Correo',
          help: 'Por favor, elija el tipo de cuenta de Mi Correo',
        },
      },
      me_correo_argentino_postal: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'me_correo_argentino_postal',
        name: 'me_correo_argentino_postal',
        change_param: 'correo_argentino',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: 'XXXX',
        messages: {
          error: 'Por favor, ingrese un código postal válido',
          help: 'Ingrese su código postal, será utilizado para calcular los costos de envío',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
      me_correo_argentino_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Ofrecer envío gratis',
        id: 'me_correo_argentino_tipo',
        name: 'me_correo_argentino_tipo',
        change_param: 'correo_argentino',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_correo_argentino_gratis_sucursal: {
        validate: false,
        name: 'me_correo_argentino_gratis_sucursal',
        label: 'Ofrecer envío gratis para envíos a sucursal',
        value: 0,
        change_param: 'correo_argentino',
      },
      me_correo_argentino_gratis_domicilio: {
        validate: false,
        name: 'me_correo_argentino_gratis_domicilio',
        label: 'Ofrecer envío gratis para envíos a domicilio',
        value: 0,
        change_param: 'correo_argentino',
      },
      me_correo_argentino_gratis_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'me_correo_argentino_gratis_minimo',
        name: 'me_correo_argentino_gratis_minimo',
        change_param: 'correo_argentino',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '3000',
        messages: {
          help: 'El monto mínimo requerido de la compra para poder acceder al envío gratis',
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
      me_correo_argentino_recargo_monto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto fijo a recargar/descontar',
        id: 'me_correo_argentino_recargo_monto',
        name: 'me_correo_argentino_recargo_monto',
        change_param: 'correo_argentino',
        regex: Regex.PRECIO_CON_SIGNO.ARG,
        value: '',
        default_value: '',
        placeholder: '50.00',
        messages: {
          error: 'Por favor, ingrese un monto válido',
          error_extra: RegexExtra.PRECIO_CON_SIGNO.ARG,
        },
      },
      me_correo_argentino_recargo_porcentaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje sobre la tarifa a recargar/descontar',
        id: 'me_correo_argentino_recargo_porcentaje',
        name: 'me_correo_argentino_recargo_porcentaje',
        change_param: 'correo_argentino',
        regex: Regex.PORCENTAJE_CON_SIGNO,
        value: '',
        default_value: '',
        placeholder: '5',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.PORCENTAJE_CON_SIGNO,
        },
      },
    },
    loading: {
      correo_argentino: true,
      error: false,
    },
    forms: {
      correo_argentino: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      me_correo_argentino_tipo_cuenta: [
        {
          value: 0,
          label: 'CONSUMIDOR FINAL',
        },
        {
          value: 1,
          label: 'PYME',
        },
      ],
      me_correo_argentino_tipo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si (Para todos los productos)',
        },
        {
          value: 2,
          label: 'Si (Ventas mayores a cierto monto)',
        },
      ],
    },
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            correo_argentino: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          this.state.correo_argentino,
          (correo_argentino_obj) => {
            const isUserMigrated = !!resp.data.me_correo_argentino_email;
            const isUserMigratedOrNew =
              isUserMigrated ||
              (!resp.data.me_correo_argentino &&
                !resp.data.me_correo_argentino_postal);

            this.setState((prevState) => ({
              correo_argentino: {
                ...correo_argentino_obj,
                me_correo_argentino_agree: {
                  ...correo_argentino_obj.me_correo_argentino_agree,
                  value: isUserMigratedOrNew,
                },
                me_correo_argentino_password: {
                  ...prevState.correo_argentino.me_correo_argentino_password,
                  validate: !isUserMigrated,
                  required: !isUserMigrated,
                },
              },
              loading: {
                ...prevState.loading,
                correo_argentino: false,
                error: false,
              },
            }));
          }
        );
      }
    });
  }

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    Validate.validate_input(
      {
        ...this.state[aditional][name],
        value: value,
      },
      blur,
      (input) => {
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: input,
          },
        }));
      }
    );
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: prevState[aditional][name]['value'] ? 0 : 1,
        },
      },
    }));
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleClickShowPassword = () => {
    const newInputType =
      this.state.correo_argentino.me_correo_argentino_password.type ===
      'password'
        ? 'text'
        : 'password';
    this.setState((prevState) => ({
      correo_argentino: {
        ...prevState.correo_argentino,
        me_correo_argentino_password: {
          ...prevState.correo_argentino.me_correo_argentino_password,
          type: newInputType,
        },
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        correo_argentino: true,
      },
    }));

    const isValidated = !!this.state.correo_argentino.me_correo_argentino.value;

    let form = {
      ...this.state.correo_argentino,
      me_correo_argentino_email: {
        ...this.state.correo_argentino.me_correo_argentino_email,
        validate: isValidated,
      },
      me_correo_argentino_password: {
        ...this.state.correo_argentino.me_correo_argentino_password,
        validate: isValidated,
      },
      me_correo_argentino_postal: {
        ...this.state.correo_argentino.me_correo_argentino_postal,
        validate: isValidated,
      },
    };

    const isUserMigrated = !!this.props.metodos_envio.me_correo_argentino_email;
    const isDataIncompleted =
      !form.me_correo_argentino_postal.value ||
      !form.me_correo_argentino_email.value ||
      (!isUserMigrated && !form.me_correo_argentino_password.value);
    const isDataError =
      form.me_correo_argentino_postal.error ||
      form.me_correo_argentino_email.error ||
      form.me_correo_argentino_password.error;
    const ERROR_ALERT_MESSAGE =
      'Los datos de cuenta de correo argentino no son correctos';

    if (
      !!this.state.correo_argentino.me_correo_argentino.value &&
      (isDataIncompleted || isDataError)
    ) {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          correo_argentino: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: ERROR_ALERT_MESSAGE,
          type: 'error',
        },
      }));
      return;
    }

    if (
      (form.me_correo_argentino_agree.value &&
        form.me_correo_argentino.value) ||
      !form.me_correo_argentino.value
    ) {
      if (
        ((form.me_correo_argentino_clasico.value ||
          form.me_correo_argentino_prioritario.value) &&
          form.me_correo_argentino.value) ||
        !form.me_correo_argentino.value
      ) {
        if (Validate.validar_formulario(form)) {
          this.props.metodoenvioConfigurarCorreoArgentino(
            {
              me_correo_argentino_email: form.me_correo_argentino.value
                ? form.me_correo_argentino_email.value
                : '',
              me_correo_argentino_password: form.me_correo_argentino.value
                ? form.me_correo_argentino_password.value
                : '',
              me_correo_argentino: form.me_correo_argentino.value,
              me_correo_argentino_tipo_cuenta:
                form.me_correo_argentino_tipo_cuenta.value,
              me_correo_argentino_postal: form.me_correo_argentino_postal.value,
              me_correo_argentino_prioritario:
                form.me_correo_argentino_prioritario.value,
              me_correo_argentino_clasico:
                form.me_correo_argentino_clasico.value,
              me_correo_argentino_tipo: form.me_correo_argentino_tipo.value,
              me_correo_argentino_gratis_sucursal:
                form.me_correo_argentino_gratis_sucursal.value,
              me_correo_argentino_gratis_domicilio:
                form.me_correo_argentino_gratis_domicilio.value,
              me_correo_argentino_gratis_minimo:
                form.me_correo_argentino_gratis_minimo.value,
              me_correo_argentino_recargo_porcentaje:
                form.me_correo_argentino_recargo_porcentaje.value,
              me_correo_argentino_recargo_monto:
                form.me_correo_argentino_recargo_monto.value,
            },
            (err, resp) => {
              this.setState(
                (prevState) => ({
                  forms: {
                    ...prevState.forms,
                    correo_argentino: false,
                  },
                  alert: {
                    ...prevState.alert,
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success',
                  },
                }),
                () => {
                  if (!err) {
                    if (form.me_correo_argentino.value === 1) {
                      createAmplitudeEventWithDevice(
                        method_shipment_amplitude_events.metodos_envio_activar
                          .event_name,
                        { metodo_envio: 'correo_argentino', success: true }
                      );

                      if (this.props.idCountry === 'ARG') {
                        braze.logCustomEvent(
                          'empretienda_metodos_envío_activar',
                          {
                            metodo_envio: 'correo_argentino',
                          }
                        );
                      }
                    } else {
                      createAmplitudeEventWithDevice(
                        method_shipment_amplitude_events
                          .metodos_envio_desactivar.event_name,
                        { metodo_envio: 'correo_argentino' }
                      );
                    }
                  }
                }
              );
            }
          );
        } else {
          Validate.validar_formulario_actualizar_obj(form, (new_form) => {
            this.setState((prevState) => ({
              forms: {
                ...prevState.forms,
                correo_argentino: false,
              },
              correo_argentino: new_form,
              alert: {
                ...prevState.alert,
                open: true,
                message: ERROR_ALERT_MESSAGE,
                type: 'error',
              },
            }));
          });
        }
      } else {
        this.setState(
          (prevState) => ({
            forms: {
              ...prevState.forms,
              correo_argentino: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message:
                'Debes seleccionar al menos una de las dos modalidades de envío',
              type: 'error',
            },
          }),
          () => {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'correo_argentino', success: false }
            );
          }
        );
      }
    } else {
      this.setState(
        (prevState) => ({
          forms: {
            ...prevState.forms,
            correo_argentino: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message:
              'Debes aceptar los términos de uso para poder activar Correo Argentino',
            type: 'error',
          },
        }),
        () => {
          createAmplitudeEventWithDevice(
            method_shipment_amplitude_events.metodos_envio_activar.event_name,
            { metodo_envio: 'correo_argentino', success: false }
          );
        }
      );
    }
  };

  render() {
    let classes = this.props.classes;

    let ejemplo = 500.0;
    let porcentaje = Validate.trim_float(
      this.state.correo_argentino.me_correo_argentino_recargo_porcentaje.value
    );
    let porcentaje_a_ejemplo = Validate.trim_float(
      Validate.get_fixed_percent(ejemplo, porcentaje)
    );
    let monto = Validate.trim_float(
      this.state.correo_argentino.me_correo_argentino_recargo_monto.value
    );
    let total = ejemplo + porcentaje_a_ejemplo + monto;
    total = total > 0 ? total : 0;
    let porcentaje_label = porcentaje >= 0 ? 'recargo' : 'descuento';
    let monto_fijo_label = monto >= 0 ? 'recargo' : 'descuento';
    const isUserMigratedOrNew =
      this.props.metodos_envio.me_correo_argentino_email ||
      (!this.props.metodos_envio.me_correo_argentino &&
        !this.props.metodos_envio.me_correo_argentino_postal);

    return (
      <WrapperLayout
        loading={this.state.loading.correo_argentino}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="Correo Argentino"
                image_size={Validate.is_mobile() ? '60px' : '75px'}
                image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/correoargentino-logo.png`}
                subtitles={[
                  <span>
                    Para utilizar esta integración con el tarifario de Correo
                    Argentino es necesario que tengas una cuenta en Mi Correo.
                    Podés crear la tuya desde{' '}
                    <a
                      href="https://www.correoargentino.com.ar/MiCorreo/public"
                      target="_blank"
                      style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                      aquí
                    </a>
                    .
                  </span>,
                  <span>
                    Esta integración sirve para automatizar los cálculos de
                    costos de envío.
                  </span>,
                  <span>
                    Deberás dar de alta el envío, abonarlo e imprimir la
                    etiqueta de envío desde Mi Correo.
                  </span>,
                ]}
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Información de Mi Correo"
                      subtitles={[
                        'En esta sección debes configurar los datos de tu cuenta de Mi Correo para poder calcular los costos de envío.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={{
                          ...this.state.correo_argentino
                            .me_correo_argentino_email,
                          error:
                            (this.state.correo_argentino
                              .me_correo_argentino_email
                              ? this.state.correo_argentino
                                  .me_correo_argentino_email.error
                              : false) &&
                            !!this.state.correo_argentino.me_correo_argentino
                              .value,
                          validate:
                            !!this.state.correo_argentino.me_correo_argentino
                              .value,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={{
                          ...this.state.correo_argentino
                            .me_correo_argentino_password,
                          error:
                            (this.state.correo_argentino
                              .me_correo_argentino_password
                              ? this.state.correo_argentino
                                  .me_correo_argentino_password.error
                              : false) &&
                            !!this.state.correo_argentino.me_correo_argentino
                              .value,
                          validate:
                            !!this.state.correo_argentino.me_correo_argentino
                              .value,
                        }}
                        showDefaultAdornment={false}
                        end_adornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Presione el botón para visibilizar la contraseña."
                              onClick={this.handleClickShowPassword}
                              style={{ right: '-14px', position: 'relative' }}
                              size="large"
                            >
                              {this.state.correo_argentino
                                .me_correo_argentino_password.type ===
                              'text' ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectUI
                        data={
                          this.state.correo_argentino
                            .me_correo_argentino_tipo_cuenta
                        }
                        options={
                          this.state.selects.me_correo_argentino_tipo_cuenta
                        }
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={
                          this.state.correo_argentino.me_correo_argentino_postal
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={
                          this.state.correo_argentino.me_correo_argentino
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Envío gratis"
                      subtitles={[
                        'En esta sección podés configurar los envíos gratuitos a través de Correo Argentino.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <SelectUI
                        data={
                          this.state.correo_argentino.me_correo_argentino_tipo
                        }
                        options={this.state.selects.me_correo_argentino_tipo}
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <ToggleUI
                      show={
                        this.state.correo_argentino.me_correo_argentino_tipo
                          .value === 2
                      }
                    >
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={
                            this.state.correo_argentino
                              .me_correo_argentino_gratis_minimo
                          }
                          adornment="$"
                          step="0.01"
                        />
                      </Grid>
                    </ToggleUI>
                    <ToggleUI
                      show={
                        this.state.correo_argentino.me_correo_argentino_tipo
                          .value >= 1
                      }
                    >
                      <Grid item xs={12}>
                        <CheckboxUI
                          handleChangeCheckbox={this.handleChangeCheckbox}
                          checkbox={
                            this.state.correo_argentino
                              .me_correo_argentino_gratis_sucursal
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxUI
                          handleChangeCheckbox={this.handleChangeCheckbox}
                          checkbox={
                            this.state.correo_argentino
                              .me_correo_argentino_gratis_domicilio
                          }
                        />
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Modalidades de envío"
                      subtitles={[
                        'En esta seccion debes configurar que tipo de modalidad de envío vas a ofrecer.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={
                          this.state.correo_argentino
                            .me_correo_argentino_clasico
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={
                          this.state.correo_argentino
                            .me_correo_argentino_prioritario
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <ModuleCardTitleUI
                      title="Recargos y/o descuentos (opcional)"
                      subtitles={[
                        'Podés configurar un aumento o disminución en monto fijo y/o porcentaje al monto tarifado por Correo Argentino. Por ejemplo, podés agregar el porcentaje de comisión por venta de los métodos de pago, costos de embalaje, despacho, etc o hacer un descuento por una parte del envío a tu cliente y vos abonar esa diferencia.',
                        'Para descontar sólo tenés que poner el signo menos (-) delante del monto y/o porcentaje.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={
                          this.state.correo_argentino
                            .me_correo_argentino_recargo_porcentaje
                        }
                        end_adornment="%"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={
                          this.state.correo_argentino
                            .me_correo_argentino_recargo_monto
                        }
                        adornment="$"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body1" mb={1}>
                        Ejemplo de cómo tarifaría suponiendo que el costo del
                        envío sea de ${Validate.number_format(ejemplo)} (monto
                        no real, los costos de envío pueden variar según origen,
                        destino, dimensiones y peso del paquete):
                      </Typography>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Ejemplo tarifa final Correo Argentino
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(ejemplo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Porcentaje de {porcentaje_label} ({porcentaje}%)
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(porcentaje_a_ejemplo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Monto de {monto_fijo_label}
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(monto)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Tarifa final a pagar por el cliente
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(total)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <ToggleUI show={!isUserMigratedOrNew}>
                      <Grid item xs={12}>
                        <CheckboxUI
                          handleChangeCheckbox={this.handleChangeCheckbox}
                          checkbox={
                            this.state.correo_argentino
                              .me_correo_argentino_agree
                          }
                        />
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  minHeight={true}
                  isLoading={this.state.forms.correo_argentino}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodoenvioConfigurarCorreoArgentino: (data, callback) =>
      dispatch(metodoenvioConfigurarCorreoArgentino(data, callback)),
  };
};

const CorreoArgentinoPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CorreoArgentino_)
);

export default CorreoArgentinoPage;
