import React, { useRef, useState } from 'react';
import Editor from './Editor';
import { Box, Grid } from '@mui/material';
import { ModalUI } from './ModalUI';
import { InputUI } from './InputUI';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import { EmpicUI } from './EmpicUI';
import { showAlertUI } from '../../store/actions/alertActions';
import { connect } from 'react-redux';
import { uploadImageEditor } from '../../store/actions';

const NewQuillUI_ = ({
  placeholder,
  defaultValue,
  dispatchAlert,
  handleChangeQuill,
  uploadImageEditor,
}) => {
  const quillRef = useRef();
  const [isModalOpen, setModalOpen] = useState({
    link: false,
    video: false,
    image: false,
  });
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);

  const [state, setState] = useState({
    link: {
      html_link: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link a utilizar',
        id: 'html_link',
        name: 'html_link',
        change_param: 'link',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          help: 'Si el link va a un sitio web externo, debe poseer http o https al inicio',
          error: 'Por favor, ingrese un link válido',
          error_extra: RegexExtra.LINK,
        },
      },
    },
    video: {
      html_video: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link del video',
        id: 'html_video',
        name: 'html_video',
        change_param: 'video',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          help: 'Acá ingresá el link del video deseado',
          error: 'Por favor, ingrese un link de video válido',
          error_extra: RegexExtra.LINK,
        },
      },
    },
    image: {
      validate: false,
      auth: false,
      file: null,
      required: true,
      label: '',
      id: 'quill',
      sublabels: [],
      files: [],
      messages: {
        help: '',
        error: '',
      },
    },
  });

  const toggleModalHandler = (isOpen, typeModal) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [typeModal]: isOpen,
    }));
  };

  const handleChange = (e, blur, aditional) => {
    const value = e.target.value;
    const name = e.target.name;

    Validate.validate_input(
      { ...state[aditional][name], value: value },
      blur,
      (input) => {
        setState((prevState) => ({
          ...prevState,
          [aditional]: {
            ...prevState[aditional],
            [name]: input,
          },
        }));
      }
    );
  };

  const handleChangeFile = (_err, data) => {
    setState((prevState) => ({
      ...prevState,
      image: data,
    }));
  };

  const handleSubmitModalVideo = (e) => {
    e.preventDefault();
    const form = state.video;

    if (Validate.validar_formulario(form)) {
      let format = false;
      let videoUrl = form.html_video.value;

      //reviso si necesito manipular el video de youtube
      if (Validate.contiene_substring(videoUrl, 'youtu.be/')) {
        videoUrl = videoUrl.split('youtu.be/')[1];
        format = true;
      } else if (Validate.contiene_substring(videoUrl, '/watch?v=')) {
        if (Validate.contiene_substring(videoUrl, '&list=')) {
          videoUrl = videoUrl.split('&list=')[0];
          videoUrl = videoUrl.split('/watch?v=')[1];
          format = true;
        } else {
          videoUrl = videoUrl.split('/watch?v=')[1];
          format = true;
        }
      }

      if (format) {
        videoUrl = 'https://www.youtube.com/embed/' + videoUrl;
      }

      const length = quillRef.current.getLength();
      quillRef.current.format('video', videoUrl);
      quillRef.current.insertText(length, '\n', 'user');

      const form_obj = Validate.limpiar_formulario(state.video);

      setState((prevState) => ({
        ...prevState,
        video: form_obj,
      }));

      toggleModalHandler(false, 'video');
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          video: new_form,
        }));
      });
    }
  };

  const handleSubmitModalLink = (e) => {
    e.preventDefault();
    const form = state.link;

    if (Validate.validar_formulario(form)) {
      quillRef.current.format('link', form.html_link.value);
      toggleModalHandler(false, 'link');
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          link: new_form,
        }));
      });
    }
  };

  const handleSubmitModalImage = (e) => {
    e.preventDefault();

    setLoadingImageUpload(true);

    const form = state.image;

    if (form.auth) {
      uploadImageEditor(
        {
          file: form.files[0].file,
        },
        (err, resp) => {
          if (err) {
            setLoadingImageUpload(false);

            dispatchAlert({
              open: true,
              message: resp.message,
              type: 'error',
            });
          } else {
            const range = quillRef.current.getSelection(true);
            const position = range ? range.index : quillRef.current.getLength();

            if (resp.data.link) {
              quillRef.current.insertEmbed(
                position,
                'image',
                resp.data.link,
                'user'
              );

              quillRef.current.setSelection(position + 1);
            }

            setState((prevState) => ({
              ...prevState,
              image: {
                ...prevState.image,
                files: [],
                auth: false,
                validate: false,
              },
            }));

            setLoadingImageUpload(false);
            toggleModalHandler(false, 'image');
          }
        }
      );
    } else {
      setLoadingImageUpload(false);
      dispatchAlert({
        open: true,
        type: 'error',
        message: 'Por favor, seleccione una imagen',
      });
    }
  };

  return (
    <Box>
      <ModalUI
        id="video"
        title="Agregar video"
        button_label="Agregar"
        button_loading={false}
        aditional_param="video"
        open={isModalOpen.video}
        handleSubmit={handleSubmitModalVideo}
        modalHandler={() => toggleModalHandler(false, 'video')}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={state.video.html_video}
            />
          </Grid>
        </Grid>
      </ModalUI>
      <ModalUI
        id="link"
        title="Agregar link"
        button_label="Agregar"
        aditional_param="link"
        button_loading={false}
        open={isModalOpen.link}
        handleSubmit={handleSubmitModalLink}
        modalHandler={() => toggleModalHandler(false, 'link')}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <InputUI handleChange={handleChange} input={state.link.html_link} />
          </Grid>
        </Grid>
      </ModalUI>
      <ModalUI
        id="image"
        button_label="Subir"
        title="Agregar imagen"
        aditional_param="upload"
        open={isModalOpen.image}
        button_loading={loadingImageUpload}
        button_disabled={!state.image.auth}
        handleSubmit={handleSubmitModalImage}
        modalHandler={() => toggleModalHandler(false, 'image')}
      >
        <EmpicUI
          xs={12}
          sm={12}
          md={12}
          lg={12}
          max_size={7}
          max_files={1}
          row_height="200px"
          data={state.image}
          reducer_max_width={960}
          reducer_max_height={960}
          handleChange={handleChangeFile}
          allowed_files={['jpg', 'png', 'jpeg', 'webp']}
          cdn_prefix={Config.CLOUDFRONT_DESCIMAGES_CDN}
          input_allowed_mime="image/jpg,image/jpeg,image/png,image/webp"
        />
      </ModalUI>

      <Editor
        ref={quillRef}
        placeholder={placeholder}
        defaultValue={defaultValue}
        dispatchAlert={dispatchAlert}
        handleChangeQuill={handleChangeQuill}
        toggleModalHandler={toggleModalHandler}
      />
    </Box>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (data) => dispatch(showAlertUI(data)),
    uploadImageEditor: (data, callback) =>
      dispatch(uploadImageEditor(data, callback)),
  };
};

const NewQuillUI = connect(mapStateToProps, mapDispatchToProps)(NewQuillUI_);

export default NewQuillUI;
