import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  tiendaObtener,
  clienteObtenerClientes,
  tiendaActualizarEmailMarketing,
  clienteExportarNuevosSuscriptores,
} from '../../store/actions';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Button,
} from '@mui/material';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ToggleUI,
} from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { useTheme } from '@mui/material/styles';

const EmailMarketingPage = ({ classes }) => {
  const [isGetClientsLoading, setIsGetClientsLoading] = useState(true);
  const [
    isEmailMarketingActivationLoading,
    setIsEmailMarketingActivationLoading,
  ] = useState(false);
  const [isShopDataLoading, setIsShopDataLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const tienda = useSelector((state) => state.tienda);
  const clientes = useSelector((state) => state.clientes);

  useEffect(() => {
    dispatch(
      tiendaObtener((err, _resp) => {
        setIsShopDataLoading(false);
        setIsError(err);
      })
    );

    dispatch(
      clienteObtenerClientes({ cl_bloqueado: 0 }, (err, _resp) => {
        setIsError(err);
        setIsGetClientsLoading(false);
      })
    );
  }, [dispatch]);

  const handleCloseAlert = () => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      open: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsEmailMarketingActivationLoading(true);

    dispatch(
      tiendaActualizarEmailMarketing(
        {
          t_email_marketing: tienda.t_email_marketing ? 0 : 1,
        },
        (err, resp) => {
          setIsEmailMarketingActivationLoading(false);
          setAlert({
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          });
        }
      )
    );
  };

  const generateTXT = (clientes, filename) => {
    let list = '';
    clientes.forEach((cliente) => {
      list += cliente.cl_email + '\n';
    });
    let data = new Blob([list], { type: 'text/plain' });
    let anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(data);
    anchor.download = filename + '.txt';
    anchor.click();
  };

  const handleExportNewSubscribers = (e) => {
    e.preventDefault();

    let newSubscribers = clientes.filter(
      (cliente) => cliente.cl_exportado === 0
    );

    if (newSubscribers.length) {
      dispatch(
        clienteExportarNuevosSuscriptores((err, resp) => {
          if (err) {
            setAlert({
              open: true,
              message: resp.message,
              type: 'error',
            });
          } else {
            generateTXT(newSubscribers, 'nuevos_suscriptores');
          }
        })
      );
    }
  };

  const handleExportAllSubscribers = (e) => {
    e.preventDefault();
    generateTXT(clientes, 'todos_los_suscriptores');
  };

  const clientsQuantity = clientes.length;
  let unexportedClientsQuantity = clientes.filter(
    (cliente) => cliente.cl_exportado === 0
  ).length;

  return (
    <WrapperLayout
      loading={isShopDataLoading || isGetClientsLoading}
      error={isError}
    >
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Email marketing"
            subtitles={[
              'El email marketing es una herramienta de comunicación directa con tus clientes a través de correo electrónico. Podés enviar comunicaciones, promociones, informar sobre un nuevo artículo en tu blog, etc.',
              'En esta sección vas a poder exportar la lista de contactos de tu newsletter (suscripciones) o de tus clientes para luego utilizarlas en tu plataforma de email marketing preferida (Por ejemplo: Envialo simple, Doppler, Mailchimp, etc).',
            ]}
            button={
              <ButtonUI
                type="callf"
                label={tienda.t_email_marketing ? 'Desactivar' : 'Activar'}
                fullWidth={false}
                minHeight={true}
                onClickFunc={handleSubmit}
                isLoading={isEmailMarketingActivationLoading}
              />
            }
          />
        </Grid>
        <ToggleUI show={!!tienda.t_email_marketing}>
          <Grid item md={6} xs={12}>
            <Card sx={theme.card.card}>
              <CardHeader
                sx={{
                  paddingTop: '25px',
                  paddingBottom: '25px',
                  '.MuiCardHeader-action': theme.card.card_action_button,
                  '.MuiCardHeader-title': {
                    ...theme.card.card_header_title,
                    fontSize: '1.3rem',
                  },
                }}
                action={
                  <Chip
                    component="div"
                    color="primary"
                    label={
                      unexportedClientsQuantity === 1
                        ? unexportedClientsQuantity + ' Nuevo'
                        : unexportedClientsQuantity + ' Nuevos'
                    }
                  />
                }
                title="Nuevos suscriptores"
              />
              <CardContent>
                <Button
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  sx={{ width: '100%' }}
                  onClick={handleExportNewSubscribers}
                  disabled={unexportedClientsQuantity === 0}
                >
                  Exportar
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card sx={theme.card.card}>
              <CardHeader
                sx={{
                  paddingTop: '25px',
                  paddingBottom: '25px',
                  '.MuiCardHeader-action': theme.card.card_action_button,
                  '.MuiCardHeader-title': {
                    ...theme.card.card_header_title,
                    fontSize: '1.3rem',
                  },
                }}
                action={
                  <Chip
                    component="div"
                    color="primary"
                    label={clientsQuantity}
                  />
                }
                title="Todos los suscriptores"
              />
              <CardContent>
                <Button
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  sx={{ width: '100%' }}
                  onClick={handleExportAllSubscribers}
                  disabled={clientsQuantity === 0}
                >
                  Exportar
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </ToggleUI>
      </Grid>
    </WrapperLayout>
  );
};

export default EmailMarketingPage;
