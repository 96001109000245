import React from 'react';
import { InputUI, ToggleUI } from '../../components/common';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const InputText_ = ({
  classes,
  columns,
  idCountry,
  inputValue,
  handleChange,
  show = true,
  field_prefix,
}) => {
  return (
    <ToggleUI show={show}>
      <Grid
        item
        xs={12}
        md={columns ? columns : idCountry === 'COL' ? 6 : 12}
        className={classes.inputContainer}
      >
        <InputUI
          input={inputValue}
          field_prefix={field_prefix}
          handleChange={handleChange}
        />
      </Grid>
    </ToggleUI>
  );
};

const style = () => ({
  inputContainer: {
    marginBottom: '12px',
  },
});

export const InputText = withStyles(style)(InputText_);
