import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import PlanInfo from './subcomponents/PlanInfo';
import { AlertUI, ToggleUI } from '../common';
import BillList from './subcomponents/BillList';
import BillCard from './subcomponents/BillCard';
import { ModalCancelSuscription, ModalSetIdPayment } from './subcomponents';
import { Validate } from '../../other';
import {
  tiendaObtenerMiPlan,
  tiendaObtenerMiPlanWithCode,
} from '../../store/actions';
import { connect, useSelector } from 'react-redux';
import CONDICIONES_FISCALES from './condicionesFiscales.json';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../amplitude';
import { SuccessSuscriptionMessage } from './subcomponents/SuccessSuscriptionMessage';
import { withStyles } from '@mui/styles';
import { ModalCancelSuscriptionInCriticalPeriod } from './subcomponents/ModalCancelSuscriptionInCriticalPeriod';
import { useHistory } from 'react-router-dom';

const MiPlan_ = ({ classes, tiendaObtenerMiPlan }) => {
  const [showLastExpirationDate, setShowLastExpirationDate] = useState(false);
  const [openCancelSuscription, setOpenCancelSuscription] = useState(false);
  const [
    openCancelSuscriptionInCriticalPeriod,
    setOpenCancelSuscriptionInCriticalPeriod,
  ] = useState(false);
  const [openSetIdPayment, setOpenSetIdPayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [billData, setBillData] = useState();
  const [billId, setBillId] = useState(0);
  const [isSuscribed, setIsSuscribed] = useState(false);
  const [isAvailableCancelSuscription, setIsAvailableCancelSuscription] =
    useState(true);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  });
  const [isTrialUser, setIsTrialUser] = useState(false);
  const history = useHistory();

  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const handleErrorMessage = (errorMessage) => {
    if (
      errorMessage ===
        'No pudimos darle de alta a tu suscripción. Reintentá mas tarde.' &&
      idCountry === 'COL'
    ) {
      return 'No fue posible completar el registro de tu suscripción. Te pedimos que intentes nuevamente más adelante.';
    } else if (
      errorMessage ===
        'No pudimos validar la tarjeta ingresada. Por favor, intentá con otra.' &&
      idCountry === 'COL'
    ) {
      return 'No pudimos verificar la tarjeta ingresada. Te sugerimos intentar con otra.';
    } else {
      return errorMessage;
    }
  };

  useEffect(() => {
    tiendaObtenerMiPlan((err, resp) => {
      if (err) {
        setAlert({
          open: true,
          message: resp.message,
          type: 'error',
        });
      } else if (resp.data) {
        setBillData(resp.data);

        const isVisibleLastExpirationDate =
          resp.data.factura.dias_vencimiento <= 10 && !resp.data.factura.paga;

        setShowLastExpirationDate(isVisibleLastExpirationDate);
        setIsSuscribed(!!resp.data.tienda.t_debito_automatico);
        setIsAvailableCancelSuscription(!!resp.data.factura.baja_habilitada);
        setIsTrialUser(resp.data.tienda.t_prueba);

        if (resp.data.tienda.t_deuda) {
          createAmplitudeEventWithDevice(
            suscription_amplitude_events.inicio_con_deuda.event_name
          );
        }
      }

      setLoading(false);
      if (localStorage.getItem('plan-alert')) {
        const planAlert = JSON.parse(localStorage.getItem('plan-alert'));
        setAlert(planAlert);
        localStorage.removeItem('plan-alert');
      }
    });
  }, []);

  const handleSubmitInformarPago = ({ err, billIdForValidation, alert }) => {
    setAlert(alert);

    if (!err) {
      createAmplitudeEventWithDevice(
        suscription_amplitude_events.plan_selecciona_informar_pago.event_name
      );

      let newBills = billData.facturas.map((bill) => {
        let newBill = bill;

        if (bill.id === billIdForValidation) {
          newBill.paga = 1;
        }

        return newBill;
      });

      if (newBills.length === 0) {
        newBills = [
          {
            ...billData.factura,
            paga: 1,
            pdf: null,
            facturada: 1,
          },
        ];
      }

      setShowLastExpirationDate(false);

      setOpenSetIdPayment(false);
      setBillData({
        ...billData,
        factura: {
          ...billData.factura,
          paga: 1,
        },
        facturas: newBills,
      });
    }
  };

  const handleSubmitCancelSuscription = ({ err, alert }) => {
    setAlert(alert);
    if (!err) {
      // éxito
      setIsSuscribed(false);
    }
  };

  if (loading)
    return (
      <>
        <br />
        <LinearProgress />
      </>
    );

  const cancelSuscription = () => {
    createAmplitudeEventWithDevice(
      suscription_amplitude_events.mi_plan_selecciona_cancelar_suscripcion
        .event_name,
      { estado: isAvailableCancelSuscription ? 'habilitado' : 'cobro_en_curso' }
    );

    if (isAvailableCancelSuscription) {
      setOpenCancelSuscription(true);
    } else {
      setOpenCancelSuscriptionInCriticalPeriod(true);
    }
  };

  const paymentMethodsItems = [
    {
      boldText: isSuscribed ? 'Débito automático' : 'Tipo de suscripción',
      text: isSuscribed
        ? 'Suscripto al pago mensual'
        : 'No tenés suscripción activa',
    },
    {
      boldText: 'Próximo vencimiento',
      text: billData?.factura.fecha_vencimiento,
    },
    {
      type: 'callf',
      isSuscribed: isSuscribed,
      label: 'Cancelar suscripción',
      onClick: cancelSuscription,
    },
  ];

  const redirectToSuscription = () => {
    createAmplitudeEventWithDevice(
      suscription_amplitude_events.mi_plan_selecciona_actualizar_tarjeta
        .event_name
    );

    history.push(`/plan/metodo-pago/suscripcion`);
  };

  const paymentMethodsButton = {
    type: 'callf',
    label: isSuscribed ? 'Actualizar tarjeta' : 'Suscribirme',
    onClick: isSuscribed
      ? redirectToSuscription
      : () => history.push(`/plan/metodo-pago`),
  };

  const billDataItems = {
    ARG: [
      {
        boldText: 'Razón social',
        text: billData?.datosfacturacion.df_nombre || '-',
      },
      {
        boldText: billData?.datosfacturacion.df_tipo_documento || 'CUIL',
        text: billData?.datosfacturacion.df_documento || '-',
      },
      {
        boldText: 'Condición',
        text: CONDICIONES_FISCALES[
          billData?.datosfacturacion.df_condicion || 'default'
        ],
      },
    ],
    COL: [
      {
        boldText: 'Nombre completo',
        text:
          String(billData?.datosfacturacion.df_nombre).replace(',', '') || '-',
      },
      {
        boldText: 'Número de identificación',
        text: billData?.datosfacturacion.df_documento || '-',
      },
    ],
  };

  const showTopMessage =
    (isSuscribed && !isAvailableCancelSuscription) || billData?.tienda.t_deuda;

  return (
    <>
      <AlertUI
        open={alert.open}
        type={alert.type}
        hideSeconds={5000}
        message={handleErrorMessage(alert.message)}
        handleCloseAlert={() => setAlert({ ...alert, open: false })}
      />
      <ModalSetIdPayment
        billId={billId}
        open={openSetIdPayment}
        modalHandler={setOpenSetIdPayment}
        handleSubmit={handleSubmitInformarPago}
      />
      <ModalCancelSuscription
        idCountry={idCountry}
        open={openCancelSuscription}
        modalHandler={setOpenCancelSuscription}
        handleSubmit={handleSubmitCancelSuscription}
      />
      <ModalCancelSuscriptionInCriticalPeriod
        open={openCancelSuscriptionInCriticalPeriod}
        modalHandler={setOpenCancelSuscriptionInCriticalPeriod}
      />

      <Grid container>
        <SuccessSuscriptionMessage
          show={showTopMessage}
          isTrialUser={isTrialUser}
          isSuscribedAndNotAvailableCancel={
            isSuscribed && !isAvailableCancelSuscription
          }
          isDebtor={billData?.tienda.t_deuda}
        />
      </Grid>
      <ToggleUI show={showLastExpirationDate}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <BillCard
              headerText="Próximo vencimiento"
              bill={{
                ...billData?.factura,
                monto: Validate.number_format(
                  billData?.factura?.monto,
                  idCountry
                ),
                setBillId: (id) => setBillId(id),
              }}
              show={{
                monto: true,
                infoPago: isSuscribed ? false : true,
              }}
              setOpenSetIdPayment={setOpenSetIdPayment}
              isSuscribed={isSuscribed}
            />
          </Grid>
          <Grid
            className={classes.infoContainer}
            item
            md={6}
            xs={12}
            pl={{ md: 1.5 }}
            pb={{ xs: 3, md: 0 }}
          >
            <PlanInfo
              title="Información de tu método de pago"
              button={paymentMethodsButton}
              items={paymentMethodsItems}
            />
          </Grid>
        </Grid>
      </ToggleUI>

      <Grid className={classes.wrapper} container spacing={3}>
        <Grid item md={6} xs={12}>
          <BillList bills={billData?.facturas} isSuscribed={isSuscribed} />
        </Grid>

        <Grid item md={6} xs={12} pl={{ md: 1.5 }} pb={{ xs: 3, md: 0 }}>
          <ToggleUI show={!showLastExpirationDate}>
            <Grid className={classes.infoContainer} item xs={12}>
              <PlanInfo
                title="Información de tu método de pago"
                button={paymentMethodsButton}
                items={paymentMethodsItems}
              />
            </Grid>
          </ToggleUI>
          <PlanInfo
            title="Información de facturación"
            button={{
              label: 'Editar',
              link: '/plan/datos-facturacion',
            }}
            items={billDataItems[idCountry]}
          />
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  wrapper: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  infoContainer: {
    [theme.breakpoints.up('md')]: {
      marginBottom: '68px',
    },
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtenerMiPlan: (callback) => dispatch(tiendaObtenerMiPlan(callback)),
    tiendaObtenerMiPlanWithCode: (data, callback) =>
      dispatch(tiendaObtenerMiPlanWithCode(data, callback)),
  };
};

export const MiPlan = withStyles(styles)(
  connect(null, mapDispatchToProps)(MiPlan_)
);
