import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const ModuleButtonBoxUI_ = (props) => {
  const {
    classes,
    children,
    xlSize = 2,
    lgSize = 2,
    mdSize = 4,
    smSize = 6,
    xsSize = 12,
  } = props;
  return (
    <div className={classes.module_button_box}>
      <Grid container spacing={1.5}>
        <Grid item xl={xlSize} lg={lgSize} md={mdSize} sm={smSize} xs={xsSize}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme) => ({
  module_button_box: {
    marginTop: '20px',
  },
});

export const ModuleButtonBoxUI = withStyles(styles)(ModuleButtonBoxUI_);
