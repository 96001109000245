import { Config } from '../other';

export const getAppsActives = () => {
  return {
    status: 200,
    message: 'Ok',
    data: {
      applications: [Config.ECOMMAPP_APP_ID],
    },
  };
};
