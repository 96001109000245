/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const betaReducer = (state = initState, action) => {
  switch (action.type) {
    case Reducers.BETA_GUARDAR:
      localStorage.setItem('bdata', JSON.stringify(action.data));
      return action.data;
    case Reducers.AUTH_UNSET:
      if (localStorage.getItem('bdata')) {
        localStorage.removeItem('bdata');
      }
      return initState;
    default:
      return state;
  }
};

export { betaReducer };
