import React, { Fragment } from 'react';
import { ToggleUI } from './';
import { Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ModuleCardTitleUI = ({
  title = '',
  subtitles = [],
  with_grid = true,
  subtitleFontSize = '0.8rem',
}) => {
  const theme = useTheme();
  const subtitles_html = subtitles.map((subtitle, index) => {
    return (
      <Typography
        p={0}
        m={0}
        fontSize={subtitleFontSize}
        color={theme.texts.card_subtitle}
        key={index}
      >
        {subtitle}
      </Typography>
    );
  });

  if (with_grid) {
    return (
      <Grid item xs={12}>
        <ToggleUI show={title !== ''}>
          <Typography
            color={theme.texts.card_title}
            fontWeight="400"
            fontSize="1.1rem"
          >
            {title}
          </Typography>
        </ToggleUI>
        {subtitles_html}
      </Grid>
    );
  } else {
    return (
      <Fragment>
        <ToggleUI show={title !== ''}>
          <Typography
            color={theme.texts.card_title}
            fontWeight="400"
            fontSize="1.1rem"
          >
            {title}
          </Typography>
        </ToggleUI>
        {subtitles_html}
      </Fragment>
    );
  }
};
