import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { ModuleTitleUI, CardModuleUI } from '../../common';
import { CardTitle, CardText, Page } from '../subcomponents';

const ProgressCard_ = (props) => {
  const {
    classes,
    title = '',
    subtitle = '',
    text = '',
    completion_percentage = 0,
  } = props;

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <ModuleTitleUI title={title} />
      </Grid>
      <Grid item xs={12}>
        <CardModuleUI>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <CardTitle title={subtitle} />
              <CardText text={text} />
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                variant="determinate"
                value={Number(completion_percentage)}
              />
            </Grid>
            <Grid item>
              <p className={classes.progress_percentage}>
                {Number(parseInt(completion_percentage))}%
              </p>
            </Grid>
          </Grid>
        </CardModuleUI>
      </Grid>
      <Grid item>
        <Page firstvalue="2" lastvalue="de 4" />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  card_text: {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '19px',
    color: '#000000',
  },
  progress_percentage: {
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '19px',
  },
});

export const ProgressCard = withStyles(styles)(ProgressCard_);
