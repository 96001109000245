import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const trackObtenerTracks = (data, callback) => {
  let page = data.page;
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/track', {
        params: data,
      })
      .then((resp) => {
        //guardo en redux
        const track = resp.data.data;
        if (page === 0) {
          dispatch({ type: Reducers.TRACK_GUARDAR, data: track });
        } else {
          dispatch({
            type: Reducers.TRACK_GUARDAR_NO_CLEAN,
            data: track,
          });
        }

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const trackObtenerTrack = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(`${Config.BACKEND_ENDPOINT}/track/${data.idTrack}`)
      .then((resp) => {
        //guardo en redux
        const track = resp.data.data;
        dispatch({ type: Reducers.TRACK_GUARDAR, data: track });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export { trackObtenerTrack, trackObtenerTracks };
