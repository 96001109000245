/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  metodosenvioObtener,
  metodospagoObtener,
  metodoenvioConfigurarMercadoEnvios,
} from '../../../store/actions';
import { Config, Validate, Regex, RegexExtra } from '../../../other';
import { Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  CheckboxUI,
  SelectUI,
  InputUI,
  ToggleUI,
} from '../../../components/common';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

class MercadoEnvios_ extends Component {
  state = {
    mercadoenvios: {
      me_mercadoenvios: {
        validate: false,
        name: 'me_mercadoenvios',
        label: 'Activar Mercado Envios',
        value: 1,
        change_param: 'mercadoenvios',
      },
      me_mercadoenvios_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Ofrecer envío gratis',
        id: 'me_mercadoenvios_tipo',
        name: 'me_mercadoenvios_tipo',
        change_param: 'mercadoenvios',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_mercadoenvios_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'me_mercadoenvios_minimo',
        name: 'me_mercadoenvios_minimo',
        change_param: 'mercadoenvios',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '3000',
        messages: {
          help: 'El monto mínimo requerido de la compra para poder acceder al envío gratis',
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
    },
    loading: {
      mercadoenvios: true,
      metodos_pago: true,
      error: false,
    },
    forms: {
      mercadoenvios: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      me_mercadoenvios_tipo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si (Para todos los productos)',
        },
        {
          value: 2,
          label: 'Si (Ventas mayores a cierto monto)',
        },
      ],
    },
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            mercadoenvios: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          this.props.metodos_envio,
          this.state.mercadoenvios,
          (mercadoenvios_obj) => {
            this.setState((prevState) => ({
              mercadoenvios: mercadoenvios_obj,
              loading: {
                ...prevState.loading,
                mercadoenvios: false,
                error: false,
              },
            }));
          }
        );
      }
    });

    this.props.metodospagoObtener((err, resp) => {
      this.setState((prevState) => ({
        loading: {
          ...prevState.loading,
          metodos_pago: false,
          error: err ? true : false,
        },
      }));
    });
  }

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: prevState[aditional][name]['value'] ? 0 : 1,
        },
      },
    }));
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value = Validate.trim_int(e.target.value);

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        mercadoenvios: true,
      },
    }));

    let form = this.state.mercadoenvios;
    if (Validate.validar_formulario(form)) {
      this.props.metodoenvioConfigurarMercadoEnvios(
        {
          me_mercadoenvios: form.me_mercadoenvios.value,
          me_mercadoenvios_tipo: form.me_mercadoenvios_tipo.value,
          me_mercadoenvios_minimo: form.me_mercadoenvios_minimo.value,
        },
        (err, resp) => {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              mercadoenvios: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            mercadoenvios: false,
          },
          mercadoenvios: new_form,
          alert: {
            ...prevState.alert,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          },
        }));
      });
    }
  };

  render() {
    let classes = this.props.classes;
    const imageME = `${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/mercadoenvios-logo.png`;

    const html = this.props.metodos_pago.mp_mercadopago ? (
      <form onSubmit={this.handleSubmit}>
        <ModuleTitleUI
          title="Mercado Envios"
          image={imageME}
          subtitles={[
            'Para poder activar Mercado Envíos en tu tienda primero debes activar la función desde tu cuenta de Mercado Pago.',
            'En el tutorial de Mercado Envíos te indicamos como hacerlo y respondemos todas las preguntas frecuentes de la integración.',
            'Esta opción de envíos sólo está disponible para 1.000 tiendas que fueron seleccionadas por Mercado Pago para utilizar la integración, en los próximos meses se irá abriendo a toda la base.',
          ]}
          button={
            <ButtonUI
              type="link"
              label="Tutorial"
              fullWidth={false}
              link="https://recursos.empretienda.com/mercado-envios.pdf"
              external_link
              open_new_tab
            />
          }
        />
        <Card className={classes.card}>
          <CardContent className={classes.card_content}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <SelectUI
                  data={this.state.mercadoenvios.me_mercadoenvios_tipo}
                  options={this.state.selects.me_mercadoenvios_tipo}
                  handleChangeSelect={this.handleChangeSelect}
                />
              </Grid>
              <ToggleUI
                show={
                  this.state.mercadoenvios.me_mercadoenvios_tipo.value === 2
                }
              >
                <Grid item xs={12}>
                  <InputUI
                    handleChange={this.handleChange}
                    input={this.state.mercadoenvios.me_mercadoenvios_minimo}
                    adornment="$"
                  />
                </Grid>
              </ToggleUI>
              <Grid item xs={12}>
                <CheckboxUI
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  checkbox={this.state.mercadoenvios.me_mercadoenvios}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ModuleButtonBoxUI>
          <ButtonUI
            type="submit"
            label="Guardar"
            minHeight={true}
            isLoading={this.state.forms.mercadoenvios}
          />
        </ModuleButtonBoxUI>
      </form>
    ) : (
      <div>
        <ModuleTitleUI
          title="Mercado Envios"
          image={imageME}
          subtitles={[
            'Para utilizar Mercado Envíos, primero debes activarlo en tu cuenta de Mercado Pago.',
            'Una vez realizado el paso anterior, puedes regresar y activar Mercado Envíos.',
          ]}
        />
        <ButtonUI
          type="link"
          link="/metodos-pago"
          label="Activar Mercado Pago"
          fullWidth={false}
        />
      </div>
    );

    return (
      <WrapperLayout
        loading={
          this.state.loading.mercadoenvios || this.state.loading.metodos_pago
        }
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            {html}
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  text_link: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const mapStateToProps = (state) => {
  return {
    metodos_envio: state.metodos_envio,
    metodos_pago: state.metodos_pago,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodospagoObtener: (callback) => dispatch(metodospagoObtener(callback)),
    metodoenvioConfigurarMercadoEnvios: (data, callback) =>
      dispatch(metodoenvioConfigurarMercadoEnvios(data, callback)),
  };
};

const MercadoEnviosPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MercadoEnvios_)
);

export default MercadoEnviosPage;
