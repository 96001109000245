/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const trackReducer = (state = initState, action) => {
  let tracks;
  switch (action.type) {
    case Reducers.TRACK_GUARDAR:
      tracks = action.data;
      return tracks;

    case Reducers.TRACK_GUARDAR_NO_CLEAN:
      tracks = action.data;
      return [...state, ...tracks];

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { trackReducer };
