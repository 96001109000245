import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Tooltip,
  Chip,
  Typography,
} from '@mui/material';

import { Config, Validate } from '../../other';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import {
  AlertUI,
  ButtonUI,
  ModuleCardTitleUI,
  ModuleTitleUI,
} from '../../components/common';
import {
  categoriasObtenerFlattenJerarquico,
  dominioObtenerDominioPreferencial,
} from '../../store/actions';

const GoogleShoppingPage = ({
  classes,
  getPreferentialDomain,
  getCategories,
  categorias,
  dominio,
}) => {
  const [loading, setLoading] = useState({
    dominio: true,
    categorias: true,
  });
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: 'success',
    message: 'El link de tu feed ha sido copiado con éxito',
  });

  useEffect(() => {
    getPreferentialDomain((err, _resp) => {
      if (err) {
        setError(true);
      } else {
        setLoading((prevState) => ({
          ...prevState,
          dominio: false,
        }));
      }
    });
  }, []);

  useEffect(() => {
    getCategories((err, _resp) => {
      if (err) {
        setError(true);
      } else {
        setLoading((prevState) => ({
          ...prevState,
          categorias: false,
        }));
      }
    });
  }, []);

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      document.querySelector('#link_catalogo').value
    );
    if (!Validate.is_mobile()) {
      setAlert((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  };

  const showCategoriesWithoutGoogleCategory = () => {
    const categories = categorias.filter(
      (categoria) => categoria.c_google_category_id === 0
    );

    if (categories.length > 0) {
      const categorias_chips = categories.map((category) => {
        return (
          <Chip
            className={classes.chip}
            component="span"
            color="primary"
            label={category.c_nombre}
            key={category.idCategorias}
          />
        );
      });

      return (
        <Grid item xs={12} pb={3}>
          <div className={classes.box}>
            <Typography>
              Las siguientes categorías no tienen configurada la categoría de
              Google. Los productos que estén dentro de estas categorías
              primarias no se mostrarán en tu feed de Google Shopping.
            </Typography>
            {categorias_chips}
          </div>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const link_catalogo =
    'https://' + dominio.d_nombre + '/google/google-shopping.xml';

  return (
    <WrapperLayout
      loading={loading.dominio || loading.categorias}
      error={error}
    >
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/google-shopping-logo.png`}
            beta
            image_size={Validate.is_mobile() ? '50px' : '75px'}
            subtitles={[
              'Es una sección de Google que agrupa los productos de las marcas que utilizan esta función.',
              'Al buscar un producto en Google aparecen los resultados de Google Shopping permitiendo que los usuarios encuentren tu producto e ingresen a tu web para comprarlo.',
              'Para activar Google shopping te recomendamos ver nuestro tutorial haciendo click en el botón superior derecho.',
              'Recomendamos que configures el Category ID de Google en todas tus categorías y cargues las dimensiones de todos tus productos.',
              'Esta función se encuentra en estado "beta", es decir que está en fase de pruebas antes de su lanzamiento oficial. Se pueden presentar problemas y/o errores que necesitamos que nos informes para poder corregir.',
            ]}
            button={
              <ButtonUI
                type="link"
                label="Tutorial"
                fullWidth={false}
                link="https://recursos.empretienda.com/google-shopping.pdf"
                external_link
                open_new_tab
              />
            }
          />
          <Card className={classes.card}>
            <CardContent className={classes.card_content}>
              <Grid container>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Feed de productos"
                    with_grid={false}
                  />
                </Grid>
                {showCategoriesWithoutGoogleCategory()}
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    label="Link para tu catálogo de productos"
                    value={link_catalogo}
                    id="link_catalogo"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Copiar link" placement="left">
                          <InputAdornment position="end">
                            <IconButton onClick={handleCopyLink} size="large">
                              <Icon>filter_none</Icon>
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.card_below}>
            <CardContent className={classes.card_content}>
              <Grid container spacing={1.5}>
                <ModuleCardTitleUI title="Ejemplo de cómo funciona:" />
                <Grid item md={4} xs={12}>
                  <img
                    src={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/google-shopping/google-shopping-1.png`}
                    className={classes.step_image}
                    alt="google shopping step 1"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <img
                    src={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/google-shopping/google-shopping-2.png`}
                    className={classes.step_image}
                    alt="google shopping step 2"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <img
                    src={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/google-shopping/google-shopping-3.png`}
                    className={classes.step_image}
                    alt="google shopping step 3"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
  step_image: {
    width: '80%',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  box: {
    padding: '10px',
    backgroundColor: '#f3f3f3',
    borderRadius: '4px',
  },
  chip: theme.chip,
});

const mapStateToProps = (state) => {
  return {
    dominio: state.dominio,
    categorias: state.categorias,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (callback) =>
      dispatch(categoriasObtenerFlattenJerarquico(callback)),
    getPreferentialDomain: (callback) =>
      dispatch(dominioObtenerDominioPreferencial(callback)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GoogleShoppingPage)
);
