/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = {
  open: false,
  message: '',
  type: 'error',
};

const alertReducer = (state = initState, action) => {
  switch (action.type) {
    case Reducers.SHOW_ALERT:
      return action.data;
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { alertReducer };
