import React from 'react';
import { ButtonUI } from '../../common';
import { Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const ApplicationInstalledItem_ = ({ app, classes, handleOpenModal }) => {
  const { name, logo, description } = app;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <Grid item xs={12} className={classes.container} component="li">
          <Grid item className={classes.containerImage}>
            <img src={logo} width="69" height="69" />
            <div className={classes.containerText}>
              <span className={classes.appTitle}>{name}</span>
              <div className={classes.containerDescription}>
                <p className={classes.text} style={{ WebkitLineClamp: 3 }}>
                  {description}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item className={classes.containerButton}>
            <ButtonUI
              classes={{ root: classes.button }}
              label="Desinstalar"
              type="callf"
              variant="outlined"
              fullWidth={false}
              onClickFunc={handleOpenModal}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  card: {
    ...theme.card.card,
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: { height: '119px' },
  },
  card_content: {
    ...theme.card.card_content,
    padding: '16px 18px',
    '&:last-child': {
      padding: '16px 18px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      padding: '0px',
      '&:last-child': {
        padding: '0px',
      },
    },
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      padding: '16px 18px',
    },
  },
  containerImage: {
    display: 'flex',
    alignItems: 'center',
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '24px 0px 0px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px',
    },
  },
  containerText: {
    marginLeft: '11px',
    maxWidth: '207px',
  },
  appTitle: {
    fontSize: '1.12rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    fontWeight: '600',
    color: '#3a3a3a',
  },
  text: {
    color: '#3a3a3a',
    fontSize: '0.87rem',
    fontWeight: 400,
    margin: '3px 0px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  button: {
    textTransform: 'capitalize',
    fontSize: '0.87rem',
    fontWeight: 600,
    border: '2px solid #53B755',
    minWidth: '142px',
  },
  iconTitle: {
    color: '#1010EF',
    marginLeft: '6px',
  },
  containerDescription: {
    maxWidth: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '308px',
    },
  },
});

export const ApplicationInstalledItem = withStyles(styles)(
  ApplicationInstalledItem_
);
