/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI, InputUI } from '../../../components/common';

const ModalGoogleID = (props) => {
  let {
    googleid,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="googleid"
      title="Agregar categoría de Google"
      subtitle={
        <span>
          Sirve para integrar tu tienda con Google Shopping y Empreshops. Mira
          el tutorial{' '}
          <a
            href="https://blog.uala.com.ar/empretienda/empresale"
            style={{
              textDecoration: 'underline',
              color: 'inherit',
            }}
            target="_blank"
          >
            aquí
          </a>
          .
        </span>
      }
      modalHandler={modalHandler}
      aditional_param="googleid"
      handleSubmit={handleSubmit}
      button_label="Guardar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            Busca el ID de la categoría de Google haciendo click{' '}
            <a
              href="https://blog.uala.com.ar/empretienda/empresale"
              target="_blank"
              style={{
                textDecoration: 'underline',
                color: 'inherit',
              }}
            >
              aquí
            </a>
            .
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            step="1"
            input={googleid.c_google_category_id}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalGoogleID };
