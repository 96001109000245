/**
 * @summary KeyValue pair
 * @typedef {Object} KeyValue
 * @property {string} key
 * @property {string} value

/**
 * @summary Stock that is part of a product sent in a chunk
 * @typedef {Object} ChunkStock
 * @property {number} idStock
 * @property {number} s_cantidad
 * @property {number} s_ilimitado
 * @property {string } s_sku
 * @property {number} s_precio
 * @property {number} s_precio_oferta
 * @property {number} s_precio_mayorista
 * @property {number} s_peso
 * @property {number} s_alto
 * @property {number} s_ancho
 * @property {number} s_profundidad
 * @property { KeyValue[]} valoratributo
 */

/**
 * @summary Product sent in a chunk
 * @typedef {Object} ChunkProduct
 * @property {number} idProductos
 * @property {number[]} row
 * @property {string} p_nombre
 * @property {string} p_descripcion
 * @property {number} p_desactivado
 * @property {number} p_tipo_venta
 * @property {string} categorias
 * @property {string[]} atributos
 * @property {ChunkStock[] } stock
 */

/**
 * @summary A Chunk is a collection of products informed to the backend
 * @typedef {Object} Chunk
 * @property { ChunkProduct[] } data
 */

/**
 * @summary Separate ítems
 * @description
 * IDProduct=0 and IDStock=0. New
 * IDProduct>0 and IDStock=0. New
 * IDProduct>0 and IDStock>0. Edited
 * IDProduct=0 and IDStock>0. Never
 * @param { Chunk } chunk
 * @return { {new_products:ChunkProduct[], edited_products:ChunkProduct[]} } New,Edited
 */
export const ChunkSeparateChangedFromNewProducts = (chunk) => {
  let new_products = [];
  let edited_products = [];
  for (const chunk_product of chunk.data) {
    let new_product = null;
    let edited_product = null;
    for (const chunk_stock of chunk_product.stock) {
      const is_new_product =
        (chunk_product.idProductos === 0 && chunk_stock.idStock === 0) ||
        (chunk_product.idProductos > 0 && chunk_stock.idStock === 0);

      if (is_new_product) {
        if (!new_product) {
          new_product = {
            ...chunk_product,
            stock: [],
          };
        }
        new_product.stock.push(chunk_stock);
      } else {
        //edited product
        if (!edited_product) {
          edited_product = {
            ...chunk_product,
            stock: [],
          };
        }
        edited_product.stock.push(chunk_stock);
      }
    }

    if (new_product) new_products.push(new_product);
    if (edited_product) edited_products.push(edited_product);
  }

  return {
    new_products,
    edited_products,
  };
};
