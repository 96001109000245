import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const cuponObtenerCupones = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/cupon-descuento')
      .then((resp) => {
        //guardo en redux
        const cupones = resp.data.data;
        dispatch({ type: Reducers.CUPON_GUARDAR, data: cupones });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const cuponObtenerCupon = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(
        `${Config.BACKEND_ENDPOINT}/cupon-descuento/${data.idCuponesDescuento}`
      )
      .then((resp) => {
        //guardo en redux
        const cupon = resp.data.data;
        dispatch({ type: Reducers.CUPON_GUARDAR, data: cupon });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const cuponAgregar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/cupon-descuento', data)
      .then((resp) => {
        //guardo en redux
        const nuevo_cupon = resp.data.data;
        dispatch({ type: Reducers.CUPON_GUARDAR, data: nuevo_cupon });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const cuponEditar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/cupon-descuento/${data.idCuponesDescuento}`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const cupon_editado = resp.data.data;
        dispatch({ type: Reducers.CUPON_GUARDAR, data: cupon_editado });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const cuponDesactivar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/cupon-descuento/${data.idCuponesDescuento}/activar-desactivar`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const cupon_desactivado = resp.data.data;
        dispatch({
          type: Reducers.CUPON_DESACTIVAR,
          data: cupon_desactivado,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const cuponBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(
        `${Config.BACKEND_ENDPOINT}/cupon-descuento/${data.idCuponesDescuento}`
      )
      .then((resp) => {
        //guardo en redux
        const cupon_eliminado = resp.data.data;
        dispatch({
          type: Reducers.CUPON_BORRAR,
          data: cupon_eliminado,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  cuponObtenerCupones,
  cuponObtenerCupon,
  cuponAgregar,
  cuponEditar,
  cuponDesactivar,
  cuponBorrar,
};
