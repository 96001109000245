export const getChipWithStateOfPayment = (message) => {
  const chipStyles = {
    //Green Chips
    Finalizado: {
      backgroundColor: '#BDE3BD',
      color: '#1B5F1C',
      border: '1px solid #1B5F1C',
      fontSize: '0.875rem',
    },
    'Pago devuelto': {
      backgroundColor: '#BDE3BD',
      color: '#1B5F1C',
      border: '1px solid #1B5F1C',
      fontSize: '0.875rem',
    },
    Enviado: {
      backgroundColor: '#BDE3BD',
      color: '#1B5F1C',
      border: '1px solid #1B5F1C',
      fontSize: '0.875rem',
    },
    //Grey Chips
    'Esperando pago': {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    Pendiente: {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    'En proceso': {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    'En mediación': {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    //Red Chips
    Cancelado: {
      backgroundColor: '#FFC9C9',
      color: '#B50707',
      border: '1px solid #B50707',
      fontSize: '0.875rem',
    },
    Rechazado: {
      backgroundColor: '#FFC9C9',
      color: '#B50707',
      border: '1px solid #B50707',
      fontSize: '0.875rem',
    },
    'En contracargo': {
      backgroundColor: '#FFC9C9',
      color: '#B50707',
      border: '1px solid #B50707',
      fontSize: '0.875rem',
    },
  };

  return {
    stylesStatePayment: chipStyles[message],
    messageStatePayment: message,
  };
};

export const getChipWithStateOfShipment = (message) => {
  const chipStyles = {
    //Green Chips
    Retirado: {
      backgroundColor: '#BDE3BD',
      color: '#1B5F1C',
      border: '1px solid #1B5F1C',
      fontSize: '0.875rem',
    },
    Enviado: {
      backgroundColor: '#BDE3BD',
      color: '#1B5F1C',
      border: '1px solid #1B5F1C',
      fontSize: '0.875rem',
    },
    //Grey Chips
    'Pendiente de retiro': {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    Pendiente: {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    'Esperando retiro': {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
    'En preparación': {
      backgroundColor: '#F2F2F2',
      color: '#565656',
      border: '1px solid #565656',
      fontSize: '0.875rem',
    },
  };
  return {
    stylesStateShipment: chipStyles[message],
    messageStateShipment: message,
  };
};
