import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ItemNumber = styled('h2')(({ theme }) => ({
  fontWeight: '700',
  fontSize: '3rem',
  lineHeight: '56px',
  margin: '0px 0px 10px 0px',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
    lineHeight: '38px',
  },
}));

export const ItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '0.87rem',
  lineHeight: '16px',
  color: '#6C6C6C',
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    margin: '0px 0px 6px 0px',
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '19px',
    textTransform: 'none',
  },
}));

export const ProductCardItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    backgroundColor: '#f4f4f4',
    marginTop: '12px',
    padding: '12px',
  },
}));
