import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToggleUI } from '../../../../../components/common';
import { BillingDirection } from './BillingDirection';

const OrdenInfoFacturacion_ = (props) => {
  let { venta, cliente, classes, idCountry } = props;
  return (
    <Card className={classes.card}>
      <CardHeader
        title="Información de facturación"
        className={classes.card_header}
        classes={{ title: classes.card_title }}
      />
      <Divider />
      <CardContent className={classes.card_content}>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Facturada:</span>{' '}
          {venta.v_factura === 1 ? 'Sí' : 'No'}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Link de factura: </span>
          {venta.v_factura === 1 && venta.v_factura_link !== null ? (
            <u>
              <a
                href={venta.v_factura_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit' }}
              >
                Descargar
              </a>
            </u>
          ) : (
            '-'
          )}
        </Typography>
      </CardContent>
      <Divider />
      <CardContent className={classes.card_content}>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Nombre completo:</span>{' '}
          {venta.v_facturacion_nombre} {venta.v_facturacion_apellido}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>
            {venta.v_facturacion_tipo_identificacion}:
          </span>{' '}
          {venta.v_facturacion_numero_identificacion}
        </Typography>
        <Typography sx={{ whiteSpace: 'nowrap' }} className={classes.info}>
          <span className={classes.info_title}>Email:</span> {cliente.cl_email}
        </Typography>
        <ToggleUI show={venta.v_facturacion_calle ? true : false}>
          <BillingDirection idCountry={idCountry} venta={venta} />
        </ToggleUI>
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  card_content: {
    padding: '16px',
    '&:last-child': {
      padding: '16px',
    },
  },
  card_title: {
    fontWeight: '500',
    fontSize: '1.1rem',
  },
  info: {
    fontWeight: '300',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  info_title: {
    fontWeight: '500',
  },
});

export const OrdenInfoFacturacion = withStyles(styles, { withTheme: true })(
  OrdenInfoFacturacion_
);
