import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, CircularProgress } from '@mui/material';

const size_circular_loading = (sizeBtn) => {
  switch (sizeBtn) {
    case 'small':
      return 22;
    case 'medium':
      return 24;
    case 'large':
      return 25;
    default:
      return 0;
  }
};

const ButtonUI_ = ({
  type,
  onClickFunc = null,
  aditionalParamFunc = null,
  link = null,
  external_link = false,
  extraPadding = false,
  open_new_tab = false,
  size = 'medium',
  variant = 'contained',
  border_radius = false,
  label,
  color = 'primary',
  fullWidth = true,
  disabled = false,
  isLoading = false,
  fixedMinWidth = false,
  minHeight = false,
  classes,
  ...rest
}) => {
  const combinedClasses = `${extraPadding ? classes.extraPadding : ''} ${
    fixedMinWidth ? classes.fixed_min_width : ''
  } ${minHeight ? classes.fixed_min_height : ''}`;

  //si esta cargando, deshabilito el boton
  disabled = isLoading ? true : disabled;

  //calculo el size del circulo cuando carga
  let circular_size = size_circular_loading(size);

  //si esta cargando, muestro el loading
  let label_btn = isLoading ? (
    <CircularProgress
      size={circular_size}
      thickness={4.5}
      classes={{ root: classes.progress }}
    />
  ) : (
    label
  );

  switch (type) {
    //submit form
    case 'submit':
      return (
        <Button
          type="submit"
          variant={variant}
          size={size}
          color={color}
          disabled={disabled}
          fullWidth={fullWidth}
          className={combinedClasses}
          classes={{
            root: border_radius ? classes.root_radius : classes.root,
            label: border_radius ? classes.label_radius : classes.label,
          }}
          {...rest}
        >
          {label_btn}
        </Button>
      );

    //call some function
    case 'callf':
      return (
        <Button
          variant={variant}
          size={size}
          color={color}
          disabled={disabled}
          onClick={(e) => onClickFunc(e, aditionalParamFunc)}
          fullWidth={fullWidth}
          className={combinedClasses}
          classes={{
            root: border_radius ? classes.root_radius : classes.root,
            label: border_radius ? classes.label_radius : classes.label,
          }}
          {...rest}
        >
          {label_btn}
        </Button>
      );

    //go to specific link
    case 'link':
      if (external_link) {
        let target = open_new_tab ? '_blank' : '_self';
        return (
          <Button
            variant={variant}
            size={size}
            color={color}
            disabled={disabled}
            href={link}
            target={target}
            fullWidth={fullWidth}
            className={combinedClasses}
            classes={{
              root: border_radius ? classes.root_radius : classes.root,
              label: border_radius ? classes.label_radius : classes.label,
            }}
            {...rest}
          >
            {label_btn}
          </Button>
        );
      } else {
        return (
          <Button
            variant={variant}
            size={size}
            color={color}
            disabled={disabled}
            component={Link}
            to={link}
            fullWidth={fullWidth}
            className={combinedClasses}
            classes={{
              root: border_radius ? classes.root_radius : classes.root,
              label: border_radius ? classes.label_radius : classes.label,
            }}
            {...rest}
          >
            {label_btn}
          </Button>
        );
      }

    //it doesn't do any action
    default:
      return (
        <Button
          variant={variant}
          size={size}
          color={color}
          disabled={disabled}
          fullWidth={fullWidth}
          className={combinedClasses}
          classes={{
            root: border_radius ? classes.root_radius : classes.root,
            label: border_radius ? classes.label_radius : classes.label,
          }}
          {...rest}
        >
          {label_btn}
        </Button>
      );
  }
};

const styles = (theme) => ({
  extraPadding: {
    padding: '13px 44px',
  },
  progress: {
    color: '#9f9f9f !important',
  },
  root: {
    boxShadow: 'none',
    borderRadius: '2px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    fontSize: '16px',
  },
  root_radius: {
    boxShadow: 'none',
    borderRadius: '40px',
    fontSize: '16px',
  },
  label_radius: {
    fontWeight: 500,
    letterSpacing: '0.5px',
    fontSize: '1rem',
    padding: '5px 20px',
  },
  fixed_min_width: {
    minWidth: '9.875rem',
  },
  fixed_min_height: {
    minHeight: '2.5rem',
  },
});

ButtonUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //Type of the button and defines its behaviour
  //submit: to use it on a form
  //callf: to use it to call a function like onClickFunc(e,aditionalParamFunc)
  //link: to use it to go to specific link
  //static: it doesn't do any action
  type: PropTypes.oneOf(['submit', 'callf', 'link', 'static']).isRequired,

  //Function used when type is callf
  //Default: null
  onClickFunc: PropTypes.func,

  //Aditional param to be passed on onClickFunc
  //Default: null
  aditionalParamFunc: PropTypes.any,

  //Link to go to when type is link
  //Default: null
  link: PropTypes.string,

  //If true, the link is an external website
  //It works if type=link
  //Default: false
  external_link: PropTypes.bool,

  //The button opens a new tab with the link
  //It works if type=link and external_link=true
  //Default: false
  open_new_tab: PropTypes.bool,

  //Defines if the button has border or not
  //Default: false
  border_radius: PropTypes.bool,

  //Size of the button
  //Default: medium
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  //Format of the button
  //contained: it has background
  //outlined: it has no background, only border and label
  //Default: contained
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),

  //The label of the button
  label: PropTypes.any.isRequired,

  //The color of the button
  //Default: primary
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'error']),

  //The button cover all available space of its father
  //Default: true
  fullWidth: PropTypes.bool,

  //The button cannot be clicked if true
  //Default: false
  disabled: PropTypes.bool,

  //The button shows a loading spinner if true
  //Default: false
  isLoading: PropTypes.bool,

  //The button min-width is fixed to 9.875rem if true
  //Default: false
  fixedMinWidth: PropTypes.bool,

  //The button has a minHeight
  //Default: false
  minHeight: PropTypes.bool,
};

export const ButtonUI = withStyles(styles)(ButtonUI_);
