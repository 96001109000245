import React, { useEffect, useState } from 'react';
import { Icon, List } from '@mui/material';
import { withStyles } from '@mui/styles';
import BillCard from './BillCard';
import { Validate } from '../../../other';
import { CardModuleUI } from '../../common';
import { useSelector } from 'react-redux';

const CardModule = ({ classes, component = 'li', idCountry, ...rest }) => {
  const wordWithoutBills = {
    ARG: `Aún no hay facturas disponibles. Podrás descargarlas desde aquí luego del primer vencimiento.`,
    COL: `Aún no tienes vencimientos disponibles.`,
  };

  return (
    <CardModuleUI {...rest} component={component}>
      <span className={classes.bill_list_text}>
        {wordWithoutBills[idCountry]}
      </span>
    </CardModuleUI>
  );
};

const BillList = ({ bills = [], classes, isSuscribed }) => {
  const [showFooterMsg, setShowFooterMsg] = useState(false);
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  useEffect(() => {
    if (!showFooterMsg && idCountry === 'COL' && bills.length > 0) {
      if (bills.find((bill) => bill.paga === 1)) {
        setShowFooterMsg(true);
      }
    }
  }, [bills]);

  return (
    <>
      <h2 className={classes.bill_list_title}>Últimas Facturas</h2>
      <List>
        {bills.length > 0 ? (
          <>
            {bills.map((bill, key) => {
              if (bill.dias_vencimiento <= 10 && !bill.paga && key === 0) {
                return bills.length > 1 ? null : (
                  <CardModule
                    key={key}
                    component="li"
                    classes={classes}
                    idCountry={idCountry}
                  />
                );
              }

              return (
                <BillCard
                  key={key}
                  iconAvatar="receipt_long"
                  title={`Vencimiento ${bill.fecha_vencimiento}`}
                  bill={{
                    ...bill,
                    monto: Validate.number_format(bill.monto, idCountry),
                  }}
                  show={{
                    monto: idCountry === 'ARG' ? false : true,
                    infoPago: false,
                  }}
                  withoutMargin={false}
                  isSuscribed={isSuscribed}
                />
              );
            })}
            {showFooterMsg && (
              <div className={classes.msgWrapper}>
                <Icon>error_outline</Icon>
                <p>Enviamos las facturas a tu email personal.</p>
              </div>
            )}
          </>
        ) : (
          <CardModule component="li" classes={classes} idCountry={idCountry} />
        )}
      </List>
    </>
  );
};

const styles = () => ({
  bill_list_title: {
    marginTop: '8px',
    fontWeight: '400',
    lineHeight: '21px',
    marginBottom: '6px',
    fontSize: '1.125rem',
  },
  bill_list_text: {
    color: '#6F6F6F',
    fontWeight: '400',
    lineHeight: '20px',
    fontSize: '0.875rem',
  },
  msgWrapper: {
    display: 'flex',
    color: '#565656',
    fontSize: '1rem',
    alignItems: 'center',
    '& span': {
      marginRight: '10px',
    },
  },
});

export default withStyles(styles)(BillList);
