import { Grid } from '@mui/material';
import React from 'react';
import { InputUI, SelectUI } from '../../../../components/common';

export const CategoriesList = ({
  block,
  handleChangeBlock,
  handleChangeSelectBlock,
}) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InputUI input={block.bl_data.title} handleChange={handleChangeBlock} />
      </Grid>
      <Grid item xs={12}>
        <SelectUI
          data={block.bl_data.type}
          options={[
            {
              value: 0,
              label: 'Categorías raíz',
            },
            {
              value: 1,
              label: 'Todas las categorías',
            },
          ]}
          handleChangeSelect={handleChangeSelectBlock}
        />
      </Grid>
    </Grid>
  );
};
