import React from 'react';
import { withStyles } from '@mui/styles';

const Select_ = ({ options, label, classes, selectProps, ...rest }) => {
  return (
    <select label={label} style={{ width: '200px' }} {...selectProps} {...rest}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

const styles = (theme) => ({
  avatar: {
    width: '30px',
    height: '30px',
    margin: 0,
  },
  helper_text: {
    marginLeft: 0,
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
});

export const Select = withStyles(styles)(Select_);
