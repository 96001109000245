import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * @summary KeyValue pair
 * @typedef {Object} KeyValue
 * @property {string} key
 * @property {string} value

/**
 * @summary Stock that is part of a product sent in a chunk
 * @typedef {Object} ChunkStock
 * @property {number} idStock
 * @property {number} s_cantidad
 * @property {number} s_ilimitado
 * @property {string } s_sku
 * @property {number} s_precio
 * @property {number} s_precio_oferta
 * @property {number} s_precio_mayorista
 * @property {number} s_peso
 * @property {number} s_alto
 * @property {number} s_ancho
 * @property {number} s_profundidad
 * @property { KeyValue[]} valorAtributo
 */

/**
 * @summary Product sent in a chunk
 * @typedef {Object} ChunkProduct
 * @property {number} idProductos
 * @property {number[]} row
 * @property {string} p_nombre
 * @property {string} p_descripcion
 * @property {number} p_desactivado
 * @property {number} p_tipo_venta
 * @property {string} categorias
 * @property {string[]} atributos
 * @property {ChunkStock[] } stock
 */

import {
  cell_value,
  column_names,
  isEmptyString,
  isEmptyPriceAndDimension,
  ConvertStringToNumberOrCero,
} from './ExportFormat';

import { Validate } from '../../../other';

const STRING_VACIO = '';

export const FormatoChunks = (props) => {
  const {
    noOpAsync,
    chunk_size,
    products_hash_table,
    normalized_product_names,
    initial_completion_percentage,
    formato_chunks_percentage,
    handleUpdateCompletionPercentage,
    handleFormatoChunksFinish,
  } = props;

  const idCountry = useSelector(
    (state) => state.tienda.Country_idCountry || 'ARG'
  );

  /**
   * @summary return a product in chunk foramt
   * @param {Object[]} producto_rows
   * @returns { ChunkProduct }
   */
  const formatProductToChunk = async (producto_rows) => {
    const first_stock_cells = producto_rows[0].cells;

    const row_numbers = producto_rows.map((row) => row.row_number);

    //p_tipo_venta
    let p_tipo_venta;
    if (
      !isEmptyPriceAndDimension(
        cell_value(first_stock_cells, column_names.precio)
      ) &&
      !isEmptyPriceAndDimension(
        cell_value(first_stock_cells, column_names.precioMayorista)
      )
    ) {
      p_tipo_venta = 2;
    } else if (
      !isEmptyPriceAndDimension(
        cell_value(first_stock_cells, column_names.precioMayorista)
      )
    ) {
      p_tipo_venta = 1;
    } else {
      p_tipo_venta = 0;
    }

    //atributos
    let atributos = [];
    const nombre_atributo_1 = cell_value(
      first_stock_cells,
      column_names.nombreAtributo1
    );
    const nombre_atributo_2 = cell_value(
      first_stock_cells,
      column_names.nombreAtributo2
    );
    const nombre_atributo_3 = cell_value(
      first_stock_cells,
      column_names.nombreAtributo3
    );

    if (!isEmptyString(nombre_atributo_1))
      atributos.push(Validate.trim(nombre_atributo_1));
    if (!isEmptyString(nombre_atributo_2))
      atributos.push(Validate.trim(nombre_atributo_2));
    if (!isEmptyString(nombre_atributo_3))
      atributos.push(Validate.trim(nombre_atributo_3));

    const columna_descripcion = cell_value(
      first_stock_cells,
      column_names.descripcion
    );
    const columna_idProductos = cell_value(
      first_stock_cells,
      column_names.IDProduct
    );
    const columna_mostrarEnTienda = cell_value(
      first_stock_cells,
      column_names.mostrarEnTienda
    );

    let stock = [];

    producto_rows.forEach((stock_row) => {
      const stock_cells = stock_row.cells;
      const columna_idStock = cell_value(stock_cells, column_names.IDStock);
      const columna_stock = cell_value(stock_cells, column_names.stock);
      const columna_sku = cell_value(stock_cells, column_names.SKU);
      const columna_precio = cell_value(stock_cells, column_names.precio);
      const columna_precio_oferta = cell_value(
        stock_cells,
        column_names.precioOferta
      );
      const columna_precio_mayorista = cell_value(
        stock_cells,
        column_names.precioMayorista
      );
      const columna_peso = cell_value(stock_cells, column_names.peso);
      const columna_alto = cell_value(stock_cells, column_names.alto);
      const columna_ancho = cell_value(stock_cells, column_names.ancho);
      const columna_profundidad = cell_value(
        stock_cells,
        column_names.profundidad
      );
      const valor_atributo_1 = cell_value(
        stock_cells,
        column_names.valorAtributo1
      );
      const valor_atributo_2 = cell_value(
        stock_cells,
        column_names.valorAtributo2
      );
      const valor_atributo_3 = cell_value(
        stock_cells,
        column_names.valorAtributo3
      );
      let s_cantidad, s_ilimitado;
      //s_cantidad y s_ilimitado
      if (columna_stock === '') {
        s_cantidad = 0;
        s_ilimitado = 1;
      } else {
        s_cantidad = Number(columna_stock);
        s_ilimitado = 0;
      }

      //s_sku opcional
      const s_sku = columna_sku
        ? Validate.trim(String(columna_sku))
        : STRING_VACIO;

      //s_precio
      const s_precio = isEmptyPriceAndDimension(columna_precio)
        ? 0
        : ConvertStringToNumberOrCero(columna_precio, idCountry);

      //s_precio_oferta
      const s_precio_oferta = isEmptyPriceAndDimension(columna_precio_oferta)
        ? 0
        : ConvertStringToNumberOrCero(columna_precio_oferta, idCountry);

      //s_precio_mayorista opcional
      const s_precio_mayorista = isEmptyPriceAndDimension(
        columna_precio_mayorista
      )
        ? 0
        : ConvertStringToNumberOrCero(columna_precio_mayorista, idCountry);

      //s_peso, s_alto, s_ancho, s_profundidad
      const s_peso = isEmptyPriceAndDimension(columna_peso)
        ? 0
        : ConvertStringToNumberOrCero(columna_peso);
      const s_alto = isEmptyPriceAndDimension(columna_alto)
        ? 0
        : ConvertStringToNumberOrCero(columna_alto);
      const s_ancho = isEmptyPriceAndDimension(columna_ancho)
        ? 0
        : ConvertStringToNumberOrCero(columna_ancho);
      const s_profundidad = isEmptyPriceAndDimension(columna_profundidad)
        ? 0
        : ConvertStringToNumberOrCero(columna_profundidad);

      //valoratributo
      let valoratributo = [];
      if (!isEmptyString(nombre_atributo_1))
        valoratributo.push({
          key: nombre_atributo_1,
          value: String(valor_atributo_1),
        });
      if (!isEmptyString(nombre_atributo_2))
        valoratributo.push({
          key: nombre_atributo_2,
          value: String(valor_atributo_2),
        });
      if (!isEmptyString(nombre_atributo_3))
        valoratributo.push({
          key: nombre_atributo_3,
          value: String(valor_atributo_3),
        });

      const stock_item = {
        idStock: isNaN(columna_idStock) ? 0 : Number(columna_idStock),
        s_cantidad,
        s_ilimitado,
        s_sku,
        s_precio,
        s_precio_oferta,
        s_precio_mayorista,
        s_peso,
        s_alto,
        s_ancho,
        s_profundidad,
        valoratributo,
      };

      stock.push(stock_item);
    });

    let product = {
      idProductos: isNaN(columna_idProductos) ? 0 : Number(columna_idProductos),
      row: row_numbers,
      p_nombre: Validate.trim(
        String(cell_value(first_stock_cells, column_names.nombre))
      ),
      p_tipo_venta: p_tipo_venta,
      categorias: [
        Validate.trim(
          String(cell_value(first_stock_cells, column_names.categorias))
        ),
      ],
      atributos,
      stock,
    };

    if (columna_descripcion !== null) {
      product = {
        ...product,
        p_descripcion: columna_descripcion
          ? Validate.trim(String(columna_descripcion))
          : STRING_VACIO,
      };
    }

    if (columna_mostrarEnTienda !== null) {
      product = {
        ...product,
        p_desactivado: columna_mostrarEnTienda === 'Si' ? 0 : 1,
      };
    }

    return product;
  };

  useEffect(() => {
    const generateChunksAsync = async (
      products_hash_table,
      normalized_product_names
    ) => {
      const chunks_cantidad = Math.ceil(
        normalized_product_names.length / chunk_size
      );
      let actual_chunk = 0;
      let chunks = [];
      let completion_percentage;
      for (let i = 0; i < normalized_product_names.length; i += chunk_size) {
        actual_chunk += 1;
        const normalized_product_names_chunk = normalized_product_names.slice(
          i,
          i + chunk_size
        );
        let chunk = {
          data: [],
        };

        for (const normalized_product_name of normalized_product_names_chunk) {
          const producto_rows = products_hash_table[normalized_product_name];
          const producto = await formatProductToChunk(producto_rows); //No quitar await!!
          chunk.data.push(producto);
        }

        chunks.push(chunk);
        completion_percentage =
          initial_completion_percentage +
          formato_chunks_percentage * (actual_chunk / chunks_cantidad);
        await handleUpdateCompletionPercentage(completion_percentage);
        await noOpAsync();
      }
      completion_percentage =
        initial_completion_percentage + formato_chunks_percentage * 1;
      await handleUpdateCompletionPercentage(completion_percentage);
      await noOpAsync();
      handleFormatoChunksFinish(chunks);
    };

    generateChunksAsync(products_hash_table, normalized_product_names);
  }, []);
  return <></>;
};
