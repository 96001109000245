import React, { useState } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToggleUI, ButtonUI } from '../../common';

const DominioRecord = (props) => {
  let { modalHandler, dominio, record, divider, classes, record_index } = props;

  let name = record.name;

  return (
    <ListItem divider={divider}>
      <ListItemText
        primary={name}
        classes={{ root: classes.list_text }}
        secondary={
          <span className={classes.span}>
            <span className={classes.span_type}>Tipo {record.type}</span>
            {record.values.map((value, key) => {
              if (record.alias) {
                return (
                  <span className={classes.span} key={key}>
                    Apunta a Empretienda
                  </span>
                );
              } else {
                return (
                  <span className={classes.span} key={key}>
                    {value}
                  </span>
                );
              }
            })}
          </span>
        }
      />
      <ToggleUI show={!record.alias}>
        <ListItemSecondaryAction>
          <IconButton
            aria-label="Editar"
            onClick={(e) => modalHandler(true, 'editar', dominio, record_index)}
            size="large"
          >
            <Icon>edit</Icon>
          </IconButton>
          <IconButton
            aria-label="Borrar"
            onClick={(e) => modalHandler(true, 'borrar', dominio, record_index)}
            size="large"
          >
            <Icon>delete</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ToggleUI>
    </ListItem>
  );
};

const DominioNoRecord = ({ classes }) => {
  return (
    <ListItem>
      <ListItemText
        primary="Aún no tienes registros cargados (los registros NS y SOA no se muestran)"
        classes={{ root: classes.list_text }}
      />
    </ListItem>
  );
};

const DominioDNSZone_ = (props) => {
  let { classes, dominio, modalHandler } = props;

  const [toggle_status, setToggle] = useState(false);
  const setToggleStatus = () => setToggle(!toggle_status);

  let records = dominio.d_metadata.dns_zone.records;

  return (
    <Grid item xs={12}>
      <Typography
        className={classes.link_ver_dnszone}
        onClick={(e) => setToggleStatus()}
      >
        {toggle_status ? 'OCULTAR ZONA DNS' : 'VER ZONA DNS'}
      </Typography>
      <ToggleUI show={toggle_status}>
        <Grid container spacing={1} style={{ marginTop: '25px' }}>
          <Grid item xs={12}>
            <ButtonUI
              type="callf"
              label="Agregar registro"
              fullWidth={false}
              onClickFunc={(e) => modalHandler(true, 'agregar', dominio, null)}
            />
          </Grid>
          <Grid item xs={12}>
            <List classes={{ root: classes.list }}>
              {records.length ? (
                records.map((record, index) => (
                  <DominioRecord
                    dominio={dominio}
                    modalHandler={modalHandler}
                    divider={index < records.length - 1}
                    classes={classes}
                    record={record}
                    record_index={index}
                    key={index}
                  />
                ))
              ) : (
                <DominioNoRecord classes={classes} />
              )}
            </List>
          </Grid>
        </Grid>
      </ToggleUI>
    </Grid>
  );
};

const styles = (theme) => ({
  link_ver_dnszone: {
    fontSize: '0.8rem',
    marginTop: '0px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  list: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
  },
  list_text: {
    paddingRight: '100px',
  },
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  span_type: {
    display: 'block',
    width: '90%',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
});

export const DominioDNSZone = withStyles(styles, { withTheme: true })(
  DominioDNSZone_
);
