import React from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { BillCardBody } from './BillCardBody';

const BillCard = ({
  bill,
  classes,
  isSuscribed,
  headerText = '',
  component = 'div',
  setOpenSetIdPayment,
  withoutMargin = true,
  show = { monto: false, infoPago: false },
}) => {
  return (
    <Card
      component={component}
      className={classes.card_below}
      style={{ marginBottom: withoutMargin ? '0px' : '14px' }}
    >
      {headerText ? (
        <>
          <CardHeader
            className={classes.card_header}
            title={
              <span className={classes.card_header_title}>{headerText}</span>
            }
          />
          <Divider variant="fullWidth" />
        </>
      ) : (
        <></>
      )}
      <BillCardBody
        show={show}
        bill={bill}
        component={component}
        iconAvatar="receipt_long"
        isSuscribed={isSuscribed}
        isCardWithHeader={!!headerText}
        setOpenSetIdPayment={setOpenSetIdPayment}
        title={`Vencimiento ${bill.fecha_vencimiento}`}
      />
    </Card>
  );
};

const styles = (theme) => ({
  card_below: {
    ...theme.card.card,
    padding: '24px',
  },
  card_header: {
    ...theme.card.card_header,
    paddingTop: '0px',
    paddingBottom: '12px',
    padding: '0px',
  },
  card_header_title: {
    fontSize: '1.125rem',
    fontWeight: '400',
    lineHeight: '21px',
    color: '#383838',
  },
});

export default withStyles(styles)(BillCard);
