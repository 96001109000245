import { worksheet_format, column_names, cell_value } from './ExportFormat';

import { ImportError } from './ImportError';

import { IMPORT_ERROR_CODE_MESSAGE } from './ImportErrorCodes';
/**
 * @typedef {Object} Cell
 * @property {number} index - index in the row of the column related to the cell
 * @property {string} title - title of the column related to the cell
 * @property {string | number | null } value - value of the cell
 */

/**
 * @summary: validates values of cells
 * @param { Cell[][]} arrayOf_row_cells
 * @returns { {err: boolean, import_errors: ImportError[]} }
 */
export const validateCellsValues = (
  arrayOf_row_cells,
  dimensiones_requeridas
) => {
  let import_errors = [];
  let err = false;

  for (const row_cells of arrayOf_row_cells) {
    const row_number = arrayOf_row_cells.indexOf(row_cells) + 2; //+1 titles +1 array begins with 0 index
    const product_name = cell_value(row_cells, column_names.nombre);
    for (const cell of row_cells) {
      //buscar la validación para el title
      const column_name = cell.title;

      const format = worksheet_format.find(
        (aFormat) => aFormat.column_name === column_name
      );
      const validations = format.validations;

      for (const aValidation of validations) {
        if (aValidation.validation(cell, row_cells, dimensiones_requeridas)) {
          //ok
        } else {
          err = true;

          import_errors.push(
            new ImportError(
              product_name,
              row_number,
              //hot-fix. Inconsistencia de dimensiones cuando las dimensiones no son requeridas
              aValidation.message === IMPORT_ERROR_CODE_MESSAGE.ERR087 ||
              aValidation.message === IMPORT_ERROR_CODE_MESSAGE.ERR404
                ? 'N/A'
                : column_name,
              aValidation.message
            )
          );
        }
      }
    }
  }

  return {
    err,
    import_errors,
  };
};
