import React from 'react';
import { AppBar, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';

const TabsUI_ = ({ classes, value, handleChange, headerTab, contentTab }) => {
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="relative">
        <Tabs
          className={classes.textColor}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
        >
          {headerTab.map((labelTab, key) => (
            <Tab
              classes={{ root: classes.tabRoot, selected: classes.selected }}
              disableRipple
              key={key}
              label={labelTab}
            />
          ))}
        </Tabs>
        <div className={classes.container}></div>
      </AppBar>
      {contentTab.map((content, index) => {
        if (index === value) {
          return (
            <div key={index} className={classes.contentTab}>
              {content}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  textColor: { textColorSecondary: '#565656' },
  tabRoot: {
    opacity: 1,
    fontWeight: '500',
    minWidth: '158px',
    marginRight: '12px',
    fontSize: '1.125rem',
    backgroundColor: '#FFF',
    textTransform: 'initial',
  },
  selected: {
    color: '#53B755',
  },
  container: {
    top: 0,
    left: 0,
    zIndex: -1,
    width: '316px',
    height: '100%',
    background: '#fff',
    position: 'absolute',
  },
  appBar: {
    color: '#565656',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  contentTab: {
    padding: '24px',
    backgroundColor: '#fff',
  },
});

export const TabsUI = withStyles(styles)(TabsUI_);
