/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const blogReducer = (state = initState, action) => {
  let data;
  switch (action.type) {
    case Reducers.BLOG_GUARDAR:
      return action.data;
    case Reducers.BLOG_BORRAR:
      let blog_borrado = action.data;
      data = state.filter((blog) => {
        return blog.idBlog !== blog_borrado.idBlog;
      });
      return data;
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { blogReducer };
