import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useState } from 'react';
import { StyledChip } from './styles';
import { useTheme } from '@emotion/react';

const FeaturedTemplate = ({
  imgSrc,
  templateName,
  isInUse,
  chipsArray,
  handleApplyTemplate,
  featuredTemplateId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeChip, setActiveChip] = useState(chipsArray[0]);
  const handleChipClick = (chip) => {
    if (activeChip.templateName !== chip.templateName) {
      setActiveChip(chip);
    }
  };
  const renderButtons = () => {
    return (
      <Grid
        container
        margin="0 auto"
        mt={1.5}
        justifyContent="start"
        direction={{ xs: 'column', sm: 'row-reverse' }}
        gap={1}
        width={{ xs: '96%', md: '100%' }}
      >
        <Button
          variant="contained"
          onClick={() => handleApplyTemplate(featuredTemplateId, true)}
          sx={{ width: { xs: '100%', md: '160px' } }}
        >
          Probar plantilla
        </Button>
        <Button
          variant="outlined"
          href={activeChip.templateDemoUrl}
          target="_blank"
          sx={{ width: { xs: '100%', md: '160px' } }}
        >
          Ver tienda demo
        </Button>
      </Grid>
    );
  };

  return (
    <Grid item mb={1}>
      <Grid container direction={{ xs: 'column-reverse', md: 'row' }}>
        <Grid item xs={7.5}>
          <Box
            component="img"
            src={imgSrc}
            alt={templateName}
            sx={{
              height: 'auto',
              width: '100%',
              objectFit: 'contain',
              position: 'relative',
              right: { xs: '0px', md: '4px' },
            }}
          />
        </Grid>
        <Grid
          item
          xs={4.5}
          mt={{ xs: 0, lg: 6 }}
          px={{ xs: '2%', md: 'unset' }}
        >
          <Typography
            as="span"
            display="inline-block"
            mb={1}
            fontSize="16px"
            color={theme.texts.secondary}
          >
            ¡Nueva!
          </Typography>
          <Box display="flex" alignItems="center" mb={1.5}>
            <Typography
              mr={2}
              as="h2"
              color={theme.texts.secondary}
              fontSize="20px"
              fontWeight="600"
            >
              {templateName}
            </Typography>
            {isInUse && (
              <>
                <CheckCircleIcon
                  color="primary"
                  sx={{ marginRight: 1, fontSize: 24 }}
                />{' '}
                <Typography
                  as="span"
                  color="primary"
                  fontSize="16px"
                  fontWeight={500}
                >
                  Plantilla en uso
                </Typography>
              </>
            )}
          </Box>
          <Box mb={1.5}>
            {chipsArray.map((chip) => (
              <StyledChip
                key={chip.templateName}
                label={chip.templateName}
                clickable
                active={activeChip.templateName === chip.templateName ? 1 : 0}
                onClick={() => handleChipClick(chip)}
              />
            ))}
          </Box>
          <Typography fontSize="16px" color={theme.texts.secondary}>
            ¡Llegó un nuevo diseño de plantilla a Empretienda!{' '}
            <strong>
              Descubrí Ceres y conocé cómo el Carrusel de banners y las imágenes
              con link
            </strong>{' '}
            pueden transformar tu tienda en un diseño único y práctico para tus
            clientes.
          </Typography>
          {!isInUse && !isMobile && renderButtons()}
        </Grid>
      </Grid>
      {!isInUse && isMobile && renderButtons()}
    </Grid>
  );
};

export default FeaturedTemplate;
