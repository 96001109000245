import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI, SelectUI } from '../../common';

const ModalEditar = (props) => {
  let {
    editar,
    handleSubmit,
    handleChange,
    handleChangeSelect,
    modalHandler,
    modal_state,
    form_state,
    selects,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="editar"
      title="Editar cliente"
      modalHandler={modalHandler}
      aditional_param="editar"
      handleSubmit={handleSubmit}
      button_label="Editar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5} direction="row">
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_nombre} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_apellido} />
        </Grid>
        <Grid item xs={6}>
          <SelectUI
            data={editar.cl_tipo_identificacion}
            options={selects.cl_tipo_identificacion}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item xs={6}>
          <InputUI
            handleChange={handleChange}
            input={editar.cl_numero_identificacion}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectUI
            data={editar.cl_mayorista}
            options={selects.cl_mayorista_tipo}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_telefono} />
        </Grid>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_email} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_calle} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_numero} />
        </Grid>
        <Grid item xs={6}>
          <InputUI handleChange={handleChange} input={editar.cl_piso} />
        </Grid>
        <Grid item xs={6}>
          <InputUI handleChange={handleChange} input={editar.cl_dpto} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.cl_ciudad} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectUI
            data={editar.cl_provincia}
            options={selects.cl_provincia}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalEditar };
