import React from 'react';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { SelectUI, CardModuleUI, ToggleUI } from '../../../common';

import { AtributosInputList } from './AtributosInputList';

const AtributosSection_ = ({
  common,
  selects,
  classes,
  atributos_on,
  atributos_off,
  modalHandler,
  handleChangeSelect,
  handleChangeDynamicInput,
  handleOpcionesAvanzadasAtributos,
  handleChangeDeleteBloqueConAtributos,
  handleAgregarNuevoBloqueConAtributos,
}) => {
  return (
    <CardModuleUI below>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography
            as="h3"
            color="#333333"
            sx={{ fontSize: '1.1rem' }}
            mb={0.5}
            data-testid={'attributes-title-id'}
          >
            Atributos
          </Typography>
          <Typography
            fontSize="14px"
            color="#666666"
            as="p"
            data-testid={'first-attributes-subtitle-id'}
          >
            Te permiten ofrecer variantes del producto, como diferentes talles,
            colores y otras características.
          </Typography>
          <Typography
            fontSize="14px"
            color="#666666"
            as="p"
            data-testid={'second-attributes-subtitle-id'}
          >
            Con esta funcionalidad, podrás gestionar y diferenciar el stock
            según estos atributos. Además, tus clientes podrán elegir entre
            ellos.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
          >
            <Grid item sm={common.p_atributos.value === 1 ? 6 : 12} xs={12}>
              <SelectUI
                data={common.p_atributos}
                options={selects.p_atributos}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs="auto">
              <ToggleUI show={common.p_atributos.value === 1}>
                <Typography
                  variant="subtitle1"
                  className={classes.editar_configuracion_box}
                >
                  <span
                    onClick={(e) =>
                      modalHandler(true, 'configurar_atributos', false)
                    }
                    className={classes.text_link}
                  >
                    {common.p_producto_digital.value
                      ? 'EDITAR PRECIO Y ATRIBUTOS'
                      : 'EDITAR PRECIO, ATRIBUTOS Y DIMENSIONES'}
                  </span>
                </Typography>
              </ToggleUI>
            </Grid>
          </Grid>
        </Grid>
        <ToggleUI show={common.p_atributos.value === 1}>
          <Grid item xs={12}>
            <AtributosInputList
              atributos_on={atributos_on}
              atributos_off={atributos_off}
              common={common}
              modalHandler={modalHandler}
              handleChangeDynamicInput={handleChangeDynamicInput}
              handleOpcionesAvanzadasAtributos={
                handleOpcionesAvanzadasAtributos
              }
              handleAgregarNuevoBloqueConAtributos={
                handleAgregarNuevoBloqueConAtributos
              }
              handleChangeDeleteBloqueConAtributos={
                handleChangeDeleteBloqueConAtributos
              }
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={common.p_atributos.value === 1 && atributos_on.stock.length > 0}
        >
          <Grid item>
            <Typography variant="subtitle1">
              <span
                onClick={handleAgregarNuevoBloqueConAtributos}
                className={classes.text_link}
              >
                AGREGAR MÁS VARIANTES
              </span>
            </Typography>
          </Grid>
        </ToggleUI>
      </Grid>
    </CardModuleUI>
  );
};

const styles = (theme) => ({
  text_link: {
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  editar_configuracion_box: {
    marginTop: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-15px',
    },
  },
});

export const AtributosSection = withStyles(styles, { withTheme: true })(
  AtributosSection_
);
