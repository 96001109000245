import React from 'react';
import { withStyles } from '@mui/styles';

const ChipUI_ = ({ classes, children }) => {
  return <span className={classes.container}>{children}</span>;
};

const style = () => ({
  container: {
    color: '#FFF',
    width: '72px',
    height: 'auto',
    display: 'flex',
    fontWeight: 400,
    minHeight: '24px',
    fontSize: '0.875rem',
    alignItems: 'center',
    borderRadius: '40px',
    justifyContent: 'center',
    backgroundColor: '#53B755',
  },
});

export const ChipUI = withStyles(style)(ChipUI_);
