import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI, ToggleUI, ModuleCardTitleUI } from '../../common';

const obtenerCondicion = (df_condicion) => {
  switch (df_condicion) {
    case 'RI':
      return 'Responsable Inscripto';
    case 'MO':
      return 'Monotributista';
    case 'CF':
      return 'Consumidor Final';
    case 'EX':
      return 'Exento';
    default:
      return '-';
  }
};

const ModalDatosFacturacion = (props) => {
  let {
    datosfacturacion,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
    datosfacturacion_close,
  } = props;
  return (
    <ModalUI
      open={modal_state}
      id="datosfacturacion"
      title="Datos de facturación"
      modalHandler={modalHandler}
      aditional_param="datosfacturacion"
      handleSubmit={
        datosfacturacion_close
          ? (e) => {
              e.preventDefault();
              modalHandler(false, 'datosfacturacion');
            }
          : handleSubmit
      }
      button_label={datosfacturacion_close ? 'Cerrar' : 'Guardar'}
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={datosfacturacion.df_nombre}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={datosfacturacion.df_documento}
          />
        </Grid>
        <ToggleUI show={datosfacturacion.df_condicion.value ? true : false}>
          <ModuleCardTitleUI
            title="Información de facturación actual:"
            subtitles={[
              'Razón social: ' + datosfacturacion.df_nombre.value,
              'Condición: ' +
                obtenerCondicion(datosfacturacion.df_condicion.value),
            ]}
          />
        </ToggleUI>
      </Grid>
    </ModalUI>
  );
};

export { ModalDatosFacturacion };
