/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, CustomCardUI } from '../../../components/common';
import { Config } from '../../../other';

const EPick = (props) => {
  const { metodos_envio } = props;

  return (
    <Grid item lg={4} sm={6} xs={12}>
      <CustomCardUI
        title="E-Pick"
        subtitle={metodos_envio.me_epick ? 'Método activo' : 'Método no activo'}
        info="Es un servicio de logística low cost que ofrece envíos puerta a puerta."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_envio/epick-card-img.png`}
      >
        <ButtonUI
          type="link"
          link="/metodos-envio/epick"
          label="Configuración"
          size="large"
        />
      </CustomCardUI>
    </Grid>
  );
};

export { EPick };
