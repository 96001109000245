import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  paginasObtener,
  paginasBorrar,
  paginasOrdenar,
  tiendaObtener,
  tiendaPaginasActivar,
  tiendaPaginasDesactivar,
} from '../../store/actions';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI, AlertUI, ModuleTitleUI, ToggleUI } from '../common';
import { ModalBorrar, PaginasList } from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const Paginas_ = ({
  classes,
  tienda,
  paginasObtener,
  tiendaObtener,
  paginasBorrar,
  paginasOrdenar,
  tiendaPaginasActivar,
  tiendaPaginasDesactivar,
}) => {
  const [state, setState] = useState({
    borrar: {
      idPaginas: null,
      pa_titulo: '',
    },
    loading: {
      paginas: true,
      tienda: true,
      error: false,
    },
    modals: {
      borrar: false,
    },
    forms: {
      activar_paginas: false,
      desactivar_paginas: false,
      borrar: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });

  useEffect(() => {
    tiendaObtener((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          tienda: false,
          error: prevState.loading.error || err ? true : false,
        },
      }));
    });
    paginasObtener((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          paginas: false,
          error: prevState.loading.error || err ? true : false,
        },
      }));
    });
  }, []);

  const handleActivarPaginasSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        activar_paginas: true,
      },
    }));

    tiendaPaginasActivar((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        forms: {
          ...prevState.forms,
          activar_paginas: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      }));
    });
  };

  const handleDesactivarPaginasSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        desactivar_paginas: true,
      },
    }));

    tiendaPaginasDesactivar((err, resp) => {
      setState((prevState) => ({
        ...prevState,
        forms: {
          ...prevState.forms,
          desactivar_paginas: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      }));
    });
  };

  const handleSubmitBorrar = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        borrar: true,
      },
    }));

    let form = state.borrar;

    paginasBorrar({ idPaginas: form.idPaginas }, (err, resp) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            borrar: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'error',
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            borrar: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'success',
          },
          modals: {
            ...prevState.modals,
            borrar: false,
          },
        }));
      }
    });
  };

  const handleOrdenar = (paginas) => {
    paginasOrdenar(paginas, (err) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          loading: {
            error: true,
          },
        }));
      }
    });
  };

  const modalHandler = (open, id, aditional) => {
    if (open) {
      let articulo = aditional;
      setState((prevState) => ({
        ...prevState,
        borrar: {
          ...prevState.borrar,
          idPaginas: articulo.idPaginas,
          pa_titulo: articulo.pa_titulo,
        },
        modals: {
          ...prevState.modals,
          [id]: open,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        modals: {
          ...prevState.modals,
          [id]: open,
        },
      }));
    }
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const activar_desactivar_btn = tienda.t_paginas ? (
    <ButtonUI
      type="callf"
      label="Desactivar"
      fullWidth={false}
      minHeight={true}
      onClickFunc={handleDesactivarPaginasSubmit}
      isLoading={state.forms.desactivar_paginas}
    />
  ) : (
    <ButtonUI
      type="callf"
      label="Activar"
      fullWidth={false}
      minHeight={true}
      onClickFunc={handleActivarPaginasSubmit}
      isLoading={state.forms.activar_paginas}
    />
  );

  return (
    <WrapperLayout
      loading={state.loading.tienda || state.loading.paginas}
      error={state.loading.error}
    >
      <ModalBorrar
        borrar={state.borrar}
        handleSubmit={handleSubmitBorrar}
        modalHandler={modalHandler}
        modal_state={state.modals.borrar}
        form_state={state.forms.borrar}
      />
      <AlertUI
        open={state.alert.open}
        type={state.alert.type}
        message={state.alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <ModuleTitleUI
        title="Páginas de contenido"
        subtitles={[
          'Las páginas de contenido se agregan al menú principal y sirven para agregar información relevante en tu tienda.',
          'Podés agregar la información de tus políticas de devoluciones, preguntas frecuentes, tabla de talles, etc. ',
          'Podés definir si la página de información es con texto o un link externo.',
        ]}
        button={activar_desactivar_btn}
      />
      <ToggleUI show={tienda.t_paginas ? true : false}>
        <Card className={classes.card_below}>
          <CardHeader
            title="Mis páginas"
            className={classes.card_header}
            classes={{ action: classes.card_action_button }}
            action={
              <ButtonUI
                type="link"
                label="Agregar"
                link="/paginas/agregar"
                minHeight={true}
              />
            }
          />
          <Divider />
          <CardContent className={classes.card_content}>
            <PaginasList
              modalHandler={modalHandler}
              handleOrdenar={handleOrdenar}
            />
          </CardContent>
        </Card>
      </ToggleUI>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paginasObtener: (callback) => dispatch(paginasObtener(callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    paginasBorrar: (data, callback) => dispatch(paginasBorrar(data, callback)),
    paginasOrdenar: (data, callback) =>
      dispatch(paginasOrdenar(data, callback)),
    tiendaPaginasActivar: (callback) =>
      dispatch(tiendaPaginasActivar(callback)),
    tiendaPaginasDesactivar: (callback) =>
      dispatch(tiendaPaginasDesactivar(callback)),
  };
};

export const Paginas = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Paginas_)
);
