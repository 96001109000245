import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const clienteObtenerClientes = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/cliente', {
        params: data,
      })
      .then((resp) => {
        //guardo en redux
        const clientes = resp.data.data;

        if (data.page === undefined || data.page === 0) {
          dispatch({
            type: Reducers.CLIENTE_GUARDAR,
            data: clientes,
          });
        } else {
          dispatch({
            type: Reducers.CLIENTE_GUARDAR_NO_CLEAN,
            data: clientes,
          });
        }

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteObtenerClientesExportar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/cliente', {
        params: data,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteSolicitudMayoristaAccion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/cliente/${data.idClientes}/aceptar-cancelar-mayorista`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const cliente = resp.data.data;
        dispatch({ type: Reducers.CLIENTE_BORRAR, data: cliente });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteRevocarMayoristaAccion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/cliente/${data.idClientes}/revocar-mayorista`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const cliente = resp.data.data;
        dispatch({
          type: Reducers.CLIENTE_REVOCAR_MAYORISTA,
          data: cliente,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteExportarNuevosSuscriptores = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/cliente/exportar-nuevos-suscriptores`)
      .then((resp) => {
        //guardo en redux
        dispatch({ type: Reducers.CLIENTE_ACTUALIZAR_EXPORTADOS });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteEditarCliente = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/cliente/${data.idClientes}`, data)
      .then((resp) => {
        //guardo en redux
        const cliente = resp.data.data;
        dispatch({ type: Reducers.CLIENTE_EDITAR, data: cliente });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteBloquearCliente = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/cliente/${data.idClientes}/bloquear`)
      .then((resp) => {
        //guardo en redux
        const cliente = resp.data.data;
        dispatch({ type: Reducers.CLIENTE_EDITAR, data: cliente });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const clienteDesbloquearCliente = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/cliente/${data.idClientes}/desbloquear`)
      .then((resp) => {
        //guardo en redux
        const cliente = resp.data.data;
        dispatch({ type: Reducers.CLIENTE_EDITAR, data: cliente });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  clienteSolicitudMayoristaAccion,
  clienteObtenerClientes,
  clienteObtenerClientesExportar,
  clienteExportarNuevosSuscriptores,
  clienteRevocarMayoristaAccion,
  clienteEditarCliente,
  clienteBloquearCliente,
  clienteDesbloquearCliente,
};
