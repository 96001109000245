import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI, CardModuleUI } from '../../common';

const PlanInfoItem = ({ title, text, isLast }) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      style={{ marginBottom: isLast ? '0px' : '24px' }}
    >
      <Grid item xs={4}>
        <b style={{ fontSize: '0.87rem' }}>{title}</b>
      </Grid>
      <Grid item xs={8}>
        <span style={{ fontSize: '0.87rem', color: '#3A3A3A' }}>{text}</span>
      </Grid>
    </Grid>
  );
};

const PlanInfo = ({ title, button, items, classes }) => {
  return (
    <>
      <Grid
        container
        sx={{ marginBottom: '3px' }}
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          <span className={classes.titleSection}>{title}</span>
        </Grid>
        <Grid item>
          <ButtonUI
            type={button?.type || 'link'}
            label={button?.label || ''}
            fullWidth={false}
            onClickFunc={button?.onClick}
            link={button?.link}
            variant="text"
            className={classes.label}
          />
        </Grid>
      </Grid>
      <CardModuleUI>
        {items.map((item, idx) => {
          if (item.isSuscribed) {
            return (
              <span key={idx} className={classes.span_aux}>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    item.onClick();
                  }}
                  className={classes.link}
                >
                  {item.label}
                </a>
              </span>
            );
          }

          return (
            <PlanInfoItem
              key={idx}
              title={item.boldText}
              text={item.text}
              isLast={idx === items.length - 1}
            />
          );
        })}
      </CardModuleUI>
    </>
  );
};

const styles = (theme) => ({
  titleSection: {
    color: '#383838',
    fontSize: '0.87rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem',
    },
  },
  label: {
    letterSpacing: 0,
    fontSize: '0.87rem',
  },
  span_aux: {
    display: 'block',
    width: '90%',
    textDecoration: 'underline',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  link: {
    fontSize: '0.87rem',
    fontWeight: '400',
    lineHeight: '14px',
    color: '#A5A5A5',
    textDecoration: 'underline !important',
  },
});

export default withStyles(styles)(PlanInfo);
