/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI, ToggleUI } from '../../../../../components/common';

const ModalAltaEnvioEmprepack = (props) => {
  let {
    alta_envio_emprepack_imprimir,
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
    venta,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="alta_envio_emprepack_imprimir"
      title="Dar de alta envío por Emprepack"
      modalHandler={modalHandler}
      aditional_param="alta_envio_emprepack_imprimir"
      handleSubmit={handleSubmit}
      button_label="Dar alta envío"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <ToggleUI
          show={!alta_envio_emprepack_imprimir.aemp_alta_finalizada.value}
        >
          <Grid item xs={12}>
            <Typography>
              Recuerda que para dar de alta el envío, primero debe ser abonado
              desde "ACCIONES" -{'>'} "Abonar envío" (si ya lo hiciste, saltear
              éste paso). <br />
              <br />
              <strong>
                Luego de dar de alta el envío, debes pegar la etiqueta en tu
                paquete y llevarlo a la sucursal de Andreani seleccionada en Mi
                tienda -{'>'} Métodos de envío -{'>'} Emprepack.
              </strong>
            </Typography>
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={alta_envio_emprepack_imprimir.aemp_alta_finalizada.value}
        >
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: '20px',
                boxSizing: 'border-box',
              }}
            >
              <Typography>
                El envío ya ha sido de alta en Andreani y puedes imprimir la
                etiqueta desde el botón "ACCIONES" o haciendo click{' '}
                <a
                  href={venta.v_etiqueta_envio}
                  target="_blank"
                  style={{ color: 'inherit' }}
                >
                  <u>aquí</u>
                </a>
                .
              </Typography>
            </div>
          </Grid>
        </ToggleUI>
      </Grid>
    </ModalUI>
  );
};

export { ModalAltaEnvioEmprepack };
