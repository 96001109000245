import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Grid,
  Avatar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Validate } from '../../../../../other';
import { ToggleUI } from '../../../../../components/common';

const OrdenOverviewPago = ({ classes, venta, ayuda }) => {
  return (
    <Card className={classes.card} sx={{ height: '100%' }}>
      <CardHeader
        title={`Pago: ${Validate.trim_uppercase(
          Validate.label_metodo_pago(venta.v_metodo_pago)
        )}`}
        subheader={
          venta.v_metodo_pago === 1 &&
          venta.v_estado_pago !== -1 &&
          venta.v_codigo_pago_adicional
            ? 'Operación: #' + venta.v_codigo_pago_adicional
            : ''
        }
        avatar={
          <Avatar
            classes={{ root: classes.card_avatar }}
            src={Validate.logo_metodo_pago(venta.v_metodo_pago)}
          />
        }
        className={classes.card_header}
        classes={{ title: classes.card_title }}
      />
      <Divider />
      <CardContent className={classes.card_content_detail}>
        <Typography className={classes.overview_detail}>
          Estado del pago: {ayuda.estado_pago.estado}
        </Typography>
        <ToggleUI show={ayuda.estado_pago.ayuda ? true : false}>
          <Typography className={classes.overview_subdetail}>
            {ayuda.estado_pago.ayuda}.
            <ToggleUI show={venta.v_estado_pago === 5}>
              {venta.v_stock_devuelto
                ? ' Los productos de la orden fueron devueltos nuevamente al stock'
                : '. Los productos de la orden aún no fueron devueltos al stock, puedes hacerlo desde el menú "ACCIONES"'}
            </ToggleUI>
          </Typography>
        </ToggleUI>
      </CardContent>
    </Card>
  );
};

const OrdenOverviewEnvio = ({ classes, venta, ayuda }) => {
  let envio_seleccionado = JSON.parse(venta.v_arr_envio_seleccionado_json);

  return (
    <Card className={classes.card} sx={{ height: '100%' }}>
      <CardHeader
        title={`Envío: ${Validate.trim_uppercase(
          Validate.label_metodo_envio(venta.v_metodo_envio, envio_seleccionado)
        )}`}
        avatar={
          <Avatar
            classes={{ root: classes.card_avatar }}
            src={Validate.logo_metodo_envio(venta.v_metodo_envio)}
          />
        }
        className={classes.card_header}
        classes={{ title: classes.card_title }}
      />
      <Divider />
      <CardContent className={classes.card_content_detail}>
        <Typography className={classes.overview_detail}>
          Estado del envío:{' '}
          {Validate.trim_uppercase(
            Validate.label_estado_envio(
              venta.v_metodo_envio,
              venta.v_estado_envio
            )
          )}
        </Typography>
        <ToggleUI show={ayuda.metodo_envio.ayuda ? true : false}>
          <Typography className={classes.overview_subdetail}>
            {ayuda.metodo_envio.ayuda}.
          </Typography>
        </ToggleUI>
      </CardContent>
    </Card>
  );
};

const OrdenOverviewObservacion = ({ classes, venta }) => {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <Typography className={classes.overview_detail_with_icon}>
          <ChatOutlinedIcon
            className={classes.overview_detail_observation_icon}
          />{' '}
          Observaciones
        </Typography>
        <Typography className={classes.overview_subdetail}>
          {venta.v_observacion}
        </Typography>
      </CardContent>
    </Card>
  );
};

const OrdenOverview_ = (props) => {
  let { venta, ayuda, classes } = props;

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <OrdenOverviewPago classes={classes} venta={venta} ayuda={ayuda} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <OrdenOverviewEnvio classes={classes} venta={venta} ayuda={ayuda} />
      </Grid>
      <ToggleUI show={venta.v_observacion ? true : false}>
        <Grid item xs={12}>
          <OrdenOverviewObservacion classes={classes} venta={venta} />
        </Grid>
      </ToggleUI>
    </Grid>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  card_title: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  card_avatar: {
    width: '55px',
    height: '55px',
  },
  card_content: {
    padding: '10px !important',
  },
  card_content_detail: {
    padding: '10px !important',
    [theme.breakpoints.up('md')]: {
      minHeight: '110px',
    },
  },
  overview_detail: {
    fontSize: '1rem',
    margin: '1px 0px',
    fontWeight: '500',
    color: 'rgba(0,0,0,0.87)',
  },
  overview_detail_with_icon: {
    fontSize: '1.1rem',
    fontWeight: '500',
    margin: '1px 0px',
    color: 'rgba(0,0,0,0.87)',
    display: 'flex',
    alignItems: 'center',
  },
  overview_detail_observation_icon: {
    marginRight: '5px',
    color: 'rgba(0,0,0,0.87)',
  },
  overview_subdetail: {
    fontSize: '0.87rem',
    margin: '10px 0px 1px 0px',
    fontWeight: '400',
    color: 'rgba(0,0,0,0.87)',
  },
});

export const OrdenOverview = withStyles(styles, { withTheme: true })(
  OrdenOverview_
);
