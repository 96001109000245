import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { ToggleUI } from '../../../components/common';
import {
  tiendaObtener,
  ventaObtenerVentas,
  metodospagoObtener,
  metodosenvioObtener,
  productoObtenerProductos,
} from '../../../store/actions';
import { StepsCompletedMessages } from './StepsCompletedMessages';
import { SpinnerProgress } from '../../../components/productos-importar/subcomponents/SpinnerProgress';
import Collapse from '@mui/material/Collapse';
import { StepsGuide } from './StepsGuide';

const Onboarding_ = ({
  getSales,
  getShopData,
  salesAmount,
  getProducts,
  getPaymentMethods,
  onboardingStepsCompleted,
  allRequestsLoaded,
  setOnboardingStepsCompleted,
  setAllRequestsLoaded,
  setSalesAmount,
  getShipmentMethods,
  handleOnboardingError,
}) => {
  const { idCountry, domainStore } = useSelector((state) => ({
    domainStore: state.dominio.d_nombre,
    idCountry: state.tienda.Country_idCountry || 'ARG',
  }));

  const [salesStepDone, setSalesStepDone] = useState(null);
  const [designStepDone, setDesignStepDone] = useState(null);
  const [addProductsStepDone, setAddProductsStepDone] = useState(null);
  const [paymentMethodStepDone, setPaymentMethodStepDone] = useState(null);
  const [shipmentMethodStepDone, setShipmentMethodStepDone] = useState(null);
  const [showCardStoreTips, setShowCardStoreTips] = useState(false);
  const [showCardSuccess, setShowCardSuccess] = useState(true);

  useEffect(() => {
    getProducts(
      {
        page: 0,
      },
      (err, resp) => {
        if (!err) {
          if (resp.data && resp.data.length > 0) {
            setAddProductsStepDone(true);
          } else {
            setAddProductsStepDone(false);
          }
        } else {
          handleOnboardingError(err);
        }
      }
    );

    getShopData((err, resp) => {
      if (!err) {
        if (resp.data && resp.data.t_logo !== null) {
          setDesignStepDone(true);
        } else {
          setDesignStepDone(false);
        }
      } else {
        handleOnboardingError(err);
      }
    });

    getPaymentMethods((err, resp) => {
      if (!err) {
        if (
          resp.data &&
          (resp.data.mp_mercadopago === 1 ||
            resp.data.mp_mobbex === 1 ||
            resp.data.mp_deposito === 1 ||
            resp.data.mp_uala === 1 ||
            resp.data.mp_efectivo === 1)
        ) {
          setPaymentMethodStepDone(true);
        } else {
          setPaymentMethodStepDone(false);
        }
      } else {
        handleOnboardingError(err);
      }
    });

    getShipmentMethods((err, resp) => {
      if (!err) {
        if (
          resp.data &&
          (resp.data.me_retiro_local === 1 ||
            resp.data.me_oca === 1 ||
            resp.data.me_correo_argentino === 1 ||
            resp.data.me_mercadoenvios === 1 ||
            resp.data.me_epick === 1 ||
            resp.data.me_emprepack === 1 ||
            resp.data.me_personalizados === 1)
        ) {
          setShipmentMethodStepDone(true);
        } else {
          setShipmentMethodStepDone(false);
        }
      } else {
        handleOnboardingError(err);
      }
    });

    getSales(
      {
        page: 0,
      },
      (err, resp) => {
        if (!err) {
          if (resp.data && resp.data.length > 0) {
            setSalesStepDone(true);
            setSalesAmount(resp.data.length);
          } else {
            setSalesStepDone(false);
            setSalesAmount(0);
          }
        } else {
          handleOnboardingError(err);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (
      !allRequestsLoaded &&
      salesStepDone !== null &&
      designStepDone !== null &&
      addProductsStepDone !== null &&
      paymentMethodStepDone !== null &&
      (shipmentMethodStepDone !== null || idCountry === 'COL')
    ) {
      setAllRequestsLoaded(true);
    }

    if (
      !onboardingStepsCompleted &&
      addProductsStepDone &&
      designStepDone &&
      paymentMethodStepDone &&
      (shipmentMethodStepDone || idCountry === 'COL')
    ) {
      setOnboardingStepsCompleted(true);
    }
  }, [
    salesStepDone,
    designStepDone,
    addProductsStepDone,
    paymentMethodStepDone,
    shipmentMethodStepDone,
  ]);

  const stepsStatus = {
    shopCreated: true,
    addProductsStepDone,
    designStepDone,
    paymentMethodStepDone,
    shipmentMethodStepDone,
  };

  // Calcular el porcentaje completado
  const totalSteps =
    idCountry === 'COL'
      ? Object.keys(stepsStatus).length - 1
      : Object.keys(stepsStatus).length;
  const completedSteps = Object.values(stepsStatus).filter(
    (step) => step
  ).length;
  const completionPercentage = (completedSteps / totalSteps) * 100;

  return (
    <ToggleUI show={salesAmount <= 1 && (showCardStoreTips || showCardSuccess)}>
      <Box
        sx={{
          gridColumn: { xs: '1 / 5', md: '1 / 9' },
          gridRow: 'span 3',
        }}
      >
        <Grid item xs={12} lg={8}>
          <ToggleUI show={!allRequestsLoaded}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <SpinnerProgress />
            </Box>
          </ToggleUI>
          <Collapse in={allRequestsLoaded}>
            <ToggleUI
              show={
                allRequestsLoaded &&
                (onboardingStepsCompleted || salesAmount === 1)
              }
            >
              <StepsCompletedMessages
                salesAmount={salesAmount}
                domainStore={`https://${domainStore}`}
                showCardStoreTips={showCardStoreTips}
                setShowCardStoreTips={setShowCardStoreTips}
                showCardSuccess={showCardSuccess}
                setShowCardSuccess={setShowCardSuccess}
              />
            </ToggleUI>
            <ToggleUI
              show={
                allRequestsLoaded &&
                !onboardingStepsCompleted &&
                salesAmount < 1
              }
            >
              <StepsGuide
                idCountry={idCountry}
                stepsStatus={stepsStatus}
                completionPercentage={completionPercentage}
              />
            </ToggleUI>
          </Collapse>
        </Grid>
      </Box>
    </ToggleUI>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShopData: (callback) => dispatch(tiendaObtener(callback)),
    getPaymentMethods: (callback) => dispatch(metodospagoObtener(callback)),
    getShipmentMethods: (callback) => dispatch(metodosenvioObtener(callback)),
    getSales: (data, callback) => dispatch(ventaObtenerVentas(data, callback)),
    getProducts: (data, callback) =>
      dispatch(productoObtenerProductos(data, callback)),
  };
};

export const Onboarding = connect(null, mapDispatchToProps)(Onboarding_);
