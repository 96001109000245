const metodospagoObtener = () => {
  return {
    status: 200,
    data: {
      mp_paypal: 0,
      mp_mercadopago: 0,
      mp_todopago: 0,
      mp_acordar: 1,
      mp_efectivo: 0,
      mp_mobbex: 0,
      mp_deposito: 0,
      mp_uala: 0,
      mp_mercadopago_migrado: 0,
      mp_todopago_merchant_id: null,
      mp_todopago_api_key: null,
      mp_mobbex_pahora: 0,
      mp_deposito_datos: null,
      mp_deposito_descripcion: null,
      mp_deposito_comprobante: 0,
      mp_deposito_descuento: 0,
      mp_deposito_porcentaje: 0,
      mp_efectivo_descuento: 0,
      mp_efectivo_porcentaje: 0,
      mp_efectivo_descripcion: null,
      mp_uala_username: null,
      mp_uala_client_id: null,
      mp_uala_client_secret_id: null,
      mp_mercadopago_activate_link: '',
    },
    message: 'Ok',
  };
};

export default {
  metodospagoObtener,
};
