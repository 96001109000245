import React, { useState } from 'react';
import { Validate, Config } from '../../../other';
import { Box, Grid, Typography } from '@mui/material';
import {
  InputUI,
  ToggleUI,
  SelectUI,
  ImageUI,
  ButtonUI,
  ModalLiteUI,
  CodeUI,
} from '../../../components/common';
import { InputColorCircle, SelectFont, InfoText } from '.';
import { borderRadiusOptions, colorOptions } from '../jsonSelects';

export const ShopSettings = ({
  settings,
  handleChange,
  handlerShowAlertUI,
  handleChangeSelect,
  handleChangeFileSettings,
}) => {
  const [isMoreColorOptionsOpen, setIsMoreColorOptionsOpen] = useState(false);
  const [isCssEditorModalOpen, setIsCssEditorModalOpen] = useState(false);

  return (
    <>
      <ModalLiteUI
        open={isCssEditorModalOpen}
        title="Código CSS"
        subtitle='Te permite editar o sobreescribir los estilos de la plantilla, recuerda que esta función es avanzada y un código CSS mal escrito puede provocar problemas en tu tienda. Adicionalmente, queda prohibido remover/ocultar la leyenda "Tienda creada con Empretienda".'
        modalHandler={() => setIsCssEditorModalOpen((prevState) => !prevState)}
        full_size
        id="codigo_css"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CodeUI
              input={settings.css}
              lang="css"
              width="100%"
              height="400px"
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonUI
              type="callf"
              onClickFunc={() =>
                setIsCssEditorModalOpen((prevState) => !prevState)
              }
              label="Cerrar editor CSS"
            />
          </Grid>
        </Grid>
      </ModalLiteUI>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <InfoText first title="Logo" />
          <Box
            border={
              settings.t_logo.files.length > 0 &&
              '1px solid rgba(0, 0, 0, 0.12)'
            }
          >
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              data={settings.t_logo}
              max_files={1}
              max_size={2}
              allowed_files={['jpg', 'png', 'jpeg', 'webp', 'gif']}
              input_allowed_mime="image/jpg,image/jpeg,image/png,image/webp,image/gif"
              handleChange={handleChangeFileSettings}
              cdn_prefix={Config.CLOUDFRONT_LOGOS_CDN}
              upload_path="logo"
              row_height="150px"
              reducer_max_width={400}
              reducer_max_height={400}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              row_spacing={0}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" item xs={12} pt={0.5}>
            <InfoText
              title="Favicon"
              text="Ícono que se utiliza para representar un sitio web en la pestaña del navegador."
            />
          </Grid>
          <Box
            border={
              settings.t_logo.files.length > 0 &&
              '1px solid rgba(0, 0, 0, 0.12)'
            }
          >
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              data={settings.t_favicon}
              max_files={1}
              max_size={0.5}
              allowed_files={['png']}
              input_allowed_mime="image/png"
              handleChange={handleChangeFileSettings}
              cdn_prefix={Config.CLOUDFRONT_LOGOS_CDN}
              upload_path="favicon"
              row_height="96px"
              reducer_max_width={128}
              reducer_max_height={128}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              row_spacing={0}
            />
          </Box>
        </Grid>
        <Grid item xs={12} mt={0.5}>
          <InfoText title="Colores" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectUI
                data={settings.color}
                options={colorOptions}
                handleChangeSelect={handleChangeSelect}
                label_atribute="gama"
                value_atribute="id"
              />
              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '0.65rem',
                  lineHeight: '1rem',
                  fontWeight: 400,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={() =>
                  setIsMoreColorOptionsOpen((prevState) => !prevState)
                }
              >
                {isMoreColorOptionsOpen
                  ? 'MOSTRAR MENOS OPCIONES DE COLOR'
                  : 'MOSTRAR MÁS OPCIONES DE COLOR'}
              </Typography>
            </Grid>
            <ToggleUI show={isMoreColorOptionsOpen}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.primary_text}
                  adornment={
                    <InputColorCircle color={settings.primary_text.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.primary_background}
                  adornment={
                    <InputColorCircle
                      color={settings.primary_background.value}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.primary_contrastText}
                  adornment={
                    <InputColorCircle
                      color={settings.primary_contrastText.value}
                    />
                  }
                />
              </Grid>
              <ToggleUI
                show={Validate.in_array(
                  settings.template_id.value,
                  [3, 5, 7, 8]
                )}
              >
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    input={settings.secondary_text}
                    adornment={
                      <InputColorCircle color={settings.secondary_text.value} />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    input={settings.secondary_background}
                    adornment={
                      <InputColorCircle
                        color={settings.secondary_background.value}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    input={settings.secondary_contrastText}
                    adornment={
                      <InputColorCircle
                        color={settings.secondary_contrastText.value}
                      />
                    }
                  />
                </Grid>
              </ToggleUI>
            </ToggleUI>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoText title="Tipografías" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectFont
                data={settings.font_title}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFont
                data={settings.font_text}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoText title="Bordes" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectUI
                data={settings.other_border_radius}
                options={borderRadiusOptions}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Mensaje de encabezado" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.message_text}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.message_link}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Edición CSS (avanzado)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ButtonUI
                  type="callf"
                  variant="outlined"
                  color="primary"
                  onClickFunc={() =>
                    setIsCssEditorModalOpen((prevState) => !prevState)
                  }
                  label="Abrir editor CSS"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
