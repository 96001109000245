import React, { useCallback, useMemo, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Divider,
  Button,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Config } from '../../../other';
import {
  CardModuleUI,
  ModuleCardTitleUI,
  ToggleUI,
} from '../../../components/common';
import ButtonMui from '../../../components/common/ButtonMui';
import { exportToExcel } from '../../../libs/exportToExcel';

const generateSoldProductsArray = (sales) => {
  const soldProducts = [];

  sales.forEach((sale) => {
    const shoppingCart = JSON.parse(sale.v_arr_carrito_json);

    Object.keys(shoppingCart).forEach((id) => {
      const productInShoppingCart = shoppingCart[id];
      const existingProduct = soldProducts.find(
        (product) => product.idStock === id
      );

      if (existingProduct) {
        // If the product already exists, the quantity is increased
        existingProduct.cantidad += productInShoppingCart.cantidad;
      } else {
        // Attributes creation if they exist
        const atrributesList =
          productInShoppingCart.atributos > 0
            ? Object.values(productInShoppingCart.info_atributos)
                .map(
                  ({ at_nombre, vat_valor }) => `(${at_nombre}: ${vat_valor})`
                )
                .join(' ')
            : '';

        soldProducts.push({
          idStock: id,
          cantidad: productInShoppingCart.cantidad,
          nombre: `${productInShoppingCart.nombre}${
            atrributesList ? ' ' + atrributesList : ''
          }`,
          imagen: productInShoppingCart.img,
          sku: productInShoppingCart.sku || '-',
        });
      }
    });
  });

  // Return sorted products by most sold to least sold
  return soldProducts.sort((a, b) => b.cantidad - a.cantidad);
};

export const SoldProductsList = ({ salesData }) => {
  const [showAll, setShowAll] = useState(false);
  const subtitle =
    'Solo se contabilizan los productos con ventas finalizadas en el periodo seleccionado.';

  const products = useMemo(
    () => generateSoldProductsArray(salesData),
    [salesData]
  );
  const productsToShow = useMemo(
    () => products.slice(0, showAll ? products.length : 15),
    [products, showAll]
  );

  const columns = [
    { label: 'Producto', value: 'nombre' },
    { label: 'SKU', value: 'sku' },
    { label: 'Cantidad total vendida', value: 'cantidad' },
  ];

  const config = {
    filename: `Productos-vendidos`,
    sheetName: 'Productos',
    columns: columns,
  };

  const handleDownload = useCallback(() => {
    exportToExcel(products, config);
  }, [products]);

  return (
    <>
      {salesData.length ? (
        <CardModuleUI below disablePadding>
          <Box sx={{ padding: '30px 20px' }}>
            <Grid
              container
              spacing={1.5}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xl={10} md={10} xs={12}>
                <ModuleCardTitleUI
                  title="Productos vendidos"
                  subtitles={[subtitle]}
                />
              </Grid>
              <Grid item xl={1} md={2} xs={12}>
                <Button
                  fullWidth
                  onClick={() => handleDownload()}
                  variant="contained"
                >
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ overflowX: 'auto' }}>
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Cantidad total vendida</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsToShow.map((producto, idx) => {
                  return (
                    <TableRow hover key={idx}>
                      <TableCell
                        sx={{
                          borderBottom:
                            idx === productsToShow.length - 1 &&
                            '0px solid transparent',
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Avatar
                            alt="producto"
                            src={
                              Config.CLOUDFRONT_PRODUCTS_CDN + producto.imagen
                            }
                            sx={{
                              marginRight: '20px',
                              height: '25px',
                              width: '25px',
                            }}
                          />
                          {producto.nombre}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom:
                            idx === productsToShow.length - 1 &&
                            '0px solid transparent',
                        }}
                      >
                        {producto.sku}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom:
                            idx === productsToShow.length - 1 &&
                            '0px solid transparent',
                        }}
                      >
                        {producto.cantidad}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <ToggleUI show={products.length > 15 && !showAll}>
            <Box
              sx={{
                paddingTop: '25px',
                paddingBottom: '25px',
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <ButtonMui
                    onClick={() => setShowAll(true)}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Ver más
                  </ButtonMui>
                </Grid>
              </Grid>
            </Box>
          </ToggleUI>
        </CardModuleUI>
      ) : (
        <CardModuleUI below>
          <ModuleCardTitleUI
            title="Productos vendidos"
            subtitles={[subtitle]}
          />
          <Typography
            sx={{
              fontSize: '0.9rem',
              margin: '20px 0px 0px 0px',
              padding: 0,
            }}
          >
            No se han encontrado productos vendidos ya que no hay ventas
            finalizadas en dicho periodo
          </Typography>
        </CardModuleUI>
      )}
    </>
  );
};
