import React, { useEffect } from 'react';

export const ChunkSepararNuevosEditados = (props) => {
  const {
    handleChunkSepararNuevosEditados,
    handleFinishSepararNuevosEditados,
  } = props;

  useEffect(() => {
    const handleChunkSepararNuevosEditadosAsync = async () => {
      await handleChunkSepararNuevosEditados();
      handleFinishSepararNuevosEditados();
    };

    handleChunkSepararNuevosEditadosAsync();
  }, []);
  return <></>;
};
