/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = {};

const metodospagoReducer = (state = initState, action) => {
  switch (action.type) {
    case Reducers.METODOSPAGO_GUARDAR:
      return action.data;
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { metodospagoReducer };
