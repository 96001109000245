import React from 'react';
import {
  Box,
  Button,
  Drawer,
  Hidden,
  Icon,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../../components/amplitude';

export const DrawerLayout = ({
  children,
  isDrawerOpen,
  openCloseDrawer,
  handleUpdatePreview,
}) => {
  const theme = useTheme();

  const handlePreviewClick = async (e) => {
    await handleUpdatePreview(e);
    openCloseDrawer(e);
    createAmplitudeEventWithDevice(
      design_amplitude_events.diseño_selecciona_ver_cambios.event_name
    );
  };

  const drawerContent = (
    <Box>
      <Box
        sx={{
          ...theme.mixins.toolbar,
          color: 'rgba(0, 0, 0, 0.87)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          padding: '0px 10px 0px 5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="Close drawer"
          onClick={openCloseDrawer}
        >
          <Icon>close</Icon>
        </IconButton>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={handlePreviewClick}
          sx={{ padding: '4px 24px' }}
        >
          Ver cambios
        </Button>
      </Box>
      <Box>{children}</Box>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { xs: 'unset', md: '300px' },
        flexShrink: { xs: 'unset', md: 0 },
        zIndex: 1,
      }}
    >
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="left"
          variant="temporary"
          open={isDrawerOpen}
          onClose={openCloseDrawer}
          PaperProps={{
            sx: { width: '300px', overflowX: 'hidden' },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open
          PaperProps={{
            sx: { width: '300px', overflowX: 'hidden' },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </Box>
  );
};
