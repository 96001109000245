import React from 'react';
import {
  Grid,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ErrorTable,
  ImportTextErrorTitle,
  TableCellColumnName,
  TableCellValue,
} from './styles';

export const TableErrors = ({ import_errors }) => {
  const importingErrors = import_errors.length;
  const multipleErrors = importingErrors > 1;

  return (
    <Grid item xs={12}>
      <ImportTextErrorTitle>
        Se detect{multipleErrors ? 'aron ' : 'ó '}
        <Typography fontSize="20px" fontWeight="500" as="span" color="#FF0000">
          {importingErrors} {multipleErrors ? 'errores' : 'error'}
        </Typography>{' '}
        en su planilla de productos
      </ImportTextErrorTitle>
      <ErrorTable>
        <TableHead>
          <TableRow>
            <TableCellColumnName>N°</TableCellColumnName>
            <TableCellColumnName sx={{ minWidth: '150px' }}>
              Nombre del producto
            </TableCellColumnName>
            <TableCellColumnName>Fila</TableCellColumnName>
            <TableCellColumnName>Columna</TableCellColumnName>
            <TableCellColumnName>Tipo de error</TableCellColumnName>
          </TableRow>
        </TableHead>
        <TableBody>
          {import_errors.map((error, i) => (
            <TableRow key={i}>
              <TableCellValue component="th" scope="row">
                {i + 1}
              </TableCellValue>
              <TableCellValue>{error.product_name}</TableCellValue>
              <TableCellValue>{error.row_number}</TableCellValue>
              <TableCellValue>{error.column_name}</TableCellValue>
              <TableCellValue>{error.error_type}</TableCellValue>
            </TableRow>
          ))}
        </TableBody>
      </ErrorTable>
    </Grid>
  );
};
