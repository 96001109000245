import React, { useState } from 'react';
import { Box, Button, Grid, Icon, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalUI, ToggleUI } from '../../../../components/common';
import { BlockList } from './BlockList';
import { BlockAdd } from './BlockAdd';
import { InfoText } from '../InfoText';
import { getTemplateName } from '../../../../other/getTemplateName';

export const TemplateAndSectionsSettings = ({
  bloques,
  agregar,
  dataForLocalStorage,
  handleChangeSelect,
  handleSortBlocks,
  handleChangeMenuTab,
  handleDeleteBlock,
  handleAddBlock,
  handleDisableEnableBlock,
}) => {
  const router = useHistory();
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';
  const [modalState, setModalState] = useState(null);
  const toggleModalBorrar = (index) => setModalState(index);
  const shop = useSelector((state) => state.tienda);
  const selectedTemplate = localStorage.getItem(
    `template-selected-${shop.idTienda}`
  );
  const templateName = getTemplateName(
    selectedTemplate ? Number(selectedTemplate) : shop.Templates_idTemplates
  );

  return (
    <Grid container>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          marginBottom: 2,
          display: 'flex',
          fontWeight: 500,
          borderRadius: '2px',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #53B755',
          gap: '10px',
          '& span': {
            color: '#53B755',
          },
        }}
      >
        <Icon>check_circle</Icon>
        Plantilla en uso: {templateName}
      </Box>
      <ToggleUI show={idCountry === 'ARG'}>
        <Button
          onClick={() => {
            router.push('/selecciona-plantilla');
            dataForLocalStorage();
          }}
          fullWidth
          variant="contained"
          sx={{ marginBottom: 3 }}
        >
          Ver todas las plantillas
        </Button>
      </ToggleUI>
      <ModalUI
        open={modalState !== null}
        id="borrar"
        title="Borrar sección"
        modalHandler={() => toggleModalBorrar(null)}
        aditional_param="borrar"
        handleSubmit={(e) => {
          handleDeleteBlock(e, modalState);
          toggleModalBorrar(null);
        }}
        button_label="Borrar"
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography>
              ¿Querés borrar la sección? Esta acción no se podrá deshacer.
            </Typography>
          </Grid>
        </Grid>
      </ModalUI>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InfoText
            first
            title="Secciones en uso"
            text="En este listado verás las secciones que están aplicadas."
          />
          <BlockList
            bloques={bloques}
            toggleModalBorrar={toggleModalBorrar}
            handleSortBlocks={handleSortBlocks}
            handleChangeMenuTab={handleChangeMenuTab}
            handleDisableEnableBlock={handleDisableEnableBlock}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoText
            title="Secciones"
            text="Las secciones son los bloques que componen tu sitio web. Podes usar hasta 12 al mismo tiempo."
          />
          <BlockAdd
            agregar={agregar}
            handleChangeSelect={handleChangeSelect}
            handleAddBlock={handleAddBlock}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
