import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { withStyles } from '@mui/styles';
import { TextField, Chip } from '@mui/material';

const ChipsInputUI_ = (props) => {
  let {
    classes,
    handleChipChange,
    handleChipDelete,
    chip_label = '',
    chipsinput,
  } = props;

  let {
    required,
    disabled,
    error,
    type,
    value,
    label,
    id,
    name,
    messages,
    chips,
    change_param = null,
    placeholder = null,
  } = chipsinput;

  id = id === '' ? name + '_' + uuid().split('-')[0] : id;

  return (
    <TextField
      id={id}
      disabled={disabled}
      classes={{ root: classes.textField }}
      variant="outlined"
      type={type}
      fullWidth
      name={name}
      error={error}
      multiline
      label={required ? label : label + ' (opcional)'}
      value={value}
      onChange={(e) => handleChipChange(e, false, change_param)}
      onBlur={(e) => handleChipChange(e, true, change_param)}
      placeholder={placeholder}
      helperText={
        error
          ? messages.error + ' ' + messages.error_extra
          : messages.help
          ? messages.help
          : ''
      }
      InputLabelProps={{
        shrink: true,
      }}
      FormHelperTextProps={{
        className: classes.helper_text,
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        startAdornment: chips.map((item, index) => (
          <Chip
            key={index}
            tabIndex={-1}
            label={chip_label ? item[chip_label] : item}
            className={classes.chip}
            onDelete={() => handleChipDelete(item, index)}
          />
        )),
      }}
    />
  );
};

const styles = (theme) => ({
  inputRoot: {
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  inputInput: {
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
  },
  helper_text: {
    marginLeft: 0,
  },
  textField: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  input: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },
  chip: {
    margin: `0px 4px ${theme.spacing(2)} 4px`,
  },
});

ChipsInputUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //Function used to update controlled input
  //handleChipChange(e,blur,change_param = null)
  handleChipChange: PropTypes.func.isRequired,

  //Function used to delete one chip
  //handleChipDelete(chip,index)
  handleChipDelete: PropTypes.func.isRequired,

  //object key to be shown
  //Default: ''
  chip_label: PropTypes.string,

  //The object initialized in state
  chipsinput: PropTypes.shape({
    auth: PropTypes.bool.isRequired,
    validate: PropTypes.bool.isRequired, //determina si hay que validarlo en submit
    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    type: PropTypes.oneOf([
      'text',
      'number',
      'password',
      'email',
      'tel',
      'date',
      'datetime-local',
    ]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    default_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    regex: PropTypes.instanceOf(RegExp).isRequired,
    change_param: PropTypes.any, //adds a third parameter to handleChipChange if needed
    placeholder: PropTypes.any,
    chips: PropTypes.array.isRequired,
    messages: PropTypes.shape({
      help: PropTypes.string,
      error: PropTypes.string.isRequired,
      error_extra: PropTypes.string.isRequired,
    }),
  }),
};

export const ChipsInputUI = withStyles(styles)(ChipsInputUI_);
