import React from 'react';

export const LogoProtectPayment = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9 15.3251L16.6 9.6251L15.55 8.6001L10.975 13.1751L8.45 10.6501L7.35 11.7501L10.9 15.3251ZM12 21.9751C9.66667 21.3918 7.75 20.0376 6.25 17.9126C4.75 15.7876 4 13.4584 4 10.9251V4.9751L12 1.9751L20 4.9751V10.9251C20 13.4584 19.25 15.7876 17.75 17.9126C16.25 20.0376 14.3333 21.3918 12 21.9751ZM12 20.4251C13.9167 19.7918 15.4792 18.5959 16.6875 16.8376C17.8958 15.0793 18.5 13.1084 18.5 10.9251V6.0251L12 3.5751L5.5 6.0251V10.9251C5.5 13.1084 6.10417 15.0793 7.3125 16.8376C8.52083 18.5959 10.0833 19.7918 12 20.4251Z"
        fill="#EAA902"
      />
    </svg>
  );
};
