import React from 'react';
import { Grid } from '@mui/material';
import { InputUI, ModuleTitleUI, CardModuleUI } from '../../common';
import { CardTitle, CardText } from '../subcomponents';
import ButtonMui from '../../common/ButtonMui';

export const TurnOffShop = ({
  handleSubmitApagar,
  handleChange,
  input_apagar_temporalmente,
  apagar_temporalmente,
}) => {
  return (
    <form onSubmit={handleSubmitApagar}>
      <Grid container spacing={0.5} justifyContent="end">
        <Grid item xs={12}>
          <ModuleTitleUI title="Importación de productos" />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <CardTitle title="Antes de comenzar apaga tu tienda temporalmente" />
            <Grid item>
              <CardText text="Para evitar problemas en el stock de tus productos actuales y que se realicen operaciones durante el proceso de importación, te recomendamos apagar tu tienda temporalmente." />
            </Grid>
            <InputUI
              handleChange={handleChange}
              input={input_apagar_temporalmente}
            />
          </CardModuleUI>
        </Grid>
        <Grid item xs={12} textAlign="end" mt={1.5}>
          <ButtonMui
            variant="contained"
            type="submit"
            sx={{ width: { xs: '100%', sm: '180px' } }}
            isLoading={apagar_temporalmente}
          >
            Apagar tienda
          </ButtonMui>
        </Grid>
      </Grid>
    </form>
  );
};
