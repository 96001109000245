import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StepContainer = styled('div', {
  shouldForwardProp: (props) =>
    props !== 'stepState' && props !== 'cursorPointer',
})(({ stepState, cursorPointer }) => ({
  margin: '0',
  background: '#FAFAFA',
  color: stepState ? '#53B755' : '#383838',
  padding: '12px',
  transition: 'background 200ms',
  '&:hover': {
    cursor: cursorPointer && 'pointer',
    background: cursorPointer && 'rgba(0, 0, 0, 0.04)',
  },
}));

export const StepContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StepContentTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '19px',
});
