import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../../../components/common';

const ModalArchivarOrden = (props) => {
  let { handleSubmit, modalHandler, modal_state, form_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="archivar_orden"
      title="Archivar orden"
      modalHandler={modalHandler}
      aditional_param="archivar_orden"
      handleSubmit={handleSubmit}
      button_label="Archivar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            ¿Confirmás que deseas archivar la orden? La misma desaparecera por
            defecto de la lista de ordenes y podrá volver a ser visualizada
            utilizando el fitro "Etiqueta de orden".
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalArchivarOrden };
