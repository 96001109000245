import React from 'react';
import { useTheme } from '@emotion/react';
import { AppBar, Box, Button, Typography, useMediaQuery } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router-dom';
import { Config } from '../../../other';

const Header = () => {
  const router = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar
      position="fixed"
      sx={{
        background: '#FFFFFF',
        borderRadius: isMobile ? '0px 0px 20px 20px' : '0px 0px 60px 60px',
        boxShadow: '0px 4px 4px 0px #0000000F',
        padding: { xs: '24px', md: '24px 60px', lg: '24px 108px' },
        paddingBottom: '0px !important',
      }}
    >
      <Box width="98%" m="0 auto" mb={4}>
        <Box
          mb={{ xs: 2, sm: 4 }}
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
        >
          <Button
            fullWidth={false}
            variant="text"
            onClick={() =>
              router.push('/builder', { from: '/selecciona-plantilla' })
            }
            startIcon={<ChevronLeftIcon />}
            sx={{
              fontSize: '14px',
              width: { xs: 'unset', md: '145px' },
              color: '#3A3A3A',
            }}
          >
            {isMobile ? 'Volver' : 'Volver a diseño'}
          </Button>
          <img
            src={`${Config.CLOUDFRONT_CDN_PROD}/landingpage/images/v4/empretienda-green-logo.png`}
            alt="Logo panel"
            style={{
              width: 'auto',
              height: isMobile ? '20px' : '40px',
            }}
          />
        </Box>
        <Typography
          color="primary"
          fontSize={isMobile ? '24px' : '36px'}
          fontWeight="600"
          as="h1"
          mb={2}
          lineHeight="unset"
        >
          Encontrá la plantilla perfecta para tu tienda
        </Typography>
        <Typography color="#3A3A3A" fontSize={isMobile ? '16px' : '24px'}>
          Conocé nuestra variedad de temas y elegí el que mejor se adapte a vos.
        </Typography>
      </Box>
    </AppBar>
  );
};

export default Header;
