import React from 'react';
import axios from 'axios';
import App from './App';
import { Config } from './other';
import * as serviceWorker from './serviceWorker';
import { initialize } from '@braze/web-sdk';
import { createRoot } from 'react-dom/client';

//redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import Reducers from './store/reducers';
import rootReducer from './store/reducers/rootReducer';
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { Auth0Provider } from '@auth0/auth0-react';

//configuracion default de axios
axios.defaults.timeout = 300000; //seteo el timeout de los requests
axios.defaults.withCredentials = true;

const sdkBrowserConfig = {
  core: {
    authorizationKey: Config.SPLIT_IO_BROWSER_API_KEY,
    key: Config.REACT_APP_NAME,
  },
};

const lowEnvironments = Config.ENVIRONMENTS.filter(
  (env) => env !== 'production'
);

const composeEnhancers = lowEnvironments.includes(Config.REACT_APP_ENVIRONMENT)
  ? composeWithDevTools(applyMiddleware(thunk))
  : applyMiddleware(thunk);

const store = createStore(rootReducer, composeEnhancers);

store.dispatch(initSplitSdk({ config: sdkBrowserConfig }));
store.dispatch(getTreatments({ splitNames: Config.FF_SPLITS }));

//chequeo beta data
if (localStorage.getItem('bdata')) {
  store.dispatch({
    type: Reducers.BETA_GUARDAR,
    data: JSON.parse(localStorage.getItem('bdata')),
  });
}

if (!Config.MOCK_ACTIVE) {
  initialize(Config.BRAZE_API_KEY, {
    enableSdkAuthentication: true,
    baseUrl: Config.BRAZE_API_ENDPOINT,
    enableLogging: Config.BRAZE_API_DEV_MODE,
  });
}

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);

serviceWorker.unregister();
