/* eslint-disable default-case */
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import {
  cuponEditar,
  cuponObtenerCupon,
  tiendaObtener,
} from '../../../store/actions';
import { Card, CardContent, Grid } from '@mui/material';
import { Regex, RegexExtra, Validate, Config } from '../../../other';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  ToggleUI,
  NextActionsUI,
  ModuleCardTitleUI,
  AutosuggestUI,
  DateTimeUI,
} from '../../common';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

let init_date = moment().set('second', 0);

class CuponesEditar_ extends Component {
  state = {
    editar: {
      idCuponesDescuento: {
        validate: false,
        value: null,
      },
      cd_codigo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código de descuento',
        id: 'cd_codigo',
        name: 'cd_codigo',
        change_param: 'editar',
        regex: Regex.CUPON_DESCUENTO,
        value: '',
        default_value: '',
        placeholder: 'MICODIGO',
        messages: {
          help: 'Es el código que van a tener que ingresar los clientes para acceder al descuento',
          error: 'Por favor, ingrese un nombre código de descuento válido',
          error_extra: RegexExtra.CUPON_DESCUENTO,
        },
      },
      cd_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de cupón',
        id: 'cd_tipo',
        name: 'cd_tipo',
        change_param: 'editar',
        value: 0,
        messages: {
          error: 'Por favor, seleccione un tipo de cupón',
        },
      },
      cd_porcentaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje de descuento',
        id: 'cd_porcentaje',
        name: 'cd_porcentaje',
        change_param: 'editar',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '25',
        messages: {
          help: 'Es el porcentaje de descuento que se aplicará al ingresar el cupón',
          error: 'Por favor, ingrese un porcentaje de descuento válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO + '[Entre 1 y 99]',
        },
      },
      cd_monto: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto',
        id: 'cd_monto',
        name: 'cd_monto',
        change_param: 'editar',
        regex: Regex.PRECIO_POSITIVO.ARG,
        value: '',
        default_value: '',
        placeholder: '150',
        messages: {
          help: 'Es el monto que le descontará al cliente al ingresar el cupón, tene en cuenta que si el monto del cupón es mayor al de la venta, los productos serán gratis. Puedes utilizar la condición con monto mínimo para evitar esto.',
          error: 'Por favor, ingrese un monto de descuento válido',
          error_extra: RegexExtra.PRECIO_POSITIVO.ARG,
        },
      },
      cd_uso: {
        validate: false,
        required: true,
        error: false,
        label: 'Límites de uso',
        id: 'cd_uso',
        name: 'cd_uso',
        change_param: 'editar',
        value: -1,
        messages: {
          error: 'Por favor, seleccione un límite de uso del cupón',
        },
      },
      cd_uso_cantidad: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Límite',
        id: 'cd_uso_cantidad',
        name: 'cd_uso_cantidad',
        change_param: 'editar',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          help: 'Determina cuantas veces puede ser utilizado el cupón',
          error: 'Por favor, ingrese un límite de uso válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      cd_tipo_cliente: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de cliente',
        id: 'cd_tipo_cliente',
        name: 'cd_tipo_cliente',
        change_param: 'editar',
        value: -1,
        messages: {
          error: 'Por favor, seleccione un tipo de cliente para el cupón',
        },
      },
      cd_duracion: {
        validate: false,
        required: true,
        error: false,
        label: 'Validez del cupón',
        id: 'cd_duracion',
        name: 'cd_duracion',
        change_param: 'editar',
        value: -1,
        messages: {
          error: 'Por favor, seleccione una duración para el cupón',
        },
      },
      cd_duracion_inicio: {
        validate: false,
        momentobj: init_date.clone(),
        label: 'Fecha de inicio',
        name: 'cd_duracion_inicio',
        change_param: 'editar',
      },
      cd_duracion_fin: {
        validate: false,
        momentobj: init_date.clone(),
        label: 'Fecha de fin',
        name: 'cd_duracion_fin',
        change_param: 'editar',
      },
      cd_condicion: {
        validate: false,
        required: true,
        error: false,
        label: 'Condición para uso',
        id: 'cd_condicion',
        name: 'cd_condicion',
        change_param: 'editar',
        value: -1,
        messages: {
          error: 'Por favor, seleccione un condición de uso',
        },
      },
      cd_condicion_monto: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'cd_condicion_monto',
        name: 'cd_condicion_monto',
        change_param: 'editar',
        regex: Regex.PRECIO_POSITIVO.ARG,
        value: '',
        default_value: '',
        placeholder: '2000',
        messages: {
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO_POSITIVO.ARG,
        },
      },
      cd_condicion_cantidad: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Cantidad mínima de productos',
        id: 'cd_condicion_cantidad',
        name: 'cd_condicion_cantidad',
        change_param: 'editar',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '150',
        messages: {
          error: 'Por favor, ingrese una cantidad mínima de productos válida',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      cd_alcance: {
        validate: false,
        required: true,
        error: false,
        label: 'Productos permitidos',
        id: 'cd_alcance',
        name: 'cd_alcance',
        change_param: 'editar',
        value: -1,
        messages: {
          error: 'Por favor, seleccione un alcance de cupón',
        },
      },
      data: {
        validate: false,
        selected: [],
      },
    },
    forms: {
      editar: false,
    },
    loading: {
      tienda: true,
      cupon: true,
      error: false,
    },
    after: false,
    selects: {
      cd_tipo: [
        {
          value: 0,
          label: 'Porcentaje de descuento',
        },
        {
          value: 1,
          label: 'Monto fijo',
        },
        {
          value: 2,
          label: '2x1 (paga unidad de mayor precio)',
        },
        {
          value: 3,
          label: '3x2 (paga unidades de mayor precio)',
        },
        {
          value: 4,
          label: '4x2 (paga unidades de mayor precio)',
        },
        {
          value: 5,
          label: '4x3 (paga unidades de mayor precio)',
        },
        {
          value: 6,
          label: 'Porcentaje de descuento en segunda unidad',
        },
      ],
      cd_uso: [
        {
          value: -1,
          label: 'Sin límite de uso',
        },
        {
          value: 0,
          label: 'Hasta cierta cantidad de uso total',
        },
        {
          value: 1,
          label: 'Hasta cierta cantidad por cliente',
        },
      ],
      cd_tipo_cliente: [
        {
          value: -1,
          label: 'Todos los clientes',
        },
        {
          value: 0,
          label: 'Solo clientes minoristas',
        },
        {
          value: 1,
          label: 'Solo clientes mayoristas',
        },
      ],
      cd_duracion: [
        {
          value: -1,
          label: 'Por tiempo ilimitado',
        },
        {
          value: 0,
          label: 'A partir de cierta fecha',
        },
        {
          value: 1,
          label: 'Hasta cierta fecha',
        },
        {
          value: 2,
          label: 'Con fecha de inicio y fin',
        },
      ],
      cd_condicion: [
        {
          value: -1,
          label: 'Sin condición de uso',
        },
        {
          value: 0,
          label: 'Compras mayores a cierto monto',
        },
        {
          value: 1,
          label: 'Compras mayores a cierta cantidad',
        },
      ],
      cd_alcance: [
        {
          value: -1,
          label: 'Todos los productos',
        },
        {
          value: 0,
          label: 'Determinadas categorías',
        },
        {
          value: 1,
          label: 'Determinados productos',
        },
      ],
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    feed_input_value: '',
    feed: [],
  };

  //autosuggest
  debounceMethod = null;

  handleOnSuggestionSelected = (selected_suggestion) => {
    this.setState((prevState) => ({
      editar: {
        ...prevState.editar,
        data: {
          ...prevState.editar.data,
          selected: [...prevState.editar.data.selected, selected_suggestion],
        },
      },
      feed_input_value: '',
    }));
  };

  canBeOption = (idlabel, id) => {
    return this.state.editar.data.selected.filter((option) => {
      return option[idlabel] === id;
    }).length > 0
      ? false
      : true;
  };

  handleSuggestionFetchData = (value) => {
    const inputValue = Validate.trim_lowercase(value);
    if (inputValue.length > 1) {
      let params =
        this.state.editar.cd_alcance.value === 0
          ? {
              search: inputValue,
            }
          : {
              search: inputValue,
              imagenes: 0,
              destacado: 0,
              orden: 0,
            };

      axios
        .get(
          `${Config.BACKEND_ENDPOINT}/${
            this.state.editar.cd_alcance.value === 1 ? 'producto' : 'categoria'
          }/buscar`,
          {
            params: params,
          }
        )
        .then((resp) => {
          let idlabel =
            this.state.editar.cd_alcance.value === 1
              ? 'idProductos'
              : 'idCategorias';

          //filtro las que ya existen en los chips para evitar duplicados
          let feed = resp.data.data.filter((option) => {
            return this.canBeOption(idlabel, option[idlabel]);
          });

          this.setState({ feed: feed });
        })
        .catch((err) => {
          this.setState((prevState) => ({
            alert: {
              ...prevState.alert,
              open: true,
              message: 'Ha ocurrido un error al buscar, intente nuevamente',
              type: 'error',
            },
          }));
        });
    }
  };

  handleDeleteChip = (deleted_option) => {
    let idlabel =
      this.state.editar.cd_alcance.value === 1 ? 'idProductos' : 'idCategorias';

    let selected = this.state.editar.data.selected.filter((option) => {
      return option[idlabel] !== deleted_option[idlabel];
    });

    this.setState((prevState) => ({
      editar: {
        ...prevState.editar,
        data: {
          ...prevState.editar.data,
          selected: selected,
        },
      },
      feed_input_value: '',
    }));
  };

  handleSuggestionsFetchRequested = ({ value }) => this.debounceMethod(value);

  handleSuggestionsClearRequested = () => this.setState({ feed: [] });

  handleSuggestionsChangeSelected = (e, { newValue }) =>
    this.setState({ feed_input_value: newValue });
  //end autosuggest

  componentDidMount() {
    this.debounceMethod = debounce(this.handleSuggestionFetchData, 500);
    let idCuponesDescuento = Validate.trim_int(
      this.props.match.params.idCuponesDescuento
    );

    if (idCuponesDescuento > 0) {
      this.props.tiendaObtener((err, resp) => {
        const idCountry =
          this.props.tienda && this.props.tienda.Country_idCountry
            ? this.props.tienda.Country_idCountry
            : 'ARG';

        this.setState((prevState) => ({
          editar: {
            ...prevState.editar,
            cd_monto: {
              ...prevState.editar.cd_monto,
              regex: Regex.PRECIO_POSITIVO[idCountry || 'ARG'],
              messages: {
                ...prevState.editar.cd_monto.messages,
                error_extra: RegexExtra.PRECIO_POSITIVO[idCountry || 'ARG'],
              },
            },
            cd_condicion_monto: {
              ...prevState.editar.cd_condicion_monto,
              regex: Regex.PRECIO_POSITIVO[idCountry || 'ARG'],
              messages: {
                ...prevState.editar.cd_condicion_monto.messages,
                error_extra: RegexExtra.PRECIO_POSITIVO[idCountry || 'ARG'],
              },
            },
          },
          loading: {
            ...prevState.loading,
            tienda: false,
            error: err ? true : false,
          },
        }));
      });
      this.props.cuponObtenerCupon(
        { idCuponesDescuento: idCuponesDescuento },
        (err, resp) => {
          if (err) {
            this.setState((prevState) => ({
              loading: {
                ...prevState.loading,
                cupon: false,
                error: true,
              },
            }));
          } else {
            let cupon = resp.data;
            let editar = this.state.editar;
            let enable_arr = [],
              disable_arr = [],
              disable_disable_arr = [];

            cupon.cd_porcentaje =
              cupon.cd_porcentaje === 0 ? '' : cupon.cd_porcentaje;
            cupon.cd_monto =
              Validate.trim_float(cupon.cd_monto) === 0 ? '' : cupon.cd_monto;
            cupon.cd_uso_cantidad =
              cupon.cd_uso_cantidad === 0 ? '' : cupon.cd_uso_cantidad;
            cupon.cd_condicion_monto =
              Validate.trim_float(cupon.cd_condicion_monto) === 0
                ? ''
                : cupon.cd_condicion_monto;
            cupon.cd_condicion_cantidad =
              cupon.cd_condicion_cantidad === 0
                ? ''
                : cupon.cd_condicion_cantidad;
            let cd_duracion_inicio =
              cupon.cd_duracion_inicio === null
                ? init_date.clone()
                : Validate.utc2local(cupon.cd_duracion_inicio).clone();
            let cd_duracion_fin =
              cupon.cd_duracion_fin === null
                ? init_date.clone()
                : Validate.utc2local(cupon.cd_duracion_fin).clone();

            //reviso el tipo de cupon
            switch (cupon.cd_tipo) {
              //porcentaje
              case 0:
                enable_arr = ['cd_porcentaje'];
                disable_arr = ['cd_monto'];
                break;
              //monto fijo
              case 1:
                enable_arr = ['cd_monto'];
                disable_arr = ['cd_porcentaje'];
                break;
              //x% off en segunda unidad
              case 6:
                enable_arr = ['cd_porcentaje'];
                disable_arr = ['cd_monto'];
                break;
              //2x1,3x2,4x2,4x3
              default:
                disable_arr = ['cd_porcentaje', 'cd_monto'];
                break;
            }

            //reviso el uso
            if (cupon.cd_uso === -1) {
              //ilimitado
              disable_arr = [...disable_arr, 'cd_uso_cantidad'];
            } else {
              //cierta cantidad total o cantidad por cliente
              enable_arr = [...enable_arr, 'cd_uso_cantidad'];
            }

            //reviso la condicion
            switch (cupon.cd_condicion) {
              //sin condicion
              case -1:
                disable_arr = [
                  ...disable_arr,
                  'cd_condicion_monto',
                  'cd_condicion_cantidad',
                ];
                break;
              //compras mayores a cierto monto
              case 0:
                enable_arr = [...enable_arr, 'cd_condicion_monto'];
                disable_arr = [...disable_arr, 'cd_condicion_cantidad'];
                break;
              //compras con x cantidad de productos
              case 1:
                enable_arr = [...enable_arr, 'cd_condicion_cantidad'];
                disable_arr = [...disable_arr, 'cd_condicion_monto'];
                break;
            }

            //cargo las categorias/productos si aplica
            let selected = [];
            if (Validate.in_array(cupon.cd_alcance, [0, 1])) {
              selected =
                cupon.cd_alcance === 1
                  ? cupon.productos_cupon
                  : cupon.categorias_cupon;
            }

            //habilito y deshabilito los campos necesarios
            editar = {
              ...editar,
              data: {
                validate: false,
                selected: selected,
              },
              cd_duracion_inicio: {
                ...editar.cd_duracion_inicio,
                momentobj: cd_duracion_inicio,
              },
              cd_duracion_fin: {
                ...editar.cd_duracion_fin,
                momentobj: cd_duracion_fin,
              },
            };
            editar = Validate.bulk_enable_disable_input(
              editar,
              enable_arr,
              disable_arr,
              disable_disable_arr,
              true
            );
            editar = Validate.cargar_formulario_sin_nulos_with_excluded_sync(
              cupon,
              editar,
              [
                'categorias_cupon',
                'productos_cupon',
                'cd_duracion_inicio',
                'cd_duracion_fin',
              ]
            );

            this.setState((prevState) => ({
              loading: {
                ...prevState.loading,
                cupon: false,
              },
              editar: editar,
            }));
          }
        }
      );
    } else {
      this.setState((prevState) => ({
        loading: {
          ...prevState.loading,
          error: true,
        },
      }));
    }
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;
    let enable_arr = [],
      disable_arr = [],
      disable_disable_arr = [];
    let editar = this.state.editar;

    switch (name) {
      case 'cd_tipo':
        switch (value) {
          //porcentaje
          case 0:
            enable_arr = ['cd_porcentaje'];
            disable_arr = ['cd_monto'];
            break;
          //monto fijo
          case 1:
            enable_arr = ['cd_monto'];
            disable_arr = ['cd_porcentaje'];
            break;
          //x% off en segunda unidad
          case 6:
            enable_arr = ['cd_porcentaje'];
            disable_arr = ['cd_monto'];
            break;
          //2x1,3x2,4x2,4x3
          default:
            enable_arr = [];
            disable_arr = ['cd_porcentaje', 'cd_monto'];
            break;
        }
        editar = Validate.bulk_enable_disable_input(
          editar,
          enable_arr,
          disable_arr,
          disable_disable_arr,
          true
        );
        this.setState((prevState) => ({
          editar: {
            ...editar,
            cd_tipo: {
              ...prevState.editar.cd_tipo,
              value: value,
            },
          },
        }));
        break;
      case 'cd_alcance':
        this.setState((prevState) => ({
          editar: {
            ...editar,
            cd_alcance: {
              ...prevState.editar.cd_alcance,
              value: value,
            },
            data: {
              validate: false,
              selected: [],
            },
          },
          feed_input_value: '',
          feed: [],
        }));
        break;
      case 'cd_uso':
        if (value === -1) {
          //ilimitado
          enable_arr = [];
          disable_arr = ['cd_uso_cantidad'];
        } else {
          //cierta cantidad total o cantidad por cliente
          enable_arr = ['cd_uso_cantidad'];
          disable_arr = [];
        }
        editar = Validate.bulk_enable_disable_input(
          editar,
          enable_arr,
          disable_arr,
          disable_disable_arr,
          true
        );
        this.setState((prevState) => ({
          editar: {
            ...editar,
            cd_uso: {
              ...prevState.editar.cd_uso,
              value: value,
            },
          },
        }));
        break;
      case 'cd_tipo_cliente':
        this.setState((prevState) => ({
          editar: {
            ...editar,
            cd_tipo_cliente: {
              ...prevState.editar.cd_tipo_cliente,
              value: value,
            },
          },
        }));
        break;
      case 'cd_condicion':
        switch (value) {
          //sin condicion
          case -1:
            enable_arr = [];
            disable_arr = ['cd_condicion_monto', 'cd_condicion_cantidad'];
            break;
          //compras mayores a cierto monto
          case 0:
            enable_arr = ['cd_condicion_monto'];
            disable_arr = ['cd_condicion_cantidad'];
            break;
          //compras con x cantidad de productos
          case 1:
            enable_arr = ['cd_condicion_cantidad'];
            disable_arr = ['cd_condicion_monto'];
            break;
        }
        editar = Validate.bulk_enable_disable_input(
          editar,
          enable_arr,
          disable_arr,
          disable_disable_arr,
          true
        );
        this.setState((prevState) => ({
          editar: {
            ...editar,
            cd_condicion: {
              ...prevState.editar.cd_condicion,
              value: value,
            },
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
          },
        }));
        break;
    }
  };

  handleChangeDate = (momentobj, name, aditional) => {
    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          momentobj: momentobj,
        },
      },
    }));
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        editar: true,
      },
    }));

    let form = this.state.editar;

    if (Validate.validar_formulario(form)) {
      //chequeo (si aplica) el % de dcto
      if (
        Validate.in_array(form.cd_tipo.value, [1, 2, 3, 4, 5]) ||
        (Validate.in_array(form.cd_tipo.value, [0, 6]) &&
          Validate.validate_range(form.cd_porcentaje.value, 1, 99))
      ) {
        //chequeo (si aplica) que la fecha de inicio < fecha de fin
        let cd_duracion_inicio = form.cd_duracion_inicio.momentobj.clone();
        let cd_duracion_fin = form.cd_duracion_fin.momentobj.clone();
        if (
          form.cd_duracion.value <= 1 ||
          (form.cd_duracion.value === 2 &&
            Validate.moment_comparar(cd_duracion_inicio, cd_duracion_fin))
        ) {
          if (
            form.cd_alcance.value === -1 ||
            (form.cd_alcance.value >= 0 && form.data.selected.length > 0)
          ) {
            let data = [];

            if (form.cd_alcance.value >= 0) {
              let idlabel =
                form.cd_alcance.value === 1 ? 'idProductos' : 'idCategorias';
              form.data.selected.forEach((item) => {
                data.push(item[idlabel]);
              });
            }

            this.props.cuponEditar(
              {
                idCuponesDescuento: form.idCuponesDescuento.value,
                cd_codigo: form.cd_codigo.value,
                cd_tipo: form.cd_tipo.value,
                cd_porcentaje: form.cd_porcentaje.value,
                cd_monto: form.cd_monto.value,
                cd_alcance: form.cd_alcance.value,
                cd_uso: form.cd_uso.value,
                cd_uso_cantidad: form.cd_uso_cantidad.value,
                cd_tipo_cliente: form.cd_tipo_cliente.value,
                cd_duracion: form.cd_duracion.value,
                cd_duracion_inicio:
                  Validate.moment_local2utc_format(cd_duracion_inicio),
                cd_duracion_fin:
                  Validate.moment_local2utc_format(cd_duracion_fin),
                cd_condicion: form.cd_condicion.value,
                cd_condicion_monto: form.cd_condicion_monto.value,
                cd_condicion_cantidad: form.cd_condicion_cantidad.value,
                data: data,
              },
              (err, resp) => {
                this.setState((prevState) => ({
                  forms: {
                    ...prevState.forms,
                    editar: false,
                  },
                  alert: {
                    ...prevState.alert,
                    open: true,
                    message: resp.message,
                    type: err ? 'error' : 'success',
                  },
                  after: err ? false : true,
                }));
              }
            );
          } else {
            this.setState((prevState) => ({
              forms: {
                ...prevState.forms,
                editar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message:
                  'Debes elegir al menos ' +
                  (form.cd_alcance.value === 1
                    ? 'un producto'
                    : 'una categoría'),
                type: 'error',
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              editar: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message:
                'La fecha de fin del cupón debe ser posterior a la fecha de inicio',
              type: 'error',
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            editar: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message: 'El porcentaje de descuento debe estar entre 1 y 99',
            type: 'error',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            editar: false,
          },
          editar: new_form,
          alert: {
            ...prevState.alert,
            open: true,
            message: 'Tienes algunos errores, por favor revisa los datos',
            type: 'error',
          },
        }));
      });
    }
  };

  render() {
    let { tienda, classes } = this.props;
    const idCountry = tienda ? tienda.Country_idCountry : 'ARG';

    return (
      <WrapperLayout
        loading={this.state.loading.tienda || this.state.loading.cupon}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ToggleUI show={!this.state.after}>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <ModuleTitleUI title="Editar cupón" />
                <Card className={classes.card}>
                  <CardContent className={classes.card_content}>
                    <Grid container>
                      <Grid item xs={12} mb={1.5}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.editar.cd_codigo}
                        />
                      </Grid>
                      <Grid item xs={12} my={1.5}>
                        <SelectUI
                          data={this.state.editar.cd_tipo}
                          options={this.state.selects.cd_tipo}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={Validate.in_array(
                          this.state.editar.cd_tipo.value,
                          [0, 6]
                        )}
                      >
                        <Grid item xs={12} my={1.5}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.editar.cd_porcentaje}
                            end_adornment="%"
                            step="1"
                          />
                        </Grid>
                      </ToggleUI>
                      <ToggleUI show={this.state.editar.cd_tipo.value === 1}>
                        <Grid item xs={12} my={1.5}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.editar.cd_monto}
                            adornment={
                              Config.CURRENCY[idCountry || 'ARG'].adornment
                            }
                            step={
                              Config.CURRENCY[idCountry || 'ARG'].formatNumber
                            }
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12} my={1.5}>
                        <SelectUI
                          data={this.state.editar.cd_duracion}
                          options={this.state.selects.cd_duracion}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={Validate.in_array(
                          this.state.editar.cd_duracion.value,
                          [0, 2]
                        )}
                      >
                        <Grid
                          item
                          xs={
                            this.state.editar.cd_duracion.value === 2 ? 6 : 12
                          }
                          my={1.5}
                          pr={
                            this.state.editar.cd_duracion.value === 2 ? 1.5 : 0
                          }
                        >
                          <DateTimeUI
                            handleChangeDate={this.handleChangeDate}
                            inputdate={this.state.editar.cd_duracion_inicio}
                          />
                        </Grid>
                      </ToggleUI>
                      <ToggleUI
                        show={Validate.in_array(
                          this.state.editar.cd_duracion.value,
                          [1, 2]
                        )}
                      >
                        <Grid
                          item
                          xs={
                            this.state.editar.cd_duracion.value === 2 ? 6 : 12
                          }
                          my={1.5}
                          pl={
                            this.state.editar.cd_duracion.value === 2 ? 1.5 : 0
                          }
                        >
                          <DateTimeUI
                            handleChangeDate={this.handleChangeDate}
                            inputdate={this.state.editar.cd_duracion_fin}
                          />
                        </Grid>
                      </ToggleUI>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className={classes.card_below}>
                  <CardContent className={classes.card_content}>
                    <Grid container>
                      <Grid item mb={1.5}>
                        <ModuleCardTitleUI
                          title="Condiciones de usabilidad"
                          subtitles={[
                            'Acá vas a poder determinar que condiciones necesita reunir el cliente para poder utilizar el cupón',
                          ]}
                        />
                      </Grid>
                      <ToggleUI show={tienda.t_mayorista === 1}>
                        <Grid item xs={12} my={1.5}>
                          <SelectUI
                            data={this.state.editar.cd_tipo_cliente}
                            options={this.state.selects.cd_tipo_cliente}
                            handleChangeSelect={this.handleChangeSelect}
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12} mb={1.5}>
                        <SelectUI
                          data={this.state.editar.cd_condicion}
                          options={this.state.selects.cd_condicion}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={this.state.editar.cd_condicion.value === 0}
                      >
                        <Grid item xs={12} my={1.5}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.editar.cd_condicion_monto}
                            adornment={
                              Config.CURRENCY[idCountry || 'ARG'].adornment
                            }
                            step={
                              Config.CURRENCY[idCountry || 'ARG'].formatNumber
                            }
                          />
                        </Grid>
                      </ToggleUI>
                      <ToggleUI
                        show={this.state.editar.cd_condicion.value === 1}
                      >
                        <Grid item xs={12} my={1.5}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.editar.cd_condicion_cantidad}
                            step="1"
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12} my={1.5}>
                        <SelectUI
                          data={this.state.editar.cd_uso}
                          options={this.state.selects.cd_uso}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI show={this.state.editar.cd_uso.value >= 0}>
                        <Grid item xs={12} my={1.5}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.editar.cd_uso_cantidad}
                            step="1"
                          />
                        </Grid>
                      </ToggleUI>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className={classes.card_below}>
                  <CardContent className={classes.card_content}>
                    <Grid container>
                      <Grid item mb={1.5}>
                        <ModuleCardTitleUI
                          title="Productos o categorías permitidas"
                          subtitles={[
                            'Acá vas a poder determinar que productos podrán utilizar el cupón de descuento',
                            'Si elegís una categoría y la categoría posee subcategorías, las subcategorías podrán utilizar el cupón. Asi como también los productos que posean categorías secundarias.',
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} my={1.5}>
                        <SelectUI
                          data={this.state.editar.cd_alcance}
                          options={this.state.selects.cd_alcance}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={Validate.in_array(
                          this.state.editar.cd_alcance.value,
                          [0, 1]
                        )}
                      >
                        <Grid item xs={12} my={1.5}>
                          <AutosuggestUI
                            suggestions={this.state.feed}
                            input_value={this.state.feed_input_value}
                            suggestion_selected={
                              this.state.editar.data.selected
                            }
                            multiple
                            suggestion_index={
                              this.state.editar.cd_alcance.value === 1
                                ? 'p_nombre'
                                : 'c_nombre'
                            }
                            label={
                              this.state.editar.cd_alcance.value === 1
                                ? 'Productos aceptados'
                                : 'Categorías aceptadas'
                            }
                            placeholder={
                              this.state.editar.cd_alcance.value === 1
                                ? 'Ej: Mochila ecológica'
                                : 'Ej: Mochilas'
                            }
                            handleOnSuggestionSelected={
                              this.handleOnSuggestionSelected
                            }
                            handleSuggestionsFetchRequested={
                              this.handleSuggestionsFetchRequested
                            }
                            handleSuggestionsClearRequested={
                              this.handleSuggestionsClearRequested
                            }
                            handleSuggestionsChangeSelected={
                              this.handleSuggestionsChangeSelected
                            }
                            handleDeleteChip={this.handleDeleteChip}
                          />
                        </Grid>
                      </ToggleUI>
                    </Grid>
                  </CardContent>
                </Card>
                <ModuleButtonBoxUI>
                  <ButtonUI
                    type="submit"
                    label="Editar"
                    minHeight={true}
                    isLoading={this.state.forms.editar}
                  />
                </ModuleButtonBoxUI>
              </Grid>
            </Grid>
          </form>
        </ToggleUI>
        <ToggleUI show={this.state.after}>
          <NextActionsUI
            title="¡El cupón ha sido editado con éxito!"
            buttons={[
              <ButtonUI type="link" label="Ver mis cupones" link="/cupones" />,
            ]}
          />
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    cuponEditar: (data, callback) => dispatch(cuponEditar(data, callback)),
    cuponObtenerCupon: (data, callback) =>
      dispatch(cuponObtenerCupon(data, callback)),
  };
};

export const CuponesEditar = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CuponesEditar_)
);
