import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import {
  ventaArchivarVenta,
  ventaObtenerVentas,
  getSalesToExport,
} from '../../store/actions';
import { connect, useSelector } from 'react-redux';
import {
  createAmplitudeEventWithDevice,
  sales_amplitude_events,
} from '../../components/amplitude';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { AlertUI, InputUI, ToggleUI } from '../../components/common';
import { ModalArchivar } from './subcomponents/ModalArchivar';
import {
  Box,
  Button,
  Grid,
  Icon,
  LinearProgress,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { VentasList } from './subcomponents/VentasList';
import ButtonMui from '../../components/common/ButtonMui';
import { salesListSelects } from './subcomponents/salesListSelects';
import { FiltersDrawer } from './subcomponents/FiltersDrawer';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getSalesColumns,
  getSalesColumnsData,
} from './subcomponents/getSalesColumns';
import { exportToExcel } from '../../libs/exportToExcel';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SalesListPage_ = ({
  getSalesDispatch,
  exportSalesDispatch,
  archiveOrderDispatch,
  tienda,
}) => {
  const initialDate = moment().set('second', 0);
  const location = useLocation();
  const router = useHistory();
  const query = useQuery();
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';
  const [filtersState, setFiltersState] = useState({
    v_estado_pago: {
      validate: false,
      required: true,
      error: false,
      label: 'Estado de pago',
      id: 'v_estado_pago',
      name: 'v_estado_pago',
      change_param: 'filter',
      value: query.get('v_estado_pago')
        ? Number(query.get('v_estado_pago'))
        : -2,
      messages: {
        error: '',
      },
    },
    v_metodo_pago: {
      validate: false,
      required: true,
      error: false,
      label: 'Método de pago',
      id: 'v_metodo_pago',
      name: 'v_metodo_pago',
      change_param: 'filter',
      value: query.get('v_metodo_pago')
        ? Number(query.get('v_metodo_pago'))
        : 0,
      messages: {
        error: '',
      },
    },
    v_metodo_envio: {
      validate: false,
      required: true,
      error: false,
      label: 'Método de envío',
      id: 'v_metodo_envio',
      name: 'v_metodo_envio',
      change_param: 'filter',
      value: query.get('v_metodo_envio')
        ? Number(query.get('v_metodo_envio'))
        : 0,
      messages: {
        error: '',
      },
    },
    v_estado_envio: {
      validate: false,
      required: true,
      error: false,
      label: 'Estado del envío',
      id: 'v_estado_envio',
      name: 'v_estado_envio',
      change_param: 'filter',
      value: query.get('v_estado_envio')
        ? Number(query.get('v_estado_envio'))
        : -1,
      messages: {
        error: '',
      },
    },
    v_archivada: {
      validate: false,
      required: true,
      error: false,
      label: 'Etiqueta de orden',
      id: 'v_archivada',
      name: 'v_archivada',
      change_param: 'filter',
      value: query.get('v_archivada') ? Number(query.get('v_archivada')) : 0,
      messages: {
        error: '',
      },
    },
    v_mayorista: {
      validate: false,
      required: true,
      error: false,
      label: 'Tipo de venta',
      id: 'v_mayorista',
      name: 'v_mayorista',
      change_param: 'filter',
      value: query.get('v_mayorista') ? Number(query.get('v_mayorista')) : -1,
      messages: {
        error: '',
      },
    },
    v_factura: {
      validate: false,
      required: true,
      error: false,
      label: 'Facturada',
      id: 'v_factura',
      name: 'v_factura',
      change_param: 'filter',
      value: query.get('v_factura') ? Number(query.get('v_factura')) : -1,
      messages: {
        error: '',
      },
    },
    v_filtrar_fecha: {
      validate: false,
      required: true,
      error: false,
      label: 'Filtrar por fecha',
      id: 'v_filtrar_fecha',
      name: 'v_filtrar_fecha',
      change_param: 'filter',
      value: query.get('v_filtrar_fecha')
        ? Number(query.get('v_filtrar_fecha'))
        : 0,
      messages: {
        error: '',
      },
    },
    fromDate: {
      validate: false,
      momentobj: query.get('desde')
        ? moment.utc(query.get('desde')).local()
        : initialDate.clone().subtract(1, 'days'),
      label: 'Desde',
      name: 'fromDate',
      change_param: 'selectDates',
    },
    untilDate: {
      validate: false,
      momentobj: query.get('hasta')
        ? moment.utc(query.get('hasta')).local()
        : initialDate.clone(),
      label: 'Hasta',
      name: 'untilDate',
      change_param: 'selectDates',
    },
  });
  const [searchInput, setSearchInput] = useState({
    auth: false,
    validate: false,
    required: true,
    disabled: false,
    error: false,
    type: 'number',
    label: 'Buscar por número',
    id: 'search',
    name: 'search',
    change_param: 'filter',
    regex: Regex.NUMERO_ENTERO_POSITIVO,
    value: '',
    default_value: '',
    placeholder: '18',
    messages: {
      error: '',
      error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
    },
  });
  const [isArchiveOrderModalOpen, setIsArchiveOrderModalOpen] = useState(false);
  const [orderNumberToArchive, setOrderNumberToArchive] = useState(null);
  const [isArchiveOrderLoading, setIsArchiveOrderLoading] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [loadNextPage, setLoadNextPage] = useState(false);

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('filterSales');

  const [exportData, setExportData] = useState([]);

  const [isSalesLoading, setIsSalesLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [errorFetching, setErrorFetching] = useState(false);

  const getParams = (operationType, pageNumAux) => {
    //page: -1 -> Get all pages
    //page: 0 -> Get first page
    //with_customer: 1 -> Gets response with added clientes object that contains more info for excel file
    let params =
      operationType === 'exportExcel'
        ? { page: -1, with_customer: 1 }
        : { page: pageNumAux };

    Object.keys(filtersState).forEach((key) => {
      if (!['fromDate', 'untilDate'].includes(filtersState[key].name)) {
        params[key] = filtersState[key].value;
      }
    });

    if (
      filtersState.v_filtrar_fecha.value === 1 ||
      (query.get('desde') && query.get('hasta'))
    ) {
      params.desde = Validate.moment_local2utc_format(
        filtersState.fromDate.momentobj.clone()
      );
      params.hasta = Validate.moment_local2utc_format(
        filtersState.untilDate.momentobj.clone().set('second', 59)
      );
    }
    return params;
  };

  const getInitialParams = () => {
    const params = {
      page: 0,
      v_estado_pago: -2,
      v_metodo_pago: 0,
      v_estado_envio: -1,
      v_metodo_envio: 0,
      v_mayorista: -1,
      v_factura: -1,
      v_archivada: 0,
    };
    return params;
  };

  const handleSalesFetch = (queryParams) => {
    getSalesDispatch(queryParams, (err, resp) => {
      const salesLength = resp.data.length;
      setIsSalesLoading(false);
      setErrorFetching(err);
      setShowLoadMoreButton(salesLength === 15);
      setLoadNextPage(false);
    });
  };

  const getSales = () => {
    setIsSalesLoading(true);
    let pageNumAux = 0;
    setPageNumber(0);
    const params = router.location.search
      ? getParams('filterSales', pageNumAux)
      : getInitialParams();
    handleSalesFetch(params);
  };

  const exportSales = () => {
    setExportData([]);

    const params =
      searchInput.value !== ''
        ? { page: 0, v_numero: searchInput.value, with_customer: 1 }
        : getParams('exportExcel');

    exportSalesDispatch(params, (err, resp) => {
      if (err) {
        setAlert({ open: true, message: resp.message, type: 'error' });
      } else {
        setExportData(resp.data);
        if (resp.data.length === 0) {
          setAlert({
            open: true,
            message:
              'No se han encontrado resultados para los filtros seleccionados',
            type: 'error',
          });
        } else {
          let date = Validate.now_format(Config.SHORTDATE_FORMAT_DASH);
          const columns = getSalesColumns(idCountry);
          const columnsData = getSalesColumnsData(resp.data, idCountry);
          const config = {
            filename: `Exportacion-ventas-${date}`,
            sheetName: 'Listado de ventas',
            columns: columns,
          };
          exportToExcel(columnsData, config);
        }
      }
    });
  };

  const getNextPageSales = () => {
    setIsSalesLoading(true);
    let pageNumAux = pageNumber + 1;
    setPageNumber((prevState) => prevState + 1);
    setLoadNextPage(true);
    const bodyParams = getParams('filterSales', pageNumAux);
    handleSalesFetch(bodyParams);
  };

  const handleSelectedDatesValidation = (fromDate, untilDate) => {
    if (filtersState.v_filtrar_fecha.value === 1) {
      const datesDifference = moment.duration(
        untilDate.momentobj.diff(fromDate.momentobj)
      );
      const fromDateMomentObjFormatted = Validate.moment_local2utc_format(
        fromDate.momentobj.clone()
      );
      const untilDateMomentObjFormatted = Validate.moment_local2utc_format(
        untilDate.momentobj.clone()
      );

      if (fromDateMomentObjFormatted > untilDateMomentObjFormatted) {
        setAlert({
          open: true,
          type: 'error',
          message: 'La fecha desde no puede ser mayor a la fecha hasta',
        });
        return false;
      } else if (
        datesDifference.years() < 1 &&
        datesDifference.months() < 1 &&
        datesDifference.days() < 1 &&
        datesDifference.hours() < 1 &&
        datesDifference.minutes() >= 0 &&
        datesDifference.seconds() >= 0
      ) {
        setAlert({
          open: true,
          type: 'error',
          message: 'Seleccione al menos una hora de diferencia',
        });
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const handleSubmitFilter = (operationType, fromDate, untilDate) => {
    const validSelectedDates = handleSelectedDatesValidation(
      fromDate,
      untilDate
    );
    if (!validSelectedDates) {
      setIsFiltersDrawerOpen(false);
      return;
    }
    if (operationType === 'filterSales') {
      setSearchInput((prevState) => ({ ...prevState, value: '' }));
      const params = new URLSearchParams();
      const filterKeys = Object.keys(filtersState);
      filterKeys.forEach((key) => {
        if (key === 'fromDate' || key === 'untilDate') {
          if (filtersState.v_filtrar_fecha.value === 1) {
            const date =
              key === 'fromDate'
                ? filtersState.fromDate.momentobj.clone()
                : filtersState.untilDate.momentobj.clone().set('second', 59);
            params.append(
              key === 'fromDate' ? 'desde' : 'hasta',
              Validate.moment_local2utc_format(date)
            );
          }
        } else {
          params.append(key, filtersState[key].value);
        }
      });
      router.push({ search: params.toString() });
      createAmplitudeEventWithDevice(
        sales_amplitude_events.ventas_selecciona_aplicar_filtros.event_name
      );
      getSales();
    } else {
      exportSales();
    }
    setIsFiltersDrawerOpen(false);
  };

  const handleChangeSearchInput = (e, blur) => {
    if (!blur) {
      const value = e.target.value;
      let params;
      if (value) {
        params = {
          page: 0,
          v_numero: value,
        };
      } else {
        params = {
          page: 0,
          v_archivada: 0,
        };
      }
      setSearchInput((prevState) => ({
        ...prevState,
        value: value,
      }));
      removeQueryParams();
      setPageNumber(0);
      setIsSalesLoading(true);
      handleSalesFetch(params);
    }
  };

  const handleChangeSelect = (e) => {
    const value = Validate.trim_int(e.target.value);
    const name = e.target.name;

    setFiltersState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
      },
    }));
  };

  const removeQueryParams = () => {
    router.replace({ pathname: location.pathname });
  };

  const cleanFilters = () => {
    setFiltersState((prevState) => ({
      ...prevState,
      v_estado_pago: {
        ...prevState.v_estado_pago,
        value: -2,
      },
      v_metodo_pago: {
        ...prevState.v_metodo_pago,
        value: 0,
      },
      v_metodo_envio: {
        ...prevState.v_metodo_envio,
        value: 0,
      },
      v_estado_envio: {
        ...prevState.v_estado_envio,
        value: -1,
      },
      v_archivada: {
        ...prevState.v_archivada,
        value: 0,
      },
      v_mayorista: {
        ...prevState.v_mayorista,
        value: -1,
      },
      v_factura: {
        ...prevState.v_factura,
        value: -1,
      },
      v_filtrar_fecha: {
        ...prevState.v_filtrar_fecha,
        value: 0,
      },
      fromDate: {
        ...prevState.fromDate,
        momentobj: initialDate.clone().subtract(1, 'days'),
      },
      untilDate: {
        ...prevState.untilDate,
        momentobj: initialDate.clone(),
      },
    }));
    setSearchInput((prevState) => ({ ...prevState, value: '' }));
    setPageNumber(0);
    removeQueryParams();
    getSales();
  };

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleDrawerOpen = (open, drawerType) => {
    setIsFiltersDrawerOpen(open);
    setExportData(isFiltersDrawerOpen === false && []);
    setDrawerType(drawerType);
    if (open && drawerType === 'filterSales') {
      createAmplitudeEventWithDevice(
        sales_amplitude_events.ventas_filtrar.event_name
      );
    }
  };

  const modalHandler = (open, id, venta) => {
    if (id === 'archivar_orden' && open) {
      setOrderNumberToArchive(venta.v_numero);
      setIsArchiveOrderModalOpen(open);
    } else {
      setIsArchiveOrderModalOpen(open);
    }
  };

  const handleSubmitArchiveOrder = (e) => {
    e.preventDefault();
    setIsArchiveOrderLoading(true);
    archiveOrderDispatch(
      {
        v_numero: orderNumberToArchive,
      },
      (err, resp) => {
        if (err) {
          setAlert({
            open: true,
            message: resp.message,
            type: 'error',
          });
          setIsArchiveOrderLoading(false);
        } else {
          setAlert({
            open: true,
            message: resp.message,
            type: 'success',
          });
          setIsArchiveOrderModalOpen(false);
          setIsArchiveOrderLoading(false);

          createAmplitudeEventWithDevice(
            sales_amplitude_events.ventas_archivar_orden.event_name,
            { source: 'listado_ordenes' }
          );
        }
      }
    );
  };

  const handleChangeDate = (value, name) => {
    setFiltersState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        momentobj: value,
      },
    }));
  };

  useEffect(() => {
    if (searchInput.value === '') getSales();
    if (!router.location.search) {
      cleanFilters();
      if (localStorage.getItem('filter_query_params')) {
        localStorage.removeItem('filter_query_params');
      }
    }
  }, [location.search]);

  return (
    <WrapperLayout error={errorFetching}>
      <AlertUI
        open={alert.open}
        type={alert.type}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <ModalArchivar
        orderNumberToArchive={orderNumberToArchive}
        handleSubmit={handleSubmitArchiveOrder}
        modalHandler={modalHandler}
        isArchiveOrderModalOpen={isArchiveOrderModalOpen}
        isArchiveOrderLoading={isArchiveOrderLoading}
      />
      <FiltersDrawer
        filtersState={filtersState}
        selects={salesListSelects}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleChangeSelect={handleChangeSelect}
        cleanFilters={cleanFilters}
        handleChangeDate={handleChangeDate}
        handleSubmitFilter={handleSubmitFilter}
        drawerType={drawerType}
        exportData={exportData}
        idCountry={tienda.Country_idCountry}
      />
      <Typography
        lineHeight={1.5}
        as="h1"
        fontSize="1.75rem"
        color="#3A3A3A"
        mb={1.5}
        data-testid={`titulo`}
      >
        Listado de ventas
      </Typography>
      <Typography
        fontSize="0.875rem"
        color="#3A3A3A"
        lineHeight="1rem"
        mb={2}
        data-testid={`subtitulo`}
      >
        Para ver la información completa de una orden en particular, realizar
        acciones o enviarle notificaciones a un cliente, hacé click en el número
        de orden.
      </Typography>
      <Box mb={2}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item lg={5} md={6} xs={12} sx={{ paddingBottom: '4px' }}>
            <InputUI
              handleChange={handleChangeSearchInput}
              input={searchInput}
              adornment="#"
              end_adornment={<Icon>search</Icon>}
            />
          </Grid>
          <Grid item lg={2} md={3} xs={6}>
            <Button
              onClick={() => handleDrawerOpen(true, 'exportExcel')}
              variant="outlined"
              color="info"
              sx={{ height: '56px', borderColor: 'rgba(0,0,0,0.23)' }}
              size="large"
              startIcon={<DownloadIcon />}
              fullWidth
              data-testid={`boton-exportar`}
            >
              Exportar
            </Button>
          </Grid>
          <Grid item lg={2} md={3} xs={6}>
            <Button
              onClick={() => handleDrawerOpen(true, 'filterSales')}
              variant="outlined"
              color="info"
              sx={{ height: '56px', borderColor: 'rgba(0,0,0,0.23)' }}
              size="large"
              startIcon={<FilterListIcon />}
              fullWidth
              data-testid={`boton-filtrar`}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mb={3.5}>
        {pageNumber === 0 && isSalesLoading && !loadNextPage ? (
          <LinearProgress />
        ) : (
          <>
            <VentasList modalHandler={modalHandler} />
            <ToggleUI show={showLoadMoreButton}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <ButtonMui
                    disabled={isSalesLoading}
                    onClick={() => getNextPageSales()}
                    isLoading={isSalesLoading}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Ver más
                  </ButtonMui>
                </Grid>
              </Grid>
            </ToggleUI>
          </>
        )}
      </Box>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.ventas,
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSalesDispatch: (data, callback) =>
      dispatch(ventaObtenerVentas(data, callback)),
    exportSalesDispatch: (data, callback) =>
      dispatch(getSalesToExport(data, callback)),
    archiveOrderDispatch: (data, callback) =>
      dispatch(ventaArchivarVenta(data, callback)),
  };
};

const SalesListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesListPage_);

export default SalesListPage;
