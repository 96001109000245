import React from 'react';
import { Paragraph, StyledLink } from './styles';

export const MessageTextTips = ({ salesAmount, idCountry }) => {
  if (idCountry === 'COL') {
    if (salesAmount === 1) {
      return (
        <Paragraph>
          Sigue creciendo y{' '}
          <StyledLink
            href="https://empretienda.helpjuice.com/"
            rel="noreferrer"
            target="_blank"
          >
            aprendiendo
          </StyledLink>{' '}
          con nuestra plataforma diseñada para emprendedores como tú.
        </Paragraph>
      );
    }

    return (
      <Paragraph>
        Mantén el enfoque en tus objetivos y haz crecer tu emprendimiento con
        nuestra plataforma.
      </Paragraph>
    );
  }

  if (salesAmount === 1) {
    return (
      <Paragraph>
        Seguí creciendo y{' '}
        <StyledLink
          href="https://empretienda.helpjuice.com/"
          rel="noreferrer"
          target="_blank"
        >
          aprendiendo
        </StyledLink>{' '}
        con nuestra plataforma diseñada para emprendedores como vos.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      En{' '}
      <StyledLink
        href="https://empretienda.helpjuice.com/"
        rel="noreferrer"
        target="_blank"
      >
        nuestros tutoriales
      </StyledLink>{' '}
      encontrarás herramientas para hacer crecer tu emprendimiento.
    </Paragraph>
  );
};
