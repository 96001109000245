import React from 'react';
import { ModalUI } from '../../common';
import { withStyles } from '@mui/styles';

const ModalCancelSuscriptionInCriticalPeriod_ = ({
  open,
  classes,
  modalHandler,
}) => {
  const handleSubmitCancelSuscription = (e) => {
    e.preventDefault();
    modalHandler(false);
  };

  return (
    <ModalUI
      open={open}
      button_label="Aceptar"
      id="cancelar_suscripcion_critical_period"
      modalHandler={modalHandler}
      aditional_param="cancelar_suscripcion_critical_period"
      handleSubmit={handleSubmitCancelSuscription}
      title="No pudimos darle de baja a tu suscripción"
      classes={{ card_actions: classes.card_actions }}
      subtitle={
        'Tienes un cobro en curso. Te podrás dar de baja cuando te llegue la factura. Si tienes una duda, comunícate con nosotros desde el Centro de ayuda.'
      }
    />
  );
};

const styles = () => ({
  card_actions: {
    marginTop: '24px',
  },
});

export const ModalCancelSuscriptionInCriticalPeriod = withStyles(styles)(
  ModalCancelSuscriptionInCriticalPeriod_
);
