import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Config } from './../../other';

const PublicLayout_ = (props) => {
  let { classes, children, image = 'login-bg.png' } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={5} md={6} xs={12}>
          <div className={classes.input_box}>{children}</div>
        </Grid>
        <Hidden mdDown>
          <Grid item lg={7} md={6} xs={12}>
            <img
              src={`${Config.CLOUDFRONT_CDN_PROD}/panel/v4/${image}`}
              className={classes.ilustracion}
              alt="Ilustracion"
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    margin: 'auto',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  ilustracion: {
    width: '100%',
    heigth: 'auto',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginBottom: '20px',
      marginTop: '20px',
    },
  },
  input_box: {
    width: '90%',
    heigth: 'inherit',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
});

export const PublicLayout = withStyles(styles, { withTheme: true })(
  PublicLayout_
);
