import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid, LinearProgress } from '@mui/material';
import { Config } from './../../other';

const Splash_ = ({ classes }) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      sx={{ height: '100vh' }}
    >
      <Grid item xs={6} sx={{ margin: '0 auto' }}>
        <img
          src={`${Config.CLOUDFRONT_CDN_PROD}/landingpage/images/v4/empretienda-green-logo.png`}
          alt="Logo panel"
          className={classes.logo}
        />
        <br />
        <LinearProgress />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  logo: {
    width: 'auto',
    height: '20px',
    margin: 'auto',
    display: 'block',
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
      height: '28px',
    },
  },
});

export const Splash = withStyles(styles)(Splash_);
