import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../common';
import { Validate } from '../../../other';

const ModalBloquear = (props) => {
  let { bloquear, handleSubmit, modalHandler, modal_state, form_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="bloquear"
      title="Bloquear cliente"
      modalHandler={modalHandler}
      aditional_param="bloquear"
      handleSubmit={handleSubmit}
      button_label="Bloquear"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            ¿Estás seguro de que deseas bloquear al cliente{' '}
            {Validate.trim_ucwords(
              bloquear.cl_nombre + ' ' + bloquear.cl_apellido
            )}
            ? Este cliente no podrá volver a comprar ni logearse en tu tienda.
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalBloquear };
