export const stringifyWithRegexDeep = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => stringifyWithRegexDeep(item));
  } else if (typeof data === 'object' && data !== null) {
    const newObj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value instanceof RegExp) {
        newObj[key] = `__REGEXP__${value.toString()}`;
      } else {
        newObj[key] = stringifyWithRegexDeep(value);
      }
    }
    return newObj;
  }
  return data;
};

export const parseWithRegexDeep = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => parseWithRegexDeep(item));
  } else if (typeof data === 'object' && data !== null) {
    const newObj = {};
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'string' && value.startsWith('__REGEXP__')) {
        const regexString = value.slice(10); // Quitar el prefijo "__REGEXP__"
        const match = regexString.match(/^\/(.*)\/([a-z]*)$/);
        newObj[key] = new RegExp(match[1], match[2]);
      } else {
        newObj[key] = parseWithRegexDeep(value);
      }
    }
    return newObj;
  }
  return data;
};
