import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  metodosenvioObtener,
  metodoenvioConfigurarOca,
  metodoenvioSolicitarCuentaOca,
} from '../../../store/actions';
import { Config, Regex, RegexExtra, Validate } from '../../../other';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  CheckboxUI,
  ModuleCardTitleUI,
  SelectUI,
  ToggleUI,
} from '../../../components/common';
import { ModalCuentaOca } from '../modales';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

class Oca_ extends Component {
  state = {
    oca: {
      me_oca: {
        validate: false,
        name: 'me_oca',
        label: 'Activar OCA',
        value: 1,
        change_param: 'oca',
      },
      me_oca_usuario: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Usuario de OCA',
        id: 'me_oca_usuario',
        name: 'me_oca_usuario',
        change_param: 'oca',
        regex: Regex.EMAIL,
        value: '',
        default_value: '',
        placeholder: 'micorreo@algo.com',
        messages: {
          error: 'Por favor, ingrese un email de oca válido',
          help: 'El email utilizado para iniciar sesión en oca.com.ar',
          error_extra: RegexExtra.EMAIL,
        },
      },
      me_oca_clave: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Clave de OCA',
        id: 'me_oca_clave',
        name: 'me_oca_clave',
        change_param: 'oca',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: 'miclaveoca',
        messages: {
          error: 'Por favor, ingrese una clave de oca válida',
          help: 'La clave utilizada para iniciar sesión en oca.com.ar',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      me_oca_nrocuenta: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número de cuenta corriente OCA',
        id: 'me_oca_nrocuenta',
        name: 'me_oca_nrocuenta',
        change_param: 'oca',
        regex: Regex.OCA_NROCUENTA,
        value: '',
        default_value: '',
        placeholder: 'XXXXXX/XXX',
        messages: {
          error: 'Por favor, ingrese un número de cuenta corriente válido',
          help: 'El número de cuenta corriente de oca',
          error_extra: RegexExtra.OCA_NROCUENTA,
        },
      },
      me_oca_solicitante: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Titular ante OCA',
        id: 'me_oca_solicitante',
        name: 'me_oca_solicitante',
        change_param: 'oca',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: 'Mi empresa o nombre',
        messages: {
          error: 'Por favor, ingrese un titular de cuenta corriente válido',
          help: 'Nombre del titular o empresa declarada ante oca',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      me_oca_cuit: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'CUIT brindado a OCA',
        id: 'me_oca_cuit',
        name: 'me_oca_cuit',
        change_param: 'oca',
        regex: Regex.CUIT_GUION,
        value: '',
        default_value: '',
        placeholder: 'XX-XXXXXXXX-X',
        messages: {
          error: 'Por favor, ingrese un CUIT válido',
          help: 'El CUIT que se le brindó a oca, con guiones',
          error_extra: RegexExtra.CUIT_GUION,
        },
      },
      me_oca_operativa_pp: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nº de operativa P a P',
        id: 'me_oca_operativa_pp',
        name: 'me_oca_operativa_pp',
        change_param: 'oca',
        regex: Regex.OCA_NROOPERATIVA,
        value: '',
        default_value: '',
        placeholder: 'XXXXXX',
        messages: {
          error:
            'Por favor, ingrese el número de operativa EPAK STD P a P PAGO ORIGEN',
          help: 'Número de operativa de 6 dígitos utilizado para los envíos a domicilio y que al paquete lo pase a retirar oca en su domicilio',
          error_extra: RegexExtra.OCA_NROOPERATIVA,
        },
      },
      me_oca_operativa_ps: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nº de operativa P a S',
        id: 'me_oca_operativa_ps',
        name: 'me_oca_operativa_ps',
        change_param: 'oca',
        regex: Regex.OCA_NROOPERATIVA,
        value: '',
        default_value: '',
        placeholder: 'XXXXXX',
        messages: {
          error:
            'Por favor, ingrese el número de operativa EPAK STD P a S PAGO ORIGEN',
          help: 'Número de operativa de 6 dígitos utilizado para los envíos a sucursal y que al paquete lo pase a retirar oca en su domicilio',
          error_extra: RegexExtra.OCA_NROOPERATIVA,
        },
      },
      me_oca_operativa_sp: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nº de operativa S a P',
        id: 'me_oca_operativa_sp',
        name: 'me_oca_operativa_sp',
        change_param: 'oca',
        regex: Regex.OCA_NROOPERATIVA,
        value: '',
        default_value: '',
        placeholder: 'XXXXXX',
        messages: {
          error:
            'Por favor, ingrese el número de operativa EPAK STD S a P PAGO ORIGEN',
          help: 'Número de operativa de 6 dígitos utilizado para los envíos a domicilio y que usted entregue el paquete en una sucursal oca',
          error_extra: RegexExtra.OCA_NROOPERATIVA,
        },
      },
      me_oca_operativa_ss: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nº de operativa S a S',
        id: 'me_oca_operativa_ss',
        name: 'me_oca_operativa_ss',
        change_param: 'oca',
        regex: Regex.OCA_NROOPERATIVA,
        value: '',
        default_value: '',
        placeholder: 'XXXXXX',
        messages: {
          error:
            'Por favor, ingrese el número de operativa EPAK STD S a S PAGO ORIGEN',
          help: 'Número de operativa de 6 dígitos utilizado para los envíos a sucursal y que usted entregue el paquete en una sucursal oca',
          error_extra: RegexExtra.OCA_NROOPERATIVA,
        },
      },
      me_oca_calle: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Calle',
        id: 'me_oca_calle',
        name: 'me_oca_calle',
        change_param: 'oca',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Av. Pellegrini',
        messages: {
          error: 'Por favor, ingrese una calle válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      me_oca_nro: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'me_oca_nro',
        name: 'me_oca_nro',
        change_param: 'oca',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '13210',
        messages: {
          error: 'Por favor, ingrese un número de calle válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      me_oca_piso: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'me_oca_piso',
        name: 'me_oca_piso',
        change_param: 'oca',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un número de piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      me_oca_dpto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dpto',
        id: 'me_oca_dpto',
        name: 'me_oca_dpto',
        change_param: 'oca',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'A',
        messages: {
          error: 'Por favor, ingrese un número de dpto válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      me_oca_cp: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'me_oca_cp',
        name: 'me_oca_cp',
        change_param: 'oca',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: '1234',
        messages: {
          error: 'Por favor, ngrese un número de código postal válido',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
      me_oca_provincia_id: {
        validate: false,
        required: true,
        error: false,
        label: 'Provincia',
        id: 'me_oca_provincia_id',
        name: 'me_oca_provincia_id',
        change_param: 'oca',
        value: 2,
        messages: {
          error: 'Por favor, seleccione una provincia',
        },
      },
      me_oca_localidad: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'me_oca_localidad',
        name: 'me_oca_localidad',
        change_param: 'oca',
        regex: Regex.CIUDAD_2_45,
        value: '',
        default_value: '',
        placeholder: 'Rosario',
        messages: {
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CIUDAD_2_45,
        },
      },
      me_oca_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Ofrecer envío gratis',
        id: 'me_oca_tipo',
        name: 'me_oca_tipo',
        change_param: 'oca',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_oca_gratis_sucursal: {
        validate: false,
        name: 'me_oca_gratis_sucursal',
        label: 'Ofrecer envío gratis para envíos a sucursal',
        value: 0,
        change_param: 'oca',
      },
      me_oca_gratis_domicilio: {
        validate: false,
        name: 'me_oca_gratis_domicilio',
        label: 'Ofrecer envío gratis para envíos a domicilio',
        value: 0,
        change_param: 'oca',
      },
      me_oca_gratis_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'me_oca_gratis_minimo',
        name: 'me_oca_gratis_minimo',
        change_param: 'oca',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '3000',
        messages: {
          help: 'El monto mínimo requerido de la compra para poder acceder al envío gratis',
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
      me_oca_recargo_monto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto fijo a recargar/descontar',
        id: 'me_oca_recargo_monto',
        name: 'me_oca_recargo_monto',
        change_param: 'oca',
        regex: Regex.PRECIO_CON_SIGNO.ARG,
        value: '',
        default_value: '',
        placeholder: '50.00',
        messages: {
          error: 'Por favor, ingrese un monto válido',
          error_extra: RegexExtra.PRECIO_CON_SIGNO.ARG,
        },
      },
      me_oca_recargo_porcentaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje sobre la tarifa a recargar/descontar',
        id: 'me_oca_recargo_porcentaje',
        name: 'me_oca_recargo_porcentaje',
        change_param: 'oca',
        regex: Regex.PORCENTAJE_CON_SIGNO,
        value: '',
        default_value: '',
        placeholder: '5',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.PORCENTAJE_CON_SIGNO,
        },
      },
    },
    solicitar_cuenta: {
      sc_razon_social: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Razón social',
        id: 'sc_razon_social',
        name: 'sc_razon_social',
        change_param: 'solicitar_cuenta',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: 'Tu razón social',
        messages: {
          error: 'Por favor, ingrese una razón social válida',
          help: 'Debe ser la razón social registrada en AFIP',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      sc_telefono: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono',
        id: 'sc_telefono',
        name: 'sc_telefono',
        change_param: 'solicitar_cuenta',
        regex: Regex.TELEFONO_COMPUESTO,
        value: '',
        default_value: '',
        placeholder: '3415555472',
        messages: {
          error: 'Por favor, ingrese un número de teléfono válido',
          help: 'Lo utilizará el ejecutivo OCA para contactarse en caso de ser necesario',
          error_extra: RegexExtra.TELEFONO_COMPUESTO,
        },
      },
      sc_doc_alta_oca: {
        validate: false,
        auth: false,
        required: true,
        id: 'sc_doc_alta_oca',
        name: 'sc_doc_alta_oca',
        change_param: 'solicitar_cuenta',
        file: null,
        messages: {
          help: 'Aquí debes cargar el archivo que descargaste y completaste (datos-alta-oca.docx)',
          error: '',
        },
      },
      sc_doc_situacion_fiscal: {
        validate: false,
        auth: false,
        required: true,
        id: 'sc_doc_situacion_fiscal',
        name: 'sc_doc_situacion_fiscal',
        change_param: 'solicitar_cuenta',
        file: null,
        messages: {
          help: 'Aquí debes cargar el archivo que descargaste y completaste (situacion-fiscal.doc)',
          error: '',
        },
      },
      sc_doc_constancia_iibb: {
        validate: false,
        auth: false,
        required: true,
        id: 'sc_doc_constancia_iibb',
        name: 'sc_doc_constancia_iibb',
        change_param: 'solicitar_cuenta',
        file: null,
        messages: {
          help: 'Aquí debes cargar tu constancia de alta de ingresos brutos',
          error: '',
        },
      },
      sc_doc_constancia_afip: {
        validate: false,
        auth: false,
        required: true,
        id: 'sc_doc_constancia_afip',
        name: 'sc_doc_constancia_afip',
        change_param: 'solicitar_cuenta',
        file: null,
        messages: {
          help: 'Aquí debes cargar tu constancia de inscripción en AFIP',
          error: '',
        },
      },
    },
    loading: {
      oca: true,
      error: false,
    },
    forms: {
      oca: false,
      solicitar_cuenta: false,
    },
    modals: {
      solicitar_cuenta: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      provincias: [
        {
          value: 2,
          label: 'BUENOS AIRES',
        },
        {
          value: 1,
          label: 'CAPITAL FEDERAL',
        },
        {
          value: 10,
          label: 'CATAMARCA',
        },
        {
          value: 19,
          label: 'CHACO',
        },
        {
          value: 9,
          label: 'CHUBUT',
        },
        {
          value: 4,
          label: 'CORDOBA',
        },
        {
          value: 5,
          label: 'CORRIENTES',
        },
        {
          value: 8,
          label: 'ENTRE RIOS',
        },
        {
          value: 12,
          label: 'FORMOSA',
        },
        {
          value: 15,
          label: 'JUJUY',
        },
        {
          value: 13,
          label: 'LA PAMPA',
        },
        {
          value: 14,
          label: 'LA RIOJA',
        },
        {
          value: 3,
          label: 'MENDOZA',
        },
        {
          value: 11,
          label: 'MISIONES',
        },
        {
          value: 6,
          label: 'NEUQUEN',
        },
        {
          value: 7,
          label: 'RIO NEGRO',
        },
        {
          value: 18,
          label: 'SALTA',
        },
        {
          value: 24,
          label: 'SAN JUAN',
        },
        {
          value: 16,
          label: 'SAN LUIS',
        },
        {
          value: 21,
          label: 'SANTA CRUZ',
        },
        {
          value: 17,
          label: 'SANTA FE',
        },
        {
          value: 22,
          label: 'SGO. DEL ESTERO',
        },
        {
          value: 20,
          label: 'TIERRA DEL FUEGO',
        },
        {
          value: 23,
          label: 'TUCUMAN',
        },
      ],
      me_oca_tipo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si (Para todos los productos)',
        },
        {
          value: 2,
          label: 'Si (Ventas mayores a cierto monto)',
        },
      ],
    },
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            oca: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          this.state.oca,
          (oca_obj) => {
            this.setState((prevState) => ({
              oca: oca_obj,
              loading: {
                ...prevState.loading,
                oca: false,
              },
            }));
          }
        );
      }
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value = Validate.trim_int(e.target.value);

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  handleChangeDoc = (err, data) => {
    let { change_param, name } = data;

    this.setState((prevState) => ({
      [change_param]: {
        ...prevState[change_param],
        [name]: data,
      },
    }));
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: prevState[aditional][name]['value'] ? 0 : 1,
        },
      },
    }));
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  modalHandler = (open, id, _aditional) => {
    this.setState((prevState) => ({
      modals: {
        ...prevState.modals,
        [id]: open,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        oca: true,
      },
    }));

    let form = this.state.oca;

    if (Validate.validar_formulario(form)) {
      this.props.metodoenvioConfigurarOca(
        {
          me_oca: form.me_oca.value,
          me_oca_usuario: form.me_oca_usuario.value,
          me_oca_clave: form.me_oca_clave.value,
          me_oca_nrocuenta: form.me_oca_nrocuenta.value,
          me_oca_solicitante: form.me_oca_solicitante.value,
          me_oca_cuit: form.me_oca_cuit.value,
          me_oca_operativa_pp: form.me_oca_operativa_pp.value,
          me_oca_operativa_ps: form.me_oca_operativa_ps.value,
          me_oca_operativa_sp: form.me_oca_operativa_sp.value,
          me_oca_operativa_ss: form.me_oca_operativa_ss.value,
          me_oca_calle: form.me_oca_calle.value,
          me_oca_nro: form.me_oca_nro.value,
          me_oca_piso: form.me_oca_piso.value,
          me_oca_dpto: form.me_oca_dpto.value,
          me_oca_cp: form.me_oca_cp.value,
          me_oca_localidad: form.me_oca_localidad.value,
          me_oca_provincia_id: form.me_oca_provincia_id.value,
          me_oca_tipo: form.me_oca_tipo.value,
          me_oca_gratis_sucursal: form.me_oca_gratis_sucursal.value,
          me_oca_gratis_domicilio: form.me_oca_gratis_domicilio.value,
          me_oca_gratis_minimo: form.me_oca_gratis_minimo.value,
          me_oca_recargo_porcentaje: form.me_oca_recargo_porcentaje.value,
          me_oca_recargo_monto: form.me_oca_recargo_monto.value,
        },
        (err, resp) => {
          this.setState(
            (prevState) => ({
              forms: {
                ...prevState.forms,
                oca: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            }),
            () => {
              if (!err && form.me_oca.value === 1) {
                createAmplitudeEventWithDevice(
                  method_shipment_amplitude_events.metodos_envio_activar
                    .event_name,
                  { metodo_envio: 'oca', success: true }
                );

                if (this.props.idCountry === 'ARG') {
                  braze.logCustomEvent('empretienda_metodos_envío_activar', {
                    metodo_envio: 'oca',
                  });
                }
              } else {
                createAmplitudeEventWithDevice(
                  method_shipment_amplitude_events.metodos_envio_desactivar
                    .event_name,
                  { metodo_envio: 'oca' }
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState(
          (prevState) => ({
            forms: {
              ...prevState.forms,
              oca: false,
            },
            oca: new_form,
            alert: {
              ...prevState.alert,
              open: true,
              message: 'Por favor, revisá tus datos, tenés algunos errores',
              type: 'error',
            },
          }),
          () => {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'oca', success: false }
            );
          }
        );
      });
    }
  };

  handleSubmitSolicitarCuentaOca = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        solicitar_cuenta: true,
      },
    }));

    let form = this.state.solicitar_cuenta;

    let documentos_ok =
      form.sc_doc_alta_oca.auth &&
      form.sc_doc_situacion_fiscal.auth &&
      form.sc_doc_constancia_iibb.auth &&
      form.sc_doc_constancia_afip.auth;

    if (documentos_ok) {
      if (Validate.validar_formulario(form)) {
        this.props.metodoenvioSolicitarCuentaOca(
          {
            sc_razon_social: form.sc_razon_social.value,
            sc_telefono: form.sc_telefono.value,
            sc_doc_alta_oca: form.sc_doc_alta_oca.file,
            sc_doc_situacion_fiscal: form.sc_doc_situacion_fiscal.file,
            sc_doc_constancia_iibb: form.sc_doc_constancia_iibb.file,
            sc_doc_constancia_afip: form.sc_doc_constancia_afip.file,
          },
          (err, resp) => {
            if (err) {
              this.setState((prevState) => ({
                forms: {
                  ...prevState.forms,
                  solicitar_cuenta: false,
                },
                alert: {
                  ...prevState.alert,
                  open: true,
                  message: resp.message,
                  type: 'error',
                },
              }));
            } else {
              this.setState((prevState) => ({
                forms: {
                  ...prevState.forms,
                  solicitar_cuenta: false,
                },
                modals: {
                  ...prevState.modals,
                  solicitar_cuenta: false,
                },
                alert: {
                  ...prevState.alert,
                  open: true,
                  message: resp.message,
                  type: 'success',
                },
              }));
            }
          }
        );
      } else {
        Validate.validar_formulario_actualizar_obj(form, (new_form) => {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              solicitar_cuenta: false,
            },
            solicitar_cuenta: new_form,
            alert: {
              ...prevState.alert,
              open: true,
              message: 'Por favor, revisá tus datos, tenés algunos errores',
              type: 'error',
            },
          }));
        });
      }
    } else {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          solicitar_cuenta: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: 'Debes cargar los 4 documentos requeridos',
          type: 'error',
        },
      }));
    }
  };

  render() {
    let classes = this.props.classes;

    let ejemplo = 500.0;
    let porcentaje = Validate.trim_float(
      this.state.oca.me_oca_recargo_porcentaje.value
    );
    let porcentaje_a_ejemplo = Validate.trim_float(
      Validate.get_fixed_percent(ejemplo, porcentaje)
    );
    let monto = Validate.trim_float(this.state.oca.me_oca_recargo_monto.value);
    let total = ejemplo + porcentaje_a_ejemplo + monto;
    total = total > 0 ? total : 0;
    let porcentaje_label = porcentaje >= 0 ? 'recargo' : 'descuento';
    let monto_fijo_label = monto >= 0 ? 'recargo' : 'descuento';

    return (
      <WrapperLayout
        loading={this.state.loading.oca}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalCuentaOca
          solicitar_cuenta={this.state.solicitar_cuenta}
          handleSubmit={this.handleSubmitSolicitarCuentaOca}
          handleChange={this.handleChange}
          handleChangeDoc={this.handleChangeDoc}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.solicitar_cuenta}
          form_state={this.state.forms.solicitar_cuenta}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="OCA"
                image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/oca-logo.png`}
                subtitles={[
                  'Automatiza la logística de tu negocio y mejora la experiencia de compra con la integración de OCA.',
                  'Con Empretienda, vas a poder acceder a un 10% de descuento en el precio de lista en OCA.',
                  'Para obtener una cuenta OCA con convenio de Empretienda debes hacer click en "SOLICITAR CUENTA".',
                ]}
                button={
                  <ButtonUI
                    type="callf"
                    label="Solicitar cuenta"
                    fullWidth={false}
                    onClickFunc={(args) =>
                      this.modalHandler(true, 'solicitar_cuenta', null)
                    }
                  />
                }
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Credenciales de OCA"
                      subtitles={[
                        'Esta información será brindada por el agente de cuentas OCA y será utilizada para realizar operaciones como cálculo de costos de envío, alta de envío, seguimientos, etc. Será mantenida de manera confidencial.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_usuario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_clave}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_nrocuenta}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_solicitante}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_cuit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_operativa_sp}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_operativa_ss}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_operativa_pp}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_operativa_ps}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Envío gratis"
                      subtitles={[
                        'En esta sección podés configurar los envíos gratuitos a través de OCA.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <SelectUI
                        data={this.state.oca.me_oca_tipo}
                        options={this.state.selects.me_oca_tipo}
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <ToggleUI show={this.state.oca.me_oca_tipo.value === 2}>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.oca.me_oca_gratis_minimo}
                          adornment="$"
                        />
                      </Grid>
                    </ToggleUI>
                    <ToggleUI show={this.state.oca.me_oca_tipo.value >= 1}>
                      <Grid item xs={12}>
                        <CheckboxUI
                          handleChangeCheckbox={this.handleChangeCheckbox}
                          checkbox={this.state.oca.me_oca_gratis_sucursal}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxUI
                          handleChangeCheckbox={this.handleChangeCheckbox}
                          checkbox={this.state.oca.me_oca_gratis_domicilio}
                        />
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <ModuleCardTitleUI
                      title="Recargos y/o descuentos (opcional)"
                      subtitles={[
                        'Podés configurar un aumento o disminución en monto fijo y/o porcentaje al monto tarifado por OCA. Por ejemplo, podés agregar el porcentaje de comisión por venta de los métodos de pago, costos de embalaje, despacho, etc o hacer un descuento por una parte del envío a tu cliente y vos abonar esa diferencia.',
                        'Para descontar sólo tenés que poner el signo menos (-) delante del monto y/o porcentaje.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_recargo_porcentaje}
                        end_adornment="%"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_recargo_monto}
                        adornment="$"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body1" mb={1}>
                        Ejemplo de cómo tarifaría suponiendo que el costo del
                        envío sea de ${Validate.number_format(ejemplo)} (monto
                        no real, los costos de envío pueden variar según origen,
                        destino, dimensiones y peso del paquete):
                      </Typography>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Ejemplo tarifa final OCA
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(ejemplo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Porcentaje de {porcentaje_label} ({porcentaje}%)
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(porcentaje_a_ejemplo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Monto de {monto_fijo_label}
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(monto)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Tarifa final a pagar por el cliente
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(total)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Dirección de retiro"
                      subtitles={[
                        'Esta información será utilizada en caso de que solicite que oca retire los paquetes en su domicilio, el horario y día de retiro lo pactará usted cada vez que confirme un envío.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_calle}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_nro}
                      />
                    </Grid>
                    <Grid item lg={4} xs={6}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_piso}
                      />
                    </Grid>
                    <Grid item lg={4} xs={6}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_dpto}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_cp}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectUI
                        data={this.state.oca.me_oca_provincia_id}
                        options={this.state.selects.provincias}
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.oca.me_oca_localidad}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={this.state.oca.me_oca}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  isLoading={this.state.forms.oca}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodoenvioConfigurarOca: (data, callback) =>
      dispatch(metodoenvioConfigurarOca(data, callback)),
    metodoenvioSolicitarCuentaOca: (data, callback) =>
      dispatch(metodoenvioSolicitarCuentaOca(data, callback)),
  };
};

const OcaPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Oca_)
);

export default OcaPage;
