import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarRedesSociales,
} from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { Grid, Icon } from '@mui/material';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
} from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { BackgroundBox } from '../../components/common/BackgroundBox/styles';

const SocialMediaPage_ = ({ tiendaObtener, tiendaActualizarRedesSociales }) => {
  const [state, setState] = useState({
    redes_sociales: {
      t_facebook: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Facebook',
        id: 't_facebook',
        name: 't_facebook',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de facebook válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_instagram: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Instagram',
        id: 't_instagram',
        name: 't_instagram',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de instragram válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_twitter: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Twitter',
        id: 't_twitter',
        name: 't_twitter',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de twitter válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_pinterest: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Pinterest',
        id: 't_pinterest',
        name: 't_pinterest',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de pinterest válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_tiktok: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'TikTok',
        id: 't_tiktok',
        name: 't_tiktok',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de TikTok válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_youtube: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'YouTube',
        id: 't_youtube',
        name: 't_youtube',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'c/empretienda',
        messages: {
          error: 'Ingrese un link de canal de YouTube válido',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
    },
    loading: {
      redes_sociales: true,
      error: false,
    },
    forms: {
      redes_sociales: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });

  useEffect(() => {
    tiendaObtener((err, resp) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            redes_sociales: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          state.redes_sociales,
          (redes_sociales_obj) => {
            setState((prevState) => ({
              ...prevState,
              redes_sociales: redes_sociales_obj,
              loading: {
                ...prevState.loading,
                redes_sociales: false,
                error: false,
              },
            }));
          }
        );
      }
    });
  }, []);

  const handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;
    const stateAux = {
      [aditional]: {
        ...state[aditional],
        [name]: {
          ...state[aditional][name],
          value: value,
        },
      },
    };

    Validate.validate_input(stateAux[aditional][name], blur, (input) => {
      setState((prevState) => ({
        ...prevState,
        [aditional]: {
          ...prevState[aditional],
          [name]: input,
        },
      }));
    });
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        redes_sociales: true,
      },
    }));

    let form = state.redes_sociales;

    if (Validate.validar_formulario(form)) {
      tiendaActualizarRedesSociales(
        {
          t_facebook: form.t_facebook.value,
          t_instagram: form.t_instagram.value,
          t_twitter: form.t_twitter.value,
          t_pinterest: form.t_pinterest.value,
          t_tiktok: form.t_tiktok.value,
          t_youtube: form.t_youtube.value,
        },
        (err, resp) => {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              redes_sociales: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            redes_sociales: false,
          },
          redes_sociales: new_form,
        }));
      });
    }
  };

  return (
    <WrapperLayout
      loading={state.loading.redes_sociales}
      error={state.loading.error}
    >
      <AlertUI
        open={state.alert.open}
        message={state.alert.message}
        type={state.alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <ModuleTitleUI
              title="Redes sociales"
              subtitles={[
                'En esta sección podés colocar los nombres de usuario de tus redes sociales para que aparezcan en tu tienda online.',
                'Recordá agregar el link de tu tienda a tus redes sociales para tener todo vinculado.',
              ]}
            />
            <BackgroundBox sx={{ padding: '20px' }} mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    adornment={
                      <Icon
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        className="fab fa-facebook-f"
                      />
                    }
                    adornment_extra="/"
                    input={state.redes_sociales.t_facebook}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    adornment={
                      <Icon
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        className="fab fa-instagram"
                      />
                    }
                    adornment_extra="/"
                    input={state.redes_sociales.t_instagram}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    adornment={
                      <Icon
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        className="fab fa-twitter"
                      />
                    }
                    adornment_extra="/"
                    input={state.redes_sociales.t_twitter}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    adornment={
                      <Icon
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        className="fab fa-pinterest-p"
                      ></Icon>
                    }
                    adornment_extra="/"
                    input={state.redes_sociales.t_pinterest}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    adornment={
                      <Icon
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        className="fab fa-tiktok"
                      ></Icon>
                    }
                    adornment_extra="/@"
                    input={state.redes_sociales.t_tiktok}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    adornment={
                      <Icon
                        sx={{ color: 'rgba(0, 0, 0, 0.87)', width: '30px' }}
                        className={'fab fa-youtube'}
                      ></Icon>
                    }
                    adornment_extra="/"
                    input={state.redes_sociales.t_youtube}
                  />
                </Grid>
              </Grid>
            </BackgroundBox>
            <ModuleButtonBoxUI>
              <ButtonUI
                type="submit"
                label="Guardar"
                minHeight={true}
                isLoading={state.forms.redes_sociales}
              />
            </ModuleButtonBoxUI>
          </form>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarRedesSociales: (data, callback) =>
      dispatch(tiendaActualizarRedesSociales(data, callback)),
  };
};

const SocialMediaPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMediaPage_);

export default SocialMediaPage;
