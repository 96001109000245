import React from 'react';
import Template from './Template';
import { Grid } from '@mui/material';
import { templateObjects } from './templateObjects';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../../components/amplitude';
import { getTemplateName } from '../../../other/getTemplateName';
import FeaturedTemplate from '../FeaturedTemplate';

const TemplatesContainer = () => {
  const featuredTemplateId = 4;
  let featuredTemplateObject = null;
  const filteredTemplateObjects = [];

  templateObjects.forEach((template) => {
    if (template.id === featuredTemplateId) {
      featuredTemplateObject = template;
    } else {
      filteredTemplateObjects.push(template);
    }
  });

  const shop = useSelector((state) => state.tienda);
  const router = useHistory();
  const selectedTemplate = localStorage.getItem(
    `template-selected-${shop.idTienda}`
  );

  const chipsArray = [
    {
      templateName: 'Indumentaria',
      templateDemoUrl: 'https://ceresplantillanueva.empretienda.com.ar/',
    },
  ];

  const handleApplyTemplate = (templateId, isFeaturedTemplate = false) => {
    localStorage.setItem(`template-selected-${shop.idTienda}`, `${templateId}`);
    router.push('/builder', { from: '/selecciona-plantilla' });
    if (isFeaturedTemplate) {
      createAmplitudeEventWithDevice(
        design_amplitude_events.diseño_selecciona_ver_demo.event_name,
        {
          plantilla: getTemplateName(templateId),
        }
      );
    } else {
      createAmplitudeEventWithDevice(
        design_amplitude_events.diseño_probar_plantilla.event_name,
        {
          plantilla: getTemplateName(templateId),
        }
      );
    }
  };

  return (
    <Grid container justifyContent="space-between" spacing={6} mb={10}>
      <FeaturedTemplate
        imgSrc={featuredTemplateObject.src}
        templateName={featuredTemplateObject.templateName}
        featuredTemplateId={featuredTemplateObject.id}
        chipsArray={chipsArray}
        isInUse={
          selectedTemplate
            ? featuredTemplateObject.id === Number(selectedTemplate)
            : featuredTemplateObject.id === shop.Templates_idTemplates
        }
        handleApplyTemplate={handleApplyTemplate}
      />
      {filteredTemplateObjects.map((template) => (
        <Template
          key={template.id}
          imgSrc={template.src}
          templateName={template.templateName}
          handleApplyTemplate={() => handleApplyTemplate(template.id)}
          isInUse={
            selectedTemplate
              ? template.id === Number(selectedTemplate)
              : template.id === shop.Templates_idTemplates
          }
        />
      ))}
    </Grid>
  );
};

export default TemplatesContainer;
