/* eslint-disable no-useless-escape */
class Regex {
  static NOMBRE_TIENDA =
    /^(?=.{2,24}$)([a-zá-úÁ-ÚA-Z0-9]((\s|\s-\s|-)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static CLAVE = /^.{6,12}$/;
  static EMAIL = /^[\w\-\.]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z]{2,7}$/;
  static NOMBRE_5_90 =
    /^(?=.{5,90}$)([a-zA-Zá-úÁ-Ú](\s[a-zA-Zá-úÁ-Ú])?(\s)?)*$/;
  static NOMBRE_2_45 =
    /^(?=.{2,45}$)([a-zA-Zá-úÁ-Ú](\s[a-zA-Zá-úÁ-Ú])?(\s)?)*$/;
  static DIRECCION_4_45 =
    /^(?=.{4,45}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\-|\-\s|\s\-|s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static DIRECCION_0_45 =
    /^(?=.{0,45}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static DIRECCION_4_65 =
    /^(?=.{4,65}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\-|\-\s|\s\-|s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static DIRECCION_0_65 =
    /^(?=.{4,65}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\-|\-\s|\s\-|s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static DIRECCION_COLOMBIA_COMPLETA =
    /^(?=.{4,65}$)(((((?!.*\s\s)[a-zA-Z0-9á-úÁ-Ú\.\,\-\s]{1,50})(\#?)((?!.*\s\s)[a-zA-Z0-9á-úÁ-Ú\s]{1,15}).(\-?).(\d{1,4})){1})((,)((?!.*\s\s)[a-zA-Z0-9á-úÁ-Ú\.\s]{3,30})){0,2})$/;
  static CIUDAD_2_45 =
    /^(?=.{2,45}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\-|\-\s|\s\-|s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static CIUDAD_0_45 =
    /^(?=.{0,45}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static CALLE_1_30 =
    /^(?=.{1,30}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static DIRECCION_CALLE = {
    COL: /^(?=.{1,30}$)([a-zá-úÁ-ÚA-Z0-9]+(\s|\.|\,)*)+$/,
    ARG: /^(?=.{1,30}$)([a-zá-úÁ-ÚA-Z0-9]+(\s|\.|\,)*)+$/,
  };
  static CALLE_NRO_1 = /^(?=.{1,17}$)([a-zá-úÁ-ÚA-Z0-9]+(\s|\.|\,)*)+$/;
  static CALLE_NRO_2 = /^[0-9]{1,4}$/;
  static CALLE_0_30 =
    /^(?=.{0,30}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static ALTURA_CALLE_1_10 =
    /^(?=.{1,10}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\/)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s|\s\/|\s\/\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static ALTURA_CALLE_O_10 =
    /^(?=.{0,10}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\/)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s|\s\/|\s\/\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static ALTURA_CALLE_ADICIONAL_1_20 =
    /^(?=.{1,20}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\/)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s|\s\/|\s\/\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static ALTURA_CALLE_ADICIONAL_O_20 =
    /^(?=.{0,20}$)(([a-zá-úÁ-ÚA-Z0-9](\.|\s|\,|\,\s|\/)?)((\s|\s\-\s|\-|\.|\s\.|\s\.\s|\s\/|\s\/\s)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static CODIGO_POSTAL = /^[1-9]{1}[0-9]{3}$/;
  static DNI = /^[0-9]{7,8}$/;
  static DOCUMENTO = /^[0-9]{7,15}$/;
  static FECHA = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
  static STRING_GENERICO_2_20 =
    /^[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9\s\!\.\-\,\?\"\'\:\¡\¿\%\(\)\[\]\$\+\~\#\_\&\/]{2,20}$/;
  static STRING_GENERICO_2_45 =
    /^[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9\s\!\.\-\,\?\"\'\:\¡\¿\%\(\)\[\]\$\+\~\#\_\&\/]{2,45}$/;
  static STRING_GENERICO_5_90 =
    /^[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9\s\!\.\-\,\?\"\'\:\¡\¿\%\(\)\[\]\$\+\~\#\_\&\/]{5,90}$/;
  static STRING_GENERICO_2_90 =
    /^[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9\s\!\.\-\,\?\"\'\:\¡\¿\%\(\)\[\]\$\+\~\#\_\&\/]{2,90}$/;
  static NUMERO_ENTERO = /^[0-9]+$/;
  static NUMERO_ENTERO_5_45 = /^[0-9]{5,45}$/;
  static API_STRING_GENERICO =
    /^[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9\s\!\.\-\,\?\"\'\:\¡\¿\%\(\)\[\]\$\+\~\#\_\&\/]{5,200}$/;
  static NUMERO_ENTERO_POSITIVO = /^[1-9]{1}([0-9]?)*$/;
  static IP = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
  static CUIT = /^[0-9]{7,11}$/;
  static CUIT_GUION = /^[0-9]{2}\-[0-9]{7,8}\-[0-9]{1}$/;
  static CUIT_GUION_OPCIONAL =
    /^[0-9]{0,2}[\-]{0,1}[0-9]{7,8}[\-]{0,1}[0-9]{1}$/;

  static PRECIO = {
    ARG: /^([0-9]{1,15}((\.)[0-9]{1,2})?)$/,
    COL: /^[0-9]{1,15}$/,
  };
  static PRECIO_CON_SIGNO = {
    ARG: /^((-)?[0-9]{1,15}((\.)[0-9]{1,2})?)$/,
    COL: /^(-)?[0-9]{1,15}$/,
  };
  static PRECIO_POSITIVO = {
    ARG: /^(([1-9]{1}[0-9]{0,14}(\.[0-9]{0,2})?)|((0.)([1-9]{1}[0-9]{0,1}|[0-9]{1}[1-9]{1})))$/,
    COL: /^[1-9]{1}[0-9]{0,14}$/,
  };

  static PORCENTAJE = /^([0-9]{1,15}((\.)[0-9]{1,2})?)$/;
  static PORCENTAJE_CON_SIGNO = /^((-)?[0-9]{1,15}((\.)[0-9]{1,2})?)$/;
  static PESO = /^([0-9]{1,}((\.)[0-9]{1,6})?)$/;
  static TELEFONO = /^(?=.{6,15}$)((\+)?[0-9])*$/;
  static TELEFONO_COMPUESTO =
    /^(?=.{6,25}$)((\+)?(\(|\s\(\s|\s\()?[0-9](\)|\s\)\s|\s\))?((\s|\-|\s\-\s)[0-9])?)*$/;
  static RED_SOCIAL = /^[a-zA-Z0-9\-\_\@\.\/\?\#\=\&]{0,100}$/;
  static DESCRIPCION_0_200 = /^[\s\S]{0,200}$/;
  static DESCRIPCION_0_500 = /^[\s\S]{0,500}$/;
  static DESCRIPCION_0_1000 = /^[\s\S]{0,1000}$/;
  static DESCRIPCION_0_5000 = /^[\s\S]{0,5000}$/;
  static DESCRIPCION_20_200 = /^[\s\S]{20,200}$/;
  static DESCRIPCION_20_500 = /^[\s\S]{20,500}$/;
  static DESCRIPCION_20_1000 = /^[\s\S]{20,1000}$/;
  static DESCRIPCION_20_5000 = /^[\s\S]{20,5000}$/;
  static DESCRIPCION_10_5000 = /^[\s\S]{10,5000}$/;
  static ANYTHING = /^.$/;
  static TODOPAGO_MERCHANT_ID = /^[0-9]{4,10}$/;
  static TODOPAGO_API_KEY = /^(TODOPAGO )[a-zA-Z0-9]{32}$/;
  static OCA_NROCUENTA = /^[0-9]{6}\/[0-9]{3}$/;
  static OCA_NROOPERATIVA = /^[0-9]{6}$/;
  static SIX_DIGIT = /^[1-9]{1}[0-9]{5}$/;
  static CATEGORIA_NOMBRE =
    /^(?=.{2,35}$)([a-zá-úÁ-ÚA-Z0-9]((\s|\s-\s|-)[a-zá-úÁ-ÚA-Z0-9])?(\s)?)*$/;
  static GOOGLE_ANALYTICS = /^(?=.{8,30}$)((UA|YT|MO|G)-[\w\-]+)*$/;
  static DOMINIO =
    /^(?=.{2,100}$)((([a-zA-Z0-9]{1,}(\-[a-zA-Z0-9]{1,})?(\.[a-zA-Z0-9]{1,})?)+(\.[a-zA-Z]{2,7})+)*)$/;
  static REGISTRO_NOMBRE =
    /^(?=.{2,100}$)((([a-zA-Z0-9\_]{1,}(\-[a-zA-Z0-9\_]{1,})?(\.[a-zA-Z0-9\_]{1,})?)+(\.[a-zA-Z]{2,7})+)*(\.)?)$/;
  static REGISTRO_A = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
  static REGISTRO_MX =
    /^([0-9]{1,2}\s(([a-zA-Z0-9]{1,}(([\-\.]{1})([a-zA-Z0-9])?)?)+\.[a-zA-Z]{2,6}(\.)?(\r\n|\n)?))+$/;
  static REGISTRO_CNAME =
    /^(?=.{2,100}$)((([a-zA-Z0-9\_]{1,}(\-[a-zA-Z0-9\_]{1,})?(\.[a-zA-Z0-9\_]{1,})?)+(\.[a-zA-Z]{2,7})+)*(\.)?)$/;
  static REGISTRO_TXT = /^[\s\S]{1,5000}$/;
  static LINK =
    /^(https:\/\/|http:\/\/)[0-9a-zA-Z\.\:\/\#\-\_\?\=\&\$\%\@\~\!]{2,200}$/;
  static NOMBRE_PRODUCTO =
    /^(?=\S.{0,78}\S$)^([A-Za-z0-9ÁÉÍÓÚáéíóúÄËÏÖÜäëïöüñÑ\/\-\!\'\"\,\.\+\[\]\(\)\:\#\s]+)$/;
  static SKU = /^[a-zA-Z0-9\-\,\.\s]{1,50}$/;
  static ATRIBUTO =
    /^(?=.{2,25}$)([a-zá-úÁ-Úä-üÄ-ÜA-Z0-9](\s[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9])?(\s)?)*$/;
  static VALOR_ATRIBUTO =
    /^(?=.{1,25}$)([a-zá-úÁ-Úä-üÄ-ÜA-Z0-9](([\/\s\-\!\'\"\,\.\+\[\]\(\)\:\#]{0,4})[a-zá-úÁ-Úä-üÄ-ÜA-Z0-9])?(\s)?([\/\-\!\'\"\,\.\+\[\]\(\)\:\#]{0,3}))*$/;
  static CUPON_DESCUENTO = /^[0-9a-zA-Z\-]{2,30}$/;
  static COLOR = /^(#)([a-fA-f0-9]{3}|[a-fA-f0-9]{6})$/;
  static PINTEREST_TAG =
    /^(<meta name="p:domain_verify" content=")([a-zA-Z0-9]{10,59})("\/>)$/;
  static DOCUMENTO_COLOMBIA = /^[1-9]{1}[0-9]{4,9}$/;
  static DOCUMENTO_COLOMBIA_PPT = /^[1-9]{1}[0-9]{3,6}$/;
  static TELEFONO_COMPUESTO_COLOMBIA = /^(57)[0-9]{10}$|^[0-9]{10}$/;
  static NOMBRE_3_45 = {
    ARG: this.STRING_GENERICO_2_90,
    COL: /^(?=.{3,45}$)([a-zA-Zá-úÁ-Ú](\s[a-zA-Zá-úÁ-Ú])?(\s)?)*$/,
  };
  static APELLIDO_3_45 = {
    ARG: this.STRING_GENERICO_2_90,
    COL: /^(?=.{3,45}$)([a-zA-Zá-úÁ-Ú](\s[a-zA-Zá-úÁ-Ú])?(\s)?)*$/,
  };
}

class RegexExtra {
  static NOMBRE_TIENDA = '[2-24 caracteres][Símbolos permitidos: - ]';
  static CLAVE = '[6-12 caracteres]';
  static EMAIL = '';
  static EMPTY = '';
  static NOMBRE_5_90 = '[5-90 caracteres]';
  static NOMBRE_2_45 = '[2-45 caracteres]';
  static DIRECCION_4_45 = '[4-45 caracteres][Símbolos permitidos: - . , ]';
  static DIRECCION_0_45 = '[Hasta 45 caracteres][Símbolos permitidos: - . , ]';
  static DIRECCION_0_65 = '[Hasta 65 caracteres][Símbolos permitidos: - . , ]';
  static DIRECCION_4_65 = '[4-65 caracteres] [Símbolos permitidos: -., ].';
  static DIRECCION_COLOMBIA_COMPLETA =
    '[4-65 caracteres] [Símbolos permitidos: -.,# ].';
  static CIUDAD_2_45 = '[2-45 caracteres][Símbolos permitidos: - . , ]';
  static CIUDAD_0_45 = '[Hasta 45 caracteres][Símbolos permitidos: - . , ]';
  static CALLE_1_30 = '[1-30 caracteres][Símbolos permitidos: - . , ]';
  static CALLE_0_30 = '[Hasta 30 caracteres][Símbolos permitidos: - . , ]';
  static ALTURA_CALLE_1_10 = '[1-10 caracteres][Símbolos permitidos: - . , / ]';
  static ALTURA_CALLE_O_10 =
    '[Hasta 10 caracteres][Símbolos permitidos: - . , / ]';
  static ALTURA_CALLE_ADICIONAL_1_20 =
    '[1-20 caracteres][Símbolos permitidos: - . , / ]';
  static ALTURA_CALLE_ADICIONAL_O_20 =
    '[Hasta 20 caracteres][Símbolos permitidos: - . , / ]';
  static CODIGO_POSTAL = '[4 caracteres][Sólo números]';
  static DNI = '[Sólo números]';
  static DOCUMENTO = '[Sólo números]';
  static FECHA = '[aaaa-mm-dd]';
  static STRING_GENERICO_2_20 =
    '[2-20 caracteres][Símbolos permitidos: ! . - , ? " \' : % ( ) [ ] $ + ~ # _ & / ]';
  static STRING_GENERICO_2_45 =
    '[2-45 caracteres][Símbolos permitidos: ! . - , ? " \' : % ( ) [ ] $ + ~ # _ & / ]';
  static STRING_GENERICO_5_90 =
    '[5-90 caracteres][Símbolos permitidos: ! . - , ? " \' : % ( ) [ ] $ + ~ # _ & / ]';
  static STRING_GENERICO_2_90 =
    '[2-90 caracteres][Símbolos permitidos: ! . - , ? " \' : % ( ) [ ] $ + ~ # _ & / ]';
  static NUMERO_ENTERO = '[Sólo números]';
  static NUMERO_ENTERO_5_45 = '[Sólo números][5-45 números]';
  static API_STRING_GENERICO = '';
  static NUMERO_ENTERO_POSITIVO = '[Número positivo]';
  static IP = '[Debe ser de la forma xxx.xxx.xxx.xxx]';
  static CUIT = '[7-11 caracteres][Sólo números, sin guiones]';
  static CUIT_GUION = '[Con guiones][Ej: XX-XXXXXXXX-X]';
  static CUIT_GUION_OPCIONAL = '[Con o sin guiones]';

  static PRECIO = {
    ARG: '[Usar . para centavos][Hasta 2 cifras para centavos]',
    COL: '',
  };
  static PRECIO_CON_SIGNO = {
    ARG: '[Puede ser negativo][Usar . para centavos][Hasta 2 cifras para centavos]',
    COL: '[Puede ser negativo]',
  };
  static PRECIO_POSITIVO = {
    ARG: '[No puede ser $0][Usar . para centavos][Hasta 2 cifras para centavos]',
    COL: '[No puede ser $0]',
  };

  static PORCENTAJE = '[Hasta 2 cifras decimales]';
  static PORCENTAJE_CON_SIGNO =
    '[Puede ser negativo][Hasta 2 cifras decimales]';
  static PESO = '[Usar . para gramos][Ej: 500gr = 0.5kg]';
  static TELEFONO = '[6-15 caracteres][Símbolos permitidos: + ]';
  static TELEFONO_COMPUESTO =
    '[6-25 caracteres][Símbolos permitidos: + ( ) - ]';
  static RED_SOCIAL =
    '[Hasta 100 caracteres][Símbolos permitidos: - _ @ . / ? # &]';
  static DESCRIPCION_0_200 = '[Hasta 200 caracteres]';
  static DESCRIPCION_0_500 = '[Hasta 500 caracteres]';
  static DESCRIPCION_0_1000 = '[Hasta 1000 caracteres]';
  static DESCRIPCION_0_5000 = '[Hasta 5000 caracteres]';
  static DESCRIPCION_20_200 = '[20-200 caracteres]';
  static DESCRIPCION_20_500 = '[20-500 caracteres]';
  static DESCRIPCION_20_1000 = '[20-1000 caracteres]';
  static DESCRIPCION_20_5000 = '[20-5000 caracteres]';
  static DESCRIPCION_10_5000 = '[10-5000 caracteres]';
  static ANYTHING = '';
  static TODOPAGO_MERCHANT_ID = '[Sólo números]';
  static TODOPAGO_API_KEY =
    '[Comienza con TODOPAGO, seguido de una cadena alfanumérica]';
  static OCA_NROCUENTA = '[Son de la forma XXXXXX/XXX]';
  static OCA_NROOPERATIVA = '[Son 6 números]';
  static SIX_DIGIT = '';
  static CATEGORIA_NOMBRE = '[2-35 caracteres][Símbolos permitidos: - ]';
  static GOOGLE_ANALYTICS = '[8-30 Caracteres]';
  static DOMINIO = '[Hasta 100 caracteres]';
  static REGISTRO_NOMBRE = '[Hasta 100 caracteres]';
  static REGISTRO_A = '[Debe ser una IPv4 la forma xxx.xxx.xxx.xxx]';
  static REGISTRO_MX =
    '[Ejemplo: 10 mx.proveedor.com][Si no se te indico un numero de prioridad, colocá 0][Si son más de uno separar con enter]';
  static REGISTRO_CNAME = '[Debe ser un dominio]';
  static REGISTRO_TXT = '[Si es más de un valor, debes seprarlos con enter]';
  static LINK =
    '[2-200 caracteres][Debe comenzar con http:// o https://][Símbolos permitidos: . : / # - _ ? = & $ % @ ~ !]';
  static NOMBRE_PRODUCTO =
    '[2-80 caracteres][Símbolos permitidos: / - ! \' " , . + [ ] ( ) : #]';
  static SKU = '[1-50 Caracteres][Símbolos permitidos: - , . ]';
  static ATRIBUTO = '[2-25 Caracteres][Sólo letras y números]';
  static VALOR_ATRIBUTO =
    '[1-25 Caracteres][Símbolos permitidos: / - ! \' " , . + [ ] ( ) : # ]';
  static CUPON_DESCUENTO = '[2-30 Caracteres][Símbolos permitidos: -]';
  static COLOR = 'Los colores deben estar en hexcode';
  static PINTEREST_TAG =
    '[Ej: <meta name="p:domain_verify" content="tucodigo"/>]';
  static DOCUMENTO_COLOMBIA = 'Ingresa entre 5 y 10 dígitos.';
  static DOCUMENTO_COLOMBIA_PPT = 'Ingresa entre 4 y 7 dígitos.';
  static NOMBRE_3_45 = {
    ARG: this.STRING_GENERICO_2_90,
    COL: '',
  };
  static APELLIDO_3_45 = {
    ARG: this.STRING_GENERICO_2_90,
    COL: '',
  };
}

export { Regex, RegexExtra };
