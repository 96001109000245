import { Grid } from '@mui/material';
import React from 'react';
import { InfoText } from '../InfoText';
import { InputUI, SelectUI } from '../../../../components/common';
import { InputColorCircle } from '../InputColorCircle';
import { purchaseInformationIconOptions } from '../../jsonSelects';

export const PurchaseInformation = ({
  block,
  handleChangeBlock,
  handleChangeSelectBlock,
}) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText title="Item 1" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.icon_1}
              options={purchaseInformationIconOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title_1}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text_1}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Item 2" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.icon_2}
              options={purchaseInformationIconOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title_2}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text_2}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Item 3" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.icon_3}
              options={purchaseInformationIconOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title_3}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text_3}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Adicionales" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.background}
              adornment={
                <InputColorCircle color={block.bl_data.background.value} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.text_color}
              adornment={
                <InputColorCircle color={block.bl_data.text_color.value} />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
