export const salesListSelects = {
  v_estado_pago: [
    {
      value: -2,
      label: 'Todos',
    },
    {
      value: -1,
      label: 'Esperando pago',
    },
    {
      value: 0,
      label: 'Pendientes',
    },
    {
      value: 1,
      label: 'Finalizados',
    },
    {
      value: 2,
      label: 'En proceso',
    },
    {
      value: 3,
      label: 'En mediación',
    },
    {
      value: 4,
      label: 'Rechazado',
    },
    {
      value: 5,
      label: 'Cancelado',
    },
    {
      value: 6,
      label: 'Pago devuelto',
    },
    {
      value: 7,
      label: 'Contracargo',
    },
  ],
  v_metodo_pago: [
    {
      label: 'Todos',
      value: 0,
    },
    {
      label: 'Ualá Bis',
      value: 7,
    },
    {
      label: 'Mercado Pago',
      value: 1,
    },
    {
      label: 'Efectivo',
      value: 2,
    },
    {
      label: 'Acordar',
      value: 3,
    },
    {
      label: 'Todo Pago',
      value: 4,
    },
    {
      label: 'Transferencia',
      value: 5,
    },
    {
      label: 'Mobbex',
      value: 6,
    },
  ],
  v_metodo_envio: [
    {
      value: 0,
      label: 'Todos',
    },
    {
      value: 1,
      label: 'Mercado Envíos',
    },
    {
      value: 2,
      label: 'Retiro en local',
    },
    {
      value: 3,
      label: 'Acordar',
    },
    {
      value: 4,
      label: 'Andreani (Emprepack)',
    },
    {
      value: 5,
      label: 'Envíos personalizados',
    },
    {
      value: 6,
      label: 'OCA',
    },
    {
      value: 7,
      label: 'Email',
    },
    {
      value: 8,
      label: 'Correo Argentino',
    },
    {
      value: 9,
      label: 'E-Pick',
    },
  ],
  v_estado_envio: [
    {
      value: -1,
      label: 'Todos',
    },
    {
      value: 0,
      label: 'Envíos pendientes',
    },
    {
      value: 1,
      label: 'Envíos en preparación',
    },
    {
      value: 2,
      label: 'Enviados / Retirados',
    },
  ],
  v_archivada: [
    {
      label: 'Todas',
      value: -1,
    },
    {
      label: 'No archivadas',
      value: 0,
    },
    {
      label: 'Archivadas',
      value: 1,
    },
  ],
  v_mayorista: [
    {
      value: -1,
      label: 'Todas',
    },
    {
      value: 0,
      label: 'Minoristas',
    },
    {
      value: 1,
      label: 'Mayoristas',
    },
  ],
  v_factura: [
    {
      value: -1,
      label: 'Todas',
    },
    {
      value: 0,
      label: 'Sin facturar',
    },
    {
      value: 1,
      label: 'Facturadas',
    },
  ],
  v_filtrar_fecha: [
    {
      value: 0,
      label: 'No',
    },
    {
      value: 1,
      label: 'Sí',
    },
  ],
};
