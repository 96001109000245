import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../../../components/common';

const ModalAbonarEnvio = (props) => {
  let { abonar_envio, handleSubmit, modalHandler, modal_state, form_state } =
    props;

  return (
    <ModalUI
      open={modal_state}
      id="abonar_envio"
      title={abonar_envio.modal_title.value}
      modalHandler={modalHandler}
      aditional_param="abonar_envio"
      handleSubmit={handleSubmit}
      button_label={abonar_envio.modal_button.value}
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>{abonar_envio.modal_subtitle.value}</Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalAbonarEnvio };
