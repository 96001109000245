import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BorderLinearProgress } from './styles';

const LinearProgress = ({ value, mb, ...rest }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} mb={mb}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" value={value} {...rest} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          sx={{ fontSize: '1rem' }}
          variant="body2"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgress;
