/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = {
  t_nombre: '',
  d_nombre: '',
};

const authReducer = (state = initState, action) => {
  let local;
  switch (action.type) {
    case Reducers.AUTH_SET:
      localStorage.setItem(
        'auth',
        JSON.stringify({
          t_nombre: action.data.t_nombre,
          d_nombre: action.data.d_nombre,
        })
      );

      return {
        ...state,
        t_nombre: action.data.t_nombre,
        d_nombre: action.data.d_nombre,
      };

    case Reducers.AUTH_ACTUALIZAR_NOMBRE:
      local = JSON.parse(localStorage.getItem('auth'));
      local = {
        ...local,
        t_nombre: action.data.t_nombre,
      };
      localStorage.setItem('auth', JSON.stringify(local));

      return {
        ...state,
        t_nombre: action.data.t_nombre,
      };

    case Reducers.AUTH_ACTUALIZAR_DOMINIO:
      local = JSON.parse(localStorage.getItem('auth'));
      local = {
        ...local,
        d_nombre: action.data.d_nombre,
      };
      localStorage.setItem('auth', JSON.stringify(local));

      return {
        ...state,
        d_nombre: action.data.d_nombre,
      };

    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { authReducer };
