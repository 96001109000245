import React from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
import { fontOptions } from '../jsonSelects';

export const SelectFont = ({
  data,
  handleChangeSelect,
  value_atribute = 'value',
  label_atribute = 'label',
}) => {
  let { error, id, name, label, value, change_param = null, messages } = data;

  const forceUpdateAndRenderWithFirstOption = (
    handleChangeSelect,
    value,
    name,
    change_param
  ) => {
    let fake_e = {
      target: {
        value: value,
        name: name,
      },
    };
    handleChangeSelect(fake_e, change_param);
  };

  const renderValue = (value, fontOptions, value_atribute, label_atribute) => {
    if (!fontOptions.length) {
      return '';
    } else {
      let selected_value = fontOptions.filter(
        (opt) => value === opt[value_atribute]
      )[0];
      return (
        <Typography component="option" fontFamily={selected_value.font_family}>
          {selected_value[label_atribute]}
        </Typography>
      );
    }
  };

  //if value null and there is options
  //force the first option of array
  //to be choosen and update data.value
  //with its value
  if (value === null && fontOptions.length) {
    forceUpdateAndRenderWithFirstOption(
      handleChangeSelect,
      fontOptions[0][value_atribute],
      name,
      change_param
    );
  }

  //if value null, bypass first render and prevent null warning
  value = value === null ? '' : value;

  return (
    <TextField
      select
      id={id}
      fullWidth
      name={name}
      error={error}
      label={label}
      value={value}
      margin="dense"
      variant="outlined"
      SelectProps={{
        native: false,
        renderValue: (value) =>
          renderValue(value, fontOptions, value_atribute, label_atribute),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) => handleChangeSelect(e, change_param)}
      helperText={error ? messages.error : messages.help}
    >
      {fontOptions.map((option) => {
        return (
          <MenuItem
            value={option[value_atribute]}
            key={option[value_atribute]}
            style={{ fontFamily: option.font_family }}
          >
            {option[label_atribute]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
