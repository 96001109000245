import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const CustomCardUI_ = (props) => {
  let { classes, image, title, subtitle, info, children } = props;

  const [help, setHelp] = useState(false);

  const setOpenHelp = () => {
    setHelp(!help);
  };

  return (
    <Card className={classes.card}>
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <div>
          <CardActionArea
            classes={{ focusHighlight: classes.card_action_area }}
            onClick={() => setOpenHelp()}
          >
            <CardMedia
              className={classes.card_media}
              image={image}
              component="img"
            />
            <CardContent className={classes.card_content}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.card_header_title}
              >
                {title}
                <Icon className={classes.card_header_icon}>help_outline</Icon>
              </Typography>
              <Typography
                gutterBottom
                component="p"
                className={classes.card_subheader}
              >
                {subtitle}
              </Typography>
            </CardContent>
          </CardActionArea>
        </div>
        <div
          className={`${classes.card_reveal} ${
            help ? classes.card_reveal_show : ''
          }`}
        >
          <CardContent className={classes.card_content}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.card_header_title}
            >
              {title}
              <Icon
                className={classes.card_header_icon}
                onClick={() => setOpenHelp()}
              >
                clear
              </Icon>
            </Typography>
            <Typography variant="body1" className={classes.card_info_text}>
              {info}
            </Typography>
          </CardContent>
        </div>
      </div>

      <Divider />
      <CardActions className={classes.card_actions}>
        <Grid container spacing={0}>
          <Grid item xs={12} px={0.5}>
            {children}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

const styles = (theme) => ({
  card: {
    ...theme.card.card,
    position: 'relative',
  },
  card_header: theme.card.card_header,
  card_header_title: {
    fontSize: '1.1rem',
    fontWeight: 300,
    marginBottom: 0,
  },
  card_action_area: {
    backgroundColor: 'inherit',
  },
  card_content: theme.card.card_content,
  card_reveal: {
    width: '100%',
    borderRadius: '4px',
    position: 'absolute',
    top: '100%',
    height: '100%',
    backgroundColor: '#fff',
    transform: 'translateY(0%)',
    transition: theme.transitions.create('all', { duration: '500ms' }),
  },
  card_reveal_show: {
    transform: 'translateY(-100%)',
  },
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_media: {
    width: 'auto',
    display: 'block',
    margin: 'auto',
    height: '150px',
  },
  card_header_icon: {
    cursor: 'pointer',
    float: 'right',
    fontSize: 'inherit',
    height: 'auto',
    lineHeight: 'inherit',
  },
  card_info_text: {
    marginTop: '20px',
  },
});

export const CustomCardUI = withStyles(styles)(CustomCardUI_);
