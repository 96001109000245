import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const localObtenerLocales = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/local')
      .then((resp) => {
        //guardo en redux
        const locales = resp.data.data;
        dispatch({ type: Reducers.LOCAL_GUARDAR, data: locales });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const localAgregar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/local', data)
      .then((resp) => {
        //guardo en redux
        const nuevo_local = resp.data.data;
        dispatch({ type: Reducers.LOCAL_AGREGAR, data: nuevo_local });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const localEditar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/local/${data.idLocales}`, data)
      .then((resp) => {
        //guardo en redux
        const local_editado = resp.data.data;
        dispatch({ type: Reducers.LOCAL_EDITAR, data: local_editado });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const localBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(`${Config.BACKEND_ENDPOINT}/local/${data.idLocales}`)
      .then((resp) => {
        //guardo en redux
        const local_borrado = resp.data.data;
        dispatch({ type: Reducers.LOCAL_BORRAR, data: local_borrado });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export { localObtenerLocales, localAgregar, localEditar, localBorrar };
