/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const ventaReducer = (state = initState, action) => {
  let venta_editada, ventas;
  switch (action.type) {
    case Reducers.VENTA_GUARDAR:
      ventas = action.data;
      return ventas;

    case Reducers.VENTA_GUARDAR_NO_CLEAN:
      ventas = action.data;
      return [...state, ...ventas];

    case Reducers.VENTA_ARCHIVAR:
      venta_editada = action.data;
      ventas = state.filter(
        (venta) => venta.idVentas !== venta_editada.idVentas
      );
      return ventas;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { ventaReducer };
