import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Card, CardContent, Grid } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import {
  AlertUI,
  ButtonUI,
  InputUI,
  ModuleButtonBoxUI,
  ModuleTitleUI,
} from '../../components/common';
import {
  tiendaActualizarEtiquetasHtml,
  tiendaObtener,
} from '../../store/actions';

const HtmlTagsPage = ({ classes, getShop, updateHtmlTags }) => {
  const [scripts, setScripts] = useState({
    t_script_head: {
      auth: true,
      validate: true,
      required: false,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Etiquetas HTML dentro de <head>',
      id: 't_script_head',
      name: 't_script_head',
      change_param: 'scripts',
      regex: Regex.DESCRIPCION_0_5000,
      value: '',
      default_value: '',
      placeholder: 'Pega tu código para <head> acá',
      messages: {
        error: 'Ingrese un código válido',
        help: 'Las etiquetas <meta>, <link> y <script> ingresadas aquí seran colocadas dentro de la etiquetas <head> de tu tienda.',
        error_extra: RegexExtra.DESCRIPCION_0_5000,
      },
    },
    t_script_body: {
      auth: true,
      validate: true,
      required: false,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Etiquetas HTML dentro de <body>',
      id: 't_script_body',
      name: 't_script_body',
      change_param: 'scripts',
      regex: Regex.DESCRIPCION_0_5000,
      value: '',
      default_value: '',
      placeholder: 'Pega tu código para <body> acá',
      messages: {
        error: 'Ingrese un código válido',
        help: 'Las etiquetas ingresadas aquí seran colocadas al final de la etiqueta <body> de tu tienda.',
        error_extra: RegexExtra.DESCRIPCION_0_5000,
      },
    },
  });
  const [loadingTienda, setLoadingTienda] = useState(true);
  const [loadingScripts, setLoadingScripts] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  const shop = useSelector((state) => state.tienda);

  useEffect(() => {
    getShop((err, resp) => {
      if (err) {
        setLoadingTienda(false);
        setError(true);
      } else {
        const shopAux = shop && resp.data;
        Validate.cargar_formulario_sin_nulos(
          shopAux,
          scripts,
          (scripts_obj) => {
            setScripts(scripts_obj);
            setLoadingTienda(false);
          }
        );
      }
    });
  }, []);

  const handleChange = (e, blur, _aditional) => {
    const { name, value } = e.target;
    const newScriptsValues = {
      ...scripts,
      [name]: {
        ...scripts[name],
        value: value,
      },
    };

    Validate.validate_input(newScriptsValues[name], blur, (input) => {
      setScripts((prevState) => ({
        ...prevState,
        [name]: input,
      }));
    });
  };

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingScripts(true);

    const form = scripts;

    if (Validate.validar_formulario(form)) {
      updateHtmlTags(
        {
          t_script_head: form.t_script_head.value,
          t_script_body: form.t_script_body.value,
        },
        (err, resp) => {
          setAlert({
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          });

          setLoadingScripts(false);
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setScripts(new_form);
        setLoadingScripts(false);
      });
    }
  };

  return (
    <WrapperLayout loading={loadingTienda} error={error}>
      <AlertUI
        open={alert.open}
        type={alert.type}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <ModuleTitleUI
              title="Etiquetas HTML"
              subtitles={[
                'Esta función te permite agregar etiquetas HTML en tu tienda online.',
                'Las etiquetas HTML te la proporcionan diferentes plataformas de terceros, por ejemplo: Google Adsense, Jivochat, Mailchimp, Doppler, etc.',
                'Luego de colocar tus etiquetas, recomendamos revisar tu tienda ya que una etiqueta HTML mal puesta podría ocacionar problemas visuales y/o de funcionamiento en tu tienda. En dicho caso te recomendamos que la borres y te comuniques con nosotros para asistirte.',
              ]}
            />
            <Card className={classes.card}>
              <CardContent className={classes.card_content}>
                <Grid container>
                  <Grid item xs={12} pb={3}>
                    <InputUI
                      handleChange={handleChange}
                      multiline
                      input={scripts.t_script_head}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={handleChange}
                      multiline
                      input={scripts.t_script_body}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <ModuleButtonBoxUI>
              <ButtonUI
                type="submit"
                label="Guardar"
                minHeight={true}
                isLoading={loadingScripts}
              />
            </ModuleButtonBoxUI>
          </form>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
    updateHtmlTags: (data, callback) =>
      dispatch(tiendaActualizarEtiquetasHtml(data, callback)),
  };
};

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(HtmlTagsPage)
);
