import React from 'react';
import { ToggleUI } from '../../common';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const InfoItem = ({ classes, bill, show, setOpenSetIdPayment }) => {
  const { id, paga, monto, setBillId } = bill;

  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const handleOnClick = (e) => {
    e.preventDefault();
    setBillId && setBillId(id);
    setOpenSetIdPayment(true);
  };

  return (
    <>
      <ToggleUI show={show.monto}>
        <>
          <span className={classes.card_details}>Monto: ${monto}</span>
          <br />
        </>
      </ToggleUI>
      <span className={classes.card_details}>
        {idCountry === 'ARG' ? 'Pagado' : 'Abonado'}: {paga ? 'Sí' : 'No'}
      </span>
      <ToggleUI show={show.infoPago && !paga && idCountry !== 'COL'}>
        <>
          <br />
          <a onClick={handleOnClick} className={classes.link} href="#">
            INFORMAR PAGO
          </a>
        </>
      </ToggleUI>
    </>
  );
};

const styles = () => ({
  link: {
    fontSize: '0.87rem',
    fontWeight: '400',
    lineHeight: '14px',
    color: '#A5A5A5',
    textDecoration: 'underline !important',
  },
  card_details: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '19px',
    color: '#A5A5A5',
  },
});

export default withStyles(styles)(InfoItem);
