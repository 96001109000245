import React from 'react';
import { ToggleUI, MessageUI } from '../../../common';

const AlertaOfertaContent = ({ producto_data }) => {
  let mostrar_mensaje = producto_data.stock.filter(
    (stock) => stock.s_oferta >= 2
  ).length
    ? true
    : false;

  let mensaje =
    'Éste producto tiene configurada una oferta con fecha determinada. Si se edita el precio de oferta la misma pasará a ser de tiempo ilimitado.';
  mensaje += producto_data.p_atributos
    ? ' Si agregas nuevas variantes con precios de ofertas, éstas serán de tiempo ilimitado. Las demás (si es que no se editan) mantendrán su oferta con fecha determinada.'
    : '';

  return (
    <ToggleUI show={mostrar_mensaje}>
      <MessageUI
        type="warning"
        message={mensaje}
        close_button={false}
        show_icon={false}
      />
    </ToggleUI>
  );
};

const AlertaOferta = ({ producto_data }) => {
  return (
    <ToggleUI show={producto_data !== null}>
      <AlertaOfertaContent producto_data={producto_data} />
    </ToggleUI>
  );
};

export { AlertaOferta };
