/**Formato acordado con equipo de Backend */

export class IMPORT_ERROR_CODE_MESSAGE {
  static ERR000 =
    'El formato de la información no es correcto. Debe ser una lista da datos.';
  static ERR001 = 'Los datos del producto no cumplen con el formato esperado.';
  static ERR002 =
    'Está enviando información extra del producto que no ha sido solicitada.';
  static ERR003 =
    'Ha especificado información opcional sobre el producto que es incorrecta.'; //qué diferencia hay con el anterior?
  static ERR004 =
    'Envió datos incorrectos respecto a las variantes del producto, debería ser una lista de datos.';
  static ERR005 =
    'Está enviando información extra sobre una variante del producto que no ha sido solicitada.';
  static ERR006 = '"IDProduct" debe ser un número entero positivo o cero.';
  static ERR007 = 'El campo “IDProduct” no debe contener letras ni signos.';
  static ERR008 = '"Nombre" debe ser un texto.';
  static ERR009 =
    'El dato ingresado en campo “Nombre” no es válido. Debe contener de 2 a 80 caracteres. Los  símbolos permitidos son: / - ! \' " , . + [ ] ( ) : #.';
  static ERR010 =
    'El formato de los datos en "Precio" y "Precio mayorista" es incorrecto.';
  static ERR011 =
    'Indicó una combinación inválida para los datos "Precio" y "Precio mayorista"';
  static ERR012 = '"Categorías” no cumple con el formato esperado.';
  static ERR013 = '"Categorías” no cumple con el formato esperado.';
  static ERR014 = '"Categorías” no cumple con el formato esperado.';
  static ERR015 =
    'Hay un error en “Categorías”. Deben estar separadas por “>” seguido de espacio. Ej: Indumentaria > Remeras.';
  static ERR016 =
    'Alguno de los datos "Nombre atributo (1, 2 ó 3)" no cumple con el formato esperado';
  static ERR017 = 'La cantidad de atributos propuestos excede la permitida.';
  static ERR018 =
    'Alguno de los datos "Nombre atributo (1, 2 ó 3)" no cumple con el formato esperado';
  static ERR019 =
    'Hay un error en “Nombre de atributo” (1, 2 o 3). Los textos deben tener de 2 a 25 caracteres, sin símbolos.';
  static ERR020 =
    'Alguno de los datos en los campos “Nombre de atributo” (1, 2 o 3) está repetido. Ingresaste el mismo nombre de atributo en varios campos.';
  static ERR021 =
    'El formato de la información sobre atributos del producto es incorrecto';
  static ERR022 =
    'Colocaste un valor en alguno de los atributos, pero no completaste el “Nombre de atributo”.';
  static ERR023 =
    'Hay inconsistencias entre los nombres y los valores de los atributos.';
  static ERR024 =
    'Alguno de los datos "Valor atributo (1, 2 ó 3)" no cumple con el formato esperado.';
  static ERR025 =
    'Hay un error en algún campo “Valor atributo” (1, 2 o 3). Debe contener de 1 a 25 caracteres. Los símbolos permitidos son: / - ! \' " , . + [ ] ( ) : #.';
  static ERR026 = '"IDStock" no cumple con el formato esperado.';
  static ERR027 =
    'Hay un error el campo “IDStock”. No debe contener números negativos ni letras.';
  static ERR028 = '"Stock" no cumple con el formato esperado';
  static ERR029 =
    'Hay un error en el campo “Stock”. No debe contener números negativos ni letras.';
  static ERR030 = '"Stock" no cumple con el formato esperado';
  static ERR031 = '"Stock" no es un valor adecuado.';
  static ERR032 = '"SKU" no cumple con el formato esperado.';
  static ERR033 =
    'Hay un error en el campo “SKU”. Debe tener de 1 a 50 caracteres. Los únicos símbolos permitidos son - , .';
  static ERR034 = '"Precio" no cumple con el formato esperado.';
  static ERR035 =
    'Hay un error en el campo “Precio”. El valor no puede ser 0 y no debe contener números negativos.';
  static ERR036 =
    'Error al interpretar si se debe mostrar o no el precio del producto.';
  static ERR037 =
    'Error al interpretar si se debe mostrar o no el precio del producto';
  static ERR038 = '"Precio oferta" no es un valor adecuado.';
  static ERR039 =
    'Hay un error en el campo “Precio oferta”. El valor no puede ser 0 y no debe contener números negativos.';
  static ERR040 = '"Precio mayorista" no cumple con el formato esperado.';
  static ERR041 =
    'Hay un error en el campo “Precio mayorista”. El valor no puede ser 0 y no debe contener números negativos.';
  static ERR042 = '"Peso" no cumple con el formato esperado.';
  static ERR043 =
    'Hay un error en el campo “Peso”. No debe contener números negativos. El valor se expresa en kg.';
  static ERR044 = '"Alto" no cumple con el formato esperado.';
  static ERR045 =
    'Hay un error en el campo “Alto”. No debe contener números negativos. El valor se expresa en cm.';
  static ERR046 = '"Ancho" no cumple con el formato esperado.';
  static ERR047 =
    'Hay un error en el campo “Ancho”. No debe contener números negativos. El valor se expresa en cm.';
  static ERR048 = '"Profundidad" no cumple con el formato esperado.';
  static ERR049 =
    'Hay un error en el campo “Profundidad”. No debe contener números negativos. El valor se expresa en cm.';
  static ERR050 =
    'Ya existe un producto con ese nombre en tu tienda y no es posible repetir.';
  static ERR051 =
    'El texto de tu descripción es demasiado largo. No debe superar los 20.000 caracteres.';
  static ERR052 = '"Mostrar en tienda" no cumple con el formato esperado.';
  static ERR053 = '"Mostrar en tienda" no es un valor adecuado.';
  static ERR054 =
    'Para editar una variante de un producto debe indicar ambos valores: "IDProduct" y "IDStock".';
  static ERR055 = '"Stock" no es un valor adecuado.';
  static ERR056 =
    'Hay un conflicto entre el tipo de venta actual del producto y los nuevos valores de "Precio" y "Precio mayorista".';
  static ERR057 =
    'Hay un conflicto entre el tipo de venta actual del producto y el nuevo valor de "Precio mayorista"';
  static ERR058 =
    '"IDProduct" no corresponde con ningún producto de la tienda.';
  static ERR059 =
    'Ya existe un producto con ese nombre en tu tienda y no es posible repetir.';
  static ERR060 =
    'Este producto ya está cargado con otro tipo de venta (“Precio”, “Precio mayorista”). Tenés que mantener el tipo de venta original del producto.';
  static ERR061 =
    'El producto original tiene atributos, tenés que mantenerlos para poder editarlo.';
  static ERR062 =
    'El producto que querés editar fue cargado en tu tienda sin atributos. Debés mantener esta característica.';
  static ERR063 =
    'Este producto tiene una cantidad de atributos diferente a los que estás intentando cargar. Deben coincidir.';
  static ERR064 =
    'Este producto tiene atributos diferentes a los que querés cargar. Deben coincidir.';
  static ERR065 =
    'La variante de producto que desea editar no existe para este producto.';
  static ERR066 =
    'Este producto está cargado en tu tienda con precio. Podés editarlo con un valor mayor a 0 y sin números negativos.';
  static ERR067 =
    'Hay un error en “Nombre de atributo” (1, 2 o 3). Ingresaste el mismo nombre de atributo en varios campos.';
  static ERR068 =
    'Al menos una de las variantes del producto que querés crear ya existe en tu tienda y no es posible repetir.';
  static ERR069 =
    'Ya existe al menos una variante de este producto con el mismo nombre en tu tienda y no es posible repetir.';
  static ERR070 =
    'Hay un error en “Nombre de atributo” (1, 2 o 3). Ingresaste un nombre de atributo diferente a los que tiene el producto que querés editar.';
  static ERR071 =
    'Recordá que no tenés que editar los campos “IDProduct” ni “IDStock”. Al cargar un nuevo producto, estos campos deben permanecer vacíos.';
  static ERR072 =
    'No es posible editar productos digitales desde la importación masiva.';
  static ERR073 = '"Descripción" no cumple con el formato esperado.';
  static ERR074 =
    'No es posible poner en el campo “Precio oferta” un valor mayor o igual al colocado en “Precio”.';
  static ERR075 = '"Categorías” no cumple con el formato esperado.';
  static ERR076 =
    'Todas las variantes de un mismo producto deben tener precio asignado. Otra opción es que ninguna tenga precio.';
  static ERR077 =
    'La lista de variantes del producto no cumple con el formato esperado.';
  static ERR078 =
    'Este producto está cargado en tu tienda sin atributos. En la importación masiva, no es posible sumar variantes.';
  static ERR079 =
    'Hay errores en los datos sobre las filas que está solicitando procesar.';
  static ERR080 =
    'Hay errores en los datos sobre las filas que está solicitando procesar.';
  static ERR081 =
    'Hay errores en los datos sobre las filas que está solicitando procesar.';
  static ERR082 =
    'Recordá que no tenés que editar el campo “IDStock”. Al cargar un nuevo producto, este campo debe permanecer vacío.';
  static ERR083 =
    'La variante de producto está repetida en el archivo y no es posible repetir variantes.';
  static ERR084 =
    'El valor de "Categorías" puede generar conflictos con categorías existentes en su tienda ';
  static ERR085 =
    'Tuvimos un problema al procesar el producto. Por farvor, volvé a intentarlo.';
  static ERR086 =
    'No es posible crear una variante para este producto, porque el producto original no tiene atributos.';
  static ERR087 =
    'Las dimensiones (alto, ancho, profundidad y peso) son opcionales. Tenés que indicar un valor para todas ellas o dejar todas vacías.';
  //errores específicos de frontend
  static ERR400 =
    'Tenés que mantener el mismo "IDProduct" para todas las variantes de un producto.';
  static ERR401 =
    'Se debe mantener el "Nombre atributo 1" para todas las variantes de un producto.';
  static ERR402 =
    'Se debe mantener el "Nombre atributo 2" para todas las variantes de un producto.';
  static ERR403 =
    'Se debe mantener el "Nombre atributo 3" para todas las variantes de un producto.';
  static ERR404 =
    'Las dimensiones (Alto, Ancho, Profundidad y Peso) son requeridas para su tienda; indique un valor para todas ellas o deje todas vacias';
}

//pasar el formato de objeto a un Map
export const convertErrorCodesToMap = () => {
  const error_codes = Object.getOwnPropertyNames(IMPORT_ERROR_CODE_MESSAGE);
  let MAP_INDEX_TO_MESSAGE = new Map();
  for (const error_code of error_codes) {
    const error_code_number = Number(error_code.substring(3));
    MAP_INDEX_TO_MESSAGE.set(
      error_code_number,
      IMPORT_ERROR_CODE_MESSAGE[error_code]
    );
  }
  return MAP_INDEX_TO_MESSAGE;
};

export const IMPORT_ERROR_MAP_NUMBER_TO_MESSAGE = convertErrorCodesToMap();
