import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  Icon,
  Toolbar,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI, ModalUI, ToggleUI } from '../../../components/common';
import {
  design_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import { IconButtonUI } from '../../../components/common/IconButtonUI';

const NavigationLayout_ = ({
  classes,
  deviceActive,
  handleSubmit,
  openCloseDrawer,
  handleUpdatePreview,
  handleChangePreviewWidth,
  handleChangeDeviceActive,
}) => {
  const [modal_state, setModalState] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const router = useHistory();

  const handleDeviceClick = (device) => {
    handleChangeDeviceActive(device);
    handleChangePreviewWidth(device);
  };

  const handleModalOpen = () => {
    setModalState(!modal_state);
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e, true);
  };

  return (
    <>
      <ModalUI
        id="salir_editor"
        open={modal_state}
        button_label="Guardar"
        title="Salir del editor"
        modalHandler={handleModalOpen}
        aditional_param="salir_editor"
        handleSubmit={handleModalSubmit}
        cancel_button_label="Salir sin guardar"
        onClickCancelButton={() => router.push('/')}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography>
              ¿Querés salir? Si tenés cambios sin guardar, se perderán.
            </Typography>
          </Grid>
        </Grid>
      </ModalUI>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className={classes.toolbar_left_container}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={openCloseDrawer}
                className={classes.menuButton}
                size="large"
              >
                <Icon>menu</Icon>
              </IconButton>
              <ToggleUI show={isMobile}>
                <Button
                  color="inherit"
                  classes={{ root: classes.navbarButton }}
                  onClick={() =>
                    window.open(
                      'https://empretienda.helpjuice.com/es_AR/dise%C3%B1o',
                      '_blank',
                      'noopener'
                    )
                  }
                >
                  <Icon style={{ marginRight: 5 }}>error_outline</Icon>{' '}
                  <p>Tutoriales</p>
                </Button>
              </ToggleUI>
              <ToggleUI show={!isMobile}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Button
                    color="inherit"
                    classes={{ root: classes.navbarButton }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleModalOpen();
                      createAmplitudeEventWithDevice(
                        design_amplitude_events.diseño_selecciona_salir
                          .event_name
                      );
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>logout</Icon>{' '}
                    <p>Salir de diseño</p>
                  </Button>
                </Box>
              </ToggleUI>
            </Box>
            <ToggleUI show={!isMobile}>
              <Box
                flex={1}
                display="flex"
                justifyContent="left"
                alignItems="center"
              >
                <Button
                  color="inherit"
                  classes={{ root: classes.navbarButton }}
                  style={{ paddingLeft: '24px', paddingRight: '24px' }}
                  onClick={() =>
                    window.open(
                      'https://empretienda.helpjuice.com/es_AR/dise%C3%B1o',
                      '_blank',
                      'noopener'
                    )
                  }
                >
                  <Icon style={{ marginRight: 5 }}>error_outline</Icon>{' '}
                  <p>Tutoriales</p>
                </Button>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  right: '18px',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButtonUI
                  aria-label="Computer"
                  isActive={deviceActive === 'desktop'}
                  onClick={() => handleDeviceClick('desktop')}
                >
                  <Icon classes={{ root: classes.action_icon }}>
                    laptop_mac
                  </Icon>
                </IconButtonUI>
                <IconButtonUI
                  aria-label="Smartphone"
                  isActive={deviceActive === 'mobile'}
                  onClick={() => handleDeviceClick('mobile')}
                >
                  <Icon classes={{ root: classes.action_icon }}>
                    smartphone
                  </Icon>
                </IconButtonUI>
              </Box>
            </ToggleUI>
            <Box className={classes.toolbar_right_container}>
              <ToggleUI show={!isMobile}>
                <Button
                  variant="outlined"
                  onClick={async (e) => {
                    handleUpdatePreview();
                    createAmplitudeEventWithDevice(
                      design_amplitude_events.diseño_selecciona_ver_cambios
                        .event_name
                    );
                  }}
                  sx={{
                    marginRight: '18px',
                    minHeight: '40px',
                    minWidth: '158px',
                  }}
                >
                  Ver cambios
                </Button>
                <ButtonUI
                  type="callf"
                  onClickFunc={handleSubmit}
                  fullWidth={false}
                  label="Guardar"
                  size="small"
                  classes={{ root: classes.buttonSubmit }}
                />
              </ToggleUI>
              <ToggleUI show={isMobile}>
                <IconButton
                  aria-label="ExitBuilder"
                  classes={{ root: classes.action_button }}
                  onClick={handleSubmit}
                >
                  <Icon
                    classes={{
                      root: classes.action_icon_save,
                    }}
                  >
                    save
                  </Icon>
                </IconButton>
                <IconButton
                  aria-label="ExitBuilder"
                  classes={{ root: classes.action_button }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleModalOpen();
                    createAmplitudeEventWithDevice(
                      design_amplitude_events.diseño_selecciona_salir.event_name
                    );
                  }}
                  size="large"
                >
                  <Icon
                    classes={{
                      root: classes.action_icon_exit,
                    }}
                  >
                    logout
                  </Icon>
                </IconButton>
              </ToggleUI>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

const styles = (theme) => ({
  buttonRefresh: {
    marginRight: '18px',
    minHeight: '40px',
    minWidth: '158px',
  },
  buttonSubmit: {
    minHeight: '40px',
    minWidth: '158px',
  },
  navbarButton: {
    color: theme.texts.secondary,
    fontSize: '14px',
    fontWeight: 500,
  },
  toolbar_sidenav: {
    ...theme.mixins.toolbar,
    display: 'none',
    alignItems: 'center',
    padding: '0px 10px 0px 5px',
    color: 'rgba(0, 0, 0, 0.87)',
    justifyContent: 'space-between',
    borderBottom: '0',

    width: '100%',
    left: '-26px',
    position: 'relative',
    maxWidth: '288px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  toolbar_left_container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: '276px',
    maxWidth: '276px',
  },
  toolbar_right_container: {
    flex: 1,
    right: '10px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',

    [theme.breakpoints.up('md')]: {
      right: 0,
    },
  },
  appBar: {
    backgroundColor: '#fff',
    zIndex: 2,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  menuButton: {
    marginRight: 0,
    color: theme.texts.secondary,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  action_button: {
    padding: '6px 15px 6px 6px',
    [theme.breakpoints.up('md')]: {
      padding: '6px',
    },
  },
  action_icon: {
    fontSize: '23px',
    color: '#333',
  },
  action_icon_save: {
    fontSize: '23px',
    color: theme.palette.primary.main,
  },
  action_icon_exit: {
    fontSize: '23px',
    color: theme.texts.secondary,
  },
});

export const NavigationLayout = withStyles(styles, { withTheme: true })(
  NavigationLayout_
);
