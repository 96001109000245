import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  createAmplitudeEventWithDevice,
  on_off_store_amplitude_events,
} from '../../components/amplitude';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { AlertUI, ButtonUI, ModuleTitleUI } from '../../components/common';
import { ModalApagar } from './ModalApagar';
import { ModalEncender } from './ModalEncender';
import {
  tiendaActualizarApagarTemporalmente,
  tiendaObtener,
} from '../../store/actions';

const TurnOffTemporarilyPage_ = ({ shop, getShop, switchShopOnOrOff }) => {
  const [turnOffTemporarily, setTurnOffTemporarily] = useState({
    t_offmode_mensaje: {
      auth: true,
      validate: true,
      required: false,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Mensaje para tus clientes',
      id: 't_offmode_mensaje',
      name: 't_offmode_mensaje',
      change_param: 'apagar_temporalmente',
      regex: Regex.DESCRIPCION_0_200,
      value: '',
      default_value: '',
      placeholder: 'Estaremos cerrados hasta el ....',
      messages: {
        error: 'Por favor, ingrese un mensaje válido',
        error_extra: RegexExtra.DESCRIPCION_0_200,
      },
    },
  });

  const [loading, setLoading] = useState({
    apagar_temporalmente: true,
    error: false,
  });

  const [forms, setForms] = useState({
    apagar_temporalmente_apagar: false,
    apagar_temporalmente_encender: false,
  });

  const [modals, setModals] = useState({
    apagar_temporalmente_apagar: false,
    apagar_temporalmente_encender: false,
  });

  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    getShop((err, resp) => {
      if (err) {
        setLoading({
          ...loading,
          apagar_temporalmente: false,
          error: true,
        });
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          turnOffTemporarily,
          (apagar_temporalmente_obj) => {
            setTurnOffTemporarily(apagar_temporalmente_obj);
            setLoading({
              ...loading,
              apagar_temporalmente: false,
              error: false,
            });
          }
        );
      }
    });
  }, []);

  const handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    setTurnOffTemporarily({
      ...turnOffTemporarily,
      [name]: {
        ...turnOffTemporarily[name],
        value: value,
      },
    });

    if (blur) {
      Validate.validate_input(turnOffTemporarily[name], blur, (input) => {
        setTurnOffTemporarily({
          ...turnOffTemporarily,
          [name]: input,
        });
      });
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const modalHandler = (open, id, aditional) => {
    setModals({
      ...modals,
      [id]: open,
    });
  };

  const handleSubmitApagar = (e) => {
    e.preventDefault();

    setForms({
      ...forms,
      apagar_temporalmente_apagar: true,
    });

    let form = turnOffTemporarily;

    if (Validate.validar_formulario(form)) {
      switchShopOnOrOff(
        {
          t_offmode_mensaje: form.t_offmode_mensaje.value,
          t_offmode: 1,
        },
        (err, resp) => {
          if (err) {
            setForms({
              ...forms,
              apagar_temporalmente_apagar: false,
            });
            setAlert({
              ...alert,
              open: true,
              message: resp.message,
              type: 'error',
            });
          } else {
            setForms({
              ...forms,
              apagar_temporalmente_apagar: false,
            });
            setAlert({
              ...alert,
              open: true,
              message: resp.message,
              type: 'success',
            });
            setModals({
              ...modals,
              apagar_temporalmente_apagar: false,
            });
            createAmplitudeEventWithDevice(
              on_off_store_amplitude_events
                .mi_tienda_selecciona_apagar_tienda_panel.event_name,
              on_off_store_amplitude_events
                .mi_tienda_selecciona_apagar_tienda_panel.event_params
            );
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setForms({
          ...forms,
          apagar_temporalmente_apagar: false,
        });
        setTurnOffTemporarily(new_form);
      });
    }
  };

  const handleSubmitEncender = (e) => {
    e.preventDefault();

    setForms({
      ...forms,
      apagar_temporalmente_encender: true,
    });

    createAmplitudeEventWithDevice(
      on_off_store_amplitude_events.mi_tienda_selecciona_encender_tienda_panel
        .event_name,
      on_off_store_amplitude_events.mi_tienda_selecciona_encender_tienda_panel
        .event_params
    );

    let form = turnOffTemporarily;

    if (Validate.validar_formulario(form)) {
      switchShopOnOrOff(
        {
          t_offmode_mensaje: form.t_offmode_mensaje.value,
          t_offmode: 0,
        },
        (err, resp) => {
          if (err) {
            setForms({
              ...forms,
              apagar_temporalmente_encender: false,
            });
            setAlert({
              ...alert,
              open: true,
              message: resp.message,
              type: 'error',
            });
          } else {
            setForms({
              ...forms,
              apagar_temporalmente_encender: false,
            });
            setAlert({
              ...alert,
              open: true,
              message: resp.message,
              type: 'success',
            });
            setModals({
              ...modals,
              apagar_temporalmente_encender: false,
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setForms({
          ...forms,
          apagar_temporalmente_encender: false,
        });
        setTurnOffTemporarily(new_form);
      });
    }
  };

  return (
    <WrapperLayout loading={loading.apagar_temporalmente} error={loading.error}>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <ModalApagar
        apagar_temporalmente={turnOffTemporarily}
        handleSubmit={handleSubmitApagar}
        handleChange={handleChange}
        modalHandler={modalHandler}
        modal_state={modals.apagar_temporalmente_apagar}
        form_state={forms.apagar_temporalmente_apagar}
      />
      <ModalEncender
        handleSubmit={handleSubmitEncender}
        handleChange={handleChange}
        modalHandler={modalHandler}
        modal_state={modals.apagar_temporalmente_encender}
        form_state={forms.apagar_temporalmente_encender}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Apagar temporalmente"
            subtitles={[
              'Con esta función podrás apagar tu tienda temporalmente. Es ideal para cuando no puedas administrarla, por ejemplo cuando estás de vacaciones.',
              'Cuando la apagues, podrás definir un mensaje opcional que se va a visualizar en la tienda.',
              'Tené en cuenta que mientras la tienda se encuentre apagada no se podrán visualizar los productos.',
            ]}
            button={
              <ButtonUI
                type="callf"
                label={shop.t_offmode ? 'Encender' : 'Apagar'}
                fullWidth={false}
                onClickFunc={() =>
                  modalHandler(
                    true,
                    shop.t_offmode
                      ? 'apagar_temporalmente_encender'
                      : 'apagar_temporalmente_apagar'
                  )
                }
              />
            }
          />
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    shop: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
    switchShopOnOrOff: (data, callback) =>
      dispatch(tiendaActualizarApagarTemporalmente(data, callback)),
  };
};

const TurnOffTemporarilyPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnOffTemporarilyPage_);

export default TurnOffTemporarilyPage;
