import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const bloqueObtenerBloques = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/bloque')
      .then((resp) => {
        //guardo en redux
        const bloques = resp.data.data;
        dispatch({ type: Reducers.BLOQUE_GUARDAR, data: bloques });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export { bloqueObtenerBloques };
