import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DropdownUI, MenuItemUI, ToggleUI } from '../../common';
import { Validate } from '../../../other';

const ClientesList_ = (props) => {
  let { clientes, tienda, classes, modalHandler } = props;

  let mobile = Validate.is_mobile();

  const renderizarClientes = (clientes, modalHandler) => {
    if (clientes.length > 0) {
      let ultimas_ventas;
      return clientes.map((cliente) => {
        ultimas_ventas = cliente.ultimas_ventas.length
          ? cliente.ultimas_ventas.map((venta) => {
              return (
                <span key={venta.idVentas}>
                  <Link
                    to={'/listado-de-ventas/orden/' + venta.v_numero}
                    className={classes.link}
                  >
                    <u>{venta.v_numero}</u>
                  </Link>
                </span>
              );
            })
          : ' -';

        return (
          <ListItem key={cliente.idClientes} className={classes.list_item}>
            {!mobile ? (
              <ListItemAvatar>
                <Avatar>
                  <Icon>person</Icon>
                </Avatar>
              </ListItemAvatar>
            ) : (
              ''
            )}
            <ListItemText
              classes={{ root: classes.list_text }}
              primary={
                Validate.is_falsy(cliente.cl_nombre)
                  ? 'S/N'
                  : Validate.trim_ucwords(
                      cliente.cl_nombre + ' ' + cliente.cl_apellido
                    )
              }
              secondary={
                <span className={classes.span}>
                  <span className={classes.span}>{cliente.cl_email}</span>
                  <span className={classes.span}>
                    Tel: {cliente.cl_telefono ? cliente.cl_telefono : '-'}
                  </span>
                  <ToggleUI show={cliente.cl_bloqueado === 0}>
                    <span className={classes.span}>
                      Últimas ventas:{ultimas_ventas}
                    </span>
                  </ToggleUI>
                  <ToggleUI show={cliente.cl_bloqueado === 1}>
                    <span className={classes.span_red}>Cliente bloqueado</span>
                  </ToggleUI>
                </span>
              }
            />
            <ListItemSecondaryAction>
              <DropdownUI type="icon">
                <MenuItemUI
                  type="callf"
                  onClickFunction={() =>
                    modalHandler(true, 'ver_informacion', cliente)
                  }
                >
                  Más información del cliente
                </MenuItemUI>
                <MenuItemUI
                  type="callf"
                  show={
                    cliente.cl_bloqueado === 0 &&
                    tienda.t_mayorista &&
                    cliente.cl_mayorista === 1
                  }
                  onClickFunction={() => modalHandler(true, 'revocar', cliente)}
                >
                  Revocar acceso mayorista
                </MenuItemUI>
                <MenuItemUI
                  type="callf"
                  show={cliente.cl_bloqueado === 0}
                  onClickFunction={() => modalHandler(true, 'editar', cliente)}
                >
                  Editar cliente
                </MenuItemUI>
                <MenuItemUI
                  type="callf"
                  show={cliente.cl_bloqueado === 0}
                  onClickFunction={() =>
                    modalHandler(true, 'bloquear', cliente)
                  }
                >
                  Bloquear cliente
                </MenuItemUI>
                <MenuItemUI
                  type="callf"
                  show={cliente.cl_bloqueado === 1}
                  onClickFunction={() =>
                    modalHandler(true, 'desbloquear', cliente)
                  }
                >
                  Desbloquear cliente
                </MenuItemUI>
              </DropdownUI>
            </ListItemSecondaryAction>
          </ListItem>
        );
      });
    } else {
      return (
        <ListItem className={classes.list_item}>
          <ListItemText
            primary="No se han encontrado clientes"
            secondary="Ajuste los filtros e intente nuevamente"
          />
        </ListItem>
      );
    }
  };

  return (
    <List classes={{ root: classes.list }}>
      {renderizarClientes(clientes, modalHandler)}
    </List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  span_red: {
    display: 'block',
    width: '90%',
    color: 'red',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    padding: '0',
  },
  list_item: {
    backgroundColor: '#fff',
    boxShadow: theme.card.card.boxShadow,
    marginBottom: '20px',
    borderRadius: '4px',
  },
  list_text: {
    paddingRight: '50px',
  },
  link: {
    marginLeft: '5px',
    color: 'inherit',
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    clientes: state.clientes,
    auth: state.auth,
  };
};

const mapDispatchToProps = null;

export const ClientesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ClientesList_));
