import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../components/common';

const ModalBorrar = (props) => {
  let { borrar, handleSubmit, modalHandler, modal_state, form_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="borrar"
      title="Borrar categoría"
      modalHandler={modalHandler}
      aditional_param="borrar"
      handleSubmit={handleSubmit}
      button_label="Borrar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '1.1rem' }}>
            ¿Estás seguro de que quieres borrar la categoría "
            {borrar.c_nombre.value}"?{' '}
            <span style={{ color: 'red' }}>
              Si hay subcategorías o productos agregados en esta categoría
              también serán eliminados.
            </span>
            <br /> Recomendamos que si hay productos que no deseas borrar
            dentro, primero los muevas a otra categoría desde la edición de
            productos.
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalBorrar };
