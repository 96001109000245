import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const paginasObtener = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/pagina')
      .then((resp) => {
        //guardo en redux
        const paginas = resp.data.data;
        dispatch({ type: Reducers.PAGINA_GUARDAR, data: paginas });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const paginasObtenerPagina = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(`${Config.BACKEND_ENDPOINT}/pagina/${data.idPaginas}`)
      .then((resp) => {
        //guardo en redux
        const pagina = resp.data.data;
        dispatch({ type: Reducers.PAGINA_GUARDAR, data: pagina });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const paginasAgregar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/pagina', data)
      .then((resp) => {
        //guardo en redux
        const pagina = resp.data.data;
        dispatch({ type: Reducers.PAGINA_GUARDAR, data: pagina });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const paginasEditar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/pagina/${data.idPaginas}`, data)
      .then((resp) => {
        //guardo en redux
        const pagina = resp.data.data;
        dispatch({ type: Reducers.PAGINA_GUARDAR, data: pagina });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const paginasBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(`${Config.BACKEND_ENDPOINT}/pagina/${data.idPaginas}`, data)
      .then((resp) => {
        //guardo en redux
        const pagina = resp.data.data;
        dispatch({ type: Reducers.PAGINA_BORRAR, data: pagina });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const paginasOrdenar = (data, callback) => {
  return (dispatch, _getState) => {
    //guardo en redux
    dispatch({ type: Reducers.PAGINA_ORDENAR, data: data });
    let ids = data.map((pagina) => pagina.idPaginas);

    axios
      .put(Config.BACKEND_ENDPOINT + '/pagina/ordenar', {
        ids: ids,
      })
      .then((_resp) => {
        return callback(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true);
      });
  };
};

export {
  paginasObtener,
  paginasObtenerPagina,
  paginasAgregar,
  paginasEditar,
  paginasBorrar,
  paginasOrdenar,
};
