import React from 'react';
import uuid from 'uuid/v1';
import { Box, useTheme } from '@mui/material';

export const ShopPreview = ({ preview_width, preview_html }) => {
  const theme = useTheme();
  let html = preview_html;

  if (html) {
    let test = html.split('</head>');
    test[1] =
      '<style type="text/css">* { pointer-events: none !important; }</style></head>' +
      test[1];
    html = test.join('');
    html = html.replace('/js/main.js', '/js/mainp.js');
    html = html.replace(
      '<script src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script>',
      ''
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        maxWidth: { xs: '100%', md: 'calc(100% - 300px)' },
        boxSizing: 'border-box',
        padding: '0px',
      }}
    >
      <Box sx={{ ...theme.mixins.toolbar }} />
      <Box display="flex" justifyContent="center">
        <Box
          component="iframe"
          title="live-preview"
          id={uuid()}
          srcDoc={html}
          sx={{
            border: '0px solid transparent',
            maxWidth: preview_width === 'desktop' ? 'unset' : '500px',
            width: '100%',
            height: '100vh',
          }}
        ></Box>
      </Box>
    </Box>
  );
};
