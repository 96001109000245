import { IconButton, Box, Typography, Fade } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ButtonUI, ToggleUI } from '../../../components/common';
import ClearIcon from '@mui/icons-material/Clear';
import { BannerContainer, BannerContent, Container } from './styles';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  connectSplit,
  selectTreatmentValue,
} from '@splitsoftware/splitio-redux';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';

export const SuscriptionBanner = connectSplit()(
  ({ messages, splitio, isBannerOpen, setIsBannerOpen }) => {
    const { title, subtitle, button, showBanner, canBeClosed } = messages;

    const idTienda = useSelector((state) => state.tienda.idTienda);

    const history = useHistory();
    const [darkMode, setDarkMode] = useState(false);
    const [isSplitLoaded, setIsSplitLoaded] = useState(false);

    useEffect(() => {
      if (splitio && splitio.isReady) {
        const trment = selectTreatmentValue(
          splitio,
          'experiment_empretienda_panel_home_banner_darkmode',
          idTienda
        );

        if (trment === 'control') {
          return;
        }
        setDarkMode(trment === 'on');
        setIsSplitLoaded(true);
      }
    }, [splitio, idTienda]);

    const handleOnClick = () => {
      createAmplitudeEventWithDevice(
        amplitude_events.home_banner_selecciona_suscribirme.event_name
      );

      history.push('/plan/metodo-pago');
    };

    return (
      <ToggleUI show={showBanner && isBannerOpen && isSplitLoaded}>
        <Fade in={isSplitLoaded}>
          <BannerContainer item xs={12}>
            <Container darkMode={darkMode}>
              <BannerContent
                sx={{
                  padding: !canBeClosed ? '16px 34px' : null,
                  width: { xs: '100%', md: 'auto' },
                }}
              >
                <Box>
                  <Typography
                    as="h3"
                    sx={{
                      fontSize: '1.12rem',
                      fontWeight: '500',
                    }}
                  >
                    {title?.text}
                  </Typography>
                  <Typography
                    as="p"
                    sx={{
                      fontSize: '1rem',
                      margin: { xs: '0px 0px 24px 0px', md: '0px' },
                    }}
                  >
                    {subtitle?.text}
                  </Typography>
                </Box>
                <ButtonUI
                  type="callf"
                  label={button?.text}
                  color={button?.color}
                  fullWidth={false}
                  onClickFunc={handleOnClick}
                  sx={{
                    marginLeft: { xs: '0px', md: '32px' },
                    width: { xs: '100%', md: '158px' },
                    maxHeight: { xs: 'unset', md: '40px' },
                  }}
                />
                <ToggleUI show={canBeClosed}>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      color: darkMode ? '#FAFAFA' : '#565656',
                      top: { xs: '8px', md: 'auto' },
                      right: { xs: '24px', md: '26px' },
                    }}
                    onClick={() => {
                      setIsBannerOpen(false);
                      localStorage.setItem('showSuscriptionBanner', false);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </ToggleUI>
              </BannerContent>
            </Container>
          </BannerContainer>
        </Fade>
      </ToggleUI>
    );
  }
);
