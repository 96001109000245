import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, DocFileUI } from '../../../../../components/common';

const ModalMarcarComoFacturada = (props) => {
  let {
    marcar_como_facturada,
    handleSubmit,
    handleChangeDoc,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  const allowed_files = ['pdf'];
  const max_file = 1;
  const input_allowed_mime = 'application/pdf';
  const max_size = 0.5;

  return (
    <ModalUI
      open={modal_state}
      id="marcar_como_facturada"
      title="Marcar como facturada"
      subtitle={marcar_como_facturada.modal_subtitle.value}
      modalHandler={modalHandler}
      aditional_param="marcar_como_facturada"
      handleSubmit={handleSubmit}
      button_label="Enviar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <DocFileUI
            data={marcar_como_facturada.v_factura_pdf}
            handleChangeDoc={handleChangeDoc}
            max_file={max_file}
            max_size={max_size}
            allowed_files={allowed_files}
            input_allowed_mime={input_allowed_mime}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalMarcarComoFacturada };
