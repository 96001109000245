import React from 'react';
import { useHistory } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { ErrorLayout } from '../../components/common';

export const WrapperLayout = ({ loading, error, children }) => {
  const history = useHistory();

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <ErrorLayout url={history.location.pathname} />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
