import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  connectSplit,
  selectTreatmentValue,
} from '@splitsoftware/splitio-redux';

const SplitioUIFeatureFlag = connectSplit()(
  ({
    splitio,
    children,
    splitName,
    treatmentValue = 'on',
    customerId = 'panel-empretienda',
  }) => {
    const [treatment, setTreatment] = useState();

    useEffect(() => {
      if (splitio && splitio.isReady) {
        const trment = selectTreatmentValue(splitio, splitName, customerId);

        setTreatment(trment);
      }
    }, [splitio, splitName, customerId]);

    if (treatment === treatmentValue) {
      return <>{children}</>;
    }

    return <></>;
  }
);

SplitioUIFeatureFlag.propTypes = {
  children: PropTypes.node.isRequired,
  splitName: PropTypes.string.isRequired,
  splitio: PropTypes.any,
  treatmentValue: PropTypes.string,
};

export { SplitioUIFeatureFlag };
