import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI, InputUI, SelectUI } from '../../common';
import { Validate } from '../../../other';

const ModalRegistro = (props) => {
  let {
    administrar_registro,
    selects,
    handleSubmit,
    handleChange,
    handleChangeSelect,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  let html, aditional_param, button_label, title;

  switch (administrar_registro.reg_action.value) {
    //0 agregar
    case 0:
      aditional_param = 'agregar';
      button_label = 'Agregar';
      title = 'Agregar registro';
      html = (
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <SelectUI
              data={administrar_registro.reg_tipo}
              options={selects.reg_tipo}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={{
                ...administrar_registro.reg_nombre,
                messages: {
                  ...administrar_registro.reg_nombre.messages,
                  help:
                    'Si debes cargar como nombre "@", será reemplazado por su valor equivalente "' +
                    administrar_registro.reg_nombre.default_value +
                    '" que es el host por defecto',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              multiline={Validate.in_array(
                administrar_registro.reg_tipo.value,
                ['MX', 'TXT']
              )}
              input={administrar_registro.reg_valor}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={administrar_registro.reg_ttl}
            />
          </Grid>
        </Grid>
      );
      break;
    //1 editar
    case 1:
      aditional_param = 'editar';
      button_label = 'Editar';
      title = 'Editar registro';
      html = (
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              multiline={Validate.in_array(
                administrar_registro.reg_tipo.value,
                ['MX', 'TXT']
              )}
              input={administrar_registro.reg_valor}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={administrar_registro.reg_ttl}
            />
          </Grid>
        </Grid>
      );
      break;
    //2 borrar
    case 2:
      aditional_param = 'borrar';
      button_label = 'Borrar';
      title = 'Borrar registro';
      html = (
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography>
              ¿Estás seguro que deseas borrar dicho registro? Debes tener en
              cuenta que puede afectar el funcionamiento de algún servicio.
            </Typography>
          </Grid>
        </Grid>
      );
      break;
    default:
      break;
  }

  return (
    <ModalUI
      open={modal_state}
      id="administrar_registro"
      title={title}
      subtitle="Solo utilizar esta opción si has visto tutoriales o manejado registros DNS anteriormente ya que pueden desconfigurar tu tienda"
      modalHandler={modalHandler}
      aditional_param={aditional_param}
      handleSubmit={handleSubmit}
      button_label={button_label}
      button_loading={form_state}
    >
      {html}
    </ModalUI>
  );
};

export { ModalRegistro };
