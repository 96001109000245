/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../common';

const ModalMigrarDominio_ = (props) => {
  let { handleSubmit, modalHandler, modal_state, form_state, dominios } = props;

  let dominio = '';

  if (dominios.length === 1) {
    if (dominios[0].d_nombre.includes('.miempretienda.com')) {
      dominio = dominios[0].d_nombre.split('.')[0];
    }
  }

  return (
    <ModalUI
      open={modal_state}
      id="migrar_dominio_gratis"
      title="Migrar a nuevo dominio"
      modalHandler={modalHandler}
      aditional_param="migrar_dominio_gratis"
      handleSubmit={handleSubmit}
      button_label="Migrar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            Este proceso sirve para migrar tu dominio gratuito de{' '}
            <strong>{dominio}.miempretienda.com</strong> a{' '}
            <strong>{dominio}.empretienda.com.ar</strong> y asi evitar problemas
            al intentar acceder a tu tienda desde Instagram, WhatsApps y/o
            Facebook.
          </Typography>
          <div style={{ marginBottom: 10 }}></div>
          <Typography>
            Preparamos un documento con toda la información que tenés que saber
            sobre el cambio de dominio gratuito. Podés verlo desde el siguiente
            link:{' '}
            <a
              href="https://recursos.empretienda.com/migracion-dominio-miempretienda.pdf"
              target="_blank"
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
            >
              https://recursos.empretienda.com/migracion-dominio-miempretienda.pdf
            </a>
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

const mapStateToProps = (state) => {
  return {
    dominios: state.dominios,
  };
};

const mapDispatchToProps = null;

export const ModalMigrarDominio = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalMigrarDominio_);
