import React, { useEffect } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { CardText, CardTitle } from '../subcomponents';
import { ModuleTitleUI, CardModuleUI, ToggleUI } from '../../common';
import { TableErrors } from '../importar/TableErrors';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';
import { ModalEncender } from '../../../pages/apagar-temporalmente/ModalEncender';
import { ItemNumber, ItemTitle, ProductCardItem } from './styles';

export const ImportSummary = ({
  handleTurnShopOn,
  modalHandler,
  modal_state,
  form_state,
  handleClickOnManageProducts,
  t_offmode,
  handleLoadMoreProducts,
  editedProducts,
  createdProducts,
  spreadsheetChunkErrors,
}) => {
  useEffect(() => {
    createAmplitudeEventWithDevice(
      import_amplitude_events.confirmar_importacion_successfull.event_name,
      import_amplitude_events.confirmar_importacion_successfull.event_params
    );
  }, []);

  return (
    <>
      <ModalEncender
        handleSubmit={handleTurnShopOn}
        modalHandler={modalHandler}
        modal_state={modal_state}
        form_state={form_state}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI title="Importación finalizada" />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <CardTitle title="Resumen de operaciones realizadas" />
            <CardText text="Podrás visualizar el detalle de cada uno de los productos desde la sección de Administrar productos." />
            <Grid container mt="36px">
              <ProductCardItem item md={3} xs={12}>
                <Typography as="span" m={{ xs: '15px 10px 0 0', md: 'unset' }}>
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.5 13.5L30.375 9.375L34.5 7.5L30.375 5.625L28.5 1.5L26.625 5.625L22.5 7.5L26.625 9.375L28.5 13.5ZM17.25 14.25L13.5 6L9.75 14.25L1.5 18L9.75 21.75L13.5 30L17.25 21.75L25.5 18L17.25 14.25ZM28.5 22.5L26.625 26.625L22.5 28.5L26.625 30.375L28.5 34.5L30.375 30.375L34.5 28.5L30.375 26.625L28.5 22.5Z"
                      fill="#53B755"
                    />
                  </svg>
                </Typography>
                <Box>
                  <ItemTitle>Productos nuevos</ItemTitle>
                  <ItemNumber sx={{ color: '#53B755' }}>
                    {createdProducts}
                  </ItemNumber>
                </Box>
              </ProductCardItem>
              <ProductCardItem item md={3} xs={12}>
                <Typography as="span" m={{ xs: '15px 10px 0 0', md: 'unset' }}>
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2498 8.4L14.9998 10.5L12.8998 6.75L14.9998 3L11.2498 5.1L7.49984 3L9.59984 6.75L7.49984 10.5L11.2498 8.4ZM29.2498 23.1L25.4998 21L27.5998 24.75L25.4998 28.5L29.2498 26.4L32.9998 28.5L30.8998 24.75L32.9998 21L29.2498 23.1ZM32.9998 3L29.2498 5.1L25.4998 3L27.5998 6.75L25.4998 10.5L29.2498 8.4L32.9998 10.5L30.8998 6.75L32.9998 3ZM21.5548 10.935C20.9698 10.35 20.0248 10.35 19.4398 10.935L1.93484 28.44C1.34984 29.025 1.34984 29.97 1.93484 30.555L5.44484 34.065C6.02984 34.65 6.97484 34.65 7.55984 34.065L25.0498 16.575C25.6348 15.99 25.6348 15.045 25.0498 14.46L21.5548 10.935ZM20.0098 19.17L16.8298 15.99L20.4898 12.33L23.6698 15.51L20.0098 19.17Z"
                      fill="#F99909"
                    />
                  </svg>
                </Typography>
                <Box>
                  <ItemTitle>Productos editados</ItemTitle>
                  <ItemNumber sx={{ color: '#F99909' }}>
                    {editedProducts}
                  </ItemNumber>
                </Box>
              </ProductCardItem>
              <ProductCardItem item md={6} xs={12}>
                <Typography as="span" m={{ xs: '15px 10px 0 0', md: 'unset' }}>
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM25.5 23.385L23.385 25.5L18 20.115L12.615 25.5L10.5 23.385L15.885 18L10.5 12.615L12.615 10.5L18 15.885L23.385 10.5L25.5 12.615L20.115 18L25.5 23.385Z"
                      fill="#FF0000"
                    />
                  </svg>
                </Typography>
                <Box>
                  <ItemTitle>Errores encontrados</ItemTitle>
                  <ItemNumber sx={{ color: '#FF0000' }}>
                    {spreadsheetChunkErrors.length}
                  </ItemNumber>
                </Box>
              </ProductCardItem>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    backgroundColor: '#6C6C6C',
                    height: '1px',
                    border: '1px',
                    margin: '36px 0px',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CardTitle
                  title={
                    t_offmode === 1
                      ? 'Encender tu tienda'
                      : 'La tienda ya está encendida'
                  }
                />
                <CardText
                  text={
                    t_offmode === 1
                      ? 'Te recomendamos volver a activar tu tienda para que sus clientes puedan volver a comprar productos.'
                      : 'Tus clientes ya pueden acceder a comprar productos'
                  }
                />
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={'auto'}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={t_offmode === 0 ? true : false}
                      onClick={() =>
                        modalHandler(true, 'apagar_temporalmente_encender')
                      }
                    >
                      Encender tienda
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <ToggleUI show={spreadsheetChunkErrors.length > 0}>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      backgroundColor: '#6C6C6C',
                      height: '1px',
                      border: '1px',
                      margin: '36px 0px',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TableErrors import_errors={spreadsheetChunkErrors} />
                </Grid>
              </ToggleUI>
            </Grid>
          </CardModuleUI>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row-reverse' }}
          gap={2}
          mt={{ md: 'unset', xs: '24px' }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleLoadMoreProducts();
            }}
          >
            Cargar más productos
          </Button>
          <Button onClick={() => handleClickOnManageProducts()} variant="text">
            Administrar productos
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
