import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { Circle } from './Circle';
import { Validate } from '../../../other';
import { ToggleUI } from '../../common';

const SuscripcionInfo_ = ({
  classes,
  expirationDate,
  monto,
  isTrial,
  idCountry,
}) => {
  const wordingSuscription = {
    ARG: {
      title: 'Simplificá tu forma de pago',
      subtitle:
        'Suscribite al débito automático con tu tarjeta de crédito o débito y olvidate de realizar el pago manual de cada mes.',
      paragraph:
        'Mientras disfrutás de tu prueba sin costo, podés suscribirte y comenzar a pagar el ',
      priceText: 'Pago mensual',
      priceAdorment: '',
    },
    COL: {
      title: 'Simplificá tu forma de pago',
      subtitle: '',
      paragraph:
        'Mientras disfrutas de tu prueba sin costo, puedes suscribirte y comenzar a pagar el ',
      priceText: 'Pago mensual',
      priceAdorment: 'COP',
    },
  };

  return (
    <div
      className={
        idCountry === 'ARG' ? classes.containerWithTab : classes.container
      }
    >
      <ToggleUI show={idCountry === 'ARG'}>
        <h3 className={classes.title}>{wordingSuscription[idCountry].title}</h3>
        <p className={classes.subtitle}>
          {wordingSuscription[idCountry].subtitle}
        </p>
      </ToggleUI>

      {isTrial && (
        <p className={classes.paragraph}>
          {wordingSuscription[idCountry].paragraph} {expirationDate}.
        </p>
      )}

      <Grid
        container
        wrap="nowrap"
        justifyContent="space-between"
        className={classes.priceContainer}
      >
        <Grid p={1.5} item xs={12} className={classes.priceText}>
          <p>{wordingSuscription[idCountry].priceText}</p>
        </Grid>
        <Grid p={1.5} item xs={12} className={classes.price}>
          <p>
            <strong>
              ${Validate.number_format(monto, idCountry)}{' '}
              {wordingSuscription[idCountry].priceAdorment}
            </strong>{' '}
            por mes
          </p>
          <Circle checked={true} />
        </Grid>
      </Grid>
      <ToggleUI show={idCountry === 'COL'}>
        <Grid item xs={12} className={classes.infoText}>
          <p>
            Con tu suscripción mensual podrás acceder a todas las herramientas
            sin costo adicional.
          </p>
          <p>Cancelación disponible según tu preferencia.</p>
        </Grid>
      </ToggleUI>
    </div>
  );
};

const style = (theme) => ({
  containerWithTab: {
    padding: '0',
    [theme.breakpoints.up('sm')]: {
      padding: '12px 24px 12px 24px',
    },
    backgroundColor: '#FFF',
  },
  container: {
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      padding: '22px 32px',
    },
    backgroundColor: '#FFF',
  },
  paragraph: {
    fontWeight: '500',
    marginBottom: '1.5rem',
  },
  price: {
    margin: '0',
    display: 'flex',
    textAlign: 'end',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& p': {
      marginRight: '12px',
    },
  },
  priceText: {
    margin: 0,
  },
  priceContainer: {
    margin: 0,
    padding: 0,
    width: '100%',
    fontSize: '0.75rem',
    borderRadius: '4px',
    alignItems: 'center',
    border: '2px solid #53B755',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      padding: '0px 24px',
    },
  },
  title: {
    fontSize: '1rem',
    margin: '0px',
  },
  subtitle: {
    fontWeight: '400',
    fontSize: '.875rem',
    margin: '6px 0 24px 0',
  },
  infoText: {
    margin: '24px 0 12px',
    '& p': { margin: 0 },
  },
});

export const SuscripcionInfo = withStyles(style)(SuscripcionInfo_);
