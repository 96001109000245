import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { InfoText } from '../InfoText';
import { ShopProductsFeeder } from '../ShopProductsFeeder';
import { InputUI, SelectUI, ToggleUI } from '../../../../components/common';
import { InputColorCircle } from '../InputColorCircle';
import {
  blockAlignmentOptions,
  blockSpacingOptions,
  mobileRowElementsQuantity,
  rowElementsQuantity,
} from '../../jsonSelects';

export const ProductsSet = ({
  handleChangeProductsBlock,
  handlerShowAlertUI,
  handleChangeBlock,
  handleChangeSelectBlock,
  block,
  showAlign,
}) => {
  const [isConfigOptionsOpen, setIsConfigOptionsOpen] = useState(false);

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText first title="Productos" />
        <ShopProductsFeeder
          handlerShowAlertUI={handlerShowAlertUI}
          productos={block.bl_data.products}
          max_products={12}
          handleChangeProducts={(products) =>
            handleChangeProductsBlock(
              block.bl_data.title.change_param,
              products
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '0.65rem',
            lineHeight: '1rem',
            fontWeight: 400,
            textDecoration: 'underline',
            cursor: 'pointer',
            userSelect: 'none',
            margin: '15px 0px 0px 0px',
          }}
          onClick={() => setIsConfigOptionsOpen((prevState) => !prevState)}
        >
          {isConfigOptionsOpen
            ? 'OCULTAR OPCIONES DE CONFIGURACIÓN'
            : 'MOSTRAR MÁS OPCIONES DE CONFIGURACIÓN'}
        </Typography>
      </Grid>
      <ToggleUI show={isConfigOptionsOpen}>
        <Grid item xs={12}>
          <InfoText title="Título (opcional)" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <InputUI
                input={block.bl_data.title}
                handleChange={handleChangeBlock}
              />
            </Grid>
            <ToggleUI show={showAlign}>
              <Grid item xs={12}>
                <SelectUI
                  data={block.bl_data.title_align}
                  options={blockAlignmentOptions}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </ToggleUI>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoText title="Texto (opcional)" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <InputUI
                input={block.bl_data.text}
                handleChange={handleChangeBlock}
                multiline
              />
            </Grid>
            <ToggleUI show={showAlign}>
              <Grid item xs={12}>
                <SelectUI
                  data={block.bl_data.text_align}
                  options={blockAlignmentOptions}
                  handleChangeSelect={handleChangeSelectBlock}
                />
              </Grid>
            </ToggleUI>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoText title="Adicionales" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.type}
                options={[
                  {
                    value: 1,
                    label: 'Carrusel',
                  },
                  {
                    value: 2,
                    label: 'Grilla',
                  },
                  {
                    value: 3,
                    label: 'Grilla Masonry',
                  },
                ]}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.per_row}
                options={rowElementsQuantity}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.per_row_mobile}
                options={mobileRowElementsQuantity}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
            <Grid item xs={12}>
              <InputUI
                handleChange={handleChangeBlock}
                input={block.bl_data.background}
                adornment={
                  <InputColorCircle color={block.bl_data.background.value} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.section_size}
                options={blockSpacingOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </Grid>
        </Grid>
      </ToggleUI>
    </Grid>
  );
};
