import { cell_value, column_names, isEmptyNumber } from './ExportFormat';
import { ImportError } from './ImportError';
import { IMPORT_ERROR_CODE_MESSAGE } from './ImportErrorCodes';
/**
 * @typedef {Object} Cell
 * @property {number} index - index in the row of the column related to the cell
 * @property {string} title - title of the column related to the cell
 * @property {string | number | null } value - value of the cell
 */

/**
 * @typedef {Object} Row
 * @property {number} row_number - index of the row in the workbook
 * @property {Cell []} cells - cells of the row
 * @property {string } normalized_product_name - the product normalized name
 */

/**
 * @summary: validates rules over stock items related to a product
 * @param { Row[]} rows
 * @returns { {err: boolean, error_messages: string[]} }
 */
export const productsHashTableAndValidateStock = (rows) => {
  let import_errors = [];

  let products_hash_table = [];
  rows.forEach((row) => {
    const key = row.normalized_product_name;
    if (products_hash_table[key]) {
      products_hash_table[key].push(row);
    } else {
      products_hash_table[key] = [];
      products_hash_table[key].push(row);
    }
  });

  const normalized_product_names = Object.keys(products_hash_table);
  normalized_product_names.forEach((normalized_product_name) => {
    const producto_rows = products_hash_table[normalized_product_name];
    const first_stock_row = producto_rows.filter(
      (element, index) => index === 0
    );
    const first_stock_cells = first_stock_row[0].cells;
    const first_stock_row_number = producto_rows[0].row_number;
    const first_stock_product_name = cell_value(
      first_stock_cells,
      column_names.nombre
    );
    const leftover_stock_rows = producto_rows.filter(
      (element, index) => index > 0
    );

    const cant_items = producto_rows.length;

    const cant_con_precio = producto_rows.reduce(
      (previous_value, producto_row) => {
        const cells = producto_row.cells;
        const precio = cell_value(cells, column_names.precio);
        return previous_value + (isEmptyNumber(precio) ? 0 : 1);
      },
      0
    );

    const cant_con_precio_mayorista = producto_rows.reduce(
      (previous_value, producto_row) => {
        const cells = producto_row.cells;
        const precio_mayorista = cell_value(
          cells,
          column_names.precioMayorista
        );
        return previous_value + (isEmptyNumber(precio_mayorista) ? 0 : 1);
      },
      0
    );

    if (cant_con_precio > 0 && cant_con_precio !== cant_items) {
      import_errors.push(
        new ImportError(
          first_stock_product_name,
          first_stock_row_number,
          column_names.precio,
          IMPORT_ERROR_CODE_MESSAGE.ERR076
        )
      );
    }

    if (
      cant_con_precio_mayorista > 0 &&
      cant_con_precio_mayorista !== cant_items
    ) {
      import_errors.push(
        new ImportError(
          first_stock_product_name,
          first_stock_row_number,
          column_names.precioMayorista,
          IMPORT_ERROR_CODE_MESSAGE.ERR076
        )
      );
    }

    //Comparar resto de productos con la primera línea
    leftover_stock_rows.forEach((stock_row) => {
      const cells = stock_row.cells;
      const row_number = stock_row.row_number;
      const product_name = cell_value(cells, column_names.nombre);

      if (
        cell_value(cells, column_names.IDProduct) !==
        cell_value(first_stock_cells, column_names.IDProduct)
      )
        import_errors.push(
          new ImportError(
            product_name,
            row_number,
            column_names.IDProduct,
            IMPORT_ERROR_CODE_MESSAGE.ERR400
          )
        );

      if (
        cell_value(cells, column_names.nombreAtributo1) !==
        cell_value(first_stock_cells, column_names.nombreAtributo1)
      )
        import_errors.push(
          new ImportError(
            product_name,
            row_number,
            column_names.nombreAtributo1,
            IMPORT_ERROR_CODE_MESSAGE.ERR401
          )
        );

      if (
        cell_value(cells, column_names.nombreAtributo2) !==
        cell_value(first_stock_cells, column_names.nombreAtributo2)
      )
        import_errors.push(
          new ImportError(
            product_name,
            row_number,
            column_names.nombreAtributo2,
            IMPORT_ERROR_CODE_MESSAGE.ERR402
          )
        );

      if (
        cell_value(cells, column_names.nombreAtributo3) !==
        cell_value(first_stock_cells, column_names.nombreAtributo3)
      )
        import_errors.push(
          new ImportError(
            product_name,
            row_number,
            column_names.nombreAtributo3,
            IMPORT_ERROR_CODE_MESSAGE.ERR403
          )
        );
    });
  });
  return {
    err: import_errors.length === 0 ? false : true,
    import_errors,
    products_hash_table,
    normalized_product_names,
  };
};
