import React from 'react';
import { Validate } from '../../../../../other';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const DirectionDetails_ = ({ classes, venta, idCountry }) => {
  if (idCountry === 'COL') {
    return (
      <>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Dirección:</span>{' '}
          {Validate.trim_ucwords(venta.v_envio_calle)} {' - '}{' '}
          {venta.v_envio_numero}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Apto/Localidad/Barrio:</span>{' '}
          {venta.v_envio_dpto ? Validate.trim_ucwords(venta.v_envio_dpto) : '-'}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Ciudad:</span>{' '}
          {Validate.trim_ucwords(venta.v_envio_ciudad)}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Provincia:</span>{' '}
          {venta.v_envio_provincia}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Calle:</span>{' '}
          {Validate.trim_ucwords(venta.v_envio_calle)}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Número:</span>{' '}
          {venta.v_envio_numero}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Piso:</span>{' '}
          {venta.v_envio_piso ? Validate.trim_ucwords(venta.v_envio_piso) : '-'}{' '}
          <span className={classes.info_title}>Dpto:</span>{' '}
          {venta.v_envio_dpto ? Validate.trim_ucwords(venta.v_envio_dpto) : '-'}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Ciudad:</span>{' '}
          {Validate.trim_ucwords(venta.v_envio_ciudad)}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Provincia:</span>{' '}
          {venta.v_envio_provincia}
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Código postal:</span>{' '}
          {venta.v_envio_postal}
        </Typography>
      </>
    );
  }
};

const styles = () => ({
  info: {
    fontWeight: '300',
  },
  info_title: {
    fontWeight: '500',
  },
});

export const DirectionDetails = withStyles(styles)(DirectionDetails_);
