class Config {
  static BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
  static USE_HEADER =
    process.env.REACT_APP_USE_HEADER === 'true' ? true : false;
  static RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;
  static CLOUDFRONT_CDN_PROD = process.env.REACT_APP_CLOUDFRONT_CDN_PROD;
  static CLOUDFRONT_PRODUCTS_CDN = process.env.REACT_APP_CLOUDFRONT_CDN;
  static CLOUDFRONT_LOGOS_CDN = process.env.REACT_APP_CLOUDFRONT_CDN;
  static CLOUDFRONT_BANNERS_CDN = process.env.REACT_APP_CLOUDFRONT_CDN;
  static CLOUDFRONT_BLOCKS_CDN = process.env.REACT_APP_CLOUDFRONT_CDN;
  static CLOUDFRONT_DESCIMAGES_CDN = `${process.env.REACT_APP_CLOUDFRONT_CDN}descripciones/`;
  static CLOUDFRONT_PORTADAS_CDN = `${process.env.REACT_APP_CLOUDFRONT_CDN}portadas/`;
  static DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;
  static DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT;
  static SHORTDATE_FORMAT = process.env.REACT_APP_SHORTDATE_FORMAT;
  static SHORTDATE_FORMAT_DASH = process.env.REACT_APP_SHORTDATE_FORMAT_DASH;
  static SHORTDATE_FULLFORMAT = process.env.REACT_APP_SHORTDATE_FULLFORMAT;
  static REACT_APP_PUBLIC_AMPLITUDE_API_KEY =
    process.env.REACT_APP_PUBLIC_AMPLITUDE_API_KEY;
  static SPLIT_IO_BROWSER_API_KEY =
    process.env.REACT_APP_SPLIT_IO_BROWSER_API_KEY;
  static REACT_APP_NAME = process.env.REACT_APP_NAME;
  static BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY;
  static BRAZE_API_ENDPOINT = process.env.REACT_APP_BRAZE_API_ENDPOINT;
  static BRAZE_API_DEV_MODE =
    process.env.REACT_APP_BRAZE_API_DEV_MODE === 'true' ? true : false;
  static REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
  static ENVIRONMENTS = ['development', 'stage', 'local', 'production'];
  static CURRENCY = {
    ARG: { formatNumber: '0.01', adornment: '$' },
    COL: { formatNumber: '1', adornment: '$' },
  };
  static LANDINGPAGE_URL = process.env.REACT_APP_LANDINGPAGE_URL;
  static MP_PUBLIC_KEY = {
    ARG: process.env.REACT_APP_MP_PUBLIC_KEY,
    COL: process.env.REACT_APP_MP_COL_PUBLIC_KEY,
  };
  static PROVINCIAS = {
    ARG: [
      {
        value: '-',
        label: '-',
      },
      {
        value: 'BUENOS AIRES',
        label: 'Buenos Aires',
      },
      {
        value: 'CIUDAD DE BUENOS AIRES',
        label: 'Ciudad de Buenos Aires',
      },
      {
        value: 'CATAMARCA',
        label: 'Catamarca',
      },
      {
        value: 'CHACO',
        label: 'Chaco',
      },
      {
        value: 'CHUBUT',
        label: 'Chubut',
      },
      {
        value: 'CORDOBA',
        label: 'Córdoba',
      },
      {
        value: 'CORRIENTES',
        label: 'Corrientes',
      },
      {
        value: 'ENTRE RIOS',
        label: 'Entre Ríos',
      },
      {
        value: 'FORMOSA',
        label: 'Formosa',
      },
      {
        value: 'JUJUY',
        label: 'Jujuy',
      },
      {
        value: 'LA PAMPA',
        label: 'La Pampa',
      },
      {
        value: 'LA RIOJA',
        label: 'La Rioja',
      },
      {
        value: 'MENDOZA',
        label: 'Mendoza',
      },
      {
        value: 'MISIONES',
        label: 'Misiones',
      },
      {
        value: 'NEUQUEN',
        label: 'Neuquén',
      },
      {
        value: 'RIO NEGRO',
        label: 'Río Negro',
      },
      {
        value: 'SALTA',
        label: 'Salta',
      },
      {
        value: 'SAN JUAN',
        label: 'San Juan',
      },
      {
        value: 'SAN LUIS',
        label: 'San Luis',
      },
      {
        value: 'SANTA CRUZ',
        label: 'Santa Cruz',
      },
      {
        value: 'SANTA FE',
        label: 'Santa Fe',
      },
      {
        value: 'SANTIAGO DEL ESTERO',
        label: 'Santiago del Estero',
      },
      {
        value: 'TIERRA DEL FUEGO',
        label: 'Tierra del Fuego',
      },
      {
        value: 'TUCUMAN',
        label: 'Tucumán',
      },
    ],
  };
  static ECOMMAPP_APP_ID = process.env.REACT_APP_ECOMMAPP_APP_ID;
  static MOCK_ACTIVE =
    process.env.REACT_APP_MOCK_ACTIVE === 'true' ? true : false;
  static BFF_ENDPOINT = process.env.REACT_APP_BFF_ENDPOINT;
  static CDN_APP_PERMISSIONS = 'https://d2fl1yj7aqv1p3.cloudfront.net';

  static FF_SPLITS = [
    'experiment_empretienda_panel_onboarding',
    'feature_flag_empretienda_panel_aplicaciones',
    'feature_flag_empretienda_panel_mi_plan_debito_automatico',
    'experiment_empretienda_panel_home_banner_darkmode',
  ];
}

export { Config };
