import { Grid } from '@mui/material';
import React from 'react';
import { InfoText } from '../InfoText';
import {
  ButtonUI,
  InputUI,
  SelectUI,
  ToggleUI,
} from '../../../../components/common';
import { InputColorCircle } from '../InputColorCircle';
import { blockAlignmentOptions, blockSpacingOptions } from '../../jsonSelects';

export const ProductsFeed = ({
  showAlign,
  block,
  handleChangeBlock,
  handleChangeSelectBlock,
  handleChangeMenuTab,
}) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText title="Título (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <ToggleUI show={showAlign}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.title_align}
                options={blockAlignmentOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Texto (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
          <ToggleUI show={showAlign}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.text_align}
                options={blockAlignmentOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText
          title="Productos"
          subtitle="Para modificar el orden de los productos, podés hacerlo desde la pestaña de productos."
        />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <ButtonUI
              type="callf"
              onClickFunc={(e) => handleChangeMenuTab(e, -5)}
              variant="outlined"
              color="primary"
              size="small"
              label="Ver productos"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Adicionales" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.background}
              adornment={
                <InputColorCircle color={block.bl_data.background.value} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.section_size}
              options={blockSpacingOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.show_more_btn}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
