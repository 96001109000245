import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';
import metodosPagoDummy from '../../__mocks__/metodosPagoDummy';

const metodospagoObtener = (callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = metodosPagoDummy.metodospagoObtener();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/medios-pago')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarMercadoPago = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/activar-mercadopago', data)
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarMercadoPago = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/desactivar-mercadopago')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarTodoPago = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/activar-todopago', data)
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarTodoPago = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/desactivar-todopago')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarUala = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/uala-bis/activar', data)
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarUala = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/uala-bis/desactivar')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarDeposito = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/activar-deposito', data)
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarDeposito = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/desactivar-deposito')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarEfectivo = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/activar-efectivo', data)
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarEfectivo = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/desactivar-efectivo')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarAcordar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/activar-acordar')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarAcordar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/desactivar-acordar')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoObtenerUrlMobbex = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/medios-pago/url-mobbex')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoActivarMobbex = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/activar-mobbex', data)
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodopagoDesactivarMobbex = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-pago/desactivar-mobbex')
      .then((resp) => {
        //guardo en redux
        const metodos_pago = resp.data.data;
        dispatch({
          type: Reducers.METODOSPAGO_GUARDAR,
          data: metodos_pago,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  metodospagoObtener,
  metodopagoActivarMercadoPago,
  metodopagoDesactivarMercadoPago,
  metodopagoActivarTodoPago,
  metodopagoDesactivarTodoPago,
  metodopagoActivarUala,
  metodopagoDesactivarUala,
  metodopagoActivarDeposito,
  metodopagoDesactivarDeposito,
  metodopagoActivarEfectivo,
  metodopagoDesactivarEfectivo,
  metodopagoActivarAcordar,
  metodopagoDesactivarAcordar,
  metodopagoObtenerUrlMobbex,
  metodopagoActivarMobbex,
  metodopagoDesactivarMobbex,
};
