import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// variant

const LabelUI_ = ({ variant, text, classes, isLoading = false }) => {
  return (
    <>
      {isLoading ? (
        <Skeleton style={{ marginLeft: '6px' }} width={100} height={28} />
      ) : (
        <div
          className={`${classes.box_label} ${
            variant === 'success' ? classes.box_success : classes.box_danger
          }`}
        >
          <span
            className={`${classes.text_label} ${
              variant === 'danger' ? classes.text_danger : classes.text_success
            }`}
          >
            {text}
          </span>
        </div>
      )}
    </>
  );
};

LabelUI_.propTypes = {
  variant: PropTypes.oneOf(['success', 'danger']),
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

const styles = () => ({
  box_label: {
    padding: '6px',
    borderRadius: '4px',
    marginLeft: '5px',
  },
  text_label: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
  },
  box_success: {
    backgroundColor: '#BDE3BD',
  },
  text_success: {
    color: '#1B5F1C',
  },
  box_danger: {
    backgroundColor: 'rgba(255, 0, 0, 0.2);',
  },
  text_danger: {
    color: '#FF0000',
  },
});

export const LabelUI = withStyles(styles)(LabelUI_);
