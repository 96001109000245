import React, { useEffect, useState } from 'react';
import { AlertUI, CardModuleUI, ModuleTitleUI, ToggleUI } from '../common';
import { Divider, Grid, Icon } from '@mui/material';
import { ModalInstallApp } from './subcomponents/ModalInstallApp';
import { ModalUninstallApp } from './subcomponents/ModalUninstallApp';
import { connect } from 'react-redux';
import {
  applications_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import {
  aplicacionesObtener,
  aplicacionesActivasObtener,
  aplicacionesPermisosObtener,
} from '../../store/actions';
import { withStyles } from '@mui/styles';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { AppListForInstall } from './subcomponents/AppListForInstall';
import { AppInstalledList } from './subcomponents/AppInstalledList';

const Applications_ = ({
  classes,
  history,
  getApplications,
  getApplicationsActives,
  getPermissions,
}) => {
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  });
  const [error, setError] = useState(false);
  const [loadingApps, setLoadingApps] = useState(true);
  const [loadingAppsActives, setLoadingAppsActives] = useState(true);
  const [openInstallApp, setOpenInstallApp] = useState(false);
  const [openUninstallApp, setOpenUninstallApp] = useState(false);
  const [appSelected, setAppSelected] = useState({});
  const [appList, setAppList] = useState([]);
  const [appActivesList, setAppActivesList] = useState([]);
  const [permissionsTitles, setPermissionsTitles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isAppDeleted, setIsAppDeleted] = useState(true);

  useEffect(() => {
    if (isAppDeleted) {
      getApplications((err, resp) => {
        if (err) {
          setError(true);
        } else {
          setAppList(resp);
          setLoadingApps(false);
        }
      });

      getPermissions((err, resp) => {
        if (err) {
          setError(err);
        } else {
          setPermissions(resp);
        }
      });
    }
  }, [isAppDeleted]);

  useEffect(() => {
    if (appList.length > 0) {
      getApplicationsActives((err, resp) => {
        if (err) {
          setError(true);
        } else {
          setAppActivesList(resp.data.applications);
          setLoadingAppsActives(false);
          setIsAppDeleted(false);
        }
      });
    }
  }, [appList]);

  const getTitlePermissions = (appScopes) => {
    let foundPermissions = [];

    permissions.forEach((permission) => {
      if (
        appScopes &&
        appScopes.length > 0 &&
        appScopes.some((s) => permission.scope.includes(s))
      ) {
        foundPermissions.push(permission.title);
      }
    });

    return foundPermissions;
  };

  const handleOpenModal = ({ appId, isInstall }) => {
    const appFiltered = appList.find((app) => app.app_id === appId);

    setPermissionsTitles([]);

    setAppSelected(appFiltered);

    if (isInstall) {
      setOpenInstallApp(true);

      if (appFiltered && appFiltered.scope && appFiltered.scope.length > 0) {
        const titles = getTitlePermissions(appFiltered.scope);
        setPermissionsTitles(titles);
      }

      createAmplitudeEventWithDevice(
        applications_amplitude_events.aplicaciones_instalar.event_name,
        {
          aplicacion: `(${appFiltered.name})`,
          source: 'card',
        }
      );
    } else {
      setOpenUninstallApp(true);
      createAmplitudeEventWithDevice(
        applications_amplitude_events.aplicaciones_desinstalar.event_name,
        {
          aplicacion: `(${appFiltered.name})`,
        }
      );
    }
  };

  const hasInstalledApps = appActivesList.length > 0;

  return (
    <WrapperLayout error={error} loading={loadingApps || loadingAppsActives}>
      <AlertUI
        open={alert.open}
        type={alert.type}
        hideSeconds={5000}
        message={alert.message}
        handleCloseAlert={() => setAlert({ ...alert, open: false })}
      />
      <ModalInstallApp
        app={appSelected}
        history={history}
        open={openInstallApp}
        modalHandler={setOpenInstallApp}
        permissionsTitles={permissionsTitles}
      />
      <ModalUninstallApp
        app={appSelected}
        history={history}
        open={openUninstallApp}
        modalHandler={setOpenUninstallApp}
        setAlert={setAlert}
        setLoadingApps={setLoadingApps}
        setLoadingAppsActives={setLoadingAppsActives}
        setIsAppDeleted={setIsAppDeleted}
      />
      <Grid container>
        <Grid item xs={12}>
          <ModuleTitleUI
            classes={{ module_box: classes.moduleTitle }}
            title="Aplicaciones"
          />
          <p className={classes.cardSubtitle}>
            Ampliá los límites de tu tienda online con estas funcionalidades.
            Aquí vas a encontrar herramientas que te ayudarán a hacer crecer tu
            negocio.
          </p>
          {/* Aplicaciones Instaladas */}
          <Grid container className={classes.appSectionContainer}>
            <h3 className={classes.appTitleSection}>Mis aplicaciones</h3>
            <Grid item xs={12} className={classes.myAppsContainer}>
              <ToggleUI show={!hasInstalledApps}>
                <CardModuleUI>
                  <div className={classes.myAppsCard}>
                    <Icon>library_add</Icon>
                    <span className={classes.myAppsText}>
                      Llevá tu tienda al próximo nivel con la integración de
                      aplicaciones.
                    </span>
                  </div>
                </CardModuleUI>
              </ToggleUI>
              <ToggleUI show={hasInstalledApps}>
                <AppInstalledList
                  appList={appList}
                  appActivesList={appActivesList}
                  handleOpenModal={handleOpenModal}
                  history={history}
                />
              </ToggleUI>
            </Grid>
          </Grid>

          <Grid container>
            <h3 className={classes.appTitleSection}>
              Explorá nuestras aplicaciones
            </h3>
            <Grid item xs={12}>
              <Divider className={classes.cardDivider} variant="fullWidth" />
            </Grid>
          </Grid>

          {/* Para Instalar */}
          <AppListForInstall
            appList={appList}
            appActivesList={appActivesList}
            handleOpenModal={handleOpenModal}
            history={history}
          />
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  moduleTitle: {
    marginBottom: '16px',
    '& div': {
      marginBottom: '0px',
    },
  },
  appSectionContainer: {
    marginBottom: '24px',
  },
  cardSubtitle: {
    fontSize: '1rem',
    fontWeight: '400',
    color: '#3A3A3A',
    marginTop: '0px',
    marginBottom: '48px',
    [theme.breakpoints.up('sm')]: { marginBottom: '32px' },
  },
  appTitleSection: {
    fontSize: '1.12rem',
    fontWeight: '600',
    marginTop: '0px',
    marginBottom: '16px',
  },
  myAppsContainer: {
    marginBottom: '0px',
  },
  myAppsCard: {
    display: 'flex',
    alignItems: 'center',
  },
  myAppsText: {
    fontSize: '1rem',
    fontWeight: '400',
    color: '#3A3A3A',
    marginLeft: '16px',
  },
  cardDivider: {
    marginBottom: '24px',
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationsActives: (callback) =>
      dispatch(aplicacionesActivasObtener(callback)),
    getApplications: (callback) => dispatch(aplicacionesObtener(callback)),
    getPermissions: (callback) =>
      dispatch(aplicacionesPermisosObtener(callback)),
  };
};

export const Applications = withStyles(styles)(
  connect(null, mapDispatchToProps)(Applications_)
);
