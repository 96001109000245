import { Box, Grid } from '@mui/material';
import React from 'react';
import { InfoText } from '../InfoText';
import { ImageUI, InputUI, SelectUI } from '../../../../components/common';
import { Config, Validate } from '../../../../other';
import { InputColorCircle } from '../InputColorCircle';
import { useTheme } from '@emotion/react';
import { blockAlignmentOptions, blockSpacingOptions } from '../../jsonSelects';

export const ImagesWithLinkGrid = ({
  block,
  handleChangeGridDesign,
  handleChangeBlock,
  handleChangeFileBlock,
  handleChangeSelectBlock,
  handlerShowAlertUI,
}) => {
  const theme = useTheme();
  const getImagesQuantity = (gridType) => {
    if (gridType === 0) {
      return 2;
    } else if (Validate.in_array(gridType, [1, 2])) {
      return 3;
    } else if (Validate.in_array(gridType, [3, 4])) {
      return 4;
    } else {
      return 5;
    }
  };

  const grids = [0, 1, 2, 3, 4, 5];
  const gridType = block.bl_data.type;
  const gridMaxFiles = getImagesQuantity(gridType.value);
  const htmlGrids = grids.map((type) => {
    return (
      <Grid item xs={6} key={type}>
        <Box
          sx={{
            border: `2px solid ${
              type === gridType.value
                ? theme.palette.primary.main
                : 'transparent'
            }`,
            lineHeight: 0,
            borderRadius: '4px',
          }}
        >
          <Box
            component="img"
            alt="grid"
            src={`${Config.CLOUDFRONT_CDN_PROD}/panel/builder/grid/grid-${type}.png`}
            onClick={() =>
              handleChangeGridDesign(
                type,
                gridType.change_param,
                getImagesQuantity(type)
              )
            }
            onKeyDown={({ code }) =>
              code === 'Enter' &&
              handleChangeGridDesign(
                type,
                gridType.change_param,
                getImagesQuantity(type)
              )
            }
            sx={{
              width: '100%',
              height: 'auto',
              cursor: 'pointer',
              lineHeight: 0,
              borderRadius: '4px',
            }}
            tabIndex={0}
          />
        </Box>
      </Grid>
    );
  });

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText title="Título (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.title_align}
              options={blockAlignmentOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Texto (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.text_align}
              options={blockAlignmentOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Tipo de diseño" />
        <Grid container spacing={0.5}>
          {htmlGrids}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Imágenes" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              enable_title
              enable_url
              data={block.bl_data.items}
              max_files={gridMaxFiles}
              max_size={4}
              allowed_files={['jpg', 'png', 'jpeg', 'gif']}
              input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
              handleChange={handleChangeFileBlock}
              cdn_prefix={Config.CLOUDFRONT_BLOCKS_CDN}
              upload_path="block"
              row_height="76px"
              reducer_max_width={960}
              reducer_max_height={960}
              xs={4}
              sm={4}
              md={4}
              lg={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Adicionales" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.background}
              adornment={
                <InputColorCircle color={block.bl_data.background.value} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.section_size}
              options={blockSpacingOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
