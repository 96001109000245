/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const dominiosReducer = (state = initState, action) => {
  let dominios;
  switch (action.type) {
    case Reducers.DOMINIOS_GUARDAR:
      return action.data;
    case Reducers.DOMINIO_AGREGAR:
      return [...state, action.data];
    case Reducers.DOMINIO_EDITAR:
      let dominio_editado = action.data;
      dominios = state.map((dominio) => {
        return dominio.d_nombre === dominio_editado.d_nombre
          ? dominio_editado
          : dominio;
      });
      return dominios;
    case Reducers.DOMINIO_MIGRAR:
      let dominio_migrado = action.data;
      dominios = state.map((dominio) => {
        return dominio.d_pago === 0
          ? {
              ...dominio,
              d_nombre: dominio_migrado.d_nombre,
            }
          : dominio;
      });
      return dominios;
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { dominiosReducer };
