import { Avatar, Grid } from '@mui/material';
import React from 'react';
import { withStyles } from '@mui/styles';

const AvatarUI_ = ({ classes, children, className, color }) => (
  <Grid
    className={className}
    container
    justifyContent="center"
    alignItems="center"
  >
    <Avatar
      className={`${classes.avatar} ${
        color === 'blue' ? classes.avatarBlue : classes.avatarDefault
      }`}
    >
      {children}
    </Avatar>
  </Grid>
);

const styles = () => ({
  avatar: {
    width: 62,
    height: 62,
    margin: '0 0 0 12px',
  },
  avatarBlue: {
    backgroundColor: '#426EFC',
  },
  avatarDefault: {
    backgroundColor: '#F2F2F2',
  },
});

export const AvatarUI = withStyles(styles)(AvatarUI_);
