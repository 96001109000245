import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../common';

const ModalSolicitud = (props) => {
  let {
    solicitud_mayorista,
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id={
        solicitud_mayorista.cl_mayorista
          ? 'solicitud_mayorista_aceptar'
          : 'solicitud_mayorista_rechazar'
      }
      title={
        solicitud_mayorista.cl_mayorista
          ? 'Aceptar solicitud'
          : 'Rechazar solicitud'
      }
      modalHandler={modalHandler}
      aditional_param={
        solicitud_mayorista.cl_mayorista
          ? 'solicitud_mayorista_aceptar'
          : 'solicitud_mayorista_rechazar'
      }
      handleSubmit={handleSubmit}
      button_label={solicitud_mayorista.cl_mayorista ? 'Aceptar' : 'Rechazar'}
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            {solicitud_mayorista.cl_mayorista
              ? '¿Está seguro que quiere aceptar la solicitud mayorista de ' +
                solicitud_mayorista.cl_nombre +
                '? Su cuenta pasará a ser mayorista, además podrá comenzar a comprar sus productos mayoristas y le notificaremos vía email.'
              : '¿Está seguro que quiere rechazar la solicitud mayorista de ' +
                solicitud_mayorista.cl_nombre +
                '? Su cuenta seguirá siendo minorista y le notificaremos vía email.'}
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalSolicitud };
