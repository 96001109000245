import React from 'react';
import { ButtonUI } from '../../common';
import {
  applications_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../amplitude';
import { Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const CardAplication_ = ({ app, classes, handleOpenModal, history }) => {
  const { name, logo, description } = app;

  const handleMoreInfo = () => {
    createAmplitudeEventWithDevice(
      applications_amplitude_events.aplicaciones_conocer_mas.event_name,
      {
        aplicacion: `(${name})`,
      }
    );
    history.push(`/aplicaciones/${name}`);
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <Grid item xs={12} className={classes.container}>
          <Grid item className={classes.containerImage}>
            <img src={logo} width="69" height="69" />
            <div className={classes.containerText}>
              <span className={classes.appTitle}>{name}</span>
              <div className={classes.containerDescription}>
                <p className={classes.text} style={{ WebkitLineClamp: 3 }}>
                  {description}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" className={classes.containerButtons}>
          <Grid item xs={6} md={6}>
            <ButtonUI
              type="callf"
              label="Ver más"
              variant="text"
              fullWidth={true}
              onClickFunc={handleMoreInfo}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <ButtonUI
              type="callf"
              label="Instalar"
              onClickFunc={handleOpenModal}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  card: { ...theme.card.card, minWidth: '340px' },
  card_content: {
    ...theme.card.card_content,
    padding: '16px 18px',
    '&:last-child': {
      padding: '16px 18px',
    },
  },
  container: {
    margin: '0px 0px 24px 0px',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  containerImage: {
    display: 'flex',
    alignItems: 'center',
  },
  containerText: {
    marginLeft: '11px',
    maxWidth: '207px',
  },
  appTitle: {
    fontSize: '1.12rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    fontWeight: '600',
    color: '#3a3a3a',
  },
  containerDescription: {
    maxWidth: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '308px',
    },
  },
  text: {
    color: '#3a3a3a',
    fontSize: '0.87rem',
    fontWeight: 400,
    margin: '3px 0px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  containerButtons: {
    gap: '5px',
  },
});

export const CardAplication = withStyles(styles)(CardAplication_);
