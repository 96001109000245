import { useContext, useEffect, useState } from 'react';
import { AmplitudeContext, createAmplitudeEvent } from './AmplitudeProvider';
import { Validate } from '../../other';
import useWindowSize from './useWindowSize';

const useAmplitudeEvent = (eventName) => {
  const { width } = useWindowSize();
  const AmplitudeData = useContext(AmplitudeContext);
  const [eventSend, setEventSend] = useState(false);
  const device = Validate.is_mobile() ? 'mobile' : 'desktop';

  useEffect(() => {
    if (AmplitudeData.isInitiated && width > 0 && !eventSend) {
      createAmplitudeEvent(eventName, { device });
      setEventSend(true);
    }
  }, [AmplitudeData.isInitiated, width]);
  return eventSend;
};

export default useAmplitudeEvent;
