import React from 'react';
import { withStyles } from '@mui/styles';
import { InputLabel } from './InputLabel';

const CreditCardInput_ = ({ children, classes, inputProps, error }) => {
  return (
    <>
      <InputLabel
        className={`${classes.label} ${error && classes.error}`}
        label={inputProps.label}
      />
      {inputProps?.startAdornment}
      {children}
    </>
  );
};

const styles = () => ({
  label: {
    border: 0,
    top: '-7px',
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'absolute',
    fontSize: '0.75rem',
    padding: '0 4px 0 4px',
    backgroundColor: 'white',
  },
  error: {
    color: '#f44336',
  },
});

export const CreditCardInput = withStyles(styles)(CreditCardInput_);
