import React from 'react';
import { createAmplitudeEventWithDevice } from './AmplitudeProvider';
export const AmplitudeRenderErrorUI = (props) => {
  const { type, open, amplitude_event_failed } = props;

  if (open && type === 'error') {
    createAmplitudeEventWithDevice(
      amplitude_event_failed.event_name,
      amplitude_event_failed.event_params
    );
  }

  return <></>;
};
