/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const localReducer = (state = initState, action) => {
  let locales;
  switch (action.type) {
    case Reducers.LOCAL_GUARDAR:
      locales = action.data;
      return locales;

    case Reducers.LOCAL_AGREGAR:
      let nuevo_local = action.data;
      locales = [...state, nuevo_local];
      return locales;

    case Reducers.LOCAL_EDITAR:
      let local_editado = action.data;
      locales = state.map((local) => {
        if (local.idLocales === local_editado.idLocales) {
          return local_editado;
        } else {
          return local;
        }
      });
      return locales;

    case Reducers.LOCAL_BORRAR:
      let local_borrado = action.data;
      locales = state.filter((local) => {
        return local.idLocales !== local_borrado.idLocales;
      });
      return locales;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { localReducer };
