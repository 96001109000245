import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../../components/common';

const ModalAgregar = ({
  agregar,
  handleSubmit,
  handleChange,
  modalHandler,
  modal_state,
  form_state,
}) => {
  return (
    <ModalUI
      open={modal_state}
      id="agregar"
      title={
        agregar.c_padre.value ? 'Agregar subcategoría' : 'Agregar categoría'
      }
      subtitle={
        agregar.c_padre.value
          ? 'Dentro de ' + agregar.c_padre.c_nombre_padre
          : ''
      }
      modalHandler={modalHandler}
      aditional_param="agregar"
      handleSubmit={handleSubmit}
      button_label="Agregar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={agregar.c_nombre} />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalAgregar };
