import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Validate } from '../../../../other';

const EnviosPersonalizadosList_ = ({
  envio_personalizado,
  modalHandler,
  classes,
}) => {
  const mobile = Validate.is_mobile();
  const router = useHistory();

  const getShipmentType = (ep_cobro_envio) => {
    switch (ep_cobro_envio) {
      case 0:
        return 'Envío gratuito';
      case 1:
        return 'Envío con costo fijo (pago online)';
      case 2:
        return 'Envío con costo fijo (pago en destino)';
      case 3:
        return 'Envío sin costo fijo (pago en destino)';
    }
  };

  const getGeographicRange = (personalizedShipment) => {
    switch (personalizedShipment.ep_filtrado_geografico) {
      case 0:
        return (
          <span className={classes.span}>
            Método habilitado para todo el país
          </span>
        );
      case 1:
        return personalizedShipment.provincias.map((provincia) => {
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={provincia.pro_nombre}
              key={provincia.idProvincias}
            />
          );
        });
      case 2:
        return personalizedShipment.ciudades.map((ciudad) => {
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={ciudad.ciu_nombrecompleto}
              key={ciudad.idCiudades}
            />
          );
        });
    }
  };

  const renderList = (personalizedShipments, modalHandler) => {
    if (personalizedShipments.length > 0) {
      return personalizedShipments.map((enviope, index) => {
        return (
          <ListItem
            divider={index < personalizedShipments.length - 1}
            key={enviope.idEnviosPersonalizados}
          >
            {!mobile ? (
              <ListItemAvatar>
                <Avatar>
                  <Icon>local_shipping</Icon>
                </Avatar>
              </ListItemAvatar>
            ) : (
              ''
            )}
            <ListItemText
              primary={enviope.ep_nombre}
              secondary={
                <span className={classes.span}>
                  <span className={classes.span}>
                    Tipo: {getShipmentType(enviope.ep_cobro_envio)}
                  </span>

                  {enviope.ep_cobro_envio === 1 ||
                  enviope.ep_cobro_envio === 2 ? (
                    <span className={classes.span}>
                      Costo: ${Validate.number_format(enviope.ep_precio)}
                    </span>
                  ) : (
                    ''
                  )}

                  <span className={classes.span}>
                    Pesos permitidos:{' '}
                    {enviope.ep_filtrar_peso
                      ? enviope.ep_peso_minimo +
                        'kg a ' +
                        enviope.ep_peso_maximo +
                        'kg'
                      : 'Todos'}
                  </span>

                  <span className={classes.span}>
                    Precio mínimo de compra:{' '}
                    {enviope.ep_filtrar_precio
                      ? '$' + Validate.number_format(enviope.ep_precio_minimo)
                      : 'No'}
                  </span>

                  {getGeographicRange(enviope)}
                </span>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                size="large"
                aria-label="Editar"
                onClick={() =>
                  router.push(
                    `/metodos-envio/envios-personalizados/${enviope.idEnviosPersonalizados}`
                  )
                }
              >
                <Icon>edit</Icon>
              </IconButton>
              <IconButton
                aria-label="Borrar"
                onClick={() => modalHandler(true, 'borrar', enviope)}
                size="large"
              >
                <Icon>delete</Icon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      });
    } else {
      return (
        <ListItem>
          <ListItemText
            primary="No tenés métodos de envíos personalizados activos"
            secondary="Puedes agregar uno haciendo click en Agregar"
          />
        </ListItem>
      );
    }
  };

  return (
    <List classes={{ root: classes.list }}>
      {renderList(envio_personalizado, modalHandler)}
    </List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  chip: theme.chip,
});

const mapStateToProps = (state) => {
  return {
    envio_personalizado: state.envio_personalizado,
  };
};

const mapDispatchToProps = null;

export const EnviosPersonalizadosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EnviosPersonalizadosList_));
