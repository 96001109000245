import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ModuleTitleUI, CardModuleUI } from '../../common';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';
import { useHistory } from 'react-router-dom';

export const UnexpectedError = () => {
  const router = useHistory();
  useEffect(() => {
    createAmplitudeEventWithDevice(
      import_amplitude_events.confirmar_importacion_failed.event_name,
      import_amplitude_events.confirmar_importacion_failed.event_params
    );
  }, []);

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <ModuleTitleUI title="Error inesperado" />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: { xs: '0.87rem', md: '1.25rem' },
                    lineHeight: { xs: '20px', md: '23px' },
                    color: '#000000',
                    marginBottom: { xs: '0px', md: '24px' },
                  }}
                >
                  Lo sentimos, ocurrió un error al cargar tus productos{' '}
                </Typography>
                <Typography>
                  Se detectó un error inesperado que impide que continuemos el
                  proceso. Por favor, vuelve a intentarlo.{' '}
                </Typography>
              </Grid>
            </Grid>
          </CardModuleUI>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mt="20px"
          >
            <Box>
              <Grid item>
                <Button
                  fullWidth={false}
                  onClick={() => router.push('/productos')}
                  variant="contained"
                >
                  Volver al inicio
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
