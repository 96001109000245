import React, { useState, useEffect } from 'react';
import { Icon, Grid, Box } from '@mui/material';
import {
  InputUI,
  CardModuleUI,
  ModuleTitleUI,
  ButtonUI,
} from '../../../common';
import { CardText, Page } from '../../subcomponents';
import { Regex, RegexExtra } from '../../../../other';
import { ConfirmChanges } from './ConfirmChanges';

export const FilterProduct = ({
  new_products,
  edited_products,
  handleConfirmarProceso,
  handleVolverASubir,
}) => {
  const [filterInputState, setFilterInputState] = useState({
    auth: false,
    validate: false,
    required: true,
    disabled: false,
    error: false,
    type: 'text',
    label: 'Buscar por nombre',
    id: 'search',
    name: 'search',
    change_param: 'filter',
    regex: Regex.NOMBRE_PRODUCTO,
    value: '',
    default_value: '',
    placeholder: 'Nombre del producto',
    messages: {
      error: '',
      error_extra: RegexExtra.NOMBRE_PRODUCTO,
    },
  });

  const [newProductsFiltered, setNewProductsFiltered] = useState([]);
  const [editedProductsFiltered, setEditedProductsFiltered] = useState([]);

  useEffect(() => {
    filterProducts();
  }, [newProductsFiltered, editedProductsFiltered]);

  const filterProducts = () => {
    const newProducts = new_products.filter((chunk_product) => {
      return chunk_product.p_nombre
        .toLowerCase()
        .indexOf(filterInputState.value.toLowerCase()) >= 0
        ? true
        : false;
    });

    const editedProducts = edited_products.filter((chunk_product) => {
      return chunk_product.p_nombre
        .toLowerCase()
        .indexOf(filterInputState.value.toLowerCase()) >= 0
        ? true
        : false;
    });

    setNewProductsFiltered(newProducts);
    setEditedProductsFiltered(editedProducts);
  };

  const handleGetNewProductsFiltered = () => {
    return newProductsFiltered;
  };

  const handleGetEditedProductsFiltered = () => {
    return editedProductsFiltered;
  };

  const handleChange = (e, blur) => {
    if (!blur) {
      const value = e.target.value;
      setFilterInputState((prevState) => ({
        ...prevState,
        value: value,
      }));
    }
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <ModuleTitleUI title="Confirme los cambios realizados" />
      </Grid>
      <Grid item xs={12}>
        <CardModuleUI>
          <Grid container>
            <Grid item xs={12}>
              <CardText text="Confirme para continuar con el proceso de importación de productos o vuelva a subir la planilla." />
            </Grid>
            <Grid mb="39px" item xs={12}>
              <InputUI
                handleChange={handleChange}
                input={filterInputState}
                end_adornment={<Icon>search</Icon>}
              />
            </Grid>
            <Grid item xs={12}>
              <ConfirmChanges
                handleGetNewProductsFiltered={handleGetNewProductsFiltered}
                handleGetEditedProductsFiltered={
                  handleGetEditedProductsFiltered
                }
              />
            </Grid>
          </Grid>
        </CardModuleUI>
      </Grid>
      <Grid item xs={12}>
        <Box mt="10px" display={{ xs: 'none', md: 'block' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Page firstvalue="3" lastvalue="de 4" />
            </Grid>
            <Grid item>
              <ButtonUI
                type="callf"
                fullWidth={false}
                variant="text"
                label="Volver a subir el excel"
                onClickFunc={() => handleVolverASubir()}
              />
              <ButtonUI
                type="callf"
                fullWidth={false}
                variant="contained"
                label="Confirmar"
                onClickFunc={() => handleConfirmarProceso()}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt="36px" display={{ xs: 'block', md: 'none' }}>
          <Grid container>
            <Grid item xs={12}>
              <ButtonUI
                type="callf"
                fullWidth
                variant="contained"
                label="Confirmar"
                onClickFunc={(e) => handleConfirmarProceso()}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonUI
                type="callf"
                fullWidth
                variant="text"
                label="Volver a subir el excel"
                onClickFunc={() => handleVolverASubir()}
              />
            </Grid>
            <Grid item xs={12} mt="36px">
              <Page firstvalue="3" lastvalue="de 4" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
