import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarSeoDescripcion,
} from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
} from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const SeoDescripcionPage = ({
  classes,
  getShop,
  shop,
  updateSeoDescription,
}) => {
  const [seoDescription, setSeoDescription] = useState({
    t_seo_descripcion: {
      auth: true,
      validate: true,
      required: false,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Descripción',
      id: 't_seo_descripcion',
      name: 't_seo_descripcion',
      change_param: 'seo_descripcion',
      regex: Regex.DESCRIPCION_0_200,
      value: '',
      default_value: '',
      placeholder: ' ',
      messages: {
        error: 'Ingrese una descripción válida',
        help: 'Una buena descripción no debe exceder los 200 caracteres',
        error_extra: RegexExtra.DESCRIPCION_0_200,
      },
    },
  });
  const [loading, setLoading] = useState({
    seoDescription: true,
    forms: false,
  });
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    getShop((err, resp) => {
      if (err) {
        setLoading((prevState) => ({
          ...prevState,
          seoDescription: false,
        }));
        setError(true);
      } else {
        const shopAux = shop && resp.data;
        Validate.cargar_formulario_sin_nulos(
          shopAux,
          seoDescription,
          (seoDescription_obj) => {
            setSeoDescription(seoDescription_obj);
            setLoading((prevState) => ({
              ...prevState,
              seoDescription: false,
            }));
          }
        );
      }
    });
  }, []);

  const handleChange = (e, blur, _aditional) => {
    const { name, value } = e.target;
    const newSeoDescriptionValues = {
      ...seoDescription,
      [name]: {
        ...seoDescription[name],
        value: value,
      },
    };

    Validate.validate_input(newSeoDescriptionValues[name], blur, (input) => {
      setSeoDescription((prevState) => ({
        ...prevState,
        [name]: input,
      }));
    });
  };

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading((prevState) => ({
      ...prevState,
      forms: true,
    }));

    const form = seoDescription;

    if (Validate.validar_formulario(form)) {
      updateSeoDescription(
        {
          t_seo_descripcion: form.t_seo_descripcion.value,
        },
        (err, resp) => {
          setLoading((prevState) => ({
            ...prevState,
            forms: false,
          }));

          setAlert({
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setSeoDescription(new_form);
        setLoading((prevState) => ({
          ...prevState,
          forms: false,
        }));
      });
    }
  };

  return (
    <WrapperLayout loading={loading.seoDescription} error={error}>
      <AlertUI
        open={alert.open}
        type={alert.type}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <ModuleTitleUI
              title="Descripción de la tienda"
              subtitles={[
                'La descripción de la tienda es el texto que aparece debajo del título en los resultados de los buscadores, una buena descripción ayuda a mejorar el posicionamiento en los mismos.',
              ]}
            />
            <Card className={classes.card}>
              <CardContent className={classes.card_content}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <InputUI
                      multiline
                      handleChange={handleChange}
                      input={seoDescription.t_seo_descripcion}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <ModuleButtonBoxUI>
              <ButtonUI
                type="submit"
                label="Guardar"
                minHeight={true}
                isLoading={loading.forms}
              />
            </ModuleButtonBoxUI>
          </form>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
});

const mapStateToProps = (state) => {
  return {
    shop: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
    updateSeoDescription: (data, callback) =>
      dispatch(tiendaActualizarSeoDescripcion(data, callback)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SeoDescripcionPage)
);
