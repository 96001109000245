import React from 'react';
import { Box } from '@mui/material';
import { StepContainer, StepContent, StepContentTitle } from './styles';
import ChevronRight from '@mui/icons-material/ChevronRight';

const NotificationItem = ({
  color,
  isDisabled,
  icon: Icon,
  contentText,
  handleOnClick,
}) => {
  return (
    <StepContainer
      colorText={color}
      tabIndex={isDisabled ? -1 : 0}
      role={isDisabled ? undefined : 'button'}
      onClick={isDisabled ? undefined : handleOnClick}
    >
      <StepContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Icon htmlColor={color} />

          <Box ml={2}>
            <StepContentTitle component="h3">{contentText}</StepContentTitle>
          </Box>
        </Box>

        <Box>
          <ChevronRight />
        </Box>
      </StepContent>
    </StepContainer>
  );
};

export default NotificationItem;
