import { Grid, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ButtonUI, SelectUI, ToggleUI } from '../../components/common';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import { connect } from 'react-redux';
import {
  planObtenerCiudades,
  planObtenerEstados,
  tiendaActualizarDatosFacturacion,
  tiendaObtenerMiPlan,
} from '../../store/actions';
import inputDefaultProps from '../../components/plan/metodo-pago/suscripcion/inputDefaultProps.json';
import { InputText } from './InputText';
import { withStyles } from '@mui/styles';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../../components/amplitude';

const DEFAULT_INPUT_VALUES = {
  ARG: {
    df_nombre: { label: 'Nombre completo / Razón social' },
    df_apellido: {},
    df_tipo_documento: {},
    df_documento: { label: 'DNI / CUIT' },
    df_provincia: { label: 'Provincia' },
    df_ciudad: {},
  },
  COL: {
    df_nombre: { label: 'Nombres', placeholder: 'Nombres' },
    df_apellido: { label: 'Apellidos', placeholder: 'Apellidos' },
    df_tipo_documento: {
      label: 'Tipo de documento',
    },
    df_documento: {
      label: 'Número de identidad',
      placeholder: 'Ingresar números',
      help: 'Ingresa entre 5 y 10 dígitos.',
    },
    df_provincia: {
      label: 'Departamento',
    },
    df_ciudad: {
      label: 'Ciudad / Región',
    },
  },
};

const DOC_TYPES = [
  {
    label: 'CC',
    value: 'CC',
  },
  {
    label: 'CE',
    value: 'CE',
  },
  /*   {
    label: 'PPT',
    value: 'PPT',
  }, */
];

const getFormatState = (docType) => {
  return {
    regex:
      docType === 'PPT'
        ? Regex.DOCUMENTO_COLOMBIA_PPT
        : Regex.DOCUMENTO_COLOMBIA,
    message:
      docType === 'PPT'
        ? RegexExtra.DOCUMENTO_COLOMBIA_PPT
        : RegexExtra.DOCUMENTO_COLOMBIA,
  };
};

const FormBillingData_ = ({
  classes,
  history,
  setError,
  setAlert,
  idCountry,
  getCities,
  getStates,
  isNewUser,
  updateBillData,
  datosFacturacion,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [allStates, setAllStates] = useState([
    { label: 'Seleccionar', value: '-' },
  ]);
  const [allCities, setAllCities] = useState([
    { label: 'Seleccionar', value: '-' },
  ]);

  const [inputNroDocumento, setInputNroDocumento] = useState({
    ...inputDefaultProps,
    label: DEFAULT_INPUT_VALUES[idCountry].df_documento?.label,
    id: 'df_documento',
    name: 'df_documento',
    change_param: 'datosfacturacion',
    placeholder: 'Ingresar números',
    regex: Regex.CUIT,
    messages: {
      help: DEFAULT_INPUT_VALUES[idCountry].df_documento?.help,
      error: 'Ingresá un número de DNI o CUIT válido.',
      error_extra: RegexExtra.CUIT,
    },
  });
  const [inputNombre, setInputNombre] = useState({
    ...inputDefaultProps,
    label: DEFAULT_INPUT_VALUES[idCountry].df_nombre?.label,
    placeholder: DEFAULT_INPUT_VALUES[idCountry].df_nombre?.placeholder || '',
    id: 'df_nombre',
    name: 'df_nombre',
    change_param: 'datosfacturacion',
    regex: Regex.NOMBRE_3_45[idCountry],
    messages: {
      error: 'Ingresá un nombre válido.',
      error_extra: RegexExtra.NOMBRE_3_45[idCountry],
    },
  });
  const [selectedState, setSelectedState] = useState({
    label: DEFAULT_INPUT_VALUES[idCountry].df_provincia?.label,
    auth: true,
    validate: false,
    required: true,
    error: false,
    id: 'df_provincia',
    name: 'df_provincia',
    change_param: 'datosFacturacion',
    value: '-',
    default_value: null,
    messages: {
      error: 'Selecciona una opción.',
    },
  });

  const [inputApellido, setInputApellido] = useState({
    ...inputDefaultProps,
    label: DEFAULT_INPUT_VALUES[idCountry].df_apellido?.label || '',
    placeholder: DEFAULT_INPUT_VALUES[idCountry].df_apellido?.placeholder || '',
    id: 'df_apellido',
    name: 'df_apellido',
    change_param: 'datosfacturacion',
    regex: Regex.APELLIDO_3_45[idCountry],
    messages: {
      error: 'Ingresá un apellido válido.',
      error_extra: RegexExtra.APELLIDO_3_45[idCountry],
    },
  });
  const [selectedDocType, setSelectedDocType] = useState({
    label: DEFAULT_INPUT_VALUES[idCountry].df_tipo_documento.label,
    placeholder:
      DEFAULT_INPUT_VALUES[idCountry].df_tipo_documento.placeholder || '',
    auth: true,
    validate: false,
    required: true,
    error: false,
    id: 'df_tipo_documento',
    name: 'df_tipo_documento',
    change_param: 'datosFacturacion',
    value: 'CC',
    default_value: null,
    messages: {
      error: 'Selecciona una opción.',
    },
  });
  const [inputTelefono, setInputTelefono] = useState({
    ...inputDefaultProps,
    label: 'Teléfono',
    placeholder: 'Ingresar teléfono',
    id: 'df_telefono',
    name: 'df_telefono',
    change_param: 'datosfacturacion',
    regex: Regex.TELEFONO_COMPUESTO_COLOMBIA,
    messages: {
      error: 'Ingresa un número de teléfono válido.',
      error_extra: '',
    },
  });
  const [selectedCity, setSelectedCity] = useState({
    label: DEFAULT_INPUT_VALUES[idCountry].df_ciudad.label,
    auth: true,
    validate: false,
    required: true,
    error: false,
    id: 'df_ciudad',
    name: 'df_ciudad',
    change_param: 'datosFacturacion',
    value: '-',
    default_value: null,
    messages: {
      error: 'Selecciona una opción.',
    },
  });
  const [inputDireccion, setInputDireccion] = useState({
    ...inputDefaultProps,
    label: 'Dirección',
    placeholder: 'Ingresar dirección',
    id: 'df_direccion',
    name: 'df_direccion',
    change_param: 'datosfacturacion',
    regex: Regex.DIRECCION_CALLE[idCountry],
    messages: {
      error: 'Ingresa una dirección válida',
      error_extra: '',
    },
  });
  const [inputNumero1, setInputNumero1] = useState({
    ...inputDefaultProps,
    label: 'Número 1',
    placeholder: 'Ingresar números',
    id: 'df_numero1',
    name: 'df_numero1',
    change_param: 'datosfacturacion',
    regex: Regex.CALLE_NRO_1,
    messages: {
      error: 'Ingresa un número válido',
      error_extra: '',
    },
  });
  const [inputNumero2, setInputNumero2] = useState({
    ...inputDefaultProps,
    label: 'Número 2',
    placeholder: 'Ingresar números',
    id: 'df_numero2',
    name: 'df_numero2',
    change_param: 'datosfacturacion',
    regex: Regex.CALLE_NRO_2,
    messages: {
      error: 'Ingresa un número válido',
      error_extra: '',
    },
  });

  //TODO: workaround para evitar un pestañeo en la carga de datos
  // deberíamos definir una forma de esperar datos dentro del WrapperLayout

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (idCountry === 'COL') {
      getStates((err, resp) => {
        if (err) {
          setError(true);
        } else {
          const statesList = [...resp.data];
          statesList.length > 0 &&
            setAllStates([
              { label: 'Seleccionar', value: '-' },
              ...statesList.map((item) => ({
                value: item.state_id,
                label: item.state,
              })),
            ]);
        }
      });

      getCities((err, resp) => {
        if (err) {
          setError(true);
        } else {
          setAllCities(resp.data);
        }
      });
    }
  }, [idCountry]);

  useEffect(() => {
    if (datosFacturacion) {
      if (idCountry === 'COL' && allStates.length > 1 && allCities.length > 1) {
        let df_nombre = String(
          datosFacturacion.df_nombre?.split(',')[0] || ''
        )?.trim();
        const df_apellido = String(
          datosFacturacion.df_nombre?.split(',')[1] || ''
        )?.trim();
        df_nombre = df_apellido ? df_nombre : '';

        const df_tipo_documento = DOC_TYPES.find(
          (el) => el.value === datosFacturacion.df_tipo_documento
        ) || { value: 'CC' };

        // ej: 123 Calle Ángel Ñandú #76 - 9920
        const [direccion = '', numeros = ''] = datosFacturacion?.df_direccion
          ? datosFacturacion.df_direccion.split('#')
          : [];
        const df_direccion = String(direccion)?.trim() || '';
        const df_numero1 = String(numeros.split('-')[0] || '')?.trim() || '';
        const df_numero2 = String(numeros.split('-')[1] || '')?.trim() || '';

        const { regex, message } = getFormatState(df_tipo_documento.value);

        setInputNombre({
          ...inputNombre,
          auth: !!datosFacturacion.df_nombre,
          value: df_nombre,
          regex: Regex.NOMBRE_3_45[idCountry],
          messages: {
            ...inputNombre.messages,
            error: 'Ingresa un nombre válido.',
            error_extra: RegexExtra.NOMBRE_3_45[idCountry],
            help: '',
          },
        });
        setInputApellido({
          ...inputApellido,
          auth: !!datosFacturacion.df_nombre,
          value: df_apellido,
          regex: Regex.APELLIDO_3_45[idCountry],
          messages: {
            ...inputApellido.messages,
            error: 'Ingresa un apellido válido.',
            error_extra: RegexExtra.APELLIDO_3_45[idCountry],
            help: '',
          },
        });
        setSelectedDocType({
          ...selectedDocType,
          value: df_tipo_documento.value,
        });
        setInputNroDocumento({
          ...inputNroDocumento,
          auth: !!datosFacturacion.df_documento,
          regex: regex,
          value:
            (datosFacturacion?.df_tipo_documento !== 'PPT'
              ? datosFacturacion.df_documento
              : '') || '',
          messages: {
            help: message,
            error: message,
            error_extra: '',
          },
        });
        setInputTelefono({
          ...inputTelefono,
          auth: !!datosFacturacion?.df_telefono,
          value: datosFacturacion?.df_telefono || '',
        });
        setSelectedState({
          ...selectedState,
          value: datosFacturacion.df_provincia_id || '-',
        });
        setSelectedCity({
          ...selectedCity,
          value: datosFacturacion.df_localidad_id || '-',
        });
        setInputDireccion({
          ...inputDireccion,
          auth: !!datosFacturacion.df_direccion,
          value: df_direccion,
        });
        setInputNumero1({
          ...inputNumero1,
          auth: !!datosFacturacion.df_direccion,
          value: df_numero1,
        });
        setInputNumero2({
          ...inputNumero2,
          auth: !!datosFacturacion.df_direccion,
          value: df_numero2,
        });
      }

      if (idCountry === 'ARG') {
        setSelectedState({
          ...selectedState,
          value: datosFacturacion.df_provincia || '-',
        });

        setInputNombre({
          ...inputNombre,
          auth: !!datosFacturacion.df_nombre,
          value: datosFacturacion.df_nombre || '',
        });

        setInputNroDocumento({
          ...inputNroDocumento,
          auth: !!datosFacturacion.df_documento,
          value: datosFacturacion.df_documento || '',
        });
      }
    }
  }, [datosFacturacion, idCountry, allStates, allCities]);

  const handleSubmitUpdateBillData = (e) => {
    e.preventDefault();

    const form = {
      ARG: {
        df_nombre: inputNombre,
        df_provincia: selectedState,
        df_documento: inputNroDocumento,
      },
      COL: {
        df_nombre: inputNombre,
        df_apellido: inputApellido,
        df_tipo_documento: selectedDocType,
        df_documento: inputNroDocumento,
        df_telefono: inputTelefono,
        df_provincia: selectedState,
        df_localidad: selectedCity,
        df_direccion: inputDireccion,
        df_numero1: inputNumero1,
        df_numero2: inputNumero2,
      },
    };

    const selectsIsOK =
      selectedState.value !== '-' &&
      (selectedCity.value !== '-' || idCountry === 'ARG');

    if (Validate.validar_formulario(form[idCountry]) && selectsIsOK) {
      setLoadingButton(true);

      const doc_len = form[idCountry].df_documento.value.length;
      const isValidDoc = {
        ARG: doc_len === 11 || doc_len === 8 || doc_len === 7,
        COL: true,
      };

      if (isValidDoc[idCountry]) {
        const formData = {
          ARG: {
            df_nombre: form[idCountry].df_nombre.value,
            df_provincia: selectedState.value,
            df_documento: form[idCountry].df_documento.value,
          },
          COL: {
            df_nombre: `${Validate.trim(
              form[idCountry].df_nombre.value
            )}, ${Validate.trim(form[idCountry].df_apellido?.value)}`,
            df_tipo_documento: form[idCountry].df_tipo_documento?.value,
            df_documento: form[idCountry].df_documento.value,
            df_provincia_id: form[idCountry].df_provincia?.value,
            df_localidad_id: form[idCountry].df_localidad?.value,
            df_telefono: form[idCountry].df_telefono?.value,
            df_direccion: `${Validate.trim(
              form[idCountry].df_direccion?.value
            )} #${Validate.trim(
              form[idCountry].df_numero1?.value
            )} - ${Validate.trim(form[idCountry].df_numero2?.value)}`,
          },
        };

        updateBillData(formData[idCountry], (err, resp) => {
          setLoadingButton(false);

          if (!err) {
            const translate = { ARG: '¡Listo!', COL: '¡Hecho!' };
            const messageSuccess = `${translate[idCountry]} Ya guardamos tus datos de facturación.`;

            createAmplitudeEventWithDevice(
              suscription_amplitude_events.plan_datos_facturacion_guardar
                .event_name,
              { success: true }
            );

            localStorage.setItem(
              'plan-alert',
              JSON.stringify({
                open: true,
                type: 'success',
                message: messageSuccess,
              })
            );

            if (isNewUser) {
              history.push('/plan/metodo-pago');
            } else {
              history.push('/plan');
            }
          } else {
            createAmplitudeEventWithDevice(
              suscription_amplitude_events.plan_datos_facturacion_guardar
                .event_name,
              { success: false }
            );

            setAlert({
              open: true,
              type: 'error',
              message: resp.message,
            });
          }
        });
      } else {
        setTimeout(() => {
          setLoadingButton(false);
          setAlert({
            open: true,
            type: 'error',
            message: 'Por favor, ingrese un documento válido',
          });
        }, 2000);
      }
    } else {
      Validate.validar_formulario_actualizar_obj(
        form[idCountry],
        (new_form) => {
          setInputNombre(new_form.df_nombre);
          setInputApellido(new_form.df_apellido);
          setInputNroDocumento(new_form.df_documento);
          setInputTelefono(new_form.df_telefono);
          setSelectedState({
            ...selectedState,
            error: selectedState.value === '-',
          });
          setSelectedCity({
            ...selectedCity,
            error: selectedCity.value === '-',
          });
          setInputDireccion(new_form.df_direccion);
          setInputNumero1(new_form.df_numero1);
          setInputNumero2(new_form.df_numero2);

          setLoadingButton(false);
        }
      );
    }
  };

  const handleChange = (e, blur) => {
    const value = e.target.value;
    const name = e.target.name;

    const statesTextInputs = {
      df_nombre: { state: inputNombre, set: setInputNombre },
      df_apellido: { state: inputApellido, set: setInputApellido },
      df_tipo_documento: {
        state: { ...selectedDocType, error: false },
        set: setSelectedDocType,
      },
      df_documento: {
        state: inputNroDocumento,
        set: setInputNroDocumento,
      },
      df_telefono: { state: inputTelefono, set: setInputTelefono },
      df_provincia: {
        state: { ...selectedState, error: false },
        set: setSelectedState,
      },
      df_ciudad: {
        state: { ...selectedCity, error: false },
        set: setSelectedCity,
      },
      df_direccion: { state: inputDireccion, set: setInputDireccion },
      df_numero1: { state: inputNumero1, set: setInputNumero1 },
      df_numero2: { state: inputNumero2, set: setInputNumero2 },
    };

    if (Object.keys(statesTextInputs).includes(name)) {
      let newInputText = {
        ...statesTextInputs[name].state,
        value: value,
      };

      if (name === 'df_provincia') {
        setSelectedCity({ ...selectedCity, value: '-' });
      }

      if (idCountry === 'COL' && name === 'df_documento') {
        const { regex, message } = getFormatState(selectedDocType.value);

        newInputText = {
          ...newInputText,
          regex: regex,
          messages: {
            help: message,
            error: message,
            error_extra: '',
          },
        };
      }

      if (name === 'df_tipo_documento') {
        const { regex, message } = getFormatState(value);

        const nroDocState = {
          ...inputNroDocumento,
          regex: regex,
          messages: {
            help: message,
            error: message,
            error_extra: '',
          },
        };

        Validate.validate_input(nroDocState, true, (input) => {
          setInputNroDocumento(input);
        });
      }

      Validate.validate_input(newInputText, blur, (input) => {
        statesTextInputs[name].set(input);
      });
    }
  };

  const getProvincias = () => {
    if (idCountry === 'ARG') {
      return Config.PROVINCIAS.ARG;
    }

    return allStates;
  };

  const getCiudades = (state_id) => {
    const cities = allCities.filter((city) => city.state_id === state_id);

    return [
      { label: 'Seleccionar', value: '-' },
      ...cities.map((item) => ({
        value: item.city_id,
        label: item.city,
      })),
    ];
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <p className={classes.subtitle}>
            Usaremos esta información para generar tu factura por el servicio de
            Empretienda.
          </p>
        </Grid>
        <Grid item xs={12} className={classes.containerRow}>
          <InputText
            inputValue={inputNombre}
            idCountry={idCountry}
            handleChange={handleChange}
          />
          <InputText
            show={idCountry === 'COL'}
            inputValue={inputApellido}
            idCountry={idCountry}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.containerRow}>
          <ToggleUI show={idCountry === 'COL'}>
            <Grid
              className={classes.inputContainer}
              item
              xs={12}
              md={idCountry === 'COL' ? 6 : 12}
            >
              <SelectUI
                data={selectedDocType}
                options={DOC_TYPES}
                handleChangeSelect={handleChange}
              />
            </Grid>
          </ToggleUI>
          <InputText
            inputValue={inputNroDocumento}
            idCountry={idCountry}
            handleChange={handleChange}
          />
        </Grid>
        <InputText
          columns={12}
          show={idCountry === 'COL'}
          inputValue={inputTelefono}
          idCountry={idCountry}
          handleChange={handleChange}
        />
        <Grid item xs={12} className={classes.containerRow}>
          <Grid
            className={classes.inputContainer}
            item
            xs={12}
            md={idCountry === 'COL' ? 6 : 12}
          >
            <SelectUI
              data={selectedState}
              options={getProvincias()}
              handleChangeSelect={handleChange}
            />
          </Grid>
          <ToggleUI show={idCountry === 'COL'}>
            <Grid
              className={classes.inputContainer}
              item
              xs={12}
              md={idCountry === 'COL' ? 6 : 12}
            >
              <SelectUI
                data={selectedCity}
                options={getCiudades(selectedState.value)}
                handleChangeSelect={handleChange}
              />
            </Grid>
          </ToggleUI>
        </Grid>
        <Grid item xs={12} className={classes.containerRow}>
          <InputText
            show={idCountry === 'COL'}
            inputValue={inputDireccion}
            idCountry={idCountry}
            handleChange={handleChange}
          />
          <InputText
            field_prefix={'#'}
            columns={3}
            show={idCountry === 'COL'}
            inputValue={inputNumero1}
            idCountry={idCountry}
            handleChange={handleChange}
          />
          <InputText
            field_prefix={'-'}
            columns={3}
            show={idCountry === 'COL'}
            inputValue={inputNumero2}
            idCountry={idCountry}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container wrap="nowrap">
        <Grid item xs={12} className={classes.containerButtons}>
          <ButtonUI
            fullWidth={false}
            type="callf"
            label="Guardar"
            isLoading={loadingButton}
            minHeight={true}
            onClickFunc={handleSubmitUpdateBillData}
            classes={{ root: classes.buttonOk }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const style = (theme) => ({
  container: {
    padding: '24px 24px 12px',
    borderRadius: '4px',
    backgroundColor: '#FFF',
  },
  buttonOk: {
    minWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '180px',
    },
  },
  containerButtons: {
    wrap: 'nowrap',
    display: 'flex',
    marginTop: '24px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  subtitle: {
    margin: '0 0 24px',
    color: '#565656',
    fontWeight: '400',
    fontSize: '.875rem',
  },
  inputContainer: {
    marginBottom: '12px',
  },
  containerRow: {
    [theme.breakpoints.up('sm')]: {
      gap: '12px',
      display: 'flex',
    },
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateBillData: (data, callback) =>
      dispatch(tiendaActualizarDatosFacturacion(data, callback)),
    getMiPlanData: (callback) => dispatch(tiendaObtenerMiPlan(callback)),
    getStates: (callback) => dispatch(planObtenerEstados(callback)),
    getCities: (callback) => dispatch(planObtenerCiudades(callback)),
  };
};

export const FormBillingData = withStyles(style)(
  connect(null, mapDispatchToProps)(FormBillingData_)
);
