import React from 'react';
import { Icon, Grid, Card, CardContent, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI } from '../../../../../components/common';
import { Validate } from '../../../../../other';

const OrdenInfoCliente_ = (props) => {
  let { venta, cliente, classes } = props;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <Grid container spacing={1.5}>
          <Grid item xl={8} xs={12}>
            <Typography className={classes.nombre_cliente}>
              {Validate.trim_ucwords(
                venta.v_facturacion_nombre + ' ' + venta.v_facturacion_apellido
              )}
            </Typography>
            <Typography className={classes.info} sx={{ whiteSpace: 'nowrap' }}>
              <span className={classes.info_title}>Email:</span>{' '}
              {cliente.cl_email}
            </Typography>
            <Typography className={classes.info}>
              <span className={classes.info_title}>Teléfono:</span>{' '}
              {venta.v_envio_telefono}
            </Typography>
          </Grid>
          <Grid item xl={4} xs={12}>
            <Grid container spacing={0.5}>
              <Grid item xl={12} xs={6}>
                <ButtonUI
                  type="link"
                  link={`https://wa.me/${Validate.normalize_phone_number(
                    venta.v_envio_telefono
                  )}`}
                  external_link
                  open_new_tab
                  variant="outlined"
                  label={
                    <span className={classes.button_icon_wrapper}>
                      <Icon className="fab fa-whatsapp" />
                      <span className={classes.button_icon_text}>WhatsApp</span>
                    </span>
                  }
                />
              </Grid>
              <Grid item xl={12} xs={6}>
                <ButtonUI
                  type="link"
                  link={`mailto:${cliente.cl_email}`}
                  external_link
                  open_new_tab
                  variant="outlined"
                  label={
                    <span className={classes.button_icon_wrapper}>
                      <Icon className="far fa-envelope" />
                      <span className={classes.button_icon_text}>E-mail</span>
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  card: {
    ...theme.card.card,
    borderLeft: '8px solid #53B755',
  },
  card_content: {
    padding: '16px',
    '&:last-child': {
      padding: '16px',
    },
  },
  nombre_cliente: {
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  info: {
    fontWeight: '300',
    marginBottom: '3px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  info_title: {
    fontWeight: '400',
  },
  button_icon_wrapper: {
    display: 'flex',
  },
  button_icon_text: {
    marginLeft: '5px',
    color: '#000',
    textTransform: 'none',
  },
});

export const OrdenInfoCliente = withStyles(styles, { withTheme: true })(
  OrdenInfoCliente_
);
