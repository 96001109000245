import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI, InputUI, ToggleUI } from '../../../../../components/common';

const ModalDevolverDinero = (props) => {
  let {
    devolver_dinero,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  let modal_description = devolver_dinero.modal_subtitle.value;

  return (
    <ModalUI
      open={modal_state}
      id="devolver_dinero"
      title="Devolver dinero"
      subtitle={
        modal_description ? '' : 'El total de la venta será devuelto al cliente'
      }
      modalHandler={modalHandler}
      aditional_param="devolver_dinero"
      handleSubmit={handleSubmit}
      button_label={modal_description ? 'Marcar devuelto' : 'Devolver dinero'}
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ToggleUI show={modal_description ? true : false}>
            <Typography>{modal_description}</Typography>
          </ToggleUI>
          <ToggleUI show={modal_description ? false : true}>
            <InputUI
              handleChange={handleChange}
              multiline
              input={devolver_dinero.devo_razon}
            />
          </ToggleUI>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalDevolverDinero };
