import React from 'react';
import { DropdownUI, MenuItemUI } from '../../../../../components/common';
import { Validate } from '../../../../../other';

//cancelar compra
const accion_cancelar_compra = (venta) => {
  let accion = [];
  if (venta.v_estado_pago !== 5) {
    switch (venta.v_metodo_pago) {
      //mp, ok
      case 1:
        if (Validate.in_array(venta.v_estado_pago, [0, 2])) {
          accion = [
            {
              modal: 'cancelar_orden',
              label: 'Cancelar orden',
              description:
                'Si el cliente ya pago, el dinero se vera reflejado en su cuenta de Mercado Pago',
            },
          ];
        } else if (venta.v_estado_pago === 1) {
          accion = [
            {
              modal: 'message',
              label: 'Cancelar orden',
              description:
                'Solo puedes devolver el dinero en las ordenes de Mercado Pago con pago finalizado',
            },
          ];
        } else {
          accion = [
            {
              modal: 'message',
              label: 'Cancelar orden',
              description:
                'Las ordenes de Mercado Pago solo pueden ser canceladas cuando estan en estado "pendiente" o "en proceso"',
            },
          ];
        }
        break;

      //efectivo
      case 2:
        accion = [
          {
            modal: 'cancelar_orden',
            label: 'Cancelar orden',
            description: '',
          },
        ];
        break;

      //acordar
      case 3:
        accion = [
          {
            modal: 'cancelar_orden',
            label: 'Cancelar orden',
            description: '',
          },
        ];
        break;

      //todo pago
      case 4:
        accion = [
          {
            modal: 'message',
            label: 'Cancelar orden',
            description: 'Las ventas con Todo Pago solo pueden ser devueltas',
          },
        ];
        break;

      //transferencia / deposito
      case 5:
        accion = [
          {
            modal: 'cancelar_orden',
            label: 'Cancelar orden',
            description: '',
          },
        ];
        break;

      //mobbex
      case 6:
        if (venta.v_estado_pago === 1) {
          let message = venta.v_stock_devuelto
            ? 'Si el cliente abono, el dinero de su pago será devuelto, en caso contrario, solo se cancelara.'
            : 'Si el cliente abono, el dinero de su pago será devuelto, en caso contrario, solo se cancelara. También se devolveran los productos de la orden al stock.';

          accion = [
            {
              modal: 'cancelar_orden',
              label: 'Cancelar orden',
              description: message,
            },
          ];
        }
        break;

      //uala bis
      case 7:
        accion = [
          {
            modal: 'message',
            label: 'Cancelar orden',
            description: 'Las ventas con Ualá Bis solo pueden ser devueltas',
          },
        ];
        break;

      default:
        break;
    }
  }
  return accion;
};

//devolver productos al stock
const accion_devolver_stock = (venta) => {
  let accion = [];
  if (venta.v_stock_devuelto === 0) {
    accion = [
      {
        modal: 'devolver_stock',
        label: 'Devolver productos al stock',
        description: '',
      },
    ];
  }
  return accion;
};

//devolver dinero
const accion_devolver_dinero = (venta) => {
  let accion = [];
  if (venta.v_estado_pago === 1) {
    switch (venta.v_metodo_pago) {
      //mercado pago
      case 1:
        accion = [
          {
            modal: 'devolver_dinero',
            label: 'Devolver dinero',
            description: '',
          },
        ];
        break;

      //todopago
      case 4:
        accion = [
          {
            modal: 'devolver_dinero',
            label: 'Devolver dinero',
            description: '',
          },
        ];
        break;

      //mobbex
      case 6:
        accion = [
          {
            modal: 'devolver_dinero',
            label: 'Devolver dinero',
            description: '',
          },
        ];
        break;

      //uala bis
      case 7:
        accion = [
          {
            modal: 'devolver_dinero',
            label: 'Devolver dinero',
            description: (
              <span>
                <strong>
                  Esta acción no devolverá el dinero, sólo marcará el pago como
                  devuelto en tu panel.
                </strong>{' '}
                Para efectivamente devolver el dinero deberás hacerlo desde el
                detalle de la operación en la app de Ualá.
              </span>
            ),
          },
        ];
        break;

      default:
        break;
    }
  }
  return accion;
};

//dar alta envio e imprimir etiqueta
const accion_alta_envio_imprimir_etiqueta = (venta) => {
  let accion = [];
  if (
    venta.v_estado_envio === 0 &&
    Validate.in_array(venta.v_metodo_envio, [4, 6])
  ) {
    let modal_string =
      venta.v_metodo_envio === 6
        ? 'alta_envio_oca_imprimir'
        : 'alta_envio_emprepack_imprimir';
    if (Validate.in_array(venta.v_metodo_pago, [1, 4, 6, 7])) {
      if (Validate.in_array(venta.v_estado_pago, [1, 3])) {
        accion = [
          {
            modal: modal_string,
            label: 'Dar alta envío e imprimir etiqueta',
            description: '',
          },
        ];
      } else if (Validate.in_array(venta.v_estado_pago, [0, 2, 4])) {
        accion = [
          {
            modal: 'message',
            label: 'Dar alta envío e imprimir etiqueta',
            description:
              'Debes esperar a que la orden haya sido abonada para poder dar de alta el envío, nosotros te notificaremos por email cuando esto suceda.',
          },
        ];
      }
    } else {
      if (venta.v_estado_pago === 1) {
        accion = [
          {
            modal: modal_string,
            label: 'Dar alta envío e imprimir etiqueta',
            description: '',
          },
        ];
      } else if (venta.v_estado_pago === 0) {
        accion = [
          {
            modal: 'message',
            label: 'Dar alta envío e imprimir etiqueta',
            description:
              'Debes colocar la orden como abonada para poder dar alta el envío.',
          },
        ];
      }
    }
  }
  return accion;
};

//imprimir etiqueta
const accion_imprimir_etiqueta = (venta) => {
  let accion = [];
  if (
    venta.v_metodo_envio === 1 &&
    Validate.in_array(venta.v_estado_pago, [1, 3])
  ) {
    accion = [
      {
        modal: 'message',
        label: 'Imprimir etiqueta de envío',
        description:
          'Las etiquetas de Mercado Envíos se imprimen desde tu cuenta de Mercado Pago',
      },
    ];
  } else if (
    venta.v_metodo_envio === 6 &&
    Validate.in_array(venta.v_estado_envio, [1, 2])
  ) {
    if (venta.v_envio_etiqueta_adicional) {
      accion = [
        {
          modal: 'link',
          label: 'Imprimir orden de admisión',
          description: venta.v_envio_etiqueta_adicional,
        },
      ];
    }
    accion = [
      ...accion,
      {
        modal: 'link',
        label: 'Imprimir etiqueta de envío',
        description: venta.v_etiqueta_envio,
      },
    ];
  } else if (
    venta.v_metodo_envio === 4 &&
    Validate.in_array(venta.v_estado_envio, [1, 2])
  ) {
    accion = [
      {
        modal: 'link',
        label: 'Imprimir etiqueta de envío',
        description: venta.v_etiqueta_envio,
      },
    ];
  }
  return accion;
};

//abonar envio y solicitar etiqueta
const accion_abonar_envio = (venta) => {
  let accion = [];
  if (venta.v_metodo_envio === 9) {
    if (Validate.in_array(venta.v_estado_pago, [1, 3])) {
      accion = [
        {
          modal: 'abonar_envio',
          label: 'Abonar envío a E-Pick y solicitar etiqueta',
          description:
            'Ahí podrás abonar el envío e imprimir la etiqueta. Si no tenés una cuenta en E-Pick, podés crear una utilizando el mismo número de celular registrado en Mi Tienda -> Métodos de envío -> E-Pick.',
        },
      ];
    } else {
      accion = [
        {
          modal: 'message',
          label: 'Abonar envío a E-Pick y solicitar etiqueta',
          description:
            'Debes esperar a que la orden haya sido abonada o marcada como abonada para poder abonar el envío.',
        },
      ];
    }
  } else if (venta.v_metodo_envio === 4 && !venta.v_envio_numero_seguimiento) {
    if (venta.v_estado_pago === 1) {
      accion = [
        {
          modal: 'abonar_envio',
          label: 'Abonar envío',
          description:
            'Te redirigiremos a un link de Mercado Pago para que abones el envío, luego, podrás imprimir la etiqueta y despacharlo.',
        },
      ];
    } else {
      accion = [
        {
          modal: 'message',
          label: 'Abonar envío',
          description:
            'Debes esperar a que la orden haya sido abonada o marcada como abonada para poder abonar el envío.',
        },
      ];
    }
  }
  return accion;
};

//notificar envio al cliente y colocar como enviado
const accion_notificar_envio_y_colocar_enviado = (venta) => {
  let accion = [];
  if (venta.v_metodo_envio !== 2 && venta.v_estado_envio < 2) {
    if (Validate.in_array(venta.v_estado_pago, [1, 3])) {
      if (Validate.in_array(venta.v_metodo_envio, [4, 6])) {
        if (venta.v_estado_envio === 1) {
          accion = [
            {
              modal: 'notificar_envio_enviado',
              label: 'Notificar envío al cliente y colocar como enviado',
              description:
                'Adicionalmente se envía el número de seguimiento (' +
                venta.v_envio_numero_seguimiento +
                ') al cliente.',
            },
          ];
        } else if (venta.v_estado_envio === 0) {
          accion = [
            {
              modal: 'message',
              label: 'Notificar envío al cliente y colocar como enviado',
              description:
                'Debes dar alta el envío e imprimir la etiqueta de envío antes de notificar el envío',
            },
          ];
        }
      } else if (Validate.in_array(venta.v_metodo_envio, [1, 3, 5])) {
        if (venta.v_estado_envio < 2) {
          accion = [
            {
              modal: 'notificar_envio_enviado',
              label: 'Notificar envío al cliente y colocar como enviado',
              description: '',
            },
          ];
        }
      } else if (venta.v_metodo_envio === 8 || venta.v_metodo_envio === 9) {
        if (venta.v_estado_envio < 2) {
          accion = [
            {
              modal: 'notificar_envio_enviado',
              label: 'Notificar al cliente y colocar como enviado',
              description: '',
            },
          ];
        }
      }
    } else if (Validate.in_array(venta.v_estado_pago, [0, 2, 4])) {
      if (Validate.in_array(venta.v_metodo_pago, [1, 4, 6, 7])) {
        accion = [
          {
            modal: 'message',
            label: 'Notificar envío al cliente y colocar como enviado',
            description:
              'Debes esperar a que el cliente abone la orden para realizar el envío, nosotros te notificaremos por email cuando esto suceda',
          },
        ];
      } else {
        accion = [
          {
            modal: 'message',
            label: 'Notificar envío al cliente y colocar como enviado',
            description:
              'Debes colocar la orden como abonada para poder notificar el envío',
          },
        ];
      }
    }
  }
  return accion;
};

//colocar como retirada
const accion_colocar_como_retirada = (venta) => {
  let accion = [];
  if (venta.v_metodo_envio === 2 && venta.v_estado_envio < 2) {
    if (Validate.in_array(venta.v_estado_pago, [1, 3])) {
      accion = [
        {
          modal: 'colocar_retirada',
          label: 'Colocar como retirada',
          description: '',
        },
      ];
    } else if (Validate.in_array(venta.v_estado_pago, [0, 2, 4])) {
      if (Validate.in_array(venta.v_metodo_pago, [1, 4, 6, 7])) {
        accion = [
          {
            modal: 'message',
            label: 'Colocar como retirada',
            description:
              'Debes esperar a que la orden este abonada para poder colocarla como retirada, nosotros te notificaremos por email cuando esto suceda.',
          },
        ];
      } else {
        accion = [
          {
            modal: 'message',
            label: 'Colocar como retirada',
            description:
              'Debes colocar la orden como abonada para poder colocarla como retirada.',
          },
        ];
      }
    }
  }
  return accion;
};

//entregar descargables
const accion_entregar_descargables = (venta) => {
  let accion = [];
  if (venta.v_metodo_envio === 7 && venta.v_estado_envio === 0) {
    if (Validate.in_array(venta.v_estado_pago, [1, 3])) {
      accion = [
        {
          modal: 'entregar_descargables',
          label: 'Entregar descargables',
          description: '',
        },
      ];
    } else if (Validate.in_array(venta.v_estado_pago, [0, 2, 4])) {
      if (Validate.in_array(venta.v_metodo_pago, [1, 4, 6, 7])) {
        accion = [
          {
            modal: 'message',
            label: 'Entregar descargables',
            description:
              'Debes esperar a que la orden este abonada para poder entregar los descargables, en caso de que los productos sean de descarga automática, se enviarán automáticamente.',
          },
        ];
      } else {
        accion = [
          {
            modal: 'message',
            label: 'Entregar descargables',
            description:
              'Debes colocar la orden como abonada para poder entregar los descargables.',
          },
        ];
      }
    }
  }
  return accion;
};

//colocar como abonada
const accion_colocar_como_abonada = (venta) => {
  let accion = [];
  if (venta.v_estado_pago !== 1) {
    accion = [
      {
        modal: 'colocar_abonada',
        label: 'Colocar como abonada',
        description: '',
      },
    ];
  }
  return accion;
};

//imprimir orden de compra
const accion_imprimir_orden_compra = () => {
  let accion = [
    {
      modal: 'imprimir_orden_compra',
      label: 'Imprimir orden de compra',
      description: '',
    },
  ];
  return accion;
};

//enviar mensaje al cliente
const accion_enviar_mensaje_cliente = () => {
  let accion = [
    {
      modal: 'enviar_mensaje_cliente',
      label: 'Enviar mensaje al cliente',
      description: '',
    },
  ];
  return accion;
};

//editar los datos de facturación
const accion_editar_datos_facturacion = () => {
  let accion = [
    {
      modal: 'editar_datos_facturacion',
      label: 'Editar datos de facturación',
      description: '',
    },
  ];
  return accion;
};

//actualizacion manual mp
const accion_actualizacion_manual_mp = (venta) => {
  let accion = [];
  if (venta.v_metodo_pago === 1) {
    accion = [
      {
        modal: 'actualizacion_manual_mp',
        label: 'Actualización manual de estado de pago',
        description: '',
      },
    ];
  }
  return accion;
};

//archivar orden
const accion_archivar_orden = (venta) => {
  let accion = [];
  if (venta.v_archivada === 0) {
    accion = [
      {
        modal: 'archivar_orden',
        label: 'Archivar orden',
        description: '',
      },
    ];
  }
  return accion;
};

//cancelar envio
const accion_cancelar_envio = (venta) => {
  let accion = [];
  if (venta.v_metodo_envio === 6 && venta.v_estado_envio >= 1) {
    accion = [
      {
        modal: 'cancelar_envio',
        label: 'Cancelar envío',
        description: '',
      },
    ];
  }
  return accion;
};

//editar datos de envio
const accion_editar_datos_envio = (venta) => {
  let accion = [];
  if (
    venta.v_estado_envio === 0 &&
    !venta.v_envio_identificador &&
    !Validate.in_array(venta.v_metodo_envio, [7, 1])
  ) {
    accion = [
      {
        modal: 'editar_datos_envio',
        label: 'Editar datos de envío',
        description: '',
      },
    ];
  }
  return accion;
};

//marcar como facturada
const accion_marcar_como_facturada = () => {
  let accion = [
    {
      modal: 'marcar_como_facturada',
      label: 'Marcar como facturada',
      description: '',
    },
  ];
  return accion;
};

const array_acciones = (venta, idCountry) => {
  let acciones = [
    ...accion_cancelar_compra(venta),
    ...accion_devolver_stock(venta),
    ...accion_devolver_dinero(venta),
    ...accion_alta_envio_imprimir_etiqueta(venta),
    ...accion_imprimir_etiqueta(venta),
    ...accion_abonar_envio(venta),
    ...accion_notificar_envio_y_colocar_enviado(venta),
    ...accion_colocar_como_retirada(venta),
    ...accion_entregar_descargables(venta),
    ...accion_colocar_como_abonada(venta),
    ...accion_imprimir_orden_compra(),
    ...accion_enviar_mensaje_cliente(),
    ...accion_actualizacion_manual_mp(venta),
    ...accion_cancelar_envio(venta),
    ...accion_marcar_como_facturada(),
  ];

  if (idCountry === 'COL') {
    acciones.push(...accion_archivar_orden(venta));
  } else {
    acciones.push(
      ...accion_editar_datos_facturacion(),
      ...accion_editar_datos_envio(venta),
      ...accion_archivar_orden(venta)
    );
  }

  return acciones;
};

const OrdenAcciones = (props) => {
  let { venta, loading, modalHandler, idCountry } = props;

  let acciones = array_acciones(venta, idCountry);

  return (
    <DropdownUI label="Acciones" isLoading={loading}>
      {acciones.map((accion, idx) => {
        if (accion.modal === 'link') {
          return (
            <MenuItemUI
              type="link"
              external
              open_new_tab
              link={accion.description}
              key={idx}
            >
              {accion.label}
            </MenuItemUI>
          );
        } else {
          return (
            <MenuItemUI
              type="callf"
              onClickFunction={() =>
                modalHandler(true, accion.modal, accion.description)
              }
              key={idx}
            >
              {accion.label}
            </MenuItemUI>
          );
        }
      })}
    </DropdownUI>
  );
};

export { OrdenAcciones };
