import React from 'react';
import { Grid } from '@mui/material';
import { InfoText } from '../InfoText';
import {
  ImageUI,
  InputUI,
  SelectUI,
  ToggleUI,
} from '../../../../components/common';
import { Config } from '../../../../other';
import { InputColorCircle } from '../InputColorCircle';
import {
  blockAlignmentOptions,
  blockSpacingOptions,
  imagesGapOptions,
  mobileRowElementsQuantity,
  rowElementsQuantity,
} from '../../jsonSelects';

export const ImagesWithLink = ({
  block,
  showAlign,
  handleChangeBlock,
  handleChangeFileBlock,
  handleChangeSelectBlock,
  handlerShowAlertUI,
}) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText title="Título (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <ToggleUI show={showAlign}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.title_align}
                options={blockAlignmentOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Texto (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
          <ToggleUI show={showAlign}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.text_align}
                options={blockAlignmentOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText
          title="Imágenes"
          subtitle="Recomendamos que todas las imágenes sean del mismo tamaño."
        />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              enable_url
              enable_title
              data={block.bl_data.items}
              max_files={6}
              max_size={4}
              allowed_files={['jpg', 'png', 'jpeg', 'gif']}
              input_allowed_mime="image/jpg,image/jpeg,image/png,image/gif"
              handleChange={handleChangeFileBlock}
              cdn_prefix={Config.CLOUDFRONT_BLOCKS_CDN}
              upload_path="block"
              row_height="76px"
              reducer_max_width={600}
              reducer_max_height={600}
              xs={4}
              sm={4}
              md={4}
              lg={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Adicionales" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.gap}
              options={imagesGapOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.per_row}
              options={rowElementsQuantity}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.per_row_mobile}
              options={mobileRowElementsQuantity}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.type}
              options={[
                {
                  value: 1,
                  label: 'Carrusel',
                },
                {
                  value: 2,
                  label: 'Grilla',
                },
                {
                  value: 3,
                  label: 'Grilla Masonry',
                },
              ]}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.background}
              adornment={
                <InputColorCircle color={block.bl_data.background.value} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.section_size}
              options={blockSpacingOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
