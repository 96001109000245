import {
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AppMenuItem } from './AppMenuItem';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import { LabelUI, SwitchUI } from '../../../components/common';
import { useHistory } from 'react-router-dom';

const DrawerLayout_ = ({
  url,
  classes,
  loading,
  offMode,
  isDebtor,
  isSuscribed,
  domainName,
  dispatchAlert,
  handleDrawerToggle,
  handleChangeOffStore,
  avoidDrawerLogic,
}) => {
  const [menuItems, setMenuItems] = useState([
    {
      id: 1,
      label: 'Mi tienda',
      icon: 'store',
      isOpen: false,
      isParentMenu: true,
      items: [
        { id: 2, label: 'Diseño', link: '/builder' },
        {
          id: 3,
          label: 'Información de mi tienda',
          link: '/informacion-tienda',
        },
        {
          id: 4,
          label: 'Activar formulario de contacto',
          link: '/formulario-contacto',
        },
        { id: 5, label: 'Agregar redes sociales', link: '/redes-sociales' },
        { id: 6, label: 'Añadir chat a mi tienda', link: '/chat' },
        { id: 7, label: 'Editar páginas', link: '/paginas' },
        {
          id: 8,
          label: 'Crear blog',
          link: '/blog',
          amplitudeEvent: () =>
            createAmplitudeEventWithDevice(
              amplitude_events.ayuda_blog.event_name
            ),
        },
        { id: 9, label: 'Apagar temporalmente', link: '/apagar-temporalmente' },
      ],
    },
    {
      id: 10,
      label: 'Gestión de ventas',
      icon: 'attach_money',
      isOpen: false,
      isParentMenu: true,
      items: [
        {
          id: 11,
          label: 'Listado de ventas',
          link: '/listado-de-ventas',
          amplitudeEvent: () =>
            createAmplitudeEventWithDevice(
              amplitude_events.ventas__panel_lateral.event_name
            ),
        },
        {
          id: 12,
          label: 'Clientes',
          link: '/clientes',
          onlyArg: true,
          amplitudeEvent: () =>
            createAmplitudeEventWithDevice(
              amplitude_events.clientes__panel_lateral.event_name
            ),
        },
        { id: 13, label: 'Métricas', link: '/metricas' },
        { id: 14, label: 'Métodos de pago', link: '/metodos-pago' },
        {
          id: 15,
          label: 'Métodos de envío',
          link: '/metodos-envio',
          onlyArg: true,
        },
        {
          id: 16,
          label: 'Crear promociones',
          isOpen: false,
          isParentSubmenu: true,
          items: [
            { id: 17, withPadding: true, label: 'Ofertas', link: '/ofertas' },
            {
              id: 18,
              withPadding: true,
              label: 'Cupones de descuento',
              link: '/cupones',
            },
          ],
        },
      ],
    },
    {
      id: 19,
      label: 'Productos',
      icon: 'shopping_basket',
      isOpen: false,
      isParentMenu: true,
      items: [
        { id: 20, label: 'Categorías', link: '/categorias' },
        { id: 21, label: 'Agregar productos', link: '/productos/agregar' },
        { id: 22, label: 'Administrar productos', link: '/productos' },
        {
          id: 23,
          label: 'Configuraciones avanzadas',
          isOpen: false,
          isParentSubmenu: true,
          items: [
            {
              id: 24,
              withPadding: true,
              label: 'Activar productos digitales',
              link: '/productos-digitales',
            },
            {
              id: 25,
              withPadding: true,
              label: 'Vender por mayor',
              link: '/venta-mayorista',
            },
            {
              id: 26,
              withPadding: true,
              label: 'Aumentar los precios',
              link: '/productos/aumento-masivo',
              onlyArg: true,
            },
            {
              id: 27,
              withPadding: true,
              label: 'Notificaciones de stock',
              link: '/notificaciones-stock',
            },
          ],
        },
      ],
    },
    {
      id: 28,
      label: 'Aplicaciones',
      link: '/aplicaciones',
      icon: 'library_add',
      isOpen: false,
      isParentMenu: true,
      newFeature: true,
      split: false,
      onlyArg: true,
    },
    {
      id: 29,
      label: 'Potenciar mi tienda',
      icon: 'trending_up',
      isOpen: false,
      isParentMenu: true,
      items: [
        {
          id: 30,
          label: 'Métricas de seguimiento',
          isOpen: false,
          isParentSubmenu: true,
          items: [
            {
              id: 31,
              withPadding: true,
              label: 'Facebook Pixel',
              link: '/facebook-pixel',
            },
            {
              id: 32,
              withPadding: true,
              label: 'Google Analitycs',
              link: '/google-analytics',
            },
          ],
        },
        {
          id: 33,
          label: 'Canales de venta',
          isOpen: false,
          isParentSubmenu: true,
          items: [
            { id: 34, withPadding: true, label: 'Meta Ads', link: '/meta-ads' },
            {
              id: 35,
              withPadding: true,
              label: 'Google Shopping',
              link: '/google-shopping',
            },
            {
              id: 36,
              withPadding: true,
              label: 'Empreshops',
              link: '/empreshops',
              onlyArg: true,
            },
          ],
        },
        {
          id: 37,
          label: 'Más configuraciones',
          isOpen: false,
          isParentSubmenu: true,
          items: [
            {
              id: 38,
              withPadding: true,
              label: 'Etiquetas HTML',
              link: '/etiquetas-html',
            },
            {
              id: 39,
              withPadding: true,
              label: 'Conectar con Pinterest',
              link: '/pinterest-tag',
            },
            {
              id: 40,
              withPadding: true,
              label: 'Email marketing',
              link: '/email-marketing',
            },
            {
              id: 41,
              withPadding: true,
              label: 'Descripción para Google',
              link: '/seo-descripcion',
            },
          ],
        },
      ],
    },
    {
      id: 42,
      label: 'Mi cuenta',
      icon: 'person',
      isOpen: false,
      isParentMenu: true,
      withMargin: true,
      items: [
        { id: 43, label: 'Mi plan', link: '/plan' },
        { id: 45, label: 'Configurar dominio', link: '/dominios' },
        { id: 46, label: 'Data fiscal', link: '/data-fiscal', onlyArg: true },
        { id: 47, label: 'Dar de baja', link: '/baja' },
      ],
    },
    {
      id: 48,
      label: 'Centro de ayuda',
      icon: 'help',
      isOpen: false,
      isParentMenu: true,
      items: [
        {
          id: 49,
          label: 'Enviar consulta',
          link: '/soporte',
          amplitudeEvent: () =>
            createAmplitudeEventWithDevice(
              amplitude_events.ayuda_enviar_consulta.event_name
            ),
        },
        { id: 50, label: 'Foro', link: '/foro', onlyArg: true },
      ],
    },
  ]);

  const history = useHistory();

  const colorButtonHome = () => {
    const colorIcon = url === '/' ? '#53B755' : '#000000';

    const colorText =
      url === '/'
        ? classes.list_item_menu_home_green
        : classes.list_item_menu_home_black;

    return (
      <>
        <ListItemIcon className={classes.list_icon}>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 19.4997V14.4997H14.0001V19.4997C14.0001 20.0497 14.4501 20.4997 15.0001 20.4997H18.0001C18.5501 20.4997 19.0001 20.0497 19.0001 19.4997V12.4997H20.7001C21.1601 12.4997 21.3801 11.9297 21.0301 11.6297L12.6701 4.09973C12.2901 3.75973 11.7101 3.75973 11.3301 4.09973L2.9701 11.6297C2.6301 11.9297 2.8401 12.4997 3.3001 12.4997H5.0001V19.4997C5.0001 20.0497 5.4501 20.4997 6.0001 20.4997H9.0001C9.5501 20.4997 10.0001 20.0497 10.0001 19.4997Z"
              fill={colorIcon}
            />
          </svg>
        </ListItemIcon>
        <ListItemText
          sx={{ paddingLeft: '20px' }}
          inset
          className={colorText}
          primary="Inicio"
        />
      </>
    );
  };

  const handlerCloseMenu = () => {
    setMenuItems((prevItems) =>
      prevItems.map((menuItem) => {
        if (menuItem.isParentMenu) {
          return {
            ...menuItem,
            isOpen: false,
          };
        }

        return menuItem;
      })
    );
  };

  const openMenuRecursive = (targetLink, items) => {
    if (!avoidDrawerLogic) {
      if (!items || !Array.isArray(items)) {
        return false;
      }

      for (const item of items) {
        if (item.link === targetLink) {
          item.isOpen = true;
          return true;
        } else if (item.items && openMenuRecursive(targetLink, item.items)) {
          item.isOpen = true;
          return true;
        }
      }
      return false;
    }
  };

  const handleMenuItemClick = (targetLink) => {
    if (!avoidDrawerLogic) {
      setMenuItems((prevItems) => {
        const updatedItems = prevItems.map((menuItem) => {
          const isOpen =
            menuItem.isParentMenu &&
            openMenuRecursive(targetLink, menuItem.items);
          return { ...menuItem, isOpen };
        });
        return updatedItems;
      });
    }
  };

  useEffect(() => {
    if (!avoidDrawerLogic) {
      const targetLink = history.location.pathname;
      if (targetLink) {
        setMenuItems((prevItems) => {
          const updatedItems = prevItems.map((menuItem) => {
            const isOpen =
              menuItem.isParentMenu &&
              openMenuRecursive(targetLink, menuItem.items);
            return { ...menuItem, isOpen };
          });
          return updatedItems;
        });
      }
    }
  }, [history.location.pathname]);

  const dispatchMessageAlert = () => {
    const message = isSuscribed
      ? 'No podrás navegar por el sitio hasta que ingrese el pago.'
      : 'No podrás navegar por el sitio hasta que completes el pago para reactivar tu tienda.';

    dispatchAlert({
      open: true,
      type: 'error',
      message: message,
    });
  };

  return (
    <div className={classes.full_container}>
      <div className={classes.toolbar_sidenav}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          size="large"
        >
          <Icon>chevron_left</Icon>
        </IconButton>
      </div>
      <div style={{ padding: '10px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={10}>
            <ListItem
              className={classes.link_home}
              button
              onClick={(e) => {
                createAmplitudeEventWithDevice(
                  amplitude_events.selecciona_home_panel.event_name,
                  amplitude_events.selecciona_home_panel.event_params
                );
                handlerCloseMenu();

                if (!isDebtor) {
                  history.push('/');
                } else {
                  dispatchMessageAlert();
                }
              }}
            >
              {colorButtonHome(url, classes)}
            </ListItem>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              color="inherit"
              aria-label="Abrir tienda"
              onClick={() => {
                createAmplitudeEventWithDevice(
                  amplitude_events.ver_tienda__panel_lateral.event_name,
                  amplitude_events.ver_tienda__panel_lateral.event_params
                );
                const link = 'https://' + domainName;
                window.open(link, '_blank');
              }}
              size="large"
            >
              <Icon>open_in_new</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} mb={0.5}>
            <Typography
              className={classes.url_text}
              variant="caption"
              gutterBottom
              noWrap
            >
              {domainName}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <LabelUI
                text={offMode === 0 ? 'Tienda Encendida' : 'Tienda Apagada'}
                variant={offMode === 0 ? 'success' : 'danger'}
                isLoading={loading.private_layout || loading.error}
              />
              <SwitchUI
                checked={offMode === 0 ? true : false}
                handleChange={handleChangeOffStore}
                disabled={
                  history.location.pathname.indexOf('importacion') > -1
                    ? true
                    : false
                }
                isLoading={loading.privateLayout || loading.error}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider />
      {loading.menu ? (
        <List className={classes.menu_items_ul}>
          <Skeleton
            style={{ margin: '8px 0' }}
            height={24}
            width={150}
            count={8}
            wrapper={ListItem}
          />
        </List>
      ) : (
        <List className={classes.menu_items_ul}>
          <div>
            {menuItems
              .filter((item) => item.id <= 41)
              .map((item, index) => (
                <AppMenuItem
                  menuItems={menuItems}
                  setMenuItems={(state) => {
                    if (!isDebtor) {
                      setMenuItems(state);
                    } else {
                      dispatchMessageAlert();
                    }
                  }}
                  {...item}
                  key={index}
                  onItemClick={() => {
                    if (!isDebtor) {
                      handleMenuItemClick(item.link);
                    } else {
                      dispatchMessageAlert();
                    }
                  }}
                />
              ))}
          </div>
          <div>
            {menuItems
              .filter((item) => item.id > 41)
              .map((item, index) => (
                <AppMenuItem
                  menuItems={menuItems}
                  setMenuItems={(state) => {
                    if (!isDebtor) {
                      setMenuItems(state);
                    } else {
                      dispatchMessageAlert();
                    }
                  }}
                  {...item}
                  key={index}
                  onItemClick={() => handleMenuItemClick(item.link)}
                />
              ))}
          </div>
        </List>
      )}
    </div>
  );
};

const styles = (theme) => ({
  full_container: {
    height: '100%',
  },
  menu_items_ul: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
  toolbar_sidenav: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '0px 5px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  nested: {
    paddingLeft: 0,
  },
  link_home: {
    paddingLeft: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.00) !important',
    },

    '&:focus': {
      backgroundColor: '#ffffff',
    },
  },
  list_item_menu_home_green: {
    '& span': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      padding: '7.5px',
      marginLeft: '16px',
    },
  },
  list_item_menu_home_black: {
    '& span': {
      color: '#000000;',
    },
    [theme.breakpoints.up('md')]: {
      padding: '7.5px',
      marginLeft: '16px',
    },
  },
  url_text: {
    paddingLeft: '5px',
    fontWeight: '600',
    display: 'block',
  },
});

export const DrawerLayout = withStyles(styles)(DrawerLayout_);
