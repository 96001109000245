import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../../../components/common';

const ModalCancelarEnvio = (props) => {
  let { handleSubmit, modalHandler, modal_state, form_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="cancelar_envio"
      title="Cancelar envío"
      subtitle="Esta función es normalmente utilizada para anular una etiqueta impresa para poder volver a generar una nueva."
      modalHandler={modalHandler}
      aditional_param="cancelar_envio"
      handleSubmit={handleSubmit}
      button_label="Cancelar envío"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            ¿Confirmás que deseas cancelar el envío? Al cancelar el envío se
            borra la etiqueta y número de seguimiento. <br />
            <br />
            Recuerda que si ya despachaste el paquete, esta acción no frena el
            envío, para ello debes comunicarte con el proveedor logístico.
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalCancelarEnvio };
