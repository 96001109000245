import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { ModalLiteUI, ToggleUI } from '../../common';
import { Validate } from '../../../other';

const ModalVerInformacion = (props) => {
  let { ver_informacion, modalHandler, modal_state } = props;

  return (
    <ModalLiteUI
      open={modal_state}
      title="Información del cliente"
      id="ver_informacion"
      modalHandler={modalHandler}
      aditional_param="ver_informacion"
    >
      <List>
        <ListItem>
          <ListItemText
            primary={
              'Nombre: ' + Validate.trim_ucwords(ver_informacion.cl_nombre)
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Apellido: ' + Validate.trim_ucwords(ver_informacion.cl_apellido)
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Email: ' + ver_informacion.cl_email} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Teléfono: ' +
              (ver_informacion.cl_telefono ? ver_informacion.cl_telefono : '-')
            }
          />
        </ListItem>
        <ToggleUI show={ver_informacion.cl_tipo_identificacion ? true : false}>
          <ListItem>
            <ListItemText
              primary={
                ver_informacion.cl_tipo_identificacion +
                ': ' +
                ver_informacion.cl_numero_identificacion
              }
            />
          </ListItem>
        </ToggleUI>
        <ListItem>
          <ListItemText
            primary={
              'Calle: ' +
              (ver_informacion.cl_calle
                ? Validate.trim_ucwords(ver_informacion.cl_calle)
                : '-')
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Número: ' +
              (ver_informacion.cl_numero
                ? Validate.trim_ucwords(ver_informacion.cl_numero)
                : '-')
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Piso: ' +
              (ver_informacion.cl_piso
                ? Validate.trim_ucwords(ver_informacion.cl_piso)
                : '-') +
              ' Dpto: ' +
              (ver_informacion.cl_dpto
                ? Validate.trim_ucwords(ver_informacion.cl_dpto)
                : '-')
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Ciudad: ' +
              (ver_informacion.cl_ciudad
                ? Validate.trim_ucwords(ver_informacion.cl_ciudad)
                : '-')
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'Provincia: ' +
              (ver_informacion.cl_provincia
                ? Validate.trim_ucwords(ver_informacion.cl_provincia)
                : '-')
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              'País: ' +
              (ver_informacion.cl_pais
                ? Validate.trim_ucwords(ver_informacion.cl_pais)
                : 'Argentina')
            }
          />
        </ListItem>
      </List>
    </ModalLiteUI>
  );
};

export { ModalVerInformacion };
