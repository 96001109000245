/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const cuponReducer = (state = initState, action) => {
  let cupones;
  switch (action.type) {
    case Reducers.CUPON_GUARDAR:
      cupones = action.data;
      return cupones;

    case Reducers.CUPON_BORRAR:
      let cupon_borrado = action.data;
      cupones = state.filter((cupon) => {
        return cupon.idCuponesDescuento !== cupon_borrado.idCuponesDescuento;
      });
      return cupones;

    case Reducers.CUPON_DESACTIVAR:
      let cupon_desactivado = action.data;
      cupones = state.map((cupon) => {
        return cupon.idCuponesDescuento === cupon_desactivado.idCuponesDescuento
          ? {
              ...cupon,
              cd_desactivado: cupon_desactivado.cd_desactivado,
            }
          : cupon;
      });
      return cupones;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { cuponReducer };
