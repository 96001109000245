import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI, SelectUI, ToggleUI } from '../../common';
import { useSelector } from 'react-redux';
import { Config } from '../../../other';

const ModalConfiguracionMayorista = (props) => {
  let {
    configuracion_mayorista,
    handleSubmit,
    handleChange,
    handleChangeSelect,
    modalHandler,
    modal_state,
    form_state,
    selects,
  } = props;
  const idCountry = useSelector((state) => state.tienda.Country_idCountry);

  return (
    <ModalUI
      open={modal_state}
      id="configuracion_mayorista"
      title="Configuración mayorista"
      modalHandler={modalHandler}
      aditional_param="configuracion_mayorista"
      handleSubmit={handleSubmit}
      button_label="Guardar"
      button_loading={form_state}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectUI
            data={configuracion_mayorista.cm_limitar_cantidad_minima}
            options={selects.opciones_limitar_cantidad}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <ToggleUI
          show={
            parseInt(configuracion_mayorista.cm_limitar_cantidad_minima.value)
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={configuracion_mayorista.cm_cantidad_minima}
              step="1"
            />
          </Grid>
        </ToggleUI>
        <Grid item xs={12}>
          <SelectUI
            data={configuracion_mayorista.cm_limitar_precio_minimo}
            options={selects.opciones_limitar_monto}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <ToggleUI
          show={
            parseInt(configuracion_mayorista.cm_limitar_precio_minimo.value)
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={configuracion_mayorista.cm_precio_minimo}
              adornment={Config.CURRENCY[idCountry].adornment}
              step={Config.CURRENCY[idCountry].formatNumber}
            />
          </Grid>
        </ToggleUI>
      </Grid>
    </ModalUI>
  );
};

export { ModalConfiguracionMayorista };
