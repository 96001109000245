import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { TextField, InputAdornment } from '@mui/material';
import { withStyles } from '@mui/styles';
import Check from '@mui/icons-material/Check';
import { Validate } from '../../other';
import { ToggleUI } from './ToggleUI';

const InputUI_ = ({
  input,
  onBlur,
  classes,
  onChange,
  step = '1',
  handleChange,
  field_prefix,
  adornment = false,
  multiline = false,
  end_adornment = false,
  adornment_extra = false,
  showDefaultAdornment = true,
}) => {
  let {
    auth,
    required,
    disabled,
    error,
    type,
    value,
    label,
    id,
    name,
    messages,
    change_param = null,
    placeholder = null,
  } = input;

  let inputProps =
    type === 'number'
      ? { step: step, className: classes.input }
      : { className: classes.input };

  id = id ? id : name + '_' + uuid().split('-')[0];

  return (
    <div className={classes.root}>
      <ToggleUI show={!!field_prefix}>
        <span className={classes.field_prefix}>{field_prefix}</span>
      </ToggleUI>
      <TextField
        id={id}
        disabled={disabled}
        classes={{ root: classes.textField }}
        variant="outlined"
        type={type}
        margin="dense"
        name={name}
        error={error}
        multiline={multiline}
        label={required ? label : label + ' (opcional)'}
        value={value}
        onChange={
          onChange ? onChange : (e) => handleChange(e, false, change_param)
        }
        onBlur={onBlur ? onBlur : (e) => handleChange(e, true, change_param)}
        placeholder={placeholder}
        inputProps={inputProps}
        helperText={
          error
            ? Validate.trim(`${messages.error} ${messages.error_extra || ''}`)
            : messages.help
            ? messages.help
            : ''
        }
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          className: classes.helper_text,
        }}
        InputProps={{
          endAdornment:
            !error && auth && !Validate.is_falsy(value) ? (
              <InputAdornment position="end">
                {end_adornment ? end_adornment : null}
                {showDefaultAdornment ? (
                  <Check classes={{ root: classes.tick }} />
                ) : null}
              </InputAdornment>
            ) : end_adornment ? (
              end_adornment
            ) : null,
          startAdornment: adornment ? (
            <InputAdornment
              sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              position="start"
            >
              {adornment}
              {adornment_extra ? adornment_extra : null}
            </InputAdornment>
          ) : null,
        }}
      />
    </div>
  );
};

const styles = () => ({
  tick: {
    color: '#9e9e9e',
    fontSize: '1.2rem',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  helper_text: {
    marginLeft: 0,
  },
  textField: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  input: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },
  field_prefix: {
    left: '2px',
    display: 'flex',
    color: '#565656',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    position: 'relative',
    alignItems: 'center',
    height: '54px',
    margin: '8px 12px 4px 0px',
  },
});

InputUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //Function used to update controlled input
  //handleChange(e,blur,change_param = null)
  handleChange: PropTypes.func.isRequired,

  //Adds an icon or text as input prefix
  adornment: PropTypes.oneOfType([
    PropTypes.bool, //if false it has no adornment
    PropTypes.string, //example: '$'
    PropTypes.element, //example: <Icon/>
  ]),

  //Adds a second icon or text as input prefix (adornment has to exist)
  adornment_extra: PropTypes.oneOfType([
    PropTypes.bool, //if false it has no adornment
    PropTypes.string, //example: '$'
    PropTypes.element, //example: <Icon/>
  ]),

  //Adds an icon or text as input suffix
  end_adornment: PropTypes.oneOfType([
    PropTypes.bool, //if false it has no adornment
    PropTypes.string, //example: 'kg'
    PropTypes.element, //example: <Icon/>
  ]),

  //Show an check icon for default as input suffix when it is validated
  //Default: true
  showDefaultAdornment: PropTypes.bool,

  //false for input and true for textarea
  //Default: false
  multiline: PropTypes.bool,

  //defines de step of a input of type number
  step: PropTypes.string,

  //The object initialized in state
  input: PropTypes.shape({
    auth: PropTypes.bool.isRequired,
    validate: PropTypes.bool.isRequired, //determina si hay que validarlo en submit
    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    type: PropTypes.oneOf([
      'text',
      'number',
      'password',
      'email',
      'tel',
      'date',
      'datetime-local',
      'color',
    ]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    default_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    regex: PropTypes.instanceOf(RegExp),
    change_param: PropTypes.any, //adds a third parameter to handleChange if needed
    placeholder: PropTypes.any,
    messages: PropTypes.shape({
      help: PropTypes.node,
      error: PropTypes.string,
      error_extra: PropTypes.string,
    }),
    field_prefix: PropTypes.string,
  }),
};

export const InputUI = withStyles(styles)(InputUI_);
