import {
  Box,
  Chip,
  ClickAwayListener,
  Collapse,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Config, Validate } from '../../../other';
import { ToggleUI } from '../../../components/common';
import InventoryIcon from '@mui/icons-material/Inventory';
import {
  getChipWithStateOfPayment,
  getChipWithStateOfShipment,
} from './getChipWithText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SalesTableRow = ({
  venta,
  classes,
  idCountry,
  saleIndex,
  tableClass,
  modalHandler,
  selectedShipment,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const productsObj = JSON.parse(venta.v_arr_carrito_json);
  const productsIds = Object.keys(productsObj);
  const productsArray = productsIds.map((productId) => {
    return productsObj[productId];
  });

  const [expandTableRow, setExpandTableRow] = useState(false);
  const router = useHistory();

  const handleSaveQueryParams = () => {
    if (router.location.search) {
      localStorage.setItem('filter_query_params', router.location.search);
    }
  };

  const { stylesStatePayment, messageStatePayment } = getChipWithStateOfPayment(
    Validate.label_estado_pago(venta.v_estado_pago)
  );

  const { stylesStateShipment, messageStateShipment } =
    getChipWithStateOfShipment(
      Validate.label_estado_envio(venta.v_metodo_envio, venta.v_estado_envio)
    );

  return (
    <>
      <TableRow
        sx={{ backgroundColor: saleIndex % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
      >
        <TableCell classes={{ root: tableClass }}>
          <Link
            to={'/listado-de-ventas/orden/' + venta.v_numero}
            className={classes.link_order}
            onClick={handleSaveQueryParams}
            data-testid={`Orden-${venta.v_numero}`}
          >
            <Typography
              sx={{ fontSize: '0.875rem', fontWeight: '500' }}
              as="span"
            >
              #{venta.v_numero}
            </Typography>
          </Link>
        </TableCell>
        <TableCell
          sx={{ cursor: 'pointer' }}
          classes={{ root: tableClass }}
          onClick={() => setExpandTableRow((expandTableRow) => !expandTableRow)}
        >
          Ver
          <IconButton size="large">
            {expandTableRow ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell classes={{ root: tableClass }}>
          <Box
            className={classes.customer_name}
            data-testid={`Cliente-${venta.v_numero}`}
          >
            {Validate.trim_ucwords(
              venta.v_facturacion_nombre + ' ' + venta.v_facturacion_apellido
            )}
            <ToggleUI show={venta.v_observacion ? true : false}>
              <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                <Tooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={() => setTooltipOpen(false)}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={venta.v_observacion}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <IconButton
                    aria-label="Observacion"
                    onClick={() => setTooltipOpen(true)}
                    size="large"
                    data-testid={`Observacion-${venta.v_numero}`}
                  >
                    <Icon style={{ fontSize: '1rem' }}>message</Icon>
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </ToggleUI>
          </Box>
        </TableCell>
        <TableCell
          classes={{ root: tableClass }}
          data-testid={`Fecha-${venta.v_numero}`}
        >
          <Box>
            {Validate.utc2local_format(venta.v_fecha, Config.SHORTDATE_FORMAT)}
          </Box>
          {Validate.utc2local_format(venta.v_fecha, 'H:mm')} hs
        </TableCell>
        <TableCell
          classes={{ root: tableClass }}
          data-testid={`Metodo-Pago-${venta.v_numero}`}
        >
          <Box>
            <Chip sx={stylesStatePayment} label={messageStatePayment} />
          </Box>
          <Typography component="span" sx={{ fontSize: '0.875rem' }}>
            {Validate.label_metodo_pago(venta.v_metodo_pago)}
          </Typography>
        </TableCell>
        <TableCell
          classes={{ root: tableClass }}
          data-testid={`Metodo-Envio-${venta.v_numero}`}
        >
          <Box>
            <Chip sx={stylesStateShipment} label={messageStateShipment} />
          </Box>
          <Typography component="span" sx={{ fontSize: '0.875rem' }}>
            {Validate.label_metodo_envio(
              venta.v_metodo_envio,
              selectedShipment
            )}
          </Typography>
        </TableCell>
        <TableCell
          classes={{ root: tableClass }}
          data-testid={`Monto-${venta.v_numero}`}
        >
          $
          {Validate.number_format(
            parseFloat(venta.v_monto) + parseFloat(venta.v_envio_costo),
            idCountry
          )}
        </TableCell>
        <TableCell classes={{ root: tableClass }} align="center">
          <IconButton
            aria-label="Archivar"
            onClick={(e) => modalHandler(true, 'archivar_orden', venta)}
            disabled={venta.v_archivada ? true : false}
            size="large"
            data-testid={`Archivar-${venta.v_numero}`}
          >
            <InventoryIcon htmlColor="#565656" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{ backgroundColor: saleIndex % 2 === 0 ? '#FFFFFF' : '#FAFAFA' }}
      >
        <TableCell sx={{ padding: '0px' }} />
        <TableCell sx={{ padding: '0px' }} colSpan={7}>
          <Collapse in={expandTableRow} timeout="auto">
            <Box sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 394 }}>
                <Table
                  stickyHeader
                  size="small"
                  sx={{
                    height: '100px',
                    '& th': {
                      backgroundColor:
                        saleIndex % 2 === 0 ? '#FFFFFF' : '#FAFAFA',
                    },
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        '& th': {
                          color: '#3A3A3A',
                        },
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          width: { xs: 400, xl: '40%' },
                        }}
                      >
                        Productos
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ width: { xs: 160, xl: '17.5%' } }}
                      >
                        Cantidad
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ width: { xs: 161, xl: '18%' } }}
                      >
                        Precio unitario
                      </TableCell>
                      <TableCell sx={{ width: 'auto' }} align="left">
                        Subtotal
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productsArray.map((product, key) => {
                      const idAttributes = Object.keys(product.info_atributos);

                      let attributesArray = idAttributes.map((id) => {
                        return `${product.info_atributos[id].at_nombre}: ${product.info_atributos[id].vat_valor}`;
                      });

                      if (product.sku) {
                        attributesArray = [
                          `SKU: ${product.sku}`,
                          ...attributesArray,
                        ];
                      }

                      return (
                        <TableRow
                          key={key}
                          sx={{
                            minHeight: '65px',
                            '& td': {
                              py: 0.5,
                              color: '#3A3A3A',
                              fontSize: '0.75rem',
                            },
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Box
                                width={40}
                                height={40}
                                margin={'8px'}
                                component="img"
                                alt={`Foto producto ${product.nombre}`}
                                src={`${Config.CLOUDFRONT_PRODUCTS_CDN}${product.img}`}
                              />
                              <Box>
                                <Typography
                                  component="p"
                                  sx={{
                                    fontSize: '0.875rem',
                                    fontWeight: '500',
                                  }}
                                >
                                  {product.nombre}
                                </Typography>

                                {attributesArray.map(
                                  (element, index, attributes) => {
                                    let elementText = element + ' | ';
                                    if (index === attributes.length - 1) {
                                      elementText = element;
                                    }

                                    return (
                                      <Typography
                                        key={index}
                                        component="span"
                                        sx={{ fontSize: '0.75rem' }}
                                      >
                                        {elementText}
                                      </Typography>
                                    );
                                  }
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>{product.cantidad}</TableCell>
                          <TableCell>
                            $
                            {Validate.number_format(
                              parseFloat(product.precio_unitario),
                              idCountry
                            )}
                          </TableCell>
                          <TableCell>
                            $
                            {Validate.number_format(
                              parseFloat(
                                product.precio_unitario * product.cantidad
                              ),
                              idCountry
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SalesTableRow;
