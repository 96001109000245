import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Config } from '../other';

const fetchData = async () => {
  const { data } = await axios.get(
    Config.BACKEND_ENDPOINT + '/dominio/preferencial'
  );
  return data.data;
};

const useGetDomain = () => {
  return useQuery({
    refetchOnMount: false,
    queryKey: ['domainData'],
    queryFn: fetchData,
    gcTime: 12 * 60 * 60 * 1000, // 12 hours
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
};

export default useGetDomain;
