import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { AlertUI, ModuleTitleUI } from '../../components/common';
import { FormBillingData } from './FormBillingData';
import { tiendaObtenerMiPlan } from '../../store/actions';

const BillingInformationPage_ = ({ getPlanData, history }) => {
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [billingData, setBillingData] = useState();

  useEffect(() => {
    getPlanData((err, resp) => {
      if (err) {
        setError(true);
      } else if (resp.data) {
        setIsNewUser(!resp.data.facturacion);
        setBillingData(resp.data.datosfacturacion);
        setLoading(false);
      }
    });
  }, []);

  return (
    <WrapperLayout loading={loading} error={error}>
      <AlertUI
        open={alert.open}
        type={alert.type}
        hideSeconds={5000}
        message={alert.message}
        handleCloseAlert={() => setAlert({ ...alert, open: false })}
      />
      <ModuleTitleUI title="Datos de Facturación" />
      <FormBillingData
        history={history}
        setError={setError}
        setAlert={setAlert}
        isNewUser={isNewUser}
        idCountry={idCountry}
        datosFacturacion={billingData}
      />
    </WrapperLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlanData: (callback) => dispatch(tiendaObtenerMiPlan(callback)),
  };
};

const BillingInformationPage = connect(
  null,
  mapDispatchToProps
)(BillingInformationPage_);

export default BillingInformationPage;
