/* eslint-disable no-unreachable */
import React, { Fragment } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToggleUI } from '../../../../../components/common';
import { Validate } from '../../../../../other';
import { DirectionDetails } from './DirectionDetails';

const InfoEnvioMetodo = ({ venta, ayuda, classes }) => {
  let envio_seleccionado = JSON.parse(venta.v_arr_envio_seleccionado_json);

  let volumen_venta = Validate.obtener_volumen_venta(venta);
  let peso_venta = Validate.obtener_peso_venta(venta);

  return (
    <CardContent className={classes.card_content}>
      <Typography className={classes.info}>
        <span className={classes.info_title}>Método de envío:</span>{' '}
        {ayuda.metodo_envio.nombre}
      </Typography>
      <ToggleUI
        show={
          !Validate.in_array(venta.v_metodo_envio, [1, 2, 3, 5, 7, 9]) &&
          venta.v_metodo_envio_subtipo === 1
        }
      >
        <Typography className={classes.info}>
          <span className={classes.info_title}>Sucursal destino:</span>{' '}
          {envio_seleccionado.descripcion.join(' - ')}
        </Typography>
      </ToggleUI>
      <Typography className={classes.info}>
        <span className={classes.info_title}>Estado del envío:</span>{' '}
        {Validate.label_estado_envio(
          venta.v_metodo_envio,
          venta.v_estado_envio
        )}
      </Typography>
      <ToggleUI show={venta.v_envio_numero_seguimiento ? true : false}>
        <Typography className={classes.info}>
          <span className={classes.info_title}>N&deg; de seguimiento:</span>{' '}
          {venta.v_envio_numero_seguimiento}
        </Typography>
      </ToggleUI>
      <ToggleUI show={!Validate.in_array(venta.v_metodo_envio, [2, 3, 7])}>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Costo del envío:</span>{' '}
          {Validate.label_costo_envio(
            venta.v_metodo_envio,
            venta.v_envio_costo,
            venta.v_envio_gratis,
            envio_seleccionado
          )}
        </Typography>
      </ToggleUI>
      <ToggleUI show={Validate.in_array(venta.v_metodo_envio, [4, 8])}>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Peso total:</span>{' '}
          {peso_venta.toFixed(2)}kg
        </Typography>
      </ToggleUI>
      <ToggleUI show={Validate.in_array(venta.v_metodo_envio, [4])}>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Peso máximo admitido:</span>{' '}
          {(peso_venta * 1.25).toFixed(2)}kg
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Volumen:</span>{' '}
          {volumen_venta.toFixed(0)}cm3
        </Typography>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Volumen máximo admitido:</span>{' '}
          {(volumen_venta * 2).toFixed(0)}cm3
        </Typography>
      </ToggleUI>
    </CardContent>
  );
};

const InfoEnvioDireccion = ({ venta, classes, idCountry }) => {
  return (
    <ToggleUI
      show={
        !Validate.in_array(venta.v_metodo_envio, [2, 7]) &&
        !(venta.v_metodo_envio === 1 && venta.v_estado_pago === -1)
      }
    >
      <Divider />
      <CardContent className={classes.card_content}>
        <Typography className={classes.info_head}>
          Dirección de envío:
        </Typography>
        <DirectionDetails idCountry={idCountry} venta={venta} />
      </CardContent>
    </ToggleUI>
  );
};

const InfoEnvioDestinatario = ({ venta, cliente, classes }) => {
  return (
    <Fragment>
      <Divider />
      <CardContent className={classes.card_content}>
        <Typography className={classes.info_head}>
          {venta.v_metodo_envio === 2 ? 'Retira:' : 'Enviar a:'}
        </Typography>
        <ToggleUI show={venta.v_envio_nombre ? true : false}>
          <Typography className={classes.info}>
            <span className={classes.info_title}>Nombre completo:</span>{' '}
            {Validate.trim_ucwords(
              venta.v_envio_nombre + ' ' + venta.v_envio_apellido
            )}
          </Typography>
        </ToggleUI>
        <Typography className={classes.info}>
          <span className={classes.info_title}>Teléfono:</span>{' '}
          {venta.v_envio_telefono}
        </Typography>
        <Typography className={classes.info} sx={{ whiteSpace: 'nowrap' }}>
          <span className={classes.info_title}>Email:</span> {cliente.cl_email}
        </Typography>
        <ToggleUI show={venta.v_envio_tipo_identificacion ? true : false}>
          <Typography className={classes.info}>
            <span className={classes.info_title}>
              {venta.v_envio_tipo_identificacion}:
            </span>{' '}
            {venta.v_envio_numero_identificacion}
          </Typography>
        </ToggleUI>
      </CardContent>
    </Fragment>
  );
};

const OrdenInfoEnvio_ = ({ data, classes, idCountry }) => {
  let { venta, cliente, ayuda } = data;

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Información de envío"
        className={classes.card_header}
        classes={{ title: classes.card_title }}
      />
      <Divider />
      <InfoEnvioMetodo venta={venta} ayuda={ayuda} classes={classes} />
      <InfoEnvioDireccion
        venta={venta}
        classes={classes}
        idCountry={idCountry}
      />
      <InfoEnvioDestinatario
        venta={venta}
        cliente={cliente}
        classes={classes}
      />
    </Card>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  card_content: {
    padding: '16px',
    '&:last-child': {
      padding: '16px',
    },
  },
  card_title: {
    fontWeight: '500',
    fontSize: '1.1rem',
  },
  info_head: {
    fontWeight: '500',
    fontSize: '1rem',
    marginBottom: '5px',
  },
  info: {
    fontWeight: '300',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  info_title: {
    fontWeight: '500',
  },
});

export const OrdenInfoEnvio = withStyles(styles, { withTheme: true })(
  OrdenInfoEnvio_
);
