import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColumnTitle = styled(TableCell)({
  border: 'none',
  fontWeight: '400',
  fontSize: '0.87rem',
  lineHeight: '16px',
  color: '#6C6C6C',
});
