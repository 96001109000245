import React, { Suspense, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { Splash } from '../../components/common/Splash';
import { useAuth0 } from '@auth0/auth0-react';

export default function CreateShop() {
  const { user } = useAuth0();
  const router = useHistory();
  const searchParams = new URLSearchParams(router.location.search);
  const idCountry = user.app_metadata.id_country;
  const couponCode = searchParams.get('ca_codigo') || '';

  const FormControl = lazy(() => import(`./${idCountry}`));

  if (localStorage.getItem('auth')) {
    router.push('/');
  }

  const dispatchFacebookAndGTM = () => {
    if (typeof window !== 'undefined') {
      if (window.fbq) {
        window.fbq('track', 'StartTrial');
      }
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          event_category: 'Crear Tienda',
          event_label: 'Tienda Creada',
        });
      }
    }
  };

  return (
    <Suspense fallback={<Splash />}>
      <FormControl
        idCountry={idCountry}
        couponCode={couponCode}
        dispatchFacebookAndGTM={dispatchFacebookAndGTM}
      />
    </Suspense>
  );
}
