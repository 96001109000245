import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const VentaPaginacion_ = (props) => {
  const { classes, firstvalue, lastvalue } = props;

  return (
    <>
      <Typography component="span" className={classes.card_text}>
        <b className={classes.text_bold}>{firstvalue}</b> {lastvalue}
      </Typography>
    </>
  );
};

const styles = (theme) => ({
  card_text: {
    fontSize: '1rem',
    lineHeight: '21px',
    fontWeight: '600',
    color: '#6F6F6F',
    marginRight: '32px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
    },
  },
  text_bold: {
    color: '#383838',
  },
});

export const VentaPaginacion = withStyles(styles)(VentaPaginacion_);
