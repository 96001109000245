/* eslint-disable default-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { trackObtenerTracks } from '../../store/actions';
import { Grid, Box, LinearProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AlertUI, ToggleUI, ButtonUI, ModuleTitleUI } from '../common';
import { Validate } from '../../other';
import { OfertasList } from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import ButtonMui from '../common/ButtonMui';

const es_mobile = Validate.is_mobile();

class Ofertas_ extends Component {
  state = {
    page: 0,
    load_more: {
      loading: false,
      show: false,
    },

    //carga de datos
    loading: {
      init_ofertas: true,
      ofertas: true,
      error: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.cargarOfertas();
  }

  cargarOfertas = () => {
    this.setState((prevState) => ({
      loading: {
        ...prevState.loading,
        ofertas: true,
      },
      load_more: {
        loading: prevState.page > 0 ? true : false,
        show: prevState.page > 0 ? true : false,
      },
    }));

    this.props.trackObtenerTracks(
      {
        track_type: 1,
        order: 1,
        page: this.state.page,
      },
      (err, resp) => {
        let cantidad_resultados = resp.data.length;

        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            init_ofertas: false,
            ofertas: false,
            error: prevState.loading.error || err ? true : false,
          },
          load_more: {
            loading: false,
            show: cantidad_resultados === 15,
          },
          page: cantidad_resultados === 15 ? prevState.page + 1 : 0,
        }));
      }
    );
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  render() {
    return (
      <WrapperLayout loading={false} error={this.state.loading.error}>
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container justifyContent="space-between" spacing={1.5}>
          <Grid item md="auto" xs={12}>
            <ModuleTitleUI
              title="Ofertas"
              subtitles={[
                <span>
                  Desde esta sección podés agregar precios promocionales a
                  través de un % de descuento a un producto, varios productos o
                  a toda una categoría.
                </span>,
                <span>
                  Estas ofertas podés configurarlas con su % correspondiente de
                  descuento, aplicar o no un redondeo y determinar o no un
                  tiempo de validez.
                </span>,
                <span>
                  Te recomendamos mirar el tutorial antes de realizar una
                  oferta:{' '}
                  <u>
                    <a
                      href="https://youtu.be/MTbofpaIdxc"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      tutorial
                    </a>
                  </u>
                  .
                </span>,
              ]}
            />
          </Grid>
          <Grid item md="auto" xs={12}>
            <Grid container spacing={1.5}>
              <Grid item>
                <ButtonUI
                  type="link"
                  label={es_mobile ? 'Agregar' : 'Agregar oferta'}
                  fullWidth={false}
                  link="/ofertas/agregar"
                />
              </Grid>
              <Grid item>
                <ButtonUI
                  type="link"
                  label={es_mobile ? 'Borrar' : 'Borrar oferta'}
                  fullWidth={false}
                  color="error"
                  link="/ofertas/eliminar"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ margin: '20px 0px 30px 0px' }}>
          {this.state.page === 0 && this.state.loading.ofertas ? (
            <LinearProgress />
          ) : (
            <OfertasList />
          )}
        </Box>
        <ToggleUI show={this.state.load_more.show}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ButtonMui
                disabled={this.state.load_more.loading}
                onClick={() => this.cargarOfertas()}
                isLoading={this.state.load_more.loading}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Ver más
              </ButtonMui>
            </Grid>
          </Grid>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tracks: state.tracks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackObtenerTracks: (data, callback) =>
      dispatch(trackObtenerTracks(data, callback)),
  };
};

export const Ofertas = connect(mapStateToProps, mapDispatchToProps)(Ofertas_);
