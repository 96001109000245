import { Table, TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ErrorTable = styled(Table)(({ theme }) => ({
  margin: '20px 0px 40px 0px',
  '&::-webkit-scrollbar': {
    width: '17px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    backgroundColor: '#F2F4F6',
  },
  '&::-webkit-scrollbar-thumb': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)',
    backgroundColor: '#C4C4C4',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
    width: '75vw',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
  },
}));

export const TableCellValue = styled(TableCell)({
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '19px',
  color: '#242424',
  borderBottom: 'none',
});

export const TableCellColumnName = styled(TableCell)({
  border: 'none',
  color: 'rgba(0, 0, 0, 0.54)',
});

export const ImportTextErrorTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1.25rem',
  lineHeight: '23px',
  color: '#000000',
  marginBottom: '24px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '0px',
    fontSize: '1rem',
    lineHeight: '18.75px',
  },
}));
