import React from 'react';

export const IconGreen = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4167 7L11.9933 5.3725L12.1917 3.22L10.0858 2.74167L8.98334 0.875L7 1.72667L5.01667 0.875L3.91417 2.73583L1.80834 3.20833L2.00667 5.36667L0.583336 7L2.00667 8.6275L1.80834 10.7858L3.91417 11.2642L5.01667 13.125L7 12.2675L8.98334 13.1192L10.0858 11.2583L12.1917 10.78L11.9933 8.6275L13.4167 7ZM5.88584 9.75333L3.66917 7.53083L4.5325 6.6675L5.88584 8.02667L9.29834 4.6025L10.1617 5.46583L5.88584 9.75333Z"
      fill="#1B5F1C"
    />
  </svg>
);
