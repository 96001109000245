/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const enviopersonalizadoReducer = (state = initState, action) => {
  let enviospe;
  switch (action.type) {
    case Reducers.ENVIOPERSONALIZADO_GUARDAR:
      enviospe = action.data;
      return enviospe;

    case Reducers.ENVIOPERSONALIZADO_BORRAR:
      let enviope_borrado = action.data;
      enviospe = state.filter((enviope) => {
        return (
          enviope.idEnviosPersonalizados !==
          enviope_borrado.idEnviosPersonalizados
        );
      });
      return enviospe;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { enviopersonalizadoReducer };
