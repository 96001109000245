import React, { useEffect, useState } from 'react';
import Header from './Header';
import { Box } from '@mui/material';
import TemplatesContainer from './TemplatesContainer';
import { connect, useSelector } from 'react-redux';
import { Splash } from '../../components/common/Splash';
import { tiendaObtener } from '../../store/actions';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../components/amplitude';

const SelectTemplatePage_ = ({ getShop }) => {
  const shop = useSelector((state) => state.tienda);
  const [isShopLoading, setIsShopLoading] = useState(true);

  useEffect(() => {
    getShop((_err, _resp) => {
      setIsShopLoading(false);
    });
    createAmplitudeEventWithDevice(
      design_amplitude_events.diseño_ver_plantillas_disponibles.event_name
    );
  }, []);

  if (!shop.Templates_idTemplates || isShopLoading) {
    return <Splash />;
  }

  return (
    <>
      <Header />
      <Box
        mt={{ xs: '280px', md: '262px' }}
        bgcolor="#F5F6F8"
        height="100%"
        p={{ xs: '0px 20px', md: '0px 60px', lg: '0px 108px' }}
        as="main"
      >
        <TemplatesContainer />
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
  };
};

const SelectTemplatePage = connect(
  null,
  mapDispatchToProps
)(SelectTemplatePage_);

export default SelectTemplatePage;
