import React from 'react';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const ButtonMui = ({
  isLoading = false,
  disabled = false,
  onClick,
  children,
  endIcon = null,
  ...rest
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      sx={{
        minHeight: '2.5rem',
        borderRadius: '2px',
        paddingY: '3px',
        fontSize: '16px',
        fontWeight: '400',
        boxShadow: 'none',
      }}
      disabled={isLoading || disabled}
      onClick={onClick}
      endIcon={
        isLoading ? <CircularProgress size={20} color="inherit" /> : endIcon
      }
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonMui;
