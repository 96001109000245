import React, { useEffect, createContext, useState } from 'react';
import amplitude from 'amplitude-js';
import { Config, Validate } from '../../other';

export const createAmplitudeEvent = (name, params = {}) => {
  const options = {
    ...params,
  };
  if (name !== '') {
    amplitude.getInstance().logEvent(name, options);
  }
};

export const createAmplitudeEventWithDevice = (name, params = {}) => {
  const device = Validate.is_mobile() ? 'mobile' : 'desktop';

  const { country, idTienda, category, ...rest } = params;

  const options = idTienda
    ? {
        ...rest,
        device,
        idTienda: idTienda,
      }
    : {
        ...rest,
        device,
      };

  idTienda && amplitude.getInstance().setUserId(idTienda);
  country && amplitude.getInstance().setUserProperties({ country: country });
  category && amplitude.getInstance().setUserProperties({ category: category });

  amplitude.getInstance().logEvent(name, options);
};

export const AmplitudeContext = createContext({ isInitiated: false });

const AmplitudeProvider = ({ children }) => {
  const [isInitiated, setIsinitiated] = useState(false);
  useEffect(() => {
    if (!isInitiated) {
      amplitude
        .getInstance()
        .init(
          Config.REACT_APP_PUBLIC_AMPLITUDE_API_KEY ?? '',
          undefined,
          undefined,
          () => {
            setIsinitiated(true);
          }
        );
    }
  }, []);

  return (
    <AmplitudeContext.Provider value={{ isInitiated }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;
