import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Hidden,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MenuItemUI, DropdownUI } from '../../../common';
import { Validate, Config } from '../../../../other';

const CuponesList_ = ({ tienda, cupones, modalHandler }) => {
  const theme = useTheme();
  const router = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const idCountry = tienda ? tienda.Country_idCountry : 'ARG';

  const obtenerTipoCupon = (cupon, idCountry) => {
    switch (cupon.cd_tipo) {
      case 0:
        return 'Descuento del ' + cupon.cd_porcentaje + '%';
      case 1:
        return (
          'Descuento de $' + Validate.number_format(cupon.cd_monto, idCountry)
        );
      case 2:
        return '2x1 (paga unidad de mayor precio)';
      case 3:
        return '3x2 (paga unidades de mayor precio)';
      case 4:
        return '4x2 (paga unidades de mayor precio)';
      case 5:
        return '4x3 (paga unidades de mayor precio)';
      case 6:
        return (
          'Descuento del ' +
          cupon.cd_porcentaje +
          '% en segunda unidad (de menor precio)'
        );
    }
  };

  const obtenerAlcanceCupon = (cupon) => {
    const sxChip = {
      fontSize: '11px',
      color: 'rgba(0,0,0,0.6)',
      backgroundColor: '#e4e4e4',
      margin: '10px 5px 5px 0px',
      maxWidth: '126px',
    };

    switch (cupon.cd_alcance) {
      case -1:
        return (
          <Chip
            sx={sxChip}
            component="span"
            color="primary"
            label="Todos los productos"
          />
        );
      case 0:
        return cupon.categorias_cupon.map((categoria) => {
          return (
            <Chip
              sx={sxChip}
              component="span"
              color="primary"
              label={categoria.c_nombre}
              key={categoria.idCategorias}
            />
          );
        });
      case 1:
        return cupon.productos_cupon.map((producto) => {
          return (
            <Chip
              sx={sxChip}
              component="span"
              color="primary"
              label={producto.p_nombre}
              key={producto.idProductos}
            />
          );
        });
    }
  };

  const obtenerLimiteUsoCupon = (cupon) => {
    switch (cupon.cd_uso) {
      case -1:
        return 'Sin límite';
      case 0:
        return cupon.cd_uso_cantidad === 1
          ? '1 compra'
          : cupon.cd_uso_cantidad + ' compras';
      case 1:
        return cupon.cd_uso_cantidad === 1
          ? '1 compra por cliente'
          : cupon.cd_uso_cantidad + ' compras por cliente';
    }
  };

  const obtenerCondicionUsoCupon = (cupon, idCountry) => {
    switch (cupon.cd_condicion) {
      case -1:
        return 'Sin condición';
      case 0:
        return (
          'Compras mayores a $' +
          Validate.number_format(cupon.cd_condicion_monto, idCountry)
        );
      case 1:
        return 'Compras de ' + cupon.cd_condicion_cantidad + ' o más productos';
    }
  };

  const obtenerDuracionCupon = (cupon) => {
    switch (cupon.cd_duracion) {
      case -1:
        return 'Ilimitado';
      case 0:
        return (
          'Desde el ' +
          Validate.utc2local_format(
            cupon.cd_duracion_inicio,
            Config.DATE_FORMAT
          )
        );
      case 1:
        return (
          'Hasta el ' +
          Validate.utc2local_format(cupon.cd_duracion_fin, Config.DATE_FORMAT)
        );
      case 2:
        return (
          'Desde el ' +
          Validate.utc2local_format(
            cupon.cd_duracion_inicio,
            Config.DATE_FORMAT
          ) +
          ' hasta el ' +
          Validate.utc2local_format(cupon.cd_duracion_fin, Config.DATE_FORMAT)
        );
    }
  };

  return (
    <List
      sx={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0',
        '&:last-child': {
          borderBottom: '0px solid transparent',
        },
      }}
    >
      {cupones.length === 0 ? (
        <ListItem>
          <ListItemText
            primary="No hay cupones de descuento"
            secondary="Puedes agregar uno haciendo click en agregar"
          />
        </ListItem>
      ) : (
        cupones.map((cupon, index) => {
          const sxText = {
            fontSize: '0.875rem',
            color: 'rgba(0, 0, 0, 0.6)',
          };

          return (
            <ListItem
              key={cupon.idCuponesDescuento}
              divider={index < cupones.length - 1}
            >
              {!isMobile && (
                <ListItemAvatar>
                  <Avatar>
                    <Icon>local_offer</Icon>
                  </Avatar>
                </ListItemAvatar>
              )}
              <Box display="flex" flexDirection="column">
                <Typography component="h3">{cupon.cd_codigo}</Typography>
                <Typography component="span" {...sxText}>
                  Tipo: {obtenerTipoCupon(cupon, idCountry)}
                </Typography>
                <Typography component="span" {...sxText}>
                  Límite de uso: {obtenerLimiteUsoCupon(cupon)}
                </Typography>
                <Typography component="span" {...sxText}>
                  Condición de uso: {obtenerCondicionUsoCupon(cupon, idCountry)}
                </Typography>
                <Typography component="span" {...sxText}>
                  Tiempo de validez: {obtenerDuracionCupon(cupon)}
                </Typography>
                {obtenerAlcanceCupon(cupon)}
              </Box>

              <ListItemSecondaryAction>
                <Hidden mdUp>
                  <DropdownUI type="icon">
                    <MenuItemUI
                      type="callf"
                      onClickFunction={() =>
                        modalHandler(true, 'desactivar_activar', cupon)
                      }
                    >
                      {cupon.cd_desactivado
                        ? 'Activar cupón'
                        : 'Desactivar cupón'}
                    </MenuItemUI>
                    <MenuItemUI
                      type="link"
                      link={'/cupones/' + cupon.idCuponesDescuento}
                    >
                      Editar cupón
                    </MenuItemUI>
                    <MenuItemUI
                      type="callf"
                      onClickFunction={() =>
                        modalHandler(true, 'borrar', cupon)
                      }
                    >
                      Borrar cupón
                    </MenuItemUI>
                  </DropdownUI>
                </Hidden>
                <Hidden mdDown>
                  <IconButton
                    aria-label="Borrar"
                    onClick={() =>
                      modalHandler(true, 'desactivar_activar', cupon)
                    }
                    size="large"
                  >
                    <Icon>
                      {cupon.cd_desactivado ? 'visibility_off' : 'visibility'}
                    </Icon>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="Editar"
                    onClick={() =>
                      router.push(`/cupones/${cupon.idCuponesDescuento}`)
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="Borrar"
                    onClick={() => modalHandler(true, 'borrar', cupon)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Hidden>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      )}
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    cupones: state.cupones,
    tienda: state.tienda,
  };
};

const mapDispatchToProps = null;

export const CuponesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CuponesList_);
