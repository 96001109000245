import { Icon } from '@mui/material';
import React from 'react';
import { LineDot } from './LineDot';
import { Config } from '../../../other';
import { withStyles } from '@mui/styles';

const ModalInstallAppBody_ = ({
  classes,
  urlLandingPage,
  app,
  permissionsTitles,
}) => {
  const { name, logo, policy_url } = app;
  const empretiendaLogo = `${Config.CLOUDFRONT_CDN_PROD}/applications/logos/empretienda-logo.png`;

  const combinedClass = `${classes.widthLogo} ${classes.containerAppLogo}`;

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.containerImage}>
          <div className={classes.containerAppLogo}>
            <img src={empretiendaLogo} />
            <span className={classes.textApp}>Tu tienda</span>
          </div>
          <LineDot />
          <div className={combinedClass}>
            <img src={logo} />
            <span className={classes.textApp}>{name}</span>
          </div>
        </div>
        <p className={classes.infoText}>
          Vas a agregar {name} a tu tienda. Esta aplicación necesita de los
          siguientes permisos para instalarse:
        </p>
        {permissionsTitles.map((title, idx) => {
          return (
            <div className={classes.containerText} key={idx}>
              <Icon classes={{ root: classes.icon }}>verified_user</Icon>
              <span className={classes.permissionsText}>{title}</span>
            </div>
          );
        })}
      </div>
      <p className={classes.privacyText}>
        Al aceptar, acordás con las{' '}
        <a
          className={classes.link}
          target="_blank"
          rel="noreferrer"
          href={policy_url}
        >
          Políticas de Privacidad de {app.name}
        </a>
        . Empretienda no se hace responsable de esta aplicación y no garantiza
        su calidad, precisión y seguridad, por lo que la integración de este
        servicio queda bajo tu exclusivo criterio. En caso de dudas, consultá
        los{' '}
        <a
          className={classes.link}
          target="_blank"
          rel="noreferrer"
          href={`${urlLandingPage}/terminos-y-condiciones`}
        >
          Términos y condiciones de Empretienda.
        </a>
      </p>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerImage: {
    display: 'flex',
    marginBottom: '38px',
    alignItems: 'flex-start',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '50px',
    },
  },
  containerAppLogo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '& img': {
      height: '56px',
      width: '56px',
      [theme.breakpoints.up('sm')]: {
        height: '80px',
        width: '80px',
      },
    },
  },
  widthLogo: {
    maxWidth: '100px',
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '38px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: '50px',
      },
    },
  },
  icon: {
    color: '#53B755',
    marginRight: '5px',
  },
  infoText: {
    marginTop: 0,
    fontWeight: 400,
    color: '#383838',
    textAlign: 'center',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '1rem',
    marginBottom: '32px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '50px',
    },
  },
  permissionsText: {
    color: '#383838',
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
  },
  privacyText: {
    color: '#6F6F6F',
    fontSize: '0.87rem',
    fontWeight: 400,
    lineHeight: '16.41px',

    margin: 0,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
  },
  textApp: {
    color: '#383838',
    fontSize: '0.875rem',
    fontWeight: 600,
    margin: '8px',
  },
  link: { color: '#53B755' },
});

export const ModalInstallAppBody = withStyles(styles)(ModalInstallAppBody_);
