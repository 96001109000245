import { Validate, Config } from '../../../other';
import SalesDataExcel from './SalesDataExcel.json';

const parseProducts = (cartJson) => {
  const prevProducts = JSON.parse(cartJson);
  const products = [];
  for (let idStock in prevProducts) {
    products.push(prevProducts[idStock]);
  }
  return products;
};

const productsString = (productList) => {
  const products = [];
  productList.forEach((item) => {
    let product = `${item.cantidad}x ${item.nombre}`;
    if (item.atributos > 0) {
      const attributes_st = [];
      const attributes = item.info_atributos;
      for (const element in attributes) {
        const atributo = `${attributes[element].at_nombre}: ${attributes[element].vat_valor}`;
        attributes_st.push(atributo);
      }
      product = `${product} (${attributes_st.toString()})`;
    }
    product = `${product} (SKU: ${item.sku ? item.sku : '-'})`;
    products.push(product);
  });

  return products.toString();
};

export const getSalesColumnsData = (salesData, idCountry) => {
  return salesData.map((sale) => {
    const selectedShipment = JSON.parse(sale.v_arr_envio_seleccionado_json);
    const productsList = parseProducts(sale.v_arr_carrito_json);
    const shipmentMethodExtra = Validate.labels_metodo_envio_extra(
      sale.v_metodo_envio,
      selectedShipment
    );

    let v_facturacion_nombrecompleto = sale.v_facturacion_nombre;
    if (sale.v_facturacion_apellido) {
      v_facturacion_nombrecompleto += ' ' + sale.v_facturacion_apellido;
    }

    let destinationStreet = Validate.trim_ucwords(sale.v_envio_calle);
    let billingStreet = Validate.trim_ucwords(sale.v_facturacion_calle);

    if (idCountry === 'COL') {
      destinationStreet = sale.v_envio_calle + ' - ' + sale.v_envio_numero;
      billingStreet =
        sale.v_facturacion_calle + ' - ' + sale.v_facturacion_numero;
    }

    return {
      ...sale,
      v_fecha: Validate.utc2local_format(sale.v_fecha, Config.DATE_FORMAT),
      v_facturacion_nombrecompleto: Validate.trim_ucwords(
        v_facturacion_nombrecompleto
      ),
      cl_email: sale.cliente.cl_email,
      pago: Validate.label_estado_pago(sale.v_estado_pago),
      metodo_pago: Validate.label_metodo_pago(sale.v_metodo_pago),
      envio: Validate.label_estado_envio(
        sale.v_metodo_envio,
        sale.v_estado_envio
      ),
      metodo_envio: Validate.label_metodo_envio(
        sale.v_metodo_envio,
        selectedShipment
      ),
      v_monto: Validate.number_format_excel(sale.v_monto),
      costo_envio: Validate.number_format_excel(
        Validate.label_costo_envio_exportar(
          sale.v_metodo_envio,
          sale.v_envio_costo,
          sale.v_envio_gratis,
          selectedShipment
        )
      ),
      metodo_envio_tipo: shipmentMethodExtra.tipo_envio,
      metodo_envio_tipo_sucursal: shipmentMethodExtra.sucursal,
      v_envio_nombre: Validate.trim_ucwords(sale.v_envio_nombre),
      v_envio_apellido: Validate.trim_ucwords(sale.v_envio_apellido),
      v_envio_calle: destinationStreet,
      v_envio_ciudad: Validate.trim_ucwords(sale.v_envio_ciudad),
      v_facturacion_calle: billingStreet,
      v_facturacion_ciudad: Validate.trim_ucwords(sale.v_facturacion_ciudad),
      productos: productsString(productsList),
      facturada: sale.v_factura ? 'Si' : 'No',
    };
  });
};

export const getSalesColumns = (idCountry) => {
  const salesColumns = SalesDataExcel[idCountry || 'ARG'].map(
    ({ label, value }) => {
      return {
        label: label,
        value: value,
      };
    }
  );
  return salesColumns;
};
