import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { tiendaObtener, productoAumentoMasivo } from '../../store/actions';
import { Validate, Regex, RegexExtra } from '../../other';
import { Grid } from '@mui/material';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  SelectUI,
  InputUI,
  ToggleUI,
  NextActionsUI,
} from '../common';
import {
  massive_increase_price_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { BackgroundBox } from '../common/BackgroundBox/styles';
import ButtonMui from '../common/ButtonMui';

const MassivePricesIncrease_ = ({
  tiendaObtener,
  productoAumentoMasivo,
  tienda,
}) => {
  const [state, setState] = useState({
    aumento_masivo: {
      tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Cuales productos aumentar',
        id: 'tipo',
        name: 'tipo',
        change_param: 'aumento_masivo',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      porcentaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje de aumento',
        id: 'porcentaje',
        name: 'porcentaje',
        change_param: 'aumento_masivo',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '100',
        messages: {
          help: 'Indica el porcentaje en el cual se van a incrementar los precios de todos los productos',
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
    },
    after: false,
    loading: {
      tienda: true,
      error: false,
    },
    forms: {
      aumento_masivo: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      tipo: [
        {
          value: 0,
          label: 'Todos los productos',
        },
        {
          value: 1,
          label: 'Solo productos minoristas',
        },
        {
          value: 2,
          label: 'Solo productos mayoristas',
        },
      ],
    },
  });

  useEffect(() => {
    tiendaObtener((err, resp) => {
      const tipo = !err && resp.data.t_mayorista === 0 ? 1 : 0;

      setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          tienda: false,
          error: err ? true : false,
        },
        aumento_masivo: {
          ...prevState.aumento_masivo,
          tipo: {
            ...prevState.aumento_masivo.tipo,
            value: tipo,
          },
        },
      }));
    });
  }, []);

  const handleChangeSelect = (e, aditional) => {
    const name = e.target.name;
    const value = Validate.trim_int(e.target.value);

    setState((prevState) => ({
      ...prevState,
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  const handleChange = (e, blur, aditional) => {
    const value = e.target.value;
    const name = e.target.name;

    const stateAux = {
      [aditional]: {
        ...state[aditional],
        [name]: {
          ...state[aditional][name],
          value: value,
        },
      },
    };

    Validate.validate_input(stateAux[aditional][name], blur, (input) => {
      setState((prevState) => ({
        ...prevState,
        [aditional]: {
          ...prevState[aditional],
          [name]: input,
        },
      }));
    });
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        aumento_masivo: true,
      },
    }));

    const form = state.aumento_masivo;
    if (Validate.validar_formulario(form)) {
      const porcentaje = form.porcentaje.value;
      const tipo = form.tipo.value;

      if (porcentaje > 0 && porcentaje <= 500) {
        productoAumentoMasivo(
          {
            porcentaje: porcentaje,
            tipo: tipo,
          },
          (err, resp) => {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                aumento_masivo: false,
              },
              after: err ? false : true,
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            }));
            createAmplitudeEventWithDevice(
              massive_increase_price_amplitude_events
                .aumento_masivo_selecciona_aumentar.event_name
            );
          }
        );
      } else {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            aumento_masivo: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message: 'El porcentaje debe estar entre 0 y 500',
            type: 'error',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            aumento_masivo: false,
          },
          aumento_masivo: new_form,
        }));
      });
    }
  };

  return (
    <WrapperLayout loading={state.loading.tienda} error={state.loading.error}>
      <AlertUI
        open={state.alert.open}
        message={state.alert.message}
        type={state.alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ToggleUI show={state.after}>
            <NextActionsUI
              title="¡Los precios han sido aumentados con éxito!"
              buttons={[
                <ButtonUI
                  type="link"
                  label="Ver mis productos"
                  link="/productos"
                />,
                <ButtonUI
                  type="link"
                  label="Volver a aumentar"
                  link="/productos/aumento-masivo"
                  external_link
                />,
              ]}
            />
          </ToggleUI>
          <ToggleUI show={!state.after}>
            <form onSubmit={handleSubmit}>
              <ModuleTitleUI
                title="Aumento masivo de precios"
                subtitles={[
                  'Sirve para aumentar masivamente el precio de todos tus productos en función a un porcentaje dado de una manera simple y rápida.',
                  'Una vez aumentados los precios de manera masiva, no es posible volver atrás. No se pueden disminuir los precios de manera masiva.',
                  'Si el producto está en oferta, se aumenta el precio de ambos (con y sin oferta) teniendo en cuenta el porcentaje configurado.',
                ]}
              />
              <BackgroundBox mb={2}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={handleChange}
                      input={state.aumento_masivo.porcentaje}
                      end_adornment="%"
                    />
                  </Grid>
                  <ToggleUI show={tienda.t_mayorista === 1}>
                    <Grid item xs={12}>
                      <SelectUI
                        data={state.aumento_masivo.tipo}
                        options={state.selects.tipo}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </Grid>
                  </ToggleUI>
                </Grid>
              </BackgroundBox>
              <ButtonMui
                sx={{ width: { xs: '100%', sm: '190px' } }}
                type="submit"
                isLoading={state.forms.aumento_masivo}
              >
                Aumentar precios
              </ButtonMui>
            </form>
          </ToggleUI>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    productoAumentoMasivo: (data, callback) =>
      dispatch(productoAumentoMasivo(data, callback)),
  };
};

export const MassivePricesIncrease = connect(
  mapStateToProps,
  mapDispatchToProps
)(MassivePricesIncrease_);
