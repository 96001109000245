import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Paragraph = styled(Typography)({
  color: '#6F6F6F',
  fontWeight: '400',
  lineHeight: '24px',
  fontSize: '0.875rem',
});

export const StyledLink = styled('a')({
  color: '#53B755',
  fontWeight: '400',
  lineHeight: '24px',
  fontSize: '0.875rem',
});
