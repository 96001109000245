import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { OfertasItem } from './';

const OfertasList_ = (props) => {
  let { tracks, classes } = props;

  const renderizarOfertas = (tracks) => {
    if (tracks.length > 0) {
      return tracks.map((track, index) => {
        return <OfertasItem track={track} key={index} />;
      });
    } else {
      return (
        <Grid item xs={12}>
          <div className={classes.list_item}>
            <Typography className={classes.list_item_title}>
              No se ha encontrado historial de ofertas realizadas
            </Typography>
          </div>
        </Grid>
      );
    }
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5">Historial de ofertas</Typography>
      </Grid>
      {renderizarOfertas(tracks)}
    </Grid>
  );
};

const styles = (theme) => ({
  list: {
    padding: '0',
  },
  list_item: {
    backgroundColor: '#fff',
    boxShadow: theme.card.card.boxShadow,
    borderRadius: '4px',
    padding: '15px 7.5px',
  },
  list_item_title: {
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
  },
});

const mapStateToProps = (state) => {
  return {
    tracks: state.tracks,
    auth: state.auth,
  };
};

const mapDispatchToProps = null;

export const OfertasList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OfertasList_));
