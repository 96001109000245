import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  SelectUI,
  InputUI,
  ImageUI,
  ToggleUI,
  CardModuleUI,
} from '../../../common';
import { Config, Validate } from '../../../../other';
import { LinkUI } from '../../../common/LinkUI/styles';
import NewQuillUI from '../../../common/NewQuillUI';

const es_mobile = Validate.is_mobile();
const es_iphone = Validate.is_iphone();

export const InfoSection = ({
  tienda,
  handleChangeFile,
  handleChange,
  handleChangeSelect,
  handleChangeQuill,
  modalHandler,
  selects,
  common,
}) => {
  return (
    <CardModuleUI>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={common.p_nombre} />
        </Grid>
        <Grid item xs={12}>
          <SelectUI
            data={common.idCategorias}
            options={selects.categorias}
            handleChangeSelect={handleChangeSelect}
            value_atribute="idCategorias"
            label_atribute={es_iphone ? 'c_nombre_slash' : 'c_nombre_full'}
          />
          <Typography
            variant="caption"
            color="#666666"
            fontSize="12px"
            gutterBottom
          >
            Opcionalmente podés elegir categorías secundarias{' '}
            <Typography
              as="span"
              onClick={() =>
                modalHandler(true, 'categorias_secundarias', false)
              }
              sx={{
                color: 'inherit',
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '0.75rem',
              }}
            >
              aquí
            </Typography>
            .
          </Typography>
        </Grid>
        <ToggleUI show={tienda.t_mayorista ? true : false}>
          <Grid
            item
            sm={tienda.t_mayorista && tienda.t_productos_digitales ? 6 : 12}
            xs={12}
          >
            <SelectUI
              data={common.p_tipo_venta}
              options={selects.p_tipo_venta}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI show={tienda.t_productos_digitales ? true : false}>
          <Grid
            item
            sm={tienda.t_mayorista && tienda.t_productos_digitales ? 6 : 12}
            xs={12}
          >
            <SelectUI
              data={common.p_producto_digital}
              options={selects.p_producto_digital}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </ToggleUI>
        <Grid item xs={12}>
          <NewQuillUI
            defaultValue={common.p_descripcion.value}
            handleChangeQuill={handleChangeQuill}
            placeholder={'Descripción de tu producto...'}
          />
          <Typography color="#666666" fontSize="12px" mt={'4px'}>
            Aquí podés agregar videos embebidos (desde el ícono de la cinta de
            video), que se reproducen directamente en la tienda. Son muy útiles
            para mostrar el producto en uso.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            as="h3"
            color="#333333"
            sx={{ fontSize: '1.1rem' }}
            mb={0.5}
            data-testid={'images-title-id'}
          >
            Imágenes
          </Typography>
          <Typography
            fontSize="14px"
            color="#666666"
            as="p"
            mb={2}
            data-testid={'images-subtitle-id'}
          >
            Para una mejor experiencia de navegación, recomendamos que todas las
            imágenes de tu tienda tengan el mismo tamaño. También podés{' '}
            <LinkUI
              href="https://www.remove.bg/es"
              target="_blank"
              rel="noreferrer"
            >
              quitarles el fondo
            </LinkUI>{' '}
            a las fotos para que se vean mejor. Recordá que es posible subir
            hasta 6 por producto, con un peso máximo de 10 megas por foto (
            <LinkUI
              href="https://tinypng.com/"
              target="_blank"
              rel="noreferrer"
            >
              comprimí tus imágenes
            </LinkUI>
            ). Los formatos permitidos son jpg, png, jpeg, webp.
          </Typography>
          <ImageUI
            data={common.fotos}
            max_files={6}
            max_size={10}
            allowed_files={['jpg', 'png', 'jpeg', 'webp']}
            input_allowed_mime="image/jpg,image/jpeg,image/png,image/webp"
            handleChange={handleChangeFile}
            cdn_prefix={Config.CLOUDFRONT_PRODUCTS_CDN}
            upload_path="products"
            row_height={es_mobile ? '100px' : '125px'}
            reducer_max_width={600}
            reducer_max_height={1200}
            xs={4}
            sm={4}
            md={2}
            lg={2}
            z_index={1000}
          />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};
