import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AlertUI } from './';
import { Validate } from '../../other';
import 'blueimp-canvas-to-blob/js/canvas-to-blob';
import loadImage from 'blueimp-load-image';

class DocFileUI_ extends Component {
  state = {
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  processSingleFile = () => {
    let data = this.props.data;
    let { id } = data;
    let docfile = document.getElementById(id);
    let documento = docfile.files[0];

    if (documento === undefined) {
      this.props.handleChangeDoc(false, {
        ...data,
        auth: data.required ? false : true,
        file: null,
      });
      docfile.value = '';
    } else if (
      Validate.in_array(documento.type, [
        'image/jpeg',
        'image/jpg',
        'image/png',
      ])
    ) {
      loadImage(
        documento,
        (img) => {
          img.toBlob((blob) => {
            documento = new File([blob], documento.name, {
              type: documento.type,
              lastModified: Date.now(),
            });
            let { valid, message } = this.validarDocumento(documento);
            if (valid) {
              this.props.handleChangeDoc(true, {
                ...data,
                auth: true,
                file: documento,
                messages: {
                  ...data.messages,
                  error: message,
                },
              });
              docfile.value = '';
            } else {
              this.setState(
                (prevState) => ({
                  alert: {
                    ...prevState.alert,
                    open: true,
                    message: message,
                  },
                }),
                () => {
                  this.props.handleChangeDoc(false, {
                    ...data,
                    auth: data.required ? false : true,
                    file: null,
                    messages: {
                      ...data.messages,
                      error: message,
                    },
                  });
                  docfile.value = '';
                }
              );
            }
          }, documento.type);
        },
        {
          meta: true,
          canvas: true,
          imageSmoothingQuality: 'high',
          imageSmoothingEnabled: true,
          maxWidth: 800,
          maxHeight: 800,
          orientation: true,
        }
      );
    } else {
      let { valid, message } = this.validarDocumento(documento);
      if (valid) {
        this.props.handleChangeDoc(true, {
          ...data,
          auth: true,
          file: documento,
          messages: {
            ...data.messages,
            error: message,
          },
        });
        docfile.value = '';
      } else {
        this.setState(
          (prevState) => ({
            alert: {
              ...prevState.alert,
              open: true,
              message: message,
            },
          }),
          () => {
            this.props.handleChangeDoc(false, {
              ...data,
              auth: data.required ? false : true,
              file: null,
              messages: {
                ...data.messages,
                error: message,
              },
            });
            docfile.value = '';
          }
        );
      }
    }
  };

  validarDocumento = (file) => {
    let { allowed_files, max_size } = this.props;
    let { name, size } = file;

    let extensionArr = name.split('.');
    if (extensionArr.length > 1) {
      let extension = Validate.trim_lowercase(
        extensionArr[extensionArr.length - 1]
      ); //obtengo la extension
      if (Validate.in_array(extension, allowed_files)) {
        size = size / 1024 / 1024; //convierto a mb
        if (size <= max_size) {
          return {
            valid: true,
            message: '',
          };
        } else {
          return {
            valid: false,
            message:
              'El documento ' +
              name +
              ' no debe exceder los ' +
              max_size +
              'mb de tamaño',
          };
        }
      } else {
        let tipos = allowed_files.join(', ');
        return {
          valid: false,
          message: 'El documento ' + name + ' debe ser de tipo: ' + tipos,
        };
      }
    } else {
      let tipos = allowed_files.join(', ');
      return {
        valid: false,
        message: 'El documento ' + name + ' debe ser de tipo: ' + tipos,
      };
    }
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  render() {
    let { data, classes, input_allowed_mime } = this.props;

    let { auth, id, file, messages } = data;

    return (
      <div>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container alignItems="center" spacing={0.5}>
          <Grid item md={2} xs={5}>
            <input
              type="file"
              accept={input_allowed_mime}
              className={classes.docfile_input}
              id={id}
              onChange={this.processSingleFile}
            />
            <label htmlFor={id}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                component="span"
                className={classes.docfile_button}
              >
                Elegir
              </Button>
            </label>
          </Grid>
          <Grid item md={10} xs={7}>
            <label htmlFor={id}>
              <Typography className={classes.docfile_name} variant="body1">
                {auth && file ? file.name : 'Seleccionar archivo'}
              </Typography>
            </label>
          </Grid>
          <Typography className={classes.docfile_help} variant="body1">
            {messages.help}
          </Typography>
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  docfile_name: {
    color: '#9e9e9e',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '4px',
    cursor: 'pointer',
  },
  docfile_help: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '4px',
    fontSize: '0.75rem',
  },
  docfile_input: {
    display: 'none',
  },
  docfile_button: {
    boxShadow: 'none',
    borderRadius: '2px',
  },
});

DocFileUI_.propTypes = {
  /**
   * Object used to add some styling with withStyles
   */
  classes: PropTypes.object,

  /**
   * The object initialized in state
   */
  data: PropTypes.shape({
    validate: PropTypes.bool.isRequired,
    auth: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    file: PropTypes.any,
    messages: PropTypes.shape({
      help: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }).isRequired,
  }),

  /**
   * max size in mb for a single file
   */
  max_size: PropTypes.number.isRequired,

  /**
   * file extensions allowed, used in validation func
   * ej: ["jpg","png",...]
   */
  allowed_files: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * file extensions allowed, used in the input
   * ej: image/jpg,image/png,image/jpeg
   */
  input_allowed_mime: PropTypes.string.isRequired,

  /**
   * the function used to send the file to the parent component
   * handleChangeDoc(err:bool,data:obj)
   */
  handleChangeDoc: PropTypes.func.isRequired,
};

export const DocFileUI = withStyles(styles)(DocFileUI_);
