import React, { useEffect } from 'react';

import { titleWithDot, column_names } from './ExportFormat';
import { productsHashTableAndValidateStock } from './hashTableAndValidateStock';
import { validateCellsValues } from './validateCellsValues';
import { normalizeName } from './workBookUtils';
import { Validate } from '../../../other';

const DEFAULT_NAME_FOR_CUSTOMER_COLUMN = 'empty_title';
const asignColumnIndexToCells = (rows_data_without_titles) => {
  //armamos rows que contienen celdas con index-value
  let row_data = [];
  for (const row of rows_data_without_titles) {
    let row2 = [];

    for (let value_index = 0; value_index < row.length; value_index++) {
      const key_value = {
        index: value_index,
        value: row[value_index],
      };
      row2.push(key_value);
    }
    row_data.push(row2);
  }
  return row_data;
};

const getTitleBasedOnIndex = (index, valid_columns) => {
  const column = valid_columns.find((key_title) => key_title.key === index);
  return column ? column.title : DEFAULT_NAME_FOR_CUSTOMER_COLUMN;
};

export const ValidarDatos = (props) => {
  const {
    planilla_datos,
    valid_columns,
    handleValidarDatosFinish,
    initial_completion_percentage,
    validar_datos_percentage,
    handleUpdateCompletionPercentage,
    dimensiones_requeridas,
  } = props;

  useEffect(() => {
    const validationAsync = async () => {
      const asignColumnIndexToCellsAsync = async (planilla_datos) => {
        //basado en la posición de las celdas, les asigno su índice con base cero
        const rows_cell_with_index = asignColumnIndexToCells(planilla_datos);
        await handleUpdateCompletionPercentage(
          initial_completion_percentage + validar_datos_percentage * (1 / 7)
        );

        //a partir del índice, asigno el título a las celdas
        const rows_cell_with_title = rows_cell_with_index.map((row) => {
          const cells_with_title = row.map((cell) => {
            return {
              index: cell.index,
              title: getTitleBasedOnIndex(cell.index, valid_columns),
              value: cell.value,
            };
          });
          //elimino las celdas del usuario, columnas auxiliares, cálculos, comentarios
          const without_custom_cells = cells_with_title.filter((cell) => {
            return cell.title === DEFAULT_NAME_FOR_CUSTOMER_COLUMN
              ? false
              : true;
          });
          return without_custom_cells;
        });
        return rows_cell_with_title;
      };

      const rows_cell_with_title = await asignColumnIndexToCellsAsync(
        planilla_datos
      );
      /**
       * En este punto cada celda tiene su índice. tomo como testigo la primera fila para definir el mapeo
       * entre las columnas del archivo actual y las del excel ideal (con todas las columnas y en orden)
       * de esta manera puedo asignar la letra en la hoja de cálculo (A, B,..,Z,AA,AB)
       */

      await handleUpdateCompletionPercentage(
        initial_completion_percentage + validar_datos_percentage * (2 / 7)
      );

      //paso comas(,) a puntos(.)

      const replaceComaWithDotsAsync = async (rows_cell_with_title) => {
        const rows_cells_with_dot = rows_cell_with_title.map((row) => {
          const cellsWithDot = row.filter((cell) =>
            titleWithDot.some((title) => cell.title === title)
          );

          const cellsTransformed = cellsWithDot.map((cell) => {
            /**
             * En la conversión se pasa a tipo String para realizar los ajustes y
             * se conserva el tipo String para la siguiente etapa de validación por
             * RegExp
             */
            const newCell = {
              ...cell,
              value: Validate.trim(
                String(cell.value).replace(',', '.').replace(' ', '')
              ),
            };
            return newCell;
          });

          const cellsWithoutDot = row.filter(
            (cell) => !titleWithDot.some((title) => cell.title === title)
          );
          return [...cellsTransformed, ...cellsWithoutDot];
        });

        return rows_cells_with_dot;
      };
      const rows_cells_with_dot = await replaceComaWithDotsAsync(
        rows_cell_with_title
      );

      const rowsCellsNameOkAsync = async (rows_cells_with_dot) => {
        const rows_cell_name = rows_cells_with_dot.map((row) => {
          const name_cell = row.find(
            (cell) => cell.title === column_names.nombre
          );

          const new_name_cell = {
            ...name_cell,
            value: String(name_cell.value).replace('”', '"').replace('’', "'"),
          };

          const rest_off_cells = row.filter(
            (cell) => !(cell.title === column_names.nombre)
          );
          return [new_name_cell, ...rest_off_cells];
        });

        return rows_cell_name;
      };
      const rows_cells_ok = await rowsCellsNameOkAsync(rows_cells_with_dot);

      await handleUpdateCompletionPercentage(
        initial_completion_percentage + validar_datos_percentage * (3 / 7)
      );

      const { err, import_errors } = await validateCellsValues(
        rows_cells_ok,
        dimensiones_requeridas
      );
      await handleUpdateCompletionPercentage(
        initial_completion_percentage + validar_datos_percentage * (4 / 7)
      );

      if (!err) {
        let rows_normalized = [];

        for (const row of rows_cells_ok) {
          const normalized_row = {
            row_number: rows_cells_ok.indexOf(row) + 2, //en el excel tiene título (+1) y los array arrancan en cero (+1)
            normalized_product_name: normalizeName(
              row.find((element) => element.title === 'Nombre').value
            ),
            cells: [...row],
          };
          rows_normalized.push(normalized_row);
        }

        await handleUpdateCompletionPercentage(
          initial_completion_percentage + validar_datos_percentage * (5 / 7)
        );

        const hashTableAndValidateStockAsync = async (rows_normalized) => {
          const {
            err,
            import_errors,
            products_hash_table,
            normalized_product_names,
          } = productsHashTableAndValidateStock(rows_normalized);
          return {
            err,
            import_errors,
            products_hash_table,
            normalized_product_names,
          };
        };

        const {
          err: stock_err,
          import_errors: stock_import_errors,
          products_hash_table,
          normalized_product_names,
        } = await hashTableAndValidateStockAsync(rows_normalized);

        await handleUpdateCompletionPercentage(
          initial_completion_percentage + validar_datos_percentage * (7 / 7)
        );

        handleValidarDatosFinish(
          stock_err,
          stock_import_errors,
          products_hash_table,
          normalized_product_names
        );
      } else {
        handleValidarDatosFinish(err, import_errors, null, null, null);
      }
    };

    validationAsync();
  }, []);

  return <></>;
};
