import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Validate } from '../../../other';
import { LinkText } from '../subcomponents';
import { FilterSidenav } from '../../productos/subcomponents';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';

class ExportarProductos_ extends Component {
  state = {
    filter: {
      idCategorias: {
        validate: false,
        required: true,
        error: false,
        label: 'Categoría',
        id: 'idCategorias',
        name: 'idCategorias',
        change_param: 'filter',
        value: 0,
        messages: {
          error: '',
        },
      },
      p_tipo_venta: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de venta',
        id: 'p_tipo_venta',
        name: 'p_tipo_venta',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      p_producto_digital: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de producto',
        id: 'p_producto_digital',
        name: 'p_producto_digital',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      p_oferta: {
        validate: false,
        required: true,
        error: false,
        label: 'En oferta',
        id: 'p_oferta',
        name: 'p_oferta',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      dimensiones_configuradas: {
        validate: false,
        required: true,
        error: false,
        label: 'Dimensiones configuradas',
        id: 'dimensiones_configuradas',
        name: 'dimensiones_configuradas',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      estado_stock: {
        validate: false,
        required: true,
        error: false,
        label: 'Estado de stock',
        id: 'estado_stock',
        name: 'estado_stock',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      orden: {
        validate: false,
        required: true,
        error: false,
        label: 'Ordenar',
        id: 'orden',
        name: 'orden',
        change_param: 'filter',
        value: 0,
        messages: {
          error: '',
        },
      },
      incluir_descripcion: {
        validate: false,
        required: true,
        error: false,
        label: 'Incluir descripción',
        id: 'incluir_descripcion',
        name: 'incluir_descripcion',
        change_param: 'filter',
        value: 0,
        messages: {
          error: '',
        },
      },
    },
    selects: {
      categorias: [
        {
          idCategorias: 0,
          c_nombre: 'Todas las categorías',
          c_prefix: 'Todas las categorías',
          c_link_full: '',
          c_link: '',
          c_nombre_full: 'Todas las categorías',
          c_nombre_slash: 'Todas las categorías',
          c_padre: null,
          padres: [],
          hijos: [],
        },
      ],
      p_tipo_venta: [
        {
          value: -1,
          label: 'Todos los tipos',
        },
        {
          value: 0,
          label: 'Solo minorista',
        },
        {
          value: 1,
          label: 'Solo mayorista',
        },
        {
          value: 2,
          label: 'Mayorista y minorista',
        },
      ],
      p_producto_digital: [
        {
          value: -1,
          label: 'Todos los tipos',
        },
        {
          value: 0,
          label: 'Producto físico',
        },
        {
          value: 1,
          label: 'Producto digital (descarga automática)',
        },
        {
          value: 2,
          label: 'Producto digital (envío manual)',
        },
      ],
      p_oferta: [
        {
          value: -1,
          label: 'No filtrar',
        },
        {
          value: 0,
          label: 'Sin oferta',
        },
        {
          value: 1,
          label: 'Con oferta',
        },
        {
          value: 2,
          label: 'Oferta con fecha de finalización',
        },
        {
          value: 3,
          label: 'Oferta con fecha de inicio y finalización',
        },
      ],
      dimensiones_configuradas: [
        {
          value: -1,
          label: 'Todos',
        },
        {
          value: 0,
          label: 'Dimensiones sin configurar',
        },
        {
          value: 1,
          label: 'Dimensiones configuradas',
        },
      ],
      estado_stock: [
        {
          value: -1,
          label: 'Todos',
        },
        {
          value: 0,
          label: 'Sin stock',
        },
        {
          value: 1,
          label: 'Con stock',
        },
        {
          value: 2,
          label: 'Con stock ilimitado',
        },
      ],
      orden: [
        {
          value: 0,
          label: 'Más nuevo a más viejo',
        },
        {
          value: 1,
          label: 'Más viejo a más nuevo',
        },
        {
          value: 2,
          label: 'Menor precio',
        },
        {
          value: 3,
          label: 'Mayor precio',
        },
        {
          value: 4,
          label: 'Alfabéticamente',
        },
      ],
      incluir_descripcion: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
    },

    //carga de datos
    loading: {
      error: false,
      exportar: false,
    },

    detalle_stock: {
      idProductos: null, //producto que posee el detalle abierto
      p_atributos: 0, //el producto tiene o no atributos
      idStock: null, //stock que se desea editar
      editar: {}, //campos de edicion
    },

    //forms
    forms: {
      borrar: false,
      desactivar: false,
      detalle_stock: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },

    //sidenav
    filters_sidenav: false,

    //Tipo de sidenav según el botón
    sidenav_type: 'exportar',
  };

  componentDidMount() {
    let filter = this.state.filter;

    if (this.props.tienda.t_mayorista === 0) {
      filter = {
        ...filter,
        p_tipo_venta: {
          ...filter.p_tipo_venta,
          value: 0,
        },
      };
    }

    this.setState((prevState) => ({
      selects: {
        ...prevState.selects,
        categorias: [
          ...prevState.selects.categorias,
          ...this.props.categorias_flatten_jerarquico,
        ],
      },
      filter: filter,
    }));
  }

  //abrir y cerrar drawer
  handleDrawer = (open, type) => {
    this.setState({
      filters_sidenav: open,
      sidenav_type: type,
    });
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  limpiarFiltros = () => {
    this.setState((prevState) => ({
      filter: {
        idCategorias: {
          ...prevState.filter.idCategorias,
          value: 0,
        },
        p_tipo_venta: {
          ...prevState.filter.p_tipo_venta,
          value: this.props.tienda.t_mayorista === 0 ? 0 : -1,
        },
        p_producto_digital: {
          ...prevState.filter.p_producto_digital,
          value: -1,
        },
        p_oferta: {
          ...prevState.filter.p_oferta,
          value: -1,
        },
        dimensiones_configuradas: {
          ...prevState.filter.dimensiones_configuradas,
          value: -1,
        },
        estado_stock: {
          ...prevState.filter.estado_stock,
          value: -1,
        },
        orden: {
          ...prevState.filter.orden,
          value: 0,
        },
        incluir_descripcion: {
          ...prevState.filter.incluir_descripcion,
          value: 0,
        },
        search: {
          ...prevState.filter.search,
          value: '',
        },
        search_codigo: {
          ...prevState.filter.search_codigo,
          value: '',
        },
        page: 0,
      },
    }));
  };

  handleSubmitFilter = (type) => {
    //No se usa este modo. Solo se usa exportar
  };

  obtenerParametros = () => {
    let filter = this.state.filter;
    return {
      idCategorias: filter.idCategorias.value,
      p_tipo_venta: filter.p_tipo_venta.value,
      p_producto_digital: filter.p_producto_digital.value,
      p_oferta: filter.p_oferta.value,
      dimensiones_configuradas: filter.dimensiones_configuradas.value,
      estado_stock: filter.estado_stock.value,
      orden: filter.orden.value,
    };
  };

  handleExportStart = () => {
    this.setState((prevState) => ({
      loading: {
        ...prevState.loading,
        exportar: true,
      },
    }));
    this.props.exportStart();
  };

  handleExportFinish = (err, resp, message) => {
    this.setState((prevState) => ({
      loading: {
        ...prevState.loading,
        exportar: false,
      },
    }));

    if (!err) {
      createAmplitudeEventWithDevice(
        import_amplitude_events.exportar_desde_importacion_success.event_name,
        import_amplitude_events.exportar_desde_importacion_success.event_params
      );
    } else {
      createAmplitudeEventWithDevice(
        import_amplitude_events.exportar_desde_importacion_failed.event_name,
        import_amplitude_events.exportar_desde_importacion_failed.event_params
      );
    }

    this.props.exportFinish(err, resp, message);
  };

  render() {
    return (
      <>
        <p className={this.props.classes.import_text}>
          Para agregar nuevos productos&nbsp;
          <LinkText
            type="callf"
            text="Descargá la planilla vacía"
            onClickFunc={(e) => {
              createAmplitudeEventWithDevice(
                import_amplitude_events.descargar_planilla_vacia.event_name,
                { success: true }
              );
              const link =
                'https://recursos.empretienda.com/planilla-importacion-productos-vacia.xlsx';
              window.open(link, '_blank');
            }}
          />
          . Para editar productos existentes&nbsp;
          <LinkText
            type="callf"
            text="Exportá tu planilla de productos actual"
            onClickFunc={(e) => this.handleDrawer(true, 'exportar')}
          />
          .
        </p>
        <FilterSidenav
          filter={this.state.filter}
          selects={this.state.selects}
          isFiltersDrawerOpen={this.state.filters_sidenav}
          handleDrawer={this.handleDrawer}
          handleChangeSelect={this.handleChangeSelect}
          limpiarFiltros={this.limpiarFiltros}
          handleSubmitFilter={this.handleSubmitFilter}
          type={this.state.sidenav_type}
          loading={this.state.loading}
          exportStart={this.handleExportStart}
          exportFinish={this.handleExportFinish}
          obtenerParametros={this.obtenerParametros}
        />
      </>
    );
  }
}

const styles = (theme) => ({
  import_text: {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '19px',
    color: '#000000',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      fontSize: '0.87rem',
      lineHeight: '20px',
    },
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = null;

export const ExportarProductos = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExportarProductos_));
