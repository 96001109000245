import React from 'react';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToggleUI } from './';

const CardHeaderUI = ({
  header_title,
  header_subtitle,
  header_button,
  classes,
}) => {
  return (
    <ToggleUI show={header_title ? true : false}>
      <CardHeader
        title={header_title}
        subheader={header_subtitle}
        className={classes.card_header}
        classes={{ action: classes.card_action_button }}
        action={header_button}
      />
      <Divider />
    </ToggleUI>
  );
};

const CardModuleUI_ = ({
  classes,
  className = '',
  below = false,
  with_toggle = false,
  big_shadow = false,
  show = true,
  header_title = '',
  header_subtitle = '',
  header_button = '',
  disablePadding = false,
  children,
  ...rest
}) => {
  let card_class = below
    ? big_shadow
      ? classes.card_shadow_below
      : classes.card_below
    : big_shadow
    ? classes.card_shadow
    : classes.card;

  if (with_toggle) {
    return (
      <ToggleUI show={show}>
        <Card className={`${card_class} ${className}`} {...rest}>
          <CardHeaderUI
            header_title={header_title}
            header_subtitle={header_subtitle}
            header_button={header_button}
            classes={classes}
          />
          <CardContent
            className={
              disablePadding
                ? classes.card_content_no_padding
                : classes.card_content
            }
          >
            {children}
          </CardContent>
        </Card>
      </ToggleUI>
    );
  } else {
    return (
      <Card className={`${card_class} ${className}`} {...rest}>
        <CardHeaderUI
          header_title={header_title}
          header_subtitle={header_subtitle}
          header_button={header_button}
          classes={classes}
        />
        <CardContent
          className={
            disablePadding
              ? classes.card_content_no_padding
              : classes.card_content
          }
        >
          {children}
        </CardContent>
      </Card>
    );
  }
};

const styles = (theme) => ({
  card: theme.card.card,
  card_shadow: {
    ...theme.card.card,
    boxShadow: ' 0 20px 80px 0 rgba(0, 0, 0, 0.1)',
  },
  card_below: theme.card.card_below,
  card_shadow_below: {
    ...theme.card.card_below,
    boxShadow: ' 0 20px 80px 0 rgba(0, 0, 0, 0.1)',
  },
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_content_no_padding: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

export const CardModuleUI = withStyles(styles)(CardModuleUI_);
