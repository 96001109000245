const productoObtenerProductos = () => {
  return {
    status: 200,
    data: [
      {
        idProductos: 3868362,
        p_nombre: 'Freddy Krugger',
        p_precio: 2000,
        p_link: 'freddy-krugger',
        p_descripcion: '',
        p_oferta: 0,
        p_precio_oferta: 0,
        p_oferta_fecha_inicio: null,
        p_oferta_fecha: null,
        p_tipo_venta: 0,
        p_precio_mayorista: 0,
        p_cantidad_minima: 1,
        p_destacado: 0,
        p_peso: 0,
        p_ancho: 0,
        p_alto: 0,
        p_profundidad: 0,
        p_atributos: 0,
        p_mostrar_precio: 1,
        p_datos_stock: 1,
        p_producto_digital: 0,
        p_desactivado: 0,
        p_orden: 0,
        Categorias_idCategorias: 35400,
        imagenes: [
          {
            idImagenes: 437435,
            i_link:
              '9a004a76a226a5fe8cd1f14dc485310669159d07cdcf25de797d9f5abbdb9862110154.jpeg',
            i_orden: 0,
          },
        ],
        atributos: [],
        stock: [
          {
            idStock: 394811,
            s_sku: '',
            s_cantidad: 0,
            s_ilimitado: 1,
            s_precio: 2000,
            s_oferta: 0,
            s_precio_oferta: 0,
            s_oferta_fecha_inicio: null,
            s_oferta_fecha: null,
            s_precio_mayorista: 0,
            s_cantidad_minima: 1,
            s_mostrar_precio: 1,
            s_peso: 0,
            s_ancho: 0,
            s_alto: 0,
            s_profundidad: 0,
            s_imagen: 437435,
            s_producto_digital_link: '',
            s_producto_digital_plazo: '',
            s_producto_digital_observacion: '',
            valoratributo: [],
          },
        ],
        categorias_productos: [
          {
            idCategorias: 35400,
            c_nombre: 'Desarrollo',
            c_link: 'desarrollo',
            c_orden: 2,
            c_padre: null,
            c_google_category_id: 0,
          },
        ],
      },
      {
        idProductos: 3868051,
        p_nombre: 'Mochila',
        p_precio: 300,
        p_link: 'mochila',
        p_descripcion: '',
        p_oferta: 0,
        p_precio_oferta: 0,
        p_oferta_fecha_inicio: null,
        p_oferta_fecha: null,
        p_tipo_venta: 0,
        p_precio_mayorista: 0,
        p_cantidad_minima: 1,
        p_destacado: 0,
        p_peso: 0,
        p_ancho: 0,
        p_alto: 0,
        p_profundidad: 0,
        p_atributos: 0,
        p_mostrar_precio: 1,
        p_datos_stock: 1,
        p_producto_digital: 2,
        p_desactivado: 0,
        p_orden: 0,
        Categorias_idCategorias: 35400,
        imagenes: [
          {
            idImagenes: 437058,
            i_link:
              '96b7c559518b2bebccd09bda740e5dc401b75cd7b6dd3138dd3e42ff65cb2454110154.png',
            i_orden: 0,
          },
        ],
        atributos: [],
        stock: [
          {
            idStock: 394486,
            s_sku: '',
            s_cantidad: 0,
            s_ilimitado: 1,
            s_precio: 300,
            s_oferta: 0,
            s_precio_oferta: 0,
            s_oferta_fecha_inicio: null,
            s_oferta_fecha: null,
            s_precio_mayorista: 0,
            s_cantidad_minima: 1,
            s_mostrar_precio: 1,
            s_peso: 0,
            s_ancho: 0,
            s_alto: 0,
            s_profundidad: 0,
            s_imagen: 437058,
            s_producto_digital_link: '',
            s_producto_digital_plazo: '',
            s_producto_digital_observacion: '',
            valoratributo: [],
          },
        ],
        categorias_productos: [
          {
            idCategorias: 35400,
            c_nombre: 'Desarrollo',
            c_link: 'desarrollo',
            c_orden: 2,
            c_padre: null,
            c_google_category_id: 0,
          },
        ],
      },
    ],
    message: 'Ok',
  };
};

export default {
  productoObtenerProductos,
};
