import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { enviopersonalizadoAgregar } from '../../../../store/actions';
import { Card, CardContent, Grid } from '@mui/material';
import { Regex, RegexExtra, Validate, Config } from '../../../../other';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  ToggleUI,
  NextActionsUI,
  ModuleCardTitleUI,
  AutosuggestUI,
} from '../../../../components/common';
import { withStyles } from '@mui/styles';
import { WrapperLayout } from '../../../../layouts/WrapperLayout';

class EnvioPersonalizadoAgregar_ extends Component {
  state = {
    agregar: {
      ep_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre del método de envío',
        id: 'ep_nombre',
        name: 'ep_nombre',
        change_param: 'agregar',
        regex: Regex.STRING_GENERICO_2_90,
        value: '',
        default_value: '',
        placeholder: 'Cadetería',
        messages: {
          help: 'Elige un nombre que represente a tu método de envío',
          error: 'Por favor, ingrese un nombre de envío válido',
          error_extra: RegexExtra.STRING_GENERICO_2_90,
        },
      },
      ep_descripcion: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Descripción',
        id: 'ep_descripcion',
        name: 'ep_descripcion',
        change_param: 'agregar',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Se entrega por cadetería al día siguiente',
        messages: {
          help: 'Esta información se le mostrará a los clientes cuando calculen el costo de envío o lo seleccionen en el checkout.',
          error: 'Por favor, ingrese una descripción para el envío válida',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
      ep_cobro_envio: {
        validate: false,
        required: true,
        error: false,
        label: 'Cobrar por el envío',
        id: 'ep_cobro_envio',
        name: 'ep_cobro_envio',
        change_param: 'agregar',
        value: 0,
        messages: {
          help: 'Cobrar un costo adicional por el envío.',
          error: 'Por favor, seleccione un tipo de cobro de envío',
        },
      },
      ep_efectivo: {
        validate: false,
        required: true,
        error: false,
        label: 'Pago en efectivo',
        id: 'ep_efectivo',
        name: 'ep_efectivo',
        change_param: 'agregar',
        value: 0,
        messages: {
          help: 'Tus clientes podrán pagarte la orden utilizando el método de pago efectivo si se encuentra habilitado en tu tienda y seleccionan esta opción de envío.',
          error: 'Por favor, seleccione una opción de pago',
        },
      },
      ep_precio: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Costo del envío',
        id: 'ep_precio',
        name: 'ep_precio',
        change_param: 'agregar',
        regex: Regex.PRECIO_POSITIVO.ARG,
        value: '',
        default_value: '',
        placeholder: '149.99',
        messages: {
          help: 'El precio que se le cobrará al cliente por dicho envío.',
          error: 'Por favor, ingrese un precio válido',
          error_extra: RegexExtra.PRECIO_POSITIVO.ARG,
        },
      },
      ep_filtrar_peso: {
        validate: false,
        required: true,
        error: false,
        label: 'Condición de peso',
        id: 'ep_filtrar_peso',
        name: 'ep_filtrar_peso',
        change_param: 'agregar',
        value: 0,
        messages: {
          help: 'Habilitar esta opción de envío si el peso de la compra se encuentra en un rango específico.',
          error: 'Por favor, seleccione una condición de peso para el envío',
        },
      },
      ep_peso_minimo: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Peso mínimo',
        id: 'ep_peso_minimo',
        name: 'ep_peso_minimo',
        change_param: 'agregar',
        regex: Regex.PESO,
        value: '',
        default_value: '',
        placeholder: '0',
        messages: {
          help: 'El peso mínimo que debe tener la compra para poder utilizar el método',
          error: 'Por favor, ingrese un peso mínimo válido',
          error_extra: RegexExtra.PESO,
        },
      },
      ep_peso_maximo: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Peso máximo',
        id: 'ep_peso_maximo',
        name: 'ep_peso_maximo',
        change_param: 'agregar',
        regex: Regex.PESO,
        value: '',
        default_value: '',
        placeholder: '0.5',
        messages: {
          help: 'El peso máximo que debe tener la compra para poder utilizar el método',
          error: 'Por favor, ingrese un peso máximo válido',
          error_extra: RegexExtra.PESO,
        },
      },
      ep_filtrar_precio: {
        validate: false,
        required: true,
        error: false,
        label: 'Condición de precio mínimo',
        id: 'ep_filtrar_precio',
        name: 'ep_filtrar_precio',
        change_param: 'agregar',
        value: 0,
        messages: {
          help: 'Habilitar esta opción de envío si el precio de la compra supera un monto específico.',
          error: 'Por favor, seleccione una condición de precio para el envío',
        },
      },
      ep_precio_minimo: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'ep_precio_minimoo',
        name: 'ep_precio_minimo',
        change_param: 'agregar',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '1000',
        messages: {
          help: 'El monto mínimo requerido de la compra para elegir este envío.',
          error: 'Por favor, ingrese un precio válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
      ep_filtrado_geografico: {
        validate: false,
        required: true,
        error: false,
        label: 'Filtrar geográficamente',
        id: 'ep_filtrado_geografico',
        name: 'ep_filtrado_geografico',
        change_param: 'agregar',
        value: 0,
        messages: {
          help: 'Habilitar esta opción de envío si el cliente se encuentra en una ubicación geográfica determinada.',
          error: 'Por favor, seleccione una opción geográfica',
        },
      },
      geodata: {
        validate: false,
        selected: [],
      },
    },
    forms: {
      agregar: false,
    },
    after: false,
    selects: {
      ep_cobro_envios: [
        {
          value: 0,
          label: 'No (Envío gratuito)',
        },
        {
          value: 1,
          label: 'Si (Envío con costo fijo, pago online)',
        },
        {
          value: 2,
          label: 'Si (Envío con costo fijo, pago en destino)',
        },
        {
          value: 3,
          label: 'Si (Envío sin costo fijo, pago en destino)',
        },
      ],
      ep_efectivo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      ep_filtrar_peso: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      ep_filtrar_precio: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      ep_filtrado_geografico: [
        {
          value: 0,
          label: 'No filtrar (Válido para todo el país)',
        },
        {
          value: 1,
          label: 'Filtrar por provincias',
        },
        {
          value: 2,
          label: 'Filtrar por ciudades',
        },
      ],
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    feed_input_value: '',
    feed: [],
  };

  //autosuggest
  debounceMethod = null;

  handleOnSuggestionSelected = (selected_suggestion) => {
    this.setState((prevState) => ({
      agregar: {
        ...prevState.agregar,
        geodata: {
          ...prevState.agregar.geodata,
          selected: [
            ...prevState.agregar.geodata.selected,
            selected_suggestion,
          ],
        },
      },
      feed_input_value: '',
    }));
  };

  canBeGeodataOption = (idlabel, id) => {
    return this.state.agregar.geodata.selected.filter((option) => {
      return option[idlabel] === id;
    }).length > 0
      ? false
      : true;
  };

  handleSuggestionFetchData = (value) => {
    const inputValue = Validate.trim_lowercase(value);
    if (inputValue.length > 1) {
      axios
        .get(
          `${Config.BACKEND_ENDPOINT}/${
            this.state.agregar.ep_filtrado_geografico.value === 1
              ? 'provincia'
              : 'ciudad'
          }/buscar`,
          {
            params: { search: inputValue },
          }
        )
        .then((resp) => {
          let idlabel =
            this.state.agregar.ep_filtrado_geografico.value === 1
              ? 'idProvincias'
              : 'idCiudades';

          //filtro las que ya existen en los chips para evitar duplicados
          let feed = resp.data.data.filter((option) => {
            return this.canBeGeodataOption(idlabel, option[idlabel]);
          });

          this.setState({ feed: feed });
        })
        .catch((err) => {
          this.setState((prevState) => ({
            alert: {
              ...prevState.alert,
              open: true,
              message: 'Ha ocurrido un error al buscar, intente nuevamente',
              type: 'error',
            },
          }));
        });
    }
  };

  handleDeleteChip = (deleted_option) => {
    let idlabel =
      this.state.agregar.ep_filtrado_geografico.value === 1
        ? 'idProvincias'
        : 'idCiudades';

    let selected = this.state.agregar.geodata.selected.filter((option) => {
      return option[idlabel] !== deleted_option[idlabel];
    });

    this.setState((prevState) => ({
      agregar: {
        ...prevState.agregar,
        geodata: {
          ...prevState.agregar.geodata,
          selected: selected,
        },
      },
      feed_input_value: '',
    }));
  };

  handleSuggestionsFetchRequested = ({ value }) => this.debounceMethod(value);

  handleSuggestionsClearRequested = () => this.setState({ feed: [] });

  handleSuggestionsChangeSelected = (e, { newValue }) =>
    this.setState({ feed_input_value: newValue });
  //end autosuggest

  componentDidMount() {
    this.debounceMethod = debounce(this.handleSuggestionFetchData, 500);
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    switch (name) {
      case 'ep_cobro_envio':
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
            ep_precio: {
              ...prevState.agregar.ep_precio,
              validate: value === 1 || value === 2 ? true : false,
            },
          },
        }));
        break;
      case 'ep_filtrar_precio':
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
            ep_precio_minimo: {
              ...prevState.agregar.ep_precio_minimo,
              validate: value === 1 ? true : false,
            },
          },
        }));
        break;
      case 'ep_filtrar_peso':
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
            ep_peso_minimo: {
              ...prevState.agregar.ep_peso_minimo,
              validate: value === 1 ? true : false,
            },
            ep_peso_maximo: {
              ...prevState.agregar.ep_peso_maximo,
              validate: value === 1 ? true : false,
            },
          },
        }));
        break;
      case 'ep_filtrado_geografico':
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
            geodata: {
              validate: false,
              selected: [],
            },
          },
          feed_input_value: '',
          feed: [],
        }));
        break;
      default:
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
          },
        }));
        break;
    }
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        agregar: true,
      },
    }));

    let form = this.state.agregar;

    if (Validate.validar_formulario(form)) {
      if (
        (form.ep_filtrado_geografico.value > 0 &&
          form.geodata.selected.length > 0) ||
        form.ep_filtrado_geografico.value === 0
      ) {
        let geodata = [];

        if (form.ep_filtrado_geografico.value > 0) {
          let idlabel =
            form.ep_filtrado_geografico.value === 1
              ? 'idProvincias'
              : 'idCiudades';
          form.geodata.selected.forEach((geopoint) => {
            geodata.push(geopoint[idlabel]);
          });
        }

        this.props.enviopersonalizadoAgregar(
          {
            ep_nombre: form.ep_nombre.value,
            ep_descripcion: form.ep_descripcion.value,
            ep_cobro_envio: form.ep_cobro_envio.value,
            ep_precio: form.ep_precio.value,
            ep_filtrar_peso: form.ep_filtrar_peso.value,
            ep_peso_minimo: form.ep_peso_minimo.value,
            ep_peso_maximo: form.ep_peso_maximo.value,
            ep_filtrar_precio: form.ep_filtrar_precio.value,
            ep_precio_minimo: form.ep_precio_minimo.value,
            ep_filtrado_geografico: form.ep_filtrado_geografico.value,
            ep_efectivo: form.ep_efectivo.value,
            geodata: geodata,
          },
          (err, resp) => {
            this.setState((prevState) => ({
              forms: {
                ...prevState.forms,
                agregar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              after: err ? false : true,
            }));
          }
        );
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            agregar: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message:
              'Debes elegir al menos una ' +
              (form.ep_filtrado_geografico.value === 1
                ? 'provincia'
                : 'ciudad'),
            type: 'error',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            agregar: false,
          },
          agregar: new_form,
          alert: {
            ...prevState.alert,
            open: true,
            message: 'Tienes algunos errores, por favor revisa los datos',
            type: 'error',
          },
        }));
      });
    }
  };

  render() {
    let classes = this.props.classes;

    return (
      <WrapperLayout loading={false} error={false}>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <ToggleUI show={!this.state.after}>
            <form onSubmit={this.handleSubmit}>
              <Grid item xs={12}>
                <ModuleTitleUI title="Agregar envío personalizado" />
                <Card className={classes.card}>
                  <CardContent className={classes.card_content}>
                    <Grid container spacing={1.5}>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.agregar.ep_nombre}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.agregar.ep_descripcion}
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectUI
                          data={this.state.agregar.ep_cobro_envio}
                          options={this.state.selects.ep_cobro_envios}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={Validate.in_array(
                          this.state.agregar.ep_cobro_envio.value,
                          [1, 2]
                        )}
                      >
                        <Grid item xs={12}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.agregar.ep_precio}
                            adornment="$"
                            step="0.01"
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12}>
                        <SelectUI
                          data={this.state.agregar.ep_efectivo}
                          options={this.state.selects.ep_efectivo}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className={classes.card_below}>
                  <CardContent className={classes.card_content}>
                    <Grid container spacing={1.5}>
                      <ModuleCardTitleUI
                        title="Condiciones de elegibilidad"
                        subtitles={[
                          'Acá podés ajustar los parámetros para determinar quién puede utilizar el método de envío',
                        ]}
                      />
                      <Grid item xs={12}>
                        <SelectUI
                          data={this.state.agregar.ep_filtrar_peso}
                          options={this.state.selects.ep_filtrar_peso}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={this.state.agregar.ep_filtrar_peso.value === 1}
                      >
                        <Grid item md={6} xs={12}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.agregar.ep_peso_minimo}
                            end_adornment="kg"
                            step="0.01"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.agregar.ep_peso_maximo}
                            end_adornment="kg"
                            step="0.01"
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12}>
                        <SelectUI
                          data={this.state.agregar.ep_filtrar_precio}
                          options={this.state.selects.ep_filtrar_precio}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={this.state.agregar.ep_filtrar_precio.value === 1}
                      >
                        <Grid item xs={12}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.agregar.ep_precio_minimo}
                            adornment="$"
                            step="0.01"
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12}>
                        <SelectUI
                          data={this.state.agregar.ep_filtrado_geografico}
                          options={this.state.selects.ep_filtrado_geografico}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={Validate.in_array(
                          this.state.agregar.ep_filtrado_geografico.value,
                          [1, 2]
                        )}
                      >
                        <Grid item xs={12}>
                          <AutosuggestUI
                            suggestions={this.state.feed}
                            input_value={this.state.feed_input_value}
                            suggestion_selected={
                              this.state.agregar.geodata.selected
                            }
                            multiple
                            suggestion_index={
                              this.state.agregar.ep_filtrado_geografico
                                .value === 1
                                ? 'pro_nombre'
                                : 'ciu_nombrecompleto'
                            }
                            label={
                              this.state.agregar.ep_filtrado_geografico
                                .value === 1
                                ? 'Provincias aceptadas'
                                : 'Ciudades aceptadas'
                            }
                            placeholder={
                              this.state.agregar.ep_filtrado_geografico
                                .value === 1
                                ? 'Ej: Santa Fe'
                                : 'Ej: Rosario'
                            }
                            handleOnSuggestionSelected={
                              this.handleOnSuggestionSelected
                            }
                            handleSuggestionsFetchRequested={
                              this.handleSuggestionsFetchRequested
                            }
                            handleSuggestionsClearRequested={
                              this.handleSuggestionsClearRequested
                            }
                            handleSuggestionsChangeSelected={
                              this.handleSuggestionsChangeSelected
                            }
                            handleDeleteChip={this.handleDeleteChip}
                          />
                        </Grid>
                      </ToggleUI>
                    </Grid>
                  </CardContent>
                </Card>
                <ModuleButtonBoxUI>
                  <ButtonUI
                    type="submit"
                    label="Agregar"
                    isLoading={this.state.forms.agregar}
                    minHeight={true}
                  />
                </ModuleButtonBoxUI>
              </Grid>
            </form>
          </ToggleUI>
        </Grid>
        <ToggleUI show={this.state.after}>
          <NextActionsUI
            title="¡El método de envío ha sido creado con éxito!"
            buttons={[
              <ButtonUI
                type="link"
                label="Ver mis métodos"
                link="/metodos-envio/envios-personalizados"
              />,
              <ButtonUI
                type="link"
                label="Crear otro método"
                link="/metodos-envio/envios-personalizados/agregar"
                external_link
              />,
            ]}
          />
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    enviopersonalizadoAgregar: (data, callback) =>
      dispatch(enviopersonalizadoAgregar(data, callback)),
  };
};

const EnvioPersonalizadoAgregarPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EnvioPersonalizadoAgregar_)
);

export default EnvioPersonalizadoAgregarPage;
