import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tiendaObtener, tiendaActualizarChat } from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { Card, CardContent, Grid, Icon } from '@mui/material';
import {
  InputUI,
  ButtonUI,
  CheckboxUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
} from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { withStyles } from '@mui/styles';
class ChatPage_ extends Component {
  state = {
    chat: {
      t_chat: {
        validate: false,
        name: 't_chat',
        label: 'Activar chat',
        value: 1,
        change_param: 'chat',
      },
      t_chat_facebook: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Página de facebook',
        id: 't_chat_facebook',
        name: 't_chat_facebook',
        change_param: 'chat',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una página de facebook válida',
          help: 'Si tu página de facebook es www.facebook.com/empretiendaok, solo coloca empretiendaok',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_chat_whatsapp: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Whatsapp',
        id: 't_chat_whatsapp',
        name: 't_chat_whatsapp',
        change_param: 'chat',
        regex: Regex.TELEFONO,
        value: '',
        default_value: '',
        placeholder: '5491122222222',
        messages: {
          error: 'Ingrese un número de whatsapp válido',
          help: 'Recomendamos que lo agregues con código de país (549 para Argentina) y sin simbolos ni espacios, ejemplo: 5491122222222, en lugar de +549 (11) 22222222',
          error_extra: RegexExtra.TELEFONO,
        },
      },
    },
    loading: {
      chat: true,
      error: false,
    },
    forms: {
      chat: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            chat: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          this.state.chat,
          (chat_obj) => {
            this.setState((prevState) => ({
              chat: chat_obj,
              loading: {
                ...prevState.loading,
                chat: false,
                error: false,
              },
            }));
          }
        );
      }
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    Validate.validate_input(
      {
        ...this.state[aditional][name],
        value: value,
      },
      blur,
      (input) => {
        this.setState((prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: input,
          },
        }));
      }
    );
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: prevState[aditional][name]['value'] ? 0 : 1,
        },
      },
    }));
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        chat: true,
      },
    }));

    let form = this.state.chat;

    if (Validate.validar_formulario(form)) {
      if (
        (form.t_chat.value === 1 &&
          (form.t_chat_facebook.value !== '' ||
            form.t_chat_whatsapp.value !== '')) ||
        form.t_chat.value === 0
      ) {
        this.props.tiendaActualizarChat(
          {
            t_chat: form.t_chat.value,
            t_chat_facebook: form.t_chat_facebook.value,
            t_chat_whatsapp: form.t_chat_whatsapp.value,
          },
          (err, resp) => {
            this.setState((prevState) => ({
              forms: {
                ...prevState.forms,
                chat: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            }));
          }
        );
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            chat: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message:
              'Debes completar al menos un medio de contacto para activar el chat',
            type: 'success',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            chat: false,
          },
          chat: new_form,
        }));
      });
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout
        loading={this.state.loading.chat}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="Chat"
                subtitles={[
                  'Un chat en tu tienda online te permitirá comunicarte mejor con tus potenciales clientes y concretar ventas.',
                  'El chat lo podés vincular con tu página de Facebook o con tu número de WhatsApp.',
                ]}
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={<Icon className="fab fa-facebook-square" />}
                        adornment_extra="/"
                        input={this.state.chat.t_chat_facebook}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={<Icon className="fab fa-whatsapp" />}
                        adornment_extra="+"
                        input={this.state.chat.t_chat_whatsapp}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={this.state.chat.t_chat}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  minHeight={true}
                  isLoading={this.state.forms.chat}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarChat: (data, callback) =>
      dispatch(tiendaActualizarChat(data, callback)),
  };
};

const ChatPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChatPage_)
);

export default ChatPage;
