import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const InfoCard_ = ({ classes, title, subtitle }) => {
  return (
    <div className={classes.card_container}>
      <Typography className={classes.card_title} gutterBottom component="p">
        {title}
      </Typography>
      <Typography className={classes.card_subtitle} gutterBottom component="p">
        {subtitle}
      </Typography>
    </div>
  );
};

const styles = (theme) => ({
  card_container: {
    marginRight: '32px',
    [theme.breakpoints.down('md')]: {
      marginRight: '24px',
    },
  },
  card_title: {
    fontWeight: '500',
    fontSize: '0.75rem',
    color: '#9E9E9E',
  },
  card_subtitle: {
    marginTop: '6px',
    fontWeight: '600',
    fontSize: '0.87rem',
    color: '#3A3A3A',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      whiteSpace: 'nowrap',
    },
  },
});

export const InfoCard = withStyles(styles)(InfoCard_);
