import React from 'react';
import {
  SwipeableDrawer,
  Grid,
  IconButton,
  Typography,
  Icon,
  Button,
  Box,
} from '@mui/material';
import { SelectUI, ButtonUI } from '../../common';
import { Validate } from '../../../other';

const es_iphone = Validate.is_iphone();

export const FilterSidenav = ({
  filter,
  selects,
  isFiltersDrawerOpen,
  handleDrawer,
  handleChangeSelect,
  limpiarFiltros,
  handleSubmitFilter,
  type,
  loading,
}) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={isFiltersDrawerOpen}
      onClose={() => handleDrawer(false, type)}
      onOpen={() => handleDrawer(true, type)}
      disableBackdropTransition={!es_iphone}
      disableDiscovery={es_iphone}
    >
      <Box
        sx={{
          width: '300px',
          padding: 2,
        }}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={0.5}>
              <Grid item>
                <IconButton
                  aria-label="Cancelar"
                  onClick={() => handleDrawer(false, type)}
                  size="large"
                >
                  <Icon>clear</Icon>
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {type === 'filtrar' ? 'Filtrar' : 'Exportar'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={filter.cl_mayorista}
              options={selects.cl_mayorista}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={filter.cl_bloqueado}
              options={selects.cl_bloqueado}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12} mb={0.5}>
            <SelectUI
              data={filter.cl_registro_completo}
              options={selects.cl_registro_completo}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12} mb={0.5}>
            <ButtonUI
              type="callf"
              label={type === 'exportar' ? 'Exportar' : 'Aplicar filtros'}
              size="large"
              isLoading={
                type === 'exportar' ? loading.exportar : loading.clientes
              }
              onClickFunc={() => handleSubmitFilter(type)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ marginTop: '2px' }}
              onClick={(e) => limpiarFiltros()}
              variant="outlined"
              size="large"
              fullWidth
            >
              <Box display="flex" alignItems="center" p="3px 0px">
                <Icon sx={{ marginRight: '10px' }}>delete</Icon>
                Limpiar filtros
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};
