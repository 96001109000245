import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const ImportContainer = styled(Box)(({ theme }) => ({
  border: '1px dashed #242424',
  borderRadius: '6px',
  padding: '40px',
  [theme.breakpoints.down('md')]: {
    padding: '75px 0px 75px 0px',
    margin: '10px 0px 0px 0px',
  },
}));

export const ImportText = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '19px',
  color: '#000000',
  marginBottom: '24px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '0px',
    fontSize: '0.87rem',
    lineHeight: '20px',
  },
}));
