import React from 'react';
import { Grid } from '@mui/material';
import { ToggleUI } from '../../common';
import { TurnOffShop } from './ApagarTienda';
import { PlanillaCicloDeVida } from './PlanillaCicloDeVida';

export const ImportarProductos = ({
  handleChange,
  handleSubmitApagar,
  etapa,
  input_apagar_temporalmente,
  exportStart,
  exportFinish,
  categorias_flatten_jerarquico,
  handleImpactandoTienda,
  dimensiones_requeridas,
  apagar_temporalmente,
}) => {
  return (
    <Grid container spacing={1.5}>
      <ToggleUI show={etapa === 'APAGAR_TIENDA' ? true : false}>
        <Grid item xs={12}>
          <TurnOffShop
            handleChange={handleChange}
            handleSubmitApagar={handleSubmitApagar}
            input_apagar_temporalmente={input_apagar_temporalmente}
            apagar_temporalmente={apagar_temporalmente}
          />
        </Grid>
      </ToggleUI>
      <ToggleUI show={etapa === 'PROCESAR_PLANILLA' ? true : false}>
        <PlanillaCicloDeVida
          etapa={etapa}
          exportStart={exportStart}
          exportFinish={exportFinish}
          categorias_flatten_jerarquico={categorias_flatten_jerarquico}
          handleImpactandoTienda={handleImpactandoTienda}
          dimensiones_requeridas={dimensiones_requeridas}
        />
      </ToggleUI>
    </Grid>
  );
};
