/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const clienteReducer = (state = initState, action) => {
  let clientes;
  switch (action.type) {
    case Reducers.CLIENTE_GUARDAR:
      clientes = action.data;
      return clientes;

    case Reducers.CLIENTE_GUARDAR_NO_CLEAN:
      clientes = action.data;
      return [...state, ...clientes];

    case Reducers.CLIENTE_AGREGAR:
      let nuevo_cliente = action.data;
      clientes = [...state, nuevo_cliente];
      return clientes;

    case Reducers.CLIENTE_EDITAR:
      let cliente_editado = action.data;

      clientes = state.map((cliente) => {
        if (cliente.idClientes === cliente_editado.idClientes) {
          return {
            ...cliente_editado,
            ultimas_ventas: cliente.ultimas_ventas,
          };
        } else {
          return cliente;
        }
      });
      return clientes;

    case Reducers.CLIENTE_REVOCAR_MAYORISTA:
      let cliente_revocado = action.data;
      clientes = state.map((cliente) => {
        if (cliente.idClientes === cliente_revocado.idClientes) {
          return {
            ...cliente,
            cl_mayorista: 0,
          };
        } else {
          return cliente;
        }
      });
      return clientes;

    case Reducers.CLIENTE_BORRAR:
      let cliente_borrado = action.data;
      clientes = state.filter((cliente) => {
        return cliente.idClientes !== cliente_borrado.idClientes;
      });
      return clientes;
    case Reducers.CLIENTE_ACTUALIZAR_EXPORTADOS:
      clientes = state.map((cliente) => {
        return {
          ...cliente,
          cl_exportado: 1,
        };
      });
      return clientes;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { clienteReducer };
