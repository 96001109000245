import { read, utils } from 'xlsx';

export const normalizeName = (name) => {
  return String(name)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

/**
 * Exports rows and columns of a given workbook
 * @typedef {Object} Rows_Titles
 * @property {Error} err - there are errors
 * @property {any[]} rows - a list of rows
 * @property { {key:string, name:string[]} } columns - a list of columns
 */

/**
 * Exports rows and columns of a given workbook
 * @param {Object} workBook - a workbook
 * @param {String} sheetName - Name of workbook's sheet
 * @return { Rows_Titles } a list of rows and a list of columns
 */
const getRowsCols = (workBook, sheetName) => {
  //const rows = utils.sheet_to_json(workBook[sheetName], {header:1, defval:''});
  const rows = utils.sheet_to_json(workBook[sheetName], {
    header: 1,
    defval: '',
    blankrows: false,
    raw: true,
    skipHidden: true,
    rawNumbers: true,
  });
  let columns = [];
  const err = null;
  for (let row of rows) {
    const keys = Object.keys(row);
    if (keys.length > columns.length) {
      columns = keys.map((key) => {
        return {
          key,
          name: utils.encode_col(+key),
        };
      });
    }
  }
  return { err, rows, columns };
};

/**
 * Exports rows and columns of a given workbook
 * @param {any} workbookBuffer - a buffer from a workbook (.xls, .xlsx, .csv)
 * @return { Rows_Titles } a list of rows and a list of columns
 */
export const workbookBufferToRows = (workbookBuffer) => {
  const data = read(workbookBuffer);

  const workBook = data.Sheets;
  const workSheets = data.SheetNames;
  return getRowsCols(workBook, workSheets[0]);
};
