import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../common';
import { Regex, RegexExtra, Validate } from '../../../other';
import { tiendaInformarPago } from '../../../store/actions';
import { connect } from 'react-redux';

const ModalSetIdPayment_ = ({
  open,
  billId,
  handleSubmit,
  modalHandler,
  setIdPayment,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputNumeroOperacion, setInputNumeroOperacion] = useState({
    auth: false,
    validate: true,
    required: true,
    disabled: false,
    error: false,
    type: 'number',
    label: 'Número de operación',
    id: 'numero_operacion',
    name: 'numero_operacion',
    change_param: 'informar_pago',
    regex: Regex.NUMERO_ENTERO,
    value: '',
    default_value: '',
    placeholder: 'XXXXXXXXXXX',
    messages: {
      error: 'Por favor, ingrese un número de operación de Mercado Pago válido',
      help: 'Es el número de operación de Mercado Pago de 11 dígitos que figura en Mercado Pago (no es la referencia externa).',
      error_extra: RegexExtra.NUMERO_ENTERO,
    },
  });

  const handleChange = (e, blur) => {
    const value = e.target.value;

    const newInputNumeroOperacion = {
      ...inputNumeroOperacion,
      value: value,
    };
    Validate.validate_input(newInputNumeroOperacion, blur, (input) => {
      setInputNumeroOperacion(input);
    });
  };

  const handleSubmitUpdateIdPayment = (e) => {
    e.preventDefault();

    if (
      Validate.validar_formulario({ numero_operacion: inputNumeroOperacion })
    ) {
      setLoading(true);
      setIdPayment(
        {
          idFacturas: billId,
          numero_operacion: inputNumeroOperacion.value,
        },
        (err, resp) => {
          setLoading(false);
          handleSubmit({
            err,
            billId: billId,
            alert: {
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          });
        }
      );
    }
  };

  return (
    <ModalUI
      open={open}
      id="informar_pago"
      title="Informar pago"
      button_label="Informar"
      button_loading={loading}
      modalHandler={modalHandler}
      handleSubmit={handleSubmitUpdateIdPayment}
      aditional_param="informar_pago"
      subtitle="Solo debes informar un pago si abonaste y el pago no se ve reflejado"
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={inputNumeroOperacion}
            adornment="#"
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIdPayment: (data, callback) =>
      dispatch(tiendaInformarPago(data, callback)),
  };
};

export const ModalSetIdPayment = connect(
  null,
  mapDispatchToProps
)(ModalSetIdPayment_);
