import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Icon } from '@mui/material';
import {
  ModalUI,
  InputUI,
  ModuleCardTitleUI,
} from '../../../../../components/common';

const ModalActualizarOrdenMercadoPago = (props) => {
  let {
    actualizacion_manual_mp,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="actualizacion_manual_mp"
      title="Actualización manual de pago"
      modalHandler={modalHandler}
      aditional_param="actualizacion_manual_mp"
      handleSubmit={handleSubmit}
      button_label="Actualizar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleCardTitleUI
            title="Sincronización de pagos con Mercado Pago"
            subtitles={[
              <span style={{ display: 'block', marginTop: '5px' }}>
                <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> En
                Empretienda, los estados de pago de tus ordenes (Pendientes,
                Finalizados, En proceso, En mediación, Rechazados, Cancelados,
                Pago devuelto y En contracargo) se actualizan automáticamente
                con los de tu cuenta de Mercado Pago por lo que no es
                obligatorio el uso de esta herramienta.
              </span>,
              <span style={{ display: 'block', marginTop: '5px' }}>
                <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> Suele
                suceder muy pocas veces que cuando la demanda en Mercado Pago es
                alta, las actualizaciones de los estados de pago pueden demorar
                más de lo habitual en actualizarse y quizas veas que el estado
                del pago en Mercado Pago es distinto al de Empretienda. Para
                acelerar el proceso para esta orden, puedes utilizar esta
                función para realizar una actualización manual.
              </span>,
              <span style={{ display: 'block', marginTop: '5px' }}>
                <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> Debes
                colocar el número de operación de Mercado Pago correspondiente
                al número de orden de esta venta, de lo contrario, no se va a
                poder realizar la actualización.
              </span>,
              <span style={{ display: 'block', marginTop: '5px' }}>
                <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> Si
                tenés dudas sobre el funcionamiento de esta herramienta, puedes
                consultarnos desde{' '}
                <u>
                  <Link to="/soporte" style={{ color: 'inherit' }}>
                    aquí
                  </Link>
                </u>
                .
              </span>,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            adornment="#"
            input={actualizacion_manual_mp.amao_numero_operacion}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalActualizarOrdenMercadoPago };
