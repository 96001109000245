import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import './libs/css/style.css';
// Provider
import AmplitudeProvider from './components/amplitude/AmplitudeProvider';
import {
  AllRoute,
  PrivateRoute,
  PublicRoute,
  ThemeUI,
} from './components/common';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// HOC
import { withUseAmplitudeHOC } from './components/amplitude';

// Components TODO: migrar a pages
import {
  Promo,
  Blog,
  BlogAgregar,
  BlogEditar,
  Paginas,
  PaginaAgregar,
  PaginaEditar,
  VentaMayorista,
  Dominios,
  Clientes,
  GoogleAnalytics,
  ProductoEditar,
  ProductoAgregar,
  Productos,
  MassivePricesIncrease,
  Cupones,
  CuponesAgregar,
  CuponesEditar,
  AgregarOferta,
  BorrarOferta,
  Plan,
  Baja,
  PinterestTag,
  Ofertas,
  ProductosImportar,
  ImportacionEstadoTrack,
  PlanMetodoPago,
  Applications,
} from './components';
import { Suscripcion } from './components/plan/metodo-pago/suscripcion';
import ApplicationDetail from './components/aplicaciones/ApplicationDetail';
import { LoginStep, SignUpStep } from './components/auth0';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//pages
import HomePage from './pages/home';
import ChatPage from './pages/chat';
import ForumPage from './pages/foro';
import MetaAds from './pages/meta-ads';
import SupportPage from './pages/soporte';
import MetricasPage from './pages/metricas';
import HtmlTags from './pages/etiquetas-html';
import CategoriesPage from './pages/categorias';
import EmpreshopsPage from './pages/empreshops';
import OcaPage from './pages/metodos-envio/oca';
import FiscalDataPage from './pages/data-fiscal';
import MetodosPagoPage from './pages/metodos-pago';
import FacebookPixel from './pages/facebook-pixel';
import EpickPage from './pages/metodos-envio/epick';
import LocalPage from './pages/metodos-envio/local';
import MetodosEnvioPage from './pages/metodos-envio';
import GoogleShopping from './pages/google-shopping';
import SeoDescripcion from './pages/seo-descripcion';
import SocialMediaPage from './pages/redes-sociales';
import SalesListPage from './pages/listado-de-ventas';
import EmailMarketingPage from './pages/email-marketing';
import CreateShop from './pages/formulario-crear-tienda';
import ContactFormPage from './pages/formulario-contacto';
import Reactivation from './components/plan/reactivacion';
import SelectTemplatePage from './pages/selecciona-plantilla';
import EmprepackPage from './pages/metodos-envio/emprepack';
import ShopInformationPage from './pages/informacion-tienda';
import OrderPage from './pages/listado-de-ventas/orden/[id]';
import DigitalProductsPage from './pages/productos-digitales';
import BillingInformationPage from './pages/datos-facturacion';
import TurnOffTemporarilyPage from './pages/apagar-temporalmente';
import StockNotificationsPage from './pages/notificaciones-stock';
import MercadoEnviosPage from './pages/metodos-envio/mercado-envios';
import CorreoArgentinoPage from './pages/metodos-envio/correo-argentino';
import EnviosPersonalizadosPage from './pages/metodos-envio/envios-personalizados';
import EnviosPersonalizadosEditarPage from './pages/metodos-envio/envios-personalizados/[idEnviosPersonalizados]';
import EnviosPersonalizadosAgregarPage from './pages/metodos-envio/envios-personalizados/agregar';
import BuilderPage from './pages/builder';
import { Config } from './other';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeUI>
        <BrowserRouter>
          <AmplitudeProvider>
            <Switch>
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute
                exact
                path="/informacion-tienda"
                component={withUseAmplitudeHOC(ShopInformationPage)}
              />
              <PrivateRoute
                exact
                path="/redes-sociales"
                component={withUseAmplitudeHOC(SocialMediaPage)}
              />
              <PrivateRoute
                exact
                path="/blog"
                component={withUseAmplitudeHOC(Blog)}
              />
              <PrivateRoute
                exact
                path="/blog/agregar"
                component={withUseAmplitudeHOC(BlogAgregar)}
              />
              <PrivateRoute
                exact
                path="/blog/:idBlog"
                component={withUseAmplitudeHOC(BlogEditar)}
              />
              <PrivateRoute
                exact
                path="/paginas"
                component={withUseAmplitudeHOC(Paginas)}
              />
              <PrivateRoute
                exact
                path="/paginas/agregar"
                component={withUseAmplitudeHOC(PaginaAgregar)}
              />
              <PrivateRoute
                exact
                path="/paginas/:idPaginas"
                component={withUseAmplitudeHOC(PaginaEditar)}
              />
              <PrivateRoute
                exact
                path="/chat"
                component={withUseAmplitudeHOC(ChatPage)}
              />
              <PrivateRoute
                exact
                path="/data-fiscal"
                component={withUseAmplitudeHOC(FiscalDataPage)}
              />
              <PrivateRoute
                exact
                path="/formulario-contacto"
                component={withUseAmplitudeHOC(ContactFormPage)}
              />
              <PrivateRoute
                exact
                path="/apagar-temporalmente"
                component={withUseAmplitudeHOC(TurnOffTemporarilyPage)}
              />
              <PrivateRoute
                exact
                path="/venta-mayorista"
                component={withUseAmplitudeHOC(VentaMayorista)}
              />
              <PrivateRoute
                exact
                path="/productos-digitales"
                component={withUseAmplitudeHOC(DigitalProductsPage)}
              />
              <PrivateRoute
                exact
                path="/categorias"
                component={withUseAmplitudeHOC(CategoriesPage)}
              />
              <PrivateRoute exact path="/clientes" component={Clientes} />
              <PrivateRoute
                exact
                path="/meta-ads"
                component={withUseAmplitudeHOC(MetaAds)}
              />
              <PrivateRoute
                exact
                path="/google-shopping"
                component={withUseAmplitudeHOC(GoogleShopping)}
              />
              <PrivateRoute
                exact
                path="/facebook-pixel"
                component={withUseAmplitudeHOC(FacebookPixel)}
              />
              <PrivateRoute
                exact
                path="/pinterest-tag"
                component={withUseAmplitudeHOC(PinterestTag)}
              />
              <PrivateRoute
                exact
                path="/seo-descripcion"
                component={withUseAmplitudeHOC(SeoDescripcion)}
              />
              <PrivateRoute
                exact
                path="/email-marketing"
                component={withUseAmplitudeHOC(EmailMarketingPage)}
              />
              <PrivateRoute
                exact
                path="/dominios"
                component={withUseAmplitudeHOC(Dominios)}
              />
              <PrivateRoute
                exact
                path="/google-analytics"
                component={withUseAmplitudeHOC(GoogleAnalytics)}
              />
              <PrivateRoute
                exact
                path="/metricas"
                component={withUseAmplitudeHOC(MetricasPage)}
              />
              <PrivateRoute
                exact
                path="/foro"
                component={withUseAmplitudeHOC(ForumPage)}
              />
              <PrivateRoute
                exact
                path="/soporte"
                component={withUseAmplitudeHOC(SupportPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-pago"
                component={withUseAmplitudeHOC(MetodosPagoPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio"
                component={withUseAmplitudeHOC(MetodosEnvioPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/mercado-envios"
                component={withUseAmplitudeHOC(MercadoEnviosPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/oca"
                component={withUseAmplitudeHOC(OcaPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/correo-argentino"
                component={withUseAmplitudeHOC(CorreoArgentinoPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/epick"
                component={withUseAmplitudeHOC(EpickPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/emprepack"
                component={withUseAmplitudeHOC(EmprepackPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/locales"
                component={withUseAmplitudeHOC(LocalPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/envios-personalizados"
                component={withUseAmplitudeHOC(EnviosPersonalizadosPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/envios-personalizados/agregar"
                component={withUseAmplitudeHOC(EnviosPersonalizadosAgregarPage)}
              />
              <PrivateRoute
                exact
                path="/metodos-envio/envios-personalizados/:idEnviosPersonalizados"
                component={EnviosPersonalizadosEditarPage}
              />
              <PrivateRoute
                exact
                path="/productos"
                component={withUseAmplitudeHOC(Productos)}
              />
              <PrivateRoute
                exact
                path="/productos/agregar"
                component={withUseAmplitudeHOC(ProductoAgregar)}
              />
              <PrivateRoute
                exact
                path="/productos/agregar/:idProductos"
                component={withUseAmplitudeHOC(ProductoAgregar)}
              />
              <PrivateRoute
                exact
                path="/productos/aumento-masivo"
                component={withUseAmplitudeHOC(MassivePricesIncrease)}
              />
              <PrivateRoute
                exact
                path="/productos/importacion/:idTrack"
                component={ImportacionEstadoTrack}
              />
              <PrivateRoute
                exact
                path="/productos/importacion"
                component={ProductosImportar}
              />
              <PrivateRoute
                exact
                path="/productos/:idProductos"
                component={withUseAmplitudeHOC(ProductoEditar)}
              />
              <PrivateRoute
                exact
                path="/notificaciones-stock"
                component={withUseAmplitudeHOC(StockNotificationsPage)}
              />
              <PrivateRoute
                exact
                path="/cupones"
                component={withUseAmplitudeHOC(Cupones)}
              />
              <PrivateRoute
                exact
                path="/cupones/agregar"
                component={withUseAmplitudeHOC(CuponesAgregar)}
              />
              <PrivateRoute
                exact
                path="/cupones/:idCuponesDescuento"
                component={withUseAmplitudeHOC(CuponesEditar)}
              />
              <PrivateRoute
                exact
                path="/plan"
                component={withUseAmplitudeHOC(Plan)}
              />
              <PrivateRoute
                exact
                path="/plan/reactivacion"
                component={withUseAmplitudeHOC(Reactivation)}
              />
              <PrivateRoute
                exact
                path="/plan/metodo-pago"
                component={withUseAmplitudeHOC(PlanMetodoPago)}
              />
              <PrivateRoute
                exact
                path="/plan/datos-facturacion"
                component={withUseAmplitudeHOC(BillingInformationPage)}
              />
              <PrivateRoute
                exact
                path="/plan/metodo-pago/suscripcion"
                component={withUseAmplitudeHOC(Suscripcion)}
              />
              <Redirect from="/ventas" to="/listado-de-ventas" />
              <PrivateRoute
                exact
                path="/listado-de-ventas"
                component={SalesListPage}
              />
              <PrivateRoute
                exact
                path="/listado-de-ventas/orden/:id"
                component={withUseAmplitudeHOC(OrderPage)}
              />
              <PrivateRoute
                exact
                path="/baja"
                component={withUseAmplitudeHOC(Baja)}
              />
              <PrivateRoute
                exact
                path="/ofertas"
                component={withUseAmplitudeHOC(Ofertas)}
              />
              <PrivateRoute
                exact
                path="/ofertas/agregar"
                component={withUseAmplitudeHOC(AgregarOferta)}
              />
              <PrivateRoute
                exact
                path="/ofertas/eliminar"
                component={withUseAmplitudeHOC(BorrarOferta)}
              />
              <PrivateRoute
                exact
                path="/empreshops"
                component={withUseAmplitudeHOC(EmpreshopsPage)}
              />
              <PrivateRoute
                exact
                path="/etiquetas-html"
                component={withUseAmplitudeHOC(HtmlTags)}
              />
              <PrivateRoute
                exact
                path="/builder"
                component={withUseAmplitudeHOC(BuilderPage)}
              />
              <PrivateRoute
                exact
                path="/selecciona-plantilla"
                component={withUseAmplitudeHOC(SelectTemplatePage)}
              />
              <PrivateRoute
                exact
                path="/aplicaciones"
                component={withUseAmplitudeHOC(Applications)}
              />
              <PrivateRoute
                exact
                path="/aplicaciones/:appName"
                component={withUseAmplitudeHOC(ApplicationDetail)}
              />
              <PrivateRoute
                exact
                withoutShopId
                path="/formulario-crear-tienda"
                component={CreateShop}
              />
              <PublicRoute exact path="/crear-tienda" component={SignUpStep} />
              <PublicRoute
                exact
                path="/crear-tienda/partner/:ca_codigo"
                component={SignUpStep}
              />
              <PublicRoute exact path="/login" component={LoginStep} />
              <AllRoute exact path="/activate/:token/:code" component={Promo} />
            </Switch>
          </AmplitudeProvider>
        </BrowserRouter>
      </ThemeUI>
      <ReactQueryDevtools
        initialIsOpen={Config.REACT_APP_ENVIRONMENT === 'local'}
      />
    </QueryClientProvider>
  );
};

export default App;
