import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Splash } from './Splash';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Splash />;
  }

  if (!isAuthenticated) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
      />
    );
  }
};

export { PublicRoute };
