import React from 'react';
import { Grid } from '@mui/material';
import { InfoText } from '../InfoText';
import { InputUI, SelectUI, ToggleUI } from '../../../../components/common';
import { InputColorCircle } from '../InputColorCircle';
import { blockAlignmentOptions, blockSpacingOptions } from '../../jsonSelects';

export const TextElement = ({
  block,
  showAlign,
  handleChangeBlock,
  handleChangeSelectBlock,
}) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InfoText title="Título (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.title}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <ToggleUI show={showAlign}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.title_align}
                options={blockAlignmentOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Texto" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.text}
              handleChange={handleChangeBlock}
              multiline
            />
          </Grid>
          <ToggleUI show={showAlign}>
            <Grid item xs={12}>
              <SelectUI
                data={block.bl_data.text_align}
                options={blockAlignmentOptions}
                handleChangeSelect={handleChangeSelectBlock}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Botón (opcional)" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.button}
              handleChange={handleChangeBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.button_align}
              options={blockAlignmentOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              input={block.bl_data.button_link}
              handleChange={handleChangeBlock}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InfoText title="Adicionales" />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.text_color}
              adornment={
                <InputColorCircle color={block.bl_data.text_color.value} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChangeBlock}
              input={block.bl_data.background}
              adornment={
                <InputColorCircle color={block.bl_data.background.value} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={block.bl_data.section_size}
              options={blockSpacingOptions}
              handleChangeSelect={handleChangeSelectBlock}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
