export const getStepsContent = ({
  addProductsStepDone,
  designStepDone,
  paymentMethodStepDone,
  shipmentMethodStepDone,
}) => {
  if (addProductsStepDone && !designStepDone) {
    return {
      title: 'Hacé que tu tienda sea irresistible',
      subtitle:
        'Dale tu toque personal a tu tienda para que tus clientes te identifiquen rápidamente.',
    };
  }

  if (addProductsStepDone && designStepDone && !paymentMethodStepDone) {
    return {
      title: 'Estás a pasos de terminar',
      subtitle: 'Ofreceles opciones a tus clientes para que pagar sea fácil.',
    };
  }

  if (
    addProductsStepDone &&
    designStepDone &&
    paymentMethodStepDone &&
    !shipmentMethodStepDone
  ) {
    return {
      title: 'Falta poco para empezar a vender',
      subtitle:
        'Organizá tu tienda para que tus productos lleguen sin problemas.',
    };
  }

  return {
    title: 'Pasos para dejar lista tu tienda',
    subtitle:
      'Te dejamos una guía para que empieces a poner en orden tu tienda.',
  };
};
