import React from 'react';
import useAmplitudeEvent from './useAmplitudeEvent';
import { getEventNameByPathname } from './amplitude-events';

export const withUseAmplitudeHOC = (Component) => {
  return (props) => {
    const eventSend = useAmplitudeEvent(getEventNameByPathname());
    return <Component eventSend={eventSend} {...props} />;
  };
};
