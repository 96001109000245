import uuid from 'uuid/v1';
import { Regex, RegexExtra } from '../../other';

export const loadBlock = (
  bl_tipo,
  blocksLength = 0,
  fill = false,
  bloque = null
) => {
  const change_param = uuid().split('-')[0];

  const bloque_obj = {
    idBloques: {
      validate: false,
      value: fill ? (bloque.idBloques ? bloque.idBloques : 0) : 0,
    },
    bl_activo: {
      validate: false,
      value: fill ? bloque.bl_activo : 1,
    },
    bl_tipo: {
      validate: false,
      value: bl_tipo,
    },
    bl_nombre: {
      auth: true,
      validate: true,
      required: true,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Nombre de la sección',
      id: '',
      name: 'bl_nombre',
      change_param: change_param,
      regex: Regex.STRING_GENERICO_2_20,
      value: fill ? bloque.bl_nombre : 'Sección ' + (blocksLength + 1),
      default_value: '',
      placeholder: '',
      messages: {
        help: '',
        error: 'Por favor, ingrese un nombre de sección válido',
        error_extra: RegexExtra.STRING_GENERICO_2_20,
      },
    },
    bl_data: {},
  };

  switch (bl_tipo) {
    //NOMBRE: Carrusel de banners
    case 1:
      let banners_items = fill
        ? bloque.bl_data.items
        : [
            {
              link: 'block-preview-banner.jpg',
              url: '',
              title: '',
              button: '',
            },
          ];

      bloque_obj.bl_data = {
        width: {
          validate: false,
          required: true,
          error: false,
          label: 'Ancho',
          id: '',
          name: 'bldata_width',
          change_param: change_param,
          value: fill ? bloque.bl_data.width : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        autoplay: {
          validate: false,
          required: true,
          error: false,
          label: 'Autoplay',
          id: '',
          name: 'bldata_autoplay',
          change_param: change_param,
          value: fill ? bloque.bl_data.autoplay : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        seconds: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'number',
          label: 'Tiempo de cambio (segundos)',
          id: '',
          name: 'bldata_seconds',
          change_param: change_param,
          regex: Regex.NUMERO_ENTERO_POSITIVO,
          value: fill ? bloque.bl_data.seconds : '5',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
            error: 'Por favor, ingrese un tiempo válido',
          },
        },
        items: {
          validate: false,
          auth: banners_items.length ? true : false,
          uploading: false,
          required: true,
          label: '',
          id: 'bldata_items_' + change_param,
          sublabels: [''],
          files: banners_items,
          messages: {
            help: '',
            error: '',
          },
        },
      };

      break;

    //NOMBRE: Products feed [UNIQUE]
    case 2:
      bloque_obj.bl_data = {
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'Título de la sección',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill ? bloque.bl_data.text : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#ffffff',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        show_more_btn: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Botón',
          id: '',
          name: 'bldata_show_more_btn',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_45,
          value: fill ? bloque.bl_data.show_more_btn : 'Ver más',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_45,
            error: 'Por favor, ingrese un valor del botón válido',
          },
        },
      };
      break;

    //NOMBRE: Products set
    case 3:
      bloque_obj.bl_data = {
        type: {
          validate: false,
          required: true,
          error: false,
          label: 'Tipo de visualización',
          id: '',
          name: 'bldata_type',
          change_param: change_param,
          value: fill ? bloque.bl_data.type : 2,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill ? bloque.bl_data.text : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        per_row: {
          validate: false,
          required: true,
          error: false,
          label: 'Productos por fila',
          id: '',
          name: 'bldata_per_row',
          change_param: change_param,
          value: fill ? bloque.bl_data.per_row : 3,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        per_row_mobile: {
          validate: false,
          required: true,
          error: false,
          label: 'Productos por fila (celulares)',
          id: '',
          name: 'bldata_per_row_mobile',
          change_param: change_param,
          value: fill ? bloque.bl_data.per_row_mobile : 2,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#ffffff',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        products: fill ? bloque.bl_data.products : [],
      };
      break;

    //NOMBRE: Lista de categorías
    case 4:
      bloque_obj.bl_data = {
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'PRODUCTOS',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        type: {
          validate: false,
          required: true,
          error: false,
          label: 'Mostrar',
          id: '',
          name: 'bldata_type',
          change_param: change_param,
          value: fill ? bloque.bl_data.type : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        mode: {
          validate: false,
          required: true,
          error: false,
          label: 'Modo de visualización',
          id: '',
          name: 'bldata_mode',
          change_param: change_param,
          value: fill ? bloque.bl_data.mode : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
      };
      break;

    //NOMBRE: Galería
    case 5:
      let galeria_items = fill
        ? bloque.bl_data.items
        : [
            {
              link: 'block-preview-empty.jpeg',
              url: '',
              title: '',
              button: '',
            },
            {
              link: 'block-preview-empty.jpeg',
              url: '',
              title: '',
              button: '',
            },
            {
              link: 'block-preview-empty.jpeg',
              url: '',
              title: '',
              button: '',
            },
          ];

      bloque_obj.bl_data = {
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'Título de la galería',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill
            ? bloque.bl_data.text
            : 'Acá podés escribir el texto que quieras, por ejemplo, relacionado a la galería.',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        items: {
          validate: false,
          auth: galeria_items.length ? true : false,
          uploading: false,
          required: true,
          label: '',
          id: 'bldata_items_' + change_param,
          sublabels: [''],
          files: galeria_items,
          messages: {
            help: '',
            error: '',
          },
        },
        gap: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de fotos',
          id: '',
          name: 'bldata_gap',
          change_param: change_param,
          value: fill ? bloque.bl_data.gap : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        per_row: {
          validate: false,
          required: true,
          error: false,
          label: 'Fotos por fila',
          id: '',
          name: 'bldata_per_row',
          change_param: change_param,
          value: fill ? bloque.bl_data.per_row : 3,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        per_row_mobile: {
          validate: false,
          required: true,
          error: false,
          label: 'Fotos por fila (celulares)',
          id: '',
          name: 'bldata_per_row_mobile',
          change_param: change_param,
          value: fill ? bloque.bl_data.per_row_mobile : 2,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        masonry: {
          validate: false,
          required: true,
          error: false,
          label: 'Efecto Masonry',
          id: '',
          name: 'bldata_masonry',
          change_param: change_param,
          value: fill ? bloque.bl_data.masonry : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#ffffff',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
      };
      break;

    //NOMBRE: Imagenes con link
    case 6:
      let imagenes_items = fill
        ? bloque.bl_data.items
        : [
            {
              link: 'block-preview-bag.jpeg',
              url: '',
              title: 'CARTERAS',
              button: '',
            },
            {
              link: 'block-preview-dress.jpeg',
              url: '',
              title: 'VESTIDOS',
              button: '',
            },
            {
              link: 'block-preview-shirt.jpeg',
              url: '',
              title: 'CAMISAS',
              button: '',
            },
          ];

      bloque_obj.bl_data = {
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'Título de sección',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill
            ? bloque.bl_data.text
            : 'Acá podés escribir el texto que quieras, por ejemplo, relacionado a la sección.',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        items: {
          validate: false,
          auth: imagenes_items.length ? true : false,
          uploading: false,
          required: true,
          label: '',
          id: 'bldata_items_' + change_param,
          sublabels: [''],
          files: imagenes_items,
          messages: {
            help: '',
            error: '',
          },
        },
        gap: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de fotos',
          id: '',
          name: 'bldata_gap',
          change_param: change_param,
          value: fill ? bloque.bl_data.gap : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        per_row: {
          validate: false,
          required: true,
          error: false,
          label: 'Fotos por fila',
          id: '',
          name: 'bldata_per_row',
          change_param: change_param,
          value: fill ? bloque.bl_data.per_row : 3,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        per_row_mobile: {
          validate: false,
          required: true,
          error: false,
          label: 'Fotos por fila (celulares)',
          id: '',
          name: 'bldata_per_row_mobile',
          change_param: change_param,
          value: fill ? bloque.bl_data.per_row_mobile : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        type: {
          validate: false,
          required: true,
          error: false,
          label: 'Visualización',
          id: '',
          name: 'bldata_type',
          change_param: change_param,
          value: fill ? bloque.bl_data.type : 2,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#ffffff',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
      };
      break;

    //NOMBRE: Texto
    case 7:
      bloque_obj.bl_data = {
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'Título',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill
            ? bloque.bl_data.text
            : 'Acá podés escribir el texto que quieras.',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        button: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto del botón',
          id: '',
          name: 'bldata_button',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_20,
          value: fill ? bloque.bl_data.button : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_20,
            error: 'Por favor, ingrese un texto de botón válido',
          },
        },
        button_link: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Link del botón',
          id: '',
          name: 'bldata_button_link',
          change_param: change_param,
          regex: Regex.LINK,
          value: fill ? bloque.bl_data.button_link : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.LINK,
            error: 'Por favor, ingrese un link válido',
          },
        },
        button_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del botón',
          id: '',
          name: 'bldata_button_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.button_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text_color: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de textos',
          id: '',
          name: 'bldata_text_color',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.text_color : '#000000',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#f5f5f5',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
      };
      break;

    //NOMBRE: Informacion de compra
    case 8:
      bloque_obj.bl_data = {
        title_1: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title_1',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title_1 : 'ENVÍOS A TODO EL PAÍS',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        text_1: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text_1',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_200,
          value: fill ? bloque.bl_data.text_1 : 'COMPRÁ SIN SALIR DE TU CASA',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_200,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        icon_1: {
          validate: false,
          required: true,
          error: false,
          label: 'Icono',
          id: '',
          name: 'bldata_icon_1',
          change_param: change_param,
          value: fill ? bloque.bl_data.icon_1 : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        title_2: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title_2',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title_2 : 'HASTA 12 CUOTAS',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        text_2: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text_2',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_200,
          value: fill ? bloque.bl_data.text_2 : 'CON TODAS LAS TARJETAS',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_200,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        icon_2: {
          validate: false,
          required: true,
          error: false,
          label: 'Icono',
          id: '',
          name: 'bldata_icon_2',
          change_param: change_param,
          value: fill ? bloque.bl_data.icon_2 : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        title_3: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title_3',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title_3 : 'COMPRA SEGURA',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        text_3: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text_3',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_200,
          value: fill ? bloque.bl_data.text_3 : 'PROTEGEMOS TUS DATOS',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_200,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        icon_3: {
          validate: false,
          required: true,
          error: false,
          label: 'Icono',
          id: '',
          name: 'bldata_icon_3',
          change_param: change_param,
          value: fill ? bloque.bl_data.icon_3 : 2,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text_color: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de textos',
          id: '',
          name: 'bldata_text_color',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.text_color : '#000000',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#f5f5f5',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
      };
      break;

    //NOMBRE: Imagen con texto y botón
    case 9:
      let image = fill
        ? [bloque.bl_data.image]
        : [
            {
              link: 'block-preview-bag.jpeg',
              url: '',
              title: '',
              button: '',
            },
          ];

      bloque_obj.bl_data = {
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'Título de sección',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill
            ? bloque.bl_data.text
            : 'Acá podés escribir el texto que quieras, por ejemplo, relacionado a la sección.',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        button: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto del botón',
          id: '',
          name: 'bldata_button',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_20,
          value: fill ? bloque.bl_data.button : 'BOTÓN',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_20,
            error: 'Por favor, ingrese un texto de botón válido',
          },
        },
        button_link: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Link del botón',
          id: '',
          name: 'bldata_button_link',
          change_param: change_param,
          regex: Regex.LINK,
          value: fill ? bloque.bl_data.button_link : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.LINK,
            error: 'Por favor, ingrese un link válido',
          },
        },
        button_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del botón',
          id: '',
          name: 'bldata_button_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.button_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        image: {
          validate: false,
          auth: true,
          uploading: false,
          required: true,
          label: '',
          id: 'bldata_image_' + change_param,
          sublabels: [''],
          files: image,
          messages: {
            help: '',
            error: '',
          },
        },
        image_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Tamaño de imagen',
          id: '',
          name: 'bldata_image_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.image_size : 2,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        image_position: {
          validate: false,
          required: true,
          error: false,
          label: 'Posición de la imagen',
          id: '',
          name: 'bldata_image_position',
          change_param: change_param,
          value: fill ? bloque.bl_data.image_position : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#ffffff',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
      };
      break;

    //NOMBRE: Codigo HTML
    case 10:
      let default_html = `<section class="uk-section-block">
    <h1 class="uk-text-center">Hola mundo!</h1>
</section>`;

      bloque_obj.bl_data = {
        code: {
          validate: false,
          name: 'bldata_code',
          id: '',
          change_param: change_param,
          value: fill ? bloque.bl_data.code : default_html,
          placeholder: 'Escriba o pegue su código HTML aquí',
        },
      };
      break;

    //NOMBRE: Grillas de imágenes con link
    case 11:
      let grid_image_items = fill
        ? bloque.bl_data.items
        : [
            {
              link: 'block-preview-empty.jpeg',
              url: '',
              title: '',
              button: '',
            },
            {
              link: 'block-preview-empty.jpeg',
              url: '',
              title: '',
              button: '',
            },
          ];

      bloque_obj.bl_data = {
        type: {
          validate: false,
          change_param: change_param,
          value: fill ? bloque.bl_data.type : 0,
        },
        title: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Título',
          id: '',
          name: 'bldata_title',
          change_param: change_param,
          regex: Regex.STRING_GENERICO_2_90,
          value: fill ? bloque.bl_data.title : 'Título de la sección',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.STRING_GENERICO_2_90,
            error: 'Por favor, ingrese un título válido',
          },
        },
        title_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del título',
          id: '',
          name: 'bldata_title_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.title_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        text: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Texto',
          id: '',
          name: 'bldata_text',
          change_param: change_param,
          regex: Regex.DESCRIPCION_20_5000,
          value: fill ? bloque.bl_data.text : '',
          default_value: '',
          placeholder: '',
          messages: {
            error_extra: RegexExtra.DESCRIPCION_20_5000,
            error: 'Por favor, ingrese un texto válido',
          },
        },
        text_align: {
          validate: false,
          required: true,
          error: false,
          label: 'Alineación del texto',
          id: '',
          name: 'bldata_text_align',
          change_param: change_param,
          value: fill ? bloque.bl_data.text_align : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        background: {
          auth: true,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Color de fondo',
          id: '',
          name: 'bldata_background',
          change_param: change_param,
          regex: Regex.COLOR,
          value: fill ? bloque.bl_data.background : '#ffffff',
          default_value: '',
          placeholder: '#000000',
          messages: {
            error_extra: RegexExtra.COLOR,
            error: '',
          },
        },
        items: {
          validate: false,
          auth: grid_image_items.length ? true : false,
          uploading: false,
          required: true,
          label: '',
          id: 'bldata_items_' + change_param,
          sublabels: [''],
          files: grid_image_items,
          messages: {
            help: '',
            error: '',
          },
        },
        gap: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de fotos',
          id: '',
          name: 'bldata_gap',
          change_param: change_param,
          value: fill ? bloque.bl_data.gap : 1,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
        section_size: {
          validate: false,
          required: true,
          error: false,
          label: 'Espaciado de bloque',
          id: '',
          name: 'bldata_section_size',
          change_param: change_param,
          value: fill ? bloque.bl_data.section_size : 0,
          messages: {
            help: '',
            error: 'Por favor, elija una opción',
          },
        },
      };
      break;

    default:
      break;
  }

  return bloque_obj;
};
