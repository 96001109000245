import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from './authActions';
import { getAppsActives } from '../../__mocks__/applications';

const aplicacionesActivasObtener = (callback) => {
  if (localStorage.getItem('mock-apps')) {
    const error = false;
    const resp = getAppsActives();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/tienda/aplicaciones-activas')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const aplicacionesPermisosObtener = (callback) => {
  return (dispatch, _getState) => {
    const apiUrl = `${Config.CDN_APP_PERMISSIONS}/applications/scope-titles.json`;

    axios
      .get(apiUrl, { withCredentials: false })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const aplicacionBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(
        `${Config.BFF_ENDPOINT}/1/shops/applications/${data.applicationId}`,
        { withCredentials: false }
      )
      .then((resp) => {
        //guardo en redux

        const app = resp.data.data;
        dispatch({ type: 'APP_BORRAR', data: app });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const aplicacionesObtener = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(`${Config.BFF_ENDPOINT}/1/applications`, {
        withCredentials: false,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const aplicacionObtenerLink = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/tienda/aplicacion/activar', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  aplicacionesObtener,
  aplicacionObtenerLink,
  aplicacionesActivasObtener,
  aplicacionBorrar,
  aplicacionesPermisosObtener,
};
