import React from 'react';
import { Box } from '@mui/material';

export const InputColorCircle = ({ color }) => {
  return (
    <Box
      sx={{
        borderRadius: '50px',
        width: '25px',
        height: '25px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: color,
      }}
    />
  );
};
