/* eslint-disable default-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  tiendaObtener,
  categoriasObtenerFlattenJerarquico,
  productoObtenerProductos,
  productoBorrar,
  productoDesactivarActivar,
  productoEditarDetalleStock,
} from '../../store/actions';
import {
  Grid,
  Icon,
  Box,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AlertUI, InputUI, ToggleUI } from '../common';

import { Validate, Regex, RegexExtra } from '../../other';

import {
  ProductosList,
  ModalBorrar,
  ModalDesactivarActivar,
  FilterSidenav,
  DialogCompartir,
} from './subcomponents';

import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
  amplitude_events,
} from '../amplitude';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import ButtonMui from '../common/ButtonMui';
class Productos_ extends Component {
  state = {
    filter: {
      idCategorias: {
        validate: false,
        required: true,
        error: false,
        label: 'Categoría',
        id: 'idCategorias',
        name: 'idCategorias',
        change_param: 'filter',
        value: 0,
        messages: {
          error: '',
        },
      },
      p_tipo_venta: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de venta',
        id: 'p_tipo_venta',
        name: 'p_tipo_venta',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      p_producto_digital: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de producto',
        id: 'p_producto_digital',
        name: 'p_producto_digital',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      p_oferta: {
        validate: false,
        required: true,
        error: false,
        label: 'En oferta',
        id: 'p_oferta',
        name: 'p_oferta',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      dimensiones_configuradas: {
        validate: false,
        required: true,
        error: false,
        label: 'Dimensiones configuradas',
        id: 'dimensiones_configuradas',
        name: 'dimensiones_configuradas',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      estado_stock: {
        validate: false,
        required: true,
        error: false,
        label: 'Estado de stock',
        id: 'estado_stock',
        name: 'estado_stock',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      orden: {
        validate: false,
        required: true,
        error: false,
        label: 'Ordenar',
        id: 'orden',
        name: 'orden',
        change_param: 'filter',
        value: 0,
        messages: {
          error: '',
        },
      },
      incluir_descripcion: {
        validate: false,
        required: true,
        error: false,
        label: 'Incluir descripción',
        id: 'incluir_descripcion',
        name: 'incluir_descripcion',
        change_param: 'filter',
        value: 0,
        messages: {
          error: '',
        },
      },
      search: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Buscar por nombre',
        id: 'search',
        name: 'search',
        change_param: 'filter',
        regex: Regex.NOMBRE_PRODUCTO,
        value: '',
        default_value: '',
        placeholder: 'Nombre del producto',
        messages: {
          error: '',
          error_extra: RegexExtra.NOMBRE_PRODUCTO,
        },
      },
      search_codigo: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Buscar por SKU',
        id: 'search_codigo',
        name: 'search_codigo',
        change_param: 'filter',
        regex: Regex.SKU,
        value: '',
        default_value: '',
        placeholder: 'Código de producto',
        messages: {
          error: '',
          error_extra: RegexExtra.SKU,
        },
      },
      page: 0,
    },

    borrar: {
      p_nombre: '',
      idProductos: null,
    },

    desactivar: {
      p_nombre: '',
      p_desactivado: null,
      idProductos: null,
    },

    compartir: {
      p_nombre: '',
      p_link: '',
      c_link_full: '',
    },

    load_more: {
      loading: false,
      show: false,
    },

    selects: {
      categorias: [
        {
          idCategorias: 0,
          c_nombre: 'Todas las categorías',
          c_prefix: 'Todas las categorías',
          c_link_full: '',
          c_link: '',
          c_nombre_full: 'Todas las categorías',
          c_nombre_slash: 'Todas las categorías',
          c_padre: null,
          padres: [],
          hijos: [],
        },
      ],
      p_tipo_venta: [
        {
          value: -1,
          label: 'Todos los tipos',
        },
        {
          value: 0,
          label: 'Solo minorista',
        },
        {
          value: 1,
          label: 'Solo mayorista',
        },
        {
          value: 2,
          label: 'Mayorista y minorista',
        },
      ],
      p_producto_digital: [
        {
          value: -1,
          label: 'Todos los tipos',
        },
        {
          value: 0,
          label: 'Producto físico',
        },
        {
          value: 1,
          label: 'Producto digital (descarga automática)',
        },
        {
          value: 2,
          label: 'Producto digital (envío manual)',
        },
      ],
      p_oferta: [
        {
          value: -1,
          label: 'No filtrar',
        },
        {
          value: 0,
          label: 'Sin oferta',
        },
        {
          value: 1,
          label: 'Con oferta',
        },
        {
          value: 2,
          label: 'Oferta con fecha de finalización',
        },
        {
          value: 3,
          label: 'Oferta con fecha de inicio y finalización',
        },
      ],
      dimensiones_configuradas: [
        {
          value: -1,
          label: 'Todos',
        },
        {
          value: 0,
          label: 'Dimensiones sin configurar',
        },
        {
          value: 1,
          label: 'Dimensiones configuradas',
        },
      ],
      estado_stock: [
        {
          value: -1,
          label: 'Todos',
        },
        {
          value: 0,
          label: 'Sin stock',
        },
        {
          value: 1,
          label: 'Con stock',
        },
        {
          value: 2,
          label: 'Con stock ilimitado',
        },
      ],
      orden: [
        {
          value: 0,
          label: 'Más nuevo a más viejo',
        },
        {
          value: 1,
          label: 'Más viejo a más nuevo',
        },
        {
          value: 2,
          label: 'Menor precio',
        },
        {
          value: 3,
          label: 'Mayor precio',
        },
        {
          value: 4,
          label: 'Alfabéticamente',
        },
      ],
      incluir_descripcion: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
    },

    //carga de datos
    loading: {
      init_productos: true,
      productos: true,
      categorias: true,
      tienda: true,
      error: false,
      exportar: false,
    },

    //modales
    modals: {
      borrar: false,
      desactivar: false,
    },

    //dialogs
    dialogs: {
      compartir: false,
    },

    detalle_stock: {
      idProductos: null, //producto que posee el detalle abierto
      p_atributos: 0, //el producto tiene o no atributos
      idStock: null, //stock que se desea editar
      editar: {}, //campos de edicion
    },

    //forms
    forms: {
      borrar: false,
      desactivar: false,
      detalle_stock: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },

    //sidenav
    filters_sidenav: false,

    //Tipo de sidenav según el botón
    sidenav_type: 'filtrar',
  };

  debounceSearch = null;

  obtenerParametros = () => {
    let filter = this.state.filter;

    let p_tipo_venta = filter.p_tipo_venta.value;
    if (this.state.sidenav_type === 'exportar') {
      p_tipo_venta = this.props.tienda.t_mayorista === 0 ? 0 : p_tipo_venta;
    }

    return {
      idCategorias: filter.idCategorias.value,
      p_tipo_venta: p_tipo_venta,
      p_producto_digital: filter.p_producto_digital.value,
      p_oferta: filter.p_oferta.value,
      dimensiones_configuradas: filter.dimensiones_configuradas.value,
      estado_stock: filter.estado_stock.value,
      orden: filter.orden.value,
      page: filter.page,
      search: filter.search.value,
      search_codigo: filter.search_codigo.value,
    };
  };

  componentDidMount() {
    this.debounceMethod = debounce(this.cargarProductos, 500);

    this.props.tiendaObtener((err, resp) => {
      this.setState((prevState) => ({
        loading: {
          ...prevState.loading,
          tienda: false,
          error: err ? true : prevState.loading.error,
        },
      }));
    });

    this.props.categoriasObtenerFlattenJerarquico((err, resp) => {
      this.setState((prevState) => ({
        loading: {
          ...prevState.loading,
          error: err ? true : prevState.loading.error,
          categorias: false,
        },
        selects: {
          ...prevState.selects,
          categorias: [...prevState.selects.categorias, ...resp.data],
        },
      }));
    });

    this.cargarProductos();
  }

  cargarProductos = () => {
    this.setState((prevState) => ({
      loading: {
        ...prevState.loading,
        productos: true,
      },
      load_more: {
        loading: prevState.filter.page > 0 ? true : false,
        show: prevState.filter.page > 0 ? true : false,
      },
    }));

    this.props.productoObtenerProductos(
      this.obtenerParametros(),
      (err, resp) => {
        let cantidad_resultados = resp.data.length;

        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            init_productos: false,
            productos: false,
            error: prevState.loading.error || err ? true : false,
          },
          load_more: {
            loading: false,
            show: cantidad_resultados === 15,
          },
          filter: {
            ...prevState.filter,
            page: cantidad_resultados === 15 ? prevState.filter.page + 1 : 0,
          },
        }));
      }
    );
  };

  exportStart = () => {
    this.setState((prevState) => ({
      loading: {
        ...prevState.loading,
        exportar: true,
      },
    }));
  };

  exportFinish = (err, resp, message) => {
    if (err) {
      this.setState(
        (prevState) => ({
          loading: {
            ...prevState.loading,
            exportar: false,
            error: prevState.loading.error || resp ? true : false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message: resp.message,
            type: 'error',
          },
        }),
        () => {
          createAmplitudeEventWithDevice(
            import_amplitude_events.exportar_desde_admin_productos_failed
              .event_name,
            import_amplitude_events.exportar_desde_admin_productos_failed
              .event_params
          );
        }
      );
    } else {
      let state = {
        loading: {
          ...this.state.loading,
          exportar: false,
          error: this.state.loading.error || err ? true : false,
        },
      };

      if (message.show) {
        state = {
          ...state,
          alert: {
            ...this.state.alert,
            open: true,
            message: message.message,
            type: 'error',
          },
        };
      }

      this.setState(state, () => {
        createAmplitudeEventWithDevice(
          import_amplitude_events.exportar_desde_admin_productos_success
            .event_name,
          import_amplitude_events.exportar_desde_admin_productos_success
            .event_params
        );
      });
    }
  };

  handleChange = (e, blur, aditional) => {
    if (!blur) {
      let value = e.target.value;
      let name = e.target.name;

      this.setState(
        (prevState) => ({
          [aditional]: {
            ...prevState[aditional],
            [name]: {
              ...prevState[aditional][name],
              value: value,
            },
            page: 0,
          },
        }),
        () => this.debounceMethod()
      );
    }
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  //manejo el cambio de los inputs de stock
  handleChangeStockInput = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        detalle_stock: {
          ...prevState.detalle_stock,
          editar: {
            ...prevState.detalle_stock.editar,
            [name]: {
              ...prevState.detalle_stock.editar[name],
              value: value,
            },
          },
        },
      }),
      () => {
        Validate.validate_input(
          this.state.detalle_stock.editar[name],
          blur,
          (input) => {
            this.setState((prevState) => ({
              detalle_stock: {
                ...prevState.detalle_stock,
                editar: {
                  ...prevState.detalle_stock.editar,
                  [name]: input,
                },
              },
            }));
          }
        );
      }
    );
  };

  limpiarFiltros = () => {
    this.setState((prevState) => ({
      filter: {
        idCategorias: {
          ...prevState.filter.idCategorias,
          value: 0,
        },
        p_tipo_venta: {
          ...prevState.filter.p_tipo_venta,
          value: -1,
        },
        p_producto_digital: {
          ...prevState.filter.p_producto_digital,
          value: -1,
        },
        p_oferta: {
          ...prevState.filter.p_oferta,
          value: -1,
        },
        dimensiones_configuradas: {
          ...prevState.filter.dimensiones_configuradas,
          value: -1,
        },
        estado_stock: {
          ...prevState.filter.estado_stock,
          value: -1,
        },
        orden: {
          ...prevState.filter.orden,
          value: 0,
        },
        incluir_descripcion: {
          ...prevState.filter.incluir_descripcion,
          value: 0,
        },
        search: {
          ...prevState.filter.search,
          value: '',
        },
        search_codigo: {
          ...prevState.filter.search_codigo,
          value: '',
        },
        page: 0,
      },
    }));
  };

  handleSubmitFilter = (type) => {
    type === 'filtrar'
      ? this.setState(
          (prevState) => ({
            filter: {
              ...prevState.filter,
              page: 0,
            },
          }),
          () => this.cargarProductos()
        )
      : this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            exportar: true,
          },
        }));
  };

  //abre y cierra los modales
  modalHandler = (open, id, producto) => {
    switch (id) {
      case 'borrar':
        if (open) {
          this.setState((prevState) => ({
            borrar: {
              p_nombre: producto.p_nombre,
              idProductos: producto.idProductos,
            },
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        } else {
          this.setState((prevState) => ({
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        }
        break;
      case 'desactivar':
        if (open) {
          this.setState((prevState) => ({
            desactivar: {
              p_nombre: producto.p_nombre,
              p_desactivado: producto.p_desactivado,
              idProductos: producto.idProductos,
            },
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        } else {
          this.setState((prevState) => ({
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        }
        break;
      default:
        this.setState((prevState) => ({
          modals: {
            ...prevState.modals,
            [id]: open,
          },
        }));
        break;
    }
  };

  //abre y cierra los dialogs
  dialogHandler = (open, id, data) => {
    if (open) {
      this.setState((prevState) => ({
        compartir: {
          p_nombre: data.producto.p_nombre,
          p_link: data.producto.p_link,
          c_link_full: data.categoria_principal.c_link_full,
        },
        dialogs: {
          ...prevState.dialogs,
          [id]: open,
        },
      }));
    } else {
      this.setState((prevState) => ({
        dialogs: {
          ...prevState.dialogs,
          [id]: open,
        },
      }));
    }
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  //abrir y cerrar drawer
  handleDrawer = (open, type) => {
    this.setState({
      filters_sidenav: open,
      sidenav_type: type,
    });
  };

  //copia el link del producto
  handleCopyLink = (e, link) => {
    navigator.clipboard.writeText(link).then(
      () => {
        this.setState((prevState) => ({
          alert: {
            ...prevState.alert,
            message: 'El link ha sido copiado con éxito',
            type: 'success',
            open: true,
          },
          dialogs: {
            ...prevState.dialogs,
            compartir: false,
          },
        }));
      },
      (err) => {}
    );
  };

  //cargo los campos de edicion para el stock
  handleToggleEditarDetalleStock = (producto, item_stock) => {
    if (item_stock.idStock === this.state.detalle_stock.idStock) {
      this.setState({
        detalle_stock: {
          idProductos: null,
          p_atributos: 0,
          idStock: null,
          editar: {},
        },
      });
    } else {
      let enable_arr_stock, disable_arr_stock, disable_disable_arr_stock;
      let mostrar_precio = item_stock.s_mostrar_precio;
      let editar_inputs_atributos = {};

      if (producto.p_atributos) {
        //cargo los campos de atributos
        item_stock.valoratributo.forEach((atributo) => {
          let atributo_femenino =
            atributo.at_nombre[atributo.at_nombre.length - 1] === 'a'
              ? true
              : false;
          let error_msg =
            'Por favor, ingrese un' +
            (atributo_femenino ? 'a' : '') +
            ' ' +
            atributo.at_nombre.toLowerCase() +
            ' válid' +
            (atributo_femenino ? 'a' : 'o');
          let key = 'atributo_' + atributo.idAtributos;

          editar_inputs_atributos[key] = {
            auth: false,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: atributo.at_nombre,
            id: '',
            name: key,
            change_param: 'detalle_stock',
            regex: Regex.VALOR_ATRIBUTO,
            value: atributo.valor.vat_valor,
            default_value: '',
            placeholder: atributo.at_nombre,
            messages: {
              error: error_msg,
              error_extra: RegexExtra.VALOR_ATRIBUTO,
            },
          };
        });
      }

      const idCountry =
        this.props.tienda && this.props.tienda.Country_idCountry
          ? this.props.tienda.Country_idCountry
          : 'ARG';

      //debo generar los inputs
      let editar_inputs = {
        ...editar_inputs_atributos,
        s_precio: {
          auth: false,
          validate: true,
          required: true,
          disabled: false,
          error: false,
          type: 'number',
          label: 'Precio',
          id: '',
          name: 's_precio',
          change_param: 'detalle_stock',
          regex: Regex.PRECIO_POSITIVO[idCountry],
          value: item_stock.s_precio,
          default_value: '',
          placeholder: idCountry === 'COL' ? '3300' : '3300.99',
          messages: {
            error: 'Por favor, ingrese un precio válido',
            error_extra: RegexExtra.PRECIO_POSITIVO[idCountry],
          },
        },
        s_oferta: {
          validate: false,
          value: item_stock.s_oferta,
        },
        s_precio_oferta: {
          auth: false,
          validate: item_stock.s_oferta ? true : false,
          required: true,
          disabled: false,
          error: false,
          type: 'number',
          label: 'Precio de oferta',
          id: '',
          name: 's_precio_oferta',
          change_param: 'detalle_stock',
          regex: Regex.PRECIO_POSITIVO[idCountry],
          value: item_stock.s_oferta ? item_stock.s_precio_oferta : '',
          default_value: '',
          placeholder: idCountry === 'COL' ? '3300' : '3300.99',
          messages: {
            error: 'Por favor, ingrese un precio de oferta válido',
            error_extra: RegexExtra.PRECIO_POSITIVO[idCountry],
          },
        },
        s_precio_mayorista: {
          auth: false,
          validate: false,
          required: true,
          disabled: false,
          error: false,
          type: 'number',
          label: 'Precio mayorista',
          id: '',
          name: 's_precio_mayorista',
          change_param: 'detalle_stock',
          regex: Regex.PRECIO_POSITIVO[idCountry],
          value: item_stock.s_precio_mayorista,
          default_value: '',
          placeholder: idCountry === 'COL' ? '3300' : '3300.99',
          messages: {
            error: 'Por favor, ingrese un precio mayorista válido',
            error_extra: RegexExtra.PRECIO_POSITIVO[idCountry],
          },
        },
        s_cantidad_minima: {
          auth: false,
          validate: false,
          required: true,
          disabled: false,
          error: false,
          type: 'number',
          label: 'Cantidad mínima',
          id: '',
          name: 's_cantidad_minima',
          change_param: 'detalle_stock',
          regex: Regex.NUMERO_ENTERO_POSITIVO,
          value: item_stock.s_cantidad_minima,
          default_value: '',
          placeholder: '1',
          messages: {
            error: 'Por favor, ingrese una cantidad mayorista válida',
            error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
          },
        },
        s_cantidad: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'number',
          label: 'Stock',
          id: '',
          name: 's_cantidad',
          change_param: 'detalle_stock',
          regex: Regex.NUMERO_ENTERO,
          value: item_stock.s_ilimitado ? '' : item_stock.s_cantidad,
          default_value: '',
          placeholder: '∞',
          messages: {
            error: 'Por favor, ingrese una cantidad de stock válida',
            error_extra: RegexExtra.NUMERO_ENTERO,
          },
        },
        s_sku: {
          auth: true,
          validate: true,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'SKU',
          id: '',
          name: 's_sku',
          change_param: 'detalle_stock',
          regex: Regex.SKU,
          value: item_stock.s_sku,
          default_value: '',
          placeholder: '342131232',
          messages: {
            error: 'Por favor, ingrese un código de artículo válido',
            error_extra: RegexExtra.SKU,
          },
        },
        s_producto_digital_link: {
          auth: false,
          validate: false,
          required: true,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Link de descarga',
          id: '',
          name: 's_producto_digital_link',
          change_param: 'detalle_stock',
          regex: Regex.LINK,
          value: item_stock.s_producto_digital_link,
          default_value: '',
          placeholder: 'https://drive.google.com/linkdearchivo',
          messages: {
            error: 'Por favor, ingrese un link de descarga válido',
            error_extra: RegexExtra.LINK,
          },
        },
        s_producto_digital_plazo: {
          auth: true,
          validate: false,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Plazo de entrega',
          id: '',
          name: 's_producto_digital_plazo',
          change_param: 'detalle_stock',
          regex: Regex.STRING_GENERICO_2_45,
          value: item_stock.s_producto_digital_plazo,
          default_value: '',
          placeholder: '4 días',
          messages: {
            error: 'Por favor, ingrese un plazo de entrega válido',
            error_extra: RegexExtra.STRING_GENERICO_2_45,
          },
        },
        s_producto_digital_observacion: {
          auth: true,
          validate: false,
          required: false,
          disabled: false,
          error: false,
          type: 'text',
          label: 'Observación para el cliente',
          id: '',
          name: 's_producto_digital_observacion',
          change_param: 'detalle_stock',
          regex: Regex.DESCRIPCION_0_200,
          value: item_stock.s_producto_digital_observacion,
          default_value: '',
          placeholder: 'El link estará disponible por x días',
          messages: {
            error: 'Por favor, ingrese una observación válida',
            error_extra: RegexExtra.DESCRIPCION_0_200,
          },
        },
      };

      //p_tipo_venta
      switch (producto.p_tipo_venta) {
        //0 -> minorista
        case 0:
          enable_arr_stock = mostrar_precio ? ['s_precio'] : [];
          disable_arr_stock = ['s_precio_mayorista', 's_cantidad_minima'];
          disable_disable_arr_stock = mostrar_precio ? [] : ['s_precio'];
          break;
        //1 -> mayorista
        case 1:
          enable_arr_stock = ['s_precio_mayorista', 's_cantidad_minima'];
          disable_arr_stock = mostrar_precio ? ['s_precio'] : [];
          disable_disable_arr_stock = mostrar_precio ? [] : ['s_precio'];
          break;
        //2 -> minorista y mayorista
        case 2:
          enable_arr_stock = mostrar_precio
            ? ['s_precio_mayorista', 's_cantidad_minima', 's_precio']
            : ['s_precio_mayorista', 's_cantidad_minima'];
          disable_arr_stock = [];
          disable_disable_arr_stock = mostrar_precio ? [] : ['s_precio'];
          break;
      }
      editar_inputs = Validate.bulk_enable_disable_input(
        editar_inputs,
        enable_arr_stock,
        disable_arr_stock,
        disable_disable_arr_stock,
        true
      );
      //end p_tipo_venta

      //p_producto_digital
      switch (producto.p_producto_digital) {
        //0 -> Producto físico
        case 0:
          enable_arr_stock = [];
          disable_arr_stock = [
            's_producto_digital_link',
            's_producto_digital_plazo',
            's_producto_digital_observacion',
          ];
          disable_disable_arr_stock = [];
          break;
        //1 -> Producto digital (descarga automatica)
        case 1:
          enable_arr_stock = [
            's_producto_digital_link',
            's_producto_digital_observacion',
          ];
          disable_arr_stock = ['s_producto_digital_plazo'];
          disable_disable_arr_stock = [];
          break;
        //2 -> Producto digital (envio manual)
        case 2:
          enable_arr_stock = [
            's_producto_digital_plazo',
            's_producto_digital_observacion',
          ];
          disable_arr_stock = ['s_producto_digital_link'];
          disable_disable_arr_stock = [];
          break;
      }
      editar_inputs = Validate.bulk_enable_disable_input(
        editar_inputs,
        enable_arr_stock,
        disable_arr_stock,
        disable_disable_arr_stock,
        true
      );
      //end p_producto_digital

      //valido los datos
      editar_inputs = Validate.validar_formulario_actualizar_obj_sync(
        editar_inputs,
        true
      );

      this.setState({
        detalle_stock: {
          idProductos: producto.idProductos,
          p_atributos: producto.p_atributos,
          idStock: item_stock.idStock,
          editar: editar_inputs,
        },
      });
    }
  };

  //submits
  handleSubmitBorrar = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        borrar: true,
      },
    }));

    let form = this.state.borrar;

    this.props.productoBorrar(
      { idProductos: form.idProductos },
      (err, resp) => {
        if (err) {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              borrar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          }));
        } else {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              borrar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...prevState.modals,
              borrar: false,
            },
          }));
        }
      }
    );
  };

  handleSubmitDesactivarActivar = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        desactivar: true,
      },
    }));

    let form = this.state.desactivar;

    this.props.productoDesactivarActivar(
      { idProductos: form.idProductos },
      (err, resp) => {
        if (err) {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              desactivar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          }));
        } else {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              desactivar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...prevState.modals,
              desactivar: false,
            },
          }));
        }
      }
    );
  };

  handleSubmitEditarDetalleStock = (e, producto) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        detalle_stock: true,
      },
    }));

    let detalle_stock = this.state.detalle_stock;
    let form = detalle_stock.editar;

    if (Validate.validar_formulario(form)) {
      let s_precio = Validate.trim_float(form.s_precio.value);
      let s_precio_oferta = Validate.trim_float(form.s_precio_oferta.value);

      if (
        form.s_oferta.value === 0 ||
        (form.s_oferta.value > 0 && s_precio > s_precio_oferta)
      ) {
        let flag = true;

        //chequeo que no haya duplicados
        if (detalle_stock.p_atributos) {
          let arrDuplicados = [];
          producto.stock.forEach((stk) => {
            let strvat = '';
            if (stk.idStock === detalle_stock.idStock) {
              stk.valoratributo.forEach((vat) => {
                strvat +=
                  Validate.trim_uppercase(
                    detalle_stock.editar['atributo_' + vat.idAtributos].value
                  ) + '_';
              });
            } else {
              stk.valoratributo.forEach((vat) => {
                strvat += Validate.trim_uppercase(vat.valor.vat_valor) + '_';
              });
            }
            arrDuplicados = [...arrDuplicados, strvat];
          });
          let arrDuplicadosFiltered = Validate.remove_duplicates(arrDuplicados);
          flag = arrDuplicados.length === arrDuplicadosFiltered.length;
        }
        //end chequeo que no haya duplicados

        if (flag) {
          let body = {
            idStock: detalle_stock.idStock,
            idProductos: detalle_stock.idProductos,
            p_atributos: detalle_stock.p_atributos,
            atributos: [],
          };

          Object.keys(form).forEach((key) => {
            if (Validate.contiene_substring(key, 'atributo_')) {
              body.atributos = [
                ...body.atributos,
                {
                  key: Validate.trim_int(key.split('_')[1]),
                  value: form[key].value,
                },
              ];
            } else {
              body[key] = form[key].value;
            }
          });

          this.props.productoEditarDetalleStock(body, (err, resp) => {
            if (err) {
              this.setState((prevState) => ({
                forms: {
                  ...prevState.forms,
                  detalle_stock: false,
                },
                alert: {
                  open: true,
                  type: 'error',
                  message: resp.message,
                },
              }));
            } else {
              this.setState((prevState) => ({
                forms: {
                  ...prevState.forms,
                  detalle_stock: false,
                },
                alert: {
                  open: true,
                  type: 'success',
                  message: resp.message,
                },
                detalle_stock: {
                  idProductos: null,
                  p_atributos: 0,
                  idStock: null,
                  editar: {},
                },
              }));
            }
          });
        } else {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              detalle_stock: false,
            },
            alert: {
              open: true,
              type: 'error',
              message: 'Hay 2 o más variantes repetidas, por favor, revisalas',
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            detalle_stock: false,
          },
          alert: {
            open: true,
            type: 'error',
            message:
              'El precio de oferta debe ser menor al precio del producto',
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          detalle_stock: false,
        },
        alert: {
          open: true,
          type: 'error',
          message: 'Por favor, revisa tus campos, hay algunos errores',
        },
      }));
    }
  };

  render() {
    return (
      <WrapperLayout
        loading={
          this.state.loading.tienda ||
          this.state.loading.init_productos ||
          this.state.loading.categorias
        }
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalBorrar
          borrar={this.state.borrar}
          handleSubmit={this.handleSubmitBorrar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.borrar}
          form_state={this.state.forms.borrar}
        />
        <ModalDesactivarActivar
          desactivar={this.state.desactivar}
          handleSubmit={this.handleSubmitDesactivarActivar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.desactivar}
          form_state={this.state.forms.desactivar}
        />
        <FilterSidenav
          filter={this.state.filter}
          selects={this.state.selects}
          isFiltersDrawerOpen={this.state.filters_sidenav}
          handleDrawer={this.handleDrawer}
          handleChangeSelect={this.handleChangeSelect}
          limpiarFiltros={this.limpiarFiltros}
          handleSubmitFilter={this.handleSubmitFilter}
          type={this.state.sidenav_type}
          loading={this.state.loading}
          exportStart={this.exportStart}
          exportFinish={this.exportFinish}
          obtenerParametros={this.obtenerParametros}
        />
        <DialogCompartir
          compartir={this.state.compartir}
          dialog_state={this.state.dialogs.compartir}
          dialogHandler={this.dialogHandler}
          handleCopyLink={this.handleCopyLink}
        />
        <Grid container mb={{ md: 4, xs: 1 }}>
          <Grid item xs={12} md={4} mb={2}>
            <Typography as="h1" fontSize={{ xs: '1.4rem', lg: '1.7rem' }}>
              Administrar productos
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}
            display="flex"
            gap={{ xs: 2, md: 1 }}
          >
            <Button
              onClick={() => this.props.history.push('/productos/agregar')}
              variant="contained"
              sx={{ width: { xs: '100%', md: 'unset' }, maxHeight: '40px' }}
            >
              Agregar producto
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                this.props.history.push('/productos/importacion');
                createAmplitudeEventWithDevice(
                  amplitude_events.productos_importacion_masiva.event_name,
                  { source: 'administrar productos' }
                );
              }}
              sx={{
                marginBottom: { xs: 2, md: 'unset' },
                width: { xs: '100%', md: 'unset' },
                maxHeight: '40px',
              }}
            >
              Importar o exportar
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={{ md: 3, xs: 2 }} mb={3}>
          <Grid item lg={5} md={4.5} xs={12}>
            <InputUI
              handleChange={this.handleChange}
              input={this.state.filter.search}
              end_adornment={<Icon>search</Icon>}
            />
          </Grid>
          <Grid item lg={5} md={4.5} xs={12}>
            <InputUI
              handleChange={this.handleChange}
              input={this.state.filter.search_codigo}
              end_adornment={<Icon>search</Icon>}
            />
          </Grid>
          <Grid item lg={2} md={3} xs={12}>
            <Button
              onClick={(e) => this.handleDrawer(true, 'filtrar')}
              variant="outlined"
              color="info"
              sx={{ height: '56px', borderColor: 'rgba(0,0,0,0.23)' }}
              size="large"
              startIcon={<FilterListIcon />}
              fullWidth
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ margin: '20px 0px 30px 0px' }}>
          {this.state.filter.page === 0 && this.state.loading.productos ? (
            <LinearProgress />
          ) : (
            <ProductosList
              forms={this.state.forms}
              detalle_stock={this.state.detalle_stock}
              modalHandler={this.modalHandler}
              dialogHandler={this.dialogHandler}
              handleChangeStockInput={this.handleChangeStockInput}
              handleSubmitEditarDetalleStock={
                this.handleSubmitEditarDetalleStock
              }
              handleToggleEditarDetalleStock={
                this.handleToggleEditarDetalleStock
              }
            />
          )}
        </Box>
        <ToggleUI show={this.state.load_more.show}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ButtonMui
                disabled={this.state.load_more.loading}
                onClick={() => this.cargarProductos()}
                isLoading={this.state.load_more.loading}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Ver más
              </ButtonMui>
            </Grid>
          </Grid>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    productos: state.productos,
    beta: state.beta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoriasObtenerFlattenJerarquico: (callback) =>
      dispatch(categoriasObtenerFlattenJerarquico(callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    productoObtenerProductos: (data, callback) =>
      dispatch(productoObtenerProductos(data, callback)),
    productoBorrar: (data, callback) =>
      dispatch(productoBorrar(data, callback)),
    productoDesactivarActivar: (data, callback) =>
      dispatch(productoDesactivarActivar(data, callback)),
    productoEditarDetalleStock: (data, callback) =>
      dispatch(productoEditarDetalleStock(data, callback)),
  };
};

export const Productos = connect(
  mapStateToProps,
  mapDispatchToProps
)(Productos_);
