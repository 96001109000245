import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';
import categoriaDummy from '../../__mocks__/categoriaDummy';

//funcion para formateo de categorias
const categoriasFlattenJerarquico = (
  cat,
  prefix = '',
  padres = [],
  link_prefix = ''
) => {
  let categorias = [];
  let subcategorias = [];
  let new_prefix, new_link_prefix, slashes, c_nombre_slash, hijos;

  cat.forEach((categoria) => {
    new_prefix =
      prefix === '' ? categoria.c_nombre : prefix + ' > ' + categoria.c_nombre;

    new_link_prefix = link_prefix + '/' + categoria.c_link;

    subcategorias = categoriasFlattenJerarquico(
      categoria.subcategorias,
      new_prefix,
      [...padres, categoria.idCategorias],
      new_link_prefix
    );

    hijos = subcategorias.map((subcategoria) => subcategoria.idCategorias);

    if (padres.length) {
      slashes = '';
      padres.forEach((slash) => {
        slashes += '-';
      });
      c_nombre_slash = slashes + ' ' + categoria.c_nombre;
    } else {
      c_nombre_slash = categoria.c_nombre;
    }

    categorias = [
      ...categorias,
      {
        idCategorias: categoria.idCategorias,
        c_nombre: categoria.c_nombre,
        c_google_category_id: categoria.c_google_category_id,
        c_prefix: prefix ? prefix : '-',
        c_link_full: new_link_prefix,
        c_link: categoria.c_link,
        c_nombre_full: new_prefix,
        c_nombre_slash: c_nombre_slash,
        c_padre: categoria.c_padre,
        padres: padres,
        hijos: hijos,
      },
      ...subcategorias,
    ];
  });

  return categorias;
};
//end funcion para formateo de categorias

const categoriasObtener = (callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = categoriaDummy.categoriasObtener();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch, getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/categoria')
      .then((resp) => {
        //guardo en redux
        const categorias = resp.data.data;
        dispatch({
          type: Reducers.CATEGORIA_GUARDAR,
          data: categorias,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriasObtenerFlattenJerarquico = (callback) => {
  return (dispatch, getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/categoria')
      .then((resp) => {
        //guardo en redux
        const categorias = categoriasFlattenJerarquico(resp.data.data);
        dispatch({
          type: Reducers.CATEGORIA_GUARDAR,
          data: categorias,
        });

        let response = {
          ...resp.data,
          data: categorias,
        };

        return callback(false, response);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriasAgregar = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/categoria', data)
      .then((resp) => {
        //guardo en redux
        const categorias = resp.data.data;
        dispatch({
          type: Reducers.CATEGORIA_GUARDAR,
          data: categorias,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriasEditar = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/categoria/${data.idCategorias}`, data)
      .then((resp) => {
        //guardo en redux
        const categorias = resp.data.data;
        dispatch({
          type: Reducers.CATEGORIA_GUARDAR,
          data: categorias,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriasBorrar = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .delete(`${Config.BACKEND_ENDPOINT}/categoria/${data.idCategorias}`, data)
      .then((resp) => {
        //guardo en redux
        const categorias = resp.data.data;
        dispatch({
          type: Reducers.CATEGORIA_GUARDAR,
          data: categorias,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriasOrdenar = (data, callback) => {
  return (dispatch, getState) => {
    //guardo en redux
    dispatch({
      type: Reducers.CATEGORIA_GUARDAR,
      data: data.new_categorias,
    });

    let parent = data.parent > 0 ? data.parent : 0;
    let ids = data.nivel.map((categoria) => categoria.idCategorias);

    axios
      .put(Config.BACKEND_ENDPOINT + '/categoria/ordenar', {
        ids: ids,
        c_padre: parent,
      })
      .then((resp) => {
        return callback(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true);
      });
  };
};

const categoriaCrearOfertaMasiva = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/categoria/${data.idCategorias}/oferta`,
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriaBorrarOfertaMasiva = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .delete(
        `${Config.BACKEND_ENDPOINT}/categoria/${data.idCategorias}/oferta`,
        {
          data: data,
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const categoriasConfigurarGoogleCategoryId = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/categoria/${data.idCategorias}/google-category-id`,
        data
      )
      .then((resp) => {
        //guardo en redux
        const categoria = resp.data.data;
        dispatch({
          type: Reducers.CATEGORIA_UPDATE_GOOGLE_ID,
          data: categoria,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  categoriasObtener,
  categoriasObtenerFlattenJerarquico,
  categoriasAgregar,
  categoriasEditar,
  categoriasBorrar,
  categoriasOrdenar,
  categoriaCrearOfertaMasiva,
  categoriaBorrarOfertaMasiva,
  categoriasConfigurarGoogleCategoryId,
};
