import React from 'react';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const Input_ = ({
  classes,
  error = false,
  label = '',
  helperText = '',
  ...rest
}) => {
  return (
    <div className={classes.root}>
      <TextField
        id={'id'}
        classes={{ root: classes.textField }}
        variant="outlined"
        margin="dense"
        name={'name'}
        label={label}
        placeholder={'placeholder'}
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          className: classes.helper_text,
        }}
        {...rest}
      />
    </div>
  );
};

const styles = (_theme) => {
  const backgroundColor = 'rgba(0, 0, 0, 0)';

  return {
    tick: {
      color: '#9e9e9e',
      fontSize: '1.2rem',
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    helper_text: {
      marginLeft: 0,
    },
    textField: {
      flex: 1,
      backgroundColor: backgroundColor,
    },
    input: {
      backgroundColor: backgroundColor,
      '&:hover': {
        backgroundColor: backgroundColor,
      },
      '&:focus': {
        backgroundColor: backgroundColor,
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },
    },
    field_prefix: {
      left: '2px',
      display: 'flex',
      color: '#565656',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative',
      alignItems: 'center',
      height: '54px',
      margin: '8px 12px 4px 0px',
    },
  };
};

export const Input = withStyles(styles)(Input_);
