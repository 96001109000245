/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  dominioObtenerDominios,
  dominioSolicitar,
  dominioConfirmarDNS,
  dominioConfirmarCNAME,
  dominioABMRecord,
  dominioMigrar,
} from '../../store/actions';
import { AlertUI, ModuleTitleUI, ToggleUI } from '../common';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  DominiosList,
  DominioMetodoPago,
  DominioSetup,
  ModalRegistro,
  ModalMigrarDominio,
} from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class Dominios_ extends Component {
  state = {
    solicitar: {
      d_comprar: {
        validate: false,
        required: true,
        error: false,
        label: 'Ya registré mi dominio',
        id: 'd_comprar',
        name: 'd_comprar',
        change_param: 'solicitar',
        value: 0,
        messages: {
          error: 'Por favor, elija una opción',
          help: 'Si aún no compraste tu dominio, antes de continuar debes hacerlo ya que puede no estar disponible.',
        },
      },
      d_empresa: {
        validate: false,
        required: true,
        error: false,
        label: 'Tu proveedor de dominio',
        id: 'd_empresa',
        name: 'd_empresa',
        change_param: 'solicitar',
        value: 0,
        messages: {
          error: 'Por favor, elija una opción',
          help: 'Es la empresa donde hayas comprado tu dominio. Vamos a usar esto para mostrarte el tutorial de configuración adecuado.',
        },
      },
      d_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dominio',
        id: 'd_nombre',
        name: 'd_nombre',
        change_param: 'solicitar',
        regex: Regex.DOMINIO,
        value: '',
        default_value: '',
        placeholder: 'tumarca.com.ar',
        messages: {
          help: 'Debes ingresar el dominio sin www. Por ejemplo: tumarca.com en lugar de www.tumarca.com (te activaremos ambos).',
          error:
            'Por favor, ingrese un dominio válido [sin www][sin acentos, símbolos o letra ñ][sin punto final]',
          error_extra: RegexExtra.DOMINIO,
        },
      },
      d_nombre_repeat: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Repetir dominio',
        id: 'd_nombre_repeat',
        name: 'd_nombre_repeat',
        change_param: 'solicitar',
        regex: Regex.DOMINIO,
        value: '',
        default_value: '',
        placeholder: 'tumarca.com.ar',
        messages: {
          help: 'Debes volver a ingresar el dominio.',
          error:
            'Por favor, ingrese un dominio válido [sin www][sin acentos, símbolos o letra ñ][sin punto final]',
          error_extra: RegexExtra.DOMINIO,
        },
      },
    },

    confirmar_dns: {
      confirmar_dns_leer: {
        validate: false,
        name: 'confirmar_dns_leer',
        label:
          'He leído y realizado todos los pasos de configuración detallados anteriormente. He esperado 45-60 minutos luego de realizar dicha configuración.',
        value: 0,
        change_param: 'confirmar_dns',
      },
    },

    confirmar_cname: {
      confirmar_cname_leer: {
        validate: false,
        name: 'confirmar_cname_leer',
        label:
          'He leído y realizado todos los pasos de configuración detallados anteriormente. He esperado 2-3 horas luego de realizar dicha configuración.',
        value: 0,
        change_param: 'confirmar_cname',
      },
    },

    administrar_registro: {
      reg_action: {
        validate: false,
        value: 0, //0 agregar, 1 editar, 2 borrar
      },
      idDominios: {
        value: null,
        validate: false,
      },
      reg_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de registro',
        id: 'reg_tipo',
        name: 'reg_tipo',
        change_param: 'administrar_registro',
        value: 'A',
        messages: {
          error: 'Por favor, elija un tipo de registro',
          help: 'Debes seleccionar el tipo de registro que debes cargar',
        },
      },
      reg_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'reg_nombre',
        name: 'reg_nombre',
        change_param: 'administrar_registro',
        regex: Regex.REGISTRO_NOMBRE,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          help: '',
          error: 'Por favor, ingrese un nombre de registro válido',
          error_extra: RegexExtra.REGISTRO_NOMBRE,
        },
      },
      reg_valor: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Valor',
        id: 'reg_valor',
        name: 'reg_valor',
        change_param: 'administrar_registro',
        regex: Regex.REGISTRO_A,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          help: '',
          error: 'Por favor, ingrese un valor de registro válido',
          error_extra: RegexExtra.REGISTRO_A,
        },
      },
      reg_ttl: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'TTL',
        id: 'reg_ttl',
        name: 'reg_ttl',
        change_param: 'administrar_registro',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '3600',
        default_value: '',
        placeholder: '',
        messages: {
          help: 'Si no sabes que valor colocar acá, puedes dejarlo tal cual',
          error: 'Por favor, ingrese un TTL válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
    },

    setup: {
      show: false,
      abonado: false,
      comenzado: false,
      step: 0,
      dominio: null,
    },

    //carga de datos
    loading: {
      dominios: true,
      tienda: true,
      error: false,
    },

    //forms
    forms: {
      solicitar: false,
      confirmar_dns: false,
      confirmar_cname: false,
      administrar_registro: false,
      migrar_dominio_gratis: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },

    //estado de modales
    modals: {
      administrar_registro: false,
      migrar_dominio_gratis: false,
    },

    selects: {
      d_comprar: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      d_empresa: [
        {
          value: 0,
          label: 'Seleccionar tu proveedor',
        },
        {
          value: 1,
          label: 'Nic.ar (trámites a distancia)',
        },
        {
          value: 2,
          label: 'Godaddy',
        },
        {
          value: 3,
          label: 'Donweb',
        },
        {
          value: 4,
          label: 'Hostinger',
        },
        {
          value: 5,
          label: 'Otro (no está en la lista)',
        },
      ],
      reg_tipo: [
        {
          value: 'A',
          label: 'A',
        },
        {
          value: 'CNAME',
          label: 'CNAME',
        },
        {
          value: 'MX',
          label: 'MX',
        },
        {
          value: 'TXT',
          label: 'TXT',
        },
      ],
    },

    tutoriales: {
      solicitar: 'solicitar-dominio-link',
      configurar_correo: 'configurar-correo-link',
      dns_1: 'configurar-dns-1-link',
      dns_2: 'configurar-dns-2-link',
      dns_3: 'configurar-dns-3-link',
      dns_4: 'configurar-dns-4-link',
      dns_5: 'configurar-dns-5-link',
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err1, resp) => {
      this.props.dominioObtenerDominios((err2, resp) => {
        this.setState(
          (prevState) => ({
            loading: {
              ...prevState.loading,
              dominios: false,
              tienda: false,
              error: err1 || err2 ? true : false,
            },
          }),
          () => this.cargarSetup()
        );
      });
    });
  }

  reloadDomainStatus = () => {
    this.props.dominioObtenerDominios((err, resp) => {
      if (!err) {
        this.cargarSetup();
      }
    });
  };

  //maneja el cambio de todos los inputs
  handleChange = (e, blur, aditional) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === 'reg_nombre') {
      if (Validate.contiene_substring(value, '@')) {
        value = this.state[aditional][name].default_value;
      }
    }

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    if (name === 'reg_tipo') {
      let regex_key = 'REGISTRO_' + value;
      this.setState((prevState) => ({
        administrar_registro: {
          ...prevState.administrar_registro,
          reg_tipo: {
            ...prevState.administrar_registro.reg_tipo,
            value: value,
          },
          reg_valor: {
            ...prevState.administrar_registro.reg_valor,
            auth: false,
            error: false,
            regex: Regex[regex_key],
            value: '',
            messages: {
              ...prevState.administrar_registro.reg_valor.messages,
              error_extra: RegexExtra[regex_key],
            },
          },
        },
      }));
    } else {
      value = Validate.trim_int(value);
      this.setState((prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }));
    }
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState((prevState) => ({
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: prevState[aditional][name]['value'] ? 0 : 1,
        },
      },
    }));
  };

  //abre y cierra los modales
  modalHandler = (open, id, dominio, index = null) => {
    let regex_key, record, values;

    if (open) {
      let administrar_registro = {};
      switch (id) {
        case 'agregar':
          administrar_registro = {
            ...this.state.administrar_registro,
            reg_action: {
              ...this.state.administrar_registro.reg_action,
              value: 0, //0 agregar
            },
            idDominios: {
              ...this.state.administrar_registro.idDominios,
              value: dominio.idDominios,
            },
            reg_tipo: {
              ...this.state.administrar_registro.reg_tipo,
              value: 'A',
            },
            reg_nombre: {
              ...this.state.administrar_registro.reg_nombre,
              auth: true,
              error: false,
              value: dominio.d_nombre,
              default_value: dominio.d_nombre,
            },
            reg_valor: {
              ...this.state.administrar_registro.reg_valor,
              auth: false,
              error: false,
              regex: Regex.REGISTRO_A,
              value: '',
              messages: {
                ...this.state.administrar_registro.reg_valor.messages,
                error_extra: RegexExtra.REGISTRO_A,
              },
            },
            reg_ttl: {
              ...this.state.administrar_registro.reg_ttl,
              auth: true,
              error: false,
              value: '3600',
            },
          };
          break;
        case 'borrar':
          record = dominio.d_metadata.dns_zone.records[index];
          regex_key = 'REGISTRO_' + record.type;
          values = record.values.join('\n');
          administrar_registro = {
            ...this.state.administrar_registro,
            reg_action: {
              ...this.state.administrar_registro.reg_action,
              value: 2, //borrar
            },
            idDominios: {
              ...this.state.administrar_registro.idDominios,
              value: dominio.idDominios,
            },
            reg_tipo: {
              ...this.state.administrar_registro.reg_tipo,
              value: record.type,
            },
            reg_nombre: {
              ...this.state.administrar_registro.reg_nombre,
              auth: true,
              error: false,
              value: record.name,
              default_value: dominio.d_nombre,
            },
            reg_valor: {
              ...this.state.administrar_registro.reg_valor,
              auth: true,
              error: false,
              regex: Regex[regex_key],
              value: values,
              messages: {
                ...this.state.administrar_registro.reg_valor.messages,
                error_extra: RegexExtra[regex_key],
              },
            },
            reg_ttl: {
              ...this.state.administrar_registro.reg_ttl,
              auth: true,
              error: false,
              value: record.ttl,
            },
          };
          break;
        case 'editar':
          record = dominio.d_metadata.dns_zone.records[index];
          regex_key = 'REGISTRO_' + record.type;
          values = record.values.join('\n');
          administrar_registro = {
            ...this.state.administrar_registro,
            reg_action: {
              ...this.state.administrar_registro.reg_action,
              value: 1, //editar
            },
            idDominios: {
              ...this.state.administrar_registro.idDominios,
              value: dominio.idDominios,
            },
            reg_tipo: {
              ...this.state.administrar_registro.reg_tipo,
              value: record.type,
            },
            reg_nombre: {
              ...this.state.administrar_registro.reg_nombre,
              auth: true,
              error: false,
              value: record.name,
              default_value: dominio.d_nombre,
            },
            reg_valor: {
              ...this.state.administrar_registro.reg_valor,
              auth: true,
              error: false,
              regex: Regex[regex_key],
              value: values,
              messages: {
                ...this.state.administrar_registro.reg_valor.messages,
                error_extra: RegexExtra[regex_key],
              },
            },
            reg_ttl: {
              ...this.state.administrar_registro.reg_ttl,
              auth: true,
              error: false,
              value: record.ttl,
            },
          };
          break;
        default:
          break;
      }

      this.setState((prevState) => ({
        modals: {
          ...prevState.modals,
          administrar_registro: true,
        },
        administrar_registro: administrar_registro,
      }));
    } else {
      this.setState((prevState) => ({
        modals: {
          ...prevState.modals,
          administrar_registro: false,
        },
      }));
    }
  };

  modalHandlerMigrarDominio = (open, id, aditional) => {
    this.setState((prevState) => ({
      modals: {
        ...prevState.modals,
        [id]: open,
      },
    }));
  };

  validarEmpresa = (d_nombre) => {
    let d_nombreArr = d_nombre.split('.');
    let extension = Validate.trim_lowercase(
      d_nombreArr[d_nombreArr.length - 1]
    );
    let d_empresa = this.state.solicitar.d_empresa.value;
    if (extension === 'ar') {
      return Validate.in_array(d_empresa, [1, 3, 5]);
    } else {
      return Validate.in_array(d_empresa, [2, 3, 4, 5]);
    }
  };

  //submits
  handleSolicitarDominioSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        solicitar: true,
      },
    }));

    let form = this.state.solicitar;

    if (Validate.validar_formulario(form)) {
      if (!Validate.contiene_substring(form.d_nombre.value, 'empretienda')) {
        if (!Validate.contiene_substring(form.d_nombre.value, 'www')) {
          if (this.validarEmpresa(form.d_nombre.value)) {
            this.props.dominioSolicitar(
              {
                d_nombre: form.d_nombre.value,
                d_empresa: form.d_empresa.value,
              },
              (err, resp) => {
                if (err) {
                  this.setState((prevState) => ({
                    forms: {
                      ...prevState.forms,
                      solicitar: false,
                    },
                    alert: {
                      open: true,
                      message: resp.message,
                      type: err ? 'error' : 'success',
                    },
                  }));
                } else {
                  this.setState(
                    (prevState) => ({
                      forms: {
                        ...prevState.forms,
                        solicitar: false,
                      },
                    }),
                    () => this.cargarSetup()
                  );
                }
              }
            );
          } else {
            this.setState((prevState) => ({
              forms: {
                ...prevState.forms,
                solicitar: false,
              },
              alert: {
                open: true,
                message:
                  'El proveedor seleccionado no corresponde al tipo de dominio ingresado, revise dicha información.',
                type: 'error',
              },
            }));
          }
        } else {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              solicitar: false,
            },
            alert: {
              open: true,
              message:
                'Debes ingresar el dominio sin www. Por ejemplo: tumarca.com en lugar de www.tumarca.com (te activaremos ambos).',
              type: 'error',
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            solicitar: false,
          },
          alert: {
            open: true,
            message:
              'No es posible agregar otro subdominio gratuito .empretienda.com.ar, si quieres cambiar tu dominio gratuito, comunicate con nosotros.',
            type: 'error',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          solicitar: new_form,
          forms: {
            ...prevState.forms,
            solicitar: false,
          },
        }));
      });
    }
  };

  handleChequearDNSDominioSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        confirmar_dns: true,
      },
    }));

    this.props.dominioConfirmarDNS(
      {
        id: this.state.setup.dominio.d_metadata.id.root,
      },
      (err, resp) => {
        if (err) {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              confirmar_dns: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          }));
        } else {
          this.setState(
            (prevState) => ({
              forms: {
                ...prevState.forms,
                confirmar_dns: false,
              },
            }),
            () => this.cargarSetup()
          );
        }
      }
    );
  };

  handleChequearCNAMESubdominioSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        confirmar_cname: true,
      },
    }));

    this.props.dominioConfirmarCNAME(
      {
        id: this.state.setup.dominio.d_metadata.id.root,
      },
      (err, resp) => {
        if (err) {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              confirmar_cname: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          }));
        } else {
          this.setState(
            (prevState) => ({
              forms: {
                ...prevState.forms,
                confirmar_cname: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'success',
              },
            }),
            () => this.cargarSetup()
          );
        }
      }
    );
  };

  handleAdministrarRegistroSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        administrar_registro: true,
      },
    }));

    let form = this.state.administrar_registro;

    if (Validate.validar_formulario(form)) {
      let idDominios = form.idDominios.value;
      let d_nombre = form.reg_nombre.default_value;
      let reg_action = form.reg_action.value;
      let reg_tipo = form.reg_tipo.value;
      let reg_nombre = form.reg_nombre.value;

      //armo el array de valores
      let reg_valor = form.reg_valor.value.split('\n');

      //adapto el ttl
      let reg_ttl = Validate.trim_int(form.reg_ttl.value);
      if (reg_ttl < 300) {
        reg_ttl = 300;
      } else if (reg_ttl > 21600) {
        reg_ttl = 21600;
      }

      //remuevo el punto final del nombre si lo tiene
      if (reg_nombre.charAt(reg_nombre.length - 1) === '.') {
        reg_nombre = reg_nombre.slice(0, -1);
      }

      if (Validate.contiene_substring(reg_nombre, d_nombre)) {
        let reg_nombreArr = reg_nombre.split(d_nombre);
        if (reg_nombreArr[reg_nombreArr.length - 1] === '') {
          this.props.dominioABMRecord(
            {
              idDominios: idDominios,
              reg_action: reg_action,
              reg_tipo: reg_tipo,
              reg_nombre: reg_nombre,
              reg_valor: reg_valor,
              reg_ttl: reg_ttl,
            },
            (err, resp) => {
              this.setState((prevState) => ({
                forms: {
                  ...prevState.forms,
                  administrar_registro: false,
                },
                alert: {
                  open: true,
                  message: resp.message,
                  type: err ? 'error' : 'success',
                },
                modals: {
                  ...prevState.modals,
                  administrar_registro: err ? true : false,
                },
              }));
            }
          );
        } else {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              administrar_registro: false,
            },
            alert: {
              open: true,
              message:
                'El nombre del registro debe contener "' +
                d_nombre +
                '" al final',
              type: 'error',
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            administrar_registro: false,
          },
          alert: {
            open: true,
            message:
              'El nombre del registro debe contener "' +
              d_nombre +
              '" al final',
            type: 'error',
          },
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          administrar_registro: new_form,
          forms: {
            ...prevState.forms,
            administrar_registro: false,
          },
        }));
      });
    }
  };

  handleMigrarDominioSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        migrar_dominio_gratis: true,
      },
    }));

    this.props.dominioMigrar((err, resp) => {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          migrar_dominio_gratis: false,
        },
        alert: {
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
        modals: {
          ...prevState.modals,
          migrar_dominio_gratis: err ? true : false,
        },
      }));
    });
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  //configuro el estado inicial del setup
  cargarSetup = () => {
    const { tienda, dominios } = this.props;

    const idCountry = tienda.Country_idCountry;

    let show = dominios?.filter(
      (dominio) => dominio.d_pago === 1 && dominio.d_activo === 1
    ).length
      ? false
      : true;

    let dominio = null;
    let comenzado = false;
    let abonado =
      idCountry === 'COL' || tienda.t_pago_manual || tienda.t_debito_automatico
        ? true
        : false;
    let d_empresa = 0;

    //reviso si ya hay un setup iniciado
    if (show && abonado) {
      let dominios_setup = dominios.filter(
        (dominio) => dominio.d_pago === 1 && dominio.d_activo === 0
      );
      if (dominios_setup.length) {
        dominio = dominios_setup[0];
        if (!dominio.d_metadata) {
          show = false;
        } else {
          comenzado = true;
          d_empresa = dominio.d_metadata.dns_zone.company;
        }
      }
    }

    this.setState((prevState) => ({
      solicitar: {
        ...prevState.solicitar,
        d_empresa: {
          ...prevState.solicitar.d_empresa,
          value: d_empresa,
        },
      },
      setup: {
        ...prevState.setup,
        show: show,
        abonado: abonado,
        comenzado: comenzado,
        dominio: dominio,
      },
    }));
  };

  handleChangeStep = (step) => {
    this.setState((prevState) => ({
      setup: {
        ...prevState.setup,
        step: step,
      },
    }));
  };

  render() {
    const { tienda } = this.props;
    let { show, abonado } = this.state.setup;

    return (
      <WrapperLayout
        loading={this.state.loading.tienda || this.state.loading.dominios}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
          hideSeconds={10000}
        />
        <ModalMigrarDominio
          handleSubmit={this.handleMigrarDominioSubmit}
          modalHandler={this.modalHandlerMigrarDominio}
          modal_state={this.state.modals.migrar_dominio_gratis}
          form_state={this.state.forms.migrar_dominio_gratis}
        />
        <ModalRegistro
          administrar_registro={this.state.administrar_registro}
          selects={this.state.selects}
          handleSubmit={this.handleAdministrarRegistroSubmit}
          handleChange={this.handleChange}
          handleChangeSelect={this.handleChangeSelect}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.administrar_registro}
          form_state={this.state.forms.administrar_registro}
        />
        <ModuleTitleUI
          title="Dominios"
          subtitles={[
            'El dominio es la dirección de tu tienda. Por defecto, Empretienda te configura un dominio de manera gratuita (tumarca.empretienda.com.ar) pero tenés la posibilidad de configurar tu propio dominio. Por ejemplo: "tumarca.com.ar" o "tumarca.com".',
          ]}
        />
        <ToggleUI show={!abonado}>
          <DominioMetodoPago tienda={tienda} />
        </ToggleUI>
        <ToggleUI show={show && abonado}>
          <DominioSetup
            setup={this.state.setup}
            forms={this.state.forms}
            selects={this.state.selects}
            solicitar={this.state.solicitar}
            confirmar_dns={this.state.confirmar_dns}
            confirmar_cname={this.state.confirmar_cname}
            tutoriales={this.state.tutoriales}
            handleChange={this.handleChange}
            handleChangeStep={this.handleChangeStep}
            handleChangeSelect={this.handleChangeSelect}
            reloadDomainStatus={this.reloadDomainStatus}
            handleChangeCheckbox={this.handleChangeCheckbox}
            handleSolicitarDominioSubmit={this.handleSolicitarDominioSubmit}
            handleChequearDNSDominioSubmit={this.handleChequearDNSDominioSubmit}
            handleChequearCNAMESubdominioSubmit={
              this.handleChequearCNAMESubdominioSubmit
            }
          />
        </ToggleUI>
        <DominiosList
          modalHandler={this.modalHandler}
          modalHandlerMigrarDominio={this.modalHandlerMigrarDominio}
        />
      </WrapperLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    dominios: state.dominios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    dominioObtenerDominios: (callback) =>
      dispatch(dominioObtenerDominios(callback)),
    dominioSolicitar: (data, callback) =>
      dispatch(dominioSolicitar(data, callback)),
    dominioConfirmarDNS: (data, callback) =>
      dispatch(dominioConfirmarDNS(data, callback)),
    dominioConfirmarCNAME: (data, callback) =>
      dispatch(dominioConfirmarCNAME(data, callback)),
    dominioABMRecord: (data, callback) =>
      dispatch(dominioABMRecord(data, callback)),
    dominioMigrar: (callback) => dispatch(dominioMigrar(callback)),
  };
};

export const Dominios = connect(mapStateToProps, mapDispatchToProps)(Dominios_);
