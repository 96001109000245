import { LocalShipping, Payments } from '@mui/icons-material';

export const getNotificationData = (pendingData) => {
  const { cobros_pendientes, envios_pendientes } = pendingData;

  let title = 'Estas al día con tus ventas';
  let subtitle =
    'Mantené tu tienda al día para que tus clientes estén felices.';
  let aditionalText = 'Accedé al historial completo en el ';

  const payment = {
    color: '#BDBDBD',
    type: 'payment',
    isDisabled: true,
    icon: Payments,
    contentText: 'No tenés cobros pendientes',
  };
  const shipment = {
    color: '#BDBDBD',
    type: 'shipment',
    isDisabled: true,
    icon: LocalShipping,
    contentText: 'No tenés envíos pendientes',
  };

  if (envios_pendientes > 0 || cobros_pendientes > 0) {
    title = 'Tus ventas te esperan';
    subtitle = 'Ponete al día para ofrecer el mejor servicio a tus clientes.';
    aditionalText =
      'Te mostramos tus pendientes de los últimos 10 días. Accedé al historial completo en el ';
  }

  if (cobros_pendientes > 0) {
    payment.contentText =
      cobros_pendientes === 1
        ? '1 cobro pendiente'
        : `${cobros_pendientes} cobros pendientes`;
    payment.color = '#3A3A3A';
    payment.isDisabled = false;
  }

  if (envios_pendientes > 0) {
    shipment.contentText =
      envios_pendientes === 1
        ? '1 envío pendiente'
        : `${envios_pendientes} envíos pendientes`;
    shipment.color = '#3A3A3A';
    shipment.isDisabled = false;
  }

  return {
    title,
    subtitle,
    aditionalText,
    items: [payment, shipment],
  };
};
