import React from 'react';
import { Button, Grid } from '@mui/material';
import { InputUI } from '../../../../components/common';
import { BannersCarousel } from './BannersCarousel';
import { ProductsFeed } from './ProductsFeed';
import { ProductsSet } from './ProductsSet';
import { CategoriesList } from './CategoriesList';
import { Gallery } from './Gallery';
import { ImagesWithLink } from './ImagesWithLink';
import { TextElement } from './TextElement';
import { PurchaseInformation } from './PurchaseInformation';
import { ImageWithTextAndButton } from './ImageWithTextAndButton';
import { HTMLCode } from './HTMLCode';
import { ImagesWithLinkGrid } from './ImagesWithLinkGrid';

export const BlockEdit = ({
  template_id,
  bloques,
  active_page,
  handleChangeBlock,
  handleChangeFileBlock,
  handleChangeSelectBlock,
  handleChangeMenuTab,
  handleChangeGridDesign,
  handleChangeProductsBlock,
  handlerShowAlertUI,
}) => {
  const block = bloques[active_page];
  //Alineaciones para templates fijos
  const showAlign = template_id !== 8;

  const renderElementToEdit = () => {
    switch (block.bl_tipo.value) {
      //NOMBRE: Carrusel de banners
      case 1:
        return (
          <BannersCarousel
            handlerShowAlertUI={handlerShowAlertUI}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handleChangeBlock={handleChangeBlock}
            block={block}
          />
        );

      //NOMBRE: Feed principal de productos
      case 2:
        return (
          <ProductsFeed
            showAlign={showAlign}
            block={block}
            handleChangeBlock={handleChangeBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handleChangeMenuTab={handleChangeMenuTab}
          />
        );

      //NOMBRE: Conjunto de productos
      case 3:
        return (
          <ProductsSet
            handleChangeProductsBlock={handleChangeProductsBlock}
            handlerShowAlertUI={handlerShowAlertUI}
            handleChangeBlock={handleChangeBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            block={block}
            showAlign={showAlign}
          />
        );

      //NOMBRE: Lista de categorías
      case 4:
        return (
          <CategoriesList
            block={block}
            handleChangeBlock={handleChangeBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
          />
        );

      //NOMBRE: Galería
      case 5:
        return (
          <Gallery
            block={block}
            showAlign={showAlign}
            handleChangeBlock={handleChangeBlock}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handlerShowAlertUI={handlerShowAlertUI}
          />
        );
      //NOMBRE: Imágenes con link
      case 6:
        return (
          <ImagesWithLink
            block={block}
            showAlign={showAlign}
            handleChangeBlock={handleChangeBlock}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handlerShowAlertUI={handlerShowAlertUI}
          />
        );

      //NOMBRE: Texto
      case 7:
        return (
          <TextElement
            block={block}
            showAlign={showAlign}
            handleChangeBlock={handleChangeBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
          />
        );

      //NOMBRE: Información de compra
      case 8:
        return (
          <PurchaseInformation
            block={block}
            handleChangeBlock={handleChangeBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
          />
        );

      //NOMBRE: Imagen con texto y botón
      case 9:
        return (
          <ImageWithTextAndButton
            block={block}
            showAlign={showAlign}
            handleChangeBlock={handleChangeBlock}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handlerShowAlertUI={handlerShowAlertUI}
          />
        );

      //NOMBRE: Código HTML
      case 10:
        return <HTMLCode block={block} handleChangeBlock={handleChangeBlock} />;

      //NOMBRE: Grilla de imágenes con link
      case 11:
        return (
          <ImagesWithLinkGrid
            block={block}
            handleChangeGridDesign={handleChangeGridDesign}
            handleChangeBlock={handleChangeBlock}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handlerShowAlertUI={handlerShowAlertUI}
          />
        );

      default:
        break;
    }
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <InputUI input={block.bl_nombre} handleChange={handleChangeBlock} />
      </Grid>
      <Grid item xs={12}>
        {renderElementToEdit()}
      </Grid>
      <Grid item xs={12}>
        <Button
          type="callf"
          onClick={(e) => handleChangeMenuTab(e, -3)}
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
        >
          Volver
        </Button>
      </Grid>
    </Grid>
  );
};
