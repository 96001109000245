const categoriasObtener = () => {
  return {
    status: 200,
    data: {
      code: 9,
      type: 'success',
      messages: [],
    },
    message: 'Ok',
  };
};

export default {
  categoriasObtener,
};
