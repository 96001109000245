import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../common';

const ModalBorrar = (props) => {
  let { borrar, handleSubmit, modalHandler, modal_state, form_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="borrar"
      title="Borrar cupón"
      modalHandler={modalHandler}
      aditional_param="borrar"
      handleSubmit={handleSubmit}
      button_label="Borrar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            ¿Estás seguro de que quieres borrar el cupón de descuento "
            {borrar.cd_codigo}"?
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalBorrar };
