import React, { useState, useEffect } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { Autocomplete, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { productoObtenerProductos } from '../../../store/actions';

export const InputWithSuggestions_ = ({ getProducts, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const handleChangeTextField = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const searchHN = async () => {
      if (debouncedSearchTerm) {
        getProducts(
          {
            search: searchTerm,
          },
          (err, resp) => {
            if (!err) {
              const data = resp.data.map((element) => ({
                p_nombre: element.p_nombre,
                idProductos: element.idProductos,
              }));

              setResults(data);
            }
          }
        );
      }
    };

    if (searchTerm.length > 2) {
      searchHN();
    }
  }, [debouncedSearchTerm]);

  return (
    <Autocomplete
      sx={{ '& .MuiAutocomplete-endAdornment': { display: 'none' } }}
      multiple
      disablePortal
      freeSolo
      filterSelectedOptions
      id="combo-box-demo"
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      options={results}
      noOptionsText={'Ingrese el nombre del producto'}
      getOptionLabel={(value) => value.p_nombre}
      isOptionEqualToValue={(option, value) =>
        option.idProductos === value.idProductos
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Productos"
          onChange={handleChangeTextField}
          placeholder="Ej: Remera Onix"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (data, callback) =>
      dispatch(productoObtenerProductos(data, callback)),
  };
};

export const InputWithSuggestions = connect(
  null,
  mapDispatchToProps
)(InputWithSuggestions_);
