/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const productoReducer = (state = initState, action) => {
  let productos, producto;

  switch (action.type) {
    case Reducers.PRODUCTO_GUARDAR:
      productos = action.data;
      return productos;

    case Reducers.PRODUCTO_GUARDAR_NO_CLEAN:
      productos = action.data;
      return [...state, ...productos];

    case Reducers.PRODUCTO_BORRAR:
      producto = action.data;
      productos = state.filter(
        (prod) => prod.idProductos !== producto.idProductos
      );

      return productos;

    case Reducers.PRODUCTO_DESACTIVAR_ACTIVAR:
      producto = action.data;
      productos = state.map((prod) => {
        if (prod.idProductos === producto.idProductos) {
          return {
            ...prod,
            p_desactivado: producto.p_desactivado,
          };
        } else {
          return prod;
        }
      });

      return productos;

    case Reducers.PRODUCTO_ACTUALIZAR_STOCK:
      let { stock, idProductos } = action.data;
      productos = state.map((prod) => {
        if (prod.idProductos === idProductos) {
          let stock_updated = prod.stock.map((pstock) => {
            if (pstock.idStock === stock.idStock) {
              return stock;
            } else {
              return pstock;
            }
          });

          return {
            ...prod,
            stock: stock_updated,
          };
        } else {
          return prod;
        }
      });
      return productos;

    case Reducers.PRODUCTO_AGREGAR:
      return action.data;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { productoReducer };
