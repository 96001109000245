import Quill from 'quill';
import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import ListItem, { ListContainer } from 'quill/formats/list';
import 'quill/dist/quill.snow.css';

class MyListContainer extends ListContainer {
  static tagName = ['OL', 'UL'];
  static defaultTag = 'OL';

  static create(value) {
    return document.createElement(this.getTag(value));
  }

  static getTag(val) {
    const map = {
      bullet: 'UL',
      ordered: 'OL',
    };
    return map[val] || this.defaultTag;
  }

  checkMerge() {
    return (
      super.checkMerge() && this.domNode.tagName === this.next.domNode.tagName
    );
  }
}

class MyListItem extends ListItem {
  static requiredContainer = MyListContainer;

  static register() {
    Quill.register(MyListContainer, true);
  }

  optimize(context) {
    if (
      this.statics.requiredContainer &&
      !(this.parent instanceof this.statics.requiredContainer)
    ) {
      this.wrap(
        this.statics.requiredContainer.blotName,
        MyListItem.formats(this.domNode)
      );
    }
    super.optimize(context);
  }

  format(name, value) {
    if (
      name === ListItem.blotName &&
      value !== MyListItem.formats(this.domNode)
    ) {
      this.wrap(this.statics.requiredContainer.blotName, value);
    }
    super.format(name, value);
  }
}

Quill.register({
  'formats/list': MyListContainer,
  'formats/mylist': MyListItem,
});

const Editor = forwardRef(
  (
    {
      readOnly,
      placeholder,
      onTextChange,
      defaultValue,
      dispatchAlert,
      onSelectionChange,
      handleChangeQuill,
      toggleModalHandler,
    },
    ref
  ) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
    ];

    const handleClickButtonLink = () => {
      const selection = ref.current.getSelection();
      if (selection?.length > 0) {
        toggleModalHandler(true, 'link');
      } else {
        dispatchAlert({
          open: true,
          type: 'error',
          message: 'Debes seleccionar un texto o imagen para insertar un link',
        });
      }
    };

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      );
      const quill = new Quill(editorContainer, {
        placeholder: placeholder,
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              link: handleClickButtonLink,
              image: () => toggleModalHandler(true, 'image'),
              video: () => toggleModalHandler(true, 'video'),
            },
          },
        },
      });

      const htmlFormatted = quill.clipboard.convert({
        html: `${defaultValue}<br>`,
      });

      if (defaultValueRef.current) {
        quill.setContents(htmlFormatted);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        handleChangeQuill(quill.root.innerHTML);
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      ref.current = quill;

      return () => {
        ref.current = null;

        container.innerHTML = '';
      };
    }, [ref]);

    return <div ref={containerRef}></div>;
  }
);

Editor.displayName = 'Editor';

export default Editor;
