import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Icon,
  IconButton,
  Avatar,
  Hidden,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { InputUI, ToggleUI, ButtonUI } from '../../../common';
import moment from 'moment';

import { Validate, Config } from '../../../../other';

const determinar_estado_oferta = (item) => {
  let ahora = moment().local();
  let fin = Validate.utc2local(item.s_oferta_fecha);
  if (item.s_oferta === 2) {
    //fecha de fin
    if (ahora < fin) {
      return (
        '[Finaliza ' + Validate.utc2relative_local(item.s_oferta_fecha) + ']'
      );
    } else {
      return '';
    }
  } else {
    let inicio = Validate.utc2local(item.s_oferta_fecha_inicio);
    if (ahora < inicio) {
      return (
        '[Comienza ' +
        Validate.utc2relative_local(item.s_oferta_fecha_inicio) +
        ']'
      );
    } else if (ahora < fin) {
      return (
        '[Finaliza ' + Validate.utc2relative_local(item.s_oferta_fecha) + ']'
      );
    } else {
      return '';
    }
  }
};

const DetalleStock_ = (props) => {
  let {
    toggle_status,
    forms,
    producto,
    detalle_stock,
    handleChangeStockInput,
    handleToggleEditarDetalleStock,
    handleSubmitEditarDetalleStock,
    classes,
  } = props;

  const idCountry = useSelector((state) => state.tienda.Country_idCountry);

  if (toggle_status) {
    let stock = producto.stock;
    let editar = detalle_stock.editar;

    let html;

    if (stock.length > 0) {
      html = stock.map((item, index) => {
        let mostrar_inputs = detalle_stock.idStock === item.idStock;

        let link_imagen = producto.imagenes.filter(
          (imagen) => imagen.idImagenes === item.s_imagen
        )[0].i_link;

        if (mostrar_inputs) {
          //mostrar inputs
          let atributos_html = detalle_stock.p_atributos
            ? item.valoratributo.map((atributo, idx) => {
                return (
                  <Grid item md={3} xs={12} key={idx}>
                    <InputUI
                      handleChange={handleChangeStockInput}
                      input={editar['atributo_' + atributo.idAtributos]}
                    />
                  </Grid>
                );
              })
            : '';

          return (
            <ListItem
              ContainerComponent="div"
              key={index}
              classes={{
                root:
                  index === stock.length - 1
                    ? classes.list_item_last
                    : classes.list_item,
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item md={1} xs={2}>
                  <Avatar
                    alt="Foto de variante"
                    src={Config.CLOUDFRONT_PRODUCTS_CDN + link_imagen}
                  />
                </Grid>
                <Grid item md={11} xs={10}>
                  <Grid container spacing={1.5}>
                    {atributos_html}
                    <Grid item md={3} xs={12}>
                      <InputUI
                        handleChange={handleChangeStockInput}
                        input={editar.s_cantidad}
                        step="1"
                      />
                    </Grid>
                    <ToggleUI
                      show={
                        Validate.in_array(producto.p_tipo_venta, [0, 2]) &&
                        item.s_mostrar_precio === 1
                          ? true
                          : false
                      }
                    >
                      <ToggleUI show={item.s_oferta === 0}>
                        <Grid item md={3} xs={12}>
                          <InputUI
                            handleChange={handleChangeStockInput}
                            input={editar.s_precio}
                            adornment={Config.CURRENCY[idCountry].adornment}
                            step={Config.CURRENCY[idCountry].formatNumber}
                          />
                        </Grid>
                      </ToggleUI>
                      <ToggleUI show={item.s_oferta > 0}>
                        <Grid item md={3} xs={12}>
                          <InputUI
                            handleChange={handleChangeStockInput}
                            input={editar.s_precio}
                            adornment={Config.CURRENCY[idCountry].adornment}
                            step={Config.CURRENCY[idCountry].formatNumber}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <InputUI
                            handleChange={handleChangeStockInput}
                            input={editar.s_precio_oferta}
                            adornment={Config.CURRENCY[idCountry].adornment}
                            step={Config.CURRENCY[idCountry].formatNumber}
                          />
                        </Grid>
                      </ToggleUI>
                    </ToggleUI>
                    <ToggleUI
                      show={
                        Validate.in_array(producto.p_tipo_venta, [1, 2])
                          ? true
                          : false
                      }
                    >
                      <Grid item md={3} xs={12}>
                        <InputUI
                          handleChange={handleChangeStockInput}
                          input={editar.s_precio_mayorista}
                          adornment={Config.CURRENCY[idCountry].adornment}
                          step={Config.CURRENCY[idCountry].formatNumber}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <InputUI
                          handleChange={handleChangeStockInput}
                          input={editar.s_cantidad_minima}
                          step="1"
                        />
                      </Grid>
                    </ToggleUI>
                    <Grid item md={3} xs={12}>
                      <InputUI
                        handleChange={handleChangeStockInput}
                        input={editar.s_sku}
                      />
                    </Grid>
                    <ToggleUI show={producto.p_producto_digital > 0}>
                      <ToggleUI show={producto.p_producto_digital === 1}>
                        <Grid item xs={12}>
                          <InputUI
                            handleChange={handleChangeStockInput}
                            input={editar.s_producto_digital_link}
                          />
                        </Grid>
                      </ToggleUI>
                      <ToggleUI show={producto.p_producto_digital === 2}>
                        <Grid item xs={12}>
                          <InputUI
                            handleChange={handleChangeStockInput}
                            input={editar.s_producto_digital_plazo}
                          />
                        </Grid>
                      </ToggleUI>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={handleChangeStockInput}
                          input={editar.s_producto_digital_observacion}
                          multiline
                        />
                      </Grid>
                    </ToggleUI>
                    <Grid item xs={12}>
                      <Grid container spacing={1.5}>
                        <Grid item md={3} sm={4} xs={6}>
                          <ButtonUI
                            type="callf"
                            label="Guardar"
                            onClickFunc={(e) =>
                              handleSubmitEditarDetalleStock(e, producto)
                            }
                            isLoading={forms.detalle_stock}
                            minHeight={true}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={6}>
                          <ButtonUI
                            type="callf"
                            variant="outlined"
                            label="Cancelar"
                            onClickFunc={(e) =>
                              handleToggleEditarDetalleStock(producto, item)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          );
          //end mostrar inputss
        } else {
          let atributos_html = item.valoratributo.length
            ? item.valoratributo.map((atributo, idx) => {
                return (
                  <Grid item key={idx}>
                    <Typography className={classes.stock_title} noWrap>
                      {atributo.at_nombre}
                    </Typography>
                    <Typography className={classes.stock_value} noWrap>
                      {atributo.valor.vat_valor}
                    </Typography>
                  </Grid>
                );
              })
            : '';

          return (
            <ListItem
              ContainerComponent="div"
              key={index}
              classes={{
                root:
                  index === stock.length - 1
                    ? classes.list_item_last
                    : classes.list_item,
              }}
            >
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item md={10} sm={9} xs={12}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item sm="auto" xs={10}>
                      <Avatar
                        alt="Foto de variante"
                        src={Config.CLOUDFRONT_PRODUCTS_CDN + link_imagen}
                      />
                    </Grid>
                    <Hidden smUp>
                      <Grid item xs={2}>
                        <IconButton
                          aria-label="Editar"
                          onClick={(e) =>
                            handleToggleEditarDetalleStock(producto, item)
                          }
                          size="large"
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Grid>
                    </Hidden>
                    {atributos_html}
                    <Grid item>
                      <Typography className={classes.stock_title} noWrap>
                        Cantidad
                      </Typography>
                      <Typography className={classes.stock_value} noWrap>
                        {item.s_ilimitado === 1 ? '∞' : item.s_cantidad}
                      </Typography>
                    </Grid>
                    <ToggleUI
                      show={
                        Validate.in_array(producto.p_tipo_venta, [0, 2])
                          ? true
                          : false
                      }
                    >
                      <Grid item>
                        <Typography className={classes.stock_title} noWrap>
                          Precio
                        </Typography>
                        <Typography className={classes.stock_value} noWrap>
                          <ToggleUI show={item.s_oferta === 0}>
                            {item.s_mostrar_precio === 0
                              ? '-'
                              : '$' +
                                Validate.number_format(
                                  item.s_precio,
                                  idCountry
                                )}
                          </ToggleUI>
                          <ToggleUI show={item.s_oferta === 1}>
                            <del>
                              {'$' +
                                Validate.number_format(
                                  item.s_precio,
                                  idCountry
                                )}
                            </del>{' '}
                            {'$' +
                              Validate.number_format(
                                item.s_precio_oferta,
                                idCountry
                              )}
                          </ToggleUI>
                          <ToggleUI show={item.s_oferta > 1}>
                            <del>
                              {'$' +
                                Validate.number_format(
                                  item.s_precio,
                                  idCountry
                                )}
                            </del>{' '}
                            {'$' +
                              Validate.number_format(
                                item.s_precio_oferta,
                                idCountry
                              )}{' '}
                            {determinar_estado_oferta(item)}
                          </ToggleUI>
                        </Typography>
                      </Grid>
                    </ToggleUI>
                    <ToggleUI
                      show={
                        Validate.in_array(producto.p_tipo_venta, [1, 2])
                          ? true
                          : false
                      }
                    >
                      <Grid item>
                        <Typography className={classes.stock_title} noWrap>
                          Precio mayorista
                        </Typography>
                        <Typography className={classes.stock_value} noWrap>
                          $
                          {Validate.number_format(
                            item.s_precio_mayorista,
                            idCountry
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.stock_title} noWrap>
                          Cantidad mínima
                        </Typography>
                        <Typography className={classes.stock_value} noWrap>
                          {item.s_cantidad_minima}
                        </Typography>
                      </Grid>
                    </ToggleUI>
                    <Grid item>
                      <Typography className={classes.stock_title} noWrap>
                        SKU
                      </Typography>
                      <Typography className={classes.stock_value} noWrap>
                        {item.s_sku ? item.s_sku : '-'}
                      </Typography>
                    </Grid>
                    <ToggleUI show={producto.p_producto_digital > 0}>
                      <ToggleUI show={producto.p_producto_digital === 1}>
                        <Grid item sm="auto" xs={12}>
                          <Typography className={classes.stock_title} noWrap>
                            Link
                          </Typography>
                          <Typography className={classes.stock_value} noWrap>
                            {item.s_producto_digital_link}
                          </Typography>
                        </Grid>
                      </ToggleUI>
                      <ToggleUI show={producto.p_producto_digital === 2}>
                        <Grid item sm="auto" xs={12}>
                          <Typography className={classes.stock_title} noWrap>
                            Plazo
                          </Typography>
                          <Typography className={classes.stock_value} noWrap>
                            {item.s_producto_digital_plazo}
                          </Typography>
                        </Grid>
                      </ToggleUI>
                      <Grid item sm="auto" xs={12}>
                        <Typography className={classes.stock_title} noWrap>
                          Observación
                        </Typography>
                        <Typography className={classes.stock_value} noWrap>
                          {item.s_producto_digital_observacion}
                        </Typography>
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </Grid>
                <Hidden smDown>
                  <Grid item>
                    <IconButton
                      aria-label="Editar"
                      onClick={(e) =>
                        handleToggleEditarDetalleStock(producto, item)
                      }
                      size="large"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </Grid>
                </Hidden>
              </Grid>
            </ListItem>
          );
        }
      });
    } else {
      html = (
        <ListItem
          ContainerComponent="div"
          classes={{ root: classes.list_item_last }}
        >
          <ListItemText classes={{ root: classes.list_text }}>
            El producto no tiene stock cargado, podés cargarle{' '}
            <u>
              <Link
                to={'/productos/' + producto.idProductos}
                className={classes.text_link}
              >
                aquí
              </Link>
            </u>
            .
          </ListItemText>
        </ListItem>
      );
    }

    return (
      <Grid item xs={12}>
        <List classes={{ root: classes.list }}>{html}</List>
      </Grid>
    );
  } else {
    return '';
  }
};

const styles = (theme) => ({
  list_item: {
    backgroundColor: '#fefefe',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    marginBottom: 10,
  },
  list_item_last: {
    backgroundColor: '#fefefe',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    marginBottom: 0,
  },
  list: {
    padding: '0',
    listStyle: 'none',
  },
  text_link: {
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  imagen_variante_box: {
    cursor: 'pointer',
  },
  stock_title: {
    color: '#263238',
    fontSize: '1rem',
    fontWeight: 500,
  },
  stock_value: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.85rem',
    fontWeight: 400,
  },
});

export const DetalleStock = withStyles(styles, { withTheme: true })(
  DetalleStock_
);
