import React from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DialogUI } from '../../../common';

const DialogCompartir_ = ({
  compartir,
  dialog_state,
  dialogHandler,
  handleCopyLink,
  dominio,
  classes,
}) => {
  let link_producto =
    'https://' +
    dominio.d_nombre +
    compartir.c_link_full +
    '/' +
    compartir.p_link;

  return (
    <DialogUI
      title="Compartir producto"
      dialog_state={dialog_state}
      dialogHandler={dialogHandler}
      id="compartir"
      aditional_param="compartir"
    >
      <List>
        <ListItem
          button
          component="a"
          href={'https://www.facebook.com/sharer/sharer.php?u=' + link_producto}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar_facebook}>
              <Icon className={`${classes.icon} fab fa-facebook-f`} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Compartir en Facebook" />
        </ListItem>
        <ListItem
          button
          component="a"
          href={'https://twitter.com/share?url=' + link_producto}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar_twitter}>
              <Icon className={`${classes.icon} fab fa-twitter`} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Compartir en Twitter" />
        </ListItem>
        <ListItem
          button
          component="a"
          href={'whatsapp://send?text=' + link_producto}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar_whatsapp}>
              <Icon className={`${classes.icon} fab fa-whatsapp`} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Enviar por Whatsapp" />
        </ListItem>
        <ListItem button onClick={(e) => handleCopyLink(e, link_producto)}>
          <ListItemAvatar>
            <Avatar className={classes.avatar_link}>
              <Icon className={classes.icon}>link</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Copiar link del producto" />
        </ListItem>
        <ListItem button component="a" href={link_producto} target="_blank">
          <ListItemAvatar>
            <Avatar className={classes.avatar_link}>
              <Icon className={classes.icon}>open_in_new</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Ver producto en la tienda" />
        </ListItem>
      </List>
    </DialogUI>
  );
};

const styles = (theme) => ({
  avatar_facebook: {
    backgroundColor: '#2a45c6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar_twitter: {
    backgroundColor: '#5295db',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar_whatsapp: {
    backgroundColor: '#4f9417',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar_link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    margin: theme.spacing(2),
    color: '#fff',
  },
});

const mapStateToProps = (state) => {
  return {
    dominio: state.dominio,
  };
};

const mapDispatchToProps = null;

export const DialogCompartir = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DialogCompartir_));
