import React, { useEffect, useState } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { GlobalPrivateLayout } from '../../layouts/GlobalPrivateLayout';
import { useAuth0 } from '@auth0/auth0-react';
import { Splash } from './Splash';
import axios from 'axios';
import { adminLogout } from '../../store/actions';
import * as braze from '@braze/web-sdk';
import useGetBrazeToken from '../../hooks/useGetBrazeToken';
import { connect, useSelector } from 'react-redux';

const AVAILABLE_PATHNAMES = [
  '/plan',
  '/plan/reactivacion',
  '/plan/datos-facturacion',
  '/plan/metodo-pago',
  '/plan/metodo-pago/suscripcion',
  '/soporte',
];

const UNAVAILABLE_PATHNAMES_COLOMBIA = ['/selecciona-plantilla'];

const PrivateRoute_ = ({
  component: Component,
  withoutShopId,
  adminLogout,
  ...rest
}) => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout } =
    useAuth0();
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);
  const idTienda = user && user.app_metadata?.shop_id;

  const isTokenReady = idTienda && isTokenLoaded;
  const { data: brazeToken } = useGetBrazeToken(isTokenReady);

  const isShopLimited = useSelector((state) => state.tienda.t_deuda);
  const shop = useSelector((state) => state.tienda);

  const router = useHistory();
  const location = useLocation();
  const logoutQueryParam =
    router.location.search === '?idCountry=COL' ? '?idCountry=COL' : '';
  const searchParams = new URLSearchParams(router.location.search);
  const ca_codigo = searchParams.get('ca_codigo') || '';

  useEffect(() => {
    if (idTienda && brazeToken && shop.t_email) {
      braze.changeUser(idTienda, brazeToken);
      braze.getUser().setEmail(shop.t_email);
      braze
        .getUser()
        .setCustomUserAttribute('País', user.app_metadata.id_country);

      if (localStorage.getItem('first-login')) {
        braze.getUser().setCustomUserAttribute('Uala Bis Integrado', 0);
        braze.getUser().setCustomUserAttribute('Status', 'Inactivo');
        braze.logCustomEvent('empretienda_crea_tienda');
        localStorage.removeItem('first-login');
      }
    }
  }, [idTienda, brazeToken, shop.t_email]);

  useEffect(() => {
    if (
      location.pathname !== '/builder' &&
      location.pathname !== '/selecciona-plantilla' &&
      shop
    ) {
      localStorage.removeItem(`template-selected-${shop.idTienda}`);
      localStorage.removeItem('unsaved-user-data');
    }
  }, [location.pathname, shop]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + accessToken;
        setIsTokenLoaded(true);
      } catch (error) {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}${logoutQueryParam}`,
          },
        });
        adminLogout();
        console.log('error login: ', error);
      }
    };
    if (isAuthenticated && !isLoading) {
      getToken();
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <Splash />;
  }

  if (!isTokenLoaded) {
    if (isAuthenticated) {
      return <Splash />;
    }
  }

  if (isAuthenticated && idTienda) {
    if (
      isShopLimited &&
      !AVAILABLE_PATHNAMES.includes(router.location.pathname)
    ) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: `/plan`,
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    }

    if (
      user?.app_metadata.id_country === 'COL' &&
      UNAVAILABLE_PATHNAMES_COLOMBIA.includes(location.pathname)
    ) {
      return <Redirect to="/" />;
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <GlobalPrivateLayout>
              <Component {...props} />
            </GlobalPrivateLayout>
          )}
        />
      );
    }
  } else if (isAuthenticated) {
    if (withoutShopId && !localStorage.getItem('auth')) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: `/formulario-crear-tienda`,
              search: `?ca_codigo=${ca_codigo}`,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: '/login',
            search: logoutQueryParam,
            state: { from: props.location },
          }}
        />
      )}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogout: () => dispatch(adminLogout()),
  };
};

const PrivateRoute = connect(null, mapDispatchToProps)(PrivateRoute_);
export { PrivateRoute };
