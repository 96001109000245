import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const blogObtenerArticulos = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/blog')
      .then((resp) => {
        //guardo en redux
        const articulos = resp.data.data;
        dispatch({ type: Reducers.BLOG_GUARDAR, data: articulos });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const blogObtenerArticulo = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(`${Config.BACKEND_ENDPOINT}/blog/${data.idBlog}`)
      .then((resp) => {
        //guardo en redux
        const articulo = resp.data.data;
        dispatch({ type: Reducers.BLOG_GUARDAR, data: articulo });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const blogAgregar = (data, callback) => {
  return (dispatch, getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/blog', data)
      .then((resp) => {
        //guardo en redux
        const nuevo_articulo = resp.data.data;
        dispatch({ type: Reducers.BLOG_GUARDAR, data: nuevo_articulo });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const blogEditar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/blog/${data.idBlog}`, data)
      .then((resp) => {
        //guardo en redux
        const articulo_editado = resp.data.data;
        dispatch({
          type: Reducers.BLOG_GUARDAR,
          data: articulo_editado,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const blogBorrar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .delete(`${Config.BACKEND_ENDPOINT}/blog/${data.idBlog}`)
      .then((resp) => {
        //guardo en redux
        const articulo_borrado = resp.data.data;
        dispatch({
          type: Reducers.BLOG_BORRAR,
          data: articulo_borrado,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  blogObtenerArticulos,
  blogObtenerArticulo,
  blogAgregar,
  blogEditar,
  blogBorrar,
};
