import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../../../../components/common';

const ModalCancelarOrden = (props) => {
  let {
    cancelar_orden,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="cancelar_orden"
      title="Cancelar orden"
      subtitle={cancelar_orden.modal_subtitle.value}
      modalHandler={modalHandler}
      aditional_param="cancelar_orden"
      handleSubmit={handleSubmit}
      button_label="Cancelar orden"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            multiline
            input={cancelar_orden.cao_razon}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalCancelarOrden };
