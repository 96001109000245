import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  Icon,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToggleUI, InputUI } from '../../../common';
import { Config, Validate } from '../../../../other';
import { useSelector } from 'react-redux';

const AtributosInputList_ = (props) => {
  let {
    atributos_on,
    atributos_off,
    common,
    modalHandler,
    classes,
    handleChangeDynamicInput,
    handleOpcionesAvanzadasAtributos,
    handleChangeDeleteBloqueConAtributos,
    handleAgregarNuevoBloqueConAtributos,
  } = props;

  const idCountry = useSelector((state) => state.tienda.Country_idCountry);

  let stock = atributos_on.stock;
  let atributos_dinamicos = atributos_on.atributos.chips_back.map(
    (chip) => chip.key
  );

  let atributos_html, html;

  const ShowAvatar = (item, index) => {
    let data = {
      filled: true,
      change_param: item.s_precio.change_param,
      link: item.s_imagen.link,
      variante: index,
    };

    if (item.s_imagen.link) {
      let link = Config.CLOUDFRONT_PRODUCTS_CDN + item.s_imagen.link;
      return (
        <span
          className={classes.imagen_variante_box}
          onClick={() => modalHandler(true, 'imagen_variante', data)}
        >
          <Avatar alt="Foto de variante" src={link} />
        </span>
      );
    } else {
      return (
        <span
          className={classes.imagen_variante_box}
          onClick={() =>
            modalHandler(true, 'imagen_variante', { filled: false })
          }
        >
          <Avatar>
            <Icon>camera_alt</Icon>
          </Avatar>
        </span>
      );
    }
  };

  if (stock.length) {
    html = stock.map((item, index) => {
      atributos_html = atributos_dinamicos.map((atributo, idx) => {
        if (item[atributo]) {
          return (
            <Grid item md={3} xs={6} key={index + '-' + idx + '-' + atributo}>
              <InputUI
                handleChange={handleChangeDynamicInput}
                input={item[atributo]}
              />
            </Grid>
          );
        } else {
          return '';
        }
      });

      return (
        <ListItem
          ContainerComponent="div"
          key={index}
          classes={{
            root:
              index === stock.length - 1
                ? classes.list_item_last
                : classes.list_item,
          }}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1.5}
              >
                <Grid item sm={6} xs={4}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <Grid item>{ShowAvatar(item, index + 1)}</Grid>
                    <Grid item>
                      <Hidden only="xs">
                        <Typography
                          variant="subtitle1"
                          className={classes.product_variant_title}
                        >
                          Variante {index + 1}
                        </Typography>
                        <ToggleUI show={item.s_sku.value !== ''}>
                          <Typography className={classes.product_sku}>
                            SKU: {item.s_sku.value}
                          </Typography>
                        </ToggleUI>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <Grid item>
                      <Typography variant="subtitle1">
                        <span
                          onClick={(e) =>
                            handleOpcionesAvanzadasAtributos(
                              e,
                              item.s_precio.change_param
                            )
                          }
                          className={classes.text_link}
                        >
                          {item.opciones_avanzadas.state
                            ? 'Ocultar opciones'
                            : 'Más opciones'}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="Borrar"
                        onClick={(e) =>
                          handleChangeDeleteBloqueConAtributos(
                            e,
                            item.s_precio.change_param
                          )
                        }
                        size="large"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {atributos_html}
            <Grid item md={3} xs={12}>
              <InputUI
                handleChange={handleChangeDynamicInput}
                input={item.s_cantidad}
                step="1"
              />
            </Grid>
            <ToggleUI
              show={
                Validate.in_array(common.p_tipo_venta.value, [0, 2])
                  ? true
                  : false
              }
            >
              <Grid item md={3} xs={12}>
                <InputUI
                  handleChange={handleChangeDynamicInput}
                  input={item.s_precio}
                  adornment={Config.CURRENCY[idCountry].adornment}
                  step={Config.CURRENCY[idCountry].formatNumber}
                />
              </Grid>
              <ToggleUI show={item.opciones_avanzadas.state}>
                <Grid item md={3} xs={12}>
                  <InputUI
                    handleChange={handleChangeDynamicInput}
                    input={item.s_precio_oferta}
                    adornment={Config.CURRENCY[idCountry].adornment}
                    step={Config.CURRENCY[idCountry].formatNumber}
                  />
                </Grid>
              </ToggleUI>
            </ToggleUI>
            <ToggleUI
              show={
                Validate.in_array(common.p_tipo_venta.value, [1, 2])
                  ? true
                  : false
              }
            >
              <Grid item md={3} xs={12}>
                <InputUI
                  handleChange={handleChangeDynamicInput}
                  input={item.s_precio_mayorista}
                  adornment={Config.CURRENCY[idCountry].adornment}
                  step={Config.CURRENCY[idCountry].formatNumber}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <InputUI
                  handleChange={handleChangeDynamicInput}
                  input={item.s_cantidad_minima}
                  step="1"
                />
              </Grid>
            </ToggleUI>
            <ToggleUI show={item.opciones_avanzadas.state}>
              <Grid item md={3} xs={12}>
                <InputUI
                  handleChange={handleChangeDynamicInput}
                  input={item.s_sku}
                />
              </Grid>
              <ToggleUI show={common.p_producto_digital.value > 0}>
                <ToggleUI show={common.p_producto_digital.value === 1}>
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={handleChangeDynamicInput}
                      input={item.s_producto_digital_link}
                    />
                  </Grid>
                </ToggleUI>
                <ToggleUI show={common.p_producto_digital.value === 2}>
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={handleChangeDynamicInput}
                      input={item.s_producto_digital_plazo}
                    />
                  </Grid>
                </ToggleUI>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChangeDynamicInput}
                    input={item.s_producto_digital_observacion}
                    multiline
                  />
                </Grid>
              </ToggleUI>
              <ToggleUI
                show={
                  atributos_off.ui_dimensiones.value &&
                  common.p_producto_digital.value === 0
                    ? true
                    : false
                }
              >
                <Grid item md={3} xs={6}>
                  <InputUI
                    handleChange={handleChangeDynamicInput}
                    input={item.s_alto}
                    end_adornment="cm"
                    step="1"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <InputUI
                    handleChange={handleChangeDynamicInput}
                    input={item.s_ancho}
                    end_adornment="cm"
                    step="1"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <InputUI
                    handleChange={handleChangeDynamicInput}
                    input={item.s_profundidad}
                    end_adornment="cm"
                    step="1"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <InputUI
                    handleChange={handleChangeDynamicInput}
                    input={item.s_peso}
                    end_adornment="kg"
                    step="0.01"
                  />
                </Grid>
              </ToggleUI>
            </ToggleUI>
          </Grid>
        </ListItem>
      );
    });
  } else {
    html = (
      <ListItem ContainerComponent="div" classes={{ root: classes.list_item }}>
        <ListItemText classes={{ root: classes.list_text }}>
          No hay variantes creadas,{' '}
          <span
            onClick={handleAgregarNuevoBloqueConAtributos}
            className={classes.text_link}
          >
            crear nueva
          </span>
          .
        </ListItemText>
      </ListItem>
    );
  }

  return <List classes={{ root: classes.list }}>{html}</List>;
};

const styles = (theme) => ({
  list_item: {
    backgroundColor: '#fefefe',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    marginBottom: 20,
  },
  list_item_last: {
    backgroundColor: '#fefefe',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    marginBottom: 0,
  },
  list: {
    padding: '0',
    listStyle: 'none',
  },
  text_link: {
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  imagen_variante_box: {
    cursor: 'pointer',
  },
  product_variant_title: {
    padding: 0,
    margin: 0,
    lineHeight: 1,
  },
  product_sku: {
    padding: 0,
    margin: 0,
    lineHeight: 1.25,
    color: '#9e9e9e',
    fontSize: '0.8rem',
  },
});

export const AtributosInputList = withStyles(styles, { withTheme: true })(
  AtributosInputList_
);
