const ventaObtenerVentas = () => {
  return {
    status: 200,
    data: [
      {
        idVentas: 28492,
        v_numero: 2,
        v_fecha: '2023-02-15 13:20:06',
        v_monto: '2000.00',
        v_monto_bruto: '2000.00',
        v_comision: '0.00',
        v_archivada: 0,
        v_metodo_pago: 3,
        v_metodo_pago_descuento: 0,
        v_metodo_pago_descuento_monto: '0.00',
        v_estado_pago: 0,
        v_metodo_envio: 3,
        v_metodo_envio_subtipo: 0,
        v_metodo_envio_subsubtipo: 0,
        v_estado_envio: 0,
        v_envio_gratis: 0,
        v_envio_costo: '0.00',
        v_envio_costo_gratis: '0.00',
        v_envio_nombre: 'NombreDummy',
        v_envio_apellido: 'ApellidoDummy',
        v_envio_numero_identificacion: '10101010',
        v_envio_tipo_identificacion: 'CE',
        v_envio_telefono: '1234567890',
        v_envio_calle: 'Calle 14 # 92 Norte',
        v_envio_numero: '50',
        v_envio_piso: '',
        v_envio_dpto: 'Las vvv',
        v_envio_ciudad: 'Barranquilla',
        v_envio_provincia: 'ATL\u00c1NTICO',
        v_envio_pais: 'Colombia',
        v_envio_postal: '',
        v_envio_adicional: null,
        v_envio_numero_seguimiento: null,
        v_facturacion_nombre: 'NombreDummy',
        v_facturacion_apellido: 'ApellidoDummy',
        v_facturacion_numero_identificacion: '10101010',
        v_facturacion_tipo_identificacion: 'CE',
        v_facturacion_telefono: '1234567890',
        v_facturacion_calle: 'Calle 14 # 92 Norte',
        v_facturacion_numero: '50',
        v_facturacion_piso: '',
        v_facturacion_dpto: 'Las vvv',
        v_facturacion_ciudad: 'Barranquilla',
        v_facturacion_provincia: 'ATL\u00c1NTICO',
        v_facturacion_pais: 'Colombia',
        v_facturacion_postal: '',
        v_cupon: 0,
        v_cupon_monto: '0.00',
        v_cupon_porcentaje: 0,
        v_mayorista: 0,
        v_arr_envio_seleccionado_json:
          '{"dias": null, "tipo": 3, "icono": "v4/agree-shipment-icon.png", "nombre": "Acordar env\u00edo", "precio": 0, "subtipo": 0, "subsubtipo": 0, "descripcion": ["Te podr\u00e1s comunicar con nosotros para acordar el env\u00edo al finalizar la compra"], "costo_envio_gratis": 0}',
        v_arr_carrito_json:
          '{"394811": {"id": 3868362, "img": "9a004a76a226a5fe8cd1f14dc485310669159d07cdcf25de797d9f5abbdb9862110154.jpeg", "sku": "", "alto": 0, "peso": 0, "ancho": 0, "nombre": "Freddy Krugger", "cantidad": 1, "atributos": 0, "profundidad": 0, "infoproducto": false, "info_atributos": [], "cantidad_minima": 0, "precio_unitario": 2000, "producto_digital": 0, "infoproducto_link": "", "infoproducto_plazo": "", "infoproducto_observacion": "", "cantidad_minima_individual": 0}}',
        v_codigo_pago: null,
        v_codigo_pago_adicional: null,
        v_etiqueta_envio: null,
        v_stock_devuelto: 0,
        v_envio_identificador: null,
        v_envio_fecha: null,
        v_envio_etiqueta_adicional: null,
        v_infoproductos: 0,
        v_link_pago: null,
        v_observacion: null,
        v_factura: 0,
        v_factura_link: null,
        v_factura_identificador: null,
        v_checkout:
          '51c124a2d485cc2cb1302406a1f4b205a2ef956e85c4fb0fe71d1afdf3a9663d',
        Clientes_idClientes: 29713,
      },
      {
        idVentas: 28442,
        v_numero: 1,
        v_fecha: '2023-02-10 13:25:57',
        v_monto: '2000.00',
        v_monto_bruto: '2000.00',
        v_comision: '0.00',
        v_archivada: 0,
        v_metodo_pago: 3,
        v_metodo_pago_descuento: 0,
        v_metodo_pago_descuento_monto: '0.00',
        v_estado_pago: 0,
        v_metodo_envio: 3,
        v_metodo_envio_subtipo: 0,
        v_metodo_envio_subsubtipo: 0,
        v_estado_envio: 0,
        v_envio_gratis: 0,
        v_envio_costo: '0.00',
        v_envio_costo_gratis: '0.00',
        v_envio_nombre: 'Homero',
        v_envio_apellido: 'Simpson',
        v_envio_numero_identificacion: '32123456',
        v_envio_tipo_identificacion: 'DNI',
        v_envio_telefono: '543413720055',
        v_envio_calle: 'Laprida',
        v_envio_numero: '1234',
        v_envio_piso: '',
        v_envio_dpto: '',
        v_envio_ciudad: 'Rosario',
        v_envio_provincia: 'SANTA FE',
        v_envio_pais: 'Argentina',
        v_envio_postal: '2000',
        v_envio_adicional: null,
        v_envio_numero_seguimiento: null,
        v_facturacion_nombre: 'Matias',
        v_facturacion_apellido: 'Gomez',
        v_facturacion_numero_identificacion: '32111333',
        v_facturacion_tipo_identificacion: 'DNI',
        v_facturacion_telefono: '543413222444',
        v_facturacion_calle: 'Rioja',
        v_facturacion_numero: '1244',
        v_facturacion_piso: '',
        v_facturacion_dpto: '',
        v_facturacion_ciudad: 'Rosario',
        v_facturacion_provincia: 'SANTA FE',
        v_facturacion_pais: 'Argentina',
        v_facturacion_postal: '2000',
        v_cupon: 0,
        v_cupon_monto: '0.00',
        v_cupon_porcentaje: 0,
        v_mayorista: 0,
        v_arr_envio_seleccionado_json:
          '{"dias": null, "tipo": 3, "icono": "v4/agree-shipment-icon.png", "nombre": "Acordar env\u00edo", "precio": 0, "subtipo": 0, "subsubtipo": 0, "descripcion": ["Te podr\u00e1s comunicar con nosotros para acordar el env\u00edo al finalizar la compra"], "costo_envio_gratis": 0}',
        v_arr_carrito_json:
          '{"394811": {"id": 3868362, "img": "9a004a76a226a5fe8cd1f14dc485310669159d07cdcf25de797d9f5abbdb9862110154.jpeg", "sku": "", "alto": 0, "peso": 0, "ancho": 0, "nombre": "Freddy Krugger", "cantidad": 1, "atributos": 0, "profundidad": 0, "infoproducto": false, "info_atributos": [], "cantidad_minima": 0, "precio_unitario": 2000, "producto_digital": 0, "infoproducto_link": "", "infoproducto_plazo": "", "infoproducto_observacion": "", "cantidad_minima_individual": 0}}',
        v_codigo_pago: null,
        v_codigo_pago_adicional: null,
        v_etiqueta_envio: null,
        v_stock_devuelto: 0,
        v_envio_identificador: null,
        v_envio_fecha: null,
        v_envio_etiqueta_adicional: null,
        v_infoproductos: 0,
        v_link_pago: null,
        v_observacion: null,
        v_factura: 0,
        v_factura_link: null,
        v_factura_identificador: null,
        v_checkout:
          '165211fd22ca17fa28c20e7e2121d6f40e893dbdfead49f196877d027dfd5f75',
        Clientes_idClientes: 29699,
      },
    ],
    message: 'Ok',
  };
};

export default {
  ventaObtenerVentas,
};
