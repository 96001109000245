import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { productoImportacionProcesarChunks } from '../../../store/actions';

const ProcesarChunks_ = (props) => {
  const {
    noOpAsync,
    chunks_jsons,
    initial_completion_percentage,
    procesar_chunks_percentage,
    handleUpdateCompletionPercentage,
    handleProcesarChunksFinish,
    productoImportacionProcesarChunks,
  } = props;

  useEffect(() => {
    const handleChunkProcesadoAsync = async (err, resp) => {
      const completion_percentage =
        initial_completion_percentage + procesar_chunks_percentage * 1;
      await handleUpdateCompletionPercentage(completion_percentage);
      await noOpAsync();
      handleProcesarChunksFinish(err, resp);
    };

    const chunks_a_procesar = {
      data: chunks_jsons,
    };

    productoImportacionProcesarChunks(
      chunks_a_procesar,
      handleChunkProcesadoAsync
    );
  }, []);

  return <></>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    productoImportacionProcesarChunks: (data, callback) =>
      dispatch(productoImportacionProcesarChunks(data, callback)),
  };
};

const mapStateToProps = null;

export const ProcesarChunks = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcesarChunks_);
