import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const GridItemCard_ = ({ classes, children, lg = 4 }) => {
  const propsGrid = {
    lg: lg,
    sm: 12,
    xs: 12,
  };
  return (
    <Grid item {...propsGrid} className={classes.grid_item_container}>
      {children}
    </Grid>
  );
};

const styles = (theme) => ({
  grid_item_container: {
    [theme.breakpoints.down('md')]: {
      padding: '12px 0 !important',
    },
  },
});

export const GridItemCard = withStyles(styles)(GridItemCard_);
