import { Config } from '../../other';
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux';

const splitioSetCustomerId = (data) => {
  const sdkBrowserConfig = {
    core: {
      authorizationKey: Config.SPLIT_IO_BROWSER_API_KEY,
      key: String(data.idTienda),
    },
  };

  return (dispatch, _getState) => {
    // guardo en redux
    dispatch(initSplitSdk({ config: sdkBrowserConfig }));
    dispatch(getTreatments({ splitNames: Config.FF_SPLITS }));
  };
};

export { splitioSetCustomerId };
