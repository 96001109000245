import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Grid,
  Typography,
  Hidden,
  Icon,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DetalleStock } from '../index';
import { DropdownUI, MenuItemUI, ToggleUI } from '../../../common';
import { Validate, Config } from '../../../../other';

let mobile = Validate.is_mobile();

const ProductoItem_ = ({
  categories,
  stockDetail,
  product,
  classes,
  forms,
  dialogHandler,
  modalHandler,
  handleChangeStockInput,
  handleToggleEditarDetalleStock,
  handleSubmitEditarDetalleStock,
}) => {
  const [showStockDetail, setShowStockDetail] = useState(false);

  const getProductCategory = (categoryId) =>
    categories.filter((cat) => cat.idCategorias === categoryId)[0];

  const mainCategory = getProductCategory(product.Categorias_idCategorias);

  const mainCategoryLabel = mobile
    ? mainCategory.c_nombre
    : mainCategory.c_nombre_full;

  let unlimitedStock = false;
  let totalStock = 0;
  product.stock.forEach((item) => {
    if (item.s_ilimitado) {
      unlimitedStock = true;
    }
    totalStock += item.s_cantidad;
  });

  totalStock = product.stock.length ? totalStock : '-';

  return (
    <Grid item xs={12}>
      <div className={classes.list_item}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={0.5}
            >
              <Grid item lg={8} md={6.5} sm={8} xs={10}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item md="auto" xs={2} pr={1}>
                    <Avatar
                      alt="Foto producto"
                      src={
                        Config.CLOUDFRONT_PRODUCTS_CDN +
                        product.imagenes[0].i_link
                      }
                      className={classes.bigAvatar}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography fontSize={{ sm: '1.2rem', xs: '1rem' }}>
                      {product.p_nombre}
                    </Typography>
                    <Typography className={classes.list_item_subtitle}>
                      {mainCategoryLabel}
                    </Typography>
                    <Typography mb="5px" className={classes.list_item_subtitle}>
                      Stock total: {unlimitedStock ? '∞' : totalStock}
                    </Typography>
                    <ToggleUI
                      show={Validate.in_array(product.p_tipo_venta, [1, 2])}
                    >
                      <Typography
                        mb="5px"
                        className={classes.list_item_subtitle}
                      >
                        Cantidad mínima por mayor: {product.p_cantidad_minima}
                      </Typography>
                    </ToggleUI>
                    <Typography
                      className={classes.list_item_ver_stock}
                      onClick={() => setShowStockDetail(!showStockDetail)}
                      sx={{
                        fontSize: '0.8rem',
                        marginTop: '5px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      as="span"
                    >
                      {showStockDetail
                        ? 'OCULTAR DETALLE DE STOCK'
                        : 'VER DETALLE DE STOCK'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm="auto" xs={2}>
                <Hidden mdUp>
                  <DropdownUI type="icon">
                    <MenuItemUI
                      type="callf"
                      onClickFunction={() =>
                        dialogHandler(true, 'compartir', {
                          producto: product,
                          categoria_principal: mainCategory,
                        })
                      }
                    >
                      Compartir producto
                    </MenuItemUI>
                    <MenuItemUI
                      type="callf"
                      onClickFunction={() =>
                        modalHandler(true, 'desactivar', product)
                      }
                    >
                      {product.p_desactivado
                        ? 'Activar producto'
                        : 'Desactivar producto'}
                    </MenuItemUI>
                    <MenuItemUI
                      type="link"
                      link={'/productos/' + product.idProductos}
                    >
                      Editar producto
                    </MenuItemUI>
                    <MenuItemUI
                      type="link"
                      link={'/productos/agregar/' + product.idProductos}
                    >
                      Duplicar producto
                    </MenuItemUI>
                    <MenuItemUI
                      type="callf"
                      onClickFunction={() =>
                        modalHandler(true, 'borrar', product)
                      }
                    >
                      Borrar producto
                    </MenuItemUI>
                  </DropdownUI>
                </Hidden>
                <Hidden mdDown>
                  <Grid container spacing={0}>
                    <Grid item>
                      <IconButton
                        aria-label="Compartir"
                        onClick={() =>
                          dialogHandler(true, 'compartir', {
                            producto: product,
                            categoria_principal: mainCategory,
                          })
                        }
                        size="large"
                      >
                        <Icon>share</Icon>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Link to={`/productos/agregar/${product.idProductos}`}>
                        <IconButton size="large" aria-label="Duplicar">
                          <Icon>content_copy</Icon>
                        </IconButton>
                      </Link>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="Visibilidad"
                        onClick={() =>
                          modalHandler(true, 'desactivar', product)
                        }
                        size="large"
                      >
                        <Icon>
                          {product.p_desactivado
                            ? 'visibility_off'
                            : 'visibility'}
                        </Icon>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Link to={`/productos/${product.idProductos}`}>
                        <IconButton aria-label="Editar" size="large">
                          <Icon>edit</Icon>
                        </IconButton>
                      </Link>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="Borrar"
                        onClick={(e) => modalHandler(true, 'borrar', product)}
                        size="large"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <DetalleStock
            toggle_status={showStockDetail}
            forms={forms}
            producto={product}
            detalle_stock={stockDetail}
            handleChangeStockInput={handleChangeStockInput}
            handleToggleEditarDetalleStock={handleToggleEditarDetalleStock}
            handleSubmitEditarDetalleStock={handleSubmitEditarDetalleStock}
          />
        </Grid>
      </div>
    </Grid>
  );
};

const styles = (theme) => ({
  bigAvatar: {
    [theme.breakpoints.up('lg')]: {
      width: 60,
      height: 60,
    },
  },
  list_item: {
    backgroundColor: '#fff',
    boxShadow: theme.card.card.boxShadow,
    borderRadius: '4px',
    padding: '15px 7.5px',
    [theme.breakpoints.up('sm')]: {
      padding: '15px 10px',
    },
  },
  list_item_subtitle: {
    fontSize: '0.9rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export const ProductoItem = withStyles(styles, { withTheme: true })(
  ProductoItem_
);
