import React from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { ProductoItem } from '../index';

const ProductosList_ = ({
  forms,
  productos,
  categorias,
  detalle_stock,
  modalHandler,
  dialogHandler,
  handleChangeStockInput,
  handleToggleEditarDetalleStock,
  handleSubmitEditarDetalleStock,
}) => {
  const renderProducts = () => {
    if (productos.length > 0) {
      return productos.map((producto, index) => {
        return (
          <ProductoItem
            categories={categorias}
            stockDetail={detalle_stock}
            product={producto}
            forms={forms}
            dialogHandler={dialogHandler}
            modalHandler={modalHandler}
            handleChangeStockInput={handleChangeStockInput}
            handleToggleEditarDetalleStock={handleToggleEditarDetalleStock}
            handleSubmitEditarDetalleStock={handleSubmitEditarDetalleStock}
            key={index}
          />
        );
      });
    } else {
      return (
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: '#fff',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '4px',
              padding: {
                xs: '15px 7.5px',
                sm: '15px 10px',
              },
            }}
          >
            <Typography fontSize={{ sm: '1.2rem', xs: '1rem' }}>
              No se han encontrado productos
            </Typography>
            <Typography fontSize="0.9rem" color="rgba(0, 0, 0, 0.54)">
              Tene en cuenta que se busca en función a todos los filtros
            </Typography>
          </Box>
        </Grid>
      );
    }
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      {renderProducts()}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    productos: state.productos,
    categorias: state.categorias,
    auth: state.auth,
  };
};

const mapDispatchToProps = null;

export const ProductosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductosList_);
