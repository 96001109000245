/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const paginaReducer = (state = initState, action) => {
  let data;
  switch (action.type) {
    case Reducers.PAGINA_GUARDAR:
      return action.data;

    case Reducers.PAGINA_ORDENAR:
      return [...action.data];

    case Reducers.PAGINA_BORRAR:
      let pagina_borrada = action.data;
      data = state.filter((pagina) => {
        return pagina.idPaginas !== pagina_borrada.idPaginas;
      });
      return data;

    case Reducers.AUTH_UNSET:
      return initState;

    default:
      return state;
  }
};

export { paginaReducer };
