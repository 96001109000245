import React from 'react';
import { Grid } from '@mui/material';
import { InputUI, ToggleUI, CheckboxUI } from '../../../common';
import { Validate, Config } from '../../../../other';

const PrecioStockSection = ({
  handleChange,
  handleChangeCheckbox,
  common,
  atributos_off,
  idCountry,
}) => {
  return (
    <Grid container spacing={3}>
      <ToggleUI
        show={
          Validate.in_array(common.p_tipo_venta.value, [0, 2]) ? true : false
        }
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputUI
                handleChange={handleChange}
                input={atributos_off.p_precio}
                adornment={Config.CURRENCY[idCountry].adornment}
                step={Config.CURRENCY[idCountry].formatNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputUI
                handleChange={handleChange}
                input={atributos_off.p_precio_oferta}
                adornment={Config.CURRENCY[idCountry].adornment}
                step={Config.CURRENCY[idCountry].formatNumber}
              />
            </Grid>
          </Grid>
          <CheckboxUI
            handleChangeCheckbox={handleChangeCheckbox}
            checkbox={atributos_off.p_mostrar_precio}
            inverse
            fontSize="14px"
            color="#666666"
          />
        </Grid>
      </ToggleUI>
      <ToggleUI
        show={
          Validate.in_array(common.p_tipo_venta.value, [1, 2]) ? true : false
        }
      >
        <Grid item sm={common.p_atributos.value === 0 ? 6 : 12} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={atributos_off.p_precio_mayorista}
            adornment={Config.CURRENCY[idCountry].adornment}
            step={Config.CURRENCY[idCountry].formatNumber}
          />
        </Grid>
        <ToggleUI show={common.p_atributos.value === 0}>
          <Grid item sm={6} xs={12}>
            <InputUI
              handleChange={handleChange}
              input={atributos_off.p_cantidad_minima}
              step="1"
            />
          </Grid>
        </ToggleUI>
      </ToggleUI>
      <Grid item sm={6} xs={12}>
        <InputUI
          handleChange={handleChange}
          input={atributos_off.s_cantidad}
          step="1"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <InputUI handleChange={handleChange} input={atributos_off.s_sku} />
      </Grid>
      <ToggleUI show={common.p_producto_digital.value > 0}>
        <ToggleUI show={common.p_producto_digital.value === 1}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={atributos_off.s_producto_digital_link}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI show={common.p_producto_digital.value === 2}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={atributos_off.s_producto_digital_plazo}
            />
          </Grid>
        </ToggleUI>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={atributos_off.s_producto_digital_observacion}
            multiline
          />
        </Grid>
      </ToggleUI>
    </Grid>
  );
};
export { PrecioStockSection };
