import React from 'react';
import { ButtonUI, DropdownUI, MenuItemUI } from '../../../components/common';
import { CustomCardUI } from '../components/CustomCardUI';
import { GridItemCard } from '../components/GridItemCard';
import { Config } from '../../../other';

const Efectivo = (props) => {
  const { metodos_pago, forms, modalHandler, handleDesactivarEfectivoSubmit } =
    props;

  const cargarBoton = () => {
    if (metodos_pago.mp_efectivo) {
      return (
        <DropdownUI
          label="Configuración"
          size="large"
          isLoading={forms.desactivar_efectivo}
        >
          <MenuItemUI
            type="callf"
            onClickFunction={() =>
              modalHandler(true, 'activar_editar_efectivo')
            }
          >
            Editar configuración
          </MenuItemUI>
          <MenuItemUI
            type="callf"
            onClickFunction={handleDesactivarEfectivoSubmit}
          >
            Desactivar
          </MenuItemUI>
        </DropdownUI>
      );
    } else {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={() => modalHandler(true, 'activar_editar_efectivo')}
          label="Activar"
          size="large"
        />
      );
    }
  };

  return (
    <GridItemCard lg={6}>
      <CustomCardUI
        title="Efectivo"
        subtitle={
          metodos_pago.mp_efectivo ? 'Método activo' : 'Método no activo'
        }
        info="Tus clientes podrán pagar en efectivo cuando retiren el producto. Sólo estará disponible si configuras locales en la sección métodos de envío."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/efectivo-card-img.png`}
      >
        {cargarBoton()}
      </CustomCardUI>
    </GridItemCard>
  );
};

export { Efectivo };
