import React, { useState, useEffect } from 'react';
import { AlertUI, ButtonUI, ModuleTitleUI, ToggleUI } from '../../common';
import { TabsUI } from '../../common/TabsUI';
import { SuscripcionInfo } from './SuscripcionInfo';
import { PagoManual } from './PagoManual';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  tiendaAdherirPagoManual,
  tiendaObtenerMiPlan,
  tiendaPagarFactura,
} from '../../../store/actions';
import { connect, useSelector } from 'react-redux';
import {
  createAmplitudeEventWithDevice,
  suscription_amplitude_events,
} from '../../amplitude';
import { Redirect, Route } from 'react-router-dom';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

const PlanMetodoPago_ = ({
  history,
  classes,
  pagarFactura,
  obtenerMiPlan,
  adherirPagoManual,
}) => {
  // Uala Bis 1 - MercadoPago 0
  const [metodoPago, setMetodoPago] = useState(1);

  const [monto, setMonto] = useState(799);
  const [redirectTo, setRedirectTo] = useState('');
  const [error, setError] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [idFactura, setIdFactura] = useState();
  const [isTrial, setIsTrial] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [isAvailableToPay, setisAvailableToPay] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  useEffect(() => {
    obtenerMiPlan((err, resp) => {
      if (err) {
        setError(true);
      } else if (resp.data) {
        setMonto(resp.data.factura.monto);
        setIdFactura(resp.data.factura.id);
        setIsTrial(!!resp.data.tienda.t_prueba);
        setExpirationDate(resp.data.factura.fecha_vencimiento);
        setisAvailableToPay(
          resp.data.factura.dias_vencimiento <= 10 && !resp.data.factura.paga
        );

        if (resp.data.facturacion === 0) {
          setRedirectTo(`/plan/datos-facturacion`);
        }
      }
      setLoading(false);

      if (localStorage.getItem('plan-alert')) {
        const planAlert = JSON.parse(localStorage.getItem('plan-alert'));
        setAlert(planAlert);
        localStorage.removeItem('plan-alert');
      }
    });
  }, []);

  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const handleChange = (_e, value) => setTabValue(value);

  const handleSeleccionarMetodoPago = (metodoPago) => setMetodoPago(metodoPago);

  const handlePagoManual = () => {
    adherirPagoManual((err, resp) => {
      if (err) {
        setLoadingButton(false);
        setAlert({
          open: true,
          message: resp.message,
          type: 'error',
        });
      } else {
        pagarFactura(
          {
            id: idFactura,
            metodo: metodoPago,
          },
          (err, resp) => {
            if (err) {
              setLoadingButton(false);
              setAlert({
                open: true,
                message: resp.message,
                type: 'error',
              });
            } else {
              if (metodoPago === 0) {
                createAmplitudeEventWithDevice(
                  suscription_amplitude_events
                    .plan_configuracion_metodo_pago_seleccionar.event_name,
                  { metodo_pago: 'mercado_pago' }
                );
              } else {
                createAmplitudeEventWithDevice(
                  suscription_amplitude_events
                    .plan_configuracion_metodo_pago_seleccionar.event_name,
                  { metodo_pago: 'uala_bis' }
                );
              }
              if (typeof window !== 'undefined') {
                if (window.gtag) {
                  window.gtag('event', 'conversion', {
                    event_category: 'Suscripción',
                    event_label: 'Click en pagar manualmente',
                  });
                }
              }
              window.location.replace(resp.data.url);
            }
          }
        );
      }
    });
  };

  const handleContinuar = (e) => {
    e.preventDefault();

    setLoadingButton(true);
    if (isAvailableToPay && typeof window !== 'undefined' && window.fbq) {
      window.fbq('track', 'InitiateCheckout');
    }
    if (tabValue === 1) {
      isAvailableToPay ? handlePagoManual() : history.push('/plan');
    } else if (tabValue === 0) {
      createAmplitudeEventWithDevice(
        suscription_amplitude_events.plan_configuracion_metodo_pago_seleccionar
          .event_name,
        { metodo_pago: 'suscripcion' }
      );
      history.push('/plan/metodo-pago/suscripcion');
    }
  };

  const getLabelButton = () =>
    tabValue === 1
      ? isAvailableToPay
        ? 'Continuar'
        : 'Ir a Mi plan'
      : 'Continuar';

  return (
    <WrapperLayout
      classes={{ content: classes.paymentMethodContainer }}
      loading={loading}
      error={error}
    >
      <AlertUI
        open={alert.open}
        type={alert.type}
        hideSeconds={5000}
        message={alert.message}
        handleCloseAlert={() => setAlert({ ...alert, open: false })}
      />
      <ModuleTitleUI title="Método de pago" />
      {redirectTo && (
        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: props.location },
              }}
            />
          )}
        />
      )}

      <ToggleUI show={idCountry === 'ARG'}>
        <TabsUI
          value={tabValue}
          headerTab={['Suscripción', 'Pago manual']}
          contentTab={[
            <SuscripcionInfo
              monto={monto}
              isTrial={isTrial}
              idCountry={idCountry}
              expirationDate={expirationDate}
            />,
            <PagoManual
              monto={monto}
              metodoPago={metodoPago}
              isAvailableToPay={isAvailableToPay}
              handleSeleccionarMetodoPago={handleSeleccionarMetodoPago}
            />,
          ]}
          handleChange={handleChange}
        />
      </ToggleUI>
      <ToggleUI show={idCountry === 'COL'}>
        <SuscripcionInfo
          monto={monto}
          isTrial={isTrial}
          idCountry={idCountry}
          expirationDate={expirationDate}
        />
      </ToggleUI>
      <Grid container wrap="nowrap">
        <Grid item xs={12} className={classes.containerButtons}>
          <ButtonUI
            fullWidth={false}
            type="link"
            variant="outlined"
            label="Volver a Inicio"
            link={'/'}
            classes={{ root: classes.button }}
          />
          <ButtonUI
            fullWidth={false}
            type="callf"
            label={getLabelButton()}
            isLoading={loadingButton}
            minHeight={true}
            onClickFunc={handleContinuar}
            classes={{ root: classes.buttonOk }}
          />
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const style = (theme) => ({
  button: {
    minWidth: '49%',
    marginRight: '8px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '180px',
    },
  },
  buttonOk: {
    minWidth: '49%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '180px',
    },
  },
  containerButtons: {
    wrap: 'nowrap',
    display: 'flex',
    marginTop: '24px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  paymentMethodContainer: {
    padding: '24px 12px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 32px',
    },
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    obtenerMiPlan: (callback) => dispatch(tiendaObtenerMiPlan(callback)),
    pagarFactura: (data, callback) =>
      dispatch(tiendaPagarFactura(data, callback)),
    adherirPagoManual: (callback) =>
      dispatch(tiendaAdherirPagoManual(callback)),
  };
};

export const PlanMetodoPago = withStyles(style)(
  connect(null, mapDispatchToProps)(PlanMetodoPago_)
);
