import React from 'react';
import arrayMove from 'array-move';
import { withStyles } from '@mui/styles';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import {
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

const BlockList_ = ({
  classes,
  bloques,
  toggleModalBorrar,
  handleChangeMenuTab,
  handleDisableEnableBlock,
  handleSortBlocks,
}) => {
  let html;

  const onSortEnd = ({ args }) => {
    const { oldIndex, newIndex } = args;
    if (oldIndex !== newIndex) {
      let bloques_ord = arrayMove(bloques, oldIndex, newIndex);
      handleSortBlocks(bloques_ord);
    }
  };

  const DragHandle = sortableHandle(() => (
    <Icon classes={{ root: classes.drag_icon }}>drag_indicator</Icon>
  ));

  const SortableItem = sortableElement(({ bloque, idx }) => {
    return (
      <ListItem
        ContainerComponent="div"
        key={bloque.bl_nombre.change_param}
        sx={{
          paddingLeft: '8px',
          backgroundColor: '#fff',
          outline: '1px solid #e0e0e0',
        }}
        classes={{
          container: classes.list_item_container,
        }}
      >
        <ListItemIcon classes={{ root: classes.list_item_icon }}>
          <DragHandle />
        </ListItemIcon>
        <ListItemText
          sx={{
            fontSize: '0.8rem',
            paddingLeft: '0px',
            paddingRight: '30px',
            userSelect: 'none',
          }}
          primary={
            <span className={classes.list_text}>{bloque.bl_nombre.value}</span>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="Visibilidad"
            classes={{ root: classes.action_button }}
            onClick={() => handleDisableEnableBlock(idx)}
            size="large"
          >
            <Icon classes={{ root: classes.action_icon }}>
              {bloque.bl_activo.value ? 'visibility' : 'visibility_off'}
            </Icon>
          </IconButton>
          <IconButton
            aria-label="Editar"
            classes={{ root: classes.action_button }}
            onClick={(e) => handleChangeMenuTab(e, idx)}
            size="large"
          >
            <Icon classes={{ root: classes.action_icon }}>edit</Icon>
          </IconButton>
          <IconButton
            aria-label="Borrar"
            classes={{ root: classes.action_button }}
            onClick={() => toggleModalBorrar(idx)}
            size="large"
          >
            <Icon classes={{ root: classes.action_icon }}>delete</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <List component="div" classes={{ root: classes.list }}>
        {children}
      </List>
    );
  });

  const renderizarBloques = (bloques) =>
    bloques.map((bloque, index) => (
      <SortableItem
        key={bloque.bl_nombre.change_param}
        index={index}
        idx={index}
        bloque={bloque}
      />
    ));

  html = bloques.length ? (
    <SortableContainer
      onSortEnd={(args) => onSortEnd({ args: args })}
      useDragHandle
    >
      {renderizarBloques(bloques)}
    </SortableContainer>
  ) : (
    <List classes={{ root: classes.list }}>
      <ListItem
        sx={{
          paddingLeft: '8px',
          backgroundColor: '#fff',
          outline: '1px solid #e0e0e0',
        }}
        classes={{
          container: classes.list_item_container,
        }}
      >
        <ListItemText
          sx={{
            fontSize: '0.8rem',
            paddingLeft: '0px',
            paddingRight: '30px',
            userSelect: 'none',
          }}
          primary={
            <span className={classes.list_text}>No hay secciones activas</span>
          }
        />
      </ListItem>
    </List>
  );

  return html;
};

const styles = () => ({
  list_item_icon: {
    marginRight: '4px',
  },
  list: {
    padding: '0',
    listStyle: 'none',
  },
  list_item_container: {
    zIndex: 1301,
  },
  list_text: {
    fontSize: '0.8rem',
  },
  action_icon: {
    fontSize: '15px',
  },
  action_button: {
    padding: '6px',
  },
  drag_icon: {
    cursor: 'move',
    fontSize: '20px',
  },
});

export const BlockList = withStyles(styles, { withTheme: true })(BlockList_);
