import React from 'react';
import { ApplicationInstalledItem } from './ApplicationInstalledItem';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

const AppInstalledList_ = ({
  classes,
  appList,
  appActivesList,
  handleOpenModal,
  history,
}) => {
  return (
    <Grid component="ul" container className={classes.appsInstalledContainer}>
      {appList.map((app, key) => {
        if (appActivesList.includes(app.app_id)) {
          return (
            <ApplicationInstalledItem
              key={key}
              app={app}
              history={history}
              handleOpenModal={() =>
                handleOpenModal({
                  appId: app.app_id,
                  isInstall: false,
                })
              }
            />
          );
        }

        return null;
      })}
    </Grid>
  );
};

const styles = () => ({
  appsInstalledContainer: {
    flexDirection: 'column',
    padding: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  },
});

export const AppInstalledList = withStyles(styles)(AppInstalledList_);
