/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid, Icon, Typography } from '@mui/material';
import {
  ModalUI,
  SelectUI,
  ToggleUI,
  InputUI,
  DateUI,
  ModuleCardTitleUI,
} from '../../../../../components/common';

const ModalAltaEnvioOca = (props) => {
  let {
    alta_envio_oca_imprimir,
    handleSubmit,
    handleChange,
    handleChangeSelect,
    handleChangeDate,
    modalHandler,
    modal_state,
    form_state,
    metodos_envio,
    selects,
    venta,
  } = props;

  let tiene_retiro_domicilio = metodos_envio.me_oca_operativa_pp ? true : false;

  return (
    <ModalUI
      open={modal_state}
      id="alta_envio_oca_imprimir"
      title="Dar de alta envío por OCA"
      modalHandler={modalHandler}
      aditional_param="alta_envio_oca_imprimir"
      handleSubmit={handleSubmit}
      button_label="Dar alta envío"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={alta_envio_oca_imprimir.aoca_nro_remito}
          />
        </Grid>

        <ToggleUI show={tiene_retiro_domicilio}>
          <Grid item xs={12}>
            <SelectUI
              data={alta_envio_oca_imprimir.aoca_tipo_envio}
              options={selects.aoca_tipo_envio}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </ToggleUI>

        <ToggleUI show={alta_envio_oca_imprimir.aoca_tipo_envio.value === 1}>
          <Grid item xs={12}>
            <SelectUI
              data={alta_envio_oca_imprimir.aoca_sucursal_entrega}
              options={selects.aoca_sucursal_entrega}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <DateUI
              handleChangeDate={handleChangeDate}
              inputdate={alta_envio_oca_imprimir.aoca_fecha_entrega}
              disablePast={true}
            />
          </Grid>
        </ToggleUI>

        <ToggleUI show={alta_envio_oca_imprimir.aoca_tipo_envio.value === 0}>
          <Grid item xs={12}>
            <ModuleCardTitleUI
              title="Retiro a domicilio (importante)"
              subtitles={[
                <span style={{ display: 'block' }}>
                  <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> El
                  servicio de retiro en domicilio puede aplicar un costo
                  adicional, contacte a su ejecutivo OCA para conocerlo.
                </span>,
              ]}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <SelectUI
              data={alta_envio_oca_imprimir.aoca_franja_horaria_retiro}
              options={selects.aoca_franja_horaria_retiro}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DateUI
              handleChangeDate={handleChangeDate}
              inputdate={alta_envio_oca_imprimir.aoca_fecha_retiro}
              dateProps={{
                disablePast: true,
              }}
            />
          </Grid>
        </ToggleUI>
        <Grid item xs={12}>
          <ModuleCardTitleUI
            title="Tipo de embalaje (importante)"
            subtitles={[
              <span
                style={{
                  display: 'block',
                  marginBottom: '10px',
                }}
              >
                <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> El
                costo de envío en la tienda se calcula con las dimensiones de
                cada producto en particular. En caso que tengas que enviar más
                de un producto y decidas enviarlos todos juntos en un mismo
                paquete, te recomendamos que sea lo más compacto posible y no
                queden espacios libres. De lo contrario podría haber una
                diferencia de precio entre el que abonó el cliente y el que
                finalmente tengas que pagarle a OCA, teniéndote que hacer cargo
                de esa diferencia.
              </span>,
              <span style={{ display: 'block' }}>
                <Icon style={{ fontSize: 'inherit' }}>chevron_right</Icon> Con
                cualquiera de las dos opciones es un único envío, con un único
                número de seguimiento y un único costo. Si envías cada producto
                en su paquete individual se genera una etiqueta por paquete, si
                los envías todos juntos se genera una sola etiqueta porque es un
                solo paquete.
              </span>,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectUI
            data={alta_envio_oca_imprimir.aoca_embalaje}
            options={selects.aoca_embalaje}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>

        <ToggleUI show={alta_envio_oca_imprimir.aoca_alta_finalizada.value}>
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                padding: '20px',
                boxSizing: 'border-box',
              }}
            >
              <Typography>
                <ToggleUI
                  show={venta.v_envio_etiqueta_adicional ? true : false}
                >
                  El envío ya ha sido de alta en OCA y puedes imprimir la
                  etiqueta y la orden de admisión desde el botón "ACCIONES" o
                  haciendo click{' '}
                  <a
                    href={venta.v_etiqueta_envio}
                    target="_blank"
                    style={{ color: 'inherit' }}
                  >
                    <u>aquí</u>
                  </a>{' '}
                  y{' '}
                  <a
                    href={venta.v_envio_etiqueta_adicional}
                    target="_blank"
                    style={{ color: 'inherit' }}
                  >
                    <u>aquí</u>
                  </a>
                  .
                </ToggleUI>
                <ToggleUI
                  show={venta.v_envio_etiqueta_adicional ? false : true}
                >
                  El envío ya ha sido de alta en OCA y puedes imprimir la
                  etiqueta desde el botón "ACCIONES" o haciendo click{' '}
                  <a
                    href={venta.v_etiqueta_envio}
                    target="_blank"
                    style={{ color: 'inherit' }}
                  >
                    <u>aquí</u>
                  </a>
                  .
                </ToggleUI>
              </Typography>
            </div>
          </Grid>
        </ToggleUI>
      </Grid>
    </ModalUI>
  );
};

export { ModalAltaEnvioOca };
