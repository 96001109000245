import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  tiendaObtener,
  tiendaConfigurarAlertaEnvio,
  metodosenvioObtener,
  metodoenvioActivarAcordar,
  metodoenvioDesactivarAcordar,
} from '../../store/actions';
import {
  AlertUI,
  CardModuleUI,
  ModuleCardTitleUI,
  InputUI,
  ToggleUI,
} from '../../components/common';
import {
  Acordar,
  OCA,
  MercadoEnvios,
  EnviosPersonalizados,
  RetiroLocal,
  CorreoArgentino,
  EPick,
  Emprepack,
} from './subcomponents';
import { Validate, Regex, RegexExtra } from '../../other';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../components/amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import ButtonMui from '../../components/common/ButtonMui';

class MetodosEnvio_ extends Component {
  state = {
    loading: {
      tienda: true,
      metodos_envio: true,
      error: false,
    },
    alerta_envio: {
      t_alerta_envio_mensaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje',
        id: 't_alerta_envio_mensaje',
        name: 't_alerta_envio_mensaje',
        change_param: 'alerta_envio',
        regex: Regex.DESCRIPCION_20_500,
        value: '',
        default_value: '',
        placeholder: 'Mensaje que quieras decirle a tus compradores',
        messages: {
          error: 'Por favor, ingrese una alerta válida',
          error_extra: RegexExtra.DESCRIPCION_20_500,
        },
      },
    },
    forms: {
      activar_acordar: false,
      desactivar_acordar: false,
      alerta_envio: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      this.setState((prevState) => ({
        loading: {
          ...prevState.loading,
          metodos_envio: false,
          error: err ? true : false,
        },
      }));
    });

    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            tienda: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          this.state.alerta_envio,
          (alerta_envio_obj) => {
            this.setState((prevState) => ({
              alerta_mensaje: alerta_envio_obj,
              loading: {
                ...prevState.loading,
                tienda: false,
                error: false,
              },
            }));
          }
        );
      }
    });
  }

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleActivarAcordarSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        activar_acordar: true,
      },
    }));

    this.props.metodoenvioActivarAcordar((err, resp) => {
      this.setState(
        (prevState) => ({
          forms: {
            ...prevState.forms,
            activar_acordar: false,
          },
          alert: {
            ...prevState.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        }),
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'acuerdo', success: true }
            );

            if (this.props.idCountry === 'ARG') {
              braze.logCustomEvent('empretienda_metodos_envío_activar', {
                metodo_envio: 'acuerdo',
              });
            }
          } else {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'acuerdo', success: false }
            );
          }
        }
      );
    });
  };

  handleDesactivarAcordarSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        desactivar_acordar: true,
      },
    }));

    this.props.metodoenvioDesactivarAcordar((err, resp) => {
      this.setState((prevState) => ({
        forms: {
          ...prevState.forms,
          desactivar_acordar: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      }));
      if (!err) {
        createAmplitudeEventWithDevice(
          method_shipment_amplitude_events.metodos_envio_desactivar.event_name,
          { metodo_envio: 'acuerdo' }
        );
      }
    });
  };

  handleSubmitAlertaEnvio = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        alerta_envio: true,
      },
    }));

    let form = this.state.alerta_envio;

    if (Validate.validar_formulario(form)) {
      this.props.tiendaConfigurarAlertaEnvio(
        {
          t_alerta_envio_mensaje: form.t_alerta_envio_mensaje.value,
        },
        (err, resp) => {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              alerta_envio: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            alerta_envio: false,
          },
          alerta_envio: new_form,
        }));
      });
    }
  };

  render() {
    const { metodos_envio } = this.props;
    return (
      <WrapperLayout
        loading={this.state.loading.metodos_envio || this.state.loading.tienda}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardModuleUI>
              <form onSubmit={this.handleSubmitAlertaEnvio}>
                <Grid container spacing={3}>
                  <ModuleCardTitleUI
                    title="Aclaraciones sobre envíos"
                    subtitles={[
                      'Con esta opción podés avisarles a tus clientes que tus envíos pueden demorar. El mensaje aparecerá en el calculador de costos de envío de cada producto, en el carrito y en el checkout de tu tienda.',
                    ]}
                  />
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={this.handleChange}
                      input={this.state.alerta_envio.t_alerta_envio_mensaje}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonMui
                      type="submit"
                      variant="contained"
                      isLoading={!!this.state.forms.alerta_envio}
                    >
                      {this.props.tienda.t_alerta_envio
                        ? 'Ocultar mensaje'
                        : 'Mostrar mensaje'}
                    </ButtonMui>
                  </Grid>
                </Grid>
              </form>
            </CardModuleUI>
          </Grid>
          <CorreoArgentino metodos_envio={metodos_envio} />
          <ToggleUI show={metodos_envio.me_mercadoenvios === 1}>
            <MercadoEnvios metodos_envio={metodos_envio} />
          </ToggleUI>
          <EPick metodos_envio={metodos_envio} />
          <Emprepack metodos_envio={metodos_envio} />
          <OCA metodos_envio={metodos_envio} />
          <EnviosPersonalizados metodos_envio={metodos_envio} />
          <RetiroLocal metodos_envio={metodos_envio} />
          <Acordar
            metodos_envio={metodos_envio}
            forms={this.state.forms}
            handleDesactivarAcordarSubmit={this.handleDesactivarAcordarSubmit}
            handleActivarAcordarSubmit={this.handleActivarAcordarSubmit}
          />
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaConfigurarAlertaEnvio: (data, callback) =>
      dispatch(tiendaConfigurarAlertaEnvio(data, callback)),
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodoenvioActivarAcordar: (callback) =>
      dispatch(metodoenvioActivarAcordar(callback)),
    metodoenvioDesactivarAcordar: (callback) =>
      dispatch(metodoenvioDesactivarAcordar(callback)),
  };
};

const MetodosEnvioPage = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MetodosEnvio_)
);

export default MetodosEnvioPage;
