import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarFormularioContacto,
} from '../../store/actions';
import { Grid } from '@mui/material';
import { ButtonUI, AlertUI, ModuleTitleUI } from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const ContactFormPage_ = ({
  tienda,
  tiendaObtener,
  tiendaActualizarFormularioContacto,
}) => {
  const [loading, setLoading] = useState({
    formulario_contacto: true,
    error: false,
  });

  const [forms, setForms] = useState({
    formulario_contacto: false,
  });

  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    tiendaObtener((err, resp) => {
      setLoading({
        ...loading,
        formulario_contacto: false,
        error: err ? true : false,
      });
    });
  }, [tiendaObtener]);

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setForms({
      ...forms,
      formulario_contacto: true,
    });

    tiendaActualizarFormularioContacto(
      {
        t_formulario_contacto: tienda.t_formulario_contacto ? 0 : 1,
      },
      (err, resp) => {
        setForms({
          ...forms,
          formulario_contacto: false,
        });
        setAlert({
          ...alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        });
      }
    );
  };

  return (
    <WrapperLayout loading={loading.formulario_contacto} error={loading.error}>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Formulario de contacto"
            subtitles={[
              'En esta sección podés activar un formulario de contacto que aparecerá en el menú principal de tu tienda online.',
              'Las consultas que envíen tus clientes desde el formulario te llegarán al email configurado en la sección de "Mi tienda" > "Información de mi tienda".',
            ]}
            button={
              <ButtonUI
                type="callf"
                label={tienda.t_formulario_contacto ? 'Desactivar' : 'Activar'}
                fullWidth={false}
                minHeight={true}
                onClickFunc={handleSubmit}
                isLoading={forms.formulario_contacto}
              />
            }
          />
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarFormularioContacto: (data, callback) =>
      dispatch(tiendaActualizarFormularioContacto(data, callback)),
  };
};

const ContactFormPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormPage_);

export default ContactFormPage;
