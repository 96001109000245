export default function getMessage({
  isDebtor,
  isTrialUser,
  isSuscribedAndNotAvailableCancel,
}) {
  const message = {
    title: '',
    subtitle: '',
    variant: 'Success',
    buttonVisible: false,
  };

  if (isDebtor) {
    message.variant = 'Danger';
    message.title = 'Tu tienda está desactivada por falta de pago';
    message.subtitle =
      'Te invitamos a reactivarla completando el pago de manera manual o por suscripción al débito automático.';
    message.buttonVisible = true;

    if (isSuscribedAndNotAvailableCancel) {
      message.variant = 'Success';
      message.title = 'Estamos procesando tu pago';
      message.subtitle =
        'Tu tienda se reactivará cuando ingrese el pago de tu suscripción, esto puede demorar hasta 48 horas.';
      message.buttonVisible = false;
    }
  } else {
    if (isSuscribedAndNotAvailableCancel) {
      if (isTrialUser) {
        message.title = '¡El débito automático se activó con éxito!';
        message.subtitle =
          'Para evitar errores, te sugerimos no modificar datos de tu suscripción hasta que se procese el pago. Esto puede demorar hasta 48 horas.';
      } else {
        message.title = 'Estamos procesando el pago de tu débito automático';
        message.subtitle =
          'Para evitar errores, te sugerimos no modificar datos de tu suscripción hasta que se procese el pago. Esto puede demorar hasta 48 horas.';
      }
    }
  }

  return message;
}
