import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { withStyles } from '@mui/styles';

const LinkText_ = (props) => {
  const {
    classes,
    text,
    type,
    url = null,
    aditionalParamFunc = null,
    onClickFunc = null,
  } = props;

  switch (type) {
    case 'callf':
      return (
        <Link
          className={classes.link_text}
          type={type}
          component="a"
          onClick={(e) => onClickFunc(e, aditionalParamFunc)}
        >
          {text}
        </Link>
      );

    case 'link':
      return (
        <Link
          className={classes.link_text}
          target="_blank"
          rel="noopener"
          type={type}
          component="a"
          href={url}
        >
          {text}
        </Link>
      );

    default:
      return <></>;
  }
};

const styles = (theme) => ({
  link_text: {
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: '18.75px',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.87rem',
      lineHeight: '16px',
    },
  },
});

LinkText_.propTypes = {
  //Function called on checkbox event
  // handleChangeCheckbox: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['callf', 'link']).isRequired,
  // //Reverts the option check (1 unchecked, 0 checked)
  // //Default: false (0 unchecked, 1 checked)
  // inverse: PropTypes.bool,
};

export const LinkText = withStyles(styles)(LinkText_);
