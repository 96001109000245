import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { tiendaObtener, tiendaActualizarMarket } from '../../store/actions';
import { Config, Validate } from '../../other';
import { Card, CardContent, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  CheckboxUI,
  ToggleUI,
  ModuleCardTitleUI,
} from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const EmpreshopsPage_ = ({ tienda, tiendaObtener, tiendaActualizarMarket }) => {
  const [marketState, setMarketState] = useState({
    validate: false,
    name: 't_market',
    label: 'Aparecer en Empreshops',
    value: 0,
    change_param: 'market',
  });
  const [loadingState, setLoadingState] = useState({
    market: true,
    error: false,
  });
  const [updateMarketLoading, setUpdateMarketLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  const theme = useTheme();
  const hasUserPaid = tienda.t_pago_manual || tienda.t_debito_automatico;

  useEffect(() => {
    tiendaObtener((err, resp) => {
      if (err) {
        setLoadingState((prevState) => ({
          ...prevState,
          market: false,
          error: true,
        }));
      } else {
        setMarketState((prevState) => ({
          ...prevState,
          value: resp.data.t_market,
        }));
        setLoadingState((prevState) => ({
          ...prevState,
          market: false,
          error: false,
        }));
      }
    });
  }, [tiendaObtener]);

  const handleChangeCheckbox = () => {
    setMarketState((prevState) => ({
      ...prevState,
      value: prevState['value'] ? 0 : 1,
    }));
  };

  const handleCloseAlert = () => {
    setAlertState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setUpdateMarketLoading(true);

    let form = marketState;

    if (Validate.validar_formulario(form)) {
      tiendaActualizarMarket(
        {
          t_market: form.value,
          t_market_ciudad_id: 1,
          t_market_categoria_id: [9],
        },
        (err, resp) => {
          let message = form.value
            ? 'Has activado Empreshops con éxito, recuerda ver el tutorial y configurar las categorías de Google para que aparezcan tus productos'
            : 'Tu tienda ha sido removida de Empreshops';
          if (err) {
            message = resp.message;
          }
          setUpdateMarketLoading(false);
          setAlertState((prevState) => ({
            ...prevState,
            open: true,
            message: message,
            type: err ? 'error' : 'success',
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setUpdateMarketLoading(false);
        setMarketState(new_form);
      });
    }
  };

  return (
    <WrapperLayout loading={loadingState.market} error={loadingState.error}>
      <AlertUI
        open={alertState.open}
        message={alertState.message}
        type={alertState.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <ModuleTitleUI
              image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/empreshops.png`}
              image_size="35px"
              subtitles={[
                <span>
                  <u>
                    <a
                      href="https://empreshops.com"
                      target="_blank"
                      style={{ color: 'inherit' }}
                      rel="noreferrer"
                    >
                      Empreshops.com
                    </a>
                  </u>{' '}
                  es una iniciativa desde Empretienda para darle mayor
                  visibilidad a las marcas que nos eligen y busca potenciar la
                  compra online a emprendimientos.
                </span>,
                <span>
                  Activando la función, tu tienda y productos aparecerán en los
                  resultados de{' '}
                  <u>
                    <a
                      href="https://empreshops.com"
                      target="_blank"
                      style={{ color: 'inherit' }}
                      rel="noreferrer"
                    >
                      empreshops.com
                    </a>
                  </u>
                </span>,
              ]}
              button={
                <ButtonUI
                  type="link"
                  label="Tutorial"
                  fullWidth={false}
                  link="https://blog.uala.com.ar/empretienda/empresale"
                  external_link
                  open_new_tab
                />
              }
            />
            <ToggleUI show={!hasUserPaid ? true : false}>
              <Card sx={theme.card.card_below}>
                <CardContent sx={theme.card.card_content_original}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title={
                        'Para poder aparecer en Empreshops, debes seleccionar un medio de pago.' +
                        (tienda.t_prueba
                          ? ' No vas a perder tus días de prueba.'
                          : '')
                      }
                    />
                    <Grid item xs={12}>
                      <ButtonUI
                        type="link"
                        label="Ver plan"
                        fullWidth={false}
                        link="/plan"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </ToggleUI>
            <ToggleUI show={hasUserPaid ? true : false}>
              <Card sx={theme.card.card} id="hola">
                <CardContent sx={theme.card.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Importante antes de activar"
                      subtitles={[
                        <span>
                          Antes de activar Empreshops es importante que agregues
                          el ID de Google en las categorías de tu tienda. Mira
                          cómo hacerlo haciendo clic{' '}
                          <a
                            href="https://blog.uala.com.ar/empretienda/empresale"
                            target="_blank"
                            style={{
                              textDecoration: 'underline',
                              color: 'inherit',
                            }}
                            rel="noreferrer"
                          >
                            aquí
                          </a>
                          .{' '}
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            De lo contrario no aparecerán tus productos en
                            Empreshops.
                          </span>
                        </span>,
                      ]}
                    />
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={handleChangeCheckbox}
                        checkbox={marketState}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  isLoading={updateMarketLoading}
                  minHeight={true}
                />
              </ModuleButtonBoxUI>
            </ToggleUI>
          </form>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarMarket: (callback, data) =>
      dispatch(tiendaActualizarMarket(callback, data)),
  };
};
const EmpreshopsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmpreshopsPage_);

export default EmpreshopsPage;
