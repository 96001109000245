import React from 'react';
import { withStyles } from '@mui/styles';

const InputLabel_ = ({ className, label }) => {
  return <label className={className}>{label}</label>;
};

const styles = () => ({
  label: {
    display: 'flex',
    padding: '16px 12px 12px 12px',
    maxHeight: '24px',
    borderRadius: '4px',
    border: '1px solid #565656',
  },
});

export const InputLabel = withStyles(styles)(InputLabel_);
