import React, { forwardRef } from 'react';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';
import { ToggleUI } from '../../../components/common';
import { useSelector } from 'react-redux';
import { SplitioUIFeatureFlag } from '../../../components/common/SplitioUI';
import { withStyles } from '@mui/styles';
import { processForTestId } from '../../../other/replaceString';

const AppMenuItemComponent_ = React.memo(
  ({
    classes,
    onClick,
    id,
    link,
    children,
    split = false,
    label,
    withMargin = false,
    withPadding = false,
    onlyArg = false,
    isExpandable = false,
    isParentMenu = false,
    amplitudeEvent = () => {},
  }) => {
    const idTienda = useSelector((state) => state.tienda.idTienda);
    const idCountry =
      useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

    const combinedClasses = `${withPadding ? classes.list_item_padding : ''} ${
      withMargin ? classes.list_item_margin : ''
    } ${
      isExpandable && !isParentMenu ? classes.selected_category_submenu : ''
    }  `;

    const activeClassName = classes.selected_category;

    if (!link || typeof link !== 'string') {
      return (
        <ListItem
          button
          data-testid={processForTestId(label)}
          className={combinedClasses}
          onClick={() => onClick(id, link)}
        >
          {children}
        </ListItem>
      );
    }

    return (
      <>
        {split ? (
          <ToggleUI show={idCountry !== 'COL'}>
            <SplitioUIFeatureFlag
              treatmentValue="on"
              customerId={idTienda}
              splitName="feature_flag_empretienda_panel_aplicaciones"
            >
              <ListItem
                button
                className={combinedClasses}
                onClick={() => amplitudeEvent()}
                data-testid={processForTestId(label)}
                component={forwardRef((props, ref) => (
                  <NavLink
                    exact
                    {...props}
                    innerRef={ref}
                    activeClassName={activeClassName}
                  />
                ))}
                to={link}
              >
                {children}
              </ListItem>
            </SplitioUIFeatureFlag>
          </ToggleUI>
        ) : (
          <>
            {onlyArg ? (
              <ToggleUI show={idCountry !== 'COL'}>
                <ListItem
                  button
                  className={combinedClasses}
                  onClick={() => amplitudeEvent()}
                  data-testid={processForTestId(label)}
                  component={forwardRef((props, ref) => (
                    <NavLink
                      exact
                      {...props}
                      innerRef={ref}
                      activeClassName={activeClassName}
                    />
                  ))}
                  to={link}
                >
                  {children}
                </ListItem>
              </ToggleUI>
            ) : (
              <ListItem
                button
                className={combinedClasses}
                onClick={() => amplitudeEvent()}
                data-testid={processForTestId(label)}
                component={forwardRef((props, ref) => (
                  <NavLink
                    exact
                    {...props}
                    innerRef={ref}
                    activeClassName={activeClassName}
                  />
                ))}
                to={link}
              >
                {children}
              </ListItem>
            )}
          </>
        )}
      </>
    );
  }
);

const styles = (theme) => ({
  list_item_margin: {
    marginTop: '0px',
    [theme.breakpoints.up('md')]: {
      marginTop: '50px',
    },
  },
  list_item_padding: {
    paddingLeft: '40px',
    '& span': {
      fontSize: '0.875rem',
    },
  },

  selected_category: {
    backgroundColor: '#F2F2F2',
    '& span': {
      fontWeight: '600',
    },
  },

  selected_category_submenu: {
    '&:focus': {
      backgroundColor: '#ffffff',
      '& span': {
        fontWeight: '600',
      },
    },
  },
});

export const AppMenuItemComponent = withStyles(styles)(AppMenuItemComponent_);
