import React, { createContext, useState, useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { InputUI, SelectUI, CheckboxUI } from '../components/common';
import inputDefaultValues from './data/inputDefaultValues.json';
import { Validate } from '../other';

const FormCtrlContext = createContext(null);

const FormCtrolProvider = (props) => {
  const [formState, setFormState] = useState({ dispatchErrors: 0 });

  return (
    <FormCtrlContext.Provider value={{ formState, setFormState }}>
      <Form {...props} />
    </FormCtrlContext.Provider>
  );
};

const Form = ({ children, onSubmit }) => {
  const { formState, setFormState } = useContext(FormCtrlContext);

  const dataIsMissing = () => {
    const formValues = Object.keys(formState)
      .map((name) => {
        if (name && name !== 'dispatchErrors') {
          const value = formState[name];

          if (value.value && value.value === 'select_default') {
            value.auth = false;
            value.validate = true;
          }
          return { name, value };
        }
        return null;
      })
      .filter((el) => el !== null);

    if (formValues?.length > 0) {
      const formObj = formValues.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {});

      if (Validate.validar_formulario(formObj)) {
        return false;
      } else {
        Validate.validar_formulario_actualizar_obj(formObj, (new_formObj) => {
          setFormState((currentState) => {
            return {
              ...currentState,
              ...new_formObj,
            };
          });
        });
      }
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let target = e.target;

    if (dataIsMissing()) {
      setFormState((currentState) => ({
        ...currentState,
        dispatchErrors: currentState.dispatchErrors + 1,
      }));
      target = null;
    }

    onSubmit(target);
  };

  return (
    <Grid component="form" onSubmitCapture={handleSubmit}>
      {children}
    </Grid>
  );
};

const Input = ({
  name,
  label,
  regex,
  disabled = false,
  msgError,
  helperText,
  placeholder,
  msgErrorExtra,
  required = true,
  defaultValue = '',
}) => {
  const { formState, setFormState } = useContext(FormCtrlContext);

  const [inputState, setInputState] = useState({
    ...inputDefaultValues,
    auth: required ? defaultValue !== '' : true,
    required: required,
    disabled: disabled,
    id: name,
    name: name,
    label: label,
    placeholder: placeholder,
    regex: regex,
    value: defaultValue,
    messages: {
      help: helperText,
      error: msgError,
      error_extra: msgErrorExtra,
    },
  });

  useEffect(() => {
    setFormState((currentState) => ({
      ...currentState,
      [name]: { ...inputState, value: defaultValue },
    }));
  }, []);

  useEffect(() => {
    if (formState.dispatchErrors) {
      handleBlur({
        target: { name: inputState.name, value: inputState.value },
      });
    }
  }, [formState.dispatchErrors]);

  const handleChange = ({ target: { name, value } }) => {
    setInputState({ ...inputState, value: value });

    setFormState((currentState) => ({
      ...currentState,
      [name]: { ...inputState, value: value },
    }));
  };

  const handleBlur = ({ target: { name, value } }) => {
    Validate.validate_input(
      { ...inputState, value: value },
      true,
      (newInput) => {
        setInputState(newInput);

        setFormState((currentState) => ({
          ...currentState,
          [name]: newInput,
        }));
      }
    );
  };

  return (
    <InputUI
      onBlur={handleBlur}
      onChange={handleChange}
      handleChange={() => null}
      input={inputState}
    />
  );
};

const Select = ({
  name,
  label,
  options,
  msgError,
  helperText,
  msgErrorExtra,
}) => {
  const { formState, setFormState } = useContext(FormCtrlContext);

  const [selectState, setSelectState] = useState({
    auth: true,
    validate: true,
    required: true,
    error: false,
    label: label,
    id: name,
    name: name,
    change_param: '',
    value: 'select_default',
    messages: {
      help: helperText,
      error: msgError,
      error_extra: msgErrorExtra,
    },
  });

  useEffect(() => {
    setFormState((currentState) => ({
      ...currentState,
      [name]: { ...selectState, value: 'select_default' },
    }));
  }, []);

  useEffect(() => {
    if (formState.dispatchErrors) {
      handleBlur({
        target: { name: selectState.name, value: selectState.value },
      });
    }
  }, [formState.dispatchErrors]);

  const handleChange = ({ target: { value, name } }) => {
    setSelectState({ ...selectState, value: value });

    setFormState((currentState) => ({
      ...currentState,
      [name]: { ...selectState, value: value },
    }));
  };

  const handleBlur = ({ target: { name, value } }) => {
    let newSelect;

    if (value === 'select_default') {
      newSelect = { ...selectState, error: true };
    } else {
      newSelect = { ...selectState, error: false };
    }

    setSelectState(newSelect);
    setFormState((currentState) => ({
      ...currentState,
      [name]: newSelect,
    }));
  };

  return (
    <SelectUI
      options={options}
      data={selectState}
      onChange={handleChange}
      onBlur={handleBlur}
      handleChangeSelect={() => null}
    />
  );
};

const Checkbox = ({ name, label, validate, initialValue = 0 }) => {
  const { setFormState } = useContext(FormCtrlContext);
  const [checkboxState, setCheckboxState] = useState({
    name: name,
    label: label,
    validate: validate,
    value: initialValue,
    change_param: '',
  });

  useEffect(() => {
    setFormState((currentState) => ({
      ...currentState,
      [name]: checkboxState,
    }));
  }, []);

  const handleChange = ({ target: { name } }) => {
    const checked = checkboxState.value;

    setCheckboxState({ ...checkboxState, value: checked ? 0 : 1 });
    setFormState((currentState) => ({
      ...currentState,
      [name]: { ...checkboxState, value: checked ? 0 : 1 },
    }));
  };

  return (
    <CheckboxUI checkbox={checkboxState} handleChangeCheckbox={handleChange} />
  );
};

/**
 * useForm es un custom hook que permite crear formularios con inputs,
 * selects de manera sencilla
 * @date 6/3/2024 - 21:14:58
 *
 * @returns {Form, Input, Select}
 */
const useForm = () => {
  return {
    Form: FormCtrolProvider,
    Input,
    Select,
    Checkbox,
  };
};

export default useForm;
