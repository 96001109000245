import React, { useState, useEffect } from 'react';
import { Grid, Button, Input, Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  ModuleTitleUI,
  CardModuleUI,
  ButtonUI,
  ToggleUI,
  AlertUI,
} from '../../../common';
import { connect } from 'react-redux';
import { LinkText, Page, SpinnerProgress, CardText } from '../../subcomponents';
import { Validate } from '../../../../other';
import { ExportarProductos } from '../ExportarProductos';
import { workbookBufferToRows } from '../workBookUtils';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../../amplitude';
import { ImportContainer, ImportText } from './styles';
import { useHistory } from 'react-router-dom';

const UploadSpreadsheet_ = ({
  productos,
  assignSpeadsheet,
  exportStart,
  exportFinish,
  categorias_flatten_jerarquico,
  startFormatValidation,
}) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [filename, setFilename] = useState(
    'Subí tu planilla de productos aquí'
  );
  const [showSpinnerProgress, setShowSpinnerProgress] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });
  const router = useHistory();

  const assignSpreadsheetHandler = async (ev) => {
    if (!(ev.target.files[0] && ev.target.files[0].type)) return;

    const fileType = ev.target.files[0].type;

    if (
      Validate.in_array(fileType, [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ])
    ) {
      const fileRows = ev.target.files;
      setFilename('Cargando archivo');
      setShowSpinnerProgress(true);

      const file = await fileRows?.[0]?.arrayBuffer();
      const { rows } = workbookBufferToRows(file);
      setFilename(fileRows[0].name);
      setIsFileUploaded(true);
      setShowSpinnerProgress(false);
      assignSpeadsheet(rows);
    } else {
      setAlert({
        open: true,
        type: 'error',
        message: 'Solo se permiten archivos .xlsx',
      });
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const hasProducts = () => {
    return productos.length > 0;
  };

  useEffect(() => {
    hasProducts();
  }, [productos]);

  return (
    <>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title={
              hasProducts()
                ? 'Agregar o editar productos'
                : 'Agregar nuevos productos'
            }
            button={
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                onClick={(e) => {
                  createAmplitudeEventWithDevice(
                    import_amplitude_events.ver_tutorial.event_name
                  );
                  const link =
                    'https://recursos.empretienda.com/importacion-masiva.pdf';
                  window.open(link, '_blank', 'noopener');
                }}
              >
                Tutorial
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <Grid container>
              <ToggleUI show={hasProducts()}>
                <Grid item>
                  <ExportarProductos
                    exportStart={exportStart}
                    exportFinish={exportFinish}
                    categorias_flatten_jerarquico={
                      categorias_flatten_jerarquico
                    }
                  />
                </Grid>
              </ToggleUI>
              <ToggleUI show={!hasProducts()}>
                <Grid item>
                  <ImportText as="p">
                    <LinkText
                      type="callf"
                      text="Descarga la planilla vacía"
                      onClickFunc={(e) => {
                        createAmplitudeEventWithDevice(
                          import_amplitude_events.descargar_planilla_vacia
                            .event_name,
                          { success: true }
                        );
                        const link =
                          'https://recursos.empretienda.com/planilla-importacion-productos-vacia.xlsx';
                        window.open(link, '_blank', 'noopener');
                      }}
                    />{' '}
                    para comenzar a agregar tus productos.
                  </ImportText>
                </Grid>
              </ToggleUI>
            </Grid>
            <ImportContainer>
              <Grid container alignItems="center" justifyContent="center">
                <ToggleUI show={!showSpinnerProgress}>
                  <Grid item xs={12}>
                    <label htmlFor="import-excel-file">
                      <Input
                        style={{ display: 'none' }}
                        type="file"
                        inputProps={{
                          accept:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }}
                        id="import-excel-file"
                        onChange={(e) => assignSpreadsheetHandler(e)}
                      />
                      <Button
                        variant="text"
                        size="large"
                        color="info"
                        component="span"
                        sx={{
                          marginTop: '2px',
                          fontSize: { xs: '0.87rem', md: '1rem' },
                        }}
                        fullWidth
                        startIcon={
                          isFileUploaded ? (
                            <DescriptionIcon
                              color="primary"
                              style={{ fontSize: '24px' }}
                            />
                          ) : (
                            <CloudUploadIcon />
                          )
                        }
                      >
                        {filename}
                      </Button>
                    </label>
                  </Grid>
                </ToggleUI>
                <ToggleUI show={showSpinnerProgress}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <SpinnerProgress />
                      <CardText text="Cargando archivo" />
                    </Box>
                  </Grid>
                </ToggleUI>
              </Grid>
            </ImportContainer>
            <Grid container>
              <Typography
                as="p"
                sx={{
                  fontWeight: '400',
                  lineHeight: '16px',
                  fontSize: '0.87rem',
                  marginTop: '1rem',
                }}
              >
                Formatos permitidos .xlsx.
              </Typography>
            </Grid>
          </CardModuleUI>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ marginTop: '36px', display: { xs: 'block', md: 'none' } }}>
            <ButtonUI
              fullWidth={true}
              type="callf"
              label="Importar productos"
              disabled={!isFileUploaded}
              onClickFunc={(e) => startFormatValidation()}
            />
          </Box>
          <Grid
            mt={1.5}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item sx={{ marginTop: { xs: '24px', md: '0' } }}>
              <Page firstvalue="1" lastvalue="de 4" />
            </Grid>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Grid item>
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  variant="text"
                  label="Volver"
                  onClickFunc={() => router.push('/productos')}
                />
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  label="Importar productos"
                  disabled={!isFileUploaded}
                  onClickFunc={(e) => startFormatValidation()}
                />
              </Grid>
            </Box>
            <Box
              sx={{ marginTop: '20px', display: { xs: 'block', md: 'none' } }}
            >
              <Grid item>
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  variant="text"
                  label="Volver"
                  onClickFunc={() => router.push('/productos')}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  productos: state.productos,
});
const mapDispatchToProps = null;

export const UploadSpreadsheet = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadSpreadsheet_);
