import { Chip } from '@mui/material';
import { styled } from '@mui/system';

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }) => ({
  //TODO: Estilo para resaltar chips - backgroundColor: active === 1 ? '#1976d2' : '#e0e0e0'
  marginRight: '8px',
  marginBottom: '8px',
  color: '#3A3A3A',
  fontSize: '14px',
}));
