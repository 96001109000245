import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

export const LinkUI = styled(Link)(({ color }) => ({
  //'&&' increases specificity
  '&&': {
    color: color ? color : '#1010EF',
    textDecoration: 'underline',
  },
}));
