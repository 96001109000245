import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Config } from '../other';

const useGetBrazeToken = (isTokenLoaded) => {
  return useQuery({
    refetchOnMount: false,
    queryKey: ['brazeToken'],
    enabled: !!isTokenLoaded,
    gcTime: 15 * 60 * 1000, // 15 minutes
    staleTime: 15 * 60 * 1000, // 15 minutes
    queryFn: async () => {
      const { data } = await axios.get(Config.BACKEND_ENDPOINT + '/braze');
      return data.data;
    },
  });
};

export default useGetBrazeToken;
