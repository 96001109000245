import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paginasObtenerPagina, paginasEditar } from '../../store/actions';
import { Card, CardContent, Grid } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  ToggleUI,
  NextActionsUI,
} from '../common';
import { withStyles } from '@mui/styles';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import NewQuillUI from '../common/NewQuillUI';

class PaginaEditar_ extends Component {
  state = {
    loading: {
      pagina: true,
      error: false,
    },
    editar: {
      idPaginas: {
        validate: false,
        value: null,
      },
      pa_titulo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Título',
        id: 'pa_titulo',
        name: 'pa_titulo',
        change_param: 'editar',
        regex: Regex.STRING_GENERICO_2_90,
        value: '',
        default_value: '',
        placeholder: 'Cómo comprar',
        messages: {
          error: 'Por favor, ingrese un título válido',
          error_extra: RegexExtra.STRING_GENERICO_2_90,
        },
      },
      pa_link: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link',
        id: 'pa_link',
        name: 'pa_link',
        change_param: 'editar',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: 'https://www.empretienda.com/',
        messages: {
          error: 'Por favor, ingrese un link válido',
          error_extra: RegexExtra.LINK,
        },
      },
      pa_tipo: {
        validate: false,
        value: 0,
      },
      pa_contenido: {
        validate: false,
        value: '',
      },
    },
    forms: {
      editar: false,
    },
    after: false,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    let idPaginas = Validate.trim_int(this.props.match.params.idPaginas);
    if (idPaginas > 0) {
      this.props.paginasObtenerPagina({ idPaginas: idPaginas }, (err, resp) => {
        if (err) {
          this.setState({
            loading: {
              pagina: false,
              error: true,
            },
          });
        } else {
          let editarState = this.state.editar;
          let pa_tipo = resp.data.pa_tipo;

          if (pa_tipo) {
            editarState = {
              ...editarState,
              pa_link: {
                ...editarState.pa_link,
                validate: true,
              },
            };
          }

          Validate.cargar_formulario_sin_nulos(
            resp.data,
            editarState,
            (editar_state) => {
              this.setState({
                loading: {
                  pagina: false,
                  error: false,
                },
                editar: editar_state,
              });
            }
          );
        }
      });
    } else {
      this.setState({
        loading: {
          pagina: false,
          error: true,
        },
      });
    }
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleChangeQuill = (value) => {
    this.setState((prevState) => ({
      editar: {
        ...prevState.editar,
        pa_contenido: {
          ...prevState.editar.pa_contenido,
          value: value,
        },
      },
    }));
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        editar: true,
      },
    }));

    let form = this.state.editar;

    if (Validate.validar_formulario(form)) {
      this.props.paginasEditar(
        {
          idPaginas: form.idPaginas.value,
          pa_titulo: form.pa_titulo.value,
          pa_link: form.pa_link.value,
          pa_contenido: form.pa_contenido.value,
        },
        (err, resp) => {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              editar: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            after: err ? false : true,
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            editar: false,
          },
          editar: new_form,
        }));
      });
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout
        loading={this.state.loading.pagina}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container>
          <ToggleUI show={!this.state.after}>
            <Grid item xs={12}>
              <ModuleTitleUI title="Editar página" />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.editar.pa_titulo}
                      />
                    </Grid>
                    <ToggleUI show={!this.state.editar.pa_tipo.value}>
                      <Grid item xs={12}>
                        <NewQuillUI
                          defaultValue={this.state.editar.pa_contenido.value}
                          handleChangeQuill={this.handleChangeQuill}
                          placeholder={'Escriba aquí...'}
                        />
                      </Grid>
                    </ToggleUI>
                    <ToggleUI show={this.state.editar.pa_tipo.value}>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.editar.pa_link}
                        />
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="callf"
                  label="Guardar"
                  minHeight={true}
                  onClickFunc={(e) => this.handleSubmit(e)}
                  isLoading={this.state.forms.editar}
                />
              </ModuleButtonBoxUI>
            </Grid>
          </ToggleUI>
        </Grid>
        <ToggleUI show={this.state.after}>
          <NextActionsUI
            title="¡La página ha sido editada con éxito!"
            buttons={[
              <ButtonUI type="link" label="Ver mis páginas" link="/paginas" />,
            ]}
          />
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    paginasObtenerPagina: (data, callback) =>
      dispatch(paginasObtenerPagina(data, callback)),
    paginasEditar: (data, callback) => dispatch(paginasEditar(data, callback)),
  };
};

export const PaginaEditar = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PaginaEditar_)
);
