/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const dominioReducer = (state = initState, action) => {
  let dominio;
  switch (action.type) {
    case Reducers.DOMINIO_GUARDAR:
      return action.data;
    case Reducers.DOMINIO_EDITAR_REGISTROS:
      let zona = action.data;
      dominio = state.map((dominio) => {
        if (dominio.d_nombre === zona.d_nombre) {
          return {
            ...dominio,
            d_metadata: {
              ...dominio.d_metadata,
              dns_zone: {
                ...dominio.d_metadata.dns_zone,
                records: zona.records,
              },
            },
          };
        } else {
          return dominio;
        }
      });
      return dominio;
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { dominioReducer };
