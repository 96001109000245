import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../common';

const ModalDesactivarActivar = (props) => {
  let {
    desactivar_activar,
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="desactivar_activar"
      title={
        desactivar_activar.cd_desactivado ? 'Activar cupón' : 'Desactivar cupón'
      }
      modalHandler={modalHandler}
      aditional_param="desactivar_activar"
      handleSubmit={handleSubmit}
      button_label={
        desactivar_activar.cd_desactivado ? 'Activar' : 'Desactivar'
      }
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>
            ¿Estás seguro de que quieres{' '}
            {desactivar_activar.cd_desactivado ? 'activar' : 'desactivar'} el
            cupón de descuento "{desactivar_activar.cd_codigo}"?{' '}
            {desactivar_activar.cd_desactivado
              ? 'El cupón podrá volver a ser usado'
              : 'El cupón no podrá ser usado mientras esté desactivado'}
            .
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalDesactivarActivar };
