import React, { useState } from 'react';
import {
  Grid,
  Avatar,
  ListItemText,
  Icon,
  ListItemAvatar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { AlertUI, ButtonUI, ToggleUI } from '../../common';
import InfoItem from './InfoItem';
import { connect, useSelector } from 'react-redux';
import { shopBillDownload } from '../../../store/actions';
import { useHistory } from 'react-router-dom';

const BillCardBody_ = ({
  show,
  bill,
  title,
  classes,
  component,
  iconAvatar,
  isSuscribed,
  isCardWithHeader,
  setOpenSetIdPayment,
  downloadBill,
}) => {
  const idCountry = useSelector((state) => state.tienda.Country_idCountry);
  const router = useHistory();
  const [isBillDownloadLoading, setIsBillDownloadLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleDownloadBill = async (id) => {
    if (bill.paga && !isBillDownloadLoading) {
      setIsBillDownloadLoading(true);
      await downloadBill(id, async (error, resp) => {
        if (error) {
          setIsBillDownloadLoading(false);
          setIsAlertOpen(true);
        } else {
          const blob = new Blob([resp], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Factura_${id}`);
          document.body.appendChild(link);
          link.click();
          // Cleanup
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setIsBillDownloadLoading(false);
        }
      });
    } else {
      router.push('/plan/metodo-pago');
    }
  };

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  if (Object.entries(bill).length > 0) {
    const validationTree = {
      ARG: {
        // Country
        1: {
          // pagado
          true: true, // suscripto
          false: true, // no suscripto
        },
        0: {
          // no pagado
          true: false, // suscripto
          false: true, // no suscripto
        },
      },
      COL: {
        // Country
        1: {
          // pagado
          true: false, // suscripto
          false: false, // no suscripto
        },
        0: {
          // no pagado
          true: false, // suscripto
          false: true, // no suscripto
        },
      },
    };

    const buttonShow =
      bill &&
      Object.keys(bill).length > 0 &&
      validationTree[idCountry][bill.paga][isSuscribed];

    return (
      <>
        <AlertUI
          open={isAlertOpen}
          message={'Ha ocurrido un error en la descarga'}
          type={'error'}
          handleCloseAlert={handleCloseAlert}
        />
        <Grid
          container
          alignItems="center"
          component={isCardWithHeader ? 'div' : component}
        >
          <ListItemAvatar>
            <Avatar
              classes={{
                root: classes.card_avatar,
              }}
            >
              <Icon classes={{ root: classes.avatar_icon }}>{iconAvatar}</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={title}
            classes={{ root: classes.list_text }}
            secondary={
              <InfoItem
                bill={bill}
                show={show}
                setOpenSetIdPayment={setOpenSetIdPayment}
              />
            }
          />
          <ToggleUI show={buttonShow}>
            <Grid
              className={classes.button_wrapper}
              item
              xs={12}
              md={12}
              lg={3}
            >
              <ButtonUI
                type="callf"
                fullWidth={true}
                disabled={
                  Boolean(bill.paga && !bill.facturada) || isBillDownloadLoading
                }
                label={bill.paga ? 'Descargar' : 'Pagar'}
                onClickFunc={() => handleDownloadBill(bill.id)}
              />
            </Grid>
          </ToggleUI>
        </Grid>
      </>
    );
  }

  return <></>;
};

const styles = (theme) => ({
  card_avatar: {
    width: '50px',
    height: '50px',
    background: '#53B755',
    marginRight: '14px',
  },
  avatar_icon: {
    fontSize: '28px',
  },
  button_wrapper: {
    marginTop: '24px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '0',
    },
  },
  list_text: {
    paddingRight: '0',
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    downloadBill: (data, callback) =>
      dispatch(shopBillDownload(data, callback)),
  };
};

export const BillCardBody = withStyles(styles)(
  connect(null, mapDispatchToProps)(BillCardBody_)
);
