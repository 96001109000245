import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { fontOptions } from '../jsonSelects';

export const BuilderWrapper = ({ children }) => {
  const google_fonts_link = `https://fonts.googleapis.com/css2${
    fontOptions.length
      ? '?' + fontOptions.map((font) => `family=${font.font_setting}`).join('&')
      : ''
  }&display=swap`;

  return (
    <>
      {fontOptions.length > 0 && (
        <Helmet>
          <link rel="stylesheet" href={google_fonts_link} />
        </Helmet>
      )}
      <Box display="flex">{children}</Box>
    </>
  );
};
