import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ModalUI } from '../../../../../components/common';

const ModalMessage = (props) => {
  let { message, modalHandler, modal_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="message"
      title="Mensaje"
      modalHandler={modalHandler}
      aditional_param="message"
      handleSubmit={(e) => {
        e.preventDefault();
        modalHandler(false, 'message', message.description);
      }}
      button_label="Cerrar"
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography>{message.description}</Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalMessage };
