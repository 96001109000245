import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const CardTitle_ = (props) => {
  const { classes, title } = props;

  return (
    <>
      <Typography
        gutterBottom
        variant="h5"
        component="h2"
        className={classes.card_header_title}
      >
        {title}
      </Typography>
    </>
  );
};
const styles = (theme) => ({
  card_header_title: {
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '21px',
    color: '#242424',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.12rem',
    },
  },
});

export const CardTitle = withStyles(styles)(CardTitle_);
