export const getCeresJson = (t_template_data) => {
  const { css, other, checkout, message, products_feed, products_detail } =
    t_template_data;

  return {
    id: 1,
    css: css,
    body: {
      background: '#ffffff',
    },
    fonts: {
      text: 30,
      title: 30,
    },
    other: {
      menu_type: 0,
      border_radius: 6,
      menu_label_products: other.menu_label_products,
      menu_label_wholesaler: other.menu_label_wholesaler,
    },
    popup: {
      html: '',
      link: '',
      text: '',
      type: 0,
      image: '',
      title: '',
      active: 0,
      button: '',
      visibility: 0,
      visibility_place: 0,
    },
    message: {
      link: message.link,
      text: message.text,
    },
    primary: {
      text: '#000000',
      background: '#000000',
      contrastText: '#ffffff',
    },
    checkout: {
      comments: checkout.comments,
      short_billing_form: checkout.short_billing_form,
      step_review: checkout.step_review,
    },
    secondary: {
      text: '#1c1c1c',
      background: '#1c1c1c',
      contrastText: '#ffffff',
    },
    products_feed: {
      filter: products_feed.filter,
      masonry: products_feed.masonry,
      order: products_feed.order,
      product_subtext: products_feed.product_subtext,
      product_subtext_cuotas: products_feed.product_subtext_cuotas,
      product_subtext_type: products_feed.product_subtext_type,
    },
    products_detail: {
      photo_display: products_detail.photo_display,
      photo_zoom: products_detail.photo_zoom,
    },
  };
};
