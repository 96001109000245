import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import amplitude from 'amplitude-js';
import brazeDummy from '../../__mocks__/brazeDummy';

const adminPromo = (usuario, callback) => {
  return () => {
    axios
      .put(
        Config.BACKEND_ENDPOINT +
          '/tienda/promo/' +
          usuario.t_email_hash +
          '/' +
          usuario.code,
        usuario
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const adminLogout = () => {
  if (localStorage.getItem('auth')) {
    localStorage.removeItem('auth');
  }
  return () => {
    amplitude.getInstance().setUserId(0);
  };
};

const authBrazeUser = (callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = brazeDummy.authBrazeUser();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/braze')
      .then((resp) => {
        // guardo en redux
        const brazeToken = resp.data.data;
        dispatch({ type: Reducers.AUTH_BRAZE_USER, data: brazeToken });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export { authBrazeUser, adminPromo, adminLogout };
