import React, { useState, useEffect } from 'react';
import { Grid, Input, Button, Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  ButtonUI,
  ModuleTitleUI,
  CardModuleUI,
  AlertUI,
  ToggleUI,
} from '../../common';
import { Page, SpinnerProgress, CardText } from '../subcomponents';
import { Validate } from '../../../other';
import { connect } from 'react-redux';
import { workbookBufferToRows } from './workBookUtils';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';

const SpreadsheetFormatErrors_ = ({
  handleRedirectToProducts,
  error_messages,
  asignarPlanilla,
  validarFormatoStart,
}) => {
  const [state, setState] = useState({
    importDisabled: true,
    filename: 'Subí tu planilla de productos aquí',
    icon: (
      <svg
        width="25"
        height="16"
        viewBox="0 0 25 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.85 6.04C19.17 2.59 16.14 0 12.5 0C9.61 0 7.1 1.64 5.85 4.04C2.84 4.36 0.5 6.91 0.5 10C0.5 13.31 3.19 16 6.5 16H19.5C22.26 16 24.5 13.76 24.5 11C24.5 8.36 22.45 6.22 19.85 6.04ZM14.5 9V13H10.5V9H7.5L12.5 4L17.5 9H14.5Z"
          fill="#242424"
        />
      </svg>
    ),
    show_spinner_progress: false,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });

  useEffect(() => {
    createAmplitudeEventWithDevice(
      import_amplitude_events.importar_producto_failed.event_name,
      import_amplitude_events.importar_producto_failed.event_params
    );
  }, []);

  const assignSpreadsheet = async (ev) => {
    if (!(ev.target.files[0] && ev.target.files[0].type)) return;
    const file_type = ev.target.files[0].type;

    if (
      Validate.in_array(file_type, [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ])
    ) {
      const file_rows = ev.target.files;
      setState((prevState) => ({
        ...prevState,
        filename: 'Cargando archivo',
        show_spinner_progress: true,
      }));

      const file = await file_rows?.[0]?.arrayBuffer();
      const { rows } = workbookBufferToRows(file);

      setState((prevState) => ({
        ...prevState,
        filename: file_rows[0].name,
        importDisabled: false,
        icon: (
          <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 18C0.5 19.1 1.39 20 2.49 20H14.5C15.6 20 16.5 19.1 16.5 18V6L10.5 0ZM12.5 16H4.5V14H12.5V16ZM12.5 12H4.5V10H12.5V12ZM9.5 7V1.5L15 7H9.5Z"
              fill="#53B755"
            />
          </svg>
        ),
        show_spinner_progress: false,
      }));

      asignarPlanilla(rows); //asignar la planilla al ciclo de vida
    } else {
      setState((prevState) => ({
        ...prevState,
        alert: {
          ...prevState.alert,
          open: true,
          type: 'error',
          message: 'Solo se permiten archivos .xlsx',
        },
      }));
    }
  };

  const startValidation = () => {
    validarFormatoStart(); //pasar a la siguiente etapa: validarPlanilla
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  return (
    <Grid item xs={12}>
      <AlertUI
        open={state.alert.open}
        message={state.alert.message}
        type={state.alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Errores encontrados"
            button={
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                onClick={() => {
                  createAmplitudeEventWithDevice(
                    import_amplitude_events.ver_tutorial.event_name
                  );
                  const link =
                    'https://recursos.empretienda.com/importacion-masiva.pdf';
                  window.open(link, '_blank', 'noopener');
                }}
              >
                Tutorial
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: { xs: '0.87rem', md: '1.25rem' },
                    lineHeight: { xs: '20px', md: '23px' },
                    color: '#000000',
                    marginBottom: { xs: '0px', md: '24px' },
                  }}
                >
                  Se detectaron{' '}
                  <Typography
                    fontSize={{ xs: '0.87rem', md: '1.25rem' }}
                    as="span"
                    color="#FF0000"
                  >
                    errores
                  </Typography>{' '}
                  en su planilla de productos
                </Typography>
                <Typography as="ul" p={0} m="20px 0px 40px 0px">
                  {error_messages.map((error, i) => (
                    <Typography
                      as="li"
                      sx={{
                        marginBottom: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '3px 0px',
                        textTransform: 'none',
                        fontSize: { xs: '0.87rem', md: '1rem' },
                        lineHeight: { xs: '16px', md: '18.75px' },
                      }}
                      key={i}
                    >
                      <Typography as="span" mr={{ xs: '5px', md: '10px' }}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                            fill="#FF0000"
                          />
                        </svg>
                      </Typography>
                      {error}
                    </Typography>
                  ))}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: '1px dashed #242424',
                    borderRadius: { xs: '10px 0px 0px 0px', md: '6px' },
                    padding: { xs: '75px 0px 75px 0px', md: '40px' },
                  }}
                >
                  <Grid container alignItems="center" justifyContent="center">
                    <ToggleUI show={!state.show_spinner_progress}>
                      <Grid item xs={12}>
                        <Typography as="label" htmlFor="import-excel-file">
                          <Input
                            style={{
                              display: 'none',
                            }}
                            inputProps={{
                              accept:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            }}
                            id="import-excel-file"
                            type="file"
                            onChange={(e) => assignSpreadsheet(e)}
                          />
                          <Button
                            variant="text"
                            size="large"
                            color="info"
                            component="span"
                            sx={{ marginTop: '2px' }}
                            fullWidth
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '3px 0px',
                                textTransform: 'none',
                                fontSize: { xs: '0.87rem', md: '1rem' },
                                lineHeight: { xs: '16px', md: '18.75px' },
                              }}
                            >
                              <Typography
                                as="span"
                                mr={{ xs: '5px', md: '10px' }}
                              >
                                {state.icon}
                              </Typography>
                              {state.filename}
                            </Box>
                          </Button>
                        </Typography>
                      </Grid>
                    </ToggleUI>
                    <ToggleUI show={state.show_spinner_progress}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <SpinnerProgress />
                          <CardText text="Cargando archivo" />
                        </Box>
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: '400',
                    lineHeight: '16px',
                    fontSize: '0.87rem',
                  }}
                >
                  Formatos permitidos .xlsx.
                </Typography>
              </Grid>
            </Grid>
          </CardModuleUI>
        </Grid>
        <Grid item xs={12}>
          <Box mt={'36px'} display={{ md: 'none', xs: 'block' }}>
            <ButtonUI
              fullWidth={true}
              type="callf"
              label="Importar productos"
              disabled={state.importDisabled}
              onClickFunc={(e) => startValidation()}
            />
          </Box>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item mt={{ xs: '24px', md: 'unset' }}>
              <Page firstvalue="1" lastvalue="de 4" />
            </Grid>
            <Box display={{ xs: 'none', md: 'block' }}>
              <Grid item>
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  variant="text"
                  label="Volver"
                  onClickFunc={(e) => handleRedirectToProducts()}
                />
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  label="Importar productos"
                  disabled={state.importDisabled}
                  onClickFunc={(e) => startValidation()}
                />
              </Grid>
            </Box>
            <Box mt={'20px'} display={{ md: 'none', xs: 'block' }}>
              <Grid item>
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  variant="text"
                  label="Volver"
                  onClickFunc={() => handleRedirectToProducts()}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    productos: state.productos,
  };
};
const mapDispatchToProps = null;

export const SpreadsheetFormatErrors = connect(
  mapStateToProps,
  mapDispatchToProps
)(SpreadsheetFormatErrors_);
