/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  CardModuleUI,
  ButtonUI,
  SelectUI,
  CheckboxUI,
  InputUI,
} from '../../common';

const DominioSetupLoader = ({ text, type = 0, percent = 0 }) => {
  if (type === 0) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Typography variant="body2" align="center" gutterBottom>
          {text}
        </Typography>
        <LinearProgress />
      </div>
    );
  } else {
    return (
      <div style={{ flexGrow: 1 }}>
        <Typography
          variant="body2"
          align="center"
          style={{ marginBottom: '20px' }}
          gutterBottom
        >
          {text}
        </Typography>
        <LinearProgress variant="determinate" value={percent} />
        <Typography
          variant="body2"
          align="center"
          style={{ marginTop: '5px' }}
          gutterBottom
        >
          {percent}%
        </Typography>
      </div>
    );
  }
};

const DominioSetupComprar = (props) => {
  let { classes, solicitar, selects, handleChangeStep, handleChangeSelect } =
    props;

  return (
    <Grid container>
      <Grid item xs={12} pb={3}>
        <Typography variant="body2" gutterBottom>
          En esta sección vas a poder configurar tu dominio propio.{' '}
          <strong>IMPORTANTE:</strong> en el caso que{' '}
          <strong>NO TENGAS COMPRADO UN DOMINIO PROPIO</strong> primero debes
          comprarlo como indicamos a continuación:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Comprá un dominio propio:
        </Typography>
        <Typography
          className={classes.step_text_wrapper}
          variant="body2"
          gutterBottom
        >
          1.
          <span className={classes.step_text}>
            Empretienda no vende ni registra dominios, si no tienes uno, puedes
            ver el punto 2, 3 y 4 para saber cómo obtenerlo.
          </span>
        </Typography>
        <Typography
          className={classes.step_text_wrapper}
          variant="body2"
          gutterBottom
        >
          2.
          <span className={classes.step_text}>
            No debes comprar dominios que posean acentos, caracteres especiales
            o la letra ñ ya que no podrán ser configurados. Por ejemplo: si tu
            marca utiliza la letra "ñ", al registrar el dominio reemplazala por
            la letra "n".
          </span>
        </Typography>
        <Typography
          className={classes.step_text_wrapper}
          variant="body2"
          gutterBottom
        >
          3.
          <span className={classes.step_text}>
            Podés comprar los dominios "tumarca.com.ar" desde el sitio{' '}
            <u>
              <a
                href="https://nic.ar/"
                className={classes.link}
                target="_blank"
              >
                nic.ar
              </a>
            </u>{' '}
            y este es el{' '}
            <u>
              <a
                href="https://nic.ar/es/ayuda/instructivos/registro-de-dominio"
                className={classes.link}
                target="_blank"
              >
                tutorial
              </a>
            </u>{' '}
            oficial para hacer el registro de manera correcta.
          </span>
        </Typography>
        <Typography
          className={classes.step_text_wrapper}
          variant="body2"
          gutterBottom
        >
          4.
          <span className={classes.step_text}>
            Podés comprar los dominios "tumarca.com", "tumarca.net" y otros
            desde varios sitios. Por ejemplo uno de ellos es:{' '}
            <u>
              <a
                href="https://ar.godaddy.com"
                className={classes.link}
                target="_blank"
              >
                godaddy.com
              </a>
            </u>
            .
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12} py={3}>
        <Typography variant="body2" gutterBottom>
          <strong>IMPORTANTE:</strong> no comiences el proceso si aún{' '}
          <strong>NO COMPRASTE TU DOMINIO</strong>. Primero compras tu dominio,
          ya sea en{' '}
          <u>
            <a href="https://nic.ar/" className={classes.link} target="_blank">
              nic.ar
            </a>
          </u>{' '}
          ó{' '}
          <u>
            <a
              href="https://godaddy.com/"
              className={classes.link}
              target="_blank"
            >
              godaddy.com
            </a>
          </u>{' '}
          ó donde prefieras y luego continuas el proceso.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Si ya compraste tu dominio en algún proveedor de dominios externo,
          continua desde acá:
        </Typography>
        <SelectUI
          data={solicitar.d_comprar}
          options={selects.d_comprar}
          handleChangeSelect={handleChangeSelect}
        />
      </Grid>
      <Grid item xs={12} pt={3}>
        <ButtonUI
          type="callf"
          label="Siguiente"
          onClickFunc={() => handleChangeStep(1)}
          fullWidth={false}
          disabled={solicitar.d_comprar.value === 0}
        />
      </Grid>
    </Grid>
  );
};

const DominioSetupIngresoDominio = (props) => {
  let {
    forms,
    classes,
    selects,
    solicitar,
    handleChange,
    handleChangeStep,
    handleChangeSelect,
    handleSolicitarDominioSubmit,
  } = props;

  let siguiente_cond =
    solicitar.d_empresa.value > 0 &&
    solicitar.d_nombre.auth &&
    solicitar.d_nombre_repeat.auth &&
    solicitar.d_nombre.value === solicitar.d_nombre_repeat.value;

  if (forms.solicitar) {
    return (
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <DominioSetupLoader text="Estamos preparando tu tienda, este proceso puede demorar hasta 1 minuto, por favor, espere..." />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <form onSubmit={handleSolicitarDominioSubmit}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Consideraciones antes de configurar el dominio:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Una vez iniciada la configuración, tenés un plazo de 10 días
                para concretarla. Luego de ese tiempo, la configuración expira y
                deberá ser reiniciada.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Si estas utilizando una cuenta de correo con tu dominio ej:
                hola@tumarca.com.ar (si no ignora este punto), debes tener en
                cuenta que durante el proceso de configuración y hasta que
                cargues los registros correspondientes a dicho correo, el mismo
                no va a funcionar. Recomendamos que configures otro correo en Mi
                tienda ${'-> '}
                General durante la configuración para no perder notificaciones.
                En el siguiente paso podrás acceder a un tutorial para cargar
                esta información y mantener el funcionamiento del mismo.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={solicitar.d_empresa}
              options={selects.d_empresa}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI handleChange={handleChange} input={solicitar.d_nombre} />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={solicitar.d_nombre_repeat}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <ButtonUI
                  type="callf"
                  label="Volver"
                  color="info"
                  variant="text"
                  onClickFunc={() => handleChangeStep(0)}
                  fullWidth={false}
                />
              </Grid>
              <Grid item>
                <ButtonUI
                  type="submit"
                  label="Siguiente"
                  fullWidth={false}
                  disabled={!siguiente_cond}
                  isLoading={forms.solicitar}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
};

const DominioSetupConfirmacionDNS = (props) => {
  let {
    setup,
    forms,
    classes,
    solicitar,
    confirmar_dns,
    selects,
    handleChangeSelect,
    handleChangeCheckbox,
    handleChequearDNSDominioSubmit,
  } = props;

  let d_empresa = {
    ...solicitar.d_empresa,
    label: 'Mostrar tutorial para el proveedor',
    messages: {
      error: '',
      help: '',
    },
  };

  let d_nombre = setup.dominio.d_nombre;
  let proveedor = solicitar.d_empresa.value;
  let proveedor_nombre = selects.d_empresa.filter(
    (empresa) => empresa.value === proveedor
  )[0].label;
  let tutorial_html = '';
  switch (proveedor) {
    //nic.ar
    case 1:
      tutorial_html = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Para iniciar la configuración hay que realizar los siguientes
              pasos siguiendo el tutorial oficial:{' '}
              <u>
                <a
                  href="https://nic.ar/es/ayuda/instructivos/delegacion-de-dominios"
                  className={classes.link}
                  target="_blank"
                >
                  https://nic.ar/es/ayuda/instructivos/delegacion-de-dominios
                </a>
              </u>{' '}
              <strong>
                (hacer hasta el punto 4.1 inclusive, no hacer el 4.2)
              </strong>
              .
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Las delegaciones a cargar son las siguientes{' '}
              <strong>
                (si tenés alguna delegación cargada en la tabla, debes borrarlas
                todas antes de cargar las nuevas)
              </strong>
              :
            </Typography>
            <ul
              style={{
                listStyleType: 'disc',
                marginBotton: '0px',
              }}
            >
              <li>
                <Typography variant="body2">ns1.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns2.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns3.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns4.empretienda.net</Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Una vez que llegues al punto 4.1, el procedimiento correcto para
              cargar las delgaciones es el siguiente:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Copiar la primer delegación (ns1.empretienda.net), si al pegar
                cada delegación la computadora/celular agrega "http://" al
                inicio, debes borrarlo.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Hacer click en "agregar nueva delegación".
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              3.
              <span className={classes.step_text}>
                Pegar el valor en el campo "host".
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              4.
              <span className={classes.step_text}>
                Hacer click en el icono de disquete para guardarla.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              5.
              <span className={classes.step_text}>
                Repetir los pasos del 1 al 4 con los 3 valores restantes
                (ns2.empretienda.net, ns3.empretienda.net y ns4.empretienda.net)
                hasta cargarlos todos.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              6.
              <span className={classes.step_text}>
                Una vez realizados todos esos pasos, debes hacer click en{' '}
                <strong>"EJECUTAR CAMBIOS"</strong>, si no se arrojaron errores
                todo esta correcto. Si estas recibiendo errores, debes volver a
                realizar el procedimiento (o verificar que quedaron guardadas
                volviendo a la tabla donde cargaste las 4 delegaciones) ya que
                muchas veces el sitio falla pero al repetir el proceso funciona.
                Si el problema persiste, podés comunicarte con el soporte de{' '}
                <u>
                  <a
                    href="https://nic.ar"
                    className={classes.link}
                    target="_blank"
                  >
                    nic.ar
                  </a>
                </u>{' '}
                (en la parte superior del sitio figura un botón de chat para
                comunicarte).
              </span>
            </Typography>
          </Grid>
        </Grid>
      );
      break;
    //godaddy
    case 2:
      tutorial_html = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Debes seguir los siguientes pasos para configurar tu dominio
              registrado en Godaddy:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Inicia sesión en tu cuenta de Godaddy:{' '}
                <u>
                  <a
                    href="https://sso.godaddy.com/"
                    className={classes.link}
                    target="_blank"
                  >
                    https://sso.godaddy.com/
                  </a>
                </u>
                .
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Tiene que haberte aparecido una lista de dominios, uno de ellos
                debería ser {d_nombre}. En la parte derecha del nombre de tu
                dominio debería aparecer un botón que dice{' '}
                <strong>"DNS"</strong>, debes hacer click ahí. En caso de no
                verlo, podés ingresar directamente al link:{' '}
                <u>
                  <a
                    href="https://account.godaddy.com/products"
                    className={classes.link}
                    target="_blank"
                  >
                    https://account.godaddy.com/products
                  </a>
                </u>
                .
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              3.
              <span className={classes.step_text}>
                El botón "DNS" te va a llevar a una nueva página. En dicha
                página vas a tener que bajar hasta encontrar la sección{' '}
                <strong>"Servidores de nombres"</strong> y debes hacer click en{' '}
                <strong>"Cambiar"</strong>.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              4.
              <span className={classes.step_text}>
                El botón "Cambiar" te va a llevar a una nueva página y debajo
                hay un botón que dice{' '}
                <strong>
                  "Introducir mis propios servidores de nombres (avanzado)"
                </strong>
                , debes hacer click ahí.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              5.
              <span className={classes.step_text}>
                Luego te aparecerán campos para completar, ahí vas a tener
                agregar las 4 direcciones DNS que se detallan debajo. También
                notar que hay solamente 2 campos para completar, vas a tener que
                hacer click en <strong>"Añadir servidor de nombres"</strong> dos
                veces para tener espacio suficiente para los otros 2:
              </span>
            </Typography>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <Typography variant="body2">ns1.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns2.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns3.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns4.empretienda.net</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      );
      break;
    //donweb
    case 3:
      tutorial_html = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Debes seguir los siguientes pasos para configurar tu dominio
              registrado en Donweb:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Inicia sesión en tu cuenta de Donweb:{' '}
                <u>
                  <a
                    href="https://micuenta.donweb.com/es-us/ingresar"
                    className={classes.link}
                    target="_blank"
                  >
                    https://micuenta.donweb.com/es-us/ingresar
                  </a>
                </u>
                .
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Una vez dentro, en el menú izquierdo, te va a aparecer un
                apartado que dice <strong>"Mis Servicios"</strong>. Click ahí y
                luego click en <strong>"Mis dominios"</strong>.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              3.
              <span className={classes.step_text}>
                El paso anterior te llevará a otra página, donde debe aparecer
                la lista de dominios que hayas comprado, busca el dominio{' '}
                <strong>{d_nombre}</strong> y hacé click en el botón que dice{' '}
                <strong>"Configuración"</strong>.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              4.
              <span className={classes.step_text}>
                El paso anterior te llevará a otra página, ahora nuevamente en
                el menú izquierdo, hacé click en <strong>"DNS"</strong>.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              5.
              <span className={classes.step_text}>
                El paso anterior te llevará a otra nueva página que tiene dos
                pestañas: <strong>"DNS"</strong> y{' '}
                <strong>"DNS Propios"</strong>, debes hacer click en{' '}
                <strong>"DNS"</strong> nuevamente.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              6.
              <span className={classes.step_text}>
                Luego aparecerá abajo un botón que dice{' '}
                <strong>"EDITAR"</strong>, hacé click ahí y te va a mostrar 5
                campos para editar. Tenés que borrar todos los DNS que hay
                cargados porque necesitás cargar los de tu tienda (va a quedar
                un campo vacío).
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              7.
              <span className={classes.step_text}>
                Cargá todos estos valores y luego guardá los cambios:
              </span>
            </Typography>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <Typography variant="body2">ns1.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns2.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns3.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns4.empretienda.net</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      );
      break;
    //hostinger
    case 4:
      tutorial_html = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Debes seguir los siguientes pasos para configurar tu dominio
              registrado en Hostinger:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Inicia sesión en tu cuenta de Hostinger:{' '}
                <u>
                  <a
                    href="https://www.hostinger.com.ar/cpanel-login"
                    className={classes.link}
                    target="_blank"
                  >
                    https://www.hostinger.com.ar/cpanel-login
                  </a>
                </u>
                .
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Una vez dentro, debes seguir el siguiente{' '}
                <u>
                  <a
                    href="https://support.hostinger.es/es/articles/2304646-como-cambiar-mis-nameservers-en-hostinger"
                    className={classes.link}
                    target="_blank"
                  >
                    tutorial
                  </a>
                </u>
                .
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              3.
              <span className={classes.step_text}>
                Los nameservers a cargar son los siguientes:
              </span>
            </Typography>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <Typography variant="body2">ns1.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns2.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns3.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns4.empretienda.net</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      );
      break;
    //otros
    default:
      tutorial_html = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Debes seguir los siguientes pasos para configurar tu dominio
              registrado en tu proveedor de dominio:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Inicia sesión en tu cuenta de tu proveedor.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Debes cambiar los DNS de tu dominio por los siguientes (si no
                sabes como hacer esto, te recomendamos que te comuniques con el
                soporte de dicho proveedor):
              </span>
            </Typography>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <Typography variant="body2">ns1.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns2.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns3.empretienda.net</Typography>
              </li>
              <li>
                <Typography variant="body2">ns4.empretienda.net</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      );
      break;
  }

  return (
    <CardModuleUI below header_title="Configuración DNS">
      <form onSubmit={handleChequearDNSDominioSubmit}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Antes de comenzar, es importante que:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Si actualmente estas usando cuenta de email de la forma{' '}
                <strong>algo@{d_nombre}</strong> (si no tenés actualmente, debes
                ignorar este paso), recomendamos que antes de realizar este paso
                cargues los registros del mismo para no perder su
                funcionamiento. Para saber cómo hacerlo, podés ver el siguiente{' '}
                <u>
                  <a
                    href="https://recursos.empretienda.com/vincula-tu-propio-email-personalizado.pdf"
                    className={classes.link}
                    target="_blank"
                  >
                    tutorial
                  </a>
                </u>
                .
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Tenés 10 días para realizar esta configuración de manera
                correcta. En caso contrario, la configuración expirará y deberás
                comenzar nuevamente.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              3.
              <span className={classes.step_text}>
                La única configuración disponible para dominios es mediante DNS,
                no utilizamos registro de tipo A con IPv4 fija.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Ahora debes configurar los DNS de Empretienda en tu dominio y es
              importante que:
            </Typography>
            <Typography variant="body2" gutterBottom>
              Si el proveedor que figura en el selector no es la que utilizaste
              para comprar tu dominio, debes cambiarlo para que te muestre el
              tutorial correspondiente.
            </Typography>
            <div style={{ marginBottom: '10px' }}></div>
            <SelectUI
              data={d_empresa}
              options={selects.d_empresa.filter(
                (empresa) => empresa.value !== 0
              )}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Tutorial de configuración DNS
              {proveedor !== 5 ? ' para ' + proveedor_nombre : ''}:
            </Typography>
            {tutorial_html}
            <Typography
              variant="body1"
              gutterBottom
              style={{ marginTop: '10px' }}
            >
              Luego de finalizar todo el proceso y no haber recibido errores,{' '}
              <strong>debes esperar aproximadamente 45-60 minutos</strong> y
              confirmar la configuración utilizando el botón que aparece debajo.
              Debes tener en cuenta que si luego de ese tiempo la confirmación
              falla, puede ser por 2 razones:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Debes esperar más tiempo y volver a confirmar, ya que el proceso
                de actualización puede incluso demorar hasta 48 horas.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Los DNS de Empretienda (ns1.empretienda.net,
                ns2.empretienda.net, ns3.empretienda.net y ns4.empretienda.net)
                no fueron cargados correctamente (por ejemplo: no borraste DNS
                viejos o no se guardaron correctamente). En dicho caso, te
                recomendamos revisar nuevamente el tutorial y asegurarte de que
                los 4 DNS queden guardados, luego esperar 45-60 minutos
                nuevamente y volver a confirmar.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CheckboxUI
              handleChangeCheckbox={handleChangeCheckbox}
              checkbox={confirmar_dns.confirmar_dns_leer}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonUI
              type="submit"
              label="Confirmar"
              fullWidth={false}
              disabled={confirmar_dns.confirmar_dns_leer.value === 0}
              isLoading={forms.confirmar_dns}
            />
          </Grid>
        </Grid>
      </form>
    </CardModuleUI>
  );
};

const DominioSetupRootAutomate = (props) => {
  let {
    status,
    status_hz,
    status_cert,
    status_web,
    d_nombre,
    reloadDomainStatus,
  } = props;

  let message = '';
  let step = null;
  let percent = 0;

  useEffect(() => {
    const interval = setInterval(reloadDomainStatus, 1000 * 60);
    return () => clearInterval(interval);
  }, [reloadDomainStatus]);

  if (
    status === 2 &&
    status_hz === 2 &&
    status_cert === 1 &&
    status_web === 0
  ) {
    step = 1;
    percent = 10;
  } else if (
    status === 2 &&
    status_hz === 2 &&
    status_cert === 2 &&
    status_web === 0
  ) {
    step = 2;
    percent = 20;
  } else if (
    status === 3 &&
    status_hz === 2 &&
    status_cert === 4 &&
    status_web === 0
  ) {
    step = 3;
    percent = 40;
  } else if (
    status === 4 &&
    status_hz === 2 &&
    status_cert === 4 &&
    status_web === 1
  ) {
    step = 4;
    percent = 65;
  } else if (
    status === 5 &&
    status_hz === 2 &&
    status_cert === 4 &&
    status_web === 2
  ) {
    step = 5;
    percent = 85;
  } else if (
    status === 7 &&
    status_hz === 2 &&
    status_cert === 4 &&
    status_web === 2
  ) {
    step = 6;
    percent = 95;
  } else {
    step = 6;
    percent = 95;
  }

  message = `Estamos realizando configuraciones necesarias para activar tu dominio, este proceso puede demorar algunas horas y tardar varios minutos en avanzar a la siguiente etapa. Te recomendamos no entrar al dominio hasta que te avisemos por email o espera hasta que este proceso finalice. Etapa ${step}/6.`;

  return (
    <CardModuleUI
      below
      header_title={'Estamos configurando ' + d_nombre}
      show={status <= 7 ? true : false}
      with_toggle
    >
      <DominioSetupLoader text={message} type={1} percent={percent} />
    </CardModuleUI>
  );
};

const DominioSetupConfirmacionSubdominioCNAME = (props) => {
  let {
    setup,
    forms,
    classes,
    confirmar_cname,
    handleChangeCheckbox,
    handleChequearCNAMESubdominioSubmit,
  } = props;

  let dominioArr = setup.dominio.d_nombre.split('.');
  dominioArr.shift();
  let dominio_root = dominioArr.join('.');
  let dominio = setup.dominio.d_metadata;

  return (
    <CardModuleUI below header_title="Verificación de dominio">
      <form onSubmit={handleChequearCNAMESubdominioSubmit}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Para configurar el subdominio {setup.dominio.d_nombre} en tu
              tienda, debes verificar que posees el dominio {dominio_root}:
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              1.
              <span className={classes.step_text}>
                Debes tener en cuenta que la verificación expira en 72hs, por lo
                tanto debes hacerla cuanto antes o deberas reiniciar todo el
                proceso.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              2.
              <span className={classes.step_text}>
                Para comenzar, debes ingresar en tu proveedor de hosting actual
                donde alojes el dominio {dominio_root}, luego debes dirigirte a
                la tabla de registros del mismo.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              3.
              <span className={classes.step_text}>
                En la lista debes agregar el siguiente registro (si no sabes
                como hacerlo, te recomendamos que contactes con el soporte de tu
                proveedor para que lo carguen por vos):
              </span>
            </Typography>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <Typography variant="body2">
                  <strong style={{ marginRight: '10px' }}>Tipo:</strong>
                  {dominio.certificate.validation.type}
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  <strong style={{ marginRight: '10px' }}>Nombre:</strong>
                  {dominio.certificate.validation.name}
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  <strong style={{ marginRight: '10px' }}>Valor:</strong>
                  {dominio.certificate.validation.value}
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  <strong style={{ marginRight: '10px' }}>TTL:</strong>
                  300
                </Typography>
              </li>
            </ul>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              4.
              <span className={classes.step_text}>
                Luego de cargar dicho registro, debes esperar al menos 2-3hs y
                luego hacer click en "VERIFICAR" que aparece debajo para obtener
                los últimos 2 registros.
              </span>
            </Typography>
            <Typography
              className={classes.step_text_wrapper}
              variant="body2"
              gutterBottom
            >
              5.
              <span className={classes.step_text}>
                Si la verificación falla, puede ser porque el registro fue
                agregado de manera incorrecta (recomendamos revisar que tanto el
                nombre, como tipo y valor del registro en tu tabla concidan con
                el dado anteriormente) o nuestro sistema aún no tomo tu
                configuración y debes esperar unos minutos más.
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CheckboxUI
              handleChangeCheckbox={handleChangeCheckbox}
              checkbox={confirmar_cname.confirmar_cname_leer}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonUI
              type="submit"
              label="Verificar"
              fullWidth={false}
              disabled={confirmar_cname.confirmar_cname_leer.value === 0}
              isLoading={forms.confirmar_cname}
            />
          </Grid>
        </Grid>
      </form>
    </CardModuleUI>
  );
};

const DominioSetup_ = (props) => {
  let {
    setup,
    forms,
    classes,
    solicitar,
    selects,
    //tutoriales,
    confirmar_dns,
    confirmar_cname,
    handleChange,
    handleChangeStep,
    handleChangeSelect,
    reloadDomainStatus,
    handleChangeCheckbox,
    handleSolicitarDominioSubmit,
    handleChequearDNSDominioSubmit,
    handleChequearCNAMESubdominioSubmit,
  } = props;

  let { comenzado, dominio, step } = setup;

  if (comenzado) {
    let { type, status, certificate, dns_zone, web } = dominio.d_metadata;
    let status_hz = dns_zone.status;
    let status_cert = certificate.status;
    let status_web = web.status;

    if (type === 0) {
      //dominio root
      if (
        status === 1 &&
        status_hz === 2 &&
        status_cert === 0 &&
        status_web === 0
      ) {
        //confirmacion dns
        return (
          <DominioSetupConfirmacionDNS
            setup={setup}
            forms={forms}
            confirmar_dns={confirmar_dns}
            classes={classes}
            solicitar={solicitar}
            selects={selects}
            handleChangeSelect={handleChangeSelect}
            handleChangeCheckbox={handleChangeCheckbox}
            handleChequearDNSDominioSubmit={handleChequearDNSDominioSubmit}
          />
        );
      } else {
        //op automaticas restantes
        return (
          <DominioSetupRootAutomate
            status={status}
            status_hz={status_hz}
            status_cert={status_cert}
            status_web={status_web}
            d_nombre={dominio.d_nombre}
            reloadDomainStatus={reloadDomainStatus}
          />
        );
      }
    } else {
      //subdominio
      if (
        status === 2 &&
        status_hz === 0 &&
        status_cert === 2 &&
        status_web === 0
      ) {
        return (
          <DominioSetupConfirmacionSubdominioCNAME
            setup={setup}
            forms={forms}
            classes={classes}
            confirmar_cname={confirmar_cname}
            handleChangeCheckbox={handleChangeCheckbox}
            handleChequearCNAMESubdominioSubmit={
              handleChequearCNAMESubdominioSubmit
            }
          />
        );
      } else {
        return '';
      }
    }
  } else {
    let child_content = '';
    switch (step) {
      case 0:
        child_content = (
          <DominioSetupComprar
            classes={classes}
            selects={selects}
            solicitar={solicitar}
            handleChangeStep={handleChangeStep}
            handleChangeSelect={handleChangeSelect}
          />
        );
        break;
      case 1:
        child_content = (
          <DominioSetupIngresoDominio
            forms={forms}
            classes={classes}
            selects={selects}
            solicitar={solicitar}
            handleChange={handleChange}
            handleChangeStep={handleChangeStep}
            handleChangeSelect={handleChangeSelect}
            handleSolicitarDominioSubmit={handleSolicitarDominioSubmit}
          />
        );
        break;
      default:
        child_content = '';
        break;
    }
    return (
      <CardModuleUI below header_title="Configuración de dominio propio">
        {child_content}
      </CardModuleUI>
    );
  }
};

const styles = (theme) => ({
  main_step_detail: {
    fontSize: '0.8rem',
  },
  step_text_wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  step_text: {
    marginLeft: '5px',
    display: 'inline-block',
  },
  link: {
    color: 'inherit',
  },
});

export const DominioSetup = withStyles(styles, { withTheme: true })(
  DominioSetup_
);
