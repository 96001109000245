import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { productoImportacionCrearChunks } from '../../../store/actions';

const EnviarChunks_ = (props) => {
  const {
    noOpAsync,
    chunk,
    chunks_index,
    chunks_cantidad,
    initial_completion_percentage,
    enviar_chunks_percentage,
    handleUpdateCompletionPercentage,
    handleChunkEnviado,
    productoImportacionCrearChunks,
  } = props;

  useEffect(() => {
    const handleChunkEnviadoAsync = async (err, resp) => {
      const completion_percentage =
        initial_completion_percentage +
        enviar_chunks_percentage * ((chunks_index + 1) / chunks_cantidad);
      await handleUpdateCompletionPercentage(completion_percentage);
      await noOpAsync();
      handleChunkEnviado(err, resp);
    };

    productoImportacionCrearChunks(chunk, handleChunkEnviadoAsync);
  }, []);
  return <></>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    productoImportacionCrearChunks: (data, callback) =>
      dispatch(productoImportacionCrearChunks(data, callback)),
  };
};

const mapStateToProps = null;
export const EnviarChunks = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnviarChunks_);
