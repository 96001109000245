import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Icon,
  Button,
  IconButton,
  Typography,
  SwipeableDrawer,
  Box,
} from '@mui/material';

import { Config, Validate } from '../../../../other';
import { exportToExcel } from '../../../../libs/exportToExcel';
import { SelectUI, ToggleUI, ButtonUI } from '../../../common';
import { productoObtenerProductosExportar } from '../../../../store/actions';

const es_iphone = Validate.is_iphone();

const filtroANivelDeStock = (productos, filtros) => {
  //filtro por cantidad de atributos
  const cant_maxima_atributos = 3;
  let productos_stock_filtrado = productos.filter((producto) =>
    producto.atributos.length <= cant_maxima_atributos ? true : false
  );

  //filtro por oferta
  productos_stock_filtrado = productos_stock_filtrado.map((producto) => {
    producto.stock = producto.stock.filter((stock) => {
      if (filtros.p_oferta === -1) return true; //no filtrar por oferta
      const con_oferta = stock.s_precio_oferta > 0;
      if (filtros.p_oferta === 0 && !con_oferta) return true;
      if (filtros.p_oferta === 1 && con_oferta) return true; //con oferta
      if (filtros.p_oferta === 2 && con_oferta && stock.s_oferta_fecha)
        return true; //oferta con fecha de finalización
      if (
        filtros.p_oferta === 3 &&
        con_oferta && //oferta con fecha de inicio y fin
        stock.s_oferta_fecha_inicio &&
        stock.s_oferta_fecha
      )
        return true;
      return false;
    });
    return producto;
  });
  //filtro por stock
  productos_stock_filtrado = productos_stock_filtrado.map((producto) => {
    producto.stock = producto.stock.filter((stock) => {
      if (filtros.estado_stock === -1) return true; //no filtrar por stock
      if (
        filtros.estado_stock === 0 &&
        stock.s_ilimitado === 0 &&
        stock.s_cantidad === 0
      )
        return true; //sin stock
      if (
        filtros.estado_stock === 1 &&
        stock.s_ilimitado === 0 &&
        stock.s_cantidad > 0
      )
        return true; //con stock
      if (filtros.estado_stock === 2 && stock.s_ilimitado === 1) return true; //con stock ilimitado
      return false;
    });
    return producto;
  });
  return productos_stock_filtrado;
};

const atributosAColumnas = (atributos, max_atributos) => {
  const columnas = atributos.map((atributo) => ({
    nombre: atributo.at_nombre, //Ej.:Talle
    valor: atributo.valor.vat_valor, //Ej:Mediano
  }));

  while (columnas.length < max_atributos)
    columnas.push({ nombre: '', valor: '' });
  return columnas;
};

const getExcelData = ({
  products,
  categories,
  showWholesale,
  showDescription,
}) => {
  let stock = [];

  const getCategory = (idCategory) =>
    categories.filter((cat) => cat.idCategorias === idCategory)[0];

  for (const product of products) {
    const tipo_venta_mayorista = product.p_tipo_venta >= 1;

    const category = getCategory(product.Categorias_idCategorias);

    stock.push(
      ...product.stock.map((stock) => {
        const stock_en_oferta = stock.s_oferta > 0;
        const atributos_columnas = atributosAColumnas(stock.valoratributo, 3);

        return {
          productName: product.p_nombre,
          stock:
            stock.s_ilimitado === 0
              ? Validate.number_format_excel(stock.s_cantidad)
              : '',
          sku: stock.s_sku === null || stock.s_sku === '' ? '' : stock.s_sku,
          price:
            product.p_mostrar_precio === 1
              ? stock.s_precio > 0
                ? Validate.number_format_excel(stock.s_precio)
                : ''
              : '',

          offerPrice: stock_en_oferta
            ? stock.s_precio_oferta > 0
              ? Validate.number_format_excel(stock.s_precio_oferta)
              : ''
            : '',
          ...(showWholesale
            ? {
                wholesalePrice: tipo_venta_mayorista
                  ? stock.s_precio_mayorista > 0
                    ? Validate.number_format_excel(stock.s_precio_mayorista)
                    : ''
                  : '',
              }
            : {}),

          atrName1: atributos_columnas[0].nombre,
          atrValue1: atributos_columnas[0].valor,
          atrName2: atributos_columnas[1].nombre,
          atrValue2: atributos_columnas[1].valor,
          atrName3: atributos_columnas[2].nombre,
          atrValue3: atributos_columnas[2].valor,
          categories: category.c_nombre_full,
          weight:
            stock.s_peso > 0 ? Validate.number_format_excel(stock.s_peso) : '',
          height:
            stock.s_alto > 0 ? Validate.number_format_excel(stock.s_alto) : '',
          width:
            stock.s_ancho > 0
              ? Validate.number_format_excel(stock.s_ancho)
              : '',
          depth:
            stock.s_profundidad > 0
              ? Validate.number_format_excel(stock.s_profundidad)
              : '',
          showAvailable: product.p_desactivado === 0 ? 'Si' : 'No',
          ...(showDescription
            ? {
                description: product.p_descripcion,
              }
            : {}),
          idProduct: product.idProductos,
          idStock: stock.idStock,
        };
      })
    );
  }

  return stock;
};

export const FilterSidenav_ = ({
  shop,
  type,
  filter,
  loading,
  selects,
  categories,
  exportStart,
  exportFinish,
  handleDrawer,
  limpiarFiltros,
  getExportProducts,
  obtenerParametros,
  handleChangeSelect,
  handleSubmitFilter,
  isFiltersDrawerOpen,
}) => {
  const [showExport, setShowExport] = useState(false);

  useEffect(() => {
    if (showExport) {
      const MAX_RECORDS = 50000;
      let message = '';

      getExportProducts(
        {
          limit: 1000,
          max_records: MAX_RECORDS,
          filtroANivelDeStock,
          filtros: obtenerParametros(),
        },
        (err, resp) => {
          if (err || resp.stock_count === 0) {
            message =
              'No se han encontrado resultados para los filtros seleccionados';
          } else if (resp.alcanzo_max_records) {
            message = `Superó el tamaño máximo de archivo (${MAX_RECORDS} registros). Por favor, utilice filtros para segmentar la información`;
          } else {
            handleExportProducts(resp.productos);
          }

          exportFinish(!!err, resp, {
            show: !!message,
            message: message,
          });
        }
      );

      setShowExport(false);
    }
  }, [showExport]);

  const handleExportProducts = (products) => {
    const showWholesale = shop.t_mayorista === 1;
    const showDescription = filter.incluir_descripcion.value === 1;
    const productSpreadsheetsData = getExcelData({
      products: products,
      categories: categories,
      showWholesale: showWholesale,
      showDescription: showDescription,
    });

    const date = Validate.now_format(Config.SHORTDATE_FORMAT_DASH);
    const configProducts = {
      filename: `Exportacion-productos-${date}`,
      sheetName: 'Productos',
      columns: [
        { label: 'Nombre', value: 'productName' },
        { label: 'Stock', value: 'stock' },
        { label: 'SKU', value: 'sku' },
        { label: 'Precio', value: 'price' },
        { label: 'Precio oferta', value: 'offerPrice' },
        ...(showWholesale
          ? [{ label: 'Precio mayorista', value: 'wholesalePrice' }]
          : []),
        { label: 'Nombre atributo 1', value: 'atrName1' },
        { label: 'Valor atributo 1', value: 'atrValue1' },
        { label: 'Nombre atributo 2', value: 'atrName2' },
        { label: 'Valor atributo 2', value: 'atrValue2' },
        { label: 'Nombre atributo 3', value: 'atrName3' },
        { label: 'Valor atributo 3', value: 'atrValue3' },
        { label: 'Categorías', value: 'categories' },
        { label: 'Peso', value: 'weight' },
        { label: 'Alto', value: 'height' },
        { label: 'Ancho', value: 'width' },
        { label: 'Profundidad', value: 'depth' },
        { label: 'Mostrar en tienda', value: 'showAvailable' },
        ...(showDescription
          ? [{ label: 'Descripción', value: 'description' }]
          : []),
        { label: 'IDProduct', value: 'idProduct' },
        { label: 'IDStock', value: 'idStock' },
      ],
    };

    exportToExcel(productSpreadsheetsData, configProducts);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={isFiltersDrawerOpen}
      onClose={() => handleDrawer(false, type)}
      onOpen={() => handleDrawer(true, type)}
      disableBackdropTransition={!es_iphone}
      disableDiscovery={es_iphone}
    >
      <Box
        sx={{
          width: '300px',
          padding: 2,
        }}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={0.5}>
              <Grid item>
                <IconButton
                  aria-label="Cancelar"
                  onClick={() => handleDrawer(false, type)}
                  size="large"
                >
                  <Icon>clear</Icon>
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {type === 'filtrar' ? 'Filtrar' : 'Exportar'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={filter.idCategorias}
              options={selects.categorias}
              handleChangeSelect={handleChangeSelect}
              value_atribute="idCategorias"
              label_atribute={es_iphone ? 'c_nombre_slash' : 'c_nombre'}
            />
          </Grid>
          <ToggleUI show={shop.t_mayorista}>
            <Grid item xs={12}>
              <SelectUI
                data={filter.p_tipo_venta}
                options={selects.p_tipo_venta}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </ToggleUI>
          <ToggleUI show={shop.t_productos_digitales && type !== 'exportar'}>
            <Grid item xs={12}>
              <SelectUI
                data={filter.p_producto_digital}
                options={selects.p_producto_digital}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </ToggleUI>
          <Grid item xs={12}>
            <SelectUI
              data={filter.estado_stock}
              options={selects.estado_stock}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={filter.p_oferta}
              options={selects.p_oferta}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={filter.dimensiones_configuradas}
              options={selects.dimensiones_configuradas}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectUI
              data={filter.orden}
              options={selects.orden}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <ToggleUI show={type === 'exportar' ? true : false}>
            <Grid item xs={12}>
              <SelectUI
                data={filter.incluir_descripcion}
                options={selects.incluir_descripcion}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </ToggleUI>
          <Grid item xs={12}>
            <ButtonUI
              type="callf"
              label={type === 'exportar' ? 'Exportar' : 'Aplicar filtros'}
              size="large"
              isLoading={
                type === 'exportar' ? loading.exportar : loading.productos
              }
              onClickFunc={
                type === 'exportar'
                  ? () => {
                      exportStart();
                      setShowExport(true);
                    }
                  : () => handleSubmitFilter(type)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={(args) => limpiarFiltros()}
              variant="outlined"
              size="large"
              sx={{ marginTop: '2px' }}
              fullWidth
            >
              <Box display="flex" alignItems="center" p="3px 0px">
                <Icon sx={{ marginRight: '10px' }}>delete</Icon>
                Limpiar filtros
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

const mapStateToProps = (state) => {
  return {
    shop: state.tienda,
    categories: state.categorias,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExportProducts: (data, callback) =>
      dispatch(productoObtenerProductosExportar(data, callback)),
  };
};

export const FilterSidenav = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSidenav_);
