import { styled } from '@mui/material/styles';
import LinearProgressMUI, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export const BorderLinearProgress = styled(LinearProgressMUI)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F2F2F2',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}));
