import React, { useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProductRow } from './ProductRow';
import { ProductRowMobile } from './ProductRowMobile';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../../../amplitude';
import { ColumnTitle } from './styles';

export const ConfirmChanges = ({
  handleGetNewProductsFiltered,
  handleGetEditedProductsFiltered,
}) => {
  const IterateProducts = (screen, mode) => {
    let products_filtered;

    if (mode === 'new') {
      products_filtered = handleGetNewProductsFiltered();
    } else {
      products_filtered = handleGetEditedProductsFiltered();
    }

    return products_filtered.map((chunk_product, index) => {
      return ProductWithStock(
        screen,
        chunk_product.p_nombre,
        chunk_product.categorias,
        chunk_product.stock,
        index
      );
    });
  };

  const ProductWithStock = (
    screen,
    product_name,
    category_name,
    chunk_stock,
    index
  ) => {
    if (screen === 'desktop') {
      return chunk_stock.map((stock_element, index) => {
        return (
          <ProductRow
            key={`stock-element-${index}`}
            product_name={product_name}
            category_name={category_name}
            stock_element={stock_element}
            index={index}
          />
        );
      });
    } else {
      return (
        <ProductRowMobile
          key={index}
          product_name={product_name}
          category_name={category_name}
          chunk_stock={chunk_stock}
        />
      );
    }
  };

  useEffect(() => {
    createAmplitudeEventWithDevice(
      import_amplitude_events.importar_producto_successfull.event_name,
      import_amplitude_events.importar_producto_successfull.event_params
    );
  }, []);

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'rgba(0, 182, 95, 0.05)',
                  borderRadius: '6px',
                }}
              >
                <Box>
                  <Typography
                    as="h2"
                    fontSize={{ xs: '1rem', md: '1.25rem' }}
                    color="#53B755"
                    fontWeight="bold"
                  >
                    Productos nuevos
                  </Typography>
                  <Typography as="span" fontSize="14px" color="#6C6C6C">
                    {handleGetNewProductsFiltered().length} PRODUCTOS
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: { xs: '8px 24px 24px', md: '0' },
                  overflowX: 'scroll',
                }}
              >
                <Table sx={{ display: { xs: 'none', md: 'block' } }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: { xs: '8px 24px 24px', md: '0' },
                          overflowX: 'scroll',
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <ColumnTitle>Nombre del producto</ColumnTitle>
                              <ColumnTitle>Atributos</ColumnTitle>
                              <ColumnTitle>Stock</ColumnTitle>
                              <ColumnTitle>Precio</ColumnTitle>
                              <ColumnTitle>Oferta</ColumnTitle>
                              <ColumnTitle>Precio Mayorista</ColumnTitle>
                              <ColumnTitle>Categorías</ColumnTitle>
                              <ColumnTitle>Dimensiones</ColumnTitle>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {IterateProducts('desktop', 'new')}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Box display={{ xs: 'block', md: 'none' }}>
                  {IterateProducts('mobile', 'new')}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: '20px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  background: 'rgba(249, 153, 9, 0.05)',
                  borderRadius: '6px',
                }}
              >
                <Box>
                  <Typography
                    as="h2"
                    fontSize={{ xs: '1rem', md: '1.25rem' }}
                    color="#F99909"
                    fontWeight="bold"
                  >
                    Productos editados
                  </Typography>
                  <Typography as="span" fontSize="14px" color="#6C6C6C">
                    {handleGetEditedProductsFiltered().length} PRODUCTOS
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: { xs: '8px 24px 24px', md: '0' },
                  overflowX: 'scroll',
                }}
              >
                <Table sx={{ display: { xs: 'none', md: 'block' } }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: { xs: '8px 24px 24px', md: '0' },
                          overflowX: 'scroll',
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <ColumnTitle>Nombre del producto</ColumnTitle>
                              <ColumnTitle>Atributos</ColumnTitle>
                              <ColumnTitle>Stock</ColumnTitle>
                              <ColumnTitle>Precio</ColumnTitle>
                              <ColumnTitle>Oferta</ColumnTitle>
                              <ColumnTitle>Mayorista</ColumnTitle>
                              <ColumnTitle>Categorías</ColumnTitle>
                              <ColumnTitle>Dimensiones</ColumnTitle>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {IterateProducts('desktop', 'edited')}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Box display={{ xs: 'block', md: 'none' }}>
                  {IterateProducts('mobile', 'edited')}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
