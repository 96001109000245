import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from './authActions';

const empretipsObtener = (data, callback) => {
  return (dispatch, _getState) => {
    const apiUrl = `${Config.CDN_APP_PERMISSIONS}/empretips/${data.idCountry}.json`;

    axios
      .get(apiUrl, { withCredentials: false })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export { empretipsObtener };
