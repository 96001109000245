import React, { useState } from 'react';
import { ModalUI } from '../../common';

import { connect, useSelector } from 'react-redux';
import { Config } from '../../../other';
import { ModalInstallAppBody } from './ModalInstallAppBody';
import {
  applications_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../amplitude';
import { aplicacionObtenerLink } from '../../../store/actions';
import { withStyles } from '@mui/styles';

const ModalInstallApp_ = ({
  app,
  open,
  classes,
  modalHandler,
  getApplicationLink,
  permissionsTitles,
}) => {
  const { app_id, name } = app;
  const [loading, setLoading] = useState(false);
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const urlLandingPage = `${Config.LANDINGPAGE_URL}${
    idCountry === 'COL' ? '/co' : ''
  }`;

  const handleSubmitModalInstallApp = (e) => {
    e.preventDefault();
    setLoading(true);

    getApplicationLink({ application_id: app_id }, (err, resp) => {
      if (!err && resp.data?.url) {
        createAmplitudeEventWithDevice(
          applications_amplitude_events.aplicaciones_acepta_permisos.event_name,
          {
            aplicacion: `(${name})`,
          }
        );

        window.open(resp.data.url, '_blank');
      }

      setLoading(false);
    });
  };

  return (
    <ModalUI
      id="modal-ecommapp-install"
      open={open}
      classes={{
        card_actions: classes.containerButtons,
        card_content: classes.cardContent,
        card_header_title: classes.cardHeaderTitle,
        card_header: classes.cardHeader,
      }}
      titleWithoutDivider={false}
      button_loading={loading}
      modalHandler={modalHandler}
      cancel_button_label="Cancelar"
      title="Permisos de la aplicación"
      button_label={'Aceptar e instalar'}
      handleSubmit={handleSubmitModalInstallApp}
    >
      <ModalInstallAppBody
        app={app}
        urlLandingPage={urlLandingPage}
        permissionsTitles={permissionsTitles}
      />
    </ModalUI>
  );
};

const styles = (theme) => ({
  cardHeader: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  containerButtons: {
    padding: '10px',
    '& div': { gap: '5px' },
  },
  cardHeaderTitle: {
    fontWeight: '500',
    fontSize: '1.25rem',
  },
  cardContent: {
    paddingTop: '40px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '24px',
    },
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationLink: (data, callback) =>
      dispatch(aplicacionObtenerLink(data, callback)),
  };
};

export const ModalInstallApp = withStyles(styles)(
  connect(null, mapDispatchToProps)(ModalInstallApp_)
);
