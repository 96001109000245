import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { Config } from '../../other';
import 'moment/locale/es';
import moment from 'moment';

const DateUI_ = (props) => {
  let { handleChangeDate, inputdate, disablePast = false } = props;
  let { momentobj, label, name, change_param } = inputdate;

  return (
    <LocalizationProvider
      localeText={{
        cancelButtonLabel: 'Cancelar',
        okButtonLabel: 'Aceptar',
        invalidLabel: 'Por favor, ingrese una fecha válida',
        invalidDateMessage: 'Por favor, ingrese una fecha válida',
      }}
      dateAdapter={AdapterMoment}
      adapterLocale="es"
    >
      <DatePicker
        fullWidth
        margin="dense"
        value={momentobj}
        variant="outlined"
        onChange={(newmomentobj) =>
          handleChangeDate(newmomentobj, name, change_param)
        }
        label={label}
        format={Config.SHORTDATE_FORMAT}
        minDate={disablePast ? moment() : moment([2017, 0, 1])}
        maxDate={moment().add(10, 'years')}
        sx={{ width: '100%' }}
      />
    </LocalizationProvider>
  );
};

export const DateUI = DateUI_;
