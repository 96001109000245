import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

const SalesTableHead = ({ classes }) => {
  return (
    <TableHead sx={{ backgroundColor: '#F2F2F2' }}>
      <TableRow sx={{ fontSize: '13px' }}>
        <TableCell align="left" className={classes.table_cell_title}>
          Orden
        </TableCell>
        <TableCell
          align="left"
          sx={{ minWidth: 72 }}
          className={classes.table_cell_title}
        >
          Productos
        </TableCell>
        <TableCell
          align="left"
          sx={{ minWidth: 204 }}
          className={classes.table_cell_title}
        >
          Cliente
        </TableCell>
        <TableCell align="left" className={classes.table_cell_title}>
          Fecha
        </TableCell>
        <TableCell
          align="left"
          sx={{ minWidth: 160 }}
          className={classes.table_cell_title}
        >
          Estado del pago
        </TableCell>
        <TableCell
          align="left"
          sx={{ minWidth: 160 }}
          className={classes.table_cell_title}
        >
          Estado del envío
        </TableCell>
        <TableCell
          align="left"
          sx={{ minWidth: 160 }}
          className={classes.table_cell_title}
        >
          Monto
        </TableCell>
        <TableCell
          sx={{ textAlign: 'center' }}
          className={classes.table_cell_title}
        >
          Archivar
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SalesTableHead;
