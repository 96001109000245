import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Grid, List, ListItem, Avatar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Config, Validate } from '../../../other';
import { ToggleUI } from '../../common';

const getTitle = (track_subtype, track_data) => {
  let one_entity = track_data.entities.length === 1;

  switch (track_subtype) {
    case 1:
      return `Creaste una oferta masiva a ${
        one_entity ? 'una categoría' : 'varias categorías'
      }`;
    case 2:
      return `Creaste una oferta ${
        one_entity ? 'a un producto' : ' masiva a varios productos'
      }`;
    case 3:
      return `Borraste una oferta masiva de ${
        one_entity ? 'una categoría' : 'varias categorías'
      }`;
    case 4:
      return `Borraste una oferta ${
        one_entity ? 'a un producto' : ' masiva a varios productos'
      }`;
    default:
      break;
  }
};

const TrackOfferType = ({ track_data, classes }) => {
  switch (track_data.metadata.product_offer) {
    case 1:
      return (
        <Typography className={classes.list_item_subtitle}>
          <span className={classes.detail_title}>Tipo de oferta:</span> Oferta
          por tiempo ilimitado
        </Typography>
      );

    case 2:
      return (
        <Fragment>
          <Typography className={classes.list_item_subtitle}>
            <span className={classes.detail_title}>Tipo de oferta:</span> Oferta
            con fecha de finalización
          </Typography>
          <Typography className={classes.list_item_subtitle}>
            <span className={classes.detail_title}>Fecha de finalización:</span>{' '}
            {Validate.utc2local_format(
              track_data.metadata.product_offer_until_date,
              Config.DATE_FORMAT
            )}
          </Typography>
        </Fragment>
      );

    case 3:
      return (
        <Fragment>
          <Typography className={classes.list_item_subtitle}>
            <span className={classes.detail_title}>Tipo de oferta:</span> Oferta
            con fecha de inicio y finalización
          </Typography>
          <Typography className={classes.list_item_subtitle}>
            <span className={classes.detail_title}>Fecha de inicio:</span>{' '}
            {Validate.utc2local_format(
              track_data.metadata.product_offer_from_date,
              Config.DATE_FORMAT
            )}
          </Typography>
          <Typography className={classes.list_item_subtitle}>
            <span className={classes.detail_title}>Fecha de finalización:</span>{' '}
            {Validate.utc2local_format(
              track_data.metadata.product_offer_until_date,
              Config.DATE_FORMAT
            )}
          </Typography>
        </Fragment>
      );

    default:
      return '';
  }
};

const TrackOfferEntity = ({ track, classes }) => {
  let entity_type = Validate.in_array(track.track_subtype, [1, 3])
    ? 'Categoría'
    : 'Producto';
  entity_type += track.track_data.entities.length === 1 ? '' : 's';

  return (
    <Typography className={classes.list_item_subtitle}>
      <span className={classes.detail_title}>{entity_type}:</span>{' '}
      {track.track_data.entities.join(', ')}
    </Typography>
  );
};

const TrackOfferDetail = ({ track, classes }) => {
  return (
    <Fragment>
      <ToggleUI show={track.track_subtype === 1 || track.track_subtype === 2}>
        <Typography className={classes.list_item_subtitle}>
          <span className={classes.detail_title}>Porcentaje:</span>{' '}
          {track.track_data.metadata.percentage}%
        </Typography>
        <Typography className={classes.list_item_subtitle}>
          <span className={classes.detail_title}>Con redondeo:</span>{' '}
          {track.track_data.metadata.round === 1 ? 'Sí' : 'No'}
        </Typography>
        <ToggleUI show={track.track_subtype === 1}>
          <Typography className={classes.list_item_subtitle}>
            <span className={classes.detail_title}>
              Aplicada a subcategorías:
            </span>{' '}
            {track.track_data.metadata.apply_children === 1 ? 'Sí' : 'No'}
          </Typography>
        </ToggleUI>
        <TrackOfferType track_data={track.track_data} classes={classes} />
      </ToggleUI>
      <TrackOfferEntity track={track} classes={classes} />
    </Fragment>
  );
};

const TrackOfferIcon = ({ track_subtype }) => {
  let icon, icon_color;

  if (Validate.in_array(track_subtype, [3, 4])) {
    icon = 'delete_forever';
    icon_color = '#e91e63';
  } else {
    icon_color = '#4caf50';
    icon = track_subtype === 1 ? 'style' : 'local_offer';
  }

  return (
    <Avatar style={{ backgroundColor: icon_color }}>
      <Icon style={{ color: '#fff' }}>{icon}</Icon>
    </Avatar>
  );
};

const OfertasItem_ = ({ track, classes }) => {
  return (
    <Grid item xs={12}>
      <div className={classes.list_item}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item sm="auto" xs={2}>
                <TrackOfferIcon track_subtype={track.track_subtype} />
              </Grid>
              <Grid item sm="auto" xs={10}>
                <Typography className={classes.list_item_title}>
                  {getTitle(track.track_subtype, track.track_data)}
                </Typography>
                <Typography className={classes.list_item_subtitle}>
                  {Validate.capitalize(
                    Validate.utc2relative_local(track.track_created_at)
                  )}{' '}
                  &middot;{' '}
                  {Validate.utc2local_format(
                    track.track_created_at,
                    Config.DATE_FORMAT
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <List classes={{ root: classes.list }}>
              <ListItem
                ContainerComponent="div"
                classes={{ root: classes.list_item_detail }}
              >
                <TrackOfferDetail track={track} classes={classes} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

const styles = (theme) => ({
  list: {
    padding: '0',
  },
  list_item: {
    backgroundColor: '#fff',
    boxShadow: theme.card.card.boxShadow,
    borderRadius: '4px',
    padding: '15px 7.5px',
  },
  list_item_detail: {
    backgroundColor: '#fefefe',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    display: 'block',
  },
  list_item_title: {
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
  },
  list_item_subtitle: {
    fontSize: '0.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.85rem',
    },
    color: 'rgba(0, 0, 0, 0.54)',
  },
  list_item_ver_oferta: {
    fontSize: '0.8rem',
    marginTop: '5px',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#000000',
  },
  detail_title: {
    fontWeight: '500',
    color: '#263238',
  },
});

const mapStateToProps = (state) => {
  return {
    tracks: state.tracks,
  };
};

const mapDispatchToProps = null;

export const OfertasItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OfertasItem_));
