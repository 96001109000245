import React from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';

const EditButtons = ({ isSaveChangesLoading, revertChanges, handleSubmit }) => {
  return (
    <Grid
      container
      mt={1.5}
      justifyContent="start"
      direction={{ xs: 'column', sm: 'row-reverse' }}
      gap={1}
    >
      <Button
        disabled={isSaveChangesLoading}
        variant="contained"
        onClick={(e) => handleSubmit(e)}
        endIcon={
          isSaveChangesLoading && <CircularProgress size={20} color="inherit" />
        }
        sx={{ width: { sm: '200px', xs: '100%' } }}
      >
        Guardar
      </Button>
      <Button
        variant="outlined"
        onClick={(e) => revertChanges(e)}
        disabled={isSaveChangesLoading}
        sx={{ width: { sm: '200px', xs: '100%' } }}
      >
        Borrar cambios
      </Button>
    </Grid>
  );
};

export default EditButtons;
