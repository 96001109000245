import React from 'react';
import LinearProgress from '../../../../components/common/LinearProgress';
import { CardModuleUI } from '../../../../components/common';
import { Box, Typography } from '@mui/material';
import { OnboardingStep } from '../OnboardingStep';
import { initialStateOnboardingSteps } from '../onboardingState';
import { getStepsContent } from './getStepsContent';

export const StepsGuide = ({
  idCountry,
  stepsStatus,
  completionPercentage,
}) => {
  const { title, subtitle } = getStepsContent(stepsStatus);

  return (
    <CardModuleUI
      disablePadding
      sx={{
        '&:last-child': {
          padding: '22px',
        },
      }}
    >
      <LinearProgress value={completionPercentage} mb={2} />
      <Typography
        as="h3"
        fontWeight="600"
        fontSize="1.125rem"
        lineHeight="1.2"
        mb={1}
        color="#3A3A3A"
      >
        {title}
      </Typography>
      <Typography fontSize="0.875rem" mb={2} color="#3A3A3A">
        {subtitle}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          margin: '0 auto',
          rowGap: '8px',
        }}
      >
        {initialStateOnboardingSteps.map((item, i) => {
          const onboardingStepData = {
            ...item,
            completed: stepsStatus[item.key],
          };

          if (idCountry === 'COL' && item.key === 'shipmentMethodStepDone') {
            return null;
          }

          return (
            <OnboardingStep key={i} onboardingStepData={onboardingStepData} />
          );
        })}
      </Box>
    </CardModuleUI>
  );
};
