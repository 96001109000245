import { Box } from '@mui/material';
import React from 'react';

export default function ModalVideo({ video, ...rest }) {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        zIndex: 2000,
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
      tabIndex={0}
      role="button"
      {...rest}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          right: 0,
          zIndex: 2000,
          width: '90%',
          height: '100%',
          display: 'flex',
          margin: '0 auto',
          maxWidth: '886px',
          position: 'fixed',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
            paddingTop: '56.25%',
            height: 0,
            width: '100%',
            '& iframe': {
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
            },
          }}
        >
          <iframe
            title="YouTube Video"
            src={`https://www.youtube.com/embed/${video}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
}
