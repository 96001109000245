import React from 'react';
import { withStyles } from '@mui/styles';

export const LineDot_ = ({ classes }) => {
  return (
    <div className={classes.doted}>
      <svg
        width="107"
        height="6"
        viewBox="0 0 107 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.67566 3H103.176"
          stroke="#6F6F6F"
          strokeWidth="2"
          strokeDasharray="4 4"
        />
        <circle cx="3.67566" cy="3" r="3" fill="#6F6F6F" />
        <circle cx="103.676" cy="3" r="3" fill="#6F6F6F" />
      </svg>
    </div>
  );
};

const styles = (theme) => ({
  doted: {
    width: '74px',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '106px',
      height: '80px',
      margin: '0 8px',
      alignSelf: 'center',
    },
  },
});

export const LineDot = withStyles(styles)(LineDot_);
