/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { ButtonUI, DropdownUI, MenuItemUI } from '../../../components/common';
import { CustomCardUIWithDetails } from '../components/CustomCardUIWithDetails';
import { GridItemCard } from '../components/GridItemCard';
import { Config } from '../../../other';

const UalaBis = ({
  metodos_pago,
  forms,
  modalHandler,
  handleDeshabilitarUalaSubmit,
  idCountry,
}) => {
  const cargarBoton = () => {
    if (metodos_pago.mp_uala) {
      return (
        <DropdownUI
          label="Configuración"
          size="large"
          isLoading={forms.desactivar_uala}
        >
          <MenuItemUI
            type="callf"
            onClickFunction={() => modalHandler(true, 'activar_editar_uala')}
          >
            Cambiar cuenta
          </MenuItemUI>
          <MenuItemUI
            type="callf"
            onClickFunction={handleDeshabilitarUalaSubmit}
          >
            Desactivar
          </MenuItemUI>
        </DropdownUI>
      );
    } else {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={() => modalHandler(true, 'activar_editar_uala')}
          label="Activar"
          size="large"
        />
      );
    }
  };

  return (
    <GridItemCard lg={6}>
      <CustomCardUIWithDetails
        title="Ualá Bis"
        ivaInfo={idCountry === 'COL' ? '2,89%' : '4,4% + IVA'}
        info={
          <span>
            Te permite cobrar con tarjetas de crédito y débito. La acreditación
            es inmediata siempre.
            <br />
            <br />
            Es necesario tener cuenta en Ualá para activar este método de pago.
          </span>
        }
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/uala-card-img.png`}
        subtitle={metodos_pago.mp_uala ? 'Método activo' : 'Método no activo'}
        recomendado={metodos_pago.mp_uala !== 1}
      >
        {cargarBoton()}
      </CustomCardUIWithDetails>
    </GridItemCard>
  );
};

export { UalaBis };
