import React, { useEffect, useState } from 'react';

import { BackgroundBox } from '../../../../components/common/BackgroundBox/styles';
import LinearProgress from '../../../../components/common/LinearProgress';
import { Fade, Typography } from '@mui/material';
import amplitude from 'amplitude-js';

export const CongratulationsMessage = ({ idTienda }) => {
  const initialState =
    localStorage.getItem(`close-congratulations-msg-${idTienda}`) === '1'
      ? 'none'
      : 'block';

  const [displayValue, setDisplayValue] = useState(initialState);
  const [onAnimation, setOnAnimation] = useState(true);

  useEffect(() => {
    if (localStorage.getItem(`close-congratulations-msg-${idTienda}`) !== '1') {
      localStorage.setItem(`close-congratulations-msg-${idTienda}`, '1');

      amplitude.getInstance().setUserProperties({
        guia_pasos_finalizada: true,
      });

      setTimeout(() => {
        setOnAnimation(false);
      }, 4700);

      setTimeout(() => {
        setDisplayValue('none');
      }, 5000);
    }
  }, []);
  return (
    <Fade timeout={500} in={onAnimation}>
      <BackgroundBox display={displayValue} mb={2}>
        <LinearProgress value={100} mb={2} />
        <Typography
          as="h3"
          fontWeight="600"
          fontSize="1.125rem"
          color="#3A3A3A"
          lineHeight="1.2"
        >
          ¡Felicidades! Completaste la guía de pasos
        </Typography>
      </BackgroundBox>
    </Fade>
  );
};
