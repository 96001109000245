import React from 'react';
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { ShopSettings, ShopProductsSettings, ShopCheckoutSettings } from '.';
import { withStyles } from '@mui/styles';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../../components/amplitude';
import { useHistory } from 'react-router-dom';
import { TemplateAndSectionsSettings } from './TemplateAndSectionsSettings';
import { BlockEdit } from './BlockEdit';
import { tabOptions } from '../jsonSelects';

const DrawerContent_ = ({
  active_page,
  tienda,
  settings,
  agregar,
  bloques,
  productos,
  dataForLocalStorage,
  handleChange,
  handleChangeBlock,
  handleDeleteBlock,
  handleChangeMenuTab,
  handleDisableEnableBlock,
  handleChangeSelect,
  handleChangeSelectBlock,
  handleAddBlock,
  handleSortBlocks,
  handleChangeFileBlock,
  handleChangeGridDesign,
  handleChangeFileSettings,
  handleChangeFeedProducts,
  handleChangeProductsBlock,
  handlerShowAlertUI,
  classes,
}) => {
  const history = useHistory();
  const fromTemplateSelectionPage =
    history.location.state?.from === '/selecciona-plantilla';

  const handleTabsAmplitudeEvents = (value) => {
    const events = {
      '-1': design_amplitude_events.diseño_selecciona_personalizacion
        .event_name,
      '-3': design_amplitude_events.diseño_selecciona_plantillas.event_name,
      '-5': design_amplitude_events.diseño_selecciona_productos.event_name,
      '-6': design_amplitude_events.diseño_selecciona_checkout.event_name,
    };

    if (events[value]) {
      createAmplitudeEventWithDevice(events[value]);
    }
  };

  const renderTabContent = () => {
    if (fromTemplateSelectionPage) {
      setTimeout(() => {
        history.replace({ state: {} });
        handleChangeMenuTab(null, -3);
      }, 0);
      return;
    }

    switch (active_page) {
      // personalizacion
      case -1:
        return (
          <ShopSettings
            settings={settings}
            handleChange={handleChange}
            handlerShowAlertUI={handlerShowAlertUI}
            handleChangeSelect={handleChangeSelect}
            handleChangeFileSettings={handleChangeFileSettings}
          />
        );

      // plantillas y secciones
      case -3:
        return (
          <TemplateAndSectionsSettings
            bloques={bloques}
            agregar={agregar}
            dataForLocalStorage={dataForLocalStorage}
            handleChangeSelect={handleChangeSelect}
            handleSortBlocks={handleSortBlocks}
            handleChangeMenuTab={handleChangeMenuTab}
            handleDeleteBlock={handleDeleteBlock}
            handleAddBlock={handleAddBlock}
            handleDisableEnableBlock={handleDisableEnableBlock}
          />
        );

      //productos -> productos conf
      case -5:
        return (
          <ShopProductsSettings
            tienda={tienda}
            settings={settings}
            productos={productos}
            handleChange={handleChange}
            handlerShowAlertUI={handlerShowAlertUI}
            handleChangeSelect={handleChangeSelect}
            handleChangeFeedProducts={handleChangeFeedProducts}
          />
        );

      //checkout
      case -6:
        return (
          <ShopCheckoutSettings
            settings={settings}
            handleChangeSelect={handleChangeSelect}
          />
        );

      //inicio -> bloques
      default:
        return (
          <BlockEdit
            template_id={settings.template_id.value}
            bloques={bloques}
            active_page={active_page}
            handleChangeBlock={handleChangeBlock}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handleChangeMenuTab={handleChangeMenuTab}
            handleChangeGridDesign={handleChangeGridDesign}
            handleChangeProductsBlock={handleChangeProductsBlock}
            handlerShowAlertUI={handlerShowAlertUI}
          />
        );
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.card_content_tab }}>
          <Tabs
            value={
              fromTemplateSelectionPage
                ? -3
                : active_page >= 0
                ? -3
                : active_page
            }
            scrollButtons
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              scrollButtons: classes.scrollButtons,
            }}
            onChange={(e, value) => {
              handleChangeMenuTab(e, value);
              handleTabsAmplitudeEvents(value);
            }}
            allowScrollButtonsMobile
          >
            {tabOptions.map((el, key) => (
              <Tab
                key={key}
                label={el.label}
                value={el.value}
                classes={{
                  root: classes.tabItem,
                }}
              />
            ))}
          </Tabs>
        </CardContent>
      </Card>
      <Box
        sx={{
          boxSizing: 'border-box',
          padding: '20px 12px',
        }}
      >
        {renderTabContent()}
      </Box>
    </>
  );
};

const styles = () => ({
  scrollButtons: {
    width: 'auto',
    backgroundColor: '#F2F2F2',
  },
  tabItem: {
    fontSize: '0.8125rem',
    padding: '21.25px 24px',
    minWidth: 'auto',
    backgroundColor: '#FAFAFA',
    textTransform: 'none',
  },
  card: {
    margin: '12px 12px 0',
  },
  card_content_tab: {
    padding: '0 !important',
  },
});

export const DrawerContent = withStyles(styles, { withTheme: true })(
  DrawerContent_
);
