import {
  Box,
  Button,
  IconButton,
  MobileStepper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import { BackgroundBox } from '../../../components/common/BackgroundBox/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useHistory } from 'react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Empretips = ({ empretipsList }) => {
  const [activeTip, setActiveTip] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const theme = useTheme();
  const router = useHistory();

  const handleStepButtonClick = (action) => {
    setActiveTip((prevState) => {
      if (action === 'next') return prevState + 1;
      return prevState - 1;
    });
    setAutoPlay(false);
  };

  const handleActiveTipChange = (step) => {
    setActiveTip(step);
  };

  const handleOnSwitching = (_index, type) => {
    if (type === 'end' && autoPlay) {
      setAutoPlay(false);
    }
  };

  return (
    <Box
      gridRow={{ xs: 'span 2', md: '3 / 9' }}
      gridColumn={{ xs: '1 / 5', md: '9 / 13' }}
    >
      <BackgroundBox
        sx={{
          maxHeight: '464px',
          minHeight: '464px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          as="p"
          fontSize={'1rem'}
          fontWeight={'600'}
          marginBottom={'16px'}
          color={theme.texts.secondary}
        >
          Empre
          <Typography
            as="span"
            fontWeight={600}
            color={theme.palette.primary.main}
          >
            tips
          </Typography>
        </Typography>
        <AutoPlaySwipeableViews
          interval={7000}
          index={activeTip}
          enableMouseEvents
          autoplay={autoPlay}
          onSwitching={handleOnSwitching}
          onChangeIndex={handleActiveTipChange}
        >
          {empretipsList.map((tip, index) => {
            let pathname = tip?.button?.url;

            if (
              tip?.button?.target === '_self' &&
              tip?.button?.url[0] !== '/'
            ) {
              const url = new URL(tip?.button?.url);
              pathname = url.pathname;
            }

            return (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                key={index}
              >
                <Box
                  height="308px"
                  display="block"
                  marginBottom="16px"
                  sx={{ overflowY: 'scroll' }}
                >
                  {tip?.image && (
                    <Box
                      display={'flex'}
                      marginBottom={'16px'}
                      justifyContent={'center'}
                    >
                      <Box
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '120px',
                          objectFit: 'cover',
                        }}
                        component="img"
                        alt={tip?.title}
                        src={tip?.image}
                      />
                    </Box>
                  )}
                  <Typography
                    as="h3"
                    fontWeight={600}
                    marginBottom={'8px'}
                    fontSize={'1.125rem'}
                    color={theme.texts.secondary}
                  >
                    {tip?.title}
                  </Typography>
                  {tip?.subtitle && (
                    <Typography
                      as="p"
                      fontWeight={'600'}
                      overflow={'hidden'}
                      marginBottom={'8px'}
                      fontSize={'0.875rem'}
                      textOverflow={'ellipsis'}
                      color={theme.texts.secondary}
                    >
                      {tip?.subtitle}
                    </Typography>
                  )}
                  <Typography
                    as="p"
                    fontSize="0.875rem"
                    color={theme.texts.secondary}
                  >
                    {tip?.text}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  {tip?.button?.text && (
                    <Button
                      variant="contained"
                      sx={{
                        alignSelf: 'center',
                        marginBottom: '16px',
                        paddingX: { md: '12px', lg: '48px' },
                        width: { xs: '100%', md: 'fit-content' },
                      }}
                      onClick={async () => {
                        await createAmplitudeEventWithDevice(
                          amplitude_events.home_selecciona_empretip.event_name,
                          {
                            tip: tip.tag,
                          }
                        );

                        if (tip.button.target === '_blank') {
                          window.open(pathname, '_blank');
                        }
                        if (tip.button.target === '_self') {
                          router.push(pathname);
                        }
                      }}
                    >
                      {tip.button.text}
                    </Button>
                  )}
                </Box>
              </Box>
            );
          })}
        </AutoPlaySwipeableViews>
        <MobileStepper
          sx={{ padding: 0 }}
          variant="dots"
          position="static"
          activeStep={activeTip}
          steps={empretipsList.length}
          backButton={
            <IconButton
              sx={{
                borderRadius: '50%',
                backgroundColor: '#E3E3E3',
                color: theme.texts.secondary,
                visibility: { xs: 'hidden', md: 'visible' },
              }}
              size="small"
              disabled={activeTip === 0}
              onClick={handleStepButtonClick}
            >
              <ChevronLeftIcon fontSize="medium" />
            </IconButton>
          }
          nextButton={
            <IconButton
              sx={{
                borderRadius: '50%',
                backgroundColor: '#E3E3E3',
                visibility: { xs: 'hidden', md: 'visible' },
              }}
              size="small"
              color={theme.texts.secondary}
              onClick={() => handleStepButtonClick('next')}
              disabled={activeTip === empretipsList.length - 1}
            >
              <ChevronRightIcon fontSize="medium" />
            </IconButton>
          }
        />
      </BackgroundBox>
    </Box>
  );
};

export { Empretips };
