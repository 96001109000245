import React from 'react';
import { CardAplication } from './CardAplication';
import { Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';

const AppListForInstall_ = ({
  classes,
  appList,
  appActivesList,
  handleOpenModal,
  history,
}) => {
  const appListForInstall = appList.filter(
    (app) => !appActivesList.includes(app.app_id)
  );

  return (
    <>
      {appListForInstall.length > 0 ? (
        <div className={classes.containerList}>
          {appListForInstall.map((app, key) => {
            if (appActivesList.includes(app.app_id)) {
              return null;
            }

            return (
              <CardAplication
                app={app}
                key={key}
                handleOpenModal={() =>
                  handleOpenModal({
                    appId: app.app_id,
                    isInstall: true,
                  })
                }
                history={history}
              />
            );
          })}
        </div>
      ) : (
        <Grid item xs={12}>
          <div className={classes.myAppsCardEmpty}>
            <Icon>library_add</Icon>
            <span className={classes.myAppsText}>
              Estamos trabajando para ofrecerte nuevas herramientas que
              mejorarán tu experiencia.
            </span>
          </div>
        </Grid>
      )}
    </>
  );
};

const styles = (theme) => ({
  containerList: {
    width: 'auto',
    display: 'grid',
    rowGap: '24px',
    margin: '0 auto',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridGap: '24px',
      gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 340px))',
    },
  },
  myAppsCardEmpty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.5',
  },
  myAppsText: {
    fontSize: '1rem',
    fontWeight: '400',
    color: '#3A3A3A',
    marginLeft: '16px',
  },
});

export const AppListForInstall = withStyles(styles)(AppListForInstall_);
