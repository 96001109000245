import axios from 'axios';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const soporteEnviar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/soporte', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export { soporteEnviar };
