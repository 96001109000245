import React from 'react';
import { ModalUI } from '../../common';
import { withStyles } from '@mui/styles';
import { aplicacionBorrar } from '../../../store/actions';
import { connect } from 'react-redux';
import {
  applications_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../amplitude';

const ModalUninstallApp_ = ({
  open,
  modalHandler,
  classes,
  app,
  setAlert,
  deleteApplication,
  setLoadingApps,
  setLoadingAppsActives,
  setIsAppDeleted,
}) => {
  const handleSubmitUninstallApp = (e) => {
    e.preventDefault();

    setLoadingApps(true);
    setLoadingAppsActives(true);

    deleteApplication({ applicationId: app.app_id }, (err, _resp) => {
      if (err) {
        setAlert({
          open: true,
          message: 'No pudimos desinstalar la aplicación.',
          type: 'error',
        });

        createAmplitudeEventWithDevice(
          applications_amplitude_events.aplicaciones_confirma_desinstalar
            .event_name,
          {
            aplicacion: `(${app.name})`,
            success: false,
          }
        );
        setLoadingApps(false);
        setLoadingAppsActives(false);
        modalHandler(false);
      } else {
        // Restablecer estados de carga
        setIsAppDeleted(true);

        createAmplitudeEventWithDevice(
          applications_amplitude_events.aplicaciones_confirma_desinstalar
            .event_name,
          {
            aplicacion: `(${app.name})`,
            success: true,
          }
        );

        // Mostrar mensaje de éxito
        setAlert({
          open: true,
          message: 'Desinstalaste la aplicación con éxito.',
          type: 'success',
        });

        // Cerrar modal
        modalHandler(false);
      }
    });
  };

  return (
    <ModalUI
      id="modal-ecommapp-uninstall"
      open={open}
      title="Desinstalar la aplicación"
      button_label={'Desinstalar'}
      modalHandler={modalHandler}
      cancel_button_label="Cancelar"
      handleSubmit={handleSubmitUninstallApp}
      classes={{
        card_actions: classes.containerButtons,
        card_content: classes.containerModal,
        card_header_title: classes.title,
        card_header: classes.cardHeader,
      }}
    >
      <p className={classes.modalText}>
        Si desinstalás la aplicación, perderás acceso a todas sus
        funcionalidades en tu tienda. Podrás reinstalarla cuando quieras.
      </p>
    </ModalUI>
  );
};

const styles = (theme) => ({
  cardHeader: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  modalText: {
    color: '#565656',
    fontSize: '1rem',
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      margin: '40px 20px',
    },
  },
  containerModal: {
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
    },
  },
  containerButtons: {
    '& div': { gap: '5px' },
  },
  title: {
    fontWeight: '500',
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    deleteApplication: (data, callback) =>
      dispatch(aplicacionBorrar(data, callback)),
  };
};

export const ModalUninstallApp = withStyles(styles)(
  connect(null, mapDispatchToProps)(ModalUninstallApp_)
);
