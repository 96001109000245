import Reducers from '../reducers';

const showAlertUI = (data) => {
  return (dispatch, getState) => {
    let resp = data;
    if (!data) {
      const { showAlert } = getState();
      resp = { ...showAlert, open: false };
    }

    dispatch({ type: Reducers.SHOW_ALERT, data: resp });
  };
};

export { showAlertUI };
