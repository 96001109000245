import React from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Validate } from '../../../other';

const SolicitudesList_ = (props) => {
  let { clientes, classes, modalHandler } = props;

  const renderizarSolicitudes = () => {
    let mobile = Validate.is_mobile();

    if (clientes.length > 0) {
      return clientes.map((cliente, index) => {
        return (
          <ListItem
            divider={index < clientes.length - 1}
            key={cliente.idClientes}
          >
            {!mobile ? (
              <ListItemAvatar sx={{ minWidth: 'unset' }}>
                <Avatar>
                  <Icon>person</Icon>
                </Avatar>
              </ListItemAvatar>
            ) : (
              ''
            )}
            <ListItemText
              classes={{ root: classes.list_text }}
              primary={cliente.cl_nombre + ' ' + cliente.cl_apellido}
              secondary={
                <span className={classes.span}>
                  <span className={classes.span}>{cliente.cl_email}</span>
                  <span className={classes.span}>{cliente.cl_telefono}</span>
                </span>
              }
              sx={{ paddingLeft: !mobile ? '16px' : '0' }}
            />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Aceptar"
                onClick={() =>
                  modalHandler(true, 'solicitud_mayorista_aceptar', cliente)
                }
                size="large"
              >
                <Icon>check</Icon>
              </IconButton>
              <IconButton
                aria-label="Cancelar"
                onClick={() =>
                  modalHandler(true, 'solicitud_mayorista_rechazar', cliente)
                }
                size="large"
              >
                <Icon>clear</Icon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      });
    } else {
      return (
        <ListItem>
          <ListItemText
            primary="No hay solicitudes mayoristas pendientes"
            secondary="Te vamos a notificar por email cuando tengas una nueva"
          />
        </ListItem>
      );
    }
  };

  return (
    <List classes={{ root: classes.list }}>{renderizarSolicitudes()}</List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  list_text: {
    paddingRight: '100px',
  },
});

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes,
  };
};

const mapDispatchToProps = null;

export const SolicitudesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SolicitudesList_));
