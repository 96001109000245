import React, { useEffect } from 'react';

import { titleValidation } from './titleValidation';

const MAX_RECORDS = 3000;
const formatValidation = (planilla_titulos, cantidad_registros) => {
  if (cantidad_registros === 0) {
    return {
      err: true,
      valid_columns: null,
      error_messages: ['La planilla está vacia'],
    };
  } else if (cantidad_registros === 1) {
    return {
      err: true,
      valid_columns: null,
      error_messages: ['La planilla tiene títulos pero no datos'],
    };
  }

  let { err, valid_columns, error_messages } =
    titleValidation(planilla_titulos);

  if (cantidad_registros > MAX_RECORDS + 1) {
    err = true;
    error_messages.push(`Se encontraron más de ${MAX_RECORDS} productos`);
  }

  return {
    err,
    valid_columns,
    error_messages,
  };
};

export const ValidarFormato = (props) => {
  const { planilla_titulos, cantidad_registros, handleValidarFormatoFinish } =
    props;

  useEffect(() => {
    const { err, valid_columns, error_messages } = formatValidation(
      planilla_titulos,
      cantidad_registros
    );
    handleValidarFormatoFinish(err, valid_columns, error_messages);
  }, []);

  return <></>;
};
