import { Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { Circle } from './Circle';
import { LogoUalaBis } from './LogoUalaBis';
import { LogoPayments } from './LogoPayments';
import { LogoMercadoPago } from './LogoMercadoPago';
import { AvatarUI } from '../../common';
import { Config, Validate } from '../../../other';

const PagoManualWithoutPaymentMethods = ({ classes }) => {
  return (
    <>
      <h3 className={classes.title}>Pago manual</h3>
      <p className={classes.paragraph}>
        Podrás pagar tu factura manualmente cuando falten 10 días para su
        vencimiento, te avisaremos por email.
      </p>
      <p className={classes.paragraph}>
        También podés probar la suscripción al débito automático para
        automatizar tus pagos.
      </p>
    </>
  );
};

const PagoManualWithPaymentMethods = ({
  monto,
  classes,
  metodoPago,
  handleSeleccionarMetodoPago,
}) => {
  return (
    <>
      <p className={classes.subtitle}>
        Para mantener tu tienda, recordá pagar mes a mes tu abono, antes del
        vencimiento.
      </p>
      <button
        className={classes.buttonCard}
        onClick={() => handleSeleccionarMetodoPago(1)}
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          className={classes.priceContainer}
          style={{
            border: metodoPago ? '2px solid #53B755' : '2px solid #E3E3E3',
          }}
        >
          <Grid item sx={{ paddingBottom: '12px' }} p={1.5}>
            <p className={classes.cardTitle}>Tarjeta de crédito o débito</p>
            <Grid
              item
              style={{
                display: 'flex',
                marginBottom: '12px',
                alignItems: 'center',
              }}
            >
              <span className={classes.priceTextUalaBis}>Procesado por</span>{' '}
              <LogoUalaBis
                style={{ position: 'relative', bottom: '2px' }}
                width={'48px'}
              />
            </Grid>
            <LogoPayments className={classes.logoPayments} />
          </Grid>
          <Grid item className={classes.price} p={1.5}>
            <p style={{ marginRight: '.5rem' }}>
              ${Validate.number_format(monto)} por mes
            </p>
            <Circle checked={metodoPago} />
            <AvatarUI color="blue" className={classes.showInMobile}>
              <Icon fontSize="large">credit_card</Icon>
            </AvatarUI>
          </Grid>
        </Grid>
      </button>

      <button
        className={classes.buttonCard}
        onClick={() => handleSeleccionarMetodoPago(0)}
      >
        <Grid
          container
          wrap="nowrap"
          className={classes.priceContainer}
          style={{
            border: !metodoPago ? '2px solid #53B755' : '2px solid #E3E3E3',
          }}
        >
          <Grid item className={classes.showInDesktop} p={1.5}>
            <LogoMercadoPago width="114px" />
          </Grid>
          <Grid
            item
            className={classes.showInMobile}
            alignItems="center"
            p={1.5}
          >
            <p className={classes.mpTitleMobile}>Mercado Pago</p>
          </Grid>
          <Grid item className={classes.price} p={1.5}>
            <p style={{ marginRight: '.5rem' }}>
              ${Validate.number_format(monto)} por mes
            </p>
            <Circle checked={!metodoPago} />
            <AvatarUI className={classes.showInMobile}>
              <LogoMercadoPago
                src={`${Config.CLOUDFRONT_CDN_PROD}/panel/suscripciones/mercadopago.png`}
                width="54px"
              />
            </AvatarUI>
          </Grid>
        </Grid>
      </button>
    </>
  );
};

const PagoManual_ = ({
  monto,
  classes,
  metodoPago,
  isAvailableToPay,
  handleSeleccionarMetodoPago,
}) => {
  return isAvailableToPay ? (
    <PagoManualWithPaymentMethods
      monto={monto}
      classes={classes}
      metodoPago={metodoPago}
      handleSeleccionarMetodoPago={handleSeleccionarMetodoPago}
    />
  ) : (
    <PagoManualWithoutPaymentMethods classes={classes} />
  );
};

const style = (theme) => ({
  container: { backgroundColor: '#FFF' },
  price: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& circle': {
      transition: 'stroke 100ms cubic-bezier(.4,.0,.23,1)',
    },

    '& p': {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },

    paddingRight: '0 !important',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '12px !important',
    },
  },
  priceTextUalaBis: {
    color: '#9E9E9E',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
  priceContainer: {
    margin: 0,
    width: '100%',
    textAlign: 'left',
    borderRadius: '4px',
    transition: 'border 100ms cubic-bezier(.4,.0,.23,1)',

    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  cardTitle: {
    fontSize: '1rem',
    fontWeight: '500',
    margin: '4px 0 12px 0',
  },
  mpTitleMobile: {
    margin: '0',
    fontSize: '1rem',
    fontWeight: '500',
  },
  buttonCard: {
    padding: 0,
    width: '100%',
    border: 'none',
    font: 'inherit',
    margin: '12px 0',
    color: 'inherit',
    cursor: 'pointer',
    background: 'none',
    outline: 'inherit',
  },
  title: {
    margin: '0px',
    fontSize: '1rem',
  },
  paragraph: {
    fontWeight: '400',
    fontSize: '.875rem',
    margin: '16px 0 0 0',
  },
  subtitle: {
    margin: '0 0 12px',
    fontWeight: '400',
    fontSize: '.875rem',
  },
  logoPayments: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  showInMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  showInDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
});

export const PagoManual = withStyles(style)(PagoManual_);
