import React from 'react';
import { Typography } from '@mui/material';

export const Page = ({ firstvalue, lastvalue }) => {
  return (
    <>
      <Typography
        gutterBottom
        component="p"
        fontSize="1rem"
        lineHeight="21px"
        color="#000000"
      >
        <b>{firstvalue}</b> {lastvalue}
      </Typography>
    </>
  );
};
