import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Grid } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import {
  AlertUI,
  ButtonUI,
  InputUI,
  ModuleButtonBoxUI,
  ModuleTitleUI,
} from '../../components/common';
import { tiendaActualizarDataFiscal, tiendaObtener } from '../../store/actions';

const FiscalDataPage_ = ({ getShop, updateShopFiscalData }) => {
  const [fiscalData, setFiscalData] = useState({
    t_datafiscal_codigo: {
      auth: true,
      validate: true,
      required: false,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Código',
      id: 't_datafiscal_codigo',
      name: 't_datafiscal_codigo',
      change_param: 'data_fiscal',
      regex: Regex.DESCRIPCION_0_500,
      value: '',
      default_value: '',
      placeholder: ' ',
      messages: {
        error: 'Ingrese un código válido',
        help: 'Es el código que te brinda AFIP',
        error_extra: RegexExtra.DESCRIPCION_0_500,
      },
    },
  });

  const [loadingState, setLoadingState] = useState({
    data_fiscal: true,
    error: false,
  });

  const [updateShopFiscalDataLoading, setUpdateShopFiscalDataLoading] =
    useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    getShop((err, resp) => {
      if (err) {
        setLoadingState((prevState) => ({
          ...prevState,
          data_fiscal: false,
          error: true,
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          fiscalData,
          (data_fiscal_obj) => {
            setFiscalData(data_fiscal_obj);
            setLoadingState((prevState) => ({
              ...prevState,
              data_fiscal: false,
              error: false,
            }));
          }
        );
      }
    });
  }, [getShop]);

  const handleChange = (e, blur, _aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    setFiscalData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
      },
    }));

    if (blur) {
      Validate.validate_input(fiscalData[name], blur, (input) => {
        setFiscalData((prevState) => ({
          ...prevState,
          [name]: input,
        }));
      });
    }
  };

  const handleCloseAlert = () => {
    setAlertState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setUpdateShopFiscalDataLoading(true);

    let form = fiscalData;

    if (Validate.validar_formulario(form)) {
      updateShopFiscalData(
        {
          t_datafiscal_codigo: form.t_datafiscal_codigo.value,
        },
        (err, resp) => {
          setUpdateShopFiscalDataLoading(false);
          setAlertState((prevState) => ({
            ...prevState,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setUpdateShopFiscalDataLoading(false);
        setFiscalData(new_form);
      });
    }
  };

  return (
    <WrapperLayout
      loading={loadingState.data_fiscal}
      error={loadingState.error}
    >
      <AlertUI
        open={alertState.open}
        message={alertState.message}
        type={alertState.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <ModuleTitleUI
              title="Data Fiscal"
              subtitles={[
                'En esta sección podés pegar tu código de AFIP para que se visualice en tu tienda.',
                'El código lo obtenés desde tu portal de AFIP en “Formulario 960/D” para Sitio Web.',
              ]}
            />
            <Card>
              <CardContent>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={handleChange}
                      multiline
                      input={fiscalData.t_datafiscal_codigo}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <ModuleButtonBoxUI>
              <ButtonUI
                type="submit"
                minHeight={true}
                label="Guardar"
                isLoading={updateShopFiscalDataLoading}
              />
            </ModuleButtonBoxUI>
          </form>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
    updateShopFiscalData: (data, callback) =>
      dispatch(tiendaActualizarDataFiscal(data, callback)),
  };
};

const FiscalDataPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiscalDataPage_);

export default FiscalDataPage;
