import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const CardText_ = (props) => {
  const { classes, text } = props;

  return (
    <>
      <Typography gutterBottom component="p" className={classes.card_text}>
        {text}
      </Typography>
    </>
  );
};

const styles = (theme) => ({
  card_text: {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '19px',
    color: '#000000',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.87rem',
    },
  },
});

export const CardText = withStyles(styles)(CardText_);
