import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Regex, RegexExtra, Validate } from '../../other';
import { Grid, Typography } from '@mui/material';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import {
  AlertUI,
  ButtonUI,
  CardModuleUI,
  CheckboxUI,
  InputUI,
  ModuleButtonBoxUI,
  ModuleCardTitleUI,
  ModuleTitleUI,
} from '../../components/common';
import {
  tiendaActualizarFacebookPixel,
  tiendaObtener,
} from '../../store/actions';

const FacebookPixelPage = ({ classes, shop, getShop, updateFacebookPixel }) => {
  const [facebookPixel, setFacebookPixel] = useState({
    t_facebook_pixel_codigo: {
      auth: true,
      validate: true,
      required: false,
      disabled: false,
      error: false,
      type: 'text',
      label: 'Código',
      id: 't_facebook_pixel_codigo',
      name: 't_facebook_pixel_codigo',
      change_param: 'facebook_pixel',
      regex: Regex.DESCRIPCION_0_1000,
      value: '',
      default_value: '',
      placeholder: ' ',
      messages: {
        error: 'Ingrese un código válido',
        help: 'Pegar aquí el código brindado por Facebook',
        error_extra: RegexExtra.DESCRIPCION_0_1000,
      },
    },
    t_facebook_pixel_tracking: {
      validate: false,
      name: 't_facebook_pixel_tracking',
      label:
        'Activar automáticamente los eventos del Pixel de facebook. Recordá eliminar los que hayas configurado manualmente para no replicar información.',
      value: 0,
      change_param: 'facebook_pixel',
    },
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState({
    facebookPixel: false,
    forms: false,
  });
  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    getShop((err, resp) => {
      if (err) {
        setError(true);
        setLoading((prevState) => ({
          ...prevState,
          facebookPixel: false,
        }));
      } else {
        const shopAux = shop && resp.data;

        Validate.cargar_formulario_sin_nulos(
          shopAux,
          facebookPixel,
          (facebook_pixel_obj) => {
            setFacebookPixel(facebook_pixel_obj);
            setLoading((prevState) => ({
              ...prevState,
              facebookPixel: false,
            }));
          }
        );
      }
    });
  }, []);

  const handleChange = (e, blur, _aditional) => {
    const { name, value } = e.target;

    const newFacebookPixelValues = {
      ...facebookPixel,
      [name]: {
        ...facebookPixel[name],
        value: value,
      },
    };

    Validate.validate_input(newFacebookPixelValues[name], blur, (input) => {
      setFacebookPixel((prevState) => ({
        ...prevState,
        [name]: input,
      }));
    });
  };

  const handleChangeCheckbox = (e, _aditional) => {
    const { name } = e.target;

    setFacebookPixel((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: prevState[name]['value'] ? 0 : 1,
      },
    }));
  };

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading((prevState) => ({
      ...prevState,
      forms: true,
    }));

    const form = facebookPixel;

    if (Validate.validar_formulario(form)) {
      updateFacebookPixel(
        {
          t_facebook_pixel_codigo: form.t_facebook_pixel_codigo.value,
          t_facebook_pixel_tracking: form.t_facebook_pixel_tracking.value,
        },
        (err, resp) => {
          setLoading((prevState) => ({
            ...prevState,
            forms: false,
          }));

          setAlert({
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setFacebookPixel(new_form);
        setLoading((prevState) => ({
          ...prevState,
          forms: false,
        }));
      });
    }
  };

  return (
    <WrapperLayout loading={loading.facebookPixel} error={error}>
      <AlertUI
        open={alert.open}
        type={alert.type}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <ModuleTitleUI
              title="Facebook Píxel"
              subtitles={[
                'Facebook Píxel es una herramienta que te ayuda a medir y optimizar tus campañas publicitarias en esa plataforma.',
                'Te permite medir las conversiones con el fin de monitorizar y analizar las acciones que realizan los usuarios en tu tienda tras ingresar a ella mediante tu anuncio de Facebook.',
              ]}
            />
            <CardModuleUI>
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <InputUI
                    multiline
                    handleChange={handleChange}
                    input={facebookPixel.t_facebook_pixel_codigo}
                  />
                </Grid>
              </Grid>
            </CardModuleUI>
            <CardModuleUI below>
              <Grid container>
                <Grid item xs={12} pb={3}>
                  <Typography className={classes.module_title}>
                    Eventos que se activan automáticamente al hacer click en el
                    check.
                  </Typography>
                </Grid>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Agregar al carrito / AddToCart"
                    subtitles={[
                      'Mide cuando una persona agrega un producto al carrito.',
                      'Indica automáticamente el total en pesos y el id del producto (el id es el mismo que el del catálogo para instagram shopping).',
                    ]}
                  />
                </Grid>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Contacto / Contact"
                    subtitles={[
                      'Mide cuando una persona envia un formulario de contacto o consulta por un producto.',
                    ]}
                  />
                </Grid>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Iniciar compra / InitiateCheckout"
                    subtitles={[
                      'Mide cuando una persona hace click en “Iniciar compra”.',
                      'Indica automáticamente el total en pesos y el id del/los producto/s de la orden (el id es el mismo que el del catálogo para instagram shopping).',
                    ]}
                  />
                </Grid>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Compra finalizada / Purchase"
                    subtitles={[
                      'Mide cuando una persona finaliza la compra y es dirigida a la página de “Gracias por tu compra”.',
                      'Indica automáticamente el total en pesos y el id del/los producto/s de la orden (el id es el mismo que el del catálogo para instagram shopping).',
                    ]}
                  />
                </Grid>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Buscar producto / Search"
                    subtitles={[
                      'Mide cuando una persona utiliza el buscador de la tienda.',
                      'Indica automáticamente el id del/los producto/s que aparencen en la búsqueda (el id es el mismo que el del catálogo para instagram shopping).',
                    ]}
                  />
                </Grid>
                <Grid item xs={12} pb={3}>
                  <ModuleCardTitleUI
                    title="Ver producto / ViewContent"
                    subtitles={[
                      'Mide cuando una persona entra a ver un producto en particular.',
                      'Indica automáticamente el total en pesos y el id del producto (el id es el mismo que el del catálogo para instagram shopping).',
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxUI
                    handleChangeCheckbox={handleChangeCheckbox}
                    checkbox={facebookPixel.t_facebook_pixel_tracking}
                  />
                </Grid>
              </Grid>
            </CardModuleUI>
            <ModuleButtonBoxUI>
              <ButtonUI
                type="submit"
                label="Guardar"
                isLoading={loading.forms}
              />
            </ModuleButtonBoxUI>
          </form>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  module_title: {
    color: theme.texts.card_title,
    fontWeight: '400',
    fontSize: '1.5rem',
  },
});

const mapStateToProps = (state) => {
  return {
    shop: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtener(callback)),
    updateFacebookPixel: (data, callback) =>
      dispatch(tiendaActualizarFacebookPixel(data, callback)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FacebookPixelPage)
);
