import { useAuth0 } from '@auth0/auth0-react';
import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Splash } from '../../common/Splash';

const SignUpStep = ({ match }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const router = useHistory();
  const searchParams = new URLSearchParams(router.location.search);
  const idCountry = searchParams.get('idCountry') || 'ARG';
  const ca_codigo = match.params.ca_codigo || '';

  useLayoutEffect(() => {
    if (isAuthenticated) {
      router.push('/');
    } else {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          idCountry: idCountry,
        },
        redirectUri: `${window.location.origin}/formulario-crear-tienda?ca_codigo=${ca_codigo}`,
      });
    }
  }, [isAuthenticated]);

  return <>{isLoading ? <Splash /> : ''}</>;
};

export { SignUpStep };
