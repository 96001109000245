import React from 'react';

export const Circle = ({ checked }) => (
  <svg
    width="12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={`${checked ? '12' : '13'}`}
    viewBox={`${checked ? '0 0 12 12' : '0 0 12 13'}`}
  >
    <circle
      r="5"
      cx="6"
      strokeWidth="2"
      cy={`${checked ? '6' : '6.36353'}`}
      fill={`${checked ? '#53B755' : undefined}`}
      stroke={`${checked ? '#53B755' : '#E3E3E3'}`}
    />
  </svg>
);
