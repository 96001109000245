/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const updateGoogleCategoryId = (categorias, categoria) => {
  return categorias.map((cat) => {
    if (cat.idCategorias === categoria.idCategorias) {
      return {
        ...cat,
        c_google_category_id: categoria.c_google_category_id,
      };
    } else {
      return {
        ...cat,
        subcategorias: updateGoogleCategoryId(cat.subcategorias, categoria),
      };
    }
  });
};

const categoriaReducer = (state = initState, action) => {
  switch (action.type) {
    case Reducers.CATEGORIA_GUARDAR:
      return action.data;
    case Reducers.AUTH_UNSET:
      return initState;
    case Reducers.CATEGORIA_UPDATE_GOOGLE_ID:
      return updateGoogleCategoryId(state, action.data);
    default:
      return state;
  }
};

export { categoriaReducer };
