import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  categoriasObtener,
  categoriasAgregar,
  categoriasEditar,
  categoriasBorrar,
  categoriasOrdenar,
  categoriasConfigurarGoogleCategoryId,
} from '../../store/actions';
import { Grid } from '@mui/material';
import { AlertUI, ModuleTitleUI, ButtonUI } from '../../components/common';
import {
  CategoriasList,
  ModalAgregar,
  ModalEditar,
  ModalBorrar,
  ModalGoogleID,
} from './subcomponents';
import {
  createAmplitudeEventWithDevice,
  amplitude_events,
} from '../../components/amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const CategoriesPage_ = ({
  getCategories,
  addCategory,
  editCategory,
  deleteCategory,
  sortCategories,
  configureGoogleCategoryId,
}) => {
  const [state, setState] = useState({
    agregar: {
      c_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'agregar_c_nombre',
        name: 'c_nombre',
        change_param: 'agregar',
        regex: Regex.CATEGORIA_NOMBRE,
        value: '',
        default_value: '',
        placeholder: 'Pantalones',
        messages: {
          error: 'Ingrese un nombre de categoría válida',
          error_extra: RegexExtra.CATEGORIA_NOMBRE,
        },
      },
      c_padre: {
        validate: false,
        value: null,
        c_nombre_padre: '',
      },
    },
    editar: {
      c_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'editar_c_nombre',
        name: 'c_nombre',
        change_param: 'editar',
        regex: Regex.CATEGORIA_NOMBRE,
        value: '',
        default_value: '',
        placeholder: 'Pantalones',
        messages: {
          error: 'Ingrese un nombre de categoría válida',
          error_extra: RegexExtra.CATEGORIA_NOMBRE,
        },
      },
      idCategorias: {
        validate: false,
        value: null,
      },
    },
    borrar: {
      c_nombre: {
        validate: false,
        value: '',
      },
      idCategorias: {
        validate: false,
        value: null,
      },
    },
    googleid: {
      idCategorias: {
        validate: false,
        value: null,
      },
      c_google_category_id: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'ID de categoría de Google',
        id: 'googleid_c_google_category_id',
        name: 'c_google_category_id',
        change_param: 'googleid',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: 'Ej: 166',
        messages: {
          error:
            'Ingrese un ID de categoría de Google válido. Coloca 0 para quitar la categoría de Google',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
    },
    loading: {
      categorias: true,
      error: false,
    },
    forms: {
      agregar: false,
      editar: false,
      borrar: false,
      googleid: false,
    },
    modals: {
      agregar: false,
      editar: false,
      borrar: false,
      googleid: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });

  useEffect(() => {
    getCategories((err, _resp) => {
      setState((prevState) => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          categorias: false,
          error: err ? true : false,
        },
      }));
    });
  }, []);

  const handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    const stateAux = {
      [aditional]: {
        ...state[aditional],
        [name]: {
          ...state[aditional][name],
          value: value,
        },
      },
    };
    Validate.validate_input(stateAux[aditional][name], blur, (input) => {
      setState((prevState) => ({
        ...prevState,
        [aditional]: {
          ...prevState[aditional],
          [name]: input,
        },
      }));
    });
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'agregar':
        let stateAux = open
          ? {
              ...state,
              modals: {
                ...state.modals,
                [id]: open,
              },
              agregar: {
                ...state.agregar,
                c_padre: {
                  validate: false,
                  value: aditional.c_padre,
                  c_nombre_padre: aditional.c_nombre_padre,
                },
              },
            }
          : {
              ...state,
              modals: {
                ...state.modals,
                [id]: open,
              },
            };

        setState(stateAux);

        if (!open) {
          let form_obj = Validate.limpiar_formulario(state.agregar);
          setState((prevState) => ({
            ...prevState,
            agregar: form_obj,
          }));
        }
        break;
      case 'editar':
        if (open) {
          const categoria = aditional;
          Validate.cargar_formulario_sin_nulos(
            categoria,
            state.editar,
            (editar_obj) => {
              setState((prevState) => ({
                ...prevState,
                editar: editar_obj,
                modals: {
                  ...prevState.modals,
                  [id]: open,
                },
              }));
            }
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        }
        break;
      case 'borrar':
        if (open) {
          const categoria = aditional;
          Validate.cargar_formulario_sin_nulos(
            categoria,
            state.borrar,
            (borrar_obj) => {
              setState((prevState) => ({
                ...prevState,
                borrar: borrar_obj,
                modals: {
                  ...prevState.modals,
                  [id]: open,
                },
              }));
            }
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        }
        break;
      case 'googleid':
        if (open) {
          const categoria = aditional;

          categoria.c_google_category_id = categoria.c_google_category_id
            ? categoria.c_google_category_id
            : '';

          Validate.cargar_formulario_sin_nulos(
            categoria,
            state.googleid,
            (googleid_obj) => {
              setState((prevState) => ({
                ...prevState,
                googleid: googleid_obj,
                modals: {
                  ...prevState.modals,
                  [id]: open,
                },
              }));
            }
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            modals: {
              ...prevState.modals,
              [id]: open,
            },
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleSubmitAgregar = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        agregar: true,
      },
    }));

    let form = state.agregar;

    if (Validate.validar_formulario(form)) {
      addCategory(
        {
          c_nombre: form.c_nombre.value,
          c_padre: form.c_padre.value,
        },
        (err, resp) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                agregar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            }));
            createAmplitudeEventWithDevice(
              amplitude_events.agregar_categoria_failed.event_name,
              amplitude_events.agregar_categoria_failed.event_params
            );
          } else {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                agregar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...prevState.modals,
                agregar: false,
              },
            }));
            createAmplitudeEventWithDevice(
              amplitude_events.agregar_categoria_successful.event_name,
              amplitude_events.agregar_categoria_successful.event_params
            );

            if (resp.data.length === 1) {
              braze.logCustomEvent('empretienda_primera_categoria_creada');
            }
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            agregar: false,
          },
          agregar: new_form,
        }));
      });
    }
  };

  const handleSubmitEditar = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        editar: true,
      },
    }));

    let form = state.editar;

    if (Validate.validar_formulario(form)) {
      editCategory(
        {
          c_nombre: form.c_nombre.value,
          idCategorias: form.idCategorias.value,
        },
        (err, resp) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                editar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                editar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...prevState.modals,
                editar: false,
              },
            }));
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            editar: false,
          },
          editar: new_form,
        }));
      });
    }
  };

  const handleSubmitBorrar = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        borrar: true,
      },
    }));

    let form = state.borrar;

    deleteCategory(
      {
        idCategorias: form.idCategorias.value,
      },
      (err, resp) => {
        if (err) {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              borrar: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: 'error',
            },
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              borrar: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...prevState.modals,
              borrar: false,
            },
          }));
        }
      }
    );
  };

  const handleSubmitEditarGoogleCategoryId = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        googleid: true,
      },
    }));

    let form = state.googleid;

    if (Validate.validar_formulario(form)) {
      configureGoogleCategoryId(
        {
          c_google_category_id: form.c_google_category_id.value,
          idCategorias: form.idCategorias.value,
        },
        (err, resp) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                googleid: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                googleid: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...prevState.modals,
                googleid: false,
              },
            }));
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            googleid: false,
          },
          googleid: new_form,
        }));
      });
    }
  };

  const handleOrdenar = (new_categorias, nivel, parent) => {
    sortCategories(
      {
        new_categorias: new_categorias,
        nivel: nivel,
        parent: parent,
      },
      (err) => {
        if (err) {
          setState((prevState) => ({
            ...prevState,
            loading: {
              error: true,
            },
          }));
        }
      }
    );
  };

  return (
    <WrapperLayout
      loading={state.loading.categorias}
      error={state.loading.error}
    >
      <AlertUI
        open={state.alert.open}
        message={state.alert.message}
        type={state.alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <ModalAgregar
        agregar={state.agregar}
        handleSubmit={handleSubmitAgregar}
        handleChange={handleChange}
        modalHandler={modalHandler}
        modal_state={state.modals.agregar}
        form_state={state.forms.agregar}
      />
      <ModalEditar
        editar={state.editar}
        handleSubmit={handleSubmitEditar}
        handleChange={handleChange}
        modalHandler={modalHandler}
        modal_state={state.modals.editar}
        form_state={state.forms.editar}
      />
      <ModalBorrar
        borrar={state.borrar}
        handleSubmit={handleSubmitBorrar}
        modalHandler={modalHandler}
        modal_state={state.modals.borrar}
        form_state={state.forms.borrar}
      />
      <ModalGoogleID
        googleid={state.googleid}
        handleSubmit={handleSubmitEditarGoogleCategoryId}
        handleChange={handleChange}
        modalHandler={modalHandler}
        modal_state={state.modals.googleid}
        form_state={state.forms.googleid}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Categorías"
            subtitles={[
              'En esta sección podrás agregar las categorías y subcategorías para luego subir los productos en tu tienda online.',
              'Recomendamos que los nombres de las categorías sean representativos y concisos para que tus clientes encuentren más rápido lo que necesitan y, además, ayudar al posicionamiento de la tienda en los buscadores.',
            ]}
            button={
              <ButtonUI
                type="callf"
                label="Agregar"
                fullWidth={false}
                onClickFunc={() =>
                  modalHandler(true, 'agregar', {
                    c_padre: null,
                    c_nombre_padre: '',
                  })
                }
              />
            }
          />
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <CategoriasList
                modalHandler={modalHandler}
                handleOrdenar={handleOrdenar}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (callback) => dispatch(categoriasObtener(callback)),
    addCategory: (data, callback) =>
      dispatch(categoriasAgregar(data, callback)),
    editCategory: (data, callback) =>
      dispatch(categoriasEditar(data, callback)),
    deleteCategory: (data, callback) =>
      dispatch(categoriasBorrar(data, callback)),
    sortCategories: (data, callback) =>
      dispatch(categoriasOrdenar(data, callback)),
    configureGoogleCategoryId: (data, callback) =>
      dispatch(categoriasConfigurarGoogleCategoryId(data, callback)),
  };
};

const CategoriesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesPage_);

export default CategoriesPage;
