import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, IconButton, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI } from './';

const DropdownUI_ = ({
  classes,
  children,
  label = '',
  size = 'medium',
  type = 'button',
  fullWidth = true,
  isLoading = false,
  minHeight = false,
  icon = 'more_vert',
  variant = 'contained',
}) => {
  const [menu, openMenu] = useState(null);

  const openMenuHandler = (e) => {
    openMenu(e ? e.currentTarget : null);
  };

  let menu_html = (
    <Menu
      open={Boolean(menu)}
      anchorEl={menu}
      onClose={() => openMenuHandler(null)}
      classes={{ paper: classes.paper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { openMenuHandler })
      )}
    </Menu>
  );

  if (type === 'button') {
    return (
      <div>
        <ButtonUI
          isLoading={isLoading}
          type="callf"
          label={label}
          minHeight={minHeight}
          fullWidth={fullWidth}
          size={size}
          variant={variant}
          onClickFunc={(e) => openMenuHandler(e)}
        />
        {menu_html}
      </div>
    );
  } else {
    return (
      <div>
        <IconButton
          aria-label="Icon button"
          onClick={(e) => openMenuHandler(e)}
          size="large"
        >
          <Icon>{icon}</Icon>
        </IconButton>
        {menu_html}
      </div>
    );
  }
};

const styles = (theme) => ({
  paper: {
    borderRadius: '2px',
  },
});

DropdownUI_.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.any,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'icon']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export const DropdownUI = withStyles(styles)(DropdownUI_);
