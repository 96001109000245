import React from 'react';
import uuid from 'uuid/v1';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@mui/material';

export const CheckboxUI = ({
  checkbox,
  handleChangeCheckbox,
  fontSize = '1rem',
  inverse = false,
  color = '',
}) => {
  let { label, value, name, id, change_param } = checkbox;

  let checked;

  if (inverse) {
    checked = value ? false : true;
  } else {
    checked = value ? true : false;
  }

  id = id ? id : name + '_' + uuid().split('-')[0];

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => handleChangeCheckbox(e, change_param)}
          id={id}
          name={name}
          value={value.toString()}
          color="primary"
        />
      }
      sx={{
        '.MuiFormControlLabel-label': {
          fontSize: fontSize,
          userSelect: 'none',
          color: color,
        },
      }}
      label={label}
    />
  );
};

CheckboxUI.propTypes = {
  //Function called on checkbox event
  handleChangeCheckbox: PropTypes.func.isRequired,

  //Reverts the option check (1 unchecked, 0 checked)
  //Default: false (0 unchecked, 1 checked)
  inverse: PropTypes.bool,

  //Sets the font size of the label that is next to the checkbox
  //Default: 1rem
  fontSize: PropTypes.string,

  //The object initialized in state
  checkbox: PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node.isRequired,
    ]),
    value: PropTypes.any,
    name: PropTypes.any,
    id: PropTypes.any,
    change_param: PropTypes.any.isRequired,
  }),
};
