import React from 'react';
import { Grid } from '@mui/material';
import {
  ModalUI,
  InputUI,
  SelectUI,
  ToggleUI,
} from '../../../../../components/common';

const ModalEditarDatosEnvio = (props) => {
  let {
    editar,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
    handleChangeSelect,
    selects,
    venta,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="editar_datos_envio"
      subtitle={editar.modal_subtitle.value}
      title="Editar datos de envío"
      modalHandler={modalHandler}
      aditional_param="editar_datos_envio"
      handleSubmit={handleSubmit}
      button_label="Guardar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item sm={6} xs={12}>
          <InputUI handleChange={handleChange} input={editar.v_envio_nombre} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_envio_apellido}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_envio_numero_identificacion}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputUI
            handleChange={handleChange}
            input={editar.v_envio_telefono}
          />
        </Grid>
        <ToggleUI show={venta.v_metodo_envio !== 2}>
          <Grid item sm={6} xs={12}>
            <InputUI handleChange={handleChange} input={editar.v_envio_calle} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputUI
              handleChange={handleChange}
              input={editar.v_envio_numero}
            />
          </Grid>
          <Grid item xs={6}>
            <InputUI handleChange={handleChange} input={editar.v_envio_piso} />
          </Grid>
          <Grid item xs={6}>
            <InputUI handleChange={handleChange} input={editar.v_envio_dpto} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputUI
              handleChange={handleChange}
              input={editar.v_envio_ciudad}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <SelectUI
              data={editar.v_envio_provincia}
              options={selects.v_provincia}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={editar.v_envio_postal}
            />
          </Grid>
        </ToggleUI>
        <Grid item xs={12}>
          <InputUI
            multiline
            handleChange={handleChange}
            input={editar.v_observacion}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalEditarDatosEnvio };
