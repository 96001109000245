import React from 'react';
import { Box, Button, Divider, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { VentaPaginacion } from './VentaPaginacion';
import { useTheme } from '@emotion/react';

export const OrderNavigationButtons = ({
  saleId,
  lastSaleId,
  redirectToPreviousSale,
  redirectToNextSale,
}) => {
  let router = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const filterQueryParams = localStorage.getItem('filter_query_params');

  const handleGoBackButton = () => {
    router.push(
      filterQueryParams
        ? `/listado-de-ventas${filterQueryParams}`
        : '/listado-de-ventas'
    );
    localStorage.removeItem('filter_query_params');
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb="16px">
        <Box>
          <Button
            fullWidth={false}
            variant="text"
            color="info"
            onClick={handleGoBackButton}
            startIcon={<ArrowBack />}
            sx={{ fontSize: '14px', color: '#383838' }}
          >
            {isMobile ? '' : 'Volver'}
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <VentaPaginacion firstvalue={saleId} lastvalue={'de ' + lastSaleId} />
          <Button
            sx={{
              color: '#383838',
              '&:disabled svg path': {
                fill: '#8F8F8F',
              },
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.08)',
              },
            }}
            fullWidth={false}
            variant="text"
            onClick={(e) => redirectToPreviousSale()}
            disabled={saleId === 1}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
                fill="#383838"
              />
            </svg>
          </Button>
          <Button
            sx={{
              color: '#383838',
              '&:disabled svg path': {
                fill: '#8F8F8F',
              },
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.08)',
              },
            }}
            fullWidth={false}
            variant="text"
            onClick={() => redirectToNextSale()}
            disabled={saleId === lastSaleId}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z"
                fill="#383838"
              />
            </svg>
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{ marginBottom: '25px', borderBottom: '1px solid #CDCDCD' }}
      />
    </>
  );
};
