import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

/**
 * Exporta datos a un archivo Excel.
 * @param {Array} data Los datos a exportar.
 * @param {Object} config Configuración de la exportación.
 */
export const exportToExcel = (data, config) => {
  // Genera la hoja de cálculo a partir de los datos

  const mappedData = data.map((item) =>
    config.columns.reduce((acc, column) => {
      acc[column.label] = item[column.value];
      return acc;
    }, {})
  );

  const ws = XLSX.utils.json_to_sheet(mappedData);

  // Crea un nuevo libro y añade la hoja de cálculo
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, config.sheetName || 'Planilla 1');

  // Opciones de escritura para el archivo
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Descarga el archivo
  saveAs(blob, `${config.filename || 'Export'}.xlsx`);
};
