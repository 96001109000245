import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarPinterestTag,
} from '../../store/actions';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import { Card, CardContent, Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  ModuleCardTitleUI,
} from '../common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class PinterestTag_ extends Component {
  state = {
    pinterest_tag: {
      t_pinterest_tag: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Etiqueta de Pinterest',
        id: 't_pinterest_tag',
        name: 't_pinterest_tag',
        change_param: 'pinterest_tag',
        regex: Regex.PINTEREST_TAG,
        value: '',
        default_value: '',
        placeholder: '<meta name="p:domain_verify"...',
        messages: {
          error: 'Ingrese una etiqueta de Pinterest válida',
          help: 'Es la etiqueta brindada por Pinterest para verificar el sitio web',
          error_extra: RegexExtra.PINTEREST_TAG,
        },
      },
    },
    loading: {
      pinterest_tag: true,
      error: false,
    },
    forms: {
      pinterest_tag: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState((prevState) => ({
          loading: {
            ...prevState.loading,
            pinterest_tag: false,
            error: true,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          this.state.pinterest_tag,
          (pinterest_tag_obj) => {
            this.setState((prevState) => ({
              pinterest_tag: pinterest_tag_obj,
              loading: {
                ...prevState.loading,
                pinterest_tag: false,
                error: false,
              },
            }));
          }
        );
      }
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      (prevState) => ({
        [aditional]: {
          ...prevState[aditional],
          [name]: {
            ...prevState[aditional][name],
            value: value,
          },
        },
      }),
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState((prevState) => ({
            [aditional]: {
              ...prevState[aditional],
              [name]: input,
            },
          }));
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState((prevState) => ({
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      forms: {
        ...prevState.forms,
        pinterest_tag: true,
      },
    }));

    let form = this.state.pinterest_tag;

    if (Validate.validar_formulario(form)) {
      this.props.tiendaActualizarPinterestTag(
        {
          t_pinterest_tag: form.t_pinterest_tag.value,
        },
        (err, resp) => {
          this.setState((prevState) => ({
            forms: {
              ...prevState.forms,
              pinterest_tag: false,
            },
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          }));
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState((prevState) => ({
          forms: {
            ...prevState.forms,
            pinterest_tag: false,
          },
          pinterest_tag: new_form,
        }));
      });
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout
        loading={this.state.loading.pinterest_tag}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="Etiqueta de Pinterest"
                inline_image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/pinterest-tag.png`}
                subtitles={[
                  'Conecta tu sitio web para acceder a analytics del sitio web e informar a las personas dónde pueden encontrar más contenido tuyo.',
                  'Tu imagen de perfil se mostrará junto a todos los Pines que procedan de tu sitio y el icono de un globo pequeño aparecerá a la izquierda de la URL de tu sitio web en tu perfil.',
                ]}
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container>
                    <Grid item xs={12} pb={3}>
                      <ModuleCardTitleUI
                        title="Pasos para conectar tu tienda con Pinterest:"
                        subtitles={[
                          <span>
                            1. Iniciá sesión en Pinterest desde un navegador web
                            y hacé click en{' '}
                            <Icon sx={{ position: 'relative', top: '9px' }}>
                              more_horiz
                            </Icon>{' '}
                            para abrir el menú.
                          </span>,
                          <span className={classes.tutorial_step}>
                            2. Seleccioná "Ajustes".
                          </span>,
                          <span className={classes.tutorial_step}>
                            3. Hacé click en "Conectar".
                          </span>,
                          <span className={classes.tutorial_step}>
                            4. En la sección "Conecta tu sitio web", escribí el
                            link de tu tienda y luego hacé click en "Conectar".
                          </span>,
                          <span className={classes.tutorial_step}>
                            5. Seleccioná "Añade una etiqueta HTML".
                          </span>,
                          <span className={classes.tutorial_step}>
                            6. Copiá la etiqueta y hacé click en "Siguiente".
                          </span>,
                          <span className={classes.tutorial_step}>
                            7. Pegá la etiqueta en el campo que aparece debajo y
                            hacé click en "GUARDAR".
                          </span>,
                          <span className={classes.tutorial_step}>
                            8. Volvé a Pinterest y hacé click en "Enviar".
                          </span>,
                          <span className={classes.tutorial_step}>
                            9. Para finalizar, hacé click en "Hecho".
                          </span>,
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        multiline
                        input={this.state.pinterest_tag.t_pinterest_tag}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  isLoading={this.state.forms.pinterest_tag}
                  minHeight={true}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
  tutorial_step: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarPinterestTag: (data, callback) =>
      dispatch(tiendaActualizarPinterestTag(data, callback)),
  };
};

export const PinterestTag = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PinterestTag_)
);
