import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import {
  Avatar,
  Icon,
  List,
  ListItem,
  Typography,
  IconButton,
  Divider,
  Grid,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Validate } from '../../../other';
import { CardModuleUI, ToggleUI } from '../../common';
import { DominioDNSZone } from './';

let mobile = Validate.is_mobile();

const DominioSubdominioRegistrosCNAME = ({ dominio }) => {
  return (
    <Grid item xs={12}>
      <Divider style={{ marginBottom: '15px' }} />
      <Typography variant="body1" gutterBottom>
        Para finalizar, debes crear y no borrar los siguientes 2 registros en tu
        zona DNS (recuerda esperar al menos 45 minutos luego de cargarlos antes
        de intentar acceder para dar tiempo a que se propaguen los cambios):
      </Typography>
      <Typography variant="body2" gutterBottom>
        Registro 1:
      </Typography>
      <ul style={{ listStyleType: 'disc' }}>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>Tipo:</strong> CNAME
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>Nombre:</strong>{' '}
            {dominio.d_nombre}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>Valor:</strong>{' '}
            {dominio.d_metadata.web.cdn}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>TTL:</strong> 3600
          </Typography>
        </li>
      </ul>
      <Typography variant="body2" gutterBottom>
        Registro 2:
      </Typography>
      <ul style={{ listStyleType: 'disc' }}>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>Tipo:</strong> CNAME
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>Nombre:</strong> www.
            {dominio.d_nombre}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>Valor:</strong>{' '}
            {dominio.d_metadata.web.cdn}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong style={{ marginRight: '10px' }}>TTL:</strong> 3600
          </Typography>
        </li>
      </ul>
    </Grid>
  );
};

const DominioItem = (props) => {
  let { dominio, classes, divider, modalHandler } = props;

  let show = true;
  let dns_config = false;
  let estado = dominio.d_activo ? 'Activo' : 'En proceso de configuración';

  if (dominio.d_metadata) {
    dns_config =
      dominio.d_metadata.status >= 1 && dominio.d_metadata.status <= 8;
    show = dominio.d_metadata.status <= 9;
  }

  return (
    <ToggleUI show={show}>
      <ListItem
        divider={divider}
        alignItems="flex-start"
        sx={{ padding: '12px 16px' }}
      >
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <ToggleUI show={!mobile}>
                    <Grid item>
                      <Avatar sx={{ marginRight: 2 }}>
                        <Icon>language</Icon>
                      </Avatar>
                    </Grid>
                  </ToggleUI>
                  <Grid item sx={{ marginBottom: { xs: 1, sm: 0 } }}>
                    <Typography className={classes.list_item_title}>
                      {dominio.d_nombre}
                    </Typography>
                    <Typography className={classes.list_item_subtitle}>
                      Estado: {estado}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ paddingLeft: 0 }}>
                <IconButton
                  aria-label="Link"
                  href={'https://' + dominio.d_nombre}
                  disabled={dominio.d_activo ? false : true}
                  target="_blank"
                  sx={{ padding: { xs: 0, sm: 1.5 } }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <ToggleUI show={dns_config && dominio.d_metadata.type === 0}>
            <DominioDNSZone dominio={dominio} modalHandler={modalHandler} />
          </ToggleUI>
          <ToggleUI show={dns_config && dominio.d_metadata.type === 1}>
            <DominioSubdominioRegistrosCNAME dominio={dominio} />
          </ToggleUI>
        </Grid>
      </ListItem>
    </ToggleUI>
  );
};

const DominiosList_ = (props) => {
  let { dominios, classes, modalHandler, modalHandlerMigrarDominio } = props;

  return (
    <CardModuleUI header_title="Mis dominios" below disablePadding>
      <List classes={{ root: classes.list }}>
        {dominios.map((dominio, index) => (
          <DominioItem
            dominio={dominio}
            classes={classes}
            divider={index < dominios.length - 1}
            key={index}
            modalHandlerMigrarDominio={modalHandlerMigrarDominio}
            modalHandler={modalHandler}
          />
        ))}
      </List>
    </CardModuleUI>
  );
};

const styles = (theme) => ({
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  list_item_title: {
    fontSize: '1rem',
  },
  list_item_subtitle: {
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const mapStateToProps = (state) => {
  return {
    dominios: state.dominios,
  };
};

const mapDispatchToProps = null;

export const DominiosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DominiosList_));
