import {
  worksheet_format,
  reserved_column_names,
  hasColumn,
} from './ExportFormat';

//función para buscar columnas de ExportFormat duplicadas
const searchDuplicates = (list_values) => {
  const count = {};
  const result = [];

  list_values.forEach((item) => {
    if (count[item]) {
      count[item] += 1;
      return;
    }
    count[item] = 1;
  });

  for (let prop in count) {
    if (count[prop] >= 2) {
      result.push(prop);
    }
  }
  return result;
};

export const titleValidation = (title_list) => {
  /**
   * Saber cuales son las columnas reservadas por empretienda, tanto opcionales como obligatorias
   * No puede haber columnas reservadas duplicadas. Ej: Nombre, Nombre
   * Se permite al usuario tener sus propias columnas duplicadas
   * Tener en cuenta el índice de las columnas (o letra de columna ) ya que va a haber un corrimiento por las columnas del usuario
   */

  let valid_columns = [];
  let error_messages = [];
  let err = false;

  //no puede haber columnas reservadas duplicadas
  let columns_duplicates = searchDuplicates(title_list);
  for (const column of columns_duplicates) {
    if (hasColumn(column, reserved_column_names)) {
      err = true;
      error_messages.push(`La columna ${column} esta duplicada`);
    }
  }

  if (err)
    return {
      err,
      valid_columns,
      error_messages,
    };

  //deben existir como mínimo las columnas requeridas
  const columns_required = worksheet_format
    .filter((column) => column.column_required(title_list) === true)
    .map((column) => column.column_name);

  for (const columns_title of columns_required) {
    if (!hasColumn(columns_title, title_list)) {
      err = true;
      error_messages.push(`No se encontró la columna de ${columns_title}`);
    } else {
      //índice de la columna con base cero
      const index = title_list.indexOf(columns_title);
      valid_columns.push({ key: index, title: columns_title });
    }
  }

  if (err)
    return {
      err,
      valid_columns,
      error_messages,
    };

  //pueden existir las columnas opcionales
  const columns_optional = worksheet_format
    .filter((column) => column.column_required(title_list) === false)
    .map((column) => column.column_name);

  for (const columns_title of columns_optional) {
    if (hasColumn(columns_title, title_list)) {
      //índice de la columna con base cero
      const index = title_list.indexOf(columns_title);
      valid_columns.push({ key: index, title: columns_title });
    }
  }

  return {
    err,
    valid_columns,
    error_messages,
  };
};
