import React, { useState } from 'react';
import { AppMenuItemComponent } from './AppMenuItemComponent';
import {
  Collapse,
  Icon,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ChipUI } from '../../../components/common';
import { useHistory } from 'react-router-dom';

const AppMenuItem_ = React.memo(
  ({
    label,
    link,
    newFeature = false,
    icon,
    id,
    split = false,
    classes,
    withMargin = false,
    withPadding = false,
    onlyArg = false,
    items = [],
    isOpen = false,
    setMenuItems,
    isParentMenu = false,
    onItemClick,
    amplitudeEvent = () => {},
  }) => {
    const isExpandable = items && items.length > 0;

    const history = useHistory();

    const [selectedCategory, setSelectedCategory] = useState('');

    const closeSubMenus = (subMenuItems, itemId) => {
      const test = subMenuItems.map((subItem) => ({
        ...subItem,
        isOpen: subItem.items
          ? subItem.id === itemId
            ? !subItem.isOpen
            : false
          : undefined,
      }));

      return test;
    };

    const handleToggleMenu = (itemId) => {
      setMenuItems((prevItems) =>
        prevItems.map((menuItem) => {
          if (menuItem.id === itemId) {
            // Open or close first clicked menu

            setSelectedCategory(menuItem.id);

            return { ...menuItem, isOpen: !menuItem.isOpen };
          } else if (menuItem.items && menuItem.items.length > 0) {
            // Close other menus on the same level

            if (isParentMenu) {
              return {
                ...menuItem,
                isOpen: false,
                items: closeSubMenus(menuItem.items, itemId),
              };
            }

            return {
              ...menuItem,
              items: closeSubMenus(menuItem.items, itemId),
            };
          }
          return menuItem;
        })
      );
    };

    const combinedClasses = `${
      !icon
        ? classes.list_item_without_icon
        : isOpen ||
          (label === 'Aplicaciones' &&
            history.location.pathname === '/aplicaciones')
        ? `${classes.list_item_with_icon} ${classes.list_item_with_icon_open}`
        : classes.list_item_with_icon
    } ${
      (isOpen || selectedCategory === id) && isExpandable
        ? classes.list_item_menu_bold
        : classes.list_item_menu
    } `;

    const MenuItemRoot = (
      <AppMenuItemComponent
        link={link}
        onClick={handleToggleMenu}
        id={id}
        label={label}
        withMargin={withMargin}
        withPadding={withPadding}
        onlyArg={onlyArg}
        amplitudeEvent={amplitudeEvent}
        split={split}
        isExpandable={isExpandable}
        isParentMenu={isParentMenu}
      >
        {!!icon && (
          <ListItemIcon
            className={
              isOpen ||
              (label === 'Aplicaciones' &&
                history.location.pathname === '/aplicaciones')
                ? classes.list_icon_open
                : classes.list_icon_closed
            }
          >
            <Icon>{icon}</Icon>
          </ListItemIcon>
        )}
        <ListItemText
          className={combinedClasses}
          primary={label}
          inset={!icon}
        />
        {newFeature && <ChipUI>¡Nuevo!</ChipUI>}
        {isExpandable && !isOpen && (
          <Icon className={classes.list_item_arrow_inactive}>expand_more</Icon>
        )}
        {isExpandable && isOpen && (
          <Icon
            className={
              isParentMenu
                ? classes.list_item_arrow_active
                : classes.list_item_arrow_inactive
            }
          >
            expand_less
          </Icon>
        )}
      </AppMenuItemComponent>
    );

    const MenuItemChildren = isExpandable ? (
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <AppMenuItem
              handleToggleMenu={handleToggleMenu}
              setMenuItems={setMenuItems}
              {...item}
              key={index}
              onItemClick={onItemClick}
            />
          ))}
        </List>
      </Collapse>
    ) : null;

    return (
      <>
        {MenuItemRoot}
        {MenuItemChildren}
      </>
    );
  }
);

const styles = (theme) => ({
  list_item_arrow_inactive: {
    color: theme.texts.secondary,
  },
  list_item_arrow_active: {
    color: theme.palette.primary.main,
  },
  list_icon_closed: {
    marginRight: '3px',
    color: theme.texts.secondary,
  },
  list_icon_open: {
    marginRight: '3px',
    color: theme.palette.primary.main,
  },
  list_item_menu: {
    [theme.breakpoints.up('md')]: {
      padding: '7.5px',
    },
    '& span': {
      color: theme.texts.secondary,
    },
    '&:first-child': {
      paddingLeft: '44px',
    },
  },
  list_item_menu_bold: {
    [theme.breakpoints.up('md')]: {
      padding: '7.5px',
    },
    '& span': {
      fontWeight: '500',
      color: theme.texts.secondary,
    },
    '&:first-child': {
      paddingLeft: '44px',
    },
  },
  list_item_without_icon: {
    '& span': {
      fontSize: '0.875rem',
    },
  },
  list_item_with_icon: {
    '& span': {
      fontWeight: '500',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  list_item_with_icon_open: {
    '& span': {
      color: theme.palette.primary.main,
    },
  },
});

export const AppMenuItem = withStyles(styles)(AppMenuItem_);
