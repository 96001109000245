export const getCeresBlocks = () => [
  {
    bl_tipo: 1,
    bl_nombre: 'Carrusel de banners',
    bl_activo: 1,
    bl_data: {
      items: [
        {
          url: '',
          link: 'block-preview-banner.jpg',
          title: '',
          button: '',
        },
      ],
      width: 0,
      seconds: 5,
      autoplay: 1,
    },
  },
  {
    bl_tipo: 6,
    bl_nombre: 'Imágenes con link',
    bl_activo: 1,
    bl_data: {
      gap: 0,
      text: '',
      type: 2,
      items: [
        {
          url: '',
          link: 'block-preview-bag.jpeg',
          title: 'Arriba.',
          button: '',
        },
        {
          url: '',
          link: 'block-preview-dress.jpeg',
          title: 'Abajo.',
          button: '',
        },
        {
          url: '',
          link: 'block-preview-shirt.jpeg',
          title: 'Accesorios.',
          button: '',
        },
      ],
      title: '',
      per_row: 3,
      background: '#ffffff',
      text_align: 0,
      title_align: 0,
      section_size: 0,
      per_row_mobile: 1,
    },
  },
  {
    bl_tipo: 2,
    bl_nombre: 'Feed de productos',
    bl_activo: 1,
    bl_data: {
      text: '',
      title: 'Todos los productos',
      background: '#ffffff',
      text_align: 0,
      title_align: 0,
      section_size: 1,
      show_more_btn: 'VER MÁS',
    },
  },
  {
    bl_tipo: 8,
    bl_nombre: 'Info de compra',
    bl_activo: 1,
    bl_data: {
      icon_1: 0,
      icon_2: 1,
      icon_3: 2,
      text_1: 'COMPRÁ SIN SALIR DE TU CASA',
      text_2: 'CON TODAS LAS TARJETAS',
      text_3: 'PROTEGEMOS TUS DATOS',
      title_1: 'ENVÍOS A TODO EL PAÍS',
      title_2: 'HASTA 12 CUOTAS',
      title_3: 'COMPRA SEGURA',
      background: '#f5f5f5',
      text_color: '#000000',
    },
  },
];
