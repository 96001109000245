import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StepContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'colorText',
})(({ colorText }) => ({
  margin: '0',
  background: '#FAFAFA',
  color: colorText,
  padding: '12px',
  transition: 'background 200ms',
  '&:hover': {
    cursor: colorText === '#3A3A3A' ? 'pointer' : 'auto',
    background: colorText === '#3A3A3A' ? 'rgba(0, 0, 0, 0.04)' : '#FAFAFA',
  },
}));

export const StepContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StepContentTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '19px',
});

export const Paragraph = styled(Typography)({
  color: '#3A3A3A',
  fontWeight: '400',
  lineHeight: '24px',
  fontSize: '0.875rem',
  '& a': {
    color: '#53B755',
    fontWeight: '400',
    lineHeight: '24px',
    fontSize: '0.875rem',
    textDecoration: 'underline !important',
  },
});
