/* eslint-disable react/jsx-no-duplicate-props */
/*
    Author: Luciano Ghione  
    Scope: Web
    Version: 1.0.0
    CodeUI is a component for writing html/css/javascript code
*/

import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-monokai';

const CodeUI_ = (props) => {
  let { handleChange, lang, width, height, input } = props;

  let { value, name, id, change_param, placeholder } = input;

  return (
    <AceEditor
      placeholder={placeholder}
      mode={lang}
      theme="monokai"
      name={name}
      width={width}
      height={height}
      onChange={(value) =>
        handleChange(
          {
            target: {
              value: value,
              name: name,
              id: id,
            },
          },
          false,
          change_param
        )
      }
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={value}
      setOptions={{
        showLineNumbers: true,
        tabSize: 4,
        useWorker: false,
      }}
    />
  );
};

CodeUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //Function used to update controlled input
  //handleChange(e,blur,change_param = null)
  handleChange: PropTypes.func.isRequired,

  //Language of the editor
  lang: PropTypes.oneOf(['html', 'css', 'javascript']).isRequired,

  //width of the editor
  width: PropTypes.string.isRequired,

  //height of the editor
  height: PropTypes.string.isRequired,

  //The object initialized in state
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    change_param: PropTypes.any, //adds a third parameter to handleChange if needed
    placeholder: PropTypes.string.isRequired,
  }),
};

export const CodeUI = CodeUI_;
